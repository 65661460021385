import {COMPONENT_TYPE} from './ComponentType';
import fbpIcons from '../fbpIcons/fbpIcons';
import LinkIcon from '../../common/Icons/LinkIcon';
import DateIcon from '../../common/Icons/DateIcon';
import TickIcon from "../../common/Icons/TickIcon";
import ArrowDownIcon from "../../common/Icons/ArrowDownIcon";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const BASIC_ELEMENTS = [
  {type: COMPONENT_TYPE.HEADING, title: 'Heading', icon: fbpIcons.fbpHeadingIcon},
  {type: COMPONENT_TYPE.FULL_NAME, title: 'Full Name', icon: fbpIcons.fbpFullNameIcon},
  {type: COMPONENT_TYPE.EMAIL, title: 'Email', icon: fbpIcons.fbpEmailIcon},
  {type: COMPONENT_TYPE.PHONE, title: 'Phone', icon: fbpIcons.fbpPhoneIcon},
  {type: COMPONENT_TYPE.ADDRESS, title: 'Address', icon: fbpIcons.fbpLocationPinIcon},
  {type: COMPONENT_TYPE.COMPANY, title: 'Company', icon: fbpIcons.companyIcon},
  {type: COMPONENT_TYPE.BIRTHDAY, title: 'Birthday', icon: fbpIcons.birthdayIcon},
  {type: COMPONENT_TYPE.NOTE, title: 'Note', icon: fbpIcons.noteIcon}
];

export const INPUT_BLOCKS = [
  {type: COMPONENT_TYPE.SHORT_ANSWER, title: 'Short Answer', icon: fbpIcons.shortAnswerIcon},
  {type: COMPONENT_TYPE.LONG_ANSWER, title: 'Long Answer', icon: fbpIcons.chatIcon},
  // {type: COMPONENT_TYPE.PHONE_INPUT, title: 'Phone Input', icon: fbpIcons.fbpPhoneIcon},
  {type: COMPONENT_TYPE.NUMBER, title: 'Number', icon: fbpIcons.sixNumberIcon},
  {type: COMPONENT_TYPE.LINK, title: 'Link', icon: <LinkIcon fill="#546376" opacity={1} />},
  {type: COMPONENT_TYPE.DATE, title: 'Date', icon: <DateIcon fill="#546376" />},
  {type: COMPONENT_TYPE.TIME, title: 'Time', icon: fbpIcons.timeIcon},
  {type: COMPONENT_TYPE.YES_NO, title: 'Yes/No', icon: <TickIcon fill="#546376" />},
  {type: COMPONENT_TYPE.RADIO, title: 'Radio', icon: fbpIcons.radioIcon},
  {type: COMPONENT_TYPE.CHECKBOX, title: 'Checkbox', icon: fbpIcons.checkboxIcon},
  {type: COMPONENT_TYPE.DROPDOWN, title: 'Dropdown', icon: <ArrowDownIcon fill="#546376"/>},
  {type: COMPONENT_TYPE.FILE_UPLOAD, title: 'File Upload', icon: fbpIcons.fpbUploadBlackIcon},
  {type: COMPONENT_TYPE.HIDDEN_FIELD, title: 'Hidden Field', icon: fbpIcons.fpbHiddenIcon},
  {type: COMPONENT_TYPE.DISCLAIMER, title: 'Disclaimer', icon: <WarningRoundedIcon color={"text.secondary"}/>},
];

export const LAYOUT_BLOCKS = [
  {type: COMPONENT_TYPE.HEADING, title: 'Heading', icon: fbpIcons.fbpHeadingIcon},
  {type: COMPONENT_TYPE.PARAGRAPH, title: 'Paragraph', icon: fbpIcons.textIcon},
  {type: COMPONENT_TYPE.IMAGE, title: 'Image', icon: fbpIcons.imageIcon},
  {type: COMPONENT_TYPE.VIDEO, title: 'Video', icon: fbpIcons.videoIcon},
  {type: COMPONENT_TYPE.AUDIO, title: 'Audio', icon: fbpIcons.audioIcon},
  {type: COMPONENT_TYPE.EMBED, title: 'Embed', icon: fbpIcons.embedIcon}
];

export const ALL_BLOCKS = [
  {heading: 'Basic Elements', blocks: BASIC_ELEMENTS},
  {heading: 'Input Blocks', blocks: INPUT_BLOCKS},
  {heading: 'Layout Blocks', blocks: LAYOUT_BLOCKS}
];
