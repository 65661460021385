export const DEFAULT_IMAGE_SETTINGS = {
  profileImage: {
    profileImageBackground: '#fff',
    scaleProfileImage: 1,
    radiusProfileImage: 50,
    profileImageTranslateX: 0,
    profileImageTranslateY: 0
  },
  coverImage: {
    coverHeight: 312,
    coverPositionX: 50,
    coverPositionY: 50,
    scaleCover: 100
  },
  formImage: {
    formImageOpacity: 0,
    formImageColor: '#000',
    formImageAttachment: 'scroll'
  },
  pageImage: {
    pageImageOpacity: 0,
    pageImageColor: '#000'
  }
};

export const DEFAULT_THEME = {
  classic: {
    profileImage: null,
    profileImageBackground: '#fff',
    scaleProfileImage: 1,
    radiusProfileImage: 50,
    profileImageTranslateX: 0,
    profileImageTranslateY: 0,
    coverImage: null,
    coverHeight: 312,
    coverPositionX: 50,
    coverPositionY: 50,
    scaleCover: 100,
    layout: 'classic',
    animation: 'slideLeft',
    formColor: '#ffffff',
    formImage: null,
    formImageOpacity: 0,
    formImageColor: '#000',
    formImageAttachment: 'scroll',
    pageColor: '#f4f5f7',
    pageImage: null,
    pageImageOpacity: 0,
    pageImageColor: '#000',
    fontColor: '#1c1b1f',
    inputBackground: '#ffffff',
    subTextColor: '#546376',
    placeholderColor: '#b4b8c6',
    textColor: '#546376',
    containerWidth: 1000,
    fieldSpacing: 20,
    innerGap: 60,
    labelAlignment: 'top',
    font: "'Poppins', sans-serif",
    labelFontSize: 16,
    cardViewLabelFontSize: 36,
    descriptionFontSize: 16,
    subTextFontSize: 12,
    nextButton: {
      color: '#ffffff',
      border: '#008945',
      background: '#008945',
      icon: 'arrow_forward',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    backButton: {
      color: '#008945',
      border: '#008945',
      background: '#ffffff',
      icon: 'arrow_back',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    submitButton: {
      color: '#ffffff',
      border: '#006df5',
      background: '#006df5',
      icon: 'check',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    }
  },
  card: {
    profileImage: null,
    profileImageBackground: '#fff',
    scaleProfileImage: 1,
    radiusProfileImage: 50,
    profileImageTranslateX: 0,
    profileImageTranslateY: 0,
    coverImage: null,
    coverHeight: 312,
    coverPositionX: 50,
    coverPositionY: 50,
    scaleCover: 100,
    layout: 'card',
    animation: 'slideLeft',
    formColor: '#424756',
    formImage: 'https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/cardviewbg.jpeg',
    formImageOpacity: 0,
    formImageColor: '#000',
    formImageAttachment: 'scroll',
    pageColor: '#11111B',
    pageImage: null,
    pageImageOpacity: 0,
    pageImageColor: '#000',
    fontColor: '#ffffff',
    inputBackground: 'rgba(255,255,255,0.15)',
    subTextColor: '#FBFBFB',
    placeholderColor: 'rgba(255,255,255,0.3)',
    textColor: '#ffffff',
    containerWidth: 1000,
    fieldSpacing: 20,
    innerGap: 60,
    labelAlignment: 'top',
    font: "'Poppins', sans-serif",
    labelFontSize: 16,
    cardViewLabelFontSize: 36,
    descriptionFontSize: 16,
    subTextFontSize: 12,
    nextButton: {
      color: '#ffffff',
      border: '#FC0591',
      background: '#FC0591',
      icon: 'arrow_forward',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    backButton: {
      color: '#ffffff',
      border: '#ffffff',
      background: 'rgba(0, 0, 0, 0)',
      icon: 'arrow_back',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    submitButton: {
      color: '#ffffff',
      border: '#FC0591',
      background: '#FC0591',
      icon: 'check',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    }
  }
};
