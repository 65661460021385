import {ComponentConfig} from '../../builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../../builderConstant/ComponentType';
import {CONDITION_ACTION_STATE} from '../../constant/formConditionConstant';

const matchSingleField = (condition, field) => {
  let value;

  if (field.component === COMPONENT_TYPE.CHECKBOX) {
    if (
      condition.operator === CONDITION_ACTION_STATE.IS_FILLED ||
      condition.operator === CONDITION_ACTION_STATE.IS_EMPTY
    ) {
      value = field.options.find(option => option.checked);
    } else {
      value = field.options.find(option => option.value === condition.value)?.checked
        ? condition.value
        : false;
    }
  } else if(field.component === COMPONENT_TYPE.FILE_UPLOAD) {
    value = field.files.length;
  } else {
    value = ComponentConfig[field.component].getValue(field);
  }

  return (
    (condition.operator === CONDITION_ACTION_STATE.IS_EQUAL_TO && condition.value === value) ||
    (condition.operator === CONDITION_ACTION_STATE.IS_NOT_EQUAL_TO && condition.value !== value) ||
    (condition.operator === CONDITION_ACTION_STATE.IS_EMPTY && !value) ||
    (condition.operator === CONDITION_ACTION_STATE.IS_FILLED && value)
  );
};

export default matchSingleField;
