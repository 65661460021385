import React from 'react';
import {Box, Paper} from '@mui/material';
import ReportChart from './ReportChart';
import ReportHeader from './ReportHeader';
import ReportFooter from './ReportFooter';

const Reports = () => {
  return (
    <Box pb={0.5}>
      <ReportHeader />
      <ReportFooter />
      <Paper elevation={0} sx={{p: 2, width: '100%', my: 2}}>
        <ReportChart />
      </Paper>
    </Box>
  );
};

export default Reports;
