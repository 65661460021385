import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import {getMediaImagesFailed, getMediaImagesSuccess} from './mediaImageSlice';
import MediaImageService from '../../../services/MediaImage.service';

function* mediaImageWatcher() {
  yield takeLatest('mediaImage/getMediaImages', getMediaImagesSaga);
}

function* getMediaImagesSaga(action) {
  try {
    let payloadObj = {
      page: 1,
      limit: 30
    };
    if (action.payload?.query) {
      payloadObj.query = action.payload?.query;
    }
    const response = yield call(MediaImageService.getMediaImages, payloadObj);

    if (response.success) {
      yield put(getMediaImagesSuccess(response.data));
    } else {
      yield put(getMediaImagesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* mediaImageSaga() {
  yield all([mediaImageWatcher()]);
}
