import httpRequest from '../api/httpRequest';

class FormSettingService {
  getFormSEO(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/seo/setting/fetch',
      params
    );
  }

  updateFormSEO(body) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/seo/setting/edit',
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormSettingService();
