import React from 'react';
import {useSelector} from 'react-redux';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import LongTextAdvanced from './LongTextAdvanced';
import LongTextGeneral from './LongTextGeneral';
import LongTextOptions from './LongTextOptions';

const LongTextRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <LongTextGeneral field={field} />
      </CustomTab>

      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <LongTextOptions field={field} />
        </CustomTab>
      )}
      <CustomTab title="Advanced">
        <LongTextAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default LongTextRightPanel;
