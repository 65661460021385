import {nanoid} from 'nanoid';
import { COMPONENT_TYPE } from './ComponentType';
import Prototype from './FieldPrototypes';

export const DEFAULT_ELEMENTS = [
  {
    id: nanoid(),
    ...Prototype[COMPONENT_TYPE.SUBMIT_BUTTON]
  }
];
