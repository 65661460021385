import {Stack, styled} from '@mui/material';

export const SwitchWrapper = styled(Stack)(({theme}) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  height: `36px`,
  borderRadius: '4px',
  overflow: 'hidden'
}));

export const StackStyled = styled(Stack)(({theme}) => ({
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  minWidth: '48px',
  '& .inboxViewIcon': {
    display: 'inline-flex'
  },
  '& .inboxViewText': {
    opacity: 0,
    width: 0
  },
  '&.inboxSubViewGridActive': {
    backgroundColor: theme.palette.secondary.main,
    '& .inboxViewText': {
      opacity: 1,
      width: 'auto',
      color: theme.palette.common.white
    },
    '& .inboxViewIcon': {
      display: 'none'
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    '& .inboxViewText': {
      opacity: 1,
      width: 'auto',
      color: theme.palette.common.white
    },
    '& .inboxViewIcon': {
      display: 'none'
    }
  }
}));

