import React from 'react';
import {Link} from 'react-router-dom';
import {Box, Stack, Typography} from '@mui/material';

const CustomBreadcrumb = ({parentLocation, icon, currentLocation, parentUri = '#'}) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Link
        to={location => ({
          ...location,
          pathname: parentUri,
          search: '',
          state: {prevPath: window.location.pathname}
        })}
        style={{display: 'flex', alignItems: 'center', gap: '3px'}}
      >
        <Box component="span" sx={{display: 'inline-flex'}}>
          {icon}
        </Box>
        <Typography variant={'chipLight'} color="common.white">
          {parentLocation}
        </Typography>
      </Link>
      <Typography variant={'chipLight'} color="common.white">
        /
      </Typography>
      <Typography variant={'chipLight'} color="common.white">
        {currentLocation}
      </Typography>
    </Stack>
  );
};

export default CustomBreadcrumb;
