import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: ''
};

const mediaIconSlice = createSlice({
  name: 'mediaIcon',
  initialState: initialState,
  reducers: {
    getMediaIcons: state => {
      state.isLoading = true;
    },
    getMediaIconsSuccess: (state, action) => {
      const {total_count = 0, icons = []} = action.payload;
      state.data = icons.map(data => {
        const {icon_id, raster_sizes = []} = data || {};
        const imageUrl = raster_sizes[4]?.formats[0]?.preview_url;
        return {id: icon_id, imageUrl};
      })
      state.total = total_count;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    getMediaIconsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    }
  }
});

export const {getMediaIcons, getMediaIconsSuccess, getMediaIconsFailed} =
  mediaIconSlice.actions;

export default mediaIconSlice.reducer;
