import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import TemplateService from '../../../services/Template.service';
import {
  getMoreTemplatesFailed,
  getMoreTemplatesSuccess,
  getTemplatesFailed,
  getTemplatesSuccess,
  toggleTemplateFavouriteFailed,
  toggleTemplateFavouriteSuccess
} from './templatesSlice';

function* templatesWatcher() {
  yield takeEvery('templates/getTemplates', getTemplatesSaga);
  yield takeEvery('templates/getMoreTemplates', getMoreTemplatesSaga);
  yield takeEvery('templates/toggleTemplateFavourite', toggleTemplateFavouriteSaga);
}

function* getTemplatesSaga(action) {
  try {
    const response = yield call(TemplateService.getTemplateList, action.payload);

    if (response.success) {
      yield put(getTemplatesSuccess(response.data));
    } else {
      yield put(getTemplatesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* getMoreTemplatesSaga(action) {
  try {
    const response = yield call(TemplateService.getTemplateList, action.payload);

    if (response.success) {
      yield put(getMoreTemplatesSuccess(response.data));
    } else {
      yield put(getMoreTemplatesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* toggleTemplateFavouriteSaga(action) {
  try {
    const response = yield call(TemplateService.toggleTemplateFavourite, action.payload);

    if (response.success) {
      yield put(toggleTemplateFavouriteSuccess(action.payload));
    } else {
      yield put(toggleTemplateFavouriteFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* templatesSaga() {
  yield all([templatesWatcher()]);
}
