import React from 'react';

const ArrowDownIcon = ({fill="#949DB2"}) => {
  return (
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M5.5575 6.44238L9 9.87738L12.4425 6.44238L13.5 7.49988L9 11.9999L4.5 7.49988L5.5575 6.44238Z"
              fill={fill}
          />
      </svg>
  );
};

export default ArrowDownIcon;
