import React from 'react';
import CompanyAdvanced from './CompanyAdvanced';
import CompanyGeneral from './CompanyGeneral';
import CompanyOptions from './CompanyOptions';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const CompanyRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <CompanyGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <CompanyOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <CompanyAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default CompanyRightPanel;
