import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Search} from '@mui/icons-material';
import {InputAdornment} from '@mui/material';
import CoreTextField from '../../common/TextField/CoreTextField';
import {debounceHandler} from '../../helpers/utils/debounceHandler';
import {setTemplateSearchText} from '../../state/features/adminTemplates/adminTemplatesSlice';
import {selectTemplateFiltersSearch} from '../../state/features/adminTemplates/adminTemplatesSelector';

const TemplateSearch = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectTemplateFiltersSearch);
  const [search, setSearch] = useState(searchText);

  const doSearch = value => {
    setSearch(value);
    dispatch(setTemplateSearchText(value));
  };

  // debounce implement for search input change
  const handleSearch = debounceHandler(doSearch, 500);

  return (
    <CoreTextField
      fullWidth
      size={'small'}
      type={'search'}
      color={'secondary'}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{mr: 0}} position="start">
            <Search />
          </InputAdornment>
        )
      }}
      placeholder={'Search template'}
      defaultValue={search}
      onChange={e => handleSearch(e.target.value)}
    />

  );
};

export default TemplateSearch;
