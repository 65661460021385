import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import CoreButton from '../../common/Button/CoreButton';
import LoadingButton from '../../common/Button/LoadingButton';
import {registerPatternTemplateCategory} from '../../helpers/constant/registerPattern';
import {selectAdminTemplateCategories} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import {
  moveAdminSubCategoryToCategory,
  resetMoveAdminSubCategoryToCategorySuccess
} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';

const MoveSubCategoryContent = ({onClose, subCategoryInfo = {}, categoryId}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {handleSubmit, setValue, control} = useForm();
  const {data: categoryList, isMoving, isMoveSuccess} = useSelector(selectAdminTemplateCategories);

  useEffect(() => {
    if (categoryId) {
      setValue('categoryId', categoryId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (isMoveSuccess) {
      dispatch(resetMoveAdminSubCategoryToCategorySuccess());
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoveSuccess]);

  // ## Form Submit Action
  const onSubmit = data => {
    if (subCategoryInfo?.id) {
      dispatch(
        moveAdminSubCategoryToCategory({
          categoryId: data.categoryId,
          subCategoryId: subCategoryInfo.id
        })
      );
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, backgroundColor: theme.palette.background.paper, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'subtitle1'}>{`Move “${subCategoryInfo?.name}”`}</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            You are moving this sub-category to another category. All of the templates inside the
            sub-category will be moved as well.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="categoryId"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl error={invalid} fullWidth>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Select Category
                </Typography>
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  size={'small'}
                  inputProps={{'aria-label': 'Without label'}}
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {categoryList?.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && <FormHelperText error={invalid}>{error.message}</FormHelperText>}
              </FormControl>
            )}
            rules={registerPatternTemplateCategory.categoryId || null}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isMoving}
          loadingText={'Moving...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          Move
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default MoveSubCategoryContent;
