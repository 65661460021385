import React from 'react';
import {CircularProgress, Typography} from '@mui/material';
import Styles from './CustomLoader.module.scss';

const CustomLoader = ({text = 'Hold Tight! We’re getting the page ready'}) => {
  return (
    <div className={Styles.fpbLoader}>
      <div>
        <CircularProgress color="secondary" sx={{position: 'relative'}} />
        <Typography variant={'body1'} color={'text.primary'}>
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default CustomLoader;
