import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';
import CardComponentWrapper from '../Builder/FormComponents/CardComponentWrapper';
import CardViewPublicFooter from '../Builder/CardView/CardViewPublicFooter';
import CardViewPublicPageViewManager from './CardViewPublicPageViewManager';
import PublicPageSubmit from './PublicPageSubmit';
import useRulesIndex from '../../hooks/useRulesIndex';
import {AnimationHandler} from '../../common/AnimationHandler';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../../helpers/builderConstant/ComponentType';
import {matchCondition} from '../../helpers/utils/conditionalUtils/matchCondition';
import {selectConditionSettingState} from '../../state/features/conditionSetting/conditionSettingSelector';
import {FORM_TYPE} from '../../helpers/constant/CoreConstants';
import matchPageChangeConditions from '../../helpers/utils/conditionalUtils/matchPageChangeConditions';
import initialMatchCondition from '../../helpers/utils/conditionalUtils/initialMatchCondition';
import isCurrentPageHasSubmitButton from '../../helpers/utils/conditionalUtils/isCurrentPageHasSubmitButton';
import Styles from '../Builder/BuilderContent.module.scss';

const CardViewPublicPageContent = ({theme, editMode, className = ''}) => {
  const formMethods = useForm();
  const backStack = useRef([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [forceSubmit, setForceSubmit] = useState(false);

  const progressBarComponent = useRef(
    useSelector(state => {
      if (state.conditionSetting.rules.filter(rule => rule.disabled !== true).length !== 0) {
        return null;
      }
      let progressField = null,
        localFields = state.publicPage.fields ?? state.builderFields.fields;
      localFields.forEach(eachPage => {
        eachPage.pageItems.forEach(eachField => {
          if (eachField.component === COMPONENT_TYPE.PROGRESSBAR) {
            progressField = eachField;
          }
        });
      });
      return progressField;
    })
  );

  const fields = useRef(
    useSelector(state => {
      let allFields = [],
        localFields = state.publicPage.fields ?? state.builderFields.fields;
      localFields.forEach(eachPage => {
        eachPage.pageItems.forEach(eachField => {
          if (ComponentConfig[eachField.component].cardComponent && eachField.hidden !== true) {
            allFields.push({...eachField});
          }
        });
      });
      return allFields;
    })
  );

  const {rules} = useSelector(selectConditionSettingState);
  const {fieldsIdMap} = useRulesIndex(rules, fields.current, FORM_TYPE.CARD);

  useEffect(() => {
    initialMatchCondition(rules, fieldsIdMap, fields.current, FORM_TYPE.CARD);
    setForceSubmit(isCurrentPageHasSubmitButton(fields.current, selectedPage));
    formMethods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = data => {
    if (forceSubmit) {
      setSelectedPage(fields.current.length);
      return;
    }

    let nextPageByCondition = matchPageChangeConditions(rules, fieldsIdMap, [
      fields.current[selectedPage]
    ]);

    if (nextPageByCondition === selectedPage) {
      return;
    }

    backStack.current.push(selectedPage);

    setSelectedPage(page => {
      let nextPage = fields.current.length;

      for (let i = nextPageByCondition ?? page + 1; i < fields.current.length; i++) {
        if (fields.current[i].hidden !== true) {
          nextPage = i;
          break;
        }
      }

      return nextPageByCondition = nextPage;
    });

    setForceSubmit(isCurrentPageHasSubmitButton(fields.current, nextPageByCondition));
    formMethods.reset();
  };

  const handleBack = () => {
    formMethods.reset();

    let lastVisibleFieldIndex;
    for (let i = backStack.current.length - 1; i >= 0; i--) {
      if (fields.current[backStack.current[i]].hidden !== true) {
        lastVisibleFieldIndex = i;
        break;
      }
    }

    if (lastVisibleFieldIndex !== undefined) {
      backStack.current = backStack.current.slice(0, lastVisibleFieldIndex + 1);
      let backPage = backStack.current.pop();
      setForceSubmit(isCurrentPageHasSubmitButton(fields.current, backPage));
      setSelectedPage(backPage);
    }
  };

  const handleChangeValue = data => {
    fields.current[selectedPage] = {
      ...fields.current[selectedPage],
      ...data
    };

    handleConditionalLogic();
  };

  const handleConditionalLogic = () => {
    const fieldId = fields.current[selectedPage].id;
    if (fieldsIdMap[fieldId] && fieldsIdMap[fieldId].ruleIndexes.length > 0) {
      matchCondition(rules, fieldsIdMap, fieldId, fields.current, FORM_TYPE.CARD);
      setForceSubmit(isCurrentPageHasSubmitButton(fields.current, selectedPage));
    }
  };

  return (
    <>
      <Box className={`${Styles.fbpCardViewPreviewPage} ${className}`}>
        <FormProvider {...formMethods}>
          <Box
            noValidate
            component="form"
            onSubmit={formMethods.handleSubmit(handleSubmit)}
            sx={{width: '100%', height: '100%', position: 'relative'}}
          >
            <AnimationHandler animation={theme.animation} page={selectedPage}>
              <CardComponentWrapper
                preview
                className={fields.current.length <= selectedPage ? 'fbpCardThankYou' : ''}
              >
                {fields.current.length > selectedPage && (
                  <CardViewPublicPageViewManager
                    field={fields.current[selectedPage]}
                    pageNo={selectedPage + 1}
                    totalPage={fields.current.length}
                    onChange={handleChangeValue}
                  />
                )}

                {fields.current.length <= selectedPage && (
                  <PublicPageSubmit fields={fields.current} editMode={editMode} />
                )}
              </CardComponentWrapper>
            </AnimationHandler>

            {fields.current.length > selectedPage && (
              <CardViewPublicFooter
                theme={theme}
                onBack={handleBack}
                progressBar={progressBarComponent.current}
                totalPages={fields.current.length}
                currentPage={selectedPage}
                forceSubmit={forceSubmit}
              />
            )}
          </Box>
        </FormProvider>
      </Box>
    </>
  );
};

export default CardViewPublicPageContent;
