import React from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';
import ShowSideBarIcon from '../../common/Icons/ShowSideBarIcon';
import Styles from "./FormList.module.scss";

const BoxStyle = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  width: '100%',
  maxWidth: '100%',
  background: theme.palette.common.white,
  borderRadius: '4px',
  position: 'relative',
  transition: 'all 0.3s',
  '@media (min-width: 1300px)': {
    display: 'none !important'
  }
}));

const FbpTabFormsTopbar = ({onChangeLeftSideTab}) => {
  return (
    <BoxStyle>
      <Stack direction="row" alignItems="center" spacing={1}>
        <span
          style={{display: 'inline-flex', margin: 0, cursor: 'pointer'}}
          onClick={onChangeLeftSideTab}
        >
          <ShowSideBarIcon />
        </span>
        <Typography variant="body2" color="text.primary" className={Styles.fbpLeftSidebarTitle}>
          All Forms
        </Typography>
      </Stack>
    </BoxStyle>
  );
};

export default FbpTabFormsTopbar;
