import React from 'react';
import {Stack, Slider, Typography, useTheme} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';

const RightSideSlider = ({
  text,
  title,
  alert,
  value,
  onChangeValue,
  min = 100,
  max = 400,
  step = 1
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      {alert && (
        <Typography color="error.main" variant="tooltip">
          {alert}
        </Typography>
      )}
      <Slider
        step={step}
        min={min}
        max={max}
        color={'secondary'}
        value={value}
        onChange={e => onChangeValue(e.target.value)}
        aria-label="Default"
        valueLabelDisplay="auto"
        sx={{'& .MuiSlider-valueLabel': {backgroundColor: theme.palette.other.primaryDark}}}
      />
    </Stack>
  );
};

export default RightSideSlider;
