import React from 'react';
import {Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import CustomAccordion from '../../../common/Accordion/CustomAccordion';
import RightComponentTitle from './RightComponentTitle';
import Styles from './RightSideElements.module.scss';

const BuilderLayoutOptions = ({selected, onChange}) => {
  return (
    <Box className={Styles.BuilderLayoutOptions} sx={{padding: '8px'}}>
      <CustomAccordion
        summeryClass="noSpacing"
        heading={<RightComponentTitle text="Layout" title="Change the layout of the form" />}
        expanded
      >
        <Stack
          direction="row"
          sx={{
            width: '100%',
            flexWrap: 'wrap',
            gap: '16px',
            boxSizing: 'border-box'
          }}
        >
          <Box
            sx={{flex: '1 1 46%', cursor: 'pointer'}}
            className={`${Styles.fbpLayoutPositionSingleOption} ${
              selected === 'classic' ? Styles.active : ''
            } `}
            onClick={() => onChange('classic')}
          >
            <Box
              className={`${Styles.fbpLayoutPositionSingleOptionImg} ${Styles.fbpLayoutSingleOptImg}`}
            >
              <img src={process.env.REACT_APP_CDN_LINK + 'layouticon.png'} alt="layouticon" />
            </Box>
            <Typography variant={'caption'}>Classic Form</Typography>
          </Box>
          <Box
            sx={{flex: '1 1 46%', cursor: 'pointer'}}
            className={`${Styles.fbpLayoutPositionSingleOption} ${
              selected === 'card' ? Styles.active : ''
            }`}
            onClick={() => onChange('card')}
          >
            <Box
              className={`${Styles.fbpLayoutPositionSingleOptionImg} ${Styles.card} ${Styles.fbpLayoutSingleOptImg}`}
            >
              <img className={Styles.cardLayoutImg} src={process.env.REACT_APP_CDN_LINK +'cardLayout.png'} alt="layouticon" />
            </Box>
            <Typography variant={'caption'}>Card Form</Typography>
          </Box>
        </Stack>
      </CustomAccordion>
    </Box>
  );
};

export default BuilderLayoutOptions;
