import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import {selectFormFooterData} from '../../../state/features/formFooter/formFooterSelector';
import {EditedText, FooterLinks, FooterWrapper} from './FormFooterStyles';

const FooterInFormsPageItem = ({payload = {}}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <>
      <div onClick={handleOpenModal}>
        <Typography variant={'chipLight'} sx={{color: 'var(--fbp-theming-font-color)'}}>
          {payload.linkText}
        </Typography>
      </div>

      <BasicModalWithHeader
        modalTitle={payload.linkText}
        open={isOpenModal}
        onClose={handleCloseModal}
      >
        <Box p={3} sx={{overflow: 'auto', maxHeight: 'calc(60vh)'}}>
          <EditedText>
            <div dangerouslySetInnerHTML={{__html: payload.content || 'N/A'}} />
          </EditedText>
        </Box>
      </BasicModalWithHeader>
    </>
  );
};

const FooterInFormsPage = () => {
  const formFooterData = useSelector(selectFormFooterData);
  const {
    enable = false,
    privacyPolicy = {},
    termsOfUse = {},
    disclosure = {},
    licensingInformation = {},
    aboutUs = {},
    contactUs = {},
    footerBottomContent = {}
  } = formFooterData || {};

  if (!enable) return null;

  return (
    <FooterWrapper className={'form_footer_wrapper'}>
      <FooterLinks direction={'row'} alignItems={'center'} justifyContent={'center'}>
        {privacyPolicy?.enable && <FooterInFormsPageItem payload={privacyPolicy} />}
        {termsOfUse?.enable && <FooterInFormsPageItem payload={termsOfUse} />}
        {disclosure?.enable && <FooterInFormsPageItem payload={disclosure} />}
        {licensingInformation?.enable && <FooterInFormsPageItem payload={licensingInformation} />}
        {aboutUs?.enable && <FooterInFormsPageItem payload={aboutUs} />}
        {contactUs?.enable && <FooterInFormsPageItem payload={contactUs} />}
      </FooterLinks>

      {footerBottomContent?.enable && (
        <EditedText variant={'chipLight'} sx={{color: 'var(--fbp-theming-sub-text-color)'}}>
          <div dangerouslySetInnerHTML={{__html: footerBottomContent.content}} />
        </EditedText>
      )}
    </FooterWrapper>
  );
};

export default FooterInFormsPage;
