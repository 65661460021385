import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import CustomAccordion from '../../../common/Accordion/CustomAccordion';
import RightComponentTitle from './RightComponentTitle';
import Styles from './RightSideElements.module.scss';

const EachAnimation = ({title, value, selected, onChange, image}) => (
  <Box
    sx={{flex: '0 1 28%'}}
    className={`${Styles.fbpLayoutPositionSingleOption}  ${
      selected === value ? Styles.active : ''
    }`}
    onClick={() => onChange(value)}
  >
    <Box className={`${Styles.fbpLayoutPositionSingleOptionImg} `}>
      <img
        src={`https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.1/form_animation_${image}`}
        alt=""
        className="fbp-animation-image"
      />
    </Box>
    <Typography variant="caption" sx={{letterSpacing: '0.17px'}}>
      {title}
    </Typography>
  </Box>
);

const BuilderAnimation = props => {
  return (
    <Box className={Styles.BuilderAnimation} sx={{padding: '8px'}}>
      <CustomAccordion
        summeryClass="noSpacing"
        heading={<RightComponentTitle text="Animation" title="Change the page change animation" />}
        expanded
      >
        <Box sx={{width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
          <EachAnimation title="Default" value="default" image="default.jpg" {...props} />
          <EachAnimation title="Slide Left" value="slideLeft" image="slideleft.gif" {...props} />
          <EachAnimation title="Slide Right" value="slideRight" image="slideright.gif" {...props} />
          <EachAnimation title="Slide Up" value="slideUp" image="fadeup.gif" {...props} />
          <EachAnimation title="Slide Down" value="slideDown" image="fadedown.gif" {...props} />
        </Box>
      </CustomAccordion>
    </Box>
  );
};

export default BuilderAnimation;
