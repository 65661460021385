import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';
import InboxBodyContent from './InboxView/InboxBodyContent';
import {selectSelectedSubmissionData} from '../../state/features/formSubmission/formSubmissionSelector';

const SubmissionDetails = () => {
  const submissionData = useSelector(selectSelectedSubmissionData);

  return (
    <Box sx={{px: 3, py: 2}}>
      <InboxBodyContent submission={submissionData} />
    </Box>
  );
};

export default SubmissionDetails;
