import httpRequest from '../api/httpRequest';

class SubmissionService {
  getFormSubmissionData(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'submission/lists',
      body
    );
  }

  addTagOnSubmission(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/submission/add',
      body
    );
  }
  deleteSubmission(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'formsubmission/remove',
      null,
      body
    );
  }

  removeTagFromSubmission(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/submission/remove',
      null,
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SubmissionService();
