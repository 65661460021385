import React from 'react';
import {useDispatch} from 'react-redux';
import {updateBuilderField} from '../../state/features/builderFields/builderFieldsSlice';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';

const EachEditContentCardView = ({field, pageNo, totalPage}) => {
  const dispatch = useDispatch();

  const handleChangeValue = value => {
    dispatch(updateBuilderField({...field, ...value}));
  };

  const Component = ComponentConfig[field.component]?.cardComponent;

  if (Component) {
    return (
      <Component
        editMode
        field={field}
        onChange={handleChangeValue}
        pageNo={pageNo}
        totalPage={totalPage}
      />
    );
  }

  return '';
};

export default EachEditContentCardView;
