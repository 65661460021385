import {getCookie} from '../Cookie';

/**
 * Needle examples
 * agencyId
 */
export const getAccountData = needle => {
  let accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
  if (accountData) {
    accountData = JSON.parse(accountData);
    return accountData[needle];
  }
  return false;
};
