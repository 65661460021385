import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const CompanyAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Placeholder"
        title="Change the placeholder of the input"
        placeholder="Placeholder"
        value={field.placeholder}
        onChange={e => onChangeValue({placeholder: e.target.value})}
      />

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}

      <RightSideInput
        text="Pre-filled Value"
        title="Set a pre-filled value"
        placeholder="Default Value"
        value={field.value}
        onChange={e => onChangeValue({value: e.target.value})}
      />
      <RightSideSwitch
        text="Read Only"
        title="Set the input as read only"
        checked={field.readOnly}
        onChange={value => onChangeValue({readOnly: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(CompanyAdvanced);
