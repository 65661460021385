import httpRequest from '../api/httpRequest';

class MediaImageService {
  getMediaImages(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API_17 + 'pexel-images/search',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MediaImageService();
