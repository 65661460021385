import React from 'react';
import {Box} from '@mui/material';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';

const HiddenFieldRightPanel = ({field, onChangeValue}) => {
  return (
    <Box sx={{mt: 2}}>
      <RightSideInput
        text="Name"
        title="Change the hidden field name"
        value={field.name}
        placeholder="Name"
        onChange={e => onChangeValue({name: e.target.value})}
      />

      <RightSideInput
        text="Default Value"
        title="Change the hidden field default value"
        value={field.value}
        placeholder="Value"
        onChange={e => onChangeValue({value: e.target.value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(HiddenFieldRightPanel);
