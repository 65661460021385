import React from 'react';
import {Skeleton, Stack} from "@mui/material";


const FooterSkeleton = () => {
  return (
    <Stack direction={'row'} alignItems={"center"} justifyContent={'space-between'} p={3}>
      <Skeleton variant="text" sx={{fontSize: '1rem'}} width={200}/>
      <Skeleton variant="rounded" width={40} height={15} />
    </Stack>
  );
};

export default FooterSkeleton;
