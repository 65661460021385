import httpRequest from '../api/httpRequest';

class AdminAccessService {
  getAdminAccessData(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/access', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminAccessService();
