import {Stack, styled, Typography} from '@mui/material';

export const SwitchWrapper = styled(Stack)(({theme}) => ({
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: '4px',
  overflow: 'hidden'
}));

export const StyledText = styled(Typography)(({theme}) => ({
  cursor: 'pointer',
  height: '100%',
  width: '100%',
  color: theme.palette.text.secondary,
  padding: '4px 16px',
  borderRadius: 0,
  '&.activeDevice': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white
  }
}));
