import React from 'react';
import {Typography} from '@mui/material';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {
  changeSelectedBuilderField,
  reorderPage
} from '../../../state/features/builderFields/builderFieldsSlice';
import Styles from './BuilderPages.module.scss';

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return {
      ...style,
      top: style.top - 50
    };
  }

  return {
    ...style,
    transform: 'none',
    transitionDuration: `0.001s`
  };
}

const BuilderPages = () => {
  const dispatch = useDispatch();
  const {fields, selectedPageIndex} = useSelector(state => state.builderFields);

  const onDragEnd = result => {
    if (result.destination !== null && result.source.index !== result.destination.index) {
      dispatch(
        reorderPage({sourceIndex: result.source.index, destinationIndex: result.destination.index})
      );
    }
  };

  const handlePageFocusChange = (pageIndex, pageId) => {
    dispatch(
      changeSelectedBuilderField({
        pageIndex,
        index: 0
      })
    );
    document.getElementById('page-container-' + pageId)?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="pageDropable">
        {(provided, snapshot) => {
          return (
            <div className={Styles.fbpFormBuilderPages} ref={provided.innerRef}>
              {fields.map((eachPage, index) => (
                <Draggable key={index} draggableId={'' + eachPage.pageId + index} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={`${Styles.pageNumberList} ${
                        index === selectedPageIndex ? 'active' : ''
                      }`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                      onClick={() => handlePageFocusChange(index, eachPage.pageId)}
                    >
                      <Typography variant="button">
                        Page{' '}
                        {(index + 1).toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        })}
                      </Typography>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default BuilderPages;
