import {Button} from '@mui/material';
import {styled} from '@mui/material/styles';

const CoreButton = styled(Button, {
  shouldForwardProp: propName => propName !== 'ckgroundColor` '
})(({theme, backgroundColor}) => ({
  boxShadow: 'none',
  minWidth: 'unset',
  '&.MuiButton-contained:focus': {
    backgroundColor: `${backgroundColor ? backgroundColor : theme.palette.primary.main} !important`
  },
  '&.MuiButton-containedSecondary:focus': {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  '&.MuiButton-containedSuccess:focus': {
    backgroundColor: `${theme.palette.success.main} !important`
  },
  '&.MuiButton-containedError:focus': {
    backgroundColor: `${theme.palette.error.main} !important`
  },
  '&.MuiButton-containedWarning:focus': {
    backgroundColor: `${theme.palette.warning.main} !important`
  },
  '&.MuiButton-outlined:focus': {
    backgroundColor: `${backgroundColor ? backgroundColor : 'unset'} !important`
  },
  '&.MuiButton-text:focus': {
    backgroundColor: 'unset'
  },

  '&.MuiButton-root:hover': {
    boxShadow: 'none'
  },
  '&.MuiButton-sizeSmall': {
    fontSize: '13px',
    lineHeight: '22px',
    padding: '4px 10px'
  },
  '&.MuiButton-sizeMedium': {
    fontSize: '14px',
    lineHeight: '24px',
    padding: '6px 16px'
  },
  '&.MuiButton-sizeLarge': {
    padding: '8px 24px'
  },
  '&.MuiButton-sizeSmall .MuiSvgIcon-root': {
    fontSize: '18px'
  },
  '&.MuiButton-sizeMedium .MuiSvgIcon-root': {
    fontSize: '20px'
  },
  '&.MuiButton-sizeLarge .MuiSvgIcon-root': {
    fontSize: '24px'
  },
  '& .MuiCircularProgress-root': {
    position: 'unset'
  }
}));

export default CoreButton;
