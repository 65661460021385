import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import {Divider, Fade, IconButton, Stack, Typography} from '@mui/material';
import CoreButton from '../Button/CoreButton';
import {Close} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import Styles from './styles/fbp-global-central-modal.module.scss';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  }
}));

const centralStyle = {
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 10
};

export default function GlobalCentralModal({
  open,
  icon,
  title,
  buttonText,
  actionColor='success',
  onClose,
  size,
  className,
  stopBackDrop,
  extra_props_active,
  children,
  hideFooter,
  cancelButtonText,
  onSubmit,
  bodyClass = ''
}) {
  const classes = useStyles();

  const closeModal = () => {
    onClose(false);
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit();
  };

  let extra_props = {};
  if (extra_props_active !== undefined) {
    extra_props['disableEnforceFocus'] = true;
    extra_props['disableAutoFocus'] = true;
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal + ' ' + className}
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000
        }}
        onBackdropClick={stopBackDrop !== undefined ? () => {} : closeModal}
        modalprops={{
          disableEnforceFocus: true,
          disableAutoFocus: true
        }}
        {...extra_props}
      >
        <Fade in={open} direction="left">
          <div
            className={`${Styles.fbpGlobalCentralModal} ${
              size === 'medium'
                ? Styles.fbpGlobalCentralModalMedium
                : size === 'small'
                ? Styles.fbpGlobalCentralModalSmall
                : 'large'
            } ${className}`}
            style={centralStyle}
          >
            <div className={Styles.fbpGlobalCentralModalContainer}>
              <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{padding: '24px 24px 16px 24px'}}
                className={Styles.centralModalHeader}
              >
                <span style={{display: 'inline-flex'}}>{icon ?? ''}</span>
                <Typography variant="h6" color="text.primary" sx={{marginRight: 'auto'}}>
                  {title ?? 'Title'}
                </Typography>
                <IconButton color={'error'} onClick={onClose}>
                  <Close fontSize={'medium'} />
                </IconButton>
              </Stack>
              <Divider className={Styles.topModalHr} />
              <div className={`${Styles.fbpGlobalCentralModalBody}  ${bodyClass}`}>{children}</div>
              {hideFooter !== true && (
                <div className={Styles.fbpGlobalCentralModalFooter}>
                  <Divider />
                  <Stack
                    direction={'row'}
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    p={3}
                    pb={2}
                  >
                    <CoreButton size="medium" onClick={closeModal} sx={{color: 'text.primary'}}>
                      {cancelButtonText || 'Cancel'}
                    </CoreButton>
                    <CoreButton variant="contained" color={actionColor} onClick={handleSubmit}>
                      {buttonText ?? 'Save'}
                    </CoreButton>
                  </Stack>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
