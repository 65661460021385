import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme
} from '@mui/material';
import TableLoaderSkeleton from '../../LoadingView/TableLoaderSkeleton';
import GlobalTablePagination from '../../../common/Pagination/GlobalTablePagination';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
import DeskTopIcon from '../../../common/Icons/DeskTopIcon';
import {getFormStatisticsList} from '../../../state/features/formStatistics/formStatisticSlice';
import MobileIcon from '../../../common/Icons/MobileIcon';
import TabIcon from '../../../common/Icons/TabIcon';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import {getTextCapitalized} from '../../../helpers/utils/getTextCapitalized';

const headCells = [
  {label: 'Recent Visitor'},
  {label: 'Date'},
  {label: 'Total'},
  // {label: 'Location'},
  {align: 'center', label: 'Device'}
];

// Table Head
function FilesTableHead() {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: theme.palette?.other.outlinedBorder}}>
        {headCells.map(headCell => (
          <TableCell key={headCell.label} padding={'normal'} align={headCell.align}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TrafficTable() {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const {isLoadingList, listData, selectedListFilter} = useSelector(state => state.formStatistics);

  // ## Pagination
  const onChangePerPage = (perPage, newPage) => {
    setLimit(perPage);
    dispatch(
      getFormStatisticsList({
        page: newPage,
        limit: perPage,
        tab: 'traffic'
      })
    );
  };

  const onChangePage = newPage => {
    dispatch(
      getFormStatisticsList({
        page: newPage,
        tab: 'traffic',
        limit
      })
    );
  };

  if (isLoadingList || !listData.content) {
    return (
      <Box sx={{width: '100%'}} p={3}>
        <TableLoaderSkeleton numberOfRows={2} columns={5} />
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%'}} pt={2}>
      <Paper sx={{width: '100%'}} elevation={0}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'medium'}>
            <FilesTableHead />
            <TableBody>
              {listData.content.map(eachData => (
                <TableRow>
                  <TableCell align="left">{eachData.ip}</TableCell>
                  <TableCell align="left">{eachData.createdAt}</TableCell>
                  <TableCell align="left">
                    {eachData.totalValue}
                    {selectedListFilter === 'CONVERSIONRATE' && '%'}
                  </TableCell>
                  {/*<TableCell align="left">{eachData.country}</TableCell>*/}
                  {/* <TableCell align="left">{eachData.device}</TableCell> */}
                  <TableCell align="center">
                    <CustomTooltip
                      arrow
                      placement="top"
                      title={getTextCapitalized(eachData.device)}
                    >
                      <span>
                        {eachData.device === 'mobile' ? (
                          <MobileIcon fill="#000" />
                        ) : eachData.device === 'tablet' ? (
                          <TabIcon fill="#000" />
                        ) : (
                          <DeskTopIcon fill="#000" />
                        )}
                      </span>
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {listData.content.length === 0 && (
            <div>
              <GlobalEmptyPage
                icon={<EmptySubmissionFormIcon />}
                title={'No data found'}
                description={'No corresponding published form traffic data found'}
              />
            </div>
          )}
        </TableContainer>
        <Box pt={2}>
          <GlobalTablePagination
            count={listData.count}
            page={listData.currentPage}
            rowsPerPage={limit}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
            rowsPerPageOptions={[10, 20, 30, 50]}
          />
        </Box>
      </Paper>
    </Box>
  );
}
