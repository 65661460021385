import React from 'react';
import FormListFilterHeader from './FormListFilterHeader';
import FormListView from './FormListView';

const FormListBody = () => {
  return (
    <>
      <FormListFilterHeader />
      <FormListView />
    </>
  );
};

export default FormListBody;
