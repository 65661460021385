import React from 'react';
import {Typography, Box, styled} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoText = styled(Typography)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  marginTop: '12px',
  background: theme.palette.action.actionHover,
  padding: '8px',
  borderRadius: '4px'
}));

const BasicTitleBar = ({
  title = '',
  content = '',
  titleVariant = 'subtitle1',
  contentVariant = 'body2',
  info = '',
  padding = 3,
  fontWeight
}) => {
  return (
    <Box p={padding}>
      <Typography variant={titleVariant}>{title}</Typography>
      {content ? (
        <Typography variant={contentVariant} color="text.secondary" sx={{fontWeight: fontWeight}}>
          {content}
        </Typography>
      ) : (
        ''
      )}
      {info ? (
        <InfoText variant={contentVariant} color="action.active">
          <InfoIcon fontSize={'small'} />
          {info}
        </InfoText>
      ) : (
        ''
      )}
    </Box>
  );
};

export default BasicTitleBar;
