import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import AdminTemplateCategoryTab from './AdminTemplateCategory/AdminTemplateCategoryTab';
import ShowSideBarIcon from '../../common/Icons/ShowSideBarIcon';
import HideSideBarIcon from '../../common/Icons/HideSideBarIcon';
import Styles from './AdminTemplate.module.scss';

const TemplateLeftSidebar = ({isHideLeftSide, toggleLeftSide}) => {
  return (
    <Box className={Styles.fbpLeftSidebar}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        className={Styles.fbpLeftSideTopbar}
      >
        <Typography
          variant="buttonMedium"
          color="text.secondary"
          className={Styles.fbpLeftSidebarTitle}
        >
          My Templates
        </Typography>
        <Box
          className={`${Styles.fbpToggleSidebar} ${Styles.active}`}
          onClick={toggleLeftSide}
          sx={{cursor: 'pointer'}}
        >
          {isHideLeftSide ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </Box>
      </Stack>
      <Box sx={{opacity: `${isHideLeftSide ? 0 : 1}`}}>
        <AdminTemplateCategoryTab />
      </Box>
    </Box>
  );
};

export default TemplateLeftSidebar;
