import React from 'react';
import {Box, Divider, Stack, Typography, useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Clear} from '@mui/icons-material';
import EmbedModeOptions from './EmbedModeOptions';
import EmbedCopyCode from './EmbedCopyCode';
import EmbedPreview from './EmbedPreview';
import EmbedSettings from './EmbedSettings';

const AdvanceEmbedContent = ({onClose}) => {
  const theme = useTheme();

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white',
          zIndex: 9,
          borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`
        }}
      >
        <Typography variant={'h6'}>Advance Embed Share</Typography>

        <Stack direction={'row'} alignItems="center" spacing={1}>
          <IconButton onClick={onClose} color={'error'}>
            <Clear />
          </IconButton>
        </Stack>
      </Stack>

      <Box >
        <Stack direction={'row'} sx={{height: 'calc(100vh - 113px)'}}>
          <Box p={3}
            sx={{
              bgcolor: 'other.dividerFillColor',
              width: '100%',
              maxWidth: '325px',
              height: '100%',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {display: 'none'}
            }}
          >
            <EmbedModeOptions />
            <Divider light />
            <EmbedSettings />
            <EmbedCopyCode />
          </Box>
          <Box p={3} sx={{width: '100%', flex: 1}}>
            <EmbedPreview />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default AdvanceEmbedContent;
