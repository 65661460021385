import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton, ListItemButton, Menu, MenuItem, styled, Typography, Box} from '@mui/material';
import {Delete, Edit, ExpandLess, ExpandMore, MoreVert} from '@mui/icons-material';
import AdminTemplateSubCategoryItem from './AdminTemplateSubCategoryItem';
import AddOrEditTemplateCategory from './AddOrEditTemplateCategory';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import CountChip from '../../../common/Chip/CountChip';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import {
  deleteAdminTemplateCategory,
  setSelectedAdminCategory
} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {selectAdminTemplateCategoryId} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';

const StyledBox = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 24,
  width: 40,
  marginLeft: 'auto',
  marginRight: '8px'
}));
const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
  paddingTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  borderRadius: '4px',
  '&:hover .countChip': {
    display: 'none'
  },
  '&:hover .MuiIconButton-root': {
    display: 'inline-flex',
    padding: '0 !important'
  }
}));

const AdminTemplateCategoryItem = ({categoryItem}) => {
  const dispatch = useDispatch();

  const selectedCategoryId = useSelector(selectAdminTemplateCategoryId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenCategoryModal, setOpenCategoryModal] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpenMenu = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClickCloseMenu = () => setAnchorEl(null);

  const handleClickCategory = categoryId => {
    dispatch(setSelectedAdminCategory(selectedCategoryId === categoryId ? '' : categoryId));
  };

  // handle edit category
  const toggleOpenCategoryModal = () => setOpenCategoryModal(prevState => !prevState);
  const handleEditCategory = data => {
    toggleOpenCategoryModal();
    setCategoryInfo(data);
  };

  // handle Delete Category
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDeleteCategory = () => {
    handleOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    if (categoryItem?.id) {
      dispatch(deleteAdminTemplateCategory({id: categoryItem.id}));
    }
    handleCloseDeleteModal();
  };

  const openMenu = Boolean(anchorEl);
  return (
    <>
      <ListItemButtonStyled
        key={categoryItem.id}
        sx={{pl: 1, pr: 0}}
        selected={openMenu}
        onClick={() => handleClickCategory(categoryItem.id)}
      >
        {categoryItem.id === selectedCategoryId ? (
          <ExpandLess color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
        ) : (
          <ExpandMore color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
        )}
        <Typography
          variant="buttonMedium"
          className="text-truncate"
          title={categoryItem.categoryName}
        >
          {categoryItem.categoryName}
        </Typography>

        <StyledBox
          id="template-category-id"
          aria-controls={openMenu ? 'template-category-area' : undefined}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickOpenMenu}
        >
          <CountChip className={'countChip'} label={categoryItem.count} />
          <IconButton sx={{display: 'none'}} size={'small'}>
            <MoreVert />
          </IconButton>
        </StyledBox>
      </ListItemButtonStyled>

      {categoryItem.subCategory &&
        categoryItem.subCategory?.length > 0 &&
        categoryItem.subCategory.map(subCategory => (
          <AdminTemplateSubCategoryItem
            key={subCategory.id}
            open={categoryItem.id === selectedCategoryId}
            subCategory={subCategory}
            categoryId={categoryItem.id}
          />
        ))}

      <Menu
        id="template-category-area"
        MenuListProps={{
          'aria-labelledby': 'template-category-id'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleClickCloseMenu}
      >
        <MenuItem onClick={() => handleEditCategory(categoryItem)}>
          <Edit sx={{mr: 1}} size={'small'} />
          <Typography variant={'body2'}>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteCategory}>
          <Delete sx={{mr: 1}} size={'small'} />
          <Typography variant={'body2'}>Delete</Typography>
        </MenuItem>
      </Menu>

      <BasicModalWithHeader
        titleIcon={<Edit />}
        modalTitle={'Update Category'}
        open={isOpenCategoryModal}
        onClose={toggleOpenCategoryModal}
      >
        <AddOrEditTemplateCategory
          onClose={toggleOpenCategoryModal}
          isEditMode={true}
          categoryInfo={categoryInfo}
        />
      </BasicModalWithHeader>

      <DeleteWarningModal
        warningSubtitle={'Are you sure!, want to delete this Category?'}
        warningContent={'After delete, this operation cannot be undone.'}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default AdminTemplateCategoryItem;
