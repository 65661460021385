import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import {
  getCampaignDataFailed,
  getCampaignDataSuccess,
  getMoreCampaignDataFailed,
  getMoreCampaignDataSuccess,
  inboxSubmissionAddToCampaignFailed,
  inboxSubmissionAddToCampaignSuccess
} from './campaignSlice';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import CampaignService from '../../../services/Campaign.service';

function* campaignWatcher() {
  yield takeLatest('campaign/getCampaignData', getCampaignDataSaga);
  yield takeLatest('campaign/getMoreCampaignData', getMoreCampaignDataSaga);
  yield takeLatest('campaign/inboxSubmissionAddToCampaign', inboxSubmissionAddToCampaignSaga);
}

function* getCampaignDataSaga(action) {
  try {
    const response = yield call(CampaignService.getCampaignData, action.payload);

    if (response.success) {
      yield put(getCampaignDataSuccess({data: response.data}));
    } else {
      yield put(getCampaignDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* getMoreCampaignDataSaga(action) {
  try {
    const response = yield call(CampaignService.getCampaignData, action.payload);

    if (response.success) {
      yield put(
        getMoreCampaignDataSuccess({
          data: response.data.data,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: 10
        })
      );
    } else {
      yield put(getMoreCampaignDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* inboxSubmissionAddToCampaignSaga(action) {
  try {
    const response = yield call(CampaignService.inboxSubmissionAddToCampaign, action.payload);

    if (response.success) {
      yield put(inboxSubmissionAddToCampaignSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(inboxSubmissionAddToCampaignFailed(response.data));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* campaignSaga() {
  yield all([campaignWatcher()]);
}
