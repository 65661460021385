import {Box, styled} from "@mui/material";

const ImageBox = styled(Box)(({theme}) => ({
    width: '100%',
    height: '230px',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
}));

export default ImageBox;