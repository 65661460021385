import React, {useState} from 'react';
import {Box} from '@mui/material';
import InboxBody from './InboxBody';
import InboxLeftSide from './InboxLeftSide';
import {CloseButton, InboxViewBox} from './InboxViewStyles';

const InboxView = () => {
  const [close, setClose] = useState(false);

  return (
    <>
      <InboxViewBox>
        <InboxLeftSide />
        <Box className={`inboxBodyWrapper ${close ? 'inboxViewTab' : ''}`}>
          <Box sx={{width: '40px'}} className={'inboxCloseButton'}>
            <CloseButton variant={'contained'} onClick={() => setClose(!close)}>
              {close ? 'Expend' : 'Close'}
            </CloseButton>
          </Box>
          <Box
            className="inboxBodyContent"
          >
            <InboxBody />
          </Box>
        </Box>
      </InboxViewBox>
    </>
  );
};

export default InboxView;
