import {FORM_TEMPLATE_CATEGORY_TYPE} from '../../../helpers/constant/formConstant';

export const selectAdminTemplateCategories = state => state.adminTemplateCategories;
export const selectAdminTemplateCategoriesRenderId = state =>
  state.adminTemplateCategories.renderId;
export const selectAdminTemplateCategoryType = state => state.adminTemplateCategories.categoryType;
export const selectAdminTemplateCategoryId = state =>
  state.adminTemplateCategories.selectedCategoryId;

export const selectTemplateCategoriesTypeWise = (state, type) => {
  if (type === FORM_TEMPLATE_CATEGORY_TYPE.INDUSTRIES) {
    return state.templateCategories.data.filter(
      category => category.categoryType === FORM_TEMPLATE_CATEGORY_TYPE.INDUSTRIES
    );
  }
  return state.adminTemplateCategories.data;
};

export const selectSelectedCategory = state => state.adminTemplateCategories.selectedCategory;
export const selectSelectedSubCategory = state => state.adminTemplateCategories.selectedSubCategory;
