import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Stack} from '@mui/material';
import Box from '@mui/material/Box';
import TagCard from './TagCard';
import AddTagColorBox from './AddTagColorBox';
import CoreButton from '../../../common/Button/CoreButton';
import CustomTextField from '../../../common/TextField/CustomTextField';
import {selectTags} from '../../../state/features/tags/tagsSelector';
import {addTag} from '../../../state/features/tags/tagsSlice';
import {DEFAULT_TAG_COLOR} from '../../../helpers/constant/formSubmissionConstant';
import Styles from './AddTag.module.scss';

const AddTag = () => {
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const [inputValue, setInputValue] = useState('');
  const [color, setColor] = useState(DEFAULT_TAG_COLOR);

  const handleSearchOrAdd = event => {
    setInputValue(event.target.value);
  };

  const onChangeColor = color => {
    setColor(color);
  };

  const handleAddNewTag = () => {
    dispatch(addTag({name: inputValue, color: color}));
    setInputValue('');
    setColor(DEFAULT_TAG_COLOR);
  };

  const tagList = tags.filter(tagItem => {
    if (inputValue.trim() !== '') {
      return tagItem.name.toLowerCase().search(inputValue.toLowerCase().trim()) === 0;
    }
    return true;
  });

  const isReadyToCreate = value => {
    let result = false;
    if (value.trim() !== '') {
      result = !tags.some(tag => tag.name.toLowerCase() === value.trim().toLowerCase());
    }
    return result;
  };

  return (
    <Box className={Styles.addTagWrapper}>
      <Box className={Styles.addTagInputBox}>
        <CustomTextField
          type={'search'}
          placeholder="Search or Add tag"
          size="small"
          value={inputValue}
          onChange={handleSearchOrAdd}
        />
      </Box>

      {tagList?.length > 0 ? (
        <ul className={`${Styles.addAllTagsWrp} fbp_has_scroll`}>
          {tagList.map(tag => (
            <TagCard key={tag.id} tag={tag} />
          ))}
        </ul>
      ) : (
        tagList?.length === 0 &&
        inputValue && <AddTagColorBox color={color} onChange={onChangeColor} />
      )}

      <Stack>
        <CoreButton
          size={'small'}
          variant={'contained'}
          color={'primary'}
          disabled={!isReadyToCreate(inputValue)}
          onClick={handleAddNewTag}
        >
          Create tag: {inputValue}
        </CoreButton>
      </Stack>
    </Box>
  );
};
export default AddTag;
