import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideInlineCheckbox from '../../Builder/RightComponents/RightSideInlineCheckbox';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';

const AddressOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  const [totalSelected, setTotalSelected] = useState(0);

  useEffect(() => {
    setTotalSelected(
      field.streetAddressEnabled +
        field.streetAddress2Enabled +
        field.cityEnabled +
        field.stateEnabled +
        field.zipEnabled
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeVisibility = (key, value) => {
    if (value || (!value && totalSelected !== 1)) {
      setTotalSelected(prev => prev + (value ? 1 : -1));
      onChangeValue({[key]: value});
    }
  };

  return (
    <>
      <Stack direction="column" sx={{gap: '10px'}} className="builderElementBasicStyle">
        <RightComponentTitle
          text="Visible Options"
          title="Visible options on public page"
          className="mb-3"
        />
        <RightSideInlineCheckbox
          label="Street Address 1"
          checked={field.streetAddressEnabled}
          onChange={value => handleChangeVisibility('streetAddressEnabled', value)}
        />
        <RightSideInlineCheckbox
          label="Street Address 2"
          checked={field.streetAddress2Enabled}
          onChange={value => handleChangeVisibility('streetAddress2Enabled', value)}
        />
        <RightSideInlineCheckbox
          label="City"
          checked={field.cityEnabled}
          onChange={value => handleChangeVisibility('cityEnabled', value)}
        />
        <RightSideInlineCheckbox
          label="State / Province"
          checked={field.stateEnabled}
          onChange={value => handleChangeVisibility('stateEnabled', value)}
        />
        <RightSideInlineCheckbox
          label="Postal / Zip Code"
          checked={field.zipEnabled}
          onChange={value => handleChangeVisibility('zipEnabled', value)}
        />

        {totalSelected === 1 && (
          <Typography variant="helperText" color="error.main">
            Minimum 1 field must be visible.
          </Typography>
        )}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </>
  );
};

export default withFieldPropertyChange(AddressOptions);
