import React from 'react';
import {Box, IconButton, Stack, styled, Typography} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import RoundedCrossIcon from '../../../common/Icons/RoundedCrossIcon';
import {getFileSizeInString} from '../../../helpers/utils/getFileSizeInString';

const EachFileBox = styled(Stack)(({theme}) => ({
  width: '100%',
  gap: '8px',
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.other.secondaryHover
  }
}));
const ImageBox = styled(Box)(({theme}) => ({
  width: '30px',
  height: '30px',
  borderRadius: '4px',
  overflow: 'hidden',
  display: 'grid',
  placeItems: 'center',
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}));

const FilePreview = ({files, onRemove}) => {
  const renderIcon = file => {
    if (file.type.match(/image/g)) {
      return <img src={URL.createObjectURL(file)} alt="" />;
    } else if (file.type.match(/video/g)) {
      return <VideoLibraryIcon color={'action'} />;
    } else if (file.type.match(/audio/g)) {
      return <LibraryMusicIcon color={'action'} />;
    } else if (file.type.match(/pdf/g)) {
      return <PictureAsPdfIcon color={'action'} />;
    } else {
      return <LibraryBooksIcon color={'action'} />;
    }
  };

  return (
    <>
      <Stack spacing={1}>
        {files.map((file, index) => (
          <EachFileBox p={1} key={index} direction="row" alignItems="center">
            <ImageBox>{renderIcon(file)}</ImageBox>
            <Box sx={{width: 'calc(100% - 80px)'}}>
              <Typography variant="body2" className="text-truncate" title={file.name}>
                {file.name}
              </Typography>
              <Typography variant="caption" color={"text.secondary"}>{getFileSizeInString(file.size)}</Typography>
            </Box>
            <IconButton color={'secondary'} onClick={() => onRemove(index)} sx={{ml: 'auto'}}>
              <RoundedCrossIcon fontSize="small" />
            </IconButton>
          </EachFileBox>
        ))}
      </Stack>
    </>
  );
};

export default FilePreview;
