import httpRequest, {customHttpRequest} from '../api/httpRequest';

class PublicPageService {
  submitPublicPage(params) {
    return customHttpRequest.post(process.env.REACT_APP_CLOUD_SUBMIT_URL, params);
  }

  uploadFile(params) {
    return customHttpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'files/upload',
      params.files,
      {Authorization: `Bearer ${params.apiKey}`, agencyId: params.agencyId}
    );
  }

  getFileDetails(url) {
    return customHttpRequest.get(url);
  }

  getTemplateDetails(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'public/template/render/' + params
    );
  }

  submitFormAnalytics(params) {
    return customHttpRequest.post(process.env.REACT_APP_CLOUD_VIEW_ANALYTICS_URL, params);
  }

  getS3SignedUrls(params) {
    return customHttpRequest.post(process.env.REACT_APP_CLOUD_S3_PRESIGNED, params);
  }

  uploadIndividualS3Files(url, file, headers = {}) {
    return customHttpRequest.put(url, file, headers);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PublicPageService();
