import React, {useState} from 'react';
import {Typography, useTheme} from '@mui/material';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import Box from '@mui/material/Box';
import CoreButton from '../../../common/Button/CoreButton';
import compressString from '../../../helpers/utils/compressString';
import {selectSelectedSubmissionData} from '../../../state/features/formSubmission/formSubmissionSelector';
import {useSelector} from 'react-redux';

const FormSubmissionConversationItem = ({inboxInfo}) => {
  const theme = useTheme();
  const [showAll, setShowAll] = useState(false);
  const {from = '', to = [], content = ''} = inboxInfo || {};

  const toggleShowAll = () => setShowAll(prevState => !prevState);

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.other.outlinedBorder}`,
        borderRadius: '4px',
        padding: '16px',
        margin: '28px 0 0 0',
        gap: '10px'
      }}
    >
      <Box sx={{display: 'flex'}}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant={'subtitle2'} sx={{margin: '0 0 6px', wordBreak: 'break-word'}}>
            User Name
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', gap: ' 8px', padding: '0 0 16px 0'}}>
            <span style={{display: 'inline-flex'}}>{fbpIcons.fbpEmailIcon}</span>
            <Typography variant="caption">Reply</Typography>
          </Box>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Typography variant="subtitle2" sx={{margin: '0 0 6px', wordBreak: 'break-word'}}>
            From: {from}
          </Typography>
          <Typography variant="caption" sx={{paddingBottom: '16px'}}>
            To: {to?.length > 0 && to.map(toEmail => toEmail + ', ')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="caption">{compressString(content, 60, showAll)}</Typography>
        <CoreButton onClick={toggleShowAll} variant={'text'} size={'small'} color={'secondary'}>
          Show More
        </CoreButton>
      </Box>
    </Box>
  );
};

const FormSubmissionConversation = () => {
  const {inboxReply = []} = useSelector(selectSelectedSubmissionData) || {};

  let content = null;
  if (inboxReply && inboxReply?.length > 0) {
    content = inboxReply.map((inboxInfo, index) => (
      <FormSubmissionConversationItem key={index} inboxInfo={inboxInfo} />
    ));
  } else {
    content = '';
  }

  return content;
};

export default FormSubmissionConversation;
