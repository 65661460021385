import React from 'react';
import { useSelector } from 'react-redux';
import EmbedAnythingAdvanced from './EmbedAnythingAdvanced';
import EmbedAnythingGeneral from './EmbedAnythingGeneral';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import EmbedAnythingOptions from './EmbedAnythingOptions';

const EmbedAnythingRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <EmbedAnythingGeneral field={field} />
      </CustomTab>
      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <EmbedAnythingOptions field={field} />
        </CustomTab>
      )}
      <CustomTab title="Advanced">
        <EmbedAnythingAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default EmbedAnythingRightPanel;
