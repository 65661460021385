import React from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import withThemeChange from '../../../hoc/withThemeChange';
import RightSideUploadButtonComponent from '../../Builder/RightComponents/RightSideUploadButtonComponent';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';
import RightSideSlider from '../../Builder/RightComponents/RightSideSlider';
import RightSideColorPicker from '../../Builder/RightComponents/RightSideColorPicker';
import CoreButton from '../../../common/Button/CoreButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {DEFAULT_IMAGE_SETTINGS} from '../../../helpers/builderConstant/DefaultTheme';

const ProfileImageRightPanel = ({theme, onChangeValue}) => {
  const dispatch = useDispatch();

  const handleEditOptionOpen = componentType => {
    dispatch(changeSelectedBuilderField({index: null, pageIndex: null, componentType}));
  };

  return (
    <Box sx={{mt: 2}}>
      <Box px={2} pb={2}>
        <CoreButton
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleEditOptionOpen(COMPONENT_TYPE.THEME)}
        >
          Back
        </CoreButton>
      </Box>

      <RightSideUploadButtonComponent
        text="Select Profile Image"
        title="The profile image will be visible on top of the form."
        buttonText="Choose Image"
        recommendedSize={'720 x 720'}
        onUpload={image => onChangeValue({profileImage: image})}
      />
      {theme.profileImage && (
        <>
          <RightSideImagePreviewComponent
            text="Profile Image"
            title="Profile Image Preview"
            image={theme.profileImage}
            onRemove={() =>
              onChangeValue({profileImage: null, ...DEFAULT_IMAGE_SETTINGS.profileImage})
            }
          />

          <RightSideColorPicker
            text="Background"
            title="Change the background color and opacity of the profile image background"
            color={theme.profileImageBackground ?? '#fff'}
            onChange={color => onChangeValue({profileImageBackground: color})}
            className={'builderElementBasicStyle'}
          />

          <RightSideSlider
            step={0.1}
            min={0.5}
            max={5}
            text="Zoom In/Out"
            title="Zoom in or zoom out the image"
            value={theme.scaleProfileImage ?? 1}
            onChangeValue={value => onChangeValue({scaleProfileImage: value})}
          />

          <RightSideSlider
            min={0}
            max={50}
            text="Radius"
            title="Change the border radius of the image"
            value={theme.radiusProfileImage ?? 50}
            onChangeValue={value => onChangeValue({radiusProfileImage: value})}
          />

          <RightSideSlider
            min={-100}
            max={100}
            text="Horizontal adjustment"
            title="Change the image adjustment left to right"
            value={theme.profileImageTranslateX ?? 0}
            onChangeValue={value => onChangeValue({profileImageTranslateX: value})}
          />

          <RightSideSlider
            min={-100}
            max={100}
            text="Vertical adjustment"
            title="Change the image adjustment top to bottom"
            value={theme.profileImageTranslateY ?? 0}
            onChangeValue={value => onChangeValue({profileImageTranslateY: value})}
          />

          <Box p={2} sx={{textAlign: 'end'}}>
            <CoreButton
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              startIcon={<RestartAltIcon />}
              onClick={() => onChangeValue({...DEFAULT_IMAGE_SETTINGS.profileImage})}
            >
              Reset Settings
            </CoreButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withThemeChange(ProfileImageRightPanel);
