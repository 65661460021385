export const getDeviceType = () => {
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
    window.navigator.userAgent.toLowerCase().indexOf('mobile') !== -1
  ) {
    return 'mobile';
  }

  if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      navigator.userAgent.toLowerCase()
    )
  ) {
    return 'tablet';
  }

  return 'desktop';
};
