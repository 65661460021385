import React from 'react';
import {Typography, Stack} from '@mui/material';
import CustomTextField from '../../../common/TextField/CustomTextField';

const RightsideInlineInput = ({
  label,
  value = '',
  inputType = 'text',
  onChange = null,
  placeholder = ''
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <Typography variant={'body2medium'} sx={{width: `45%`}} mb={1}>
        {label}
      </Typography>
      <CustomTextField
        size="medium"
        labelPosition="top"
        type={inputType}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
};

export default RightsideInlineInput;
