import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {List, ListItemButton, Box, styled, Typography} from '@mui/material';
import {Drafts, ListAlt} from '@mui/icons-material';
import TrashIcon from '../../../common/Icons/TrashIcon';
import AddNewTemplateCategory from './AddNewTemplateCategory';
import {FORM_STATUS} from '../../../helpers/constant/formConstant';
import {
  setAdminTemplateStatus,
  setTemplateSubCategoryId
} from '../../../state/features/adminTemplates/adminTemplatesSlice';
import {setSelectedAdminSubCategory} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {selectTemplateFilters} from '../../../state/features/adminTemplates/adminTemplatesSelector';
import Styles from '../AdminTemplate.module.scss';

const CustomHr = styled('hr')(({theme}) => ({
  border: 'none',
  height: '1px',
  background: theme.palette.other.outlinedBorder
}));

const AdminCategoryListWrapper = ({children}) => {
  const dispatch = useDispatch();
  const {subcategoryId, status} = useSelector(selectTemplateFilters);

  const handleSelectAllTemplate = () => {
    dispatch(setTemplateSubCategoryId(''));
    dispatch(setSelectedAdminSubCategory({id: '', name: '', count: 0}));
  };

  const handleSelectDraft = () => {
    dispatch(setAdminTemplateStatus(FORM_STATUS.PAUSED));
    dispatch(setSelectedAdminSubCategory({id: '', name: '', count: 0}));
  };

  const handleSelectTrash = () => {
    dispatch(setAdminTemplateStatus(FORM_STATUS.TRASH));
    dispatch(setSelectedAdminSubCategory({id: '', name: '', count: 0}));
  };

  return (
    <>
      <Box className={Styles.AdminCatagoryWrp}>
        <AddNewTemplateCategory />

        <List sx={{width: '100%', maxWidth: 360}}>
          <ListItemButton
            sx={{p:1, mb: 0.5, borderRadius: '4px'}}
            selected={subcategoryId === '' && status === null}
            onClick={handleSelectAllTemplate}
          >
            <ListAlt color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
            <Typography variant="buttonMedium" className="text-truncate" title={'All Templates'}>
              All Templates
            </Typography>
          </ListItemButton>

          {/* ## category list children ##*/}
          {children}
          <CustomHr />

          <ListItemButton
              sx={{p:1, mb: 0.5, borderRadius: '4px'}}
            selected={status === FORM_STATUS.PAUSED}
            onClick={handleSelectDraft}
          >
            <Drafts color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
            <Typography variant="buttonMedium" color={'text.secondary'}>
              Draft
            </Typography>
          </ListItemButton>
          <ListItemButton
              sx={{p:1, mb: 0.5, borderRadius: '4px'}}
            selected={status === FORM_STATUS.TRASH}
            onClick={handleSelectTrash}
          >
            <TrashIcon color={'#949DB2'} fontSize={'small'} sx={{mr: 0.5}} />
            <Typography variant="buttonMedium" color={'text.secondary'}>
              Trash
            </Typography>
          </ListItemButton>
        </List>
      </Box>
    </>
  );
};

export default AdminCategoryListWrapper;
