import React from 'react';
import {SvgIcon} from '@mui/material';

const CrossIcon = ({fill, ...props}) => {
  return (
    <SvgIcon {...props} width="21" height="20" viewBox="0 0 21 20">
      <path
        d="M16.0837 5.34167L14.9087 4.16667L10.2503 8.825L5.59199 4.16667L4.41699 5.34167L9.07533 10L4.41699 14.6583L5.59199 15.8333L10.2503 11.175L14.9087 15.8333L16.0837 14.6583L11.4253 10L16.0837 5.34167Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

export default CrossIcon;
