import React from 'react';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import FormLabelAlignment from '../../Builder/RightComponents/FormLabelAlignment';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';
import Box from '@mui/material/Box';

const DateGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Field Label"
        title="Change the label of the input"
        value={field.label}
        onChange={e => onChangeValue({label: e.target.value})}
      />

      {theme.layout === 'card' && (
        <RightSideInput
          text="Description"
          title="Change the description of the field"
          placeholder="Description"
          value={field.description}
          onChange={e => onChangeValue({description: e.target.value})}
        />
      )}

      {theme.layout === 'classic' && (
        <FormLabelAlignment
          hasSetAsDefault
          labelAlignment={field.labelAlignment}
          onChange={value => onChangeValue({labelAlignment: value})}
          shrinkMode={field.shrink}
        />
      )}

      <ThreeTabsWithTitle
        text="Separator"
        title="Separator only available on default view"
        child1="/"
        child2="-"
        child3="."
        value1="/"
        value2="-"
        value3="."
        selectedValue={field.separator}
        onChange={value => onChangeValue({separator: value})}
      />

      <RightSideSwitch
        text="Required Field"
        title="Set the field required on submission"
        checked={field.required}
        onChange={value => onChangeValue({required: value})}
      />

      <RightSideInput
        text="Helper Text"
        title="Change the text below input"
        value={field.helperText}
        placeholder="Helper Text"
        onChange={e => onChangeValue({helperText: e.target.value})}
      />

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(DateGeneral);
