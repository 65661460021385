import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Delete} from '@mui/icons-material';
import CoreButton from '../../../common/Button/CoreButton';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import useBoolean from '../../../hooks/useBoolean';
import {
  deleteCondition,
  deselectAllCondition,
  disableCondition,
  selectedAllCondition,
  selectSingleConditionId
} from '../../../state/features/conditionSetting/conditionSettingSlice';
import {
  selectConditionSettingState,
  selectHasDisabledEnableInsideSelectedCondition
} from '../../../state/features/conditionSetting/conditionSettingSelector';
import {castArray} from '../../../helpers/utils/castArray';

const ConditionListBulkAction = () => {
  const dispatch = useDispatch();
  const {rules = [], selectedConditionIds} = useSelector(selectConditionSettingState);
  const {hasDisabled, hasEnabled} = useSelector(selectHasDisabledEnableInsideSelectedCondition);

  const {
    value: isOpenDeleteModal,
    setTrue: handleOpenDeleteModal,
    setFalse: handleCloseDeleteModal
  } = useBoolean();

  const totalConditionSelected = selectedConditionIds?.length;
  const checkedAll = rules.every(singleRule => selectedConditionIds.includes(singleRule.id));
  const indeterminate = !checkedAll && totalConditionSelected > 0;

  const handleSelectAll = () => {
    dispatch(selectedAllCondition());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllCondition());
  };

  const onConfirmDelete = () => {
    dispatch(deleteCondition(castArray(selectedConditionIds)));
    handleCloseDeleteModal();
  };

  const handleEnableCondition = () => {
    dispatch(disableCondition({ids: castArray(selectedConditionIds), disabled: false}));
    dispatch(selectSingleConditionId(''));
  };
  const handleDisableCondition = () => {
    dispatch(disableCondition({ids: castArray(selectedConditionIds), disabled: true}));
    dispatch(selectSingleConditionId(''));
  };

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={2}
      sx={{mt: 3}}
    >
      <FormControlLabel
        label="Select All"
        control={
          <Checkbox
            checked={checkedAll}
            indeterminate={indeterminate}
            onChange={checkedAll || indeterminate ? handleDeselectAll : handleSelectAll}
          />
        }
      />

      {(checkedAll || indeterminate) && (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
          <Typography variant={'body1'} color={'text.secondary'}>
            {totalConditionSelected} Selected
          </Typography>

          {hasEnabled && (
            <CoreButton
              variant="contained"
              color="error"
              startIcon={<PauseIcon />}
              onClick={handleDisableCondition}
            >
              Disable
            </CoreButton>
          )}

          {hasDisabled && (
            <CoreButton
              variant="contained"
              color="success"
              startIcon={<PlayArrowIcon />}
              onClick={handleEnableCondition}
            >
              Enable
            </CoreButton>
          )}

          <CoreButton
            variant="outlined"
            color="error"
            startIcon={<Delete />}
            onClick={handleOpenDeleteModal}
          >
            Delete
          </CoreButton>
        </Stack>
      )}

      <DeleteWarningModal
        title="Delete Condition"
        warningSubtitle={'Are you sure you want to delete selected condition(s)?'}
        warningContent={`Selected ${totalConditionSelected} condition${
          totalConditionSelected > 1 ? 's' : ''
        } will be deleted permanently.This action cannot be undone.`}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </Stack>
  );
};

export default ConditionListBulkAction;
