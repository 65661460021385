import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
  addFormFolderFailed,
  addFormFolderSuccess,
  deleteFormFolderFailed,
  deleteFormFolderSuccess,
  getFormFoldersFailed,
  getFormFoldersSuccess,
  updateFormFolderColorFailed,
  updateFormFolderColorSuccess,
  updateFormFolderNameFailed,
  updateFormFolderNameSuccess
} from './formFolderSlice';
import FolderService from '../../../services/Folder.service';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import {reFetchFolderForms} from '../folderForms/folderFormsSlice';

function* formFolderWatcher() {
  yield takeEvery('formFolder/getFormFolders', getFormFoldersSaga);
  yield takeEvery('formFolder/addFormFolder', addFormFolderSaga);
  yield takeEvery('formFolder/deleteFormFolder', deleteFormFolderSaga);
  yield takeEvery('formFolder/updateFormFolderName', updateFormFolderNameSaga);
  yield takeEvery('formFolder/updateFormFolderColor', updateFormFolderColorSaga);
}

function* getFormFoldersSaga(action) {
  try {
    const response = yield call(FolderService.getFolders, action.payload);

    if (response.success) {
      yield put(getFormFoldersSuccess(response.data));
    } else {
      yield put(getFormFoldersFailed(response.message));
    }
  } catch (err) {
    yield put(getFormFoldersFailed('Network or server error'));
  }
}

function* addFormFolderSaga(action) {
  try {
    const response = yield call(FolderService.addFolder, action.payload);

    if (response.success) {
      yield put(addFormFolderSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(addFormFolderFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteFormFolderSaga(action) {
  try {
    const response = yield call(FolderService.deleteFolder, action.payload);

    if (response.success) {
      yield put(deleteFormFolderSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
      yield put(reFetchFolderForms());
    } else {
      yield put(deleteFormFolderFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormFolderNameSaga(action) {
  try {
    const response = yield call(FolderService.updateFolder, action.payload);

    if (response.success) {
      yield put(updateFormFolderNameSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
      yield put(reFetchFolderForms());
    } else {
      yield put(updateFormFolderNameFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormFolderColorSaga(action) {
  try {
    const response = yield call(FolderService.updateFolder, action.payload);

    if (response.success) {
      yield put(updateFormFolderColorSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
      yield put(reFetchFolderForms());
    } else {
      yield put(updateFormFolderColorFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formFolderSaga() {
  yield all([formFolderWatcher()]);
}
