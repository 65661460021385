import React from 'react';
import {SvgIcon} from '@mui/material';

const ColorDropIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.5 22C8.364 22 5 18.634 5 14.496C5 10.513 11.737 2.547 12.024 2.209C12.26 1.93 12.739 1.93 12.976 2.209C13.262 2.547 20 10.513 20 14.496C20 18.634 16.636 22 12.5 22Z"
        fill="#FFDCC3"
      />
    </SvgIcon>
  );
};

export default ColorDropIcon;
