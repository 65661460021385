import React from 'react';
import {Stack} from '@mui/material';
import QuickShareLink from './QuickShareLink';
import QuickShareSocialMedia from './QuickShareSocialMedia';

const QuickShare = () => {
  return (
      <Stack direction="column" sx={{gap: '16px'}}>
        <QuickShareLink />

        {/*<Box>*/}
        {/*  <CustomTitleBar title="QR Code">*/}
        {/*    <Box className={Styles.fbpPublicGenerateWr}>*/}
        {/*      <Box*/}
        {/*        sx={{display: 'flex', alignItems: 'center'}}*/}
        {/*        className={Styles.fbpPublicGenerateTop}*/}
        {/*      >*/}
        {/*        <img src={process.env.REACT_APP_CDN_LINK + 'qr-code.png'} />*/}
        {/*        <Box className={Styles.fbpPublicGenerateQrInnerRight}>*/}
        {/*          <Typography variant="body2medium" className={Styles.fbpPublicGenerateQrTitle}>*/}
        {/*            To embed the QR code on your website, copy the following HTML code.*/}
        {/*          </Typography>*/}
        {/*          <input*/}
        {/*            className={Styles.fbpPublicGenerateQrLink}*/}
        {/*            readOnly*/}
        {/*            value={`<a href="https://form.jotform.com/222121044186040" rel="no-follow"><img src="https://www.jotform.com...`}*/}
        {/*          />*/}
        {/*        </Box>*/}
        {/*      </Box>*/}
        {/*      <Stack*/}
        {/*        direction="row"*/}
        {/*        sx={{marginTop: '3px', gap: '20px'}}*/}
        {/*        alignItems="center"*/}
        {/*        justifyContent="space-between"*/}
        {/*      >*/}
        {/*        <CoreButton size="small" variant="contained" color="secondary">*/}
        {/*          Download*/}
        {/*        </CoreButton>*/}
        {/*        <CoreButton size="small" color="secondary">*/}
        {/*          Copy code*/}
        {/*        </CoreButton>*/}
        {/*      </Stack>*/}
        {/*    </Box>*/}
        {/*  </CustomTitleBar>*/}
        {/*</Box>*/}

        {/* share social media */}
        <QuickShareSocialMedia />
      </Stack>
  );
};

export default QuickShare;
