import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useBoolean from '../../../hooks/useBoolean';
import {Box, Stack, Typography} from '@mui/material';
import {Favorite, FavoriteBorder} from '@mui/icons-material';
import FormTemplatePreviewContent from './FormTemplatePreviewContent';
import {StyledBox, StyledTemplateCard} from './FormTemplateCardStyles';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import FullPageDrawer from '../../../common/Drawer/FullPageDrawer';
import CoreButton from '../../../common/Button/CoreButton';
import CustomLoader from '../../../common/CustomLoader/CustomLoader';
import TemplateService from '../../../services/Template.service';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons.js';
import {castArray} from '../../../helpers/utils/castArray';
import {FORM_TEMPLATE_TYPE} from '../../../helpers/constant/formConstant';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbarSlice';
import {toggleTemplateFavourite} from '../../../state/features/templates/templatesSlice';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';

const FormTemplateCard = ({template}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {id, formTitle, formType, fileUrl, isFavourite} = template || {};
  const {value: openPreview, setTrue: onOpenPreview, setFalse: onClosePreview} = useBoolean(false);
  const [loading, setLoading] = useState(false);

  const handleToggleFavorite = () => {
    dispatch(toggleTemplateFavourite({ids: castArray(id), favourite: !isFavourite}));
  };

  const handleUseTemplate = () => {
    setLoading(true);

    TemplateService.templateUse({templateId: id})
      .then(response => {
        if (response.success) {
          const {formId} = response.data || {};
          history.push(`/fbuilder/form/${formId}`);
        } else {
          dispatch(showErrorSnackbar(response.message));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleViewTemplate = () => {
    onOpenPreview();
  };

  if (loading) return <CustomLoader />;

  return (
    <>
      <StyledTemplateCard elevation={2}>
        <Box>
          <StyledBox>
            <img
              src={fileUrl || process.env.REACT_APP_CDN_LINK + 'defaultTemplateImg.png'}
              alt="Template"
              onClick={handleViewTemplate}
            />
            <span onClick={handleToggleFavorite}>
              {isFavourite ? (
                <Favorite style={{color: 'rgba(242, 153, 74, 1)'}} />
              ) : (
                <FavoriteBorder color={'action'} />
              )}
            </span>
          </StyledBox>
          <Box p={2}>
            <Typography variant="body1medium" onClick={handleViewTemplate}>
              {formTitle}
            </Typography>
            <Typography
              variant="body2"
              color="action.active"
              sx={{fontSize: '13px', lineHeight: '18px'}}
            >
              {formType === FORM_TEMPLATE_TYPE.CLASSIC ? 'Classic ' : 'Card '} Type
            </Typography>
          </Box>
        </Box>

        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
          <CoreButton
            variant="outlined"
            color="secondary"
            onClick={handleUseTemplate}
            sx={{padding: '6px !important'}}
          >
            Use Template
          </CoreButton>
          <CustomTooltip title={'Preview'} arrow placement="top">
            <LightCoreButton onClick={handleViewTemplate}>{fbpIcons.fbpEyeIcon}</LightCoreButton>
          </CustomTooltip>
        </Stack>
      </StyledTemplateCard>

      <FullPageDrawer open={openPreview} onClose={onClosePreview} zIndex={'1300'}>
        <FormTemplatePreviewContent
          templateInfo={template}
          onClose={onClosePreview}
          handleUseTemplate={handleUseTemplate}
        />
      </FullPageDrawer>
    </>
  );
};

export default FormTemplateCard;
