import httpRequest from '../api/httpRequest';

class FormReportService {
  getFormReportData(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'report/stats',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormReportService();
