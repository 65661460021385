import httpRequest from '../api/httpRequest';

class TemplateService {
  getTemplateList(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'template/user/list',
      body
    );
  }

  toggleTemplateFavourite(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/favourite', body);
  }

  templateUse(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/use', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TemplateService();
