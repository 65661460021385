import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Box, ButtonGroup, Typography} from '@mui/material';
import {SkipNext, SkipPrevious} from '@mui/icons-material';
import CoreButton from '../../common/Button/CoreButton';
import {getSubmissionFormNameAndEmail} from '../../helpers/utils/submissionFormUtils';
import {selectFormSubmission} from '../../state/features/formSubmission/formSubmissionSlice';
import {
  selectBackSubmissionData,
  selectNextSubmissionData,
  selectSelectedSubmissionData
} from '../../state/features/formSubmission/formSubmissionSelector';

const SubMissionDetailsHeader = () => {
  const dispatch = useDispatch();
  const submissionFormData = useSelector(selectSelectedSubmissionData);
  const selectNextSubmissionInfo = useSelector(selectNextSubmissionData);
  const selectBackSubmissionInfo = useSelector(selectBackSubmissionData);

  const {submission: {submission: submissionList = []} = {}, updatedAt} = submissionFormData || {};
  const {name, email} = getSubmissionFormNameAndEmail(submissionList);

  const handleBackClick = () => {
    dispatch(selectFormSubmission(selectBackSubmissionInfo));
  };
  const handleForwardClick = () => {
    dispatch(selectFormSubmission(selectNextSubmissionInfo));
  };

  const title = name || email;
  const formatUpdatedAt = updatedAt ? format(new Date(updatedAt), 'dd MMM yyyy') : '';
  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <ButtonGroup variant="text" sx={{mr: 2}}>
        <CoreButton
          disabled={!selectBackSubmissionInfo}
          startIcon={<SkipPrevious />}
          onClick={handleBackClick}
        />
        <CoreButton
          disabled={!selectNextSubmissionInfo}
          endIcon={<SkipNext />}
          onClick={handleForwardClick}
        />
      </ButtonGroup>
      <Box>
        <Typography variant={'h5'}>{title}</Typography>
        <Typography variant={'body1'} color={'text.secondary'}>
          Updated at {formatUpdatedAt}
        </Typography>
      </Box>
    </Box>
  );
};

export default SubMissionDetailsHeader;
