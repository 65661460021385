import React from 'react';
import RadioAdvanced from './RadioAdvanced';
import RadioGeneral from './RadioGeneral';
import RadioOptions from './RadioOptions';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const RadioRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <RadioGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <RadioOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <RadioAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default RadioRightPanel;
