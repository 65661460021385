import React from 'react';
import {Alert} from '@mui/material';
import BasicSliderStepper from './BasicSliderStepper';
import DashStepper from './DashStepper';
import AdvancedSliderStepper from './AdvancedSliderStepper';
import NumberStepper from './NumberStepper';

const ProgressBarComponent = ({editMode, totalFields = 6, totalDone = 3, field}) => {
  const getPercentage = () => {
    if (totalFields === 0 && totalDone === 0) {
      return 100;
    }
    return (totalDone / totalFields) * 100;
  };

  if (!field) {
    return '';
  }

  return (
    <>
      {field.view === '1' && (
        <BasicSliderStepper
          percentage={getPercentage()}
          color={field.color}
          backgroundColor={field.backgroundColor}
        />
      )}

      {field.view === '2' && (
        <DashStepper color={field.color} totalSteps={totalFields} totalDone={totalDone} />
      )}

      {field.view === '3' && (
        <AdvancedSliderStepper
          percentage={getPercentage()}
          color={field.color}
          backgroundColor={field.backgroundColor}
          totalSteps={totalFields}
          totalDone={totalDone}
        />
      )}

      {field.view === '4' && (
        <NumberStepper
          color={field.color}
          totalSteps={totalFields}
          totalDone={totalDone}
          backgroundColor={field.backgroundColor}
        />
      )}

      {editMode && (
        <Alert severity="warning" sx={{marginTop: '20px'}}>
          The progress bar will appear at the top of your form.
        </Alert>
      )}
    </>
  );
};

export default ProgressBarComponent;
