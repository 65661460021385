import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Stack, Typography} from '@mui/material';
import {FOLDER_DEFAULT_COLOR} from '../../helpers/constant/formFolderContant';
import FolderIcon from '../../common/Icons/FolderIcon';
import {selectFormFilters} from '../../state/features/folderForms/folderFormSelector';
import {selectFormFolder} from '../../state/features/folderForms/folderFormsSlice';
import Styles from './FormList.module.scss';

const FolderItemAll = () => {
  const dispatch = useDispatch();
  const {folderId, favourite, archived, trash} = useSelector(selectFormFilters);

  const handleSelectFormFolder = fId => {
    dispatch(selectFormFolder(fId));
  };

  // if folderId empty and all status of folder is not true
  let isAllFolderActive = false;
  if (folderId === '' && !(favourite || archived || trash)) {
    isAllFolderActive = true;
  }
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      spacing={1}
      className={`${isAllFolderActive && Styles.active} ${Styles.sidebarListTitle}`}
      onClick={() => handleSelectFormFolder('')}
    >
      <FolderIcon fontSize={'small'} sx={{color: FOLDER_DEFAULT_COLOR}} />

      <Typography variant={'buttonMedium'} color="text.primary">
        All Forms
      </Typography>
    </Stack>
  );
};

export default FolderItemAll;
