import React from 'react';
import CheckboxAdvanced from './CheckboxAdvanced';
import CheckboxGeneral from './CheckboxGeneral';
import CheckboxOptions from './CheckboxOptions';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const CheckboxRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <CheckboxGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <CheckboxOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <CheckboxAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default CheckboxRightPanel;
