import React from 'react';
import {Box, styled, Typography} from '@mui/material';

const StyledBoxTab = styled(Box)(({theme}) => ({
  width: ' 34%',
  height: '36px',
  background: theme.palette.other.gray,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    path: {
      fill: theme.palette.text.secondary
    }
  },
  '&.selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.common.white
      }
    }
  },
  '&.hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.common.white
      }
    }
  }
}));

const ThreeTabs = ({child1, child2, child3, value1, value2, value3, selectedValue, onChange}) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '4px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <StyledBoxTab
        onClick={() => onChange(value1)}
        className={value1 === selectedValue ? 'selected' : ''}
      >
        <Typography variant="body2medium" sx={{display: 'inline-flex'}}>
          {child1}
        </Typography>
      </StyledBoxTab>
      <StyledBoxTab
        onClick={() => onChange(value2)}
        className={value2 === selectedValue ? 'selected' : ''}
      >
        <Typography variant="body2medium" sx={{display: 'inline-flex'}}>
          {child2}
        </Typography>
      </StyledBoxTab>
      <StyledBoxTab
        onClick={() => onChange(value3)}
        className={value3 === selectedValue ? 'selected' : ''}
      >
        <Typography variant="body2medium" sx={{display: 'inline-flex'}}>
          {child3}
        </Typography>
      </StyledBoxTab>
    </Box>
  );
};

export default ThreeTabs;
