import React from 'react';
import {Box, Divider, Paper, Typography} from '@mui/material';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import ThankYouPageDesign from './ThankYouPageDesign';

const ThankYouDefault = () => {
  return (
    <Paper elevation={0}>
      <BasicTitleBar
        title={'Default Thank You Message'}
        titleVariant={'body2medium'}
        padding={1.5}
      />
      <Divider />

      <Box p={1.5}>
        <Typography variant={'body2'} color={'text.primary'}>
          The message below will appear when someone completes your form.
        </Typography>

        <ThankYouPageDesign padding={'25px 0'} />
      </Box>
    </Paper>
  );
};

export default ThankYouDefault;
