import React from 'react';
import {useSelector} from 'react-redux';
import {Stack, Typography} from '@mui/material';
import PublicPageService from '../../../services/PublicPage.service';
import {useWindowSize} from '../../../hooks/useWindowSize';
import {castArray} from '../../../helpers/utils/castArray';
import compressString from '../../../helpers/utils/compressString';
import middleCompressString from '../../../helpers/utils/middleCompressString';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {S3_SIGNED_URL_ACTION} from '../../../helpers/constant/formSubmissionConstant';
import {selectSubmissionApiKey} from '../../../state/features/formSubmission/formSubmissionSelector';

const TableCellViewBySubmissionType = ({column, agencyId}) => {
  const {type, value} = column || {};
  const {width} = useWindowSize();
  const formApiKey = useSelector(selectSubmissionApiKey);

  const smallScreenSize = width < 1200;
  const textLength = smallScreenSize ? 15 : 30;

  let content = undefined;
  switch (type) {
    case COMPONENT_TYPE.FULL_NAME: {
      const {prefix, firstName, middleName, lastName} = column;
      let values = [prefix, firstName, middleName, lastName];
      values = values.filter(value => !!value);
      values = values?.length > 0 && values.join(' ');
      content = <span title={values || ''}>{compressString(values || '-', textLength)}</span>;
      break;
    }

    case COMPONENT_TYPE.ADDRESS: {
      const {streetAddress1 = '', streetAddress2 = '', city, state, zip} = column;
      let values = [streetAddress1, streetAddress2, city, state, zip];
      values = values.filter(value => !!value);
      values = values?.length > 0 && values.join(', ');
      content = <span title={values || ''}>{compressString(values || '-', textLength)}</span>;
      break;
    }

    case COMPONENT_TYPE.CHECKBOX: {
      content = <span title={value || ''}>{compressString(value || '-', textLength)}</span>;
      break;
    }

    case COMPONENT_TYPE.DROPDOWN: {
      content = <span title={value || ''}>{compressString(value || '-', textLength)}</span>;
      break;
    }
    case COMPONENT_TYPE.LINK: {
      content = (
        <a href={value} target={'_blank'} rel={'noreferrer'} title={value}>
          {compressString(value || '-', textLength)}
        </a>
      );
      break;
    }
    case COMPONENT_TYPE.FILE_UPLOAD: {
      const {files} = column;

      const onClickFile = fileId => {
        PublicPageService.getS3SignedUrls({
          fileId: castArray(fileId),
          action: S3_SIGNED_URL_ACTION.VIEW,
          formApiKey: formApiKey,
          agencyId: Number(agencyId)
        }).then(response => {
          if (response.status) {
            const bucketUrl = response.bucketUrl ? JSON.parse(response.bucketUrl) : [];
            bucketUrl.forEach(file => {
              window.open(file.bucketUrl, '_blank');
            });
          }
        });
      };

      content = (
        <Stack direction={'column'} spacing={1}>
          {files?.slice(0, 2).map((file, i) => (
            <Typography
              key={i}
              variant={'body1'}
              color={'secondary'}
              onClick={() => onClickFile(file.id)}
              sx={{cursor: 'pointer'}}
              title={file.name}
            >
              {middleCompressString(file.name, 20, Math.floor(20 / 2))}
            </Typography>
          ))}
        </Stack>
      );

      break;
    }

    default:
      content = <span title={value || ''}>{compressString(value || '-', textLength)}</span>;
  }

  return content;
};

export default TableCellViewBySubmissionType;
