import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@mui/material';
import cogoToast from 'cogo-toast';
import {clearSnackbar} from '../../state/features/snackbar/snackbarSlice';
import {selectSnackbarState} from '../../state/features/snackbar/snackbarSelector';

const ErrorSnackbar = () => {
  const dispatch = useDispatch();
  const {isErrorSnackbarOpen, errorMessage} = useSelector(selectSnackbarState);

  useEffect(() => {
    if (isErrorSnackbarOpen) {
      cogoToast.error(
        <Box>
          <Typography variant={'body1'}>{errorMessage}</Typography>
        </Box>,
        {position: 'bottom-right', heading: 'Error'}
      );
      dispatch(clearSnackbar());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorSnackbarOpen]);

  return <div />;
};

export default ErrorSnackbar;
