import React from 'react';
import {Divider, Paper, Stack, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReportTimeTabs from './ReportTimeTabs';
import ButtonForIcon from '../../common/Button/ButtonForIcon';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import RouteLink from '../../common/Link/RouteLink';
import CoreButton from '../../common/Button/CoreButton';

const ReportHeader = () => {
  const width = window?.innerWidth;

  return (
    <Paper elevation={0} sx={{width: '100%'}}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
        p={2}
      >
        <div>
          <Typography variant={'h5'} color={'text.primary'} sx={{width: 'max-content'}}>
            Forms Report
          </Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            All of your forms usage and report overview will be Shown here.
          </Typography>
        </div>
        <RouteLink to={`/fbuilder/admin`}>
          {width < 600 ? (
            <CustomTooltip title="Back to Admin" arrow placement="top">
              <ButtonForIcon startIcon={<ArrowBackIcon />}></ButtonForIcon>
            </CustomTooltip>
          ) : (
            <CoreButton variant={'outlined'} color={'secondary'} startIcon={<ArrowBackIcon />}>
              Back to Admin Dashboard
            </CoreButton>
          )}
        </RouteLink>
      </Stack>
      <Divider light />
      <Stack direction={'row'} alignItems={'center'} spacing={2} p={2} pt={1}>
        <ReportTimeTabs />
      </Stack>
    </Paper>
  );
};

export default ReportHeader;
