import httpRequest from '../api/httpRequest';

class FormStatisticsService {
  getFormStatistics(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'formviewstat/analytics',
      params
    );
  }

  getTrafficData(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'formviewstat/traffic',
      params
    );
  }

  getDeviceData(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'formviewstat/device',
      params
    );
  }

  getPlatformData(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'formviewstat/platform',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormStatisticsService();
