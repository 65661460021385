import React, {useState} from 'react';
import {Box, Icon, Stack, styled, Typography} from '@mui/material';
import ButtonDesignModal from '../../EachComponent/theme/buttonDesign/ButtonDesignModal';
import RightComponentTitle from './RightComponentTitle';
import ExtraSmallButton from '../../../common/Button/ExtraSmallButton';
import CoreButton from '../../../common/Button/CoreButton';

const ButtonsWrapper = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.other.dividerFillColor,
  borderRadius: theme.spacing(0.5)
}));

const NextButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: '100px',
  background: buttontheme.nextButton.background,
  color: buttontheme.nextButton.color,
  borderRadius: buttontheme.nextButton.borderRadius,
  border: `1px solid ${buttontheme.nextButton.border}`,
  '&:hover': {
    background: buttontheme.nextButton.background,
  }
}));
const BackButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: '100px',
  background: `${buttontheme.backButton.background} !important`,
  color: buttontheme.backButton.color,
  borderRadius: buttontheme.backButton.borderRadius,
  border: `1px solid ${buttontheme.backButton.border}`,
  '&:hover': {
    border: `1px solid ${buttontheme.backButton.border}`,
    background: buttontheme.backButton.background,
  }
}));
const SubmitButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: '100px',
  background: buttontheme.submitButton.background,
  color: buttontheme.submitButton.color,
  borderRadius: buttontheme.submitButton.borderRadius,
  border: `1px solid ${buttontheme.submitButton.border}`,
  '&:hover': {
    background: buttontheme.submitButton.background,
  }
}));

const ButtonStyles = ({text, title, theme, onChange}) => {
  const [isOpenNextModal, setIsOpenNextModal] = useState(false);
  const [isOpenBackModal, setIsOpenBackModal] = useState(false);
  const [isOpenSubmitModal, setIsOpenSubmitModal] = useState(false);

  const handleChangeNextButton = obj => {
    onChange({nextButton: obj});
    setIsOpenNextModal(false);
  };

  const handleChangeBackButton = obj => {
    onChange({backButton: obj});
    setIsOpenBackModal(false);
  };

  const handleChangeSubmitButton = obj => {
    onChange({submitButton: obj});
    setIsOpenSubmitModal(false);
  };

  return (
    <Box className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <ButtonsWrapper spacing={1.5} pl={1} pr={0.5} py={1.5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2medium">Next Button</Typography>
          <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} spacing={0.5}>
            <NextButton
              buttontheme={theme}
              size={'small'}
              variant={'contained'}
              backgroundColor={theme.nextButton.background}
              endIcon={theme.nextButton.iconVisible && <Icon>{theme.nextButton.icon}</Icon>}
            >
              Next
            </NextButton>
            <ExtraSmallButton
              size={'small'}
              color={'secondary'}
              onClick={() => setIsOpenNextModal(true)}
            >
              Edit
            </ExtraSmallButton>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2medium">Back Button</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems={'center'} spacing={0.5}>
            <BackButton
              buttontheme={theme}
              size={'small'}
              variant={'outlined'}
              backgroundColor={theme.backButton.background}
              startIcon={theme.backButton.iconVisible && <Icon>{theme.backButton.icon}</Icon>}
            >
              Back
            </BackButton>
            <ExtraSmallButton
              size={'small'}
              color={'secondary'}
              onClick={() => setIsOpenBackModal(true)}
            >
              Edit
            </ExtraSmallButton>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="body2medium">Submit Button</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems={'center'} spacing={0.5}>
            <SubmitButton
              buttontheme={theme}
              size={'small'}
              variant={'contained'}
              backgroundColor={theme.submitButton.background}
              endIcon={theme.submitButton.iconVisible && <Icon>{theme.submitButton.icon}</Icon>}
            >
              Submit
            </SubmitButton>
            <ExtraSmallButton
              size={'small'}
              color={'secondary'}
              onClick={() => setIsOpenSubmitModal(true)}
            >
              Edit
            </ExtraSmallButton>
          </Stack>
        </Stack>
      </ButtonsWrapper>

      {isOpenNextModal && (
        <ButtonDesignModal
          title="Edit Next Button"
          open={isOpenNextModal}
          onClose={() => setIsOpenNextModal(false)}
          onSubmit={handleChangeNextButton}
          button={theme.nextButton}
          buttonText="Next"
        />
      )}

      {isOpenBackModal && (
        <ButtonDesignModal
          title="Edit Back Button"
          open={isOpenBackModal}
          onClose={() => setIsOpenBackModal(false)}
          onSubmit={handleChangeBackButton}
          button={theme.backButton}
          isBackButton={true}
          buttonText="Back"
        />
      )}

      {isOpenSubmitModal && (
        <ButtonDesignModal
          title="Edit Submit Button"
          open={isOpenSubmitModal}
          onClose={() => setIsOpenSubmitModal(false)}
          onSubmit={handleChangeSubmitButton}
          button={theme.submitButton}
          buttonText="Submit"
        />
      )}
    </Box>
  );
};

export default ButtonStyles;
