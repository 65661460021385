import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {EmbedShareOption} from './EmbedModeModalStyles';

const EmbedModeSingleOption = ({onClick, className, icon, mode, modeDetails}) => {
  return (
    <Stack spacing={1} sx={{height:'100%'}}>
      <EmbedShareOption onClick={onClick} className={className}>
        <Box className="fbpEmbedIcon">{icon}</Box>
        <Stack spacing={0.5}>
          <Typography variant="body2" color="text.primary">
            {mode}
          </Typography>
          {modeDetails && (
            <Typography variant="helperText" color="text.secondary">
              {modeDetails}
            </Typography>
          )}
        </Stack>
      </EmbedShareOption>
    </Stack>
  );
};

export default EmbedModeSingleOption;
