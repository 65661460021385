import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import {Alert, Box, FormControl, Grid, Typography} from '@mui/material';
import CoreTextField from '../../../common/TextField/CoreTextField';
import CustomMuiPhoneInput from '../../../common/CustomMuiPhoneInput';
import {CustomFileUploader} from '../../../common/FileUpload/CustomFileUploader';
import {selectFormFooterData} from '../../../state/features/formHeader/formHeaderSelector';
import {updateFormHeaderData} from '../../../state/features/formHeader/formHeaderSlice';
import {registerPatterFormHeaderSettings} from '../../../helpers/constant/registerPattern';
import {IconButtonStyled, LogoImageBox} from './HeaderStyles';

const HeaderSettingsBody = () => {
  const {formId} = useParams();
  const dispatch = useDispatch();
  const {handleSubmit, control, setValue} = useForm({mode: 'onBlur'});
  const formData = useSelector(selectFormFooterData);
  const [companyLogo, setCompanyLogo] = useState('');

  useEffect(() => {
    const {formId, companyName, companyPhone, companyEmail, companyLogo} = formData || {};
    if (formId) {
      setValue('companyName', companyName);
      setValue('companyPhone', companyPhone);
      setValue('companyEmail', companyEmail);
      setCompanyLogo(companyLogo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUploadImage = (imageFile = {}) => {
    setCompanyLogo(imageFile.file);
    dispatch(updateFormHeaderData({companyLogo: imageFile.file, formId: formId}));
  };

  const onRemoveCompanyLogo = () => {
    setCompanyLogo('');
    dispatch(updateFormHeaderData({companyLogo: '', formId: formId}));
  };

  const onSubmit = data => {
    dispatch(updateFormHeaderData({...data, formId: formId}));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onBlur={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="companyName"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl fullWidth required>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
                  Company Name
                </Typography>
                <CoreTextField
                  type={'text'}
                  size={'small'}
                  color={'secondary'}
                  placeholder={'Company name'}
                  inputProps={{
                    maxLength: 100
                  }}
                  value={value}
                  onChange={onChange}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="companyPhone"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl fullWidth required>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
                  Company Phone
                </Typography>
                <CustomMuiPhoneInput
                  type={'tel'}
                  size={'small'}
                  color={'secondary'}
                  placeholder={'(XXX) XXX-XXXX'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </FormControl>
            )}
            rules={registerPatterFormHeaderSettings.phone || null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="companyEmail"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl fullWidth required>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
                  Company Email
                </Typography>
                <CoreTextField
                  type={'email'}
                  size={'small'}
                  color={'secondary'}
                  placeholder={'email@address.com'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </FormControl>
            )}
            rules={registerPatterFormHeaderSettings.email || null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
            Company Logo
          </Typography>
          <CustomFileUploader
            style={{width: '100%'}}
            preType="image"
            recommendedSize={'900 x 300px (3:1)'}
            onFileUpload={onUploadImage}
          />
        </Grid>
        {companyLogo && (
          <Grid item xs={12} sm={6} sx={{mt: 3}}>
            <LogoImageBox>
              <img src={companyLogo} alt={'logo'} />
              <IconButtonStyled size="small" onClick={onRemoveCompanyLogo}>
                <CloseIcon color={'error'} />
              </IconButtonStyled>
            </LogoImageBox>
          </Grid>
        )}
      </Grid>

      <Alert sx={{mt: 2}} severity="warning">
        This header settings will appear on form public view
      </Alert>
    </Box>
  );
};

export default HeaderSettingsBody;
