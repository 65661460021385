import {styled, Typography} from '@mui/material';

export const FormLabel = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  fontSize: 'var(--fbp-theming-label-font-size) ',
  color: 'var(--fbp-theming-font-color) ',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '0.15px',
  position:'relative'
}));
