import React from 'react';
import {Box, styled} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {VideoComponentIcon} from '../../../helpers/fbpIcons/IconComponents';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const BoxStyled = styled(Box)(({theme}) => ({
  background: 'var(--fbp-theming-input-background)',
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: '8px',
  width: '100%',
  height: '260px',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '& svg path': {
    fill: 'var(--fbp-theming-text-color)'
  }
}));

const CardViewVideoComponent = ({field, editMode, onChange}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
        <Box mb={4}>
          <FormDescription>{field.description}</FormDescription>
        </Box>

        {!field.video && (
          <BoxStyled>
            <VideoComponentIcon />
          </BoxStyled>
        )}

        {field.video && (
          <BoxStyled sx={{bgcolor: 'transparent', border: 'none', p: '0'}}>
            <Box sx={{width: '100%', position: 'relative'}}>
              <video
                width="100%"
                height="250px"
                controls="false"
                style={{objectFit: 'cover', borderRadius: '8px'}}
                src={field.video}
              >
                Your browser does not support HTML video.
              </video>
              {/* <Box className={Styles.playDuration} sx={{display: 'flex'}}>
                <Box>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.001 0C4.48098 0 0.000976562 4.48 0.000976562 10C0.000976562 15.52 4.48098 20 10.001 20C15.521 20 20.001 15.52 20.001 10C20.001 4.48 15.521 0 10.001 0ZM8.00098 14.5V5.5L14.001 10L8.00098 14.5Z"
                      fill="white"
                    />
                  </svg>
                </Box>
                <Box className={Styles.videoDuration} ml={2}>
                  <Typography variant="body2medium">00:15</Typography>
                </Box>
              </Box> */}
              {editMode && (
                <CoreButton
                  variant="contained"
                  size={'small'}
                  sx={{position: 'absolute', right: '10px', top: '10px'}}
                  onClick={() => onChange({video: null})}
                >
                  remove
                </CoreButton>
              )}
            </Box>
          </BoxStyled>
        )}
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default CardViewVideoComponent;
