import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  folders: [],
  isLoading: false,
  isError: false,
  error: '',

  isFolderAdding: false,
  isFolderAddSuccess: false,

  isFolderDeleting: false,
  isFolderDeleteSuccess: false,

  isFolderUpdating: false,
  isFolderUpdateSuccess: false
};

const formFolderSlice = createSlice({
  name: 'formFolder',
  initialState: initialState,
  reducers: {
    getFormFolders: (state, action) => {
      state.isLoading = true;
    },
    getFormFoldersSuccess: (state, action) => {
      state.folders = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormFoldersFailed: (state, action) => {
      state.folders = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addFormFolder: (state, action) => {
      state.isFolderAdding = true;
    },
    addFormFolderSuccess: (state, action) => {
      if (action.payload?.id) {
        state.folders.push(action.payload);
      }
      state.isFolderAdding = false;
      state.isFolderAddSuccess = true;
    },
    addFormFolderFailed: (state, action) => {
      state.isFolderAdding = false;
    },
    resetAddFormFolderSuccess: (state, action) => {
      state.isFolderAddSuccess = false;
    },

    deleteFormFolder: state => {
      state.isFolderDeleting = true;
    },
    deleteFormFolderSuccess: (state, action) => {
      if (action.payload?.folderId) {
        state.folders = state.folders.filter(folder => folder.id !== action.payload.folderId);
      }
      state.isFolderDeleting = false;
      state.isFolderDeleteSuccess = true;
    },
    deleteFormFolderFailed: (state, action) => {
      state.isFolderDeleting = false;
    },
    resetDeleteFormFolderSuccess: (state, action) => {
      state.isFolderDeleteSuccess = false;
    },

    updateFormFolderName: state => {
      state.isFolderUpdating = true;
    },
    updateFormFolderNameSuccess: (state, action) => {
      if (action.payload?.id) {
        state.folders = state.folders.map(folder => {
          if (folder.id === action.payload.id) {
            folder.name = action.payload.name;
          }
          return folder;
        });
      }
      state.isFolderUpdating = false;
      state.isFolderUpdateSuccess = true;
    },
    updateFormFolderNameFailed: (state, action) => {
      state.isFolderUpdating = false;
    },
    resetUpdateFormFolderNameSuccess: (state, action) => {
      state.isFolderUpdateSuccess = false;
    },

    updateFormFolderColor: (state, action) => {
      state.isFolderUpdating = true;
    },
    updateFormFolderColorSuccess: (state, action) => {
      if (action.payload?.id && action.payload?.color) {
        state.folders = state.folders.map(folder => {
          if (folder.id === action.payload.id) {
            folder.color = action.payload.color;
          }
          return folder;
        });
      }
      state.isFolderUpdating = false;
    },
    updateFormFolderColorFailed: (state, action) => {
      state.isFolderUpdating = false;
    }
  }
});

export const {
  getFormFolders,
  getFormFoldersSuccess,
  getFormFoldersFailed,

  addFormFolder,
  addFormFolderSuccess,
  addFormFolderFailed,
  resetAddFormFolderSuccess,

  deleteFormFolder,
  deleteFormFolderSuccess,
  deleteFormFolderFailed,
  resetDeleteFormFolderSuccess,

  updateFormFolderName,
  updateFormFolderNameSuccess,
  updateFormFolderNameFailed,
  resetUpdateFormFolderNameSuccess,

  updateFormFolderColor,
  updateFormFolderColorSuccess,
  updateFormFolderColorFailed
} = formFolderSlice.actions;

export default formFolderSlice.reducer;
