import React, {cloneElement} from 'react';
import {useDispatch} from "react-redux";
import BuilderService from "../../services/Builder.service";
import {showErrorSnackbar} from "../../state/features/snackbar/snackbarSlice";

const CustomImageUploadWrapper = ({children, onImageUpload, onUploading, maxFileSizeMB=2}) => {
  const dispatch = useDispatch();

  const handleFileUpload = file => {
    try {
      if (!file.type.match(/image/g)) {
        dispatch(showErrorSnackbar('Please select a valid image.'));
        return;
      }

      if (file.size > 1024 * 1024 * maxFileSizeMB) {
        dispatch(showErrorSnackbar('Maximum upload size : 2MB'));
        return;
      }

      if (onUploading) onUploading(true);

      const dt = new FormData();
      dt.append('files', file, file.name);

      BuilderService.uploadFile(dt)
          .then(res => {
            if (res.success && onImageUpload) {
              onImageUpload({
                image: res.urls[0]
              });
            } else {
              dispatch(showErrorSnackbar('Image upload failed, please try again later'));
            }
          })
          .finally(() => onUploading && onUploading(true));
    } catch (e) {
      dispatch(showErrorSnackbar('Something went wrong, try upload file from local storage'));
    }
  };

  const onClickChildItem = () => {
    document.getElementById('custom-file-upload-btn').click();
  }

  return (
      <>
        <label htmlFor="custom-file-upload-btn">
          {cloneElement(children, {
            onClick: onClickChildItem
          })}
        </label>
        <input
            id={"custom-file-upload-btn"}
            type="file"
            accept="image/*"
            onChange={e => handleFileUpload(e.target.files[0])}
            hidden
        />
      </>
  );
};

export default CustomImageUploadWrapper;
