import React, {useState} from 'react';
import FormsSettings from './FormSettings';
import SettingPageWrapper from './SettingPageWrapper';
import ThankYou from './ThankYouPage/ThankYou';
import FormSEO from './FormSEO/FormSEO';
import Mapping from './Mapping/Mapping';
import Conditions from './conditionPage/Conditions';
import HeaderSettings from './HeaderSettings/HeaderSettings';
import FormFooter from './footerSettings/FormFooter';
import {FORM_SETTING_ID} from '../../helpers/constant/CoreConstants';

const options = [
  {
    id: FORM_SETTING_ID.FORM_SETTING,
    title: 'Form Settings',
    content: 'Change form status and properties.'
  },
  {
    id: FORM_SETTING_ID.MAPPING,
    title: 'Field Mapping',
    content: 'Map form fields to system fields.'
  },
  {
    id: FORM_SETTING_ID.THANK_YOU,
    title: 'Thank you page',
    content: 'Choose an Action After Submission.'
  },
  {
    id: FORM_SETTING_ID.CONDITIONS,
    title: 'Conditions',
    content: 'Create conditional logic.'
  },
  {
    id: FORM_SETTING_ID.HEADER_SETTING,
    title: 'Header Settings',
    content: 'Show Basic information from the Header'
  },
  {
    id: FORM_SETTING_ID.FOOTER_SETTING,
    title: 'Footer Settings',
    content: 'Display essential information in the footer'
  },
  {
    id: FORM_SETTING_ID.SEO_SETTING,
    title: 'SEO Settings',
    content: 'Fill your brand info for better SEO'
  }
];

const SettingPage = () => {
  const [selectedPage, setSelectedPage] = useState(FORM_SETTING_ID.FORM_SETTING);

  return (
    <SettingPageWrapper
      options={options}
      title="Setting Page"
      selectedPage={selectedPage}
      onChangePage={page => setSelectedPage(page)}
    >
      {selectedPage === FORM_SETTING_ID.FORM_SETTING && <FormsSettings />}

      {selectedPage === FORM_SETTING_ID.THANK_YOU && <ThankYou />}

      {selectedPage === FORM_SETTING_ID.CONDITIONS && <Conditions />}

      {selectedPage === FORM_SETTING_ID.HEADER_SETTING && <HeaderSettings />}

      {selectedPage === FORM_SETTING_ID.FOOTER_SETTING && <FormFooter />}

      {selectedPage === FORM_SETTING_ID.SEO_SETTING && <FormSEO />}

      {selectedPage === FORM_SETTING_ID.MAPPING && <Mapping />}
    </SettingPageWrapper>
  );
};

export default SettingPage;
