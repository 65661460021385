import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import {useDispatch, useSelector} from 'react-redux';
import {CustomDropdown} from '../../common/CustomDropdown';
import {addOrderByFilter} from '../../state/features/folderForms/folderFormsSlice';
import {selectFormFilters} from '../../state/features/folderForms/folderFormSelector';

const listData = [
  {title: 'Title [a-z]', value: 'a-z'},
  {title: 'Title [z-a]', value: 'z-a'},
  {title: 'Creation Date', value: 'cre-date'},
  {title: 'Last Edit', value: 'last-edit'},
  {title: 'Submission Count', value: 'sub-count'},
  {title: 'Unread', value: 'unread'},
  {title: 'Last Submission', value: 'last-sub'}
];

const FormListFilterStatus = () => {
  const dispatch = useDispatch();
  const {orderBy} = useSelector(selectFormFilters);
  const [value, setValue] = useState(orderBy || 'last-sub');

  useEffect(() => {
    if (orderBy === '') {
      setValue('last-sub');
    }
  }, [orderBy]);

  const onSelectFilter = event => {
    setValue(event.target.value);
    dispatch(addOrderByFilter(event.target.value));
  };

  return (
    <Box>
      <CustomDropdown
        wrapperClassName="formListFilter"
        size="small"
        dropDownLeftIcon={fbpIcons.fbpListIcon}
        data={listData}
        value={value}
        onChange={onSelectFilter}
      />
    </Box>
  );
};

export default FormListFilterStatus;
