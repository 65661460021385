import matchSingleField from './matchSingleField';
import { CONDITION_LINK, CONDITION_TYPE } from '../../constant/formConditionConstant';

const matchPageChangeConditions = (rules, fieldsIdMap, pageFields) => {
  let nextPage = null;
  pageFields.forEach(eachField => {
    if (nextPage !== null) {
      return;
    }
    const ruleIndexes = fieldsIdMap[eachField.id].ruleIndexes;

    ruleIndexes.forEach(ruleIndex => {
      if (nextPage !== null) {
        return;
      }
      const rule = rules[ruleIndex];
      let conditionSuccess = rule.link === CONDITION_LINK.ALL;
      if (rule.type !== CONDITION_TYPE.SKIP) {
        return;
      }

      for (let conditionIndex = 0; conditionIndex < rule.condition.length; conditionIndex++) {
        const ruleCondition = rule.condition[conditionIndex];
        const ruleFieldIndex = fieldsIdMap[ruleCondition.fieldId];
        const field = pageFields[ruleFieldIndex.fieldIndex] ?? pageFields[0]; //In card view there is only one field in page fields
        const isMatched = matchSingleField(ruleCondition, field);
        if (rule.link === CONDITION_LINK.ANY) {
          conditionSuccess = conditionSuccess || isMatched;
          if (isMatched) {
            break;
          }
        } else {
          conditionSuccess = conditionSuccess && isMatched;
          if (!isMatched) {
            break;
          }
        }
      }

      if (conditionSuccess) {
        nextPage = rule.action[0].page;
      }
    });
  });

  return nextPage;
};

export default matchPageChangeConditions;
