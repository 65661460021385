import React from 'react';
import FileUploadAdvanced from './FileUploadAdvanced';
import FileUploadGeneral from './FileUploadGeneral';
import FileUploadOptions from './FileUploadOptions';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const FileUploadRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <FileUploadGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <FileUploadOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <FileUploadAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default FileUploadRightPanel;
