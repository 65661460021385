import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {Box, FormHelperText, Grid, Stack, Typography} from '@mui/material';
import {CustomUploadImage} from '../../common/FileUpload/CustomUploadImage';
import CoreAutoComplete from '../../common/Autocomplete/CoreAutoComplete';
import CoreTextField from '../../common/TextField/CoreTextField';
import AsteriskIcon from '../../common/Icons/AsterickIcon';
import CoreButton from '../../common/Button/CoreButton';
import RocketIcon from '../../common/Icons/RocketIcon';
import {registerPatternPublishSettings} from '../../helpers/constant/registerPattern';
import {
  selectAdminTemplateCategories
} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import {getAdminTemplateCategories} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {selectTemplatePublish} from '../../state/features/templatePublish/templatePublishSelector';
import {
  getTemplatePublishData,
  resetTemplatePublish,
  updateTemplatePublishData
} from '../../state/features/templatePublish/templatePublishSlice';
import PublishSettingFormLoader from '../LoadingView/PublishSettingFormLoader';

const PublishSettingsForm = () => {
  const {formId} = useParams();
  const dispatch = useDispatch();
  const {handleSubmit, setValue, control} = useForm();
  const {data: categoryList} = useSelector(selectAdminTemplateCategories);
  const {data: formPublishData, isLoading, isUpdating} = useSelector(selectTemplatePublish);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [image, setImage] = useState('');
  const [imageError, setImageError] = useState({
    isError: false,
    message: ''
  });

  useEffect(() => {
    if (categoryList?.length === 0) {
      dispatch(getAdminTemplateCategories());
    }
    dispatch(getTemplatePublishData({formId: formId}));
    return () => dispatch(resetTemplatePublish());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formPublishData?.id) {
      const {formTitle, description, subcategoryId, fileUrl} = formPublishData;
      setValue('name', formTitle);
      setValue('description', description);
      setValue('subcategoryId', subcategoryId);
      setImage(fileUrl || '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPublishData?.id]);

  useEffect(() => {
    if (typeof formPublishData?.subcategoryId == 'number' && categoryList?.length > 0) {
      // find category and subCategory by subcategoryId
      const foundCategory = categoryList.find(category => {
        if (category.subCategory) {
          const subCategory = category.subCategory.find(subCategoryItem => {
            return subCategoryItem.id === formPublishData.subcategoryId;
          });

          if (subCategory?.id) {
            setValue('subCategory', subCategory);
            return true;
          }
        }

        return false;
      });

      if (foundCategory?.id) {
        setSelectedCategory(foundCategory);
        setValue('category', foundCategory);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPublishData?.subcategoryId, categoryList]);

  const onImageUpload = response => {
    setImage(response?.image);
    setImageError({isError: false, message: ''});
  };

  // ## Form Submit Action
  const onSubmit = data => {
    if (!image) {
      setImageError({isError: true, message: 'Template thumbnail image is required!'});
      return false;
    }

    const reqObj = {
      templateId: formId,
      willPublish: true
    };

    if (formPublishData?.id) {
      const {formTitle = '', description = '', subcategoryId, fileUrl} = formPublishData;

      if (formTitle.trim() !== data.name) reqObj.name = data.name;
      if (description.trim() !== data.description) reqObj.description = data.description;
      if (fileUrl !== image) reqObj.thumbnail = image;
      if (subcategoryId !== data.subCategory.id) reqObj.subcategoryId = data.subCategory.id;
    }

    dispatch(updateTemplatePublishData(reqObj));
  };

  if (isLoading) return <PublishSettingFormLoader />;

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} sx={{mb: 1}}>
                  Template Name <AsteriskIcon isError={invalid} />
                </Typography>

                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  autoFocus
                  placeholder={'Template Name'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternPublishSettings.name || null}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} sx={{mb: 1}}>
                  Template Description <AsteriskIcon isError={invalid} />
                </Typography>

                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  autoFocus
                  placeholder={'Write here'}
                  multiline
                  rows={4}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternPublishSettings.description || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={'category'}
            defaultValue={selectedCategory}
            render={({field: {value, onChange}, fieldState: {invalid, error}, ...field}) => (
              <>
                <Typography variant={'body2'} sx={{mb: 1}}>
                  Select Category <AsteriskIcon isError={invalid} />
                </Typography>
                <CoreAutoComplete
                  options={categoryList}
                  getOptionLabel={option => option.categoryName}
                  value={value}
                  onChange={(_, data) => {
                    onChange(data);
                    setSelectedCategory(data);
                    setValue('subCategory', null);
                  }}
                  renderInput={params => (
                    <CoreTextField
                      {...params}
                      size={'small'}
                      placeholder={'Choose category'}
                      error={!!(invalid && error.message)}
                      helperText={invalid && error.message}
                    />
                  )}
                />
              </>
            )}
            rules={registerPatternPublishSettings.category || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={'subCategory'}
            defaultValue={null}
            render={({field: {value, onChange}, fieldState: {invalid, error}, ...field}) => (
              <>
                <Typography variant={'body2'} sx={{mb: 1}}>
                  Select Sub-category <AsteriskIcon isError={invalid} />
                </Typography>
                <CoreAutoComplete
                  options={selectedCategory?.subCategory || []}
                  getOptionLabel={option => option.name}
                  value={value}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  renderInput={params => (
                    <CoreTextField
                      {...params}
                      size={'small'}
                      placeholder={'Choose sub-category'}
                      error={!!(invalid && error.message)}
                      helperText={invalid && error.message}
                    />
                  )}
                />
              </>
            )}
            rules={registerPatternPublishSettings.subCategory || null}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant={'body2'} sx={{mb: 1}}>
            Thumbnail <AsteriskIcon isError={imageError.isError} />
          </Typography>

          <CustomUploadImage onImageUpload={onImageUpload} />
          {imageError.isError && (
            <FormHelperText error={imageError.isError}>{imageError.message}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{display: 'flex', alignItems: 'center', height: '160px'}}>
            {image && <img src={image} alt={''} height={100} />}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <CoreButton
              disabled={isUpdating}
              type={'submit'}
              color={'secondary'}
              variant="contained"
              endIcon={<RocketIcon />}
            >
              Publish Template
            </CoreButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PublishSettingsForm;
