import React from 'react';
import {SvgIcon} from '@mui/material';

export default function ShowSideBarIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M5.338 22H18.662C20.5 22 22 20.5 22 18.662V5.338C22 3.5 20.5 2 18.662 2H5.325C3.5 2 2 3.5 2 5.338V18.675C2 20.5 3.5 22 5.338 22ZM18.662 3.662C19.575 3.662 20.325 4.412 20.325 5.325V18.662C20.325 19.575 19.575 20.325 18.662 20.325H5.325C4.412 20.325 3.662 19.575 3.662 18.662V5.338C3.662 4.425 4.412 3.675 5.325 3.675H18.662V3.662ZM15.338 22C15.838 22 16.175 21.662 16.175 21.162V2.838C16.162 2.338 15.838 2 15.338 2C14.838 2 14.5 2.338 14.5 2.838V21.175C14.5 21.662 14.838 22 15.338 22ZM10.338 12.838C10.588 12.838 10.75 12.75 10.925 12.588C11.25 12.25 11.25 11.75 10.912 11.412L8.412 8.912C8.075 8.575 7.575 8.575 7.25 8.912C6.912 9.25 6.912 9.75 7.25 10.075L9.75 12.575C9.912 12.75 10.088 12.838 10.338 12.838ZM7.838 15.338C8.088 15.338 8.25 15.25 8.425 15.088L10.925 12.588C11.25 12.25 11.25 11.75 10.912 11.412C10.575 11.075 10.075 11.075 9.75 11.412L7.25 13.912C6.912 14.25 6.912 14.75 7.25 15.075C7.412 15.25 7.588 15.338 7.838 15.338Z"
        fill="#006DF5"
      />
    </SvgIcon>
  );
}
