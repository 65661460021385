import React from 'react';
import {SvgIcon} from '@mui/material';

const RocketIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.327 12.102C17.327 12.068 17.334 12.036 17.334 12.001C17.334 7.596 15.225 3.708 12.001 1.334C8.777 3.708 6.667 7.596 6.667 12.001C6.667 12.036 6.673 12.068 6.674 12.102C5.051 13.036 3.703 14.381 2.766 16.002C1.828 17.623 1.334 19.462 1.334 21.334H4.017C5.085 19.919 6.509 18.812 8.143 18.126C9.062 19.917 10.382 21.471 12.001 22.668C13.619 21.471 14.939 19.917 15.858 18.126C17.493 18.812 18.917 19.919 19.985 21.334H22.667C22.667 19.462 22.173 17.623 21.236 16.002C20.298 14.381 18.95 13.036 17.327 12.102ZM12.001 5.916C12.554 5.916 13.085 6.136 13.476 6.527C13.867 6.918 14.087 7.449 14.087 8.002C14.087 8.556 13.867 9.087 13.476 9.478C13.085 9.869 12.554 10.089 12.001 10.089C11.447 10.089 10.916 9.869 10.525 9.478C10.134 9.087 9.914 8.556 9.914 8.002C9.914 7.449 10.134 6.918 10.525 6.527C10.916 6.136 11.447 5.916 12.001 5.916Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default RocketIcon;
