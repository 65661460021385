import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {FormControlLabel, IconButton, styled, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import AddToCampaign from '../AddToCampaign/AddToCampaign';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import ExportFormContent from '../../FormList/ExportFormContent';
import CoreButton from '../../../common/Button/CoreButton';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import BasicDrawer from '../../../common/Drawer/BasicDrawer';
import TrashIcon from '../../../common/Icons/TrashIcon';
import DownloadIcon from '../../../common/Icons/DownloadIcon';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import useBoolean from '../../../hooks/useBoolean';
import {FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION} from '../../../helpers/constant/formSubmissionConstant';
import {
  selectFormSubmissionData,
  selectSelectedContactIds,
  selectSelectedInboxFormIds
} from '../../../state/features/formSubmission/formSubmissionSelector';
import {
  deselectAllInboxForm,
  inboxFormDelete,
  selectedAllInboxForm
} from '../../../state/features/formSubmission/formSubmissionSlice';

const StyledBox = styled(Box)(({theme}) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px'
}));

const InboxLeftBulkActions = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {formId} = useParams();
  const selectedSubmissionIds = useSelector(selectSelectedInboxFormIds);
  const selectedContactIds = useSelector(selectSelectedContactIds);
  const {data = [], count = 0} = useSelector(selectFormSubmissionData);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const {value: openExportModal, toggle: toggleExportModal} = useBoolean(false);
  const {value: isDrawerOpen, toggle: toggleDrawerOpen} = useBoolean(false);

  const onOpenDeleteModal = () => setOpenDeleteModal(true);
  const onCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleSelectAll = () => {
    dispatch(selectedAllInboxForm());
  };
  const handleDeselectAll = () => {
    dispatch(deselectAllInboxForm());
  };

  const handleDelete = () => {
    onOpenDeleteModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(inboxFormDelete({ids: selectedSubmissionIds}));
    onCloseDeleteModal();
  };

  let checkedAll = false;
  let totalFormShow = 0;
  if (data && data?.length > 0) {
    checkedAll = data.every(formInboxData => selectedSubmissionIds.includes(formInboxData.id));
    totalFormShow = data.length;
  }

  return (
    <Box sx={{padding: '12px 16px'}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box>
          <FormControlLabel
            sx={{color: theme.palette.text.primary, opacity: 1}}
            control={
              <Checkbox
                checked={checkedAll}
                onChange={checkedAll ? handleDeselectAll : handleSelectAll}
                name="Select all"
                color="primary"
              />
            }
            label=""
          />
        </Box>
        {selectedSubmissionIds?.length > 0 ? (
          <StyledBox>
            <StyledBox sx={{justifyContent: 'flex-start', marginLeft: '-8px'}}>
              <CustomTooltip title="Delete" arrow placement="bottom">
                <IconButton onClick={handleDelete}>
                  <TrashIcon color={'action'} />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title="Export" arrow placement="bottom">
                <IconButton onClick={toggleExportModal}>
                  <DownloadIcon color={'action'} />
                </IconButton>
              </CustomTooltip>
            </StyledBox>

            <CoreButton
              variant="contained"
              size="small"
              color="secondary"
              sx={{minWidth: '148px'}}
              onClick={toggleDrawerOpen}
              disabled={selectedContactIds?.length === 0}
            >
              Add to Campaign
            </CoreButton>
          </StyledBox>
        ) : (
          <Typography variant="body2" color="text.primary">
            Showing {totalFormShow} / {count} submissions
          </Typography>
        )}
      </Box>

      <BasicDrawer open={isDrawerOpen} title={'Add to Campaign'} toggleDrawer={toggleDrawerOpen}>
        <AddToCampaign onClose={toggleDrawerOpen} isBulkAction={true} />
      </BasicDrawer>

      <DeleteWarningModal
        title={'Delete Submission'}
        warningSubtitle={'Are you sure you want to delete selected submission(s)?'}
        warningContent={`Selected ${selectedSubmissionIds.length} ${
          selectedSubmissionIds.length > 1 ? 'submissions' : 'submission'
        }  will be deleted permanently.This action cannot be undone.`}
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete Forever'}
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />

      <BasicModalWithHeader
        modalTitle={'Export Submissions'}
        open={openExportModal}
        onClose={toggleExportModal}
      >
        <ExportFormContent
          onClose={toggleExportModal}
          formId={formId}
          module={FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION}
          submissionIds={selectedSubmissionIds}
        />
      </BasicModalWithHeader>
    </Box>
  );
};

export default InboxLeftBulkActions;
