import {styled} from '@mui/material';
import CoreTextField from './CoreTextField';

const ExtraSmallTextField = styled(CoreTextField)(({theme}) => ({
  background: theme.palette.background.paper,
  '& input': {
    fontSize: '13px',
    color: theme.palette.text.secondary,
    '&.MuiInputBase-inputSizeSmall': {
      padding: '4.5px 14px !important'
    }
  },
  '&.addTagInput': {
    height: '30px',
    '& .MuiOutlinedInput-root': {
      height: '100%',
      padding: '0 !important'
    }
  },
  '& input[type=number]': {
    '-moz-appearance': 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  }
}));

export default ExtraSmallTextField;
