import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  styled,
  Typography,
  Box
} from '@mui/material';
import {Delete, DriveFileMove, Edit, MoreVert} from '@mui/icons-material';
import AddOrEditTemplateSubCategory from './AddOrEditTemplateSubCategory';
import MoveSubCategoryContent from '../MoveSubCategoryContent';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import CountChip from '../../../common/Chip/CountChip';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import {castArray} from '../../../helpers/utils/castArray';
import {selectSelectedSubCategory} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import {
  deleteAdminTemplateSubCategory,
  setSelectedAdminSubCategory
} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {setTemplateSubCategoryId} from '../../../state/features/adminTemplates/adminTemplatesSlice';

const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  borderRadius: '8px',
  '&:hover .countChip': {
    display: 'none'
  },
  '&:hover .MuiIconButton-root': {
    display: 'inline-flex',
    padding: '0 !important'
  }
}));

const AdminTemplateSubCategoryItem = ({open, subCategory, categoryId}) => {
  const dispatch = useDispatch();
  const selectedSubCategory = useSelector(selectSelectedSubCategory);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenSubCategoryModal, setOpenSubCategoryModal] = useState(false);
  const [isOpenMoveSubCategoryModal, setOpenMoveSubCategoryModal] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const toggleOpenSubCategoryModal = () => setOpenSubCategoryModal(prevState => !prevState);
  const toggleOpenMoveSubCategoryModal = () => setOpenMoveSubCategoryModal(prevState => !prevState);

  const handleClickOpenMenu = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClickCloseMenu = () => setAnchorEl(null);

  const handleClickSubCategory = subCategoryObj => {
    dispatch(setTemplateSubCategoryId(subCategoryObj.id));
    dispatch(
      setSelectedAdminSubCategory({
        id: subCategoryObj.id,
        name: subCategoryObj.name,
        count: subCategoryObj.count
      })
    );
  };

  const handleEdit = data => {
    toggleOpenSubCategoryModal();
  };

  // handle Delete Category
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDelete = () => {
    handleOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    if (subCategory?.id) {
      dispatch(deleteAdminTemplateSubCategory({ids: castArray(subCategory.id)}));
    }
    handleCloseDeleteModal();
  };

  const handleMoveSubCategory = data => {
    toggleOpenMoveSubCategoryModal();
  };

  const openMenu = Boolean(anchorEl);
  return (
    <>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButtonStyled
            sx={{pl: 4}}
            selected={selectedSubCategory.name === subCategory.name || openMenu}
            onClick={() => handleClickSubCategory(subCategory)}
          >
            <ListItemText secondary={subCategory.name} />
            <Box
              id="template-menu-id"
              aria-controls={openMenu ? 'template-menu-area' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup="true"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: 24,
                width: 40
              }}
              onClick={handleClickOpenMenu}
            >
              <CountChip className={'countChip'} label={subCategory.count} />
              <IconButton sx={{display: 'none'}} size={'small'}>
                <MoreVert />
              </IconButton>
            </Box>
          </ListItemButtonStyled>
        </List>
      </Collapse>

      <Menu
        id="template-menu-area"
        MenuListProps={{
          'aria-labelledby': 'template-menu-id'
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleClickCloseMenu}
      >
        <MenuItem onClick={() => handleEdit(subCategory)}>
          <Edit sx={{mr: 1}} size={'small'} />
          <Typography variant={'body2'}>Edit</Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <Delete sx={{mr: 1}} size={'small'} />
          <Typography variant={'body2'}>Delete</Typography>
        </MenuItem>
        <MenuItem onClick={handleMoveSubCategory}>
          <DriveFileMove sx={{mr: 1}} size={'small'} />
          <Typography variant={'body2'}>Move</Typography>
        </MenuItem>
      </Menu>

      <BasicModalWithHeader
        modalTitle={'Update sub-category'}
        open={isOpenSubCategoryModal}
        onClose={toggleOpenSubCategoryModal}
      >
        <AddOrEditTemplateSubCategory
          onClose={toggleOpenSubCategoryModal}
          isEditMode={true}
          subCategoryInfo={subCategory}
          categoryId={categoryId}
        />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        modalTitle={'Move sub-category'}
        open={isOpenMoveSubCategoryModal}
        onClose={toggleOpenMoveSubCategoryModal}
      >
        <MoveSubCategoryContent
          onClose={toggleOpenMoveSubCategoryModal}
          subCategoryInfo={subCategory}
          categoryId={categoryId}
        />
      </BasicModalWithHeader>

      <DeleteWarningModal
        warningSubtitle={'Are you sure!, want to delete this Category?'}
        warningContent={'After delete, this operation cannot be undone.'}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default AdminTemplateSubCategoryItem;
