import React from 'react';
import {Stack} from '@mui/material';
import Box from '@mui/material/Box';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';

const LinkComponent = ({field, editMode, onChange}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <>
      <Box sx={{position: 'relative'}} className={`${labelAlignmentClass}`}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

        <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
          <FormInput
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={{
              ...(field.required ? {required: 'This value is required'} : {}),
              ...registerPatternBuilderFields.link
            }}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(LinkComponent);
