import React from 'react';

const DownloadBlueIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.60001 20.4C3.60001 19.7373 4.13726 19.2 4.80001 19.2H19.2C19.8627 19.2 20.4 19.7373 20.4 20.4C20.4 21.0628 19.8627 21.6 19.2 21.6H4.80001C4.13726 21.6 3.60001 21.0628 3.60001 20.4ZM7.55148 11.1515C8.02011 10.6829 8.7799 10.6829 9.24853 11.1515L10.8 12.703L10.8 3.60002C10.8 2.93728 11.3373 2.40002 12 2.40002C12.6627 2.40002 13.2 2.93728 13.2 3.60002L13.2 12.703L14.7515 11.1515C15.2201 10.6829 15.9799 10.6829 16.4485 11.1515C16.9172 11.6201 16.9172 12.3799 16.4485 12.8486L12.8485 16.4486C12.6235 16.6736 12.3183 16.8 12 16.8C11.6817 16.8 11.3765 16.6736 11.1515 16.4486L7.55148 12.8486C7.08285 12.3799 7.08285 11.6201 7.55148 11.1515Z"
        fill="#006DF5"
      />
    </svg>
  );
};

export default DownloadBlueIcon;
