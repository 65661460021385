import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: ''
};

const mediaImageSlice = createSlice({
  name: 'mediaImage',
  initialState: initialState,
  reducers: {
    getMediaImages: state => {
      state.isLoading = true;
    },
    getMediaImagesSuccess: (state, action) => {
      const {total_results = 0, photos = []} = action.payload;
      state.data = photos.map(data => {
        return {
          id: data.id,
          alt: data.alt,
          tiny: data.src.tiny || data.src.original,
          original: data.src.original
        };
      });
      state.total = total_results;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    getMediaImagesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    }
  }
});

export const {getMediaImages, getMediaImagesSuccess, getMediaImagesFailed} =
  mediaImageSlice.actions;

export default mediaImageSlice.reducer;
