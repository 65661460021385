import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import AddressAdvanced from './AddressAdvanced';
import AddressGeneral from './AddressGeneral';
import AddressOptions from './AddressOptions';

const AddressRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <AddressGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <AddressOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <AddressAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default AddressRightPanel;
