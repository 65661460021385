import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import {Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import LoadingButton from '../../../common/Button/LoadingButton';
import {
  formAddToFolder,
  formRemoveFromFolder,
  resetUpdateFormSuccess
} from '../../../state/features/folderForms/folderFormsSlice';
import {selectFormFolderData} from '../../../state/features/formFolder/formFolderSelector';
import {selectFolderForm} from '../../../state/features/folderForms/folderFormSelector';
import removeDuplicates from '../../../helpers/utils/removeDuplicates';

const FormAddToFolder = ({formIds = [], folderIds = [], onClose}) => {
  const dispatch = useDispatch();
  const {folders} = useSelector(selectFormFolderData);
  const {isFormUpdating, isFormUpdateSuccess} = useSelector(selectFolderForm);
  const [selectedFolderIds, setSelectedFolderIds] = useState([...folderIds]);

  useEffect(() => {
    if (isFormUpdateSuccess) {
      if (onClose) onClose();
      dispatch(resetUpdateFormSuccess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdateSuccess]);

  const handleChange = folderId => {
    const folderIds = [...selectedFolderIds];
    const folderIdIndex = folderIds.indexOf(folderId);

    if (folderIdIndex !== -1) {
      folderIds.splice(folderIdIndex, 1);
    } else {
      folderIds.push(folderId);
    }

    setSelectedFolderIds(folderIds);
  };

  const handleSave = () => {
    const newAddFolderIds = selectedFolderIds.filter(id => folderIds.indexOf(id) === -1);
    const newRemoveFolderIds = folderIds.filter(id => selectedFolderIds.indexOf(id) === -1);

    //  handle remove form from folder
    for (let folderId of newRemoveFolderIds) {
      for (let formId of formIds) {
        dispatch(formRemoveFromFolder({formId: formId, folderId: folderId}));
      }
    }

    const folderIdList = removeDuplicates([...newAddFolderIds, ...selectedFolderIds]);
    // handle form list add to folder list
    if (folderIdList.length > 0) {
      dispatch(formAddToFolder({folderIds: folderIdList, formIds: formIds}));
    }
  };

  return (
    <Box sx={{width: 260, p: 1}}>
      <Box sx={{height: 160, mb: 1}} className={'fbp_has_scroll'}>
        <FormGroup>
          {folders?.map(folder => {
            const checked = selectedFolderIds.indexOf(folder.id) !== -1;
            return (
              <FormControlLabel
                key={folder.id}
                control={<Checkbox checked={checked} onChange={() => handleChange(folder.id)} />}
                label={folder.name}
                sx={{ml: -0.5}}
              />
            );
          })}
        </FormGroup>
      </Box>

      <LoadingButton
        fullWidth
        size={'small'}
        color={'secondary'}
        loadingText={'Saving'}
        isLoading={isFormUpdating}
        onClick={handleSave}
      >
        Save
      </LoadingButton>
    </Box>
  );
};

export default FormAddToFolder;
