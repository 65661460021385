import React from 'react';
import TemplateFilterHeader from './TemplateFilterHeader';
import AdminTemplateTable from './AdminTemplateTable';
import '../../assets/scss/base/_variables.scss';

const TemplateBody = () => {
  return (
    <>
      {/* ## Template List Filters ## */}
      <TemplateFilterHeader />
      {/* ## Template List View ## */}
      <AdminTemplateTable />
    </>
  );
};

export default TemplateBody;
