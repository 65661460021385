import React from 'react';
import {Collapse, IconButton} from '@mui/material';
import {Alert} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const CloseAlert = ({
  size = 'small',
  title = '',
  severity = 'warning',
  hideCloseIcon = true,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
              style={{visibility: hideCloseIcon ? 'hidden' : 'visible'}}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severity}
          {...rest}
        >
          {title || children}
        </Alert>
      </Collapse>
    </div>
  );
};

export default CloseAlert;
