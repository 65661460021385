import React from 'react';
import {useSelector} from 'react-redux';
import {styled} from '@mui/material';
import Box from '@mui/material/Box';
import {selectTags} from '../../../state/features/tags/tagsSelector';

const ColorBox = styled(Box)(() => ({
  height: '6px',
  width: '24px',
  borderRadius: '20px',
  marginRight: '8px',
  marginBottom: '8px'
}));

const InboxTagColorBoxes = ({tagIds = []}) => {
  const tagList = useSelector(selectTags);

  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', mt: '8px'}}>
      {tagList
        ?.slice()
        .filter(tag => tagIds.includes(tag.id))
        .map(tagItem => (
          <ColorBox key={tagItem.id} sx={{backgroundColor: tagItem.color}} />
        ))}
    </Box>
  );
};

export default InboxTagColorBoxes;
