import {Stack, styled, Box, Typography} from '@mui/material';

export const FooterWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  padding: 'var(--fbp-theming-field-spacing) var(--fbp-theming-inner-gap)',
  zIndex: 1,
  display: 'grid',
  placeItems: 'center',
  borderTop: '1px solid var(--fbp-theming-sub-text-color)',
  [theme.breakpoints.down('sm')]: {
    padding: 'var(--fbp-theming-field-spacing) 15px'
  }
}));
export const FooterLinks = styled(Stack)(() => ({
  width: '100%',
  columnGap: '16px',
  flexWrap: 'wrap',
  marginBottom: '4px',
  '& div': {
    cursor: 'pointer',
    position: 'relative',
    borderBottom: '1px solid transparent',
    transition: 'all 0.3s ease',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '-7px',
      top: 'calc(50% - 1px)',
      width: '2px',
      height: '2px',
      borderRadius: '5px',
      backgroundColor: 'var(--fbp-theming-font-color)'
    },
    '&:hover': {borderBottom: '1px solid var(--fbp-theming-font-color)'},
    '&:first-of-type': {
      '&:before': {content: 'none'}
    }
  }
}));

export const EditedText = styled(Typography)(() => ({
  '& p': {margin: 0},
  '& li': {listStyleType: 'unset'},
  '& ul': {
    color: 'unset !important',
    fontSize: 'unset !important',
    lineHeight: 'unset !important',
    letterSpacing: 'unset !important',
    fontFamily: 'unset !important',
    fontStyle: 'unset !important',
    fontWeight: 'unset !important'
  },
  '& ul:not(.browser-default)': {padding: 'revert', margin: 'revert', listStyleType: 'unset'},
  '& ul:not(.browser-default)>li': {listStyleType: 'unset'}
}));
