import React from 'react';
import {Stack, Box} from '@mui/material';
import CustomBuilderInput from '../../../common/TextField/CustomBuilderInput';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import Styles from './HiddenField.module.scss';

const HiddenFieldComponent = ({field}) => (
  <Stack sx={{position: 'relative'}}>
    <Stack>
      <FormLabel mb={2}>{field.name}</FormLabel>
      <Box className={Styles.fbpEmailWrapper}>
        <CustomBuilderInput
          readOnly
          className={Styles.fbpEmailComponent}
          value={field.value}
          placeholder="Enter hidden field value"
        />
      </Box>
    </Stack>
    <HiddenFieldAlert />
  </Stack>
);

export default HiddenFieldComponent;
