import React from 'react';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideImageUploader from '../../Builder/RightComponents/RightSideImageUploader';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';

const HeadingImage = ({field, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideImageUploader
        text="Heading Image"
        title="Change the heading image"
        recommendedSize={"900 x 300"}
        onImageUpload={image => onChangeValue({image})}
      />

      {field.image && (
        <RightSideImagePreviewComponent
          text="Heading Image"
          title="Heading Image Preview"
          image={field.image}
          onRemove={() => onChangeValue({image: null})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(HeadingImage);
