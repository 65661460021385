import React from 'react';
import {Stack, styled} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';
import CustomPhoneInput from '../../../common/CustomPhoneInput';

const StyledBox = styled('div')(({theme}) => ({
  '& input': {
    padding: '5px 12px !important',
    borderRadius: '4px !important',
    color: 'var(--fbp-text-secondary) !important',
    border: '1px solid var(--fbp-other-outlined-border) !important'
  }
}));

const RightSidePhoneInput = ({text, title, unit = '', placeholder = 'Label', ...other}) => {
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <StyledBox>
        <CustomPhoneInput {...other} className="rightSideInput" />
      </StyledBox>
    </Stack>
  );
};

export default RightSidePhoneInput;
