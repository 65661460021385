import React from 'react';
import {Typography} from '@mui/material';
import Styles from './GlobalEmptyPage.module.scss';

const GlobalEmptyPage = ({image, icon, title, description}) => {
    return (
        <div className={Styles.wrapper}>
            {image && (
                <div className={Styles.imageWrapper}>
                    <img src={image} alt="Not Found"/>
                </div>
            )}
            {icon &&
                <div className={Styles.iconWrapper}>
                    {icon}
                </div>
            }
            <Typography variant="h5" mt={4}>
                {title}
            </Typography>
            <Typography
                variant="body1medium"
                color="text.secondary"
                sx={{maxWidth: '525px', textAlign: 'center'}}
            >
                {description}
            </Typography>
        </div>
    );
};

export default GlobalEmptyPage;
