import httpRequest from '../api/httpRequest';

class FromHeaderService {
  getFormHeaderData(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API_17 + 'form-settings',
      params
    );
  }

  updateFormHeaderData(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API_17 + 'form-settings/add', body);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FromHeaderService();
