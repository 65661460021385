import React from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Paper, Stack} from '@mui/material';
import CommonTitleAndEditor from './CommonTitleAndEditor';
import FooterSettingAccordion from '../../../common/Accordion/FooterSettingAccordion';
import {selectFormFooterData, selectFormFooterState} from '../../../state/features/formFooter/formFooterSelector';
import {updateFormFooterData} from '../../../state/features/formFooter/formFooterSlice';
import {FORM_SETTINGS_TYPE_FOOTER} from '../../../helpers/constant/formSettingsConstant';

const FooterSettingsItem = ({title = '', linkText = true, payload = {}, onChangePayload}) => {
  const {isUpdating} = useSelector(selectFormFooterState);

  const onChangeSettings = (data = {}) => {
    onChangePayload({...payload, ...data});
  };

  return (
    <FooterSettingAccordion
      title={title}
      expanded={false}
      status={payload.enable}
      onChangeStatus={status => onChangeSettings({enable: status})}
      disabled={isUpdating}
    >
      <CommonTitleAndEditor
        payload={payload}
        onChangePayload={onChangeSettings}
        linkText={linkText}
      />
    </FooterSettingAccordion>
  );
};

const FormFooterOptions = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const formFooterData = useSelector(selectFormFooterData);
  const {
    privacyPolicy = {},
    termsOfUse = {},
    disclosure = {},
    licensingInformation = {},
    aboutUs = {},
    contactUs = {},
    footerBottomContent = {}
  } = formFooterData || {};

  const onChangePayload = (payload = {}) => {
    let settings = {...formFooterData, ...payload};
    settings = JSON.stringify(settings);
    dispatch(updateFormFooterData({formId, settings: settings, type: FORM_SETTINGS_TYPE_FOOTER}));
  };

  return (
    <Paper elevation={0} className={'fbp_form_builder_footer'}>
      <Stack direction={'column'} spacing={2} pb={3} pt={0} px={3}>
        <FooterSettingsItem
          title={'Privacy Policy'}
          payload={privacyPolicy}
          onChangePayload={payload => onChangePayload({privacyPolicy: payload})}
        />
        <FooterSettingsItem
          title={'Terms of Use'}
          payload={termsOfUse}
          onChangePayload={payload => onChangePayload({termsOfUse: payload})}
        />
        <FooterSettingsItem
          title={'Disclosure'}
          payload={disclosure}
          onChangePayload={payload => onChangePayload({disclosure: payload})}
        />
        <FooterSettingsItem
          title={'Licensing Information'}
          payload={licensingInformation}
          onChangePayload={payload => onChangePayload({licensingInformation: payload})}
        />
        <FooterSettingsItem
          title={'About Us'}
          payload={aboutUs}
          onChangePayload={payload => onChangePayload({aboutUs: payload})}
        />
        <FooterSettingsItem
          title={'Contact Us'}
          payload={contactUs}
          onChangePayload={payload => onChangePayload({contactUs: payload})}
        />
        <FooterSettingsItem
          title={'Footer Bottom Content'}
          linkText={false}
          payload={footerBottomContent}
          onChangePayload={payload => onChangePayload({footerBottomContent: payload})}
        />
      </Stack>
    </Paper>
  );
};

export default FormFooterOptions;
