import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack, styled, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import BuilderRightManager from './BuilderRightManager';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';

const BuilderRightSide = styled(Box)(({theme}) => ({
  width: '310px',
  background: 'var(--fbp-success-contrast)',
  boxShadow: 'var(--fbp-elevation-2)',
  position: 'relative',
  zIndex: 11
}));

const BuilderRight = ({onHideRightSide, className, selectedField}) => {
  const theme = useTheme();
  const {selectedComponentType} = useSelector(state => state.builderFields);
  const selectedType = selectedField?.component || selectedComponentType || 1;
  return (
    <BuilderRightSide className={className}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{padding: '9px 16px', backgroundColor: theme.palette.secondary.main}}
      >
        <Box
          component="span"
          sx={{display: 'inline-flex', cursor: 'pointer'}}
          onClick={onHideRightSide}
        >
          {fbpIcons.fbpShowsidebarIcon}
        </Box>
        <Typography variant={'body2'} color="common.white">
          {ComponentConfig[selectedType].name} Properties
        </Typography>
      </Stack>
      <BuilderRightManager field={selectedField} selectedType={selectedType} />
    </BuilderRightSide>
  );
};

export default BuilderRight;
