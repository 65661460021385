import React from 'react';
import {useDispatch} from 'react-redux';
import {Stack} from '@mui/material';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import {CustomFileUploader} from '../../../common/FileUpload/CustomFileUploader';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbarSlice';
import withFieldChange from '../../../hoc/withFieldChange';
import FilePreview from './FilePreview';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from '../../../common/Icons/RequireIcon';

const FileUploadComponent = ({field, editMode, onChange}) => {
  const dispatch = useDispatch();
  const handleRemoveFile = index => {
    let clonnedFiles = [...field.files];
    clonnedFiles.splice(index, 1);
    onChange({files: clonnedFiles});
    return clonnedFiles.length;
  };

  const handleChangeFiles = files => {
    let oldFiles = [...field.files];
    let newFiles = [...files];

    newFiles.forEach(file => {
      let hasFile = oldFiles.find(oldFile => oldFile.lastModified === file.lastModified);
      if (!hasFile) {
        oldFiles.push(file);
      }
    });

    if (oldFiles.length > field.fileLimit) {
      dispatch(showErrorSnackbar(`Only ${field.fileLimit} files uploads allowed.`));
      return;
    }

    onChange({files: oldFiles});
  };

  return (
    <Stack direction="column" sx={{position: 'relative'}}>
      <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
        <FormLabel>
          {field.label}
          {field.required && <RequireIcon />}
        </FormLabel>
      </Stack>
      <ConnectPublicForm
        name={field.id}
        value={field.files.length}
        rules={field.required ? registerPatternBuilderFields.file : {}}
        render={(formOption, formMethods) => (
          <>
            <CustomFileUploader
              lazyUpload
              disabled={editMode || field.readOnly}
              className="builderFileUpload"
              preType={field.fileTypeOption}
              type={field.fileType}
              title={field.uploaderTitle}
              subText={field.uploaderSubText}
              fileLimit={field.fileLimit}
              onFileUpload={files => {
                formMethods?.setValue(field.id, files.length);
                if (formOption.formState.isSubmitted) {
                  formMethods?.trigger();
                }
                handleChangeFiles(files.files);
              }}
            />

            {formOption.fieldState.error?.message ? (
              <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
            ) : (
              <FormSubtext mt={1}>{field.helperText}</FormSubtext>
            )}

            <FilePreview
              files={field.files}
              onRemove={index => {
                let totalFiles = handleRemoveFile(index);
                formMethods?.setValue(field.id, totalFiles);
                if (formOption.formState.isSubmitted) {
                  formMethods?.trigger();
                }
              }}
            />
          </>
        )}
      />

      {field.hidden && <HiddenFieldAlert />}
    </Stack>
  );
};

export default withFieldChange(FileUploadComponent);
