import {alpha, Stepper, styled} from "@mui/material";

export const StyledNumberStepper = styled(Stepper)(({progressBarColor, backgroundColor}) => ({
    '& .MuiStepLabel-labelContainer': {
        display: 'none'
    },
    '& .MuiStepIcon-text': {
        fill: progressBarColor
    },
    '& .MuiStepLabel-iconContainer': {
        '&.Mui-disabled ': {
            '& .MuiStepIcon-text': {
                fill: alpha(progressBarColor, 0.3)
            },
            '& .MuiStepIcon-root': {
                color: alpha(backgroundColor, 0.3),
                border: `1px solid ${alpha(progressBarColor, 0.3)}`,
                borderRadius: '50%'
            }
        },
        '&.Mui-completed ': {
            '& .MuiStepIcon-text': {
                fill: progressBarColor
            },
            '& .MuiStepIcon-root': {
                fill: progressBarColor,
                backgroundColor: backgroundColor,
                borderRadius: '50%'
            }
        }
    },
    '& .MuiStepIcon-root': {
        '&.Mui-active ': {
            color: backgroundColor,
            border: `1px solid ${progressBarColor}`,
            borderRadius: '50%'
        }
    },
    '& .MuiStepConnector-root': {
        left: 'calc(-50% + 11px)',
        right: 'calc(50% + 12px)',
        '& .MuiStepConnector-line': {
            borderColor: progressBarColor
        },
        '&.Mui-active': {
            '& .MuiStepConnector-line': {
                borderColor: progressBarColor
            }
        },
        '&.Mui-disabled ': {
            '& .MuiStepConnector-line': {
                borderColor: alpha(progressBarColor, 0.3)
            }
        }
    }
}));
