import React from 'react';
import {styled} from '@mui/styles';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

const BoxStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  marginTop: '16px'
}));

const NoDataFoundPage = ({title, description}) => {
  return (
    <BoxStyled>
      <Typography variant={'h5'} color={'text.secondary'}>
        {title}
      </Typography>
      <Typography variant={'body2'}>{description}</Typography>
    </BoxStyled>
  );
};

export default NoDataFoundPage;
