import React, {useCallback, useState} from 'react';
import {styled, Box} from '@mui/material';
import FormListHeader from './FormListHeader';
import FormListBody from './FormListBody';
import SeoSettings from '../../common/SeoSettings';
import FormListLeftSide from './FormListLeftSide';
import FbpTabFormsTopbar from './FbpTabFormsTopbar';
import Styles from './FormList.module.scss';

const LayoutBox = styled(Box)(({theme}) => ({
  columnGap: '16px',
  height: 'calc(100% - 85px)',
  display: 'flex',
  '@media(max-width: 1300px)': {
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    ' .fbpBodyContent': {
      overflowY: 'auto'
    }
  }
}));

const FormListPage = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;
    if (width > 1300) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <Box sx={{height: 'calc(100vh - 96px)'}}>
      <SeoSettings
        title="Form builder - Form list"
        description="Form builder description"
        keywords="test,test1"
      />
      <FormListHeader />
      <LayoutBox
        className={`${isHideLeftSide ? Styles.fbpHideSidebar : ''} ${
          isShowLeftSideTab ? Styles.showTabSidebar : ''
        }`}
      >
        <Box className={`${Styles.fbpLeftSide} fbpLeftSide`}>
          <FormListLeftSide isHideLeftSide={isHideLeftSide} toggleLeftSide={toggleLeftSidePanel} isShowLeftSideTab={isShowLeftSideTab} />
          {/* For Tab Responsive */}
          <FbpTabFormsTopbar onChangeLeftSideTab={toggleLeftSidePanel} />
        </Box>

        <Box
          p={2}
          sx={{
            flex: 1,
            height: '100%',
            bgcolor: 'common.white',
            overflow: 'hidden',
            borderRadius: '4px'
          }}
        >
          <FormListBody />
        </Box>
      </LayoutBox>
    </Box>
  );
};

export default FormListPage;
