import {startOfWeek, endOfWeek, startOfMonth, endOfMonth} from 'date-fns';

export const getDatesByDuration = durationType => {
  const currentDate = new Date();

  if (durationType === 'thisWeek') {
    return [startOfWeek(currentDate, {weekStartsOn: 1}), endOfWeek(currentDate, {weekStartsOn: 1})];
  } else if (durationType === 'thisMonth') {
    return [startOfMonth(currentDate), endOfMonth(currentDate)];
  } else if (durationType === 'allTime') {
    return [new Date('2023-01-01'), currentDate];
  }
  return [currentDate, currentDate];
};
