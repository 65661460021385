import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: ''
};

const pipelineStageSlice = createSlice({
  name: 'pipelineStage',
  initialState: initialState,
  reducers: {
    getPipelineStageData: state => {
      state.isLoading = true;
    },
    getPipelineStageDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    getPipelineStageDataFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    }
  }
});

export const {getPipelineStageData, getPipelineStageDataSuccess, getPipelineStageDataFailed} =
  pipelineStageSlice.actions;

export default pipelineStageSlice.reducer;
