import React from 'react';
import {Avatar, Box, Paper, Skeleton, Stack, Typography} from '@mui/material';

const SingleReportCard = ({title, subtitle, amount=0, icon, color, isLoading=false}) => {
  return (
    <>
      <Paper elevation={0} sx={{p: 4, width: '100%'}}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Avatar variant={'circular'} sx={{bgcolor: color}}>
              {icon}
            </Avatar>
            <Box>
              <Typography variant={'subtitle1'} color={color} fontWeight={500}>
                {title}
              </Typography>
              <Typography variant={'body2'} color={'text.secondary'}>
                {subtitle}
              </Typography>
            </Box>
          </Stack>
          <Typography variant={'h5'} color={color}>
            {isLoading ? <Skeleton width={40} height={40} /> : amount}
          </Typography>
        </Stack>
      </Paper>
    </>
  );
};

export default SingleReportCard;
