import React from 'react';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const CompanyOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideSwitch
        text="Show Company Icon"
        title="Show the icon on the input"
        checked={field.inputIcon}
        onChange={value => onChangeValue({inputIcon: value})}
      />

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(CompanyOptions);
