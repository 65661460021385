import React from 'react';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import Box from '@mui/material/Box';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';

const HeadingGeneral = ({field, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Heading Text"
        title="Change the title text"
        value={field.heading}
        placeholder="Heading Text"
        onChange={e => onChangeValue({heading: e.target.value})}
      />

      <RightSideInput
        text="Sub-heading Text"
        title="Change the text below title"
        value={field.subHeading}
        placeholder="Sub Heading Text"
        onChange={e => onChangeValue({subHeading: e.target.value})}
      />

      <ThreeTabsWithTitle
        text="Heading Size"
        title="Change the size of the title"
        child1="Large"
        child2="Medium"
        child3="Small"
        value1="large"
        value2="medium"
        value3="small"
        selectedValue={field.size}
        onChange={value => onChangeValue({size: value})}
      />

      <ThreeTabsWithTitle
        text="Heading Alignment"
        title="Change alignment of the title"
        child1={fbpIcons.alignmentLeft}
        child2={fbpIcons.alignmentCenter}
        child3={fbpIcons.alignmentRight}
        value1="left"
        value2="center"
        value3="right"
        selectedValue={field.alignment}
        onChange={value => onChangeValue({alignment: value})}
      />

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(HeadingGeneral);
