import React from 'react';
import {Fade, IconButton, useTheme} from '@mui/material';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import {Close} from "@mui/icons-material";
import Styles from './styles/fbp-global-full-modal.module.scss';

export default function GlobalFullScreenModal({
  open = false,
  onClose,
  extra_props_active,
  stopBackDrop = false,
  className = '',
  headerChildren,
  children
}) {
  const theme = useTheme();

  const closeModal = () => {
    onClose();
  };

  let extra_props = {};
  if (extra_props_active !== undefined) {
    extra_props['disableEnforceFocus'] = true;
    extra_props['disableAutoFocus'] = true;
  }

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={stopBackDrop ? () => {} : closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        {...extra_props}
        sx={{'& .MuiBackdrop-root': {backgroundColor: theme.palette.other.backdrop}}}
      >
        <Fade in={open} direction="left">
          <div className={`${Styles.fbpGlobalModalWrapper} ${className ? className : ''}`}>
            <div id="fbpGlobalModal" className={Styles.fbpGlobalModalContainer}>
              <div className={Styles.fbpGlobalModalHeader}>
                <div>{headerChildren}</div>
                <IconButton color={'error'} onClick={onClose}>
                  <Close fontSize={'medium'} />
                </IconButton>
              </div>

              <div>{children}</div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
