import React from 'react';
import {Stack, Box} from '@mui/material';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import CustomDatePicker from '../../../common/PrevDatePicker/CustomDatePicker';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import ThreeTabs from '../../Builder/RightComponents/ThreeTabs';
import TwoTabsWithTitle from '../../Builder/RightComponents/TwoTabsWithTitle';

const DateOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'classic' && (
        <TwoTabsWithTitle
          text="View Type"
          title="Change the style of the date picker"
          child1="Default"
          child2="Static"
          value1="default"
          value2="static"
          selectedValue={field.viewType}
          onChange={value => onChangeValue({viewType: value})}
        />
      )}

      {(field.viewType === 'default' || theme.layout === 'card') && (
        <RightSideDropdown
          text="Date format"
          title="Change the format of the date"
          data={[
            {title: 'mm-dd-yyyy', value: 'MM/dd/yyyy'},
            {title: 'dd-mm-yyyy', value: 'dd/MM/yyyy'},
            {title: 'yyyy-mm-dd', value: 'yyyy/MM/dd'}
          ]}
          value={field.format}
          onChange={e => onChangeValue({format: e.target.value})}
        />
      )}

      <Stack direction="column" spacing={1} className="builderElementBasicStyle">
        <RightComponentTitle text={'Default Date'} title={'Set the default date on form load'} />
        <ThreeTabs
          child1="None"
          child2="Current"
          child3="Custom"
          value1="none"
          value2="current"
          value3="custom"
          selectedValue={field.defaultDate}
          onChange={value => onChangeValue({defaultDate: value})}
        />

        {field.defaultDate === 'custom' && (
          <CustomDatePicker value={field.value} onChange={value => onChangeValue({value: value})} />
        )}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(DateOptions);
