import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {Box, Stack} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import {Shuffle} from '@mui/icons-material';
import ConditionItem from './ConditionItem/ConditionItem';
import ConditionListBulkAction from './ConditionListBulkAction';
import CircleLoader from '../../LoadingView/CircleLoader';
import CoreButton from '../../../common/Button/CoreButton';
import CustomTitleBar from '../../../common/TitleBar/CustomTitleBar';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
import {CONDITION_PAGE_VIEW} from '../../../helpers/constant/formConditionConstant';
import {
  reorderCondition,
  setConditionCurrentPage,
  toggleSelectedCondition
} from '../../../state/features/conditionSetting/conditionSettingSlice';
import {selectConditionSettingState} from '../../../state/features/conditionSetting/conditionSettingSelector';
import {selectFieldLabelsAndOptionsById} from '../../../state/features/builderFields/builderSelector';

export default function ConditionsBody() {
  const dispatch = useDispatch();

  const {
    rules = [],
    selectedConditionIds,
    isUpdating = false,
    isLoading
  } = useSelector(selectConditionSettingState);
  const fieldLabelsById = useSelector(selectFieldLabelsAndOptionsById);

  const onClickAddNewCondition = () => {
    dispatch(setConditionCurrentPage(CONDITION_PAGE_VIEW.TYPE_SELECTION));
  };

  const handleSelectCondition = id => {
    dispatch(toggleSelectedCondition(id));
  };

  const onDragEnd = result => {
    if (result.source && result.destination && result.source.index !== result.destination.index) {
      dispatch(
        reorderCondition({
          sourceIndex: result.source.index,
          destinationIndex: result.destination.index
        })
      );
    }
  };

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <CircleLoader color={'secondary'} />;
  } else if (rules.length === 0) {
    content = (
      <GlobalEmptyPage
        icon={<EmptySubmissionFormIcon />}
        title={'CREATE YOUR FIRST CONDITION!'}
        description={'You don’t have any saved conditions yet, click here to add a new condition!'}
      />
    );
  } else if (rules.length > 0) {
    content = (
      <>
        {/* ## Condition list | bulk action or header view ## */}
        <ConditionListBulkAction />

        {/* ## Condition list view */}
        <Box sx={{width: '100%', mt: 3, position: 'relative'}}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={'conditionalDroppable'}>
              {provided => (
                <Stack ref={provided.innerRef} {...provided.droppableProps} spacing={3}>
                  {Array.isArray(rules) &&
                    rules.map((singleRule, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={'conditionDroppable_' + index}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Box
                              snapshot={snapshot}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <ConditionItem
                                key={index}
                                index={index}
                                dragHandler={provided.dragHandleProps}
                                singleRule={singleRule}
                                fieldLabelsById={fieldLabelsById}
                                checked={selectedConditionIds.includes(singleRule.id)}
                                handleSelectCondition={() => handleSelectCondition(singleRule.id)}
                              />
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </>
    );
  }

  return (
    <CustomTitleBar
      title={'Conditions'}
      content={'Add conditional logic to your form.'}
      icon={<Shuffle />}
      iconBg="#006df5"
    >
      <Box sx={{p: 3, height: '100%'}}>
        <CoreButton
          fullWidth
          size="large"
          variant="contained"
          startIcon={<AddCircleIcon />}
          onClick={onClickAddNewCondition}
        >
          Add a new condition
        </CoreButton>

        {/* ## Hidden backdrop apply when updating ## */}
        <Backdrop
          sx={{background: 'none', zIndex: theme => theme.zIndex.drawer + 1}}
          open={isUpdating}
        />

        {content}
      </Box>
    </CustomTitleBar>
  );
}
