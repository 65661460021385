import React from 'react';
import {Paper} from '@mui/material';
import TableToolbar from './TableToolbar';
import TableViewBody from './TableViewBody';

export default function TableView() {
  return (
    <Paper
      sx={{
        width: '100%',
        height: 'calc(100% - 48px)',
        overflow: 'hidden'
      }}
      elevation={0}
    >
      <TableToolbar />
      <TableViewBody />
    </Paper>
  );
}
