class InputValidation {
  phone(number = '', countryOrigin = '') {
    const pNum = number ? number.trim() : '';
    if (!pNum) {
      return {invalid: true, message: 'Phone number is required!'};
    } else {
      if (pNum.length < 10 || pNum.length > 16) {
        return {invalid: true, message: 'Phone number is not valid!'};
      } else {
        return {invalid: false, message: ''};
      }
    }
  }

  email(email = '') {
    const emailAddress = email ? email.trim() : '';
    if (!emailAddress) {
      return {invalid: true, message: 'email address is required!'};
    } else {
      const testRegEx = /^([a-zA-Z0-9_-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      const test = testRegEx.test(email);
      if (!test) {
        return {invalid: true, message: 'email address is not valid!'};
      } else {
        return {invalid: false, message: ''};
      }
    }
  }

  linkUrl(url = '') {
    const urlAddress = url ? url.trim() : '';
    if (!urlAddress) {
      return {invalid: true, message: 'url is required!'};
    } else {
      const testRegEx = /^(ftp|http|https):\/\/[^ "]+$/;
      const test = testRegEx.test(url);
      if (!test) {
        return {invalid: true, message: 'url is not valid!'};
      } else {
        return {invalid: false, message: ''};
      }
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new InputValidation();
