import {Box, IconButton, Stack, styled} from '@mui/material';

export const FormHeaderWrapper = styled(Stack)(({theme}) => ({
  width: '100%',
  padding: 'var(--fbp-theming-field-spacing) var(--fbp-theming-inner-gap)',
  borderBottom: `1px solid var(--fbp-theming-sub-text-color)`,
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: 'var(--fbp-theming-field-spacing) 15px'
  }
}));

export const HeaderRightSide = styled(Stack)(({theme}) => ({
  alignItems: 'flex-end',
  textAlign: 'right',
  width: '60%',
  '& span': {
    wordBreak: 'break-all'
  }
}));

export const LogoImageBoxinForm = styled(Box)(({theme}) => ({
  width: 'max-content',
  maxWidth: '210px',
  height: '100%',
  maxHeight: '70px',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '90px'
  }
}));

export const LogoImageBox = styled(Box)(({theme}) => ({
  width: '100%',
  height: '120px',
  position: 'relative',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  }
}));

export const IconButtonStyled = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  top: 0,
  right: 0
}));
