import {COMPONENT_TYPE} from './ComponentType';

const Prototype = {
  [COMPONENT_TYPE.HEADING]: {
    component: COMPONENT_TYPE.HEADING,
    heading: 'Heading 01',
    subHeading: 'Type a sub header',
    size: 'large',
    alignment: 'left',
    hidden: false
  },
  [COMPONENT_TYPE.SUBMIT_BUTTON]: {
    component: COMPONENT_TYPE.SUBMIT_BUTTON,
    nextText: 'Next Page',
    backText: 'Back Page',
    submitText: 'Submit',
    alignment: 'center',
    cloneableField: false
  },
  [COMPONENT_TYPE.FULL_NAME]: {
    component: COMPONENT_TYPE.FULL_NAME,
    label: 'Name',
    description: 'Enter your name',
    required: false,
    hidden: false,
    prefixValue: 'Mr',
    prefixEnabled: true,
    prefixHelperText: 'Prefix',
    firstNameHelperText: 'First Name',
    firstNamePlaceholder: 'First Name',
    middleNameEnabled: true,
    middleNameHelperText: 'Middle Name',
    middleNamePlaceholder: 'Middle Name',
    lastNameHelperText: 'Last Name',
    lastNamePlaceholder: 'Last Name',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.EMAIL]: {
    component: COMPONENT_TYPE.EMAIL,
    label: 'Email Address',
    description: 'Enter a valid email',
    required: false,
    helperText: 'example@example.com',
    labelAlignment: 'top',
    hidden: false,
    inputIcon: true,
    shrink: false,
    width: 'auto',
    placeholder: 'Email Address',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.PHONE]: {
    component: COMPONENT_TYPE.PHONE,
    label: 'Phone Number',
    description: 'Enter your phone number',
    required: false,
    helperText: 'Enter phone number.',
    labelAlignment: 'top',
    hidden: false,
    inputIcon: true,
    shrink: false,
    width: 'auto',
    placeholder: '(000) 000 - 0000',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.ADDRESS]: {
    component: COMPONENT_TYPE.ADDRESS,
    label: 'Address',
    description: 'Enter your address',
    required: false,
    hidden: false,
    streetAddressEnabled: true,
    streetAddressHelperText: 'Street Address',
    streetAddressPlaceholder: '',
    streetAddress2Enabled: true,
    streetAddress2HelperText: 'Street Address Line 2',
    streetAddress2Placeholder: '',
    cityEnabled: true,
    cityHelperText: 'City',
    cityPlaceholder: '',
    stateEnabled: true,
    stateHelperText: 'State / Province',
    statePlaceholder: '',
    zipEnabled: true,
    zipHelperText: 'Postal / Zip Code',
    zipPlaceholder: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.COMPANY]: {
    component: COMPONENT_TYPE.COMPANY,
    label: 'Company Name',
    description: 'Enter your company name',
    required: false,
    helperText: 'Company Name',
    labelAlignment: 'top',
    hidden: false,
    inputIcon: true,
    shrink: false,
    width: 'auto',
    placeholder: 'Company Name',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.BIRTHDAY]: {
    component: COMPONENT_TYPE.BIRTHDAY,
    label: 'Birthday',
    description: 'Enter your DOB',
    required: false,
    helperText: '',
    labelAlignment: 'top',
    separator: '/',
    viewType: 'default',
    format: 'MM/dd/yyyy',
    hidden: false,
    inputIcon: true,
    shrink: false,
    width: 'auto',
    defaultDate: 'current',
    value: null,
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.NOTE]: {
    component: COMPONENT_TYPE.NOTE,
    label: 'Note',
    description: 'Enter note here',
    required: false,
    helperText: 'Enter Note Here',
    labelAlignment: 'top',
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: 'Type here...',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.SHORT_ANSWER]: {
    component: COMPONENT_TYPE.SHORT_ANSWER,
    label: 'Type a question',
    description: 'Enter text here',
    required: false,
    helperText: 'Type a sublabel',
    labelAlignment: 'top',
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: 'Your text here',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.LONG_ANSWER]: {
    component: COMPONENT_TYPE.LONG_ANSWER,
    label: 'Type a question',
    description: 'Enter text here',
    required: false,
    helperText: 'Type a sub header',
    labelAlignment: 'top',
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: 'Write answer',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.PHONE_INPUT]: {
    component: COMPONENT_TYPE.PHONE_INPUT,
    label: 'Phone Number',
    description: 'Enter phone number',
    required: false,
    helperText: 'Type a sub header',
    labelAlignment: 'top',
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: '(000) 000 - 0000',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.NUMBER]: {
    component: COMPONENT_TYPE.NUMBER,
    label: 'Number',
    description: 'Enter number here',
    required: false,
    helperText: 'Type a sub header',
    labelAlignment: 'top',
    limitEntry: false,
    minimumLimit: 0,
    maximumLimit: 1000,
    step: 1,
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: 'Enter number here',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.LINK]: {
    component: COMPONENT_TYPE.LINK,
    label: 'URL',
    description: 'Enter link here',
    required: false,
    helperText: 'https://www.example.com',
    labelAlignment: 'top',
    hidden: false,
    shrink: false,
    width: 'auto',
    placeholder: 'Enter URL Here',
    value: '',
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.DATE]: {
    component: COMPONENT_TYPE.DATE,
    label: 'Date',
    description: 'Enter date here',
    required: false,
    helperText: '',
    labelAlignment: 'top',
    separator: '/',
    viewType: 'default',
    format: 'MM/dd/yyyy',
    hidden: false,
    inputIcon: true,
    shrink: false,
    width: 'auto',
    defaultDate: 'current',
    value: null,
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.TIME]: {
    component: COMPONENT_TYPE.TIME,
    label: 'Time',
    description: 'Enter time here',
    required: false,
    helperText: '',
    labelAlignment: 'top',
    viewType: 'default',
    format: '12hr',
    defaultTime: 'current',
    hidden: false,
    shrink: false,
    width: 'auto',
    value: null,
    readOnly: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.YES_NO]: {
    component: COMPONENT_TYPE.YES_NO,
    label: 'Type a question',
    description: 'Choose your answer',
    required: false,
    helperText: '',
    labelAlignment: 'top',
    hidden: false,
    positiveText: 'Yes',
    negativeText: 'No',
    shrink: false,
    width: 'auto',
    value: '',
    hoverText: ''
  },
  [COMPONENT_TYPE.RADIO]: {
    component: COMPONENT_TYPE.RADIO,
    label: 'Type a question',
    description: 'You can choose only one',
    required: false,
    helperText: '',
    fieldStyle: 'list',
    labelAlignment: 'top',
    hidden: false,
    value: '',
    readOnly: false,
    hoverText: '',
    options: []
  },
  [COMPONENT_TYPE.CHECKBOX]: {
    component: COMPONENT_TYPE.CHECKBOX,
    label: 'Type a question',
    description: 'You can choose multiple',
    required: false,
    helperText: '',
    fieldStyle: 'list',
    labelAlignment: 'top',
    hidden: false,
    readOnly: false,
    hoverText: '',
    options: []
  },
  [COMPONENT_TYPE.DROPDOWN]: {
    component: COMPONENT_TYPE.DROPDOWN,
    label: 'Type a question',
    description: 'Choose one option',
    required: false,
    helperText: 'Select an option',
    labelAlignment: 'top',
    placeholder: 'Select an option',
    hidden: false,
    value: '',
    readOnly: false,
    hoverText: '',
    options: [],
    displayEmpty: true
  },
  [COMPONENT_TYPE.FILE_UPLOAD]: {
    component: COMPONENT_TYPE.FILE_UPLOAD,
    label: 'File Upload',
    description: 'Upload your file',
    required: false,
    helperText: '',
    hidden: false,
    fileTypeOption: '',
    fileType: '',
    hoverText: '',
    uploaderTitle: 'Click here to upload',
    uploaderSubText: 'or drag & drop file here',
    fileLimit: 1,
    files: []
  },
  [COMPONENT_TYPE.HIDDEN_FIELD]: {
    component: COMPONENT_TYPE.HIDDEN_FIELD,
    name: 'Enter field name',
    value: '',
    hidden: true
  },
  [COMPONENT_TYPE.PARAGRAPH]: {
    component: COMPONENT_TYPE.PARAGRAPH,
    text: 'Paragraph here \nChange it from here or right side properties panel. :)',
    shrink: false,
    width: 'auto'
  },
  [COMPONENT_TYPE.IMAGE]: {
    component: COMPONENT_TYPE.IMAGE,
    image: null,
    description: 'Description here...',
    positioning: 'default',
    alignment: 'center',
    objectFit: 'cover',
    hidden: false,
    alt: '',
    hoverText: ''
  },
  [COMPONENT_TYPE.VIDEO]: {
    component: COMPONENT_TYPE.VIDEO,
    video: null,
    description: 'Description here...',
    positioning: 'default',
    alignment: 'center',
    hidden: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.AUDIO]: {
    component: COMPONENT_TYPE.AUDIO,
    audio: null,
    description: 'Description here...',
    positioning: 'default',
    alignment: 'center',
    hidden: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.EMBED]: {
    component: COMPONENT_TYPE.EMBED,
    link: '',
    topDescription: 'Description here...',
    embedWidth: 400,
    width: 'auto',
    height: 350,
    alignment: 'center',
    hidden: false,
    title: '',
    description: '',
    showLink: false,
    hoverText: ''
  },
  [COMPONENT_TYPE.PROGRESSBAR]: {
    component: COMPONENT_TYPE.PROGRESSBAR,
    view: '1',
    color: '#4941c7',
    backgroundColor: '#D2CCFF',
    sticky: true,
    hidden: true,
    cloneableField: false,
    calculationByFields: false
  },
  [COMPONENT_TYPE.DISCLAIMER]: {
    component: COMPONENT_TYPE.DISCLAIMER,
    label: 'Consent',
    description: '',
    required: true,
    labelAlignment: 'top',
    hidden: false,
    helperText: '',
    hoverText: '',
    shrink: false,
    width: 'auto',
    checkboxLabel:'I agree to the privacy policy',
    value: false
  },
};

export default Prototype;
