import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Grid, Stack, Typography, useTheme} from '@mui/material';
import CoreButton from '../../common/Button/CoreButton';
import LoadingButton from '../../common/Button/LoadingButton';
import CoreTextField from '../../common/TextField/CoreTextField';
import CoreAutoComplete from '../../common/Autocomplete/CoreAutoComplete';
import {registerPatternTemplateCategory} from '../../helpers/constant/registerPattern';
import {castArray} from '../../helpers/utils/castArray';
import {selectAdminTemplateCategories} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import {
  selectSelectedTemplateIds,
  selectTemplates
} from '../../state/features/adminTemplates/adminTemplatesSelector';
import {
  addTemplateToSubCategory,
  moveTemplateToSubCategory,
  resetAddingSuccess,
  resetMovingSuccess
} from '../../state/features/adminTemplates/adminTemplatesSlice';

const AddOrMoveTemplateToSubCategory = ({
  onClose,
  templateInfo = {},
  categoryId,
  isAdd = false,
  isBulk = false
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedTemplateIds = useSelector(selectSelectedTemplateIds);
  const {isMoving, isMoveSuccess, isAdding, isAddSuccess} = useSelector(selectTemplates);
  const {handleSubmit, setValue, control} = useForm();
  const {data: categoryList} = useSelector(selectAdminTemplateCategories);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (categoryId) {
      const category = categoryList.find(category => category.id === categoryId);
      setSelectedCategory(category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (isMoveSuccess || isAddSuccess) {
      dispatch(resetMovingSuccess());
      dispatch(resetAddingSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoveSuccess, isAddSuccess]);

  // ## Form Submit Action
  const onSubmit = data => {
    if (templateInfo?.id && !isBulk) {
      if (isAdd) {
        dispatch(
          addTemplateToSubCategory({
            templateIds: castArray(templateInfo.id),
            subCategoryIds: castArray(data.subCategory?.id)
          })
        );
      } else {
        dispatch(
          moveTemplateToSubCategory({
            templateIds: castArray(templateInfo.id),
            subcategoryId: data.subCategory?.id
          })
        );
      }
    } else if (isBulk) {
      // for Bulk actions
      if (isAdd) {
        dispatch(
          addTemplateToSubCategory({
            templateIds: castArray(selectedTemplateIds),
            subCategoryIds: castArray(data.subCategory?.id)
          })
        );
      } else {
        dispatch(
          moveTemplateToSubCategory({
            templateIds: castArray(selectedTemplateIds),
            subcategoryId: data.subCategory?.id
          })
        );
      }
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, backgroundColor: theme.palette.background.paper, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={'category'}
            defaultValue={selectedCategory}
            render={({field: {value, onChange}, fieldState: {invalid, error}, ...field}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Select Category
                </Typography>
                <CoreAutoComplete
                  options={categoryList}
                  getOptionLabel={option => option.categoryName}
                  value={value}
                  onChange={(_, data) => {
                    onChange(data);
                    setSelectedCategory(data);
                    // after category select, do null subCategory
                    setValue('subCategory', null);
                  }}
                  renderInput={params => (
                    <CoreTextField
                      {...params}
                      size={'small'}
                      placeholder={'Choose category'}
                      error={!!(invalid && error.message)}
                      helperText={invalid && error.message}
                    />
                  )}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.categoryId || null}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name={'subCategory'}
            defaultValue={null}
            render={({field: {value, onChange}, fieldState: {invalid, error}, ...field}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Select sub-category
                </Typography>
                <CoreAutoComplete
                  options={selectedCategory?.subCategory || []}
                  getOptionLabel={option => option.name}
                  value={value}
                  onChange={(_, data) => {
                    onChange(data);
                  }}
                  renderInput={params => (
                    <CoreTextField
                      {...params}
                      size={'small'}
                      placeholder={'Choose sub-category'}
                      error={!!(invalid && error.message)}
                      helperText={invalid && error.message}
                    />
                  )}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.subCategory || null}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isMoving || isAdding}
          loadingText={isAdd ? 'Adding...' : 'Moving...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          {isAdd ? 'Add' : 'Move'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrMoveTemplateToSubCategory;
