import httpRequest from '../api/httpRequest';

class TemplatePublishService {
  getTemplatePublishData(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/settings/fetch',
      params
    );
  }

  updateTemplatePublishData(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/update', body);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TemplatePublishService();
