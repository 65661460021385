import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
  addAdminTemplateCategoryFailed,
  addAdminTemplateCategorySuccess,
  addAdminTemplateSubCategoryFailed,
  addAdminTemplateSubCategorySuccess,
  deleteAdminTemplateCategorySuccess,
  deleteAdminTemplateSubCategorySuccess,
  getAdminTemplateCategoriesFailed,
  getAdminTemplateCategoriesSuccess,
  moveAdminSubCategoryToCategoryFailed,
  moveAdminSubCategoryToCategorySuccess,
  updateAdminTemplateCategoryFailed,
  updateAdminTemplateCategorySuccess,
  updateAdminTemplateSubCategoryFailed,
  updateAdminTemplateSubCategorySuccess
} from './adminTemplateCategoriesSlice';
import AdminTemplateCategoryService from '../../../services/AdminTemplateCategory.service';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';

function* adminTemplateCategoriesWatcher() {
  yield takeEvery(
    'adminTemplateCategories/getAdminTemplateCategories',
    getAdminTemplateCategoriesSaga
  );
  yield takeEvery(
    'adminTemplateCategories/getAdminTemplateCategoriesSilently',
    getAdminTemplateCategoriesSaga
  );
  yield takeEvery('adminTemplateCategories/addAdminTemplateCategory', addAdminTemplateCategorySaga);
  yield takeEvery(
    'adminTemplateCategories/updateAdminTemplateCategory',
    updateAdminTemplateCategorySaga
  );
  yield takeEvery(
    'adminTemplateCategories/deleteAdminTemplateCategory',
    deleteAdminTemplateCategorySaga
  );
  yield takeEvery(
    'adminTemplateCategories/addAdminTemplateSubCategory',
    addAdminTemplateSubCategorySaga
  );
  yield takeEvery(
    'adminTemplateCategories/updateAdminTemplateSubCategory',
    updateAdminTemplateSubCategorySaga
  );
  yield takeEvery(
    'adminTemplateCategories/deleteAdminTemplateSubCategory',
    deleteAdminTemplateSubCategorySaga
  );
  yield takeEvery(
    'adminTemplateCategories/moveAdminSubCategoryToCategory',
    moveAdminSubCategoryToCategorySaga
  );
}

function* getAdminTemplateCategoriesSaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.getAdminTemplateCategories,
      action.payload
    );

    if (response.success) {
      yield put(getAdminTemplateCategoriesSuccess(response.data));
    } else {
      yield put(getAdminTemplateCategoriesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addAdminTemplateCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.addAdminTemplateCategory,
      action.payload
    );

    if (response.success) {
      yield put(addAdminTemplateCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(addAdminTemplateCategoryFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateAdminTemplateCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.updateAdminTemplateCategory,
      action.payload
    );

    if (response.success) {
      yield put(updateAdminTemplateCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(updateAdminTemplateCategoryFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteAdminTemplateCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.deleteAdminTemplateCategory,
      action.payload
    );

    if (response.success) {
      yield put(deleteAdminTemplateCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addAdminTemplateSubCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.addAdminTemplateSubCategory,
      action.payload
    );

    if (response.success) {
      yield put(addAdminTemplateSubCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(addAdminTemplateSubCategoryFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateAdminTemplateSubCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.updateAdminTemplateSubCategory,
      action.payload
    );

    if (response.success) {
      yield put(updateAdminTemplateSubCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(updateAdminTemplateSubCategoryFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteAdminTemplateSubCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.deleteAdminTemplateSubCategory,
      action.payload
    );

    if (response.success) {
      yield put(deleteAdminTemplateSubCategorySuccess(response.message));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* moveAdminSubCategoryToCategorySaga(action) {
  try {
    const response = yield call(
      AdminTemplateCategoryService.moveAdminSubCategoryToCategory,
      action.payload
    );

    if (response.success) {
      yield put(moveAdminSubCategoryToCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(moveAdminSubCategoryToCategoryFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* adminTemplateCategoriesSaga() {
  yield all([adminTemplateCategoriesWatcher()]);
}
