import { COMPONENT_TYPE } from "../builderConstant/ComponentType";

export const checkHasProgressBar = fields => {
  for (let i = 0; i < fields.length; i++) {
    for (let j = 0; j < fields[i].pageItems.length; j++) {
      if (fields[i].pageItems[j].component === COMPONENT_TYPE.PROGRESSBAR) {
        return fields[i].pageItems[j];
      }
    }
  }

  return false;
};
