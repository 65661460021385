import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';

const FileUploadOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideDropdown
        text="File type"
        title="Predefined file type"
        data={[
          {title: 'All', value: ''},
          {title: 'Image', value: 'image'},
          {title: 'Video', value: 'video'},
          {title: 'Audio', value: 'audio'},
          {title: 'Document (PDF)', value: 'document'},
          {title: 'Custom', value: 'custom'}
        ]}
        value={field.fileTypeOption}
        onChange={e => onChangeValue({fileTypeOption: e.target.value})}
      />

      {field.fileTypeOption === 'custom' && (
        <RightSideInput
          text="Enter File Type"
          title="Define the file type"
          value={field.fileType}
          placeholder=".mp3, .mp4, .svg, .jpg, .png"
          onChange={e => onChangeValue({fileType: e.target.value})}
        />
      )}

      <RightSideDropdown
        text="Limit Number of Files"
        title="Total number of files can upload at a time"
        data={[
          {title: '1', value: 1},
          {title: '2', value: 2},
          {title: '3', value: 3},
          {title: '4', value: 4},
          {title: '5', value: 5}
        ]}
        value={field.fileLimit}
        onChange={e => onChangeValue({fileLimit: e.target.value})}
      />

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(FileUploadOptions);
