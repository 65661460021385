import React from 'react';
import {Stack, Typography} from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DehazeIcon from '@mui/icons-material/Dehaze';
import {UnfoldLess, UnfoldMore} from '@mui/icons-material';
import EachAction from './EachAction';
import EachCondition from './EachCondition';
import useBoolean from '../../../../hooks/useBoolean';
import {
  BracesStyle,
  ColoredSpanStyle,
  ConditionTypeStack,
  FoldMoreLessIconWrapper
} from './ConditionStyles';
import {CONDITION_COLORS, CONDITION_LINK} from '../../../../helpers/constant/formConditionConstant';

const MAX_ITEM = 5;

const ConditionItemContent = ({
  index,
  singleRule,
  onClick,
  conditionTypeProperties = {},
  fieldLabelsById,
  dragHandler
}) => {
  const {condition = [], action = [], type, link} = singleRule || {};
  const {value: showAll, toggle: toggleShowAll} = useBoolean(false);

  let conditionList = [];
  let actionList = [];
  let moreConditionContent = null;
  let moreActionContent = null;

  if (showAll) {
    // if showAll is true then show all the condition and action list
    conditionList = condition.slice(0);
    actionList = action.slice(0);
  } else {
    // if showAll is now true then max 5 condition or action item list show
    conditionList = condition.slice(0, MAX_ITEM);
    actionList = action.slice(0, MAX_ITEM);

    // helper text for hide condition list
    if (condition?.length > MAX_ITEM) {
      moreConditionContent = (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <ArrowRightAltIcon htmlColor={conditionTypeProperties.color} />
          <Typography component={'span'} variant={'body1medium'} color={'text.primary'}>
            ...and{' '}
          </Typography>
          <ColoredSpanStyle
            component={'span'}
            variant={'body1medium'}
            color={CONDITION_COLORS[type]}
          >
            {condition.length - MAX_ITEM}
          </ColoredSpanStyle>{' '}
          <Typography component={'span'} variant={'body1medium'} color={'text.primary'}>
            other
          </Typography>
          <ColoredSpanStyle
            component={'span'}
            variant={'body1medium'}
            color={CONDITION_COLORS[type]}
          >
            IF
          </ColoredSpanStyle>
          <Typography component={'span'} variant={'body1medium'} color={'text.primary'}>
            conditions...
          </Typography>
        </Stack>
      );
    }

    // helper text for hide action list
    if (action?.length > MAX_ITEM) {
      moreActionContent = (
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <ArrowRightAltIcon htmlColor={conditionTypeProperties.color} />
          <Typography component={'span'} variant={'body1medium'} color={'text.primary'}>
            ...and{' '}
          </Typography>
          <ColoredSpanStyle
            component={'span'}
            variant={'body1medium'}
            color={CONDITION_COLORS[type]}
          >
            {action.length - MAX_ITEM}
          </ColoredSpanStyle>{' '}
          <Typography component={'span'} variant={'body1medium'} color={'text.primary'}>
            more affected fields...
          </Typography>
        </Stack>
      );
    }
  }

  return (
    <>
      <Stack
        direction={'row'}
        sx={{opacity: singleRule.disabled ? 0.2 : 1, width: '100%'}}
        onClick={onClick}
      >
        <ConditionTypeStack sx={{backgroundColor: conditionTypeProperties.color}} {...dragHandler}>
          <DehazeIcon />
          {conditionTypeProperties.icon}
          <Typography variant={'body2'}>{index + 1}</Typography>
        </ConditionTypeStack>
        <Stack px={2} py={2} sx={{width: '100%'}} spacing={0.5}>
          {conditionList.map((eachCondition, conditionIndex) => (
            <Stack key={conditionIndex} direction={'row'} alignItems={'center'} spacing={1}>
              <ArrowRightAltIcon htmlColor={conditionTypeProperties.color} />
              <Typography variant={'h6'} sx={{fontStyle: 'italic', fontWeight: '300'}}>
                {conditionIndex === 0 ? 'If' : link === CONDITION_LINK.ANY ? 'Or' : 'And'}
              </Typography>
              <BracesStyle>(</BracesStyle>
              <EachCondition
                ruleType={type}
                condition={eachCondition}
                fieldLabelsById={fieldLabelsById}
              />
              <BracesStyle>)</BracesStyle>
            </Stack>
          ))}
          {moreConditionContent}

          {actionList.map((eachAction, actionIndex) => (
            <EachAction
              key={actionIndex}
              ruleType={type}
              action={eachAction}
              fieldLabelsById={fieldLabelsById}
            />
          ))}
          {moreActionContent}
        </Stack>
      </Stack>

      <FoldMoreLessIconWrapper onClick={toggleShowAll}>
        {(condition.length > MAX_ITEM || action.length > MAX_ITEM) &&
          (showAll ? <UnfoldLess color={'secondary'} /> : <UnfoldMore color={'secondary'} />)}
      </FoldMoreLessIconWrapper>
    </>
  );
};

export default ConditionItemContent;
