import {all, put} from 'redux-saga/effects';
import {call, select, takeEvery} from '@redux-saga/core/effects';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import ExportedFileService from '../../../services/ExportedFile.service';
import {
  deletedExportedFileFailed,
  deletedExportedFileSuccess,
  getExportedFiles,
  getExportedFilesFailed,
  getExportedFilesSuccess
} from './exportedFileSlice';
import {selectExportedFile} from './exportedFileSelector';

function* exportedFileWatcher() {
  yield takeEvery('exportedFile/getExportedFiles', getExportedFilesSaga);
  yield takeEvery('exportedFile/deletedExportedFile', deletedExportedFileSaga);
}

function* getExportedFilesSaga(action) {
  try {
    const response = yield call(ExportedFileService.getExportedFiles, action.payload);

    if (response.success) {
      yield put(getExportedFilesSuccess(response.data));
    } else {
      yield put(getExportedFilesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deletedExportedFileSaga(action) {
  try {
    const response = yield call(ExportedFileService.deletedExportedFile, action.payload);

    if (response.success) {
      yield put(deletedExportedFileSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));

      //Refetching the table data
      const {limit, page, filters, data} = yield select(selectExportedFile);
      const pageNumber = data.length || page === 1 ? page : page - 1;

      yield put(
        getExportedFiles({
          searchText: filters.searchText,
          page: pageNumber,
          limit: limit
        })
      );
    } else {
      yield put(deletedExportedFileFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* exportedFileSaga() {
  yield all([exportedFileWatcher()]);
}
