import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack, styled} from '@mui/material';
import {StyledText, SwitchWrapper} from './DeviceSwitchStyles';
import EmbeddedForm from "./EmbeddedForm";
import StandardViewEmbed from './StandardViewEmbed';
import {EMBED_MODES} from '../../../../helpers/constant/embedConstant';
import {selectEmbedSettings} from '../../../../state/features/embedSettings/embedSettingsSelector';

const StyledBox = styled(Box)(({theme, viewmode}) => ({
  width: viewmode === 'mobile' ? '70%' : '100%',
  maxWidth: viewmode === 'mobile' ? '600px' : '100%',
  height: '100%',
  borderRadius: '6px',
  boxShadow:
    '0px 3px 5px -1px rgba(148, 157, 178, 0.2), 0px 5px 8px rgba(148, 157, 178, 0.14), 0px 1px 14px rgba(148, 157, 178, 0.12)',
  transition: 'all 0.3s ease',
  padding: theme.spacing(2)
}));

const EmbedPreview = () => {
  const {prototype = {}, currentEmbedMode, embedCode} = useSelector(selectEmbedSettings);
  const {mode} = prototype[currentEmbedMode] || {};

  const [viewMode, setViewMode] = useState('desktop');

  const viewModeHandler = mode => {
    setViewMode(mode);
  };

  // decide what to render
  let content = null;
  if(mode === EMBED_MODES.STANDARD) {
    content = <StandardViewEmbed embedCode={embedCode}/>
  } else {
    content = <EmbeddedForm embedCode={embedCode}/>
  }

  return (
    <Stack alignItems="center" sx={{width: '100%', height: '100%'}}>
      <StyledBox viewmode={viewMode} sx={mode === EMBED_MODES.SIDE_TAB && {p: 0}} >
        {content}
      </StyledBox>

      <Box mt={2} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <SwitchWrapper direction={'row'}>
          <StyledText
            variant="button"
            className={viewMode === 'desktop' && 'activeDevice'}
            onClick={() => viewModeHandler('desktop')}
          >
            Desktop
          </StyledText>
          <StyledText
            variant="button"
            className={viewMode === 'mobile' && 'activeDevice'}
            onClick={() => viewModeHandler('mobile')}
          >
            Mobile
          </StyledText>
        </SwitchWrapper>
      </Box>
    </Stack>
  );
};
export default EmbedPreview;
