import React from 'react';
import Box from '@mui/material/Box';
import {FormText} from '../../../common/Form-UI/FormText';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewParagraphComponent = ({onClick, field, currentId, editMode}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box sx={{textAlign: 'justify', wordBreak: 'break-all'}}>
        <FormText sx={{color: 'var(--fbp-theming-font-color) '}}>{field.text}</FormText>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default CardViewParagraphComponent;
