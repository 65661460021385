import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import {Collapse, List, ListItemButton, ListItemText, styled} from '@mui/material';
import CountChip from '../../../common/Chip/CountChip';
import {setTemplateStatus} from '../../../state/features/templates/templatesSlice';
import {
  selectSelectedCategory,
  selectSelectedSubCategory
} from '../../../state/features/templateCategories/templateCategoriesSelector';
import {
  setSelectedCategory,
  setSelectedSubCategory
} from '../../../state/features/templateCategories/templateCategoriesSlice';

const ListItemButtonStyled = styled(ListItemButton)(({theme}) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  borderRadius: '8px'
}));

const FormTemplateCategoryListItem = ({categoryItem}) => {
  const dispatch = useDispatch();

  const selectedCategory = useSelector(selectSelectedCategory);
  const selectedSubCategory = useSelector(selectSelectedSubCategory);

  const handleClickCategory = (id, categoryName) => {
    if (id === selectedCategory?.id) {
      dispatch(setSelectedCategory({id: '', categoryName: categoryName}));
    } else {
      dispatch(setSelectedCategory({id: id, categoryName: categoryName}));
    }
  };

  const handleClickSubCategory = subCategory => {
    dispatch(setSelectedSubCategory(subCategory));
    dispatch(setTemplateStatus(0));
  };

  const isSelectedCategory = categoryItem.id === selectedCategory?.id;
  return (
    <>
      <ListItemButtonStyled
        sx={{pl: 1}}
        selected={isSelectedCategory}
        onClick={() => handleClickCategory(categoryItem.id, categoryItem.categoryName)}
      >
        {isSelectedCategory ? (
          <ExpandLess color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
        ) : (
          <ExpandMore color={'action'} fontSize={'small'} sx={{mr: 0.5}} />
        )}
        <ListItemText primary={categoryItem.categoryName} />
        <CountChip label={categoryItem.count} />
      </ListItemButtonStyled>

      {categoryItem.subCategory &&
        categoryItem.subCategory?.length > 0 &&
        categoryItem.subCategory.map(subCategory => (
          <Collapse key={subCategory.id} in={isSelectedCategory} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButtonStyled
                sx={{pl: 4}}
                selected={selectedSubCategory?.id === subCategory.id}
                onClick={() => handleClickSubCategory(subCategory)}
              >
                <ListItemText secondary={subCategory.name} />
                <CountChip className={'countChip'} label={subCategory.count} />
              </ListItemButtonStyled>
            </List>
          </Collapse>
        ))}
    </>
  );
};

export default React.memo(FormTemplateCategoryListItem);
