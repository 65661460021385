import React from 'react';
import {Box, Stack} from '@mui/material';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import Styles from './EachComponentEmail.module.scss';

const EmailComponent = ({field, onChange, editMode}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <Box>
      <Box
        className={`${field.inputIcon ? Styles.fbpEmailComponentIcon : ''} ${labelAlignmentClass} `}
      >
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>
            {field.label}
            {field.required && <RequireIcon />}
          </FormLabel>
        </Stack>

        <Box className={Styles.fbpEmailWrapper}>
          <FormInput
            type="email"
            inputIcon={field.inputIcon && fbpIcons.fbpEmailInputIcon}
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={{
              ...(field.required ? {required: 'Email is required'} : {}),
              ...registerPatternBuilderFields.email
            }}
          />
        </Box>
      </Box>

      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(EmailComponent);
