import React from 'react';
import {Stack} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {Controller, useForm, useFormContext} from 'react-hook-form';
import CustomPhoneInput from '../../../common/CustomPhoneInput';

export const FormPhoneInput = ({
  name,
  value,
  inputIcon,
  rules,
  className = '',
  placeholder,
  readOnly,
  helperTextProps = {},
  onChange = () => {}
}) => {
  const {control} = useForm();
  const methods = useFormContext();

  return (
    <Controller
      control={methods ? methods.control : control}
      name={name}
      defaultValue={value}
      rules={rules}
      render={formOption => (
        <Stack direction="column" spacing={1}>
          <CustomPhoneInput
            inputIcon={inputIcon}
            className={className}
            value={value}
            placeholder={placeholder}
            readOnly={readOnly}
            onChange={value => {
              methods?.setValue(name, value);
              onChange(value);
              if (formOption.formState.isSubmitted) {
                methods?.trigger();
              }
            }}
          />
          {formOption.fieldState.error?.message ? (
            <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
          ) : (
            <FormSubtext>{helperTextProps.text}</FormSubtext>
          )}
        </Stack>
      )}
    />
  );
};
