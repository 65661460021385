import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  error: '',

  isFormUpdating: false,
  isFormUpdateSuccess: false
};

const formSEOSlice = createSlice({
  name: 'formSEO',
  initialState: initialState,
  reducers: {
    getFormSEO: (state, action) => {
      state.isLoading = true;
    },
    getFormSEOSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormSEOFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFormSEO: state => {
      state.isFormUpdating = true;
    },
    updateFormSEOSuccess: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
    },
    updateFormSEOFailed: (state, action) => {
      state.isFormUpdating = false;
      state.error = action.payload;
    }
  }
});

export const {
  getFormSEO,
  getFormSEOSuccess,
  getFormSEOFailed,

  updateFormSEO,
  updateFormSEOSuccess,
  updateFormSEOFailed
} = formSEOSlice.actions;

export default formSEOSlice.reducer;
