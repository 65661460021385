import React from 'react';
import AudioAdvanced from './AudioAdvanced';
import AudioGeneral from './AudioGeneral';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import {useSelector} from 'react-redux';
import {Box} from '@mui/material';
import AudioOptions from './AudioOptions';

const AudioRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  if (theme.layout === 'card') {
    return (
      <Box sx={{mt: 2}}>
        <AudioGeneral field={field} />
      </Box>
    );
  }

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <AudioGeneral field={field} />
      </CustomTab>
      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <AudioOptions field={field} />
        </CustomTab>
      )}
      <CustomTab title="Advanced">
        <AudioAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default AudioRightPanel;
