import React from 'react';
import Box from '@mui/material/Box';
import {FormText} from '../../../common/Form-UI/FormText';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const ParagraphComponent = ({field}) => {
  return (
    <Box sx={{position: 'relative'}}>
      <Box sx={{textAlign: 'justify'}}>
        <FormText sx={{color: 'var(--fbp-theming-font-color)'}}>{field.text}</FormText>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default ParagraphComponent;
