import React from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';

const StyledRadio = styled('input')(({theme, checked}) => ({
  '&[type="radio"]': {
    '&:checked': {
      position: 'relative',
      opacity: 1,
      width: '20px',
      height: '20px',
      pointerEvents: 'auto'
    },
    '&:not(:checked)': {
      position: 'relative',
      opacity: 1,
      width: '20px',
      height: '20px',
      pointerEvents: 'auto'
    }
  }
}));
const StyledStack = styled(Stack)(({theme, checked}) => ({
  maxWidth: '576px',
  width: '100%',
  backgroundColor: checked ? theme.palette.other.secondaryHover : theme.palette.common.white,
  border: '1px solid',
  borderRadius: '4px',
  borderColor: checked ? theme.palette.secondary.main : theme.palette.other.outlinedBorderDarker,
  cursor: 'pointer'
}));

const ThankYouRadioButton = ({icon = '', title, subTitle, name, onClick, ...rest}) => {
  const {checked, value} = rest;
  return (
    <StyledStack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      p={1.5}
      checked={checked}
      onClick={() => onClick(value)}
    >
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        {icon}
        <Box>
          <Typography variant={'subtitle1'} color={'text.primary'}>{title}</Typography>
          <Typography variant={"chipLight"} color={'text.secondary'}>
            {subTitle}
          </Typography>
        </Box>
      </Stack>
      <StyledRadio type="radio" name={name} onChange={() => onClick(value)} {...rest} />
    </StyledStack>
  );
};

export default ThankYouRadioButton;
