import React from 'react';
import {useSelector} from 'react-redux';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import NoteAdvanced from './NoteAdvanced';
import NoteGeneral from './NoteGeneral';
import NoteOptions from './NoteOptions';

const NoteRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <NoteGeneral field={field} />
      </CustomTab>

      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <NoteOptions field={field} />
        </CustomTab>
      )}

      <CustomTab title="Advanced">
        <NoteAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default NoteRightPanel;
