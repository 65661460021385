import React from 'react';
import {Box, Stack} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {CustomDropdown} from '../../../common/CustomDropdown';
import withFieldChange from '../../../hoc/withFieldChange';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import RequireIcon from '../../../common/Icons/RequireIcon';

const DropdownComponent = ({field, onChange, editMode}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <>
      <Box className={`${labelAlignmentClass}`}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

        <ConnectPublicForm
          name={field.id}
          value={field.value}
          rules={field.required ? {required: 'This value is required'} : {}}
          render={formOption => (
            <>
              <Stack sx={{flex: '1', gap: '5px'}} className={"BuilderDropDown"}>
                <CustomDropdown
                  data={
                    field.displayEmpty
                      ? [{title: field.placeholder, value: ''}, ...field.options]
                      : field.options
                  }
                  wrapperClassName="builderDropDown"
                  size="medium"
                  value={field.value}
                  displayEmpty={field.displayEmpty}
                  disabled={editMode || field.readOnly}
                  onChange={e => {
                    formOption.field.onChange(e);
                    onChange({value: e.target.value});
                  }}
                />
                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext>{field.helperText}</FormSubtext>
                )}
              </Stack>
            </>
          )}
        />
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(DropdownComponent);
