class ShareOnSocialMedia {
  google(link = '', target = '_blank') {
    const url = 'https://plus.google.com/share?url=' + link;
    window.open(
      url,
      target,
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600'
    );
    return true;
  }

  facebook(link = '') {
    // return `https://www.facebook.com/dialog/share?app_id=${process.env.REACT_APP_FACE_BOOK_APP_ID}&href=${link}&display=popup`;
    return `https://www.facebook.com/sharer.php?u=${link}`;
  }

  twitter(link = '') {
    return 'https://twitter.com/intent/tweet?url=' + link;

    // const url = 'https://twitter.com/intent/tweet?url=' + link + '&via=getboldify';
    // window.open(url, 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600');
    // return false;
  }

  instagram(link = '', target = '_blank') {
    const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + link;
    window.open(
      url,
      target,
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600'
    );
    return false;
  }

  linkedIn(link = '') {
    return 'https://www.linkedin.com/sharing/share-offsite/?url=' + link;

    // const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + link;
    // window.open(
    //   url,
    //   target,
    //   'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=400,width=600'
    // );
    // return false;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ShareOnSocialMedia();
