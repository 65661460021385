import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack} from '@mui/material';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import TwoTabsWithTitle from '../../Builder/RightComponents/TwoTabsWithTitle';
import CustomTextField from '../../../common/TextField/CustomTextField';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import {CustomFileUploader} from '../../../common/FileUpload/CustomFileUploader';
import RightSideAudioPreviewComponent from '../../Builder/RightComponents/RightSideAudioPreviewComponent';

const AudioGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  const [selectedTab, setSelectedTab] = useState('upload');
  const [url, setUrl] = useState(field.audio);

  useEffect(() => {
    if (field.audio) {
      setUrl(field.audio);
    }
  }, [field.audio]);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'card' && (
        <RightSideInput
          text="Description"
          title="Change the description of the field"
          placeholder="Description"
          value={field.description}
          onChange={e => onChangeValue({description: e.target.value})}
        />
      )}

      <TwoTabsWithTitle
        text="Add Audio"
        title="Add audio to the form"
        child1="Upload"
        child2="Audio URL"
        value1="upload"
        value2="url"
        selectedValue={selectedTab}
        onChange={setSelectedTab}
      >
        {selectedTab === 'upload' && (
          <CustomFileUploader
            sx={{width: '100%'}}
            preType="audio"
            onFileUpload={audioFile => onChangeValue({audio: audioFile.file})}
          />
        )}

        {selectedTab === 'url' && (
          <Stack direction="column" alignItems="flex-end" sx={{textAlign: 'right',width: '100%'}} spacing={1}>
            <CustomTextField
              size="medium"
              labelPosition="top"
              placeholder="Enter URL"
              defaultValue={field.audio}
              onChange={e => setUrl(e.target.value)}
            />

            <LightCoreButton onClick={e => onChangeValue({audio: url})} size="small">
              Save
            </LightCoreButton>
          </Stack>
        )}
      </TwoTabsWithTitle>

      {field.audio && (
        <RightSideAudioPreviewComponent
          text="Audio"
          title="The added audio"
          audio={field.audio}
          onRemove={() => onChangeValue({audio: null})}
        />
      )}

      {theme.layout === 'classic' && (
        <>
          <TwoTabsWithTitle
            text="Audio positioning"
            title="Audio position on the form"
            child1="Default"
            child2="Full Width"
            value1="default"
            value2="fullWidth"
            selectedValue={field.positioning}
            onChange={value => onChangeValue({positioning: value})}
          />

          {field.positioning === 'default' && (
            <ThreeTabsWithTitle
              text="Audio Alignment"
              title="Audio alignment on the form"
              child1={fbpIcons.fbpLeftIcon}
              child2={fbpIcons.fbpCenterIcon}
              child3={fbpIcons.fbpRightIcon}
              value1="left"
              value2="center"
              value3="right"
              selectedValue={field.alignment}
              onChange={value => onChangeValue({alignment: value})}
            />
          )}
        </>
      )}

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(AudioGeneral);
