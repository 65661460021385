import React from 'react';
import {Box, styled} from "@mui/material";

const CopyCodeBox = styled(Box)(({theme}) => ({
    wordBreak: 'break-all',
    height: 'max-content',
    maxHeight: '120px',
    overflowY: 'auto',
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    background: theme.palette.text.primary,
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: '4px 8px',
    '&::-webkit-scrollbar': {
        width: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.common.white,
        borderRadius: '4px'
    }
}));

const CodeViewer = ({children}) => {
    return (
        <CopyCodeBox>
            <code>
                {children}
            </code>
        </CopyCodeBox>
    );
};

export default CodeViewer;