import React from 'react';
import {IconButton, InputBase} from '@mui/material';
import {styled} from '@mui/material/styles';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import Styles from './CustomBuilderSearch.module.scss';

const StyledInput = styled(InputBase)(({theme}) => ({
  flex: 1,
  '.MuiInputBase-root': {
    lineHeight: 'unset'
  },
  '& input': {
    fontSize: '14px',
    color: theme.palette.text.primary,
    border: 'none !important',
    boxShadow: 'unset',
    margin: 0,
    height: 'unset',
    '&:focus': {
      border: 'none !important'
    }
  },
  '.MuiButtonBase-root': {
    padding: 0,
    flexShrink: 0,
    marginRight: '6px'
  }
}));

export default function CustomBuilderSearch({onChange, value}) {
  return (
    <div className={Styles.fbpBuilderSearchWrapper}>
      <IconButton aria-label="menu">{fbpIcons.fbpSearchIcon}</IconButton>
      <StyledInput
        placeholder="Search"
        inputProps={{'aria-label': 'Search'}}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
