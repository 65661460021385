import React from 'react';
import {SvgIcon} from '@mui/material';

const DownloadIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 20.4C3.6 19.737 4.137 19.2 4.8 19.2H19.2C19.863 19.2 20.4 19.737 20.4 20.4C20.4 21.063 19.863 21.6 19.2 21.6H4.8C4.137 21.6 3.6 21.063 3.6 20.4ZM7.551 11.151C8.02 10.683 8.78 10.683 9.249 11.151L10.8 12.703L10.8 3.6C10.8 2.937 11.337 2.4 12 2.4C12.663 2.4 13.2 2.937 13.2 3.6L13.2 12.703L14.751 11.151C15.22 10.683 15.98 10.683 16.449 11.151C16.917 11.62 16.917 12.38 16.449 12.849L12.849 16.449C12.624 16.674 12.318 16.8 12 16.8C11.682 16.8 11.377 16.674 11.151 16.449L7.551 12.849C7.083 12.38 7.083 11.62 7.551 11.151Z"
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
