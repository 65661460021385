import React from 'react';
import {Stack, styled, Box} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';

const AudioBox = styled(Box)(({theme}) => ({
  width: '100%',
  height: '120px',
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  '& audio': {
    marginTop: 26,
    width: '100%'
  },
  '& button': {
    position: 'absolute',
    right: '10px',
    top: '0',
    borderRadius: '4px',
    border: 'none',
    padding: '4px 10px',
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
    cursor: 'pointer'
  }
}));

const RightSideAudioPreviewComponent = ({text, title, audio, onRemove = () => {}}) => {
  return (
    <Stack alignItems="start" direction="column" className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <AudioBox>
        <audio controls>
          <source src={audio} type="audio/ogg" />
          <source src={audio} type="audio/mpeg" />
          <source src={audio} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
        <button onClick={onRemove}>Remove</button>
      </AudioBox>
    </Stack>
  );
};

export default RightSideAudioPreviewComponent;
