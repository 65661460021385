import {createSlice} from '@reduxjs/toolkit';

const initialStateData = {
  enable: false,
  privacyPolicy: {
    enable: false,
    linkText: "Privacy Policy",
    content: ""
  },
  termsOfUse: {
    enable: false,
    linkText: "Terms Of Use",
    content: ""
  },
  disclosure: {
    enable: false,
    linkText: "Disclosure",
    content: ""
  },
  licensingInformation: {
    enable: false,
    linkText: "Licensing Information",
    content: ""
  },
  aboutUs: {
    enable: false,
    linkText: "About Us",
    content: ""
  },
  contactUs: {
    enable: false,
    linkText: "Contact Us",
    content: ""
  },
  footerBottomContent: {
    enable: false,
    content: ""
  },
};

const initialState = {
  data: initialStateData,
  isLoading: false,
  isError: false,
  error: '',

  isUpdating: false,
  isUpdateSuccess: false
};

const formFooterSlice = createSlice({
  name: 'formFooter',
  initialState: initialState,
  reducers: {
    getFormFooterData: (state) => {
      state.isLoading = true;
    },
    getFormFooterDataSuccess: (state, action) => {
      if(action.payload.hasOwnProperty("enable")) {
        state.data = action.payload;
      } else {
        state.data = initialStateData;
      }
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormFooterDataFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    enableFormFooter: (state, action) => {
      state.data.enable = Boolean(action.payload.enable);
      state.isUpdating = true;
    },

    updateFormFooterData: (state, action) => {
      state.isUpdating = true;
    },
    updateFormFooterDataSuccess: (state, action) => {
      if(action.payload.hasOwnProperty("enable")) {
        state.data.enable = action.payload.enable;
      }
      state.data.privacyPolicy = action.payload.privacyPolicy || {};
      state.data.termsOfUse = action.payload.termsOfUse || {};
      state.data.disclosure = action.payload.disclosure || {};
      state.data.licensingInformation = action.payload.licensingInformation || {};
      state.data.aboutUs = action.payload.aboutUs || {};
      state.data.contactUs = action.payload.contactUs || {};
      state.data.footerBottomContent = action.payload.footerBottomContent || {};

      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updateFormFooterDataFailed: (state, action) => {
      state.isUpdating = false;
      state.error = action.payload;
    },

  }
});

export const {
  getFormFooterData,
  getFormFooterDataSuccess,
  getFormFooterDataFailed,

  enableFormFooter,
  updateFormFooterData,
  updateFormFooterDataSuccess,
  updateFormFooterDataFailed
} = formFooterSlice.actions;

export default formFooterSlice.reducer;
