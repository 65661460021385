import React from 'react';

const ListViewIcon = ({fill = '#006DF5'}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.57143 1.5H9V5.78571H1.5V2.57143C1.5 2.28727 1.61288 2.01475 1.81381 1.81381C2.01475 1.61288 2.28727 1.5 2.57143 1.5ZM9 1.5H15.4286C15.7127 1.5 15.9853 1.61288 16.1862 1.81381C16.3871 2.01475 16.5 2.28727 16.5 2.57143V5.78571H9V1.5ZM1.5 6.85714H5.78571V11.1429H1.5V6.85714ZM6.85714 6.85714H11.1429V11.1429H6.85714V6.85714ZM12.2143 6.85714H16.5V11.1429H12.2143V6.85714ZM1.5 12.2143H5.78571V16.5H2.57143C2.28727 16.5 2.01475 16.3871 1.81381 16.1862C1.61288 15.9853 1.5 15.7127 1.5 15.4286V12.2143ZM6.85714 12.2143H11.1429V16.5H6.85714V12.2143ZM12.2143 12.2143H16.5V15.4286C16.5 15.7127 16.3871 15.9853 16.1862 16.1862C15.9853 16.3871 15.7127 16.5 15.4286 16.5H12.2143V12.2143Z"
        fill={fill}
      />
    </svg>
  );
};

export default ListViewIcon;
