import React, {useState} from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import Styles from './styles/setting-page.module.scss';

const StackStyled = styled(Stack)(({theme}) => ({
  padding: ' 14px 16px',
  cursor: 'pointer',
  '&.fbpPublicSlListActive': {
    backgroundColor: theme.palette.action.actionHover
  },
  '&:hover': {
    backgroundColor: theme.palette.action.actionHover
  }
}));

const SettingPageWrapper = ({options, title, selectedPage, onChangePage, children}) => {
  const [isOpenLeftSidebar, setIsOpenLeftSidebar] = useState(true);

  let listRenderView = options.map(list => (
    <StackStyled
      className={selectedPage === list.id ? 'fbpPublicSlListActive' : ' '}
      key={list.id}
      onClick={() => onChangePage(list.id)}
    >
      <Typography variant="body1medium" sx={{margin: '0 0 8px 0'}}>
        {list.title}
      </Typography>
      <Typography color="text.secondary" variant="body2">
        {list.content}
      </Typography>
    </StackStyled>
  ));

  return (
    <Box
      className={`${Styles.fbpQuickReplaySettingWr} ${
        isOpenLeftSidebar ? Styles.fbpQuickReplayWrShow : Styles.fbpQuickReplayWrHide
      }`}
    >
      <Box
        className={Styles.ShowSettingsIcon}
        onClick={() => setIsOpenLeftSidebar(!isOpenLeftSidebar)}
      >
        {fbpIcons.fbpPlusIcon}
      </Box>

      <Box sx={{display: 'flex'}} className={Styles.fbpPublicShareWr}>
        <Box className={Styles.fbpPublicShareLeft}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="center"
            className={Styles.fbpPublicSlTop}
          >
            <Typography variant="subtitle1">{title}</Typography>
            <Box
              className={Styles.fbpPublicHideShowIcon}
              onClick={() => setIsOpenLeftSidebar(!isOpenLeftSidebar)}
            >
              {fbpIcons.fbpHidesidebarIcon}
            </Box>
          </Stack>

          <Box className={`fbp_has_scroll ${Styles.fbpPublicSlLists}`}>{listRenderView}</Box>
        </Box>

        <Box className={` ${Styles.fbpPublicShareMiddle}`}>
          <div className="fbp_has_scroll"> {children}</div>
        </Box>

      </Box>
    </Box>
  );
};

export default SettingPageWrapper;
