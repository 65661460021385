import React, {useEffect} from 'react';
import {Box, Checkbox, FormControlLabel, styled, Typography} from '@mui/material';
import FormListEachForm from './FormListEachForm';
import FormListEachTrash from './FormListEachTrash';
import {useDispatch, useSelector} from 'react-redux';
import FormListSkeleton from '../LoadingView/FormListSkeleton';
import GlobalTablePagination from '../../common/Pagination/GlobalTablePagination';
import GlobalEmptyPage from '../../common/GlobalEmptyPage/GlobalEmptyPage';
import isEmptyArray from '../../helpers/utils/IsEmptyArray';
import {
  selectFolderForm,
  selectFormFilters
} from '../../state/features/folderForms/folderFormSelector';
import {
  deselectAllForm,
  getFolderForms,
  getFolderFormsSilent,
  resetFormFilter,
  selectedAllForm
} from '../../state/features/folderForms/folderFormsSlice';
import removeFalsyObjectProperty from '../../helpers/utils/removeFalsyObjectProperty';
import {
  EmptyArchiveIcon,
  EmptyfavouriteIcon,
  EmptyFormListIcon,
  EmptyTrashIcon
} from '../../common/Icons/EmptyDataIcons';

const FormsBox = styled(Box)(({theme}) => ({
  height: 'calc(100% - 50px)',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 100px)'
  }
}));
const BoxStyled = styled(Box)(({theme}) => ({
  backgroundColor: theme.palette.other.outlinedBorderDarker,
  padding: '0 48px 0 15px',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const getEmptyPageContent = (favourite, archive, trash) => {
  if (favourite) {
    return {
      icon: <EmptyfavouriteIcon />,
      title: 'Favorites is Empty',
      description: 'Click the Star Button on any file to add a Favorites.'
    };
  } else if (archive) {
    return {
      icon: <EmptyArchiveIcon />,
      title: 'Archive is empty',
      description: 'You can archive your forms here if you want'
    };
  } else if (trash) {
    return {
      icon: <EmptyTrashIcon />,
      title: 'Trash is Empty',
      description:
        'All your submissions & reports data to this form will be gone forever. This operation cannot be undone.'
    };
  } else {
    return {
      icon: <EmptyFormListIcon />,
      title: 'Forms Not Found',
      description:
        'All your submissions & reports data to this form will be gone forever. This operation cannot be undone.'
    };
  }
};

const FormListView = () => {
  const dispatch = useDispatch();
  const {forms, page, limit, count, isLoading, selectedFormIds, renderId} =
    useSelector(selectFolderForm);
  const {folderId, favourite, archived, trash, searchText, startDate, endDate, orderBy, status} =
    useSelector(selectFormFilters);

  const handleSelectAll = () => {
    dispatch(selectedAllForm());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllForm());
  };

  useEffect(() => {
    const reqObj = removeFalsyObjectProperty({
      folderId,
      favourite,
      archived,
      searchText,
      startDate,
      endDate,
      orderBy,
      limit,
      status: isEmptyArray(status) ? null : status
    });

    if (renderId) {
      dispatch(getFolderFormsSilent(reqObj));
    } else {
      dispatch(getFolderForms(reqObj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, favourite, archived, searchText, startDate, endDate, orderBy, trash, renderId]);

  // reset filters when unmount
  useEffect(() => {
    return () => dispatch(resetFormFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePerPage = (perPage, newPage) => {
    const reqObj = removeFalsyObjectProperty({
      folderId,
      favourite,
      archived,
      searchText,
      startDate,
      endDate,
      orderBy,
      status
    });
    dispatch(getFolderForms({...reqObj, page: newPage, limit: perPage}));
  };

  const onChangePage = newPage => {
    const reqObj = removeFalsyObjectProperty({
      folderId,
      favourite,
      archived,
      searchText,
      startDate,
      endDate,
      orderBy,
      status
    });
    dispatch(getFolderForms({...reqObj, page: newPage, limit}));
  };

  // decide what to render
  let formsViewContent = '';
  if (isLoading) {
    formsViewContent = <FormListSkeleton count={10} />;
  } else if (!isLoading && forms?.length === 0) {
    const {image, icon, title} = getEmptyPageContent(favourite, archived, trash) || {};
    formsViewContent = <GlobalEmptyPage image={image} icon={icon} title={title} />;
  } else if (!isLoading && forms?.length > 0) {
    const checkedAll = forms.every(fData => selectedFormIds.includes(fData.id));
    formsViewContent = (
      <>
        <Box className="fbp_has_scroll" sx={{height: 'calc(100% - 46px)', overflowX: 'hidden'}}>
          <BoxStyled>
            <Box sx={{display: 'flex', alignItems: 'center', gap: '15px'}}>
              <FormControlLabel
                sx={{color: 'primary.main', opacity: '1'}}
                control={
                  <Checkbox
                    checked={checkedAll}
                    onChange={checkedAll ? handleDeselectAll : handleSelectAll}
                    name="checkedB"
                    color="primary"
                  />
                }
                label=""
              />
              <Typography variant="body2" color="text.primary">
                Forms
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="text.primary">
                Action
              </Typography>
            </Box>
          </BoxStyled>

          <ul>
            {!trash
              ? forms.map(formData => (
                  <FormListEachForm
                    key={formData.id}
                    formData={formData}
                    checked={selectedFormIds.includes(formData.id)}
                  />
                ))
              : forms.map(formData => (
                  <FormListEachTrash
                    key={formData.id}
                    formData={formData}
                    checked={selectedFormIds.includes(formData.id)}
                  />
                ))}
          </ul>
        </Box>
        <GlobalTablePagination
          count={count}
          page={page}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </>
    );
  }

  return <FormsBox>{formsViewContent}</FormsBox>;
};

export default FormListView;
