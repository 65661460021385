import {createSlice} from '@reduxjs/toolkit';
import {CUSTOM_FIELD_MAPS} from '../../../helpers/builderConstant/CustomFieldType';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: '',
  customFieldObject: {}
};

const customFieldSlice = createSlice({
  name: 'customField',
  initialState: initialState,
  reducers: {
    getCustomFieldData: state => {
      state.isLoading = true;
    },
    getCustomFieldSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
      action.payload.forEach(eachCustomField => {
        state.customFieldObject[eachCustomField.personalizeTag] = {
          type: CUSTOM_FIELD_MAPS[eachCustomField.typeId],
          label: eachCustomField.title
        };
      });
    },
    getCustomFieldFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    }
  }
});

export const {getCustomFieldData, getCustomFieldSuccess, getCustomFieldFailed} =
  customFieldSlice.actions;

export default customFieldSlice.reducer;
