import React from 'react';
import {TableCell, TableHead, TableRow, useTheme} from '@mui/material';

const headCells = [
  {label: 'Form Field'},
  {label: 'Field Type'},
  {label: 'Input Type'},
  {label: 'Contact field', align: 'right'}
];

export const MappingTableHead = () => {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: theme.palette?.other.outlinedBorder}}>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.label}
            padding={'normal'}
            align={headCell.align}
            sx={{fontWeight: 600}}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
