import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import TemplateCategoryService from '../../../services/TemplateCategory.service';
import {getTemplateCategoriesFailed, getTemplateCategoriesSuccess} from './templateCategoriesSlice';

function* templateCategoriesWatcher() {
  yield takeEvery('templateCategories/getTemplateCategories', getTemplateCategoriesSaga);
}

function* getTemplateCategoriesSaga(action) {
  try {
    const response = yield call(TemplateCategoryService.getTemplateCategories, action.payload);

    if (response.success) {
      yield put(getTemplateCategoriesSuccess(response.data.content));
    } else {
      yield put(getTemplateCategoriesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* templateCategoriesSaga() {
  yield all([templateCategoriesWatcher()]);
}
