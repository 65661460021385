import React, {useEffect} from 'react';
import {Box, Grid, Stack, Typography} from '@mui/material';
import CoreTextField from '../../common/TextField/CoreTextField';
import CoreButton from '../../common/Button/CoreButton';
import {registerPatternTemplate} from '../../helpers/constant/registerPattern';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import LoadingButton from '../../common/Button/LoadingButton';
import {
  renameTemplate,
  resetTemplateUpdateSuccess
} from '../../state/features/adminTemplates/adminTemplatesSlice';
import {selectTemplates} from '../../state/features/adminTemplates/adminTemplatesSelector';

const RenameTemplateContent = ({onClose, templateInfo = {}}) => {
  const dispatch = useDispatch();
  const {isUpdating, isUpdateSuccess} = useSelector(selectTemplates);
  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (templateInfo?.formTitle) {
      setValue('formTitle', templateInfo.formTitle);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateInfo]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(resetTemplateUpdateSuccess());
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  // handle submit add folder
  const onSubmit = data => {
    if (templateInfo?.formTitle === data?.formTitle) {
      onClose();
      return;
    }
    dispatch(renameTemplate({templateId: templateInfo?.id, name: data.formTitle}));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="formTitle"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Template Name
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternTemplate.formTitle}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isUpdating}
          loadingText={'Renaming...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          Rename
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default RenameTemplateContent;
