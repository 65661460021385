import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {nanoid} from 'nanoid';
import {Stack, Typography} from '@mui/material';
import FormLayoutCard from '../../../common/Cards/FormLayoutCard';
import CustomLoader from '../../../common/CustomLoader/CustomLoader';
import {FORM_TEMPLATE_TYPE} from '../../../helpers/constant/formConstant';
import {DEFAULT_THEME} from '../../../helpers/builderConstant/DefaultTheme';
import {DEFAULT_ELEMENTS} from '../../../helpers/builderConstant/DefaultElements';
import {addForm, resetAddFormSuccess} from '../../../state/features/folderForms/folderFormsSlice';
import {selectFolderForm} from '../../../state/features/folderForms/folderFormSelector';

const TemplateLayoutOption = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isFormAdding, isFormAddSuccess, newFormId} = useSelector(selectFolderForm);

  useEffect(() => {
    if (isFormAddSuccess) {
      dispatch(resetAddFormSuccess());
      history.push(`/fbuilder/admin/template/${newFormId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormAddSuccess]);

  // handle create form base on inside or outside folder
  const handleCreateForm = (formType, defaultTheme) => {
    const requestObj = {
      formTitle: 'Template-' + nanoid(10),
      formType: formType,
      formTheme: JSON.stringify(defaultTheme),
      elements: DEFAULT_ELEMENTS.map(el => JSON.stringify(el))
    };
    dispatch(addForm(requestObj));
  };

  if (isFormAdding) return <CustomLoader />;

  return (
    <div>
      <Stack direction={'column'} alignItems={'center'}>
        <Typography variant={'h5'}>Select your form layout</Typography>
        <Typography variant={'body2'}>Choose a layout according to your needs</Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'center'} spacing={3} p={2} mt={3}>
        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'classicform.png'}
          title={'Classic Form'}
          description={'Show all question on one page'}
          onClick={() => handleCreateForm(FORM_TEMPLATE_TYPE.CLASSIC, DEFAULT_THEME.classic)}
        />

        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'cardform.png'}
          title={'Card Form'}
          description={'Show single question per page'}
          onClick={() => handleCreateForm(FORM_TEMPLATE_TYPE.CARD, DEFAULT_THEME.card)}
        />
      </Stack>
    </div>
  );
};

export default TemplateLayoutOption;
