import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {InputAdornment} from '@mui/material';
import {Search} from '@mui/icons-material';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {debounceHandler} from '../../../helpers/utils/debounceHandler';
import {selectInboxFilters} from '../../../state/features/formSubmission/formSubmissionSelector';
import {addSearchTextForInboxForm} from '../../../state/features/formSubmission/formSubmissionSlice';

const InboxSubmissionSearch = () => {
  const dispatch = useDispatch();
  const {searchText, resetId} = useSelector(selectInboxFilters) || {};
  const [search, setSearch] = useState(searchText);

  const doSearch = value => {
    dispatch(addSearchTextForInboxForm(value));
  };

  useEffect(() => {
    if (resetId) setSearch(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetId]);

  // debounce implement for search input change
  const handleSearch = React.useMemo(() => {
    return debounceHandler(doSearch, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CoreTextField
      fullWidth
      size={'small'}
      type={'search'}
      color={'secondary'}
      InputProps={{
        startAdornment: (
          <InputAdornment sx={{mr: 0}} position="start">
            <Search />
          </InputAdornment>
        )
      }}
      placeholder="Search"
      value={search}
      onChange={e => {
        setSearch(e.target.value);
        handleSearch(e.target.value);
      }}
    />
  );
};

export default InboxSubmissionSearch;
