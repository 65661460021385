import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, Stack} from '@mui/material';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import {CustomFileUploader} from '../../../common/FileUpload/CustomFileUploader';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbarSlice';
import withFieldChange from '../../../hoc/withFieldChange';
import FilePreview from './FilePreview';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewFileUploadComponent = ({field, pageNo, editMode, totalPage, onChange}) => {
  const dispatch = useDispatch();
  const handleRemoveFile = index => {
    let clonnedFiles = [...field.files];
    clonnedFiles.splice(index, 1);
    onChange({files: clonnedFiles});
    return clonnedFiles.length;
  };

  const handleChangeFiles = files => {
    let oldFiles = [...field.files];
    let newFiles = [...files];

    //Appending to old files.
    newFiles.forEach(file => {
      let hasFile = oldFiles.find(oldFile => oldFile.lastModified === file.lastModified);
      if (!hasFile) {
        oldFiles.push(file);
      }
    });

    //Checking file limit
    if (oldFiles.length > field.fileLimit) {
      dispatch(showErrorSnackbar(`Only ${field.fileLimit} files uploads allowed.`));
      return;
    }

    onChange({files: oldFiles});
  };

  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
            <FormCardLabel>
              {field.label}
              {field.required && <RequireIcon variant="card" />}
            </FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>
        <Box>
          <ConnectPublicForm
            name={field.id}
            value={field.files.length}
            rules={field.required ? registerPatternBuilderFields.file : {}}
            render={(formOption, formMethods) => (
              <>
                <CustomFileUploader
                  lazyUpload
                  className="builderFileUpload"
                  preType={field.fileTypeOption}
                  type={field.fileType}
                  title={field.uploaderTitle}
                  subText={field.uploaderSubText}
                  fileLimit={field.fileLimit}
                  disabled={editMode || field.readOnly}
                  onFileUpload={files => {
                    formMethods?.setValue(field.id, files.length);
                    if (formOption.formState.isSubmitted) {
                      formMethods?.trigger();
                    }
                    handleChangeFiles(files.files);
                  }}
                />

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}

                <FilePreview
                  files={field.files}
                  onRemove={index => {
                    let totalFiles = handleRemoveFile(index);
                    formMethods?.setValue(field.id, totalFiles);
                    if (formOption.formState.isSubmitted) {
                      formMethods?.trigger();
                    }
                  }}
                />

                {/* <Stack direction="row" spacing={2} mt={1}>
                  <FormSubtext>
                    Per file size: Max 1MB
                  </FormSubtext>
                  <FormSubtext>
                    Total file size: Max 10MB
                  </FormSubtext>
                </Stack> */}
              </>
            )}
          />
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewFileUploadComponent);
