import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {switchFormSubmissionPageView} from '../../state/features/formSubmission/formSubmissionSlice';
import {selectFormSubmissionPageView} from '../../state/features/formSubmission/formSubmissionSelector';
import {
  FORM_SUBMISSION_PAGE_VIEW_GRID,
  FORM_SUBMISSION_PAGE_VIEW_TABLE
} from '../../helpers/constant/formSubmissionConstant';
import GridViewIcon from '../../common/Icons/GridViewIcon';
import ListViewIcon from '../../common/Icons/ListViewIcon';
import {StackStyled, SwitchWrapper} from "./SubmissionViewSwitchStyles";


const SubmissionViewSwitch = () => {
  const dispatch = useDispatch();
  const pageView = useSelector(selectFormSubmissionPageView);
  const onChangePageViewGrid = () => {
    dispatch(switchFormSubmissionPageView(FORM_SUBMISSION_PAGE_VIEW_GRID));
  };

  const onChangePageViewTable = () => {
    dispatch(switchFormSubmissionPageView(FORM_SUBMISSION_PAGE_VIEW_TABLE));
  };

  return (
    <SwitchWrapper direction="row" alignItems={'center'}>
      <CustomTooltip title="Grid View" arrow placement="top">
        <StackStyled
          direction="row"
          alignItems="center"
          justifyContent="center"
          px={1}
          className={pageView === FORM_SUBMISSION_PAGE_VIEW_GRID && 'inboxSubViewGridActive'}
          onClick={onChangePageViewGrid}
        >
          <Typography variant="helperText2" color="text.primary" className="inboxViewText">
            Grid
          </Typography>
          <span className="inboxViewIcon">
            <GridViewIcon />
          </span>
        </StackStyled>
      </CustomTooltip>
      <CustomTooltip title="List View" arrow placement="top">
        <StackStyled
          direction="row"
          alignItems="center"
          justifyContent="center"
          px={1}
          className={pageView === FORM_SUBMISSION_PAGE_VIEW_TABLE && 'inboxSubViewGridActive'}
          onClick={onChangePageViewTable}
        >
          <Typography variant="helperText2" color="text.primary" className="inboxViewText">
            List
          </Typography>
          <span className="inboxViewIcon">
            <ListViewIcon />
          </span>
        </StackStyled>
      </CustomTooltip>
    </SwitchWrapper>
  );
};

export default SubmissionViewSwitch;
