import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';

const ImageAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Alternative Text"
        title="Set the image preload text"
        placeholder="ALT text"
        value={field.alt}
        onChange={e => onChangeValue({alt: e.target.value})}
      />

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(ImageAdvanced);
