import React from 'react';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../../helpers/builderConstant/ComponentType';
import CoverImageRightPanel from '../EachComponent/imageEdits/CoverImageRightPanel';
import ProfileImageRightPanel from '../EachComponent/imageEdits/ProfileImageRightPanel';
import ThemeRightPanel from '../EachComponent/theme/ThemeRightPanel';
import FormImageRightPanel from '../EachComponent/imageEdits/FormImageRightPanel';
import PageImageRightPanel from '../EachComponent/imageEdits/PageImageRightPanel';

const BuilderRightManager = ({field, selectedType}) => {
  const Component = ComponentConfig[selectedType]?.rightComponent;

  if (Component) {
    return <Component field={field} />;
  }

  switch (selectedType) {
    case COMPONENT_TYPE.COVER_IMAGE:
      return <CoverImageRightPanel theme={field} />;
    case COMPONENT_TYPE.PROFILE_IMAGE:
      return <ProfileImageRightPanel theme={field} />;
    case COMPONENT_TYPE.FORM_IMAGE:
      return <FormImageRightPanel theme={field} />;
    case COMPONENT_TYPE.PAGE_IMAGE:
      return <PageImageRightPanel theme={field} />;
    default:
      return <ThemeRightPanel theme={field} />;
  }
};

export default BuilderRightManager;
