import React from 'react';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import {Stack, Box} from '@mui/material';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightsideInlineInput from '../../Builder/RightComponents/RightsideInlineInput';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const NameGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Field Label"
        title="Change the label of the input"
        value={field.label}
        onChange={e => onChangeValue({label: e.target.value})}
      />

      {theme.layout === 'card' && (
        <RightSideInput
          text="Description"
          title="Change the description of the field"
          value={field.description}
          placeholder="Description"
          onChange={e => onChangeValue({description: e.target.value})}
        />
      )}

      <RightSideSwitch
        text="Required Field"
        title="Set the field required on submission"
        checked={field.required}
        onChange={value => onChangeValue({required: value})}
      />

      <Stack direction="column" sx={{gap: '20px'}} className="builderElementBasicStyle">
        <RightComponentTitle text="Helper Texts" title="Change text bottom input" />
        <RightsideInlineInput
          label="Prefix"
          value={field.prefixHelperText}
          onChange={e => onChangeValue({prefixHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="First Name"
          value={field.firstNameHelperText}
          onChange={e => onChangeValue({firstNameHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="Middle Name"
          value={field.middleNameHelperText}
          onChange={e => onChangeValue({middleNameHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="Last Name"
          value={field.lastNameHelperText}
          onChange={e => onChangeValue({lastNameHelperText: e.target.value})}
        />
      </Stack>

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(NameGeneral);
