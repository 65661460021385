import httpRequest from '../api/httpRequest';

class TemplateCategoryService {
  getTemplateCategories(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatecategory/user/list',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TemplateCategoryService();
