import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import {getSaveIconsFailed, getSaveIconsSuccess} from './saveIconSlice';
import MediaIconService from "../../../services/MediaIcon.service";

function* saveIconWatcher() {
  yield takeLatest('saveIcon/getSaveIcons', getSaveIconsSaga);
  yield takeLatest('saveIcon/saveMyIcon', saveMyIconSaga);
}

function* getSaveIconsSaga(action) {
  try {
    const response = yield call(MediaIconService.getSaveIcons, action.payload);

    if (response.success) {
      yield put(getSaveIconsSuccess(response.data));
    } else {
      yield put(getSaveIconsFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* saveMyIconSaga(action) {
  try {
    yield call(MediaIconService.saveMyIcon, action.payload);

  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* saveIconSaga() {
  yield all([saveIconWatcher()]);
}
