import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import {nanoid} from 'nanoid';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideOptionsElements from '../../Builder/RightComponents/RightSideOptionsElements';
import AddImageIconContent from "../multipleChoice/AddImageIconContent";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import {getStructuredClone} from '../../../helpers/utils/getStructuredClone';

const CheckboxOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);
  const [selectedOption, setSelectedOption] = useState({});

  const handleOptionTitleChange = (index, value) => {
    let options = getStructuredClone(field.options);
    options[index].title = value;
    onChangeValue({options});
  };

  const onResetImage = (index, value) => {
    let options = getStructuredClone(field.options);
    options[index].icon = value;
    onChangeValue({options});
  };

  const handleAddOption = index => {
    let options = getStructuredClone(field.options);
    let newValue = nanoid();
    options.splice(index, 0, {title: '', value: newValue});
    onChangeValue({options});
    setTimeout(() => {
      //Focusing the next element
      document.getElementById(newValue)?.focus();
    }, 50);
  };

  const handleRemoveOption = index => {
    let options = getStructuredClone(field.options);
    options.splice(index, 1);
    onChangeValue({options});
  };

  const handleOpenAddMediaModal = (option) => {
    setSelectedOption(option);
    onOpenModal()
  }

  const onAddImageIcon = (newIcon) => {
    setSelectedOption({...selectedOption, icon: newIcon})
    const modifiedOptions = Array.isArray(field.options) ? field.options.slice(0).map(option => {
      if(option.value === selectedOption.value) return {...option, icon: newIcon}
      return option;
    }) : []

    onChangeValue({options: modifiedOptions});
    onCloseModal();
  }

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Stack direction="column" spacing={1} className="builderElementBasicStyle">
        <RightComponentTitle text="Options" title="Each options on this field" />
        {field.options.map((option, index) => (
          <RightSideOptionsElements
            key={option.value}
            value={option.value}
            title={option.title}
            icon={option.icon}
            maxLength={80}
            showImageIcon={true}
            onClickAddImage={() => handleOpenAddMediaModal(option)}
            onChangeTitle={value => handleOptionTitleChange(index, value)}
            onResetImage={value => onResetImage(index, value)}
            onAddOption={() => handleAddOption(index + 1)}
            onRemoveOption={() => handleRemoveOption(index)}
            onEnterPress={() => handleAddOption(index + 1)}
          />
        ))}

        {field.options.length === 0 && (
          <CoreButton variant="contained" size={'small'} onClick={() => handleAddOption(0)}>
            Add Option
          </CoreButton>
        )}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}

      <BasicModalWithHeader
          modalTitle={'Add Media Icon'}
          open={openModal}
          onClose={onCloseModal}
      >
        <AddImageIconContent onClose={onCloseModal} onAddImageIcon={onAddImageIcon}/>
      </BasicModalWithHeader>
    </Box>
  );
};

export default withFieldPropertyChange(CheckboxOptions);
