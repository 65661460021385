import React from 'react';
import withFieldChange from '../../../hoc/withFieldChange';
import {Box, Stack} from '@mui/material';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import CrossIcon from '../../../common/Icons/CrossIcon';
import YesIcon from '../../../common/Icons/YesIcon';
import {styled} from '@mui/styles';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const StylesYesNoBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1',
  height: '52px',
  border: '1.5px solid rgba(0, 109, 245, 0.5)',
  borderRadius: '4px',
  gap: '12px',
  transition: 'all 0.2s',
  color: 'var(--fbp-theming-text-color) ',
  fontFamily: 'var(--fbp-theming-font-family)',
  background: 'var(--fbp-theming-input-background)',
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '0.15px',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.other.skylight,
    color: theme.palette.common.white,
    '& svg path': {fill: theme.palette.common.white}
  },
  '&.active': {
    background: theme.palette.other.skylight,
    color: theme.palette.common.white,
    '& svg path': {fill: theme.palette.common.white}
  }
}));

const CardViewYesNoComponent = ({field, onChange, editMode}) => {
  const handleChangeValue = (value, method, formOption) => {
    if (!editMode && !field.readOnly) {
      method?.setValue(field.id, value);
      onChange({value: value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>

        <Stack direction="column" spacing={1}>
          <ConnectPublicForm
            name={field.id}
            value={field.value}
            rules={field.required ? {required: 'Please select an option'} : {}}
            render={(formOption, formMethods) => (
              <>
                <Stack direction="row" spacing={1} sx={{flex: '1 1', width: '100%'}}>
                  <StylesYesNoBtn
                    className={field.value === 'yes' ? 'active' : ''}
                    onClick={() => {
                      handleChangeValue(
                        field.value === 'yes' ? null : 'yes',
                        formMethods,
                        formOption
                      );
                    }}
                  >
                    <YesIcon />
                    {field.positiveText}
                  </StylesYesNoBtn>
                  <StylesYesNoBtn
                    className={field.value === 'no' ? 'active' : ''}
                    onClick={() => {
                      handleChangeValue(
                        field.value === 'no' ? null : 'no',
                        formMethods,
                        formOption
                      );
                    }}
                  >
                    <CrossIcon />
                    {field.negativeText}
                  </StylesYesNoBtn>
                </Stack>
                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewYesNoComponent);
