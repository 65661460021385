import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Stack} from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import CalendarViewDayRoundedIcon from '@mui/icons-material/CalendarViewDayRounded';
import EmbedModeSingleOption from './embedmodal/EmbedModeSingleOption';
import {EMBED_MODES} from '../../../../helpers/constant/embedConstant';
import {setCurrentEmbedMode} from '../../../../state/features/embedSettings/embedSettingsSlice';
import {selectCurrentEmbedMode} from '../../../../state/features/embedSettings/embedSettingsSelector';

const EmbedModeOptions = () => {
  const dispatch = useDispatch();
  const currentEmbedMode = useSelector(selectCurrentEmbedMode) || EMBED_MODES.STANDARD;

  const onChangeEmbedMode = mode => {
    dispatch(setCurrentEmbedMode(mode));
  };

  return (
    <Stack spacing={1} pb={2}>
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.STANDARD)}
        className={currentEmbedMode === EMBED_MODES.STANDARD ? 'activeOption' : ''}
        icon={<CalendarViewDayRoundedIcon />}
        mode={'Standard'}
      />
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.FULL_PAGE)}
        className={currentEmbedMode === EMBED_MODES.FULL_PAGE ? 'activeOption' : ''}
        icon={<SquareRoundedIcon />}
        mode={'Full Page'}
      />
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.POPUP)}
        className={currentEmbedMode === EMBED_MODES.POPUP ? 'activeOption' : ''}
        icon={<SquareRoundedIcon sx={{fontSize: '16px'}} />}
        mode={'Pop-up'}
      />
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.SLIDER)}
        className={currentEmbedMode === EMBED_MODES.SLIDER ? 'activeOption' : ''}
        icon={<RectangleRoundedIcon sx={{transform: 'translateX(6px) rotate(90deg)'}} />}
        mode={'Slider'}
      />
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.POPOVER)}
        className={currentEmbedMode === EMBED_MODES.POPOVER ? 'activeOption' : ''}
        icon={
          <FiberManualRecordRoundedIcon sx={{transform: 'translate(5px, 5px)', fontSize: '14px'}} />
        }
        mode={'Pop-over'}
      />
      <EmbedModeSingleOption
        onClick={() => onChangeEmbedMode(EMBED_MODES.SIDE_TAB)}
        className={currentEmbedMode === EMBED_MODES.SIDE_TAB ? 'activeOption' : ''}
        icon={
          <RectangleRoundedIcon
            sx={{transform: 'translateX(6px) rotate(90deg)', fontSize: '16px'}}
          />
        }
        mode={'Side Tab'}
      />
    </Stack>
  );
};

export default EmbedModeOptions;
