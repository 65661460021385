import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {Grid, Stack, Typography, Box} from '@mui/material';
import {FOLDER_DEFAULT_COLOR} from '../../helpers/constant/formFolderContant';
import CoreTextField from '../../common/TextField/CoreTextField';
import CoreButton from '../../common/Button/CoreButton';
import {registerPatternFolder} from '../../helpers/constant/registerPattern';
import LoadingButton from '../../common/Button/LoadingButton';
import {
  addFormFolder,
  resetAddFormFolderSuccess,
  resetUpdateFormFolderNameSuccess,
  updateFormFolderName
} from '../../state/features/formFolder/formFolderSlice';
import {selectFormFolderData} from '../../state/features/formFolder/formFolderSelector';

const AddOrEditFolderName = ({onClose, folderInfo = {}, isEditMode = false}) => {
  const dispatch = useDispatch();
  const {isFolderAdding, isFolderAddSuccess, isFolderUpdating, isFolderUpdateSuccess} =
    useSelector(selectFormFolderData);
  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (folderInfo?.name) {
      setValue('name', folderInfo.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderInfo]);

  useEffect(() => {
    if (isFolderAddSuccess || isFolderUpdateSuccess) {
      dispatch(resetAddFormFolderSuccess());
      dispatch(resetUpdateFormFolderNameSuccess());
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFolderAddSuccess, isFolderUpdateSuccess]);

  // handle submit add folder
  const onSubmit = data => {
    if (isEditMode) {
      dispatch(
        updateFormFolderName({
          id: folderInfo.id,
          name: data.name,
          color: folderInfo.color
        })
      );
    } else {
      dispatch(
        addFormFolder({
          name: data.name,
          color: FOLDER_DEFAULT_COLOR
        })
      );
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, bgcolor: 'background.paper', height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2medium'} color={'text.primary'} sx={{mb: 2}}>
                  Folder Name
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'Add folder name here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                  color="secondary"
                />
              </>
            )}
            rules={registerPatternFolder.name}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isFolderAdding || isFolderUpdating}
          loadingText={isEditMode ? 'Changing...' : 'Creating...'}
          type={'submit'}
          size={'large'}
          color={'success'}
        >
          {isEditMode ? 'Change' : 'Create'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditFolderName;
