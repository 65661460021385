import React from 'react';
import {useSelector} from 'react-redux';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import ParagraphGeneral from './ParagraphGeneral';
import ParagraphOptions from './ParagraphOptions';

const ParagraphRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  if (theme.layout === 'card') {
    return <ParagraphGeneral field={field} />;
  }

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <ParagraphGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <ParagraphOptions field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default ParagraphRightPanel;
