import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import Prototype from '../../helpers/builderConstant/FieldPrototypes';
import {useSelector, useDispatch} from 'react-redux';
import {nanoid} from 'nanoid';
import {DragDropContext} from 'react-beautiful-dnd';
import {useWindowSize} from '../../hooks/useWindowSize';
import {
  toggleBuilderSettingLeftPanel,
  toggleBuilderSettingRightPanel
} from '../../state/features/builderSetting/builderSettingSlice';
import {
  addBuilderField,
  changeSelectedBuilderField,
  reorderBuilderFields,
  reorderCardFields
} from '../../state/features/builderFields/builderFieldsSlice';
import BuilderRight from './BuilderRight';
import CardViewContent from './CardView/CardViewContent';
import BuilderContent from './BuilderContent';
import BuilderLeft from './BuilderLeft/BuilderLeft';
import Styles from './Builder.module.scss';

// We used useWindowSize hook because of the 1 sidebar hides on less than 1500 screen
// It happens when you click open sidebar button or resize the window.

const Builder = () => {
  const dispatch = useDispatch();
  const {width} = useWindowSize();
  const {isShowBuilderRightPanel, isShowBuilderLeftPanel, theme} = useSelector(
    state => state.builderSetting
  );

  const selectedField = useSelector(state => {
    if (state.builderFields.selectedIndex === null) {
      return state.builderSetting.theme;
    }
    return state.builderFields.fields[state.builderFields.selectedPageIndex].pageItems[
      state.builderFields.selectedIndex
    ];
  });

  useEffect(() => {
    if (width <= 1500 && isShowBuilderLeftPanel && isShowBuilderRightPanel) {
      handleRightSidePanel(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const handleLeftSidePanel = value => {
    dispatch(toggleBuilderSettingLeftPanel(value));
  };

  const handleRightSidePanel = value => {
    dispatch(toggleBuilderSettingRightPanel(value));
  };

  const handleThemeButtonClick = () => {
    dispatch(changeSelectedBuilderField(null));
    handleRightSidePanel(true);
  };

  const onDragEnd = result => {
    //If not in desired destination and not the same element dragging to the same index.
    if (
      result.destination === null ||
      (result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index)
    ) {
      return;
    }

    if (theme.layout === 'classic') {
      let destinationPageIndex = parseInt(result.destination.droppableId.replace('droppable_', ''));

      if (
        result.source.droppableId.includes('droppable_') &&
        result.destination.droppableId.includes('droppable_')
      ) {
        //If Reorder
        dispatch(
          reorderBuilderFields({
            sourceIndex: result.source.index,
            sourcePageIndex: parseInt(result.source.droppableId.replace('droppable_', '')),
            destinationIndex: result.destination.index,
            destinationPageIndex: destinationPageIndex
          })
        );
      } else {
        //If from left side
        let componentType = result.draggableId.replace(result.source.droppableId, '');

        dispatch(
          addBuilderField({
            destinationPageIndex: destinationPageIndex,
            destinationIndex: result.destination.index,
            component: {
              ...Prototype[componentType],
              id: nanoid(),
              labelAlignment: theme.labelAlignment
            }
          })
        );
      }
    } else {
      //If card view
      if (result.source.droppableId === 'cardViewDroppable') {
        //If reorder
        dispatch(
          reorderCardFields({
            sourceId: result.draggableId,
            destinationIndex: result.destination.index
          })
        );
      } else {
        //If from left side
        let componentType = result.draggableId.replace(result.source.droppableId, '');

        dispatch(
          addBuilderField({
            destinationIndex: result.destination.index,
            component: {
              ...Prototype[componentType],
              id: nanoid(),
              labelAlignment: theme.labelAlignment
            }
          })
        );
      }
    }
  };

  const handleOutsideClick = e => {
    if (
      e.target.getAttribute &&
      e.target.getAttribute('class') === e.currentTarget.getAttribute('class')
    ) {
      dispatch(changeSelectedBuilderField(null));
    }
  };

  return (
    <Box
      className={`${Styles.body}
${!isShowBuilderLeftPanel ? Styles.hideLeftSide : ''}
${!isShowBuilderRightPanel ? Styles.hideRightSide : ''}
${theme.layout === 'card' ? Styles.cardViewBody : ''}`}
      onMouseDown={handleOutsideClick}
    >
      <Box
        className={`${Styles.showOnTab} ${Styles.plusIcon}`}
        onClick={() => handleLeftSidePanel(true)}
      >
        {fbpIcons.fbpColorPlusIcon}
      </Box>
      <div className={`${Styles.showOnTab} ${Styles.themeIcon}`} onClick={handleThemeButtonClick}>
        {fbpIcons.fbpColorThemeIcon}
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <BuilderLeft
          onHideLeftSide={() => handleLeftSidePanel(false)}
          className={Styles.bodyLeftSide}
        />
        {theme.layout === 'classic' && <BuilderContent selectedField={selectedField} />}
        {theme.layout === 'card' && <CardViewContent selectedField={selectedField} />}
      </DragDropContext>

      <BuilderRight
        className={Styles.bodyRightSide}
        selectedField={selectedField}
        onHideRightSide={() => handleRightSidePanel(false)}
      />
    </Box>
  );
};

export default Builder;
