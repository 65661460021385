import React from 'react';
import {Box, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import BuilderElements from './BuilderElements';
import BuilderPages from './BuilderPages';
import {toggleBuilderSettingLeftPanelTab} from '../../../state/features/builderSetting/builderSettingSlice';
import HideSideBarIcon from '../../../common/Icons/HideSideBarIcon';
import BuilderLeftTabs, {BuilderLeftTab} from '../../../common/Tab/BuilderLeftTabs';
import Styles from './BuilderLeft.module.scss';

const BuilderLeft = ({onHideLeftSide, className}) => {
  const dispatch = useDispatch();
  const {builderLeftPanelTabIndex} = useSelector(state => state.builderSetting);
  const {theme} = useSelector(state => state.builderSetting);

  const handleChangeTab = index => {
    dispatch(toggleBuilderSettingLeftPanelTab(index));
  };

  return (
    <Box className={`${Styles.fbpFormBuilderBodyLeftSide} ${className}`}>
      <Box className={Styles.fbpFormBuilderLsTopbar}>
        <Typography variant={'body2'} color="common.white">
          Elements
        </Typography>
        <span className="showSidebarIcon" onClick={onHideLeftSide}>
          <HideSideBarIcon />
        </span>
      </Box>
      <Box sx={{height: 'calc(100% - 42px)'}}>
        {theme.layout === 'classic' && (
          <BuilderLeftTabs
            selectedTab={builderLeftPanelTabIndex}
            onChange={handleChangeTab}
            orientationPosition={'bottom'}
            indicatorColor={"secondary"}
          >
            <BuilderLeftTab title="Elements">
              <Box sx={{height: 'calc(100% - 48px)'}}>
                <BuilderElements layout={theme.layout} />
              </Box>
            </BuilderLeftTab>
            <BuilderLeftTab title="Pages" className={Styles.fbpBuilderPagesTab}>
              <BuilderPages />
            </BuilderLeftTab>
          </BuilderLeftTabs>
        )}
        {theme.layout === 'card' && <BuilderElements layout={theme.layout} />}
      </Box>
    </Box>
  );
};

export default BuilderLeft;
