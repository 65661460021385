import {Controller, useForm, useFormContext} from 'react-hook-form';

//This component is used only for public page validations
export const ConnectPublicForm = ({name, value, rules, render}) => {
  const {control} = useForm();
  const methods = useFormContext();

  return (
    <Controller
      control={methods ? methods.control : control}
      name={name}
      defaultValue={value}
      rules={rules}
      render={formOption => render(formOption, methods)}
    />
  );
};
