import {actionChannel, all, call, put, select, take} from 'redux-saga/effects';
import {updateIdMap} from '../builderFields/builderFieldsSlice';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';
import {builderQueueTypes} from './builderQueueTypes';

function* sequenceQueueManager() {
  const channel = yield actionChannel('MAKE_QUEUE');
  while (true) {
    const {payload} = yield take(channel);
    const {fieldsIdMap} = yield select(state => state.builderFields);
    yield put(updateBuilderTime('Saving...'));

    if (payload.params.elementId) {
      payload.params.elementId = fieldsIdMap[payload.params.elementId];
    }

    if (
      payload.type === builderQueueTypes.ADD_FIELD ||
      payload.type === builderQueueTypes.CLONE_FIELD
    ) {
      const response = yield call(payload.api, payload.params);
      yield put(updateIdMap({id: payload.id, elementId: response.data.elementId}));
    } else {
      yield call(payload.api, payload.params);
    }

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  }
}

export default function* builderChannelQueue() {
  yield all([sequenceQueueManager()]);
}
