import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {nanoid} from 'nanoid';
import {Box, Divider, Grid, Paper} from '@mui/material';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import CustomEditor from '../../../common/Editor/CustomEditor';
import {selectFormThankYouData} from '../../../state/features/FormThankYou/formThankYouSelector';
import {updateFormThankYouSetting} from '../../../state/features/FormThankYou/formThankYouSlice';

const ThankYouCustom = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const thankYouFormData = useSelector(selectFormThankYouData);

  const [renderId, setRenderId] = useState('');
  const [formState, setFormState] = useState({
    message: '',
    image: ''
  });

  useEffect(() => {
    if (thankYouFormData) {
      setFormState({
        ...formState,
        message: thankYouFormData.message || '',
        image: thankYouFormData.image || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankYouFormData]);

  // when update any state data and set new render id and dispatch update action
  useEffect(() => {
    if (renderId) {
      dispatch(
        updateFormThankYouSetting({
          formId: formId,
          thankyouPageSetting: JSON.stringify({...thankYouFormData, ...formState})
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId]);

  const onChangeEditorMessage = value => {
    setFormState({...formState, message: value});
  };

  const onBlurEditorMessage = value => {
    // when value actually changed, set new render id
    if (thankYouFormData?.message !== formState?.message) {
      setRenderId(nanoid(5));
    }
  };

  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'We’ll display this message when your form is submitted:'} titleVariant={'body2medium'} padding={1.5}/>
      <Divider />

      <Box p={1.5}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Box>
              <CustomEditor
                simpleVersion
                body={formState.message}
                onChangeBody={onChangeEditorMessage}
                onBlur={onBlurEditorMessage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ThankYouCustom;
