import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Stack} from '@mui/material';
import {ContentCopy, LibraryAddCheck} from "@mui/icons-material";
import CodeViewer from "../CodeViewer";
import CoreButton from '../../../../common/Button/CoreButton';
import {EMBED_MODES} from '../../../../helpers/constant/embedConstant';
import {getAccountData} from '../../../../helpers/utils/getAccountData';
import copyFromClipBoard from '../../../../helpers/utils/copyFromClipBoard';
import {selectEmbedSettings} from '../../../../state/features/embedSettings/embedSettingsSelector';
import {
  selectBuilderFormApiKey,
  selectBuilderFormTitle
} from '../../../../state/features/builderSetting/builderSettingSelector';
import {setEmbedCode} from "../../../../state/features/embedSettings/embedSettingsSlice";

const getSecretFormApiKey = (apiKey = '') => {
  let agencyId = getAccountData('agencyId');
  return window.btoa(apiKey + '/' + (agencyId || '1'));
};

const EmbedCopyCode = () => {
  const dispatch = useDispatch();
  let formApiKey = useSelector(selectBuilderFormApiKey) || '';
  formApiKey = getSecretFormApiKey(formApiKey);

  const formTitle = useSelector(selectBuilderFormTitle);
  const {prototype = {}, currentEmbedMode, embedCode, renderId} = useSelector(selectEmbedSettings);

  const currentPrototype = prototype[currentEmbedMode] || {};
  const {
    mode,
    width,
    widthSuffix,
    height,
    heightSuffix,
    popUpSize = 'large',
    buttonText = 'Get Started',
    buttonSize = 'large',
    buttonStyle = 'contained',
    buttonColor = '#6258FF',
    icon = ''
  } = currentPrototype;

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let div = '';

    let baseUrl = getAccountData('formCustomDomain') || "https://crmforms.io";
    // baseUrl="http://localhost:3017";

    const scriptSrc = baseUrl + '/embed.js';
    const script = `<script src="${scriptSrc}"></script>`;

    if (mode === EMBED_MODES.STANDARD) {
      const widthValue = width + widthSuffix;
      const heightValue = height + heightSuffix;
      div = `<div class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.STANDARD}" style="width: ${widthValue}; height: ${heightValue}"></div>${script}`;
    } else if (mode === EMBED_MODES.FULL_PAGE) {
      if (currentPrototype.withHtml) {
        div = `<!DOCTYPE html> <html lang="en"> <head> <meta charset="utf-8" /> <meta name="viewport" content="width=device-width, initial-scale=1" /> <title>${formTitle}</title> <style>*{margin:0;padding:0;} html,body,iframe{border-radius:0 !important;}</style> </head> <body> <div class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.FULL_PAGE}" style="width: 100%; height: 100vh"></div></body> </html>`;
      } else {
        div = `<div class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.FULL_PAGE}" style="width: 100%; height: 100vh"></div>${script}`;
      }
    } else if (mode === EMBED_MODES.POPUP) {
      div = `<button class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.POPUP}" data-size="${popUpSize}" data-button-style="${buttonStyle}" data-button-size="${buttonSize}" data-button-color="${buttonColor}">${buttonText}</button>${script}`;
    } else if (mode === EMBED_MODES.SLIDER) {
      div = `<button class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.SLIDER}" data-button-style="${buttonStyle}" data-button-size="${buttonSize}" data-button-color="${buttonColor}">${buttonText}</button>${script}`;
    } else if (mode === EMBED_MODES.POPOVER) {
      div = `<div class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.POPOVER}" data-button-color="${buttonColor}" data-image-icon="${icon}" style="all: unset"></div>${script}`;
    } else if (mode === EMBED_MODES.SIDE_TAB) {
      div = `<div class="embedded-form-rrsm" data-base-url="${baseUrl}" data-api-key="${formApiKey}" data-mode="${EMBED_MODES.SIDE_TAB}" data-button-color="${buttonColor}" data-button-text="${buttonText}" data-image-icon="${icon}" style="all: unset"></div>${script}`;
    }

    dispatch(setEmbedCode(div));
    if(copied) setCopied(!copied);
    // eslint-disable-next-line
  }, [mode, renderId]);

  const onCopy = React.useCallback(value => {
    copyFromClipBoard(value);
    setCopied(true);
  }, []);

  return (
    <Stack direction="column" spacing={1} py={2} >
      <CodeViewer>
        {embedCode}
      </CodeViewer>

      <Box sx={{textAlign: 'right'}}>
        <CoreButton
            size="small"
            variant="contained"
            color="secondary"
            startIcon={copied ? <LibraryAddCheck /> : <ContentCopy />}
            onClick={() => onCopy(embedCode)}
            sx={{bgcolor: 'text.secondary'}}
        >
          {copied ? 'Copied Code' : 'Copy Code'}
        </CoreButton>
      </Box>
    </Stack>
  );
};

export default EmbedCopyCode;
