import React from 'react';
import {Skeleton, Stack} from '@mui/material';

const CommonInputLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={'30%'} />
      <Skeleton variant="rect" width={'100%'} height={50} />
    </Stack>
  );
};

export default CommonInputLoader;
