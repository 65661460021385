import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const EmbedAnythingAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Title"
        title="Set the title text"
        placeholder="Title"
        value={field.title}
        onChange={e => onChangeValue({title: e.target.value})}
      />

      <RightSideInput
        text="Description"
        title="Set the description text"
        placeholder="Enter description"
        value={field.description}
        onChange={e => onChangeValue({description: e.target.value})}
      />

      <RightSideSwitch
        text="Show Link File"
        title="Show link on the box"
        checked={field.showLink}
        onChange={value => onChangeValue({showLink: value})}
      />

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(EmbedAnythingAdvanced);
