import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {alpha, Box, Grid, Stack, styled} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ConditionDropDownField from './ConditionDropDownField';
import ConditionTargetValueField from './ConditionTargetValueField';
import {selectAllInputBuilderFields} from '../../../../state/features/builderFields/builderSelector';
import {selectConditionSettingSingleRule} from '../../../../state/features/conditionSetting/conditionSettingSelector';
import {
  addCondition,
  modifyConditionSingleRuleCondition,
  removeCondition
} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {
  CONDITION_ACTION_STATE_LIST,
  CONDITION_ACTION_TARGET_LIST
} from '../../../../helpers/constant/formConditionConstant';
import ExtraSmallButton from '../../../../common/Button/ExtraSmallButton';

const StyledBox = styled(Box, {shouldForwardProp: props => props !== 'error'})(
  ({theme, error}) => ({
    width: '100%',
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: error
      ? alpha(theme.palette.error.main, theme.palette.action.activatedOpacity)
      : theme.palette.background.paper
  })
);

const ConditionController = () => {
  const dispatch = useDispatch();

  const fieldList = useSelector(selectAllInputBuilderFields);
  const singleRule = useSelector(selectConditionSettingSingleRule);

  const {condition = []} = singleRule || {};

  return (
    <Box>
      {condition.map((conditionItem, index) => {
        const {
          fieldId,
          component = 0,
          operator,
          target,
          value,
          isError = false
        } = conditionItem || {};

        const handleChangeCondition = (payload = {}) => {
          let newConditionItem = {...conditionItem, ...payload};

          if (payload.hasOwnProperty('fieldId')) {
            const selectedField = fieldList.find(field => field.id === payload.fieldId);
            if (selectedField.id) {
              newConditionItem = {
                ...newConditionItem,
                component: selectedField.component,
                operator: '',
                isError: false
              };
            }
          }

          if (
            payload.hasOwnProperty('operator') &&
            CONDITION_ACTION_TARGET_LIST[payload.operator]
          ) {
            newConditionItem = {
              ...newConditionItem,
              target: CONDITION_ACTION_TARGET_LIST[payload.operator][0]?.id,
              value: '',
              isError: false
            };
          } else if (
            payload.hasOwnProperty('operator') &&
            !CONDITION_ACTION_TARGET_LIST[payload.operator]
          ) {
            newConditionItem = {
              ...newConditionItem,
              target: '',
              value: '',
              isError: false
            };
          }

          dispatch(
            modifyConditionSingleRuleCondition({conditionItem: newConditionItem, index: index})
          );
        };

        const handleAddCondition = () => {
          dispatch(addCondition());
        };

        const handleRemoveCondition = index => {
          dispatch(removeCondition({index}));
        };

        // add or remove icon showing logic
        const isAddIconShow = index === 0;
        const isRemoveIconShow = index > 0 || condition?.length > 1;

        return (
          <Grid key={index} container spacing={1}>
            <Grid item xs={10} sx={{mb: 1}}>
              <StyledBox p={2} error={isError}>
                <Stack spacing={2}>
                  <ConditionDropDownField
                    name={'IF'}
                    value={fieldId}
                    options={fieldList}
                    placeholder={'Please select a field'}
                    onChange={event => handleChangeCondition({fieldId: event.target.value})}
                  />
                  <ConditionDropDownField
                    name={'STATE'}
                    disabled={!fieldId}
                    value={operator}
                    options={
                      CONDITION_ACTION_STATE_LIST[component] || CONDITION_ACTION_STATE_LIST[0]
                    }
                    placeholder={'Please select a field state'}
                    onChange={event => handleChangeCondition({operator: event.target.value})}
                  />
                  {CONDITION_ACTION_TARGET_LIST[operator] && (
                    <ConditionDropDownField
                      name={'TARGET'}
                      value={target}
                      options={CONDITION_ACTION_TARGET_LIST[operator]}
                      placeholder={''}
                      onChange={event => handleChangeCondition({target: event.target.value})}
                    />
                  )}
                  {CONDITION_ACTION_TARGET_LIST[operator] && (
                    <ConditionTargetValueField
                      fieldId={fieldId}
                      name={'VALUE'}
                      value={value}
                      onChange={event => handleChangeCondition({value: event.target.value})}
                    />
                  )}
                </Stack>
              </StyledBox>
            </Grid>

            <Grid item xs={2}>
              <Stack
                spacing={1}
                sx={{height: '100%', justifyContent: 'center', alignItems: 'center'}}
              >
                {isAddIconShow && (
                  <ExtraSmallButton
                    variant={'outlined'}
                    color={'secondary'}
                    size={'small'}
                    startIcon={<AddCircleIcon />}
                    onClick={handleAddCondition}
                    sx={{width: '120px'}}
                  >
                    Add Rule
                  </ExtraSmallButton>
                )}
                {isRemoveIconShow && (
                  <ExtraSmallButton
                    variant={'outlined'}
                    color={'error'}
                    size={'small'}
                    startIcon={<RemoveCircleIcon />}
                    onClick={() => handleRemoveCondition(index)}
                    sx={{width: '120px'}}
                  >
                    Remove Rule
                  </ExtraSmallButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default ConditionController;
