import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateAllFields} from '../state/features/builderFields/builderFieldsSlice';
import {updateBuilderTheme} from '../state/features/builderSetting/builderSettingSlice';

const withThemeChange =
  Component =>
  ({theme}) => {
    const dispatch = useDispatch();
    const [localTheme, setLocalTheme] = useState({...theme});

    useEffect(() => {
      setLocalTheme({...theme});
    }, [theme]);

    const handleChangeValue = value => {
      let themeValue = {...localTheme, ...value};
      setLocalTheme(themeValue);
      dispatch(updateBuilderTheme(themeValue));

      if (value.labelAlignment !== undefined) {
        dispatch(updateAllFields({type: 'labelAlignment', value: value.labelAlignment}));
      }
    };

    return <Component theme={localTheme} onChangeValue={handleChangeValue} />;
  };

export default withThemeChange;
