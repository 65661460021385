import httpRequest from '../api/httpRequest';

class FolderService {
  getFolders(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'folder/list', params);
  }

  addFolder(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'folder/create', body);
  }

  updateFolder(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'folder/update', body);
  }

  deleteFolder(params) {
    return httpRequest.delete(process.env.REACT_APP_BACKEND_URL_FORM_API + 'folder/remove', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FolderService();
