export const decryptUrlValue = base64Key => {
  try {
    const decryptedKey = window.atob(base64Key);
    const splittedValue = decryptedKey.split('/');
    return {
      apiKey: splittedValue[0],
      agencyId: splittedValue[1]
    };
  } catch {
    return {
      apiKey: '',
      agencyId: ''
    };
  }
};
