import React from 'react';
import {Stack, Box, useTheme} from '@mui/material';
import CustomChip from '../../../common/Chips/CustomChip.jsx';
import CustomAccordion from '../../../common/Accordion/CustomAccordion.jsx';
import RightComponentTitle from './RightComponentTitle.jsx';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons.js';
import {DEFAULT_THEME} from '../../../helpers/builderConstant/DefaultTheme.js';
import Styles from './RightSideElements.module.scss';

const colors = [
  {
    hexa: `#D2CCFF`,
    name: `nocolor`,
    setting: {
      formColor: '#ffffff',
      pageColor: '#D2CCFF',
      fontColor: '#2C3345',
      inputBackground: '#EDEDFF',
      subTextColor: '#546376',
      placeholderColor: '#b4b8c6',
      textColor: '#51405d'
    }
  },
  {
    hexa: `#006DF5`,
    name: `blue`,
    setting: {
      formColor: '#E4EFFF',
      pageColor: '#2462B9',
      fontColor: '#12458D',
      inputBackground: '#FFFFFF',
      subTextColor: '#12458D',
      placeholderColor: '#95a8ea',
      textColor: '#546376'
    }
  },
  {
    hexa: `#11111B`,
    name: `black`,
    setting: {
      formColor: '#424756',
      pageColor: '#11111B',
      fontColor: '#FFFFFF',
      inputBackground: '#838895',
      subTextColor: '#a6a9b3',
      placeholderColor: '#bbc1d3',
      textColor: '#ffffff'
    }
  },
  {
    hexa: `#FB6F41`,
    name: `orange`,
    setting: {
      formColor: '#FFDF79',
      pageColor: '#FB6F41',
      fontColor: '#6A1B00',
      inputBackground: '#FFFFFF',
      subTextColor: '#6A1B00',
      placeholderColor: '#cdb89c',
      textColor: '#a85817'
    }
  },
  {
    hexa: `#A352C2`,
    name: `violet`,
    setting: {
      formColor: '#F7F2FF',
      pageColor: '#A352C2',
      fontColor: '#412A68',
      inputBackground: '#FFFFFF',
      subTextColor: '#412A68',
      placeholderColor: '#b59ccd',
      textColor: '#670e6e'
    }
  },
  {
    hexa: `#83D0E4`,
    name: `sky`,
    setting: {
      formColor: '#F0FCFF',
      pageColor: '#83D0E4',
      fontColor: '#264B67',
      inputBackground: '#FFFFFF',
      subTextColor: '#264B67',
      placeholderColor: '#99c2c4',
      textColor: '#086889'
    }
  },
  {
    hexa: `#FF97BC`,
    name: `pink`,
    setting: {
      formColor: '#FFF8FA',
      pageColor: '#FF97BC',
      fontColor: '#962D53',
      inputBackground: '#FFFFFF',
      subTextColor: '#962D53',
      placeholderColor: '#c499b1',
      textColor: '#7e2a75'
    }
  },
  {
    hexa: `#A5CD68`,
    name: `green`,
    setting: {
      formColor: '#F4FFDF',
      pageColor: '#A5CD68',
      fontColor: '#566639',
      inputBackground: '#FFFFFF',
      subTextColor: '#566638',
      placeholderColor: '#97c995',
      textColor: '#276338'
    }
  },
  {
    hexa: `#5A4B44`,
    name: `chocolate`,
    setting: {
      formColor: '#EFE8D4',
      pageColor: '#5A4B44',
      fontColor: '#321F16',
      inputBackground: '#FFFFFF',
      subTextColor: '#321F16',
      placeholderColor: '#c9b395',
      textColor: '#784720'
    }
  }
];

function ColorScheme({text, title, onChange, layout = 'classic'}) {
  const theme = useTheme();
  return (
    <Box className={`${Styles.ColorScheme} builderElementBasicStyle`}>
      <CustomAccordion
        className={Styles.colorSchemeAccordion}
        summeryClass="noSpacing"
        expanded
        heading={
          <RightComponentTitle
            text={text}
            title={title}
            sideicon={fbpIcons.fbpInfoRoundIcon}
            className="colorSchemeTitle"
          />
        }
      >
        <Stack direction={'row'} sx={{flexWrap: 'wrap', gap: '13.3px'}}>
          {colors.map(color => {
            return (
              <Box
                key={color.hexa}
                className={Styles.fbpColor}
                sx={{
                  borderColor: `${color.hexa} !important`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={() => onChange(color.setting)}
              >
                {fbpIcons.fbpTickIcon}
              </Box>
            );
          })}

          <CustomChip
            text="Clear"
            color={theme.palette.error.main}
            onClose={() =>
              onChange({
                formColor: DEFAULT_THEME[layout].formColor,
                pageColor: DEFAULT_THEME[layout].pageColor,
                fontColor: DEFAULT_THEME[layout].fontColor,
                inputBackground: DEFAULT_THEME[layout].inputBackground,
                subTextColor: DEFAULT_THEME[layout].subTextColor,
                placeholderColor: DEFAULT_THEME[layout].placeholderColor,
                textColor: DEFAULT_THEME[layout].textColor
              })
            }
          />
        </Stack>
      </CustomAccordion>
    </Box>
  );
}

export default ColorScheme;
