import React from 'react';
import MaskedInput from 'react-text-mask';
import {InputAdornment} from '@mui/material';
import CustomBuilderInput from './TextField/CustomBuilderInput';
import {checkLetterExistInText} from '../helpers/utils/checkLetterExistInText';

const mask = [
  '+',
  /\d/,
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const CustomPhoneInput = ({
  className,
  placeholder,
  inputIcon,
  onChange,
  value,
  readOnly = false
}) => {
  const handleChange = e => {
    let value = e.target.value;
    if (checkLetterExistInText(value) || value === '') {
      if (value.length > 0 && value[0] !== '1') {
        value = 1 + value;
      }
      onChange(value);
    } else {
      try {
        value = value.replace(/[^0-9]+/g, '');
        if (value.length > 0 && value[0] !== '1') {
          value = 1 + value;
        }
        onChange(value);
      } catch (e) {
        console.log('Error:: ', e);
      }
    }
  };

  return (
    <MaskedInput
      mask={mask}
      className={className}
      placeholder={placeholder}
      guide={true}
      keepCharPositions={true}
      onChange={handleChange}
      value={value}
      render={(ref, props) => (
        <CustomBuilderInput
          inputRef={ref}
          type="tel"
          {...props}
          InputProps={{
            readOnly: readOnly,
            ...(inputIcon
              ? {
                  startAdornment: (
                    <InputAdornment position="start" sx={{'& span': {display: 'inline-flex'}}}>
                      {inputIcon}
                    </InputAdornment>
                  )
                }
              : {})
          }}
        />
      )}
    />
  );
};

export default CustomPhoneInput;
