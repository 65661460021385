import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, CircularProgress, useTheme} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PublicPageContent from '../../PublicView/PublicPageContent';
import Theming from '../../Builder/Theming/Theming';
import {fetchTemplatePreviewDetails} from '../../../state/features/publicPage/publicPageSlice';
import PageNotFound from '../../PublicView/PageNotFound';
import CardViewPublicPageContent from '../../PublicView/CardViewPublicPageContent';
import Styles from '../../Builder/BuilderIndex.module.scss';

const FormTemplatePreviewRender = ({templateId}) => {
  const dispatch = useDispatch();
  const themes = useTheme();
  const {isPageLoading, pageLoadError, theme} = useSelector(state => state.publicPage);

  useEffect(() => {
    dispatch(
      fetchTemplatePreviewDetails({
        templateId
      })
    );
  }, [dispatch, templateId]);

  if (pageLoadError) {
    return (
      <PageNotFound
        icon={<InfoIcon sx={{fontSize: 80, color: `${themes.palette.other.skylight}`}} />}
        text="This form is currently unavailable!"
      />
    );
  }

  return (
    <Box className={`${Styles.fbpFormBuilderPreviewWrapper} ${Styles.templatePreview}`}>
      {isPageLoading ? (
        <Box className={Styles.loaderWrapper}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={Styles.fbpFormBuilderBodyWrapper} sx={{width: '100%'}}>
          <Box className={Styles.fbpFormBuilderBodyInner}>
            <Box
              className={`${Styles.fbpFormBuilderScrollable} ${
                theme.layout === 'card' ? Styles.cardViewBodyWrapper : ''
              }`}
            >
              <Theming theme={theme} />
              <Box sx={{width: '100%', height: '100%'}}>
                {theme.layout === 'classic' ? (
                  <Box className={Styles.publicPageContentWrp}>
                    <PublicPageContent
                      theme={theme}
                      editMode
                      className={Styles.formTemplatePreview}
                    />
                  </Box>
                ) : (
                  <CardViewPublicPageContent theme={theme} editMode />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FormTemplatePreviewRender;
