import React from 'react';
import {Link} from 'react-router-dom';

const RouteLink = ({to = '', children, hover = false, ...rest}) => {
  return (
    <Link
      to={location => ({
        ...location,
        pathname: to,
        search: '',
        state: {prevPath: window.location.pathname}
      })}
      style={{
        color: 'inherit',
        textDecoration: hover ? 'underline' : 'none',
        display: 'flex'
      }}
      {...rest}
    >
      {children || null}
    </Link>
  );
};

export default RouteLink;
