import React from 'react';
import {SvgIcon} from '@mui/material';

const CloneIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.4 10.8C8.4 9.475 9.475 8.4 10.8 8.4H18C19.326 8.4 20.4 9.475 20.4 10.8V18C20.4 19.326 19.326 20.4 18 20.4H10.8C9.475 20.4 8.4 19.326 8.4 18V10.8Z"
        fill="#949DB2"
      />
      <path
        d="M6 3.6C4.675 3.6 3.6 4.675 3.6 6V13.2C3.6 14.526 4.675 15.6 6 15.6L6 6H15.6C15.6 4.675 14.526 3.6 13.2 3.6H6Z"
        fill="#949DB2"
      />
    </SvgIcon>
  );
};

export default CloneIcon;
