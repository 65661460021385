import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  page: 1,
  limit: 10,
  count: 10,
  isLoading: false,
  isError: false,
  error: '',

  isDeleting: false,
  filters: {
    searchText: ''
  }
};

const exportedFileSlice = createSlice({
  name: 'exportedFile',
  initialState: initialState,
  reducers: {
    getExportedFiles: (state, action) => {
      state.isLoading = true;
      if (action.payload) {
        state.page = action.payload.page || 1;
        state.limit = action.payload.limit || 10;
      }
    },
    getExportedFilesSuccess: (state, action) => {
      state.data = action.payload.content;
      state.limit = action.payload.limit;
      state.count = action.payload.count;
      state.page = action.payload.currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getExportedFilesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    deletedExportedFile: (state, action) => {
      state.isDeleting = true;
    },
    deletedExportedFileSuccess: (state, action) => {
      state.isDeleting = false;
      state.data = state.data.filter(tag => !action.payload.ids.includes(tag.id));
    },
    deletedExportedFileFailed: (state, action) => {
      state.isDeleting = false;
    },

    // set filters
    addExportedFileSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    resetExportedFileFilters: (state, action) => {
      state.filters.searchText = '';
    }
  }
});

export const {
  getExportedFiles,
  getExportedFilesSuccess,
  getExportedFilesFailed,

  deletedExportedFile,
  deletedExportedFileSuccess,
  deletedExportedFileFailed,

  addExportedFileSearchText,
  resetExportedFileFilters
} = exportedFileSlice.actions;

export default exportedFileSlice.reducer;
