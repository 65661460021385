import React from 'react';
import {Skeleton} from '@mui/material';

const InboxLeftSideSkeleton = () => {
  return (
    <>
      <Skeleton variant="rect" style={{marginTop: '16px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
      <Skeleton variant="rect" style={{marginTop: '12px'}} height={70} />
    </>
  );
};

export default InboxLeftSideSkeleton;
