import {useEffect, useState} from 'react';
import {styled} from '@mui/styles';
import {Typography} from '@mui/material';
import Switch from 'react-switch';

const StyledSwitch = styled(Switch)(({theme}) => ({
  transform: 'none !important',
  '& .react-switch-bg ': {
    background: `${theme.palette.common.white} !important`,
    border: `1px solid ${theme.palette.other.lightGray} !important`,
    height: '28px !important',
    lineHeight: '26px',
    borderRadius: '30px !important',
    padding: '0 5px'
  },
  '& .react-switch-handle': {
    height: '20px !important',
    width: '20px !important',
    top: '4px !important',
    left: '3px !important'
  },
  '&.pbfCustomOutlineSwitchWrActiveMode': {
    '& .react-switch-bg ': {
      borderColor: `${theme.palette.secondary.main} !important`
    },
    '& .react-switch-handle': {
      top: '4px !important',
      left: 'auto !important'
    }
  }
}));

const CustomSwitch = ({
  variant = 'contained',
  status,
  onChange,
  brandColor,
  labelChecked = 'yes',
  labelUnchecked = 'no',
  width = 62,
  disabled=false
}) => {
  const [checked, setChecked] = useState(status);
  const [outLineColor, setOutLineColor] = useState('');

  useEffect(() => {
    setChecked(status);
    if (variant === 'outlined' && checked) {
      setOutLineColor('#6258FF');
    } else {
      setOutLineColor('#1C1B1F');
    }
  }, [status, checked, variant]);

  const handleChange = () => {
    onChange(!checked);
  };

  const UnChackedIcon = () => {
    return (
      <Typography
        variant="chipLight"
        color={outLineColor}
        sx={{textTransform: 'uppercase', position: 'absolute', top: '5px', right: '5px'}}
      >
        {labelUnchecked}
      </Typography>
    );
  };

  const CheckedIcon = () => {
    return (
      <Typography variant="chipLight" color={outLineColor}>
        {labelChecked}
      </Typography>
    );
  };

  return (
    <StyledSwitch
      className={
        (variant === 'outlined' ? 'pbfCustomOutlineSwitchWr ' : ' ') +
        (checked ? 'pbfCustomOutlineSwitchWrActiveMode' : 'pbfCustomOutlineSwitchWrInactiveMode')
      }
      onChange={handleChange}
      checked={checked}
      uncheckedIcon={UnChackedIcon()}
      checkedIcon={<CheckedIcon brandColor={brandColor !== undefined ? brandColor : '#880808'} />}
      boxShadow="0 0 0px 0px #3bf"
      activeBoxShadow="0 0 0px 0px #3bf"
      onHandleColor="#006DF5"
      offHandleColor="#BDBDBD"
      onColor={brandColor !== undefined ? brandColor : '#F88379'}
      height={24}
      width={width}
      disabled={disabled}
    />
  );
};
export default CustomSwitch;
