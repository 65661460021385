import React from 'react';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {Stack, Box} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';
import Styles from './Video.module.scss';

const VideoComponent = ({field, onChange, editMode}) => {
  const alignemntClass =
    field.positioning === 'default' ? Styles[field.alignment] : Styles.fullWidth;

  return (
    <Box sx={{position: 'relative'}}>
      {!field.video && (
        <Box className={`${Styles.fbpVideo} ${alignemntClass}`}>
          <Box>
            <svg
              width="57"
              height="56"
              viewBox="0 0 57 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.83378 14.0001H5.16711V46.6667C5.16711 49.2334 7.26711 51.3334 9.83378 51.3334H42.5004V46.6667H9.83378V14.0001ZM47.1671 4.66675H19.1671C16.6004 4.66675 14.5004 6.76675 14.5004 9.33341V37.3334C14.5004 39.9001 16.6004 42.0001 19.1671 42.0001H47.1671C49.7338 42.0001 51.8338 39.9001 51.8338 37.3334V9.33341C51.8338 6.76675 49.7338 4.66675 47.1671 4.66675ZM28.5004 33.8334V12.8334L42.5004 23.3334L28.5004 33.8334Z"
                fill="#949DB2"
              />
            </svg>
          </Box>
        </Box>
      )}

      {field.video && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={`${Styles.fbpVideo} ${alignemntClass}`}
          sx={{bgcolor: 'transparent', border: 'none', p: '0'}}
        >
          <Box className={Styles.playVideo}>
            <video width="100%" height="100%" controls="false" src={field.video}>
              Your browser does not support HTML video.
            </video>
            {/* <Box className={Styles.playDuration} sx={{display: 'flex'}}>
              <Box>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.001 0C4.48098 0 0.000976562 4.48 0.000976562 10C0.000976562 15.52 4.48098 20 10.001 20C15.521 20 20.001 15.52 20.001 10C20.001 4.48 15.521 0 10.001 0ZM8.00098 14.5V5.5L14.001 10L8.00098 14.5Z"
                    fill="white"
                  />
                </svg>
              </Box>
              <Box className={Styles.videoDuration} ml={2}>
                <Typography variant="body2medium">00:15</Typography>
              </Box>
            </Box> */}
            {editMode && (
              <CoreButton
                variant="contained"
                size={'small'}
                sx={{position: 'absolute', right: '10px', top: '10px'}}
                onClick={() => onChange({video: null})}
              >
                remove
              </CoreButton>
            )}
          </Box>
        </Stack>
      )}
      {field.hidden && <HiddenFieldAlert className="mt-3" />}
    </Box>
  );
};

export default VideoComponent;
