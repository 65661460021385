import {Box, FormControlLabel, Stack, styled} from '@mui/material';

export const ListViewItemBox = styled(Stack)(({theme, selecteditem}) => ({
  width: '100%',
  minHeight: '45px',
  height: 'max-content',
  border: `1px solid ${
    selecteditem === "true" ? theme.palette.secondary.main : theme.palette.other.outlinedBorder
  }`,
  flexWrap: 'wrap',
  padding: theme.spacing(1.25),
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: 'var(--fbp-theming-input-background)',
  '&:focus': {
    borderColor: theme.palette.secondary.main
  }
}));

export const GridViewItemBox = styled(Stack)(({theme, selecteditem}) => ({
  minHeight: '150px',
  height: '100%',
  border: `1px solid ${
    selecteditem === "true" ? theme.palette.secondary.main : theme.palette.other.outlinedBorder
  }`,
  flexWrap: 'wrap',
  padding: theme.spacing(1.25),
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: 'var(--fbp-theming-input-background)',
  cursor: 'pointer',
  '&:focus': {
    borderColor: theme.palette.secondary.main
  },
  '&:last-child': {
    flexBasis: 0
  }
}));
export const SelectedIcon = styled(Box)(({theme, selected}) => ({
  width: '32px',
  height: '32px',
  borderRadius: '0 2px 0 8px',
  background: theme.palette.secondary.main,
  color: theme.palette.common.white,
  display: 'grid',
  placeItems: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  transform: selected === 'true' ? 'scale(1)' : 'scale(0)',
  transformOrigin: 'top right',
  transition: 'all 0.3s ease'
}));
export const FormControlLabelStyled = styled(FormControlLabel)(({theme}) => ({
  '&.MuiFormControlLabel-root': {
    margin: '0',
    '& .MuiRadio-root': {
      color: 'var(--fbp-theming-font-color)'
    }
  }
}));
