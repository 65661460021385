import React from 'react';
import {alpha, Box, Stack, styled, Typography} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const ContainerBox = styled(Box)(({theme}) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: theme.spacing(0.5),
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[4]
  }
}));

const AddNewConditionItem = ({condition, onClick}) => {
  const {heading, subHeading, icon, color} = condition || {};

  return (
    <ContainerBox sx={{borderColor: alpha(color, 0.4)}} onClick={onClick}>
      <Stack direction={'row'} alignItems={'center'} p={2}>
        <Box mr={2} sx={{display: 'inline-flex', color: color}}>
          {icon}
        </Box>
        <Box>
          <Typography variant="body1medium" color={'text.primary'}>
            {heading}
          </Typography>

          <Typography variant="body2" color={'text.secondary'}>
            {subHeading}
          </Typography>
        </Box>
        <Box sx={{marginLeft: 'auto', display: 'inline-flex'}}>
          <ArrowCircleRightIcon sx={{color: color}} />
        </Box>
      </Stack>
    </ContainerBox>
  );
};

export default AddNewConditionItem;
