import {styled, Box} from '@mui/material';

export const StyledBox = styled(Box)(({theme, themes}) => ({
  height: '100%',
  maxHeight: '177px',
  minHeight: '177px',
  overflow: 'hidden',
  background: theme.palette.other.outlinedBorder,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: '1 0',
  '& span': {
    position: 'absolute',
    right: '16px',
    top: '16px',
    padding: '6px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'inline-flex',
    cursor: 'pointer'
  },
  '& img': {
    width: '100%',
    height: '100%',
    minHeight: '177px',
    objectFit: 'cover'
  }
}));

export const StyledTemplateCard = styled(Box)(({theme}) => ({
  Width: {xs: '100%', sm: '48%', md: '24%', lg: '32%'},
  // maxHeight: '332px',
  height: 'auto',
  minHeight: '320px',
  cursor: 'pointer',
  background: theme.palette.common.white,
  border: `0.5px solid ${theme.palette.other.outlinedBorder}`,
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: '1 1 29%',
  [theme.breakpoints.down('large')]: {
    flex: '0 1 calc(50% - 16px)'
  },
  [theme.breakpoints.up('large')]: {
    flex: '0 1 calc(33% - 18px)'
  },
  [theme.breakpoints.up('extraLg')]: {
    flex: '0 1 calc(25% - 24px)'
  },
  [theme.breakpoints.up('xxl')]: {
    flex: '0 1 calc(20% - 24px)'
  },
  [theme.breakpoints.down('medium')]: {
    flex: '1 1 100%'
  }
}));
