import React from 'react';
import {styled, Box, Slider, alpha} from '@mui/material';

export const LineSlider = styled(Slider)(({theme, progressBarColor, backgroundColor}) => ({
  '& .MuiSlider-track': {
    border: 'none',
    color: progressBarColor
  },  
  '& .MuiSlider-rail': {
    color: alpha(progressBarColor,0.5)
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: progressBarColor,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      height: 24,
      width: 24,
      border: `2px solid ${progressBarColor}`
    }
  },
  '& .MuiSlider-valueLabel': {
    color: progressBarColor,
    backgroundColor: backgroundColor,
    transform: 'translateY(-110%) scale(1) !important'
  }
}));

const valueLabelFormat = value => {
  return `${Math.round(value)}%`;
};

const BasicSliderStepper = ({percentage, color, backgroundColor}) => {
  return (
    <Box sx={{width: '100%'}} p={4.3} pb={0}>
      <LineSlider
        value={percentage}
        min={0}
        step={1}
        max={100}
        progressBarColor={color}
        backgroundColor={backgroundColor}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="on"
        aria-labelledby="non-linear-slider"
        disableSwap
      />
    </Box>
  );
};

export default BasicSliderStepper;
