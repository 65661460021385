import React from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import withThemeChange from '../../../hoc/withThemeChange';
import RightSideUploadButtonComponent from '../../Builder/RightComponents/RightSideUploadButtonComponent';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';
import RightSideColorPicker from '../../Builder/RightComponents/RightSideColorPicker';
import RightSideSlider from '../../Builder/RightComponents/RightSideSlider';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import CoreButton from '../../../common/Button/CoreButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {DEFAULT_IMAGE_SETTINGS} from '../../../helpers/builderConstant/DefaultTheme';

const FormImageRightPanel = ({theme, onChangeValue}) => {
  const dispatch = useDispatch();

  const handleEditOptionOpen = componentType => {
    dispatch(changeSelectedBuilderField({index: null, pageIndex: null, componentType}));
  };

  return (
    <Box sx={{mt: 2}}>
      <Box px={2} pb={2}>
        <CoreButton
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleEditOptionOpen(COMPONENT_TYPE.THEME)}
        >
          Back
        </CoreButton>
      </Box>

      {!theme.formImage && (
        <RightSideColorPicker
          text="Form Color"
          title="Change the color of the form body"
          color={theme.formColor}
          onChange={color => onChangeValue({formColor: color})}
          className={'builderElementBasicStyle'}
        />
      )}

      <RightSideUploadButtonComponent
        text="Form Image"
        title="The image will visible as background of the form"
        buttonText="Choose Image"
        recommendedSize={'4160 x 6240'}
        onUpload={image => onChangeValue({formImage: image})}
      />

      {theme.formImage && (
        <>
          <RightSideImagePreviewComponent
            text="Form Image"
            title="Form Image Preview"
            image={theme.formImage}
            onRemove={() => onChangeValue({formImage: null, ...DEFAULT_IMAGE_SETTINGS.formImage})}
          />

          <RightSideSlider
            step={0.05}
            min={0}
            max={1}
            text="Opacity"
            title="Change the image opacity"
            value={theme.formImageOpacity}
            onChangeValue={value => onChangeValue({formImageOpacity: value})}
          />

          <RightSideColorPicker
            text="Opacity Color"
            title="Change the opacity color to the form image"
            color={theme.formImageColor}
            onChange={color => onChangeValue({formImageColor: color})}
            className={'builderElementBasicStyle'}
          />

          {theme.layout === 'classic' && (
            <RightSideDropdown
              text="Image Attachment"
              title="Specifies whether the background images are fixed with regard to the viewport ('fixed') or scroll along with the element ('scroll')"
              data={[
                {title: 'Scroll', value: 'scroll'},
                {title: 'Fixed', value: 'fixed'}
              ]}
              value={theme.formImageAttachment}
              onChange={e => onChangeValue({formImageAttachment: e.target.value})}
            />
          )}

          <Box p={2} sx={{textAlign: 'end'}}>
            <CoreButton
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              startIcon={<RestartAltIcon />}
              onClick={() => onChangeValue({...DEFAULT_IMAGE_SETTINGS.formImage})}
            >
              Reset Settings
            </CoreButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withThemeChange(FormImageRightPanel);
