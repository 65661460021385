import React, {useState} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  styled,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSolidSwitch from '../Switch/CustomSolidSwitch';

const StyledAccordion = styled(Accordion)(({theme}) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  '&:before': {content: 'none'},
  '&.Mui-expended': {
    margin: 0
  },
  '&.MuiAccordion-root': {
    '&.Mui-expanded': {
      marginBottom: 0,
      '&:first-of-type': {
        marginTop: '14px'
      }
    }
  },
  '& .MuiAccordionSummary-root': {
    '&.Mui-expanded': {
      minHeight: '48px'
    }
  },
  '& .MuiAccordionSummary-content': {
    '&.Mui-expanded': {
      margin: 0
    }
  }
}));

const AccordionDivider = styled(Divider)(({theme}) => ({
  margin: '0 16px',
  opacity: theme.palette.action.disabledOpacity
}));

export default function FooterSettingAccordion({
  children,
  expanded,
  title,
  status,
  onChangeStatus = () => {},
  disabled=false
}) {
  const [isExpanded, setIsExpanded] = useState(Boolean(expanded));

  return (
    <StyledAccordion
      expanded={isExpanded && status}
      onChange={() => setIsExpanded(!isExpanded)}
      elevation={0}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{width: '100%'}}
        >
          <Typography variant="body2medium" color={'text.primary'}>
            {title}
          </Typography>
          <CustomSolidSwitch
            disabled={disabled}
            checked={status}
            onChange={e => {
              onChangeStatus(e.target.checked);
              setIsExpanded(e.target.checked);
            }}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0}}>
        <Typography variant="body2" color={'text.secondary'} component="div">
          <AccordionDivider />
          {children}
        </Typography>
      </AccordionDetails>
    </StyledAccordion>
  );
}
