import React from 'react';
import {useDispatch} from 'react-redux';
import {format} from 'date-fns';
import {Box, Checkbox, Chip, Divider, FormControlLabel, Stack, Typography} from '@mui/material';
import {DeleteForever, RestoreFromTrash, Star, StarBorder} from '@mui/icons-material';
import {CustomIconList, CustomList} from './CustomListStyles';
import FolderNameChipList from './FolderNameChipList';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import RouteLink from '../../common/Link/RouteLink';
import {castArray} from '../../helpers/utils/castArray';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import {MODULE_NAMES, TRASH_TYPES} from '../../helpers/constant/CoreConstants';
import {
  formRemoveForever,
  restoreForm,
  toggleFavouriteForm,
  toggleSelectedSingleForm
} from '../../state/features/folderForms/folderFormsSlice';

const FormListEachTrash = ({formData, checked}) => {
  const dispatch = useDispatch();
  const {
    id,
    folder,
    formTitle,
    totalResponse,
    new: newResponse,
    favourite,
    updatedAt,
    status
  } = formData || {};

  const handleSelectForm = () => {
    dispatch(toggleSelectedSingleForm(id));
  };

  const handleToggleFavourite = () => {
    dispatch(toggleFavouriteForm({ids: castArray(id), favourite: !favourite}));
  };

  const handleDeleteForeverForm = () => {
    dispatch(
      formRemoveForever({
        ids: castArray(id),
        module: MODULE_NAMES.FORM,
        trashType: TRASH_TYPES.HARD
      })
    );
  };

  const handleRestoreForm = () => {
    dispatch(restoreForm({ids: castArray(id)}));
  };

  const formatUpdatedAt = updatedAt ? format(new Date(updatedAt), 'dd MMM yyyy') : 'N/A';

  return (
    <CustomList className={`${checked && 'active'} EmptyList`}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          opacity: status === FORM_STATUS.PUBLISHED ? 1 : 0.6
        }}
      >
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleSelectForm} color="primary" />}
          label={' '}
        />

        <span>
          <Checkbox
            inputProps={{'aria-label': 'Checkbox demo'}}
            icon={<StarBorder />}
            checkedIcon={<Star sx={{color: 'rgb(255, 180, 0)'}} />}
            checked={favourite}
            onClick={handleToggleFavourite}
          />
        </span>
        <Stack direction="column" spacing={1} ml={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            sx={{maxWidth: `100%`}}
          >
            <RouteLink to={`/fbuilder/form/${id}`}>
              <Typography
                variant={'body1'}
                className="text-truncate"
                title={formTitle}
                sx={{maxWidth: folder.length > 0 ? '150px' : '100%'}}
              >
                {formTitle}
              </Typography>
            </RouteLink>
            <FolderNameChipList folder={folder} id={id} />
          </Stack>

          <Stack direction={'row'} justifyContent="flex-start" spacing={1}>
            <Typography variant={'body2'}>
              {totalResponse} Responses{' '}
              {newResponse && <Chip size={'small'} label={`${newResponse} New`} color="error" />}
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography variant={'body2'}>Updated on: {formatUpdatedAt}</Typography>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{position: 'relative'}}>
        <CustomIconList className="trashPageDeleteList ">
          <li onClick={handleDeleteForeverForm}>
            <CustomTooltip title="Delete Forever" arrow placement="top">
              <DeleteForever color={'action'} />
            </CustomTooltip>
          </li>
          <li onClick={handleRestoreForm}>
            <CustomTooltip title="Restore" arrow placement="top">
              <RestoreFromTrash color={'action'} />
            </CustomTooltip>
          </li>
        </CustomIconList>
        {/*<Typography*/}
        {/*  variant="body2"*/}
        {/*  color="warning.dark"*/}
        {/*  className={'daysRemaining'}*/}
        {/*  sx={{*/}
        {/*    visibility: 'visible'*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {difference} {difference > 1 ? 'days' : 'day'} remaining*/}
        {/*</Typography>*/}
      </Box>
    </CustomList>
  );
};

export default React.memo(FormListEachTrash);
