import React from 'react';
import {Typography, Box, Stack, styled} from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Styles from './thank-you.module.scss';

const DefaultTySubInnerWrp = styled(Stack)(({theme}) => ({
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  backgroundColor: theme.palette.other.pageColor,
  borderRadius: '4px',
  width: '85%',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  zIndex: 9,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center'
  }
}));

const DefaultTyRight = styled(Stack)(({theme}) => ({
  flex: '1 1 50%',
  display: 'grid',
  placeItems: 'center',
  backgroundColor: theme.palette.other.secondaryHover,
  height: '384px',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    width: '100%',
    flex: '1 1 100%'
  }
}));

const ThankYouPageDesign = ({padding = '60px 0px'}) => {
  return (
    <Box sx={{height: '100%', display: 'grid', placeItems: 'center', padding: padding}}>
      <DefaultTySubInnerWrp className={Styles.fbpDefaultTyWr}>
        <Stack
          direction="column"
          justifyContent="center"
          sx={{flex: {xs: '1 1 100%', sm: '1 1 50%'}, p: 2}}
          className={Styles.fbpDefaultTyLeft}
        >
          <Typography variant="h3" color={'text.primary'}>
            Thank You
          </Typography>
          <Typography variant="body1" color={'text.primary'}>
            Your information has been submitted!
          </Typography>
        </Stack>
        <DefaultTyRight p={2} className={Styles.fbpDefaultTyRight}>
          <FactCheckIcon color={'secondary'} sx={{fontSize: '8rem'}} />
        </DefaultTyRight>
      </DefaultTySubInnerWrp>
    </Box>
  );
};

export default ThankYouPageDesign;
