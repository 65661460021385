import React from 'react';
import {Box, Stack} from '@mui/material';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightsideInlineInput from '../../Builder/RightComponents/RightsideInlineInput';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const AddressGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Field Label"
        title="Change the label of the input"
        value={field.label}
        onChange={e => onChangeValue({label: e.target.value})}
      />
      {theme.layout === 'card' && (
        <RightSideInput
          text="Description"
          title="Change the description of the field"
          value={field.description}
          onChange={e => onChangeValue({description: e.target.value})}
          placeholder="Description"
        />
      )}
      <RightSideSwitch
        text="Required Field"
        title="Set the field required on submission"
        checked={field.required}
        onChange={value => onChangeValue({required: value})}
      />
      <Stack
        direction="column"
        sx={{gap: '10px'}}
        className="builderElementBasicStyle"
      >
        <RightComponentTitle text="Helper Texts" title="Change text bottom input" />
        <RightsideInlineInput
          label="Street Address 1"
          value={field.streetAddressHelperText}
          onChange={e => onChangeValue({streetAddressHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="Street Address 2"
          value={field.streetAddress2HelperText}
          onChange={e => onChangeValue({streetAddress2HelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="City"
          value={field.cityHelperText}
          onChange={e => onChangeValue({cityHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="State / Province"
          value={field.stateHelperText}
          onChange={e => onChangeValue({stateHelperText: e.target.value})}
        />
        <RightsideInlineInput
          label="Postal/Zip Code"
          value={field.zipHelperText}
          onChange={e => onChangeValue({zipHelperText: e.target.value})}
        />
      </Stack>
      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(AddressGeneral);
