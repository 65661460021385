import {nanoid} from 'nanoid';
import React from 'react';
import {useMemo} from 'react';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import Prototype from '../../../../helpers/builderConstant/FieldPrototypes';
import {ALL_BLOCKS} from '../../../../helpers/builderConstant/LayoutBlocks';
import {addBuilderField} from '../../../../state/features/builderFields/builderFieldsSlice';
import Styles from './Elements.module.scss';
import {Typography} from '@mui/material';

const escapeRegex = value => {
  return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&'); // eslint-disable-line no-useless-escape
};

function getStyle(style, snapshot) {
  if (!snapshot.isDragging) {
    return {transform: 'none'};
  }
  if (!snapshot.isDropAnimating) {
    return {
      ...style,
      border: '1px dashed #4099ff',
      top: style.top - 50
    };
  }

  return {
    ...style,
    transform: 'none',
    transitionDuration: `0.001s`
  };
}

const SearchedElements = ({query}) => {
  const dispatch = useDispatch();
  const {theme} = useSelector(state => state.builderSetting);

  const searchedElements = useMemo(() => {
    let duplicate = {};
    let elements = [];
    ALL_BLOCKS.forEach(eachBlock => {
      eachBlock.blocks.forEach(eachElement => {
        if (eachElement.title.match(new RegExp(escapeRegex(query), 'gi'))) {
          if (!duplicate[eachElement.type]) {
            elements.push({...eachElement, heading: eachBlock.heading});
            duplicate[eachElement.type] = true;
          }
        }
      });
    });
    return elements;
  }, [query]);

  const handleInsertField = prototype => {
    dispatch(
      addBuilderField({
        component: {
          ...prototype,
          id: nanoid(),
          ...(prototype.labelAlignment && {labelAlignment: theme.labelAlignment})
        }
      })
    );
  };

  return (
    <Droppable droppableId={'searched_elements'} isDropDisabled={true}>
      {(provided, snapshot) => {
        return (
          <div
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            className={Styles.fbpBuildersElementsWrapper}
          >
            {searchedElements.map((element, index) => (
              <Draggable key={index} draggableId={'searched_elements' + element.type} index={index}>
                {(provided, snapshot) => (
                  <>
                    <div
                      className={Styles.fbpLeftEachElement}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      style={getStyle(provided.draggableProps.style, snapshot)}
                      onClick={() => handleInsertField(Prototype[element.type])}
                    >
                      {element.icon}
                      <Typography variant={'helperText2'} color="text.secondary">
                        {element.title}
                      </Typography>
                    </div>
                    {snapshot.isDragging && (
                      <div className={Styles.fbpLeftEachElement}>
                        {element.icon}
                        <Typography variant={'helperText2'} color="text.secondary">
                          {element.title}
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

export default SearchedElements;
