import React from 'react';
import CustomTextField from '../../../common/TextField/CustomTextField';
import RightComponentTitle from './RightComponentTitle';
import {useTheme, Stack, Typography} from '@mui/material';

const RightSideNumberInput = ({
  text,
  title,
  hasPadding = false,
  unit = '',
  value = '',
  placeholder = '',
  onChange = null
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <Stack direction="row" sx={{marginLeft: `${hasPadding ? '8px' : ''}`, width: '100%'}}>
        <CustomTextField
          size="medium"
          width="100%"
          labelPosition="top"
          min={1}
          type="number"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className="styleOptionInput"
        />
        {unit !== '' && (
          <Typography
            component={'span'}
            variant="inputText"
            color="text.primary"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '43px',
              height: '44px',
              background: theme.palette.action.selected,
              borderRadius: '0px 4px 4px 0px'
            }}
          >
            {unit}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default RightSideNumberInput;
