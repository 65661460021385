import {useState, useEffect} from 'react';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(0);

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    handleSize();
    window.addEventListener('resize', handleSize);
    window.addEventListener('load', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
      window.removeEventListener('load', handleSize);
    };
  }, []);

  return windowSize;
};
