import React from 'react';
import {Stack, styled, Typography} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
      maxWidth: '600px'
    }
  }
};

const MenuItemStyled = styled(MenuItem)(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  display: 'block'
}));

const ConditionDropDownField = ({
  name,
  value = '',
  onChange,
  options = [],
  placeholder = '',
  ...rest
}) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2}>
      <Typography variant="body1SemiBold" style={{width: '150px'}}>
        {name}
      </Typography>
      <Select
        displayEmpty
        size={'small'}
        color={'secondary'}
        inputProps={{'aria-label': 'Without label'}}
        name={'status'}
        onChange={onChange}
        value={value}
        sx={{width: '70%'}}
        MenuProps={MenuProps}
        {...rest}
      >
        <MenuItemStyled disabled={true} value="">
          <em>{placeholder}</em>
        </MenuItemStyled>
        {options.map(item => (
          <MenuItemStyled
            key={item.id}
            value={item.id}
            title={item.label?.length > 50 ? item.label : ''}
          >
            {item.label}
          </MenuItemStyled>
        ))}
      </Select>
    </Stack>
  );
};

export default ConditionDropDownField;
