import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import {styled} from '@mui/material';
import TemplateSearch from './TemplateSearch';
import TemplateBulkActions from './TemplateBulkAction';
import {selectSelectedTemplateIds} from '../../state/features/adminTemplates/adminTemplatesSelector';

const BoxStyled = styled(Box)(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gridGap: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

const TemplateFilterHeader = () => {
  const adminTemplateIds = useSelector(selectSelectedTemplateIds);

  return (
    <BoxStyled>
      <Box sx={{flex: 1}}>
        {adminTemplateIds?.length === 0 ? <TemplateSearch /> : <TemplateBulkActions />}
      </Box>
      {/*<TemplateDateRange />*/}
      {/*<TemplateOrderBy />*/}
    </BoxStyled>
  );
};

export default TemplateFilterHeader;
