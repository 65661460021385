import httpRequest from '../api/httpRequest';

class CustomFieldService {
  getCustomFieldData(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'usercustomfields/list', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CustomFieldService();
