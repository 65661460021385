import React from 'react';
import {Box} from '@mui/material';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import {useSelector} from 'react-redux';

const DisclaimerOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(DisclaimerOptions);
