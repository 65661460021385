import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Folder, Search} from '@mui/icons-material';
import {InputAdornment,Box, Stack, Typography} from '@mui/material';
import CoreTextField from '../../common/TextField/CoreTextField';
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import {debounceHandler} from '../../helpers/utils/debounceHandler';
import {selectTemplateFiltersSearch} from '../../state/features/adminTemplates/adminTemplatesSelector';
import {addExportedFileSearchText} from '../../state/features/exportedFiles/exportedFileSlice';
import PresentationBreadcrumb from '../../common/Breadcrumb/PresentationBreadcrumb';

const FilesHeader = () => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectTemplateFiltersSearch);
  const [search, setSearch] = useState(searchText);

  const doSearch = value => {
    setSearch(value);
    dispatch(addExportedFileSearchText(value));
  };

  const breadCrumbArray = [
    {
      name: 'My Forms',
      icon: <Folder color={'action'} sx={{mr: 0.5}} fontSize="small" />,
      path: '/fbuilder/forms'
    },
    {
      name: 'Export Files'
    }
  ];

  // debounce implement for search input change
  const handleSearch = debounceHandler(doSearch, 500);

  return (
    <Stack px={3} py={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Box>
        <CustomTooltip
          title={' All of your forms or submissions exported files will be find here.'}
          placement="top"
        >
          <Typography variant={'h5'} sx={{width:'max-content'}}>Exported Files</Typography>
        </CustomTooltip>
        <PresentationBreadcrumb breadCrumbArray={breadCrumbArray} />
      </Box>
      <CoreTextField
        size={'small'}
        type={'search'}
        color={'secondary'}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{mr: 0}} position="start">
              <Search />
            </InputAdornment>
          )
        }}
        placeholder={'Search template'}
        defaultValue={search}
        onChange={e => handleSearch(e.target.value)}
      />
    </Stack>
  );
};

export default FilesHeader;
