import React from 'react';
import {Box, Drawer} from '@mui/material';

const FullPageDrawer = ({
  anchor = 'top',
  open,
  onClose,
  height = '96vh',
  zIndex = '1200',
  children
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      sx={{
        zIndex: `${zIndex} !important`,
        '& .MuiPaper-root': {
          width: '98%',
          height: height,
          margin: '8px auto',
          borderRadius: '4px'
        }
      }}
    >
      <Box>{children}</Box>
    </Drawer>
  );
};

export default FullPageDrawer;
