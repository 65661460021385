import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {Box, Stack} from "@mui/material";
import {MediaImageWrapper} from "./AddMediaStyles";
import CoreButton from "../../../common/Button/CoreButton";
import {CustomUploadImage} from '../../../common/FileUpload/CustomUploadImage';
import {saveMyIcon} from "../../../state/features/saveIcon/saveIconSlice";

const AddMediaUploadTab = ({onAddImageIcon}) => {
    const dispatch = useDispatch();
    const {formId} = useParams()
    const [image, setImage] = useState("");

    const onImageUpload = (response) => {
        setImage(response.image);
    }

    const handleImageAdd = (iconImage) => {
        onAddImageIcon(image);
        dispatch(saveMyIcon({formId: formId, imageUrl: iconImage}));
    }

  return (
      <Box sx={{px: 3, mt: 2}}>
          <CustomUploadImage recommendedSize={"(1:1) 300 x 300"} onImageUpload={onImageUpload} />

          {image ? (
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mt={2}>
                  <MediaImageWrapper>
                        <img src={image} alt={"icon"} />
                  </MediaImageWrapper>

                  <div>
                      <CoreButton color={'error'} sx={{mr: 2}} onClick={() => setImage("")}>
                          reset
                      </CoreButton>
                      <CoreButton
                          color={'success'}
                          variant={"contained"}
                          onClick={() => handleImageAdd(image)}
                      >
                          Add
                      </CoreButton>
                  </div>
              </Stack>
          ) : ""}

      </Box>
  );
};

export default AddMediaUploadTab;
