import React from 'react';
import {Stack, Box} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import {CustomDropdown} from '../../../common/CustomDropdown';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import RequireIcon from '../../../common/Icons/RequireIcon';
import Styles from './Name.module.scss';

const nameTitle = [
  {title: 'Mr', value: 'Mr'},
  {title: 'Mrs', value: 'Mrs'}
];

const NameComponent = ({field, onChange, editMode}) => {
  return (
    <Box sx={{position: 'relative'}}>
      <Box>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>
        <Box className={Styles.nameWrapper}>
          {field.prefixEnabled && (
            <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
              <ConnectPublicForm
                name={field.id + 1}
                value={field.prefixValue}
                rules={field.required ? {required: 'Prefix is required'} : {}}
                render={formOption => (
                  <>
                    <CustomDropdown
                      wrapperClassName={'nameDropdown'}
                      data={nameTitle}
                      size="medium"
                      value={field.prefixValue}
                      displayEmpty={field.displayEmpty}
                      onChange={e => {
                        formOption.field.onChange(e);
                        onChange({prefixValue: e.target.value});
                      }}
                      disabled={editMode || field.readOnly}
                    />
                    {formOption.fieldState.error?.message ? (
                      <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                    ) : (
                      <FormSubtext>{field.prefixHelperText}</FormSubtext>
                    )}
                  </>
                )}
              />
            </Stack>
          )}

          <Stack direction="column" sx={{flex: '1 1 !important', width: '100%'}}>
            <FormInput
              name={field.id + 2}
              value={field.firstNameValue}
              placeholder={field.firstNamePlaceholder}
              readOnly={editMode || field.readOnly}
              onChange={e => onChange({firstNameValue: e.target.value})}
              helperTextProps={{
                text: field.firstNameHelperText
              }}
              rules={field.required ? {required: 'First name is required'} : {}}
            />
          </Stack>

          {field.middleNameEnabled && (
            <Stack direction="column" sx={{flex: '1 1 !important', width: '100%'}}>
              <FormInput
                name={field.id + 3}
                value={field.middleNameValue}
                placeholder={field.middleNamePlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => onChange({middleNameValue: e.target.value})}
                helperTextProps={{
                  text: field.middleNameHelperText
                }}
                rules={field.required ? {required: 'Middle name is required'} : {}}
              />
            </Stack>
          )}

          <Stack direction="column" sx={{flex: '1 1 !important', width: '100%'}}>
            <FormInput
              name={field.id + 4}
              value={field.lastNameValue}
              placeholder={field.lastNamePlaceholder}
              readOnly={editMode || field.readOnly}
              onChange={e => onChange({lastNameValue: e.target.value})}
              helperTextProps={{
                text: field.lastNameHelperText
              }}
              rules={field.required ? {required: 'Last name is required'} : {}}
            />
          </Stack>
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(NameComponent);
