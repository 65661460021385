import React from 'react';
import {Box, styled} from '@mui/material';
import FormTemplateBodyHeader from './FormTemplateBodyHeader';
import FormTemplateBodyContent from './FormTemplateBodyContent';
import SearchFormTemplate from './SearchFormTemplate';
import FormOptionHeading from '../FormOptionHeading';

const BoxStyled = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  '& .templateBodyContainer': {
    width: '100%',
    height: 'calc(100vh - 215px)',
    paddingBottom: '40px',
    flexWrap: 'wrap',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

const FormTemplateBody = () => {
  return (
    <BoxStyled>
      <Box className="templateBodyContainer">
        <FormOptionHeading
          title="Choose a Template"
          subtitle="Select a template from the largest selection of free form templates"
        >
          <Box sx={{my: 1}}>
            <SearchFormTemplate />
          </Box>
        </FormOptionHeading>

        <FormTemplateBodyHeader />
        <FormTemplateBodyContent />
      </Box>
    </BoxStyled>
  );
};

export default FormTemplateBody;
