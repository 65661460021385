import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Stack} from '@mui/material';
import FormOptionHeading from './FormOptionHeading';
import FormLayoutCard from '../../common/Cards/FormLayoutCard';
import CustomLoader from '../../common/CustomLoader/CustomLoader';
import {FORM_TEMPLATE_TYPE} from '../../helpers/constant/formConstant';
import {addForm, resetAddFormSuccess} from '../../state/features/folderForms/folderFormsSlice';
import {
  selectFolderForm,
  selectSelectedFolderId
} from '../../state/features/folderForms/folderFormSelector';
import {DEFAULT_THEME} from '../../helpers/builderConstant/DefaultTheme';
import {DEFAULT_ELEMENTS} from '../../helpers/builderConstant/DefaultElements';

const FormLayoutOption = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isFormAdding, isFormAddSuccess, newFormFolderId, newFormId} =
    useSelector(selectFolderForm);
  const selectedFolderId = useSelector(selectSelectedFolderId);

  useEffect(() => {
    if (isFormAddSuccess) {
      dispatch(resetAddFormSuccess());
      history.push(`/fbuilder/form/${newFormId}`);
    }
  }, [isFormAddSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle create form base on inside or outside folder
  const handleCreateForm = (formType, defaultTheme) => {
    const requestObj = {
      formTitle: 'New Form',
      folderId: newFormFolderId || selectedFolderId,
      formType: formType,
      formTheme: JSON.stringify(defaultTheme),
      elements: DEFAULT_ELEMENTS.map(el => JSON.stringify(el))
    };
    dispatch(addForm(requestObj));
  };

  if (isFormAdding) return <CustomLoader />;

  return (
    <div>
      <FormOptionHeading
        title={'Select your form layout'}
        subtitle={'Choose a layout according to your needs'}
      />

      <Stack direction={'row'} justifyContent={'center'} spacing={3} p={2} mt={3}>
        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'classicform.png'}
          title={'Classic Form'}
          description={'Show all question on one page'}
          onClick={() => handleCreateForm(FORM_TEMPLATE_TYPE.CLASSIC, DEFAULT_THEME.classic)}
        />

        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'cardform.png'}
          title={'Card Form'}
          description={'Show single question per page'}
          onClick={() => handleCreateForm(FORM_TEMPLATE_TYPE.CARD, DEFAULT_THEME.card)}
        />
      </Stack>
    </div>
  );
};

export default FormLayoutOption;
