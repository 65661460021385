import {createSlice} from '@reduxjs/toolkit';
import {format as toFormat} from 'date-fns';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import Prototype from '../../../helpers/builderConstant/FieldPrototypes';

const initialState = {
  pageLoadError: false,
  isPageLoading: true,
  title: 'Loading...',
  favIcon: '',
  status: 0,
  theme: {},
  fields: null,
  apiKey: '',
  agencyId: '',
  thankyouPageData: {},
  seoSettings: {},
  isSubmitting: true,
  isSubmited: false
};

const publicPageSlice = createSlice({
  name: 'publicPage',
  initialState: initialState,
  reducers: {
    fetchPublicPageDetails: (state, action) => {
      state.isPageLoading = true;
      state.apiKey = action.payload.apiKey;
      state.agencyId = action.payload.agencyId;
    },
    fetchPublicPageDetailsSuccess: (state, action) => {
      state.isPageLoading = false;
      state.title = action.payload.formInfo.formTitle;
      state.favIcon = action.payload.formInfo.favIcon;
      state.theme = JSON.parse(action.payload.formInfo.theme);
      state.status = action.payload.formInfo.isArchived ? 0 : action.payload.formInfo.status;
      state.seoSettings = action.payload.formInfo.seoSetting ?? {};
      if (action.payload.formInfo.thankyouPageSetting) {
        state.thankyouPageData = JSON.parse(action.payload.formInfo.thankyouPageSetting);
      }

      const clonnedFields = [...action.payload.formElements];

      //Updating default values
      clonnedFields.forEach((eachPage, eachPageIndex) => {
        eachPage.pageItems.forEach((eachField, eachFieldIndex) => {
          clonnedFields[eachPageIndex].pageItems[eachFieldIndex] = {
            ...Prototype[eachField.component],
            ...eachField
          };
          const field = clonnedFields[eachPageIndex].pageItems[eachFieldIndex];
          if (
            (field.component === COMPONENT_TYPE.BIRTHDAY ||
              field.component === COMPONENT_TYPE.DATE) &&
            field.defaultDate === 'current'
          ) {
            field.defaultDate = 'custom';
            field.value = toFormat(new Date(), 'yyyy-MM-dd');
          } else if (field.component === COMPONENT_TYPE.TIME && field.defaultTime === 'current') {
            field.defaultTime = 'custom';
            field.value = toFormat(new Date(), 'hh:mm aa');
          }
        });
      });

      state.fields = clonnedFields;
    },
    fetchPublicPageDetailsFailed: (state, action) => {
      state.pageLoadError = true;
      state.isPageLoading = false;
    },
    resetPublicPageDetails: (state, action) => {
      state.fields = null;
    },
    submitPublicPage: (state, action) => {
      state.isSubmitting = true;
    },
    submitPublicPageFailed: (state, action) => {
      state.isSubmitting = false;
      state.isSubmited = true;
    },
    submitPublicPageSuccess: (state, action) => {
      state.isSubmitting = false;
      state.isSubmited = true;
    },
    fetchTemplatePreviewDetails: (state, action) => {
      state.isPageLoading = true;
    },
    submitFormAnalytics: (state, action) => {}
  }
});

export const {
  fetchPublicPageDetails,
  fetchPublicPageDetailsSuccess,
  fetchPublicPageDetailsFailed,
  resetPublicPageDetails,
  submitPublicPage,
  submitPublicPageFailed,
  submitPublicPageSuccess,
  fetchTemplatePreviewDetails,
  submitFormAnalytics
} = publicPageSlice.actions;

export default publicPageSlice.reducer;
