import React from 'react';
import {SvgIcon} from '@mui/material';

export default function DeskTopIcon({fill = 'ffffff', ...rest}) {
  return (
    <SvgIcon {...rest} viewBox="0 0 20 20">
      <path
        d="M17.3246 2.5H3.50885C3.0203 2.5 2.55175 2.69408 2.20629 3.03954C1.86083 3.385 1.66675 3.85355 1.66675 4.34211V12.6316C1.66675 13.1201 1.86083 13.5887 2.20629 13.9341C2.55175 14.2796 3.0203 14.4737 3.50885 14.4737H9.95622V17.2368H6.27201V18.1579H14.5615V17.2368H10.8773V14.4737H17.3246C17.8132 14.4737 18.2817 14.2796 18.6272 13.9341C18.9727 13.5887 19.1667 13.1201 19.1667 12.6316V4.34211C19.1667 3.85355 18.9727 3.385 18.6272 3.03954C18.2817 2.69408 17.8132 2.5 17.3246 2.5V2.5ZM18.2457 12.6316C18.2457 12.8759 18.1487 13.1101 17.9759 13.2829C17.8032 13.4556 17.5689 13.5526 17.3246 13.5526H3.50885C3.26457 13.5526 3.0303 13.4556 2.85757 13.2829C2.68484 13.1101 2.5878 12.8759 2.5878 12.6316V4.34211C2.5878 4.09783 2.68484 3.86355 2.85757 3.69082C3.0303 3.51809 3.26457 3.42105 3.50885 3.42105H17.3246C17.5689 3.42105 17.8032 3.51809 17.9759 3.69082C18.1487 3.86355 18.2457 4.09783 18.2457 4.34211V12.6316Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
