import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {
    formId: "",
    enable: false,
    companyName: "",
    companyEmail: "",
    companyPhone: "",
    companyLogo: "",
  },

  isLoading: false,
  isError: false,
  error: '',

  isUpdating: false,
  isUpdateSuccess: false
};

const formHeaderSlice = createSlice({
  name: 'formHeader',
  initialState: initialState,
  reducers: {
    getFormHeaderData: (state) => {
      state.isLoading = true;
    },
    getFormHeaderDataSuccess: (state, action) => {
      state.data.formId = action.payload.formId;
      state.data.enable = action.payload.enable;
      state.data.companyName = action.payload.companyName;
      state.data.companyEmail = action.payload.companyEmail;
      state.data.companyPhone = action.payload.companyPhone;
      state.data.companyLogo = action.payload.companyLogo;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormHeaderDataFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    enableFormHeader: (state, action) => {
      state.data.enable = Boolean(action.payload.enable);
      state.isUpdating = true;
    },

    updateFormHeaderData: (state, action) => {
      state.isUpdating = true;
    },
    updateFormHeaderDataSuccess: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updateFormHeaderDataFailed: (state, action) => {
      state.isUpdating = false;
      state.error = action.payload;
    },

  }
});

export const {
  getFormHeaderData,
  getFormHeaderDataSuccess,
  getFormHeaderDataFailed,

  enableFormHeader,
  updateFormHeaderData,
  updateFormHeaderDataSuccess,
  updateFormHeaderDataFailed
} = formHeaderSlice.actions;

export default formHeaderSlice.reducer;