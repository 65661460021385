import {FORM_STATUS} from '../../../helpers/constant/formConstant';

export const selectFormFilters = state => state.folderForms.filters;
export const selectFormFiltersSearch = state => state.folderForms.filters.searchText;
export const selectIsTrashSelect = state => state.folderForms.filters.trash;
export const selectIsArchivedSelect = state => state.folderForms.filters.archived;
export const selectSelectedFolderId = state => state.folderForms.filters.folderId || '';

export const selectFolderForm = state => state.folderForms;
export const selectSelectedFormIds = state => state.folderForms.selectedFormIds;

export const selectedFormsFolderIds = state => {
  const formIds = state.folderForms.selectedFormIds;
  const formList = state.folderForms.forms.filter(formData => formIds.indexOf(formData.id) !== -1);

  const formFolderIds = formList
    .filter(formData => formData.folder?.length > 0)
    .reduce((folders, formData) => {
      return [...folders, ...formData.folder];
    }, [])
    .map(folder => folder.id);

  return [...new Set(formFolderIds)];
};

export const hasEnabledDisabledSelectedOnForms = state => {
  const selectedFormIds = state.folderForms.selectedFormIds;
  const result = {
    enabled: false,
    disabled: false
  };

  state.folderForms.forms.forEach(formData => {
    if (selectedFormIds.includes(formData.id)) {
      if (FORM_STATUS.PUBLISHED === formData.status) result.enabled = true;
      if (FORM_STATUS.PAUSED === formData.status) result.disabled = true;
    }
    return formData;
  });

  return result;
};
