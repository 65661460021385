import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack, Typography} from '@mui/material';
import FormTemplateTypeSelect from './FormTemplateTypeSelect';
import {FORM_TEMPLATE_STATUS} from '../../../helpers/constant/formConstant';
import {selectSelectedSubCategory} from '../../../state/features/templateCategories/templateCategoriesSelector';
import {
  selectTemplatesCount,
  selectTemplatesFiltersStatus
} from '../../../state/features/templates/templatesSelector';

const Header = ({title, count = 0}) => {
  return (
    <div>
      <Typography variant={'h5'} sx={{fontSize: '18px'}}>
        {title}
      </Typography>
      <Typography variant={'chipLight'}>
        {`${count === 1 ? '1 Template' : count + ' Templates'} found`}
      </Typography>
    </div>
  );
};

const FormTemplateBodyHeader = () => {
  const subCategory = useSelector(selectSelectedSubCategory);
  const templateStatus = useSelector(selectTemplatesFiltersStatus);
  const total = useSelector(selectTemplatesCount);

  // decide what to render on title
  let content = null;
  if (templateStatus === FORM_TEMPLATE_STATUS.MOST_POPULAR) {
    content = <Header title={'Most Popular Templates'} count={total} />;
  } else if (templateStatus === FORM_TEMPLATE_STATUS.FAVORITE) {
    content = <Header title={'Most Favorite Templates'} count={total} />;
  } else {
    content = <Header title={subCategory.name} count={total} />;
  }

  return (
    <>
      <Box
        mb={1}
        sx={{
          display: 'flex',
          alignItems: {md: 'center', sm: 'start'},
          justifyContent: 'space-between',
          flexDirection: {md: 'row', sm: 'column'}
        }}
      >
        {content}
        <Stack direction="row" alignItems="center">
          <Typography variant="body2medium" color="action.active" sx={{marginRight: '10px'}}>
            Form Type:
          </Typography>
          <FormTemplateTypeSelect />
        </Stack>
      </Box>
      <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1, textAlign: 'justify'}}>
        {subCategory.description}
      </Typography>
    </>
  );
};

export default FormTemplateBodyHeader;
