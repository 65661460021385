import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import {Stack, styled, useTheme} from '@mui/material';
import {FavoriteBorderOutlined, ListAlt, Stars} from '@mui/icons-material';
import FormTemplateTab from './FormTemplateTab.jsx';
import SidebarStatusButton from './SidebarStatusButton';
import {setTemplateStatus} from '../../../state/features/templates/templatesSlice';
import {FORM_TEMPLATE_STATUS} from '../../../helpers/constant/formConstant';
import {
  getTemplateCategories,
  resetSelectedCategory,
  resetSelectedSubCategory,
  setSelectedSubCategory
} from '../../../state/features/templateCategories/templateCategoriesSlice';
import {selectTemplatesFiltersStatus} from '../../../state/features/templates/templatesSelector';
import {selectSelectedSubCategory} from '../../../state/features/templateCategories/templateCategoriesSelector';

const StyledBox = styled(Box)(({theme}) => ({
  width: '25%',
  overflowY: 'auto',
  minWidth: '350px',
  paddingBottom: '30px',
  height: 'calc(100vh - 215px)',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

const StackStyled = styled(Stack)(({theme}) => ({
  maxWidth: '350px',
  width: '100%'
}));

const FormTemplateSidebar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const status = useSelector(selectTemplatesFiltersStatus);
  const selectedSubCategory = useSelector(selectSelectedSubCategory);

  useEffect(() => {
    dispatch(getTemplateCategories());
    dispatch(setSelectedSubCategory({id: '', name: 'All Templates'}));

    return () => {
      dispatch(resetSelectedCategory());
      dispatch(resetSelectedSubCategory());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickFavCategory = status => {
    dispatch(setTemplateStatus(status));
    dispatch(setSelectedSubCategory({}));
  };

  const handleClickSubCategory = () => {
    dispatch(setSelectedSubCategory({id: '', name: 'All Templates'}));
    dispatch(setTemplateStatus(0));
  };

  return (
    <StyledBox>
      <StackStyled sx={{width: '100%', height: '100%'}} spacing={1}>
        <SidebarStatusButton
          startIcon={<ListAlt color={'secondary'} />}
          title={'All Templates'}
          isActive={selectedSubCategory?.id === ''}
          onClick={handleClickSubCategory}
        />
        <Box
          sx={{
            width: '100%',
            height: 'auto',
            border: `1px solid ${theme.palette?.other.outlinedBorder}`,
            borderRadius: '6px',
            padding: '8px'
          }}
        >
          <FormTemplateTab />
        </Box>

        <SidebarStatusButton
          startIcon={<Stars color={'secondary'} />}
          title={'Most Popular'}
          isActive={status === FORM_TEMPLATE_STATUS.MOST_POPULAR}
          onClick={() => onClickFavCategory(FORM_TEMPLATE_STATUS.MOST_POPULAR)}
        />

        <SidebarStatusButton
          startIcon={<FavoriteBorderOutlined color={'secondary'} />}
          title={'Favorites'}
          isActive={status === FORM_TEMPLATE_STATUS.FAVORITE}
          onClick={() => onClickFavCategory(FORM_TEMPLATE_STATUS.FAVORITE)}
        />
      </StackStyled>
    </StyledBox>
  );
};

export default FormTemplateSidebar;
