import {all, put} from 'redux-saga/effects';
import {call, debounce, takeEvery} from '@redux-saga/core/effects';
import {
  addFormFailed,
  addFormSuccess,
  exportFormFailed,
  exportFormSuccess,
  formAddToFolderFailed,
  formAddToFolderSuccess,
  formCloneSuccess,
  formMoveToArchiveSuccess,
  formMoveToTrashFailed,
  formMoveToTrashSuccess,
  formPauseOrPublishSuccess,
  formRemoveForeverSuccess,
  formRemoveFromFolderSuccess,
  getFolderFormsFailed,
  getFolderFormsSuccess,
  restoreFormSuccess,
  toggleFavouriteFormFailed,
  toggleFavouriteFormSuccess,
  updateFolderFormFailed,
  updateFolderFormSuccess
} from './folderFormsSlice';
import FormService from '../../../services/Form.service';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';

function* folderFormsWatcher() {
  yield takeEvery('folderForms/getFolderForms', getFolderFormsSaga);
  yield debounce(500, 'folderForms/getFolderFormsSilent', getFolderFormsSaga);
  yield takeEvery('folderForms/addForm', addFormSaga);
  yield takeEvery('folderForms/updateFolderForm', updateFolderFormSaga);
  yield takeEvery('folderForms/exportForm', exportFormSaga);
  yield takeEvery('folderForms/formMoveToTrash', formMoveToTrashSaga);
  yield takeEvery('folderForms/restoreForm', restoreFormSaga);
  yield takeEvery('folderForms/formRemoveForever', formRemoveForeverSaga);
  yield takeEvery('folderForms/formRemoveFromFolder', formRemoveFromFolderSaga);
  yield takeEvery('folderForms/formAddToFolder', formAddToFolderSaga);
  yield takeEvery('folderForms/formClone', formCloneSaga);
  yield takeEvery('folderForms/formMoveToArchive', formMoveToArchiveSaga);
  yield takeEvery('folderForms/formPauseOrPublish', formPauseOrPublishSaga);
  yield takeEvery('folderForms/toggleFavouriteForm', toggleFavouriteFormSaga);
}

function* getFolderFormsSaga(action) {
  try {
    const response = yield call(FormService.getForms, action.payload);

    if (response.success) {
      yield put(getFolderFormsSuccess(response.data));
    } else {
      yield put(getFolderFormsFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addFormSaga(action) {
  try {
    const response = yield call(FormService.createForm, action.payload);
    if (response.success) {
      yield put(addFormSuccess(response.data));
    } else {
      yield put(addFormFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFolderFormSaga(action) {
  try {
    const response = yield call(FormService.updateForm, action.payload);

    if (response.success) {
      yield put(updateFolderFormSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(updateFolderFormFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* exportFormSaga(action) {
  try {
    const response = yield call(FormService.exportForm, action.payload);

    if (response.success) {
      yield put(exportFormSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(exportFormFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formMoveToTrashSaga(action) {
  try {
    const response = yield call(FormService.formMoveToTrash, action.payload);

    if (response.success) {
      yield put(formMoveToTrashSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(formMoveToTrashFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* restoreFormSaga(action) {
  try {
    const response = yield call(FormService.restoreForm, action.payload);

    if (response.success) {
      yield put(restoreFormSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formRemoveForeverSaga(action) {
  try {
    const response = yield call(FormService.formRemoveForever, action.payload);

    if (response.success) {
      yield put(formRemoveForeverSuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formRemoveFromFolderSaga(action) {
  try {
    const response = yield call(FormService.formRemoveFromFolder, action.payload);

    if (response.success) {
      yield put(formRemoveFromFolderSuccess(action.payload));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formAddToFolderSaga(action) {
  try {
    const response = yield call(FormService.formAddToFolder, action.payload);

    if (response.success) {
      yield put(formAddToFolderSuccess(action.payload));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(formAddToFolderFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formCloneSaga(action) {
  try {
    const response = yield call(FormService.formClone, action.payload);

    if (response.success) {
      yield put(formCloneSuccess(action.payload));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formMoveToArchiveSaga(action) {
  try {
    const response = yield call(FormService.formMoveToArchive, action.payload);

    if (response.success) {
      yield put(formMoveToArchiveSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* formPauseOrPublishSaga(action) {
  try {
    const response = yield call(FormService.formPauseOrPublish, action.payload);

    if (response.success) {
      yield put(formPauseOrPublishSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* toggleFavouriteFormSaga(action) {
  try {
    const response = yield call(FormService.formMarkAsFavourite, action.payload);

    if (response.success) {
      yield put(toggleFavouriteFormSuccess(response.data));
    } else {
      yield put(toggleFavouriteFormFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* folderFormsSaga() {
  yield all([folderFormsWatcher()]);
}
