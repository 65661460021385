import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import PhoneInputAdvanced from './PhoneInputAdvanced';
import PhoneInputGeneral from './PhoneInputGeneral';
import PhoneInputOptions from './PhoneInputOptions';

const PhoneInputRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <PhoneInputGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <PhoneInputOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <PhoneInputAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default PhoneInputRightPanel;
