import colorString from 'color-string';

class ColorCustomize {
  changeOpacity = (color, opacity = 1) => {
    const colorArray = colorString.get.rgb(color);
    colorArray[colorArray.length - 1] = opacity;

    return colorString.to.rgb(colorArray);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ColorCustomize();
