import React, {useState} from 'react';
import {Box, styled} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {StaticDatePicker} from '@mui/x-date-pickers';
import {format as dateFormat} from 'date-fns';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Styles from './custom-date-picker.module.scss';

const StaticDatePickerStyled = styled(StaticDatePicker)(({theme}) => ({
  '& .MuiPickerStaticWrapper-content': {
    minWidth: '100%'
  }
}));
const CustomDatePickerDefault = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  height: '48px',
  minHeight: '48px',
  paddingLeft: '12px',
  gap: '8px',
  borderRadius: '4px',
  '& .MuiFormControl-root': {width: '100%'},
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  '&:focus': {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  '& button': {'&:focus': {backgroundColor: 'unset'}},
  '& input': {
    padding: '0',
    border: 'none !important'
  },
  '& .MuiInputBase-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  },
  '&.BuilderDatePicker': {
    '& input': {
      color: 'var(--fbp-theming-text-color) !important',
      fontFamily: 'var(--fbp-theming-font-family) !important',
      '&::placeholder': {
        color: 'var(--fbp-theming-placeholder-color)'
      }
    },
    '& svg path': {
      fill: 'var(--fbp-theming-text-color) !important'
    }
  }
}));

const CustomDatePicker = ({
  inputIcon,
  size = 'medium',
  className = '',
  wrapperClass,
  format = 'MM/dd/yyyy',
  value = null,
  onChange = null,
  type = 'default',
  orientation = 'landscape',
  readOnly = false
}) => {
  const [open, setOpen] = useState(false);

  const handleDateChange = date => {
    try {
      if (!readOnly) {
        onChange(dateFormat(new Date(date), 'yyyy-MM-dd'));
      }
    } catch (ex) {}
  };

  const handleOpenPopup = () => {
    if (!readOnly) {
      setOpen(true);
    }
  };

  let renderClasses = null;
  renderClasses =
    size === 'small'
      ? Styles.fbpGlobalDatePickerSmall
      : size === 'medium'
      ? Styles.fbp_globalDatePickerMedium
      : Styles.fbpGlobalDatePickerLarge;

  if (type === 'default') {
    return (
      <CustomDatePickerDefault
        sx={{flex: '1'}}
        className={`${Styles.fbpGlobalDatePicker} ${renderClasses} ${className}`}
        key={format}
      >
        {inputIcon}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            open={open}
            onOpen={() => handleOpenPopup()}
            onClose={() => setOpen(false)}
            sx={{paddingLeft: '45px'}}
            value={value}
            onChange={handleDateChange}
            inputFormat={format.toUpperCase()}
            renderInput={params => {
              params.inputProps.readOnly = true;
              params.placeholder = format.toLocaleLowerCase();
              return <TextField onClick={() => handleOpenPopup()} {...params} />;
            }}
            PopperProps={{sx: {zIndex: 9999}}}
          />
        </LocalizationProvider>
      </CustomDatePickerDefault>
    );
  }

  return (
    <Box className={`${Styles.fbpGlobalDatePicker} ${renderClasses} ${className}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePickerStyled
          orientation={orientation}
          openTo="day"
          value={value}
          onChange={handleDateChange}
          renderInput={params => <TextField {...params} />}
          toolbarTitle=""
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomDatePicker;
