import httpRequest from '../api/httpRequest';

class AdminTemplateCategoryService {
  getAdminTemplateCategories(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatecategory/list',
      params
    );
  }

  addAdminTemplateCategory(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatecategory/add',
      body
    );
  }

  updateAdminTemplateCategory(body) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatecategory/update',
      body
    );
  }

  deleteAdminTemplateCategory(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatecategory/remove',
      null,
      body
    );
  }

  addAdminTemplateSubCategory(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatesubcategory/add',
      body
    );
  }
  updateAdminTemplateSubCategory(body) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatesubcategory/update',
      body
    );
  }
  deleteAdminTemplateSubCategory(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatesubcategory/remove',
      null,
      body
    );
  }

  moveAdminSubCategoryToCategory(body) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'templatesubcategory/move',
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminTemplateCategoryService();
