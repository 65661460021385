import React, {useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import withFieldChange from '../../../hoc/withFieldChange';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import CustomTimePicker from '../../../common/TimePicker/CustomTimePicker';
import {parse as parseTime} from 'date-fns';
import RequireIcon from '../../../common/Icons/RequireIcon';

const TimeComponent = ({field, editMode, onChange}) => {
  const ampm = field.format === '12hr';

  const defaultTime = useMemo(() => {
    if (field.defaultTime === 'none') {
      return null;
    } else if (field.defaultTime === 'current') {
      return new Date();
    } else {
      return field.value === null ? null : parseTime(field.value, 'hh:mm aa', new Date());
    }
  }, [field]);

  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <Box sx={{position: 'relative'}}>
      <Box className={` ${labelAlignmentClass}`}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

        <Box sx={{flex: 1}}>
          <ConnectPublicForm
            name={field.id}
            value={defaultTime}
            rules={field.required ? {required: 'This value is required'} : {}}
            render={(formOption, formMethods) => (
              <>
                <CustomTimePicker
                  className="BuilderTimePicker"
                  ampm={ampm}
                  value={defaultTime}
                  readOnly={editMode || field.readOnly}
                  viewType={field.viewType}
                  orientation={field.shrink ? 'portrait' : 'landscape'}
                  onChange={value => {
                    formMethods?.setValue(field.id, value);
                    onChange({value: value, defaultTime: 'custom'});
                    if (formOption.formState.isSubmitted) {
                      formMethods?.trigger();
                    }
                  }}
                />
                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Box>
      </Box>

      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(TimeComponent);
