import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Typography
} from '@mui/material';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
import TableLoaderSkeleton from '../../LoadingView/TableLoaderSkeleton';
import ContactFieldDropDown from './ContactFieldDropDown';
import {ComponentConfig} from '../../../helpers/builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {CONTACT_FIELDS_MAP} from '../../../helpers/constant/CoreConstants';
import {MappingTableHead} from './MappingTableHead';
import {selectFieldMapping} from '../../../state/features/fieldMapping/fieldMappingSelector';
import {
  fetchFieldMapping,
  updateFieldMapping
} from '../../../state/features/fieldMapping/fieldMappingSlice';
import {selectCustomFields} from '../../../state/features/customField/customFieldSelector';
import {getCustomFieldData} from '../../../state/features/customField/customFieldSlice';

const StyledTable = styled(Table)(({theme}) => ({
  minWidth: 750,
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  borderBottom: 'none',
  '& .MuiTableRow-root': {
    '& .MuiTableCell-root': {
      fontSize: '12px',
      borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`
    }
  }
}));

const MappingContent = () => {
  const dispatch = useDispatch();
  const [mapObject, setMapObject] = useState({});
  const [errorMap, setErrorMap] = useState({});
  const {
    isLoading,
    setting: {mappings}
  } = useSelector(selectFieldMapping);

  const {isLoading: isCustomFieldLoading, isSuccess} = useSelector(selectCustomFields);

  const allContactFields = useSelector(state => ({
    ...CONTACT_FIELDS_MAP,
    ...state.customFields.customFieldObject
  }));

  const fields = useSelector(state => {
    const allFields = [];

    state.builderFields.fields.forEach(eachPage => {
      eachPage.pageItems.forEach(eachField => {
        let clonnedField = JSON.parse(JSON.stringify(eachField));
        if (
          !clonnedField.hidden &&
          ComponentConfig[clonnedField.component].isInput !== false &&
          clonnedField.component !== COMPONENT_TYPE.FILE_UPLOAD
        ) {
          allFields.push(clonnedField);
        }
      });
    });

    return allFields;
  });

  useEffect(() => {
    if (!isCustomFieldLoading && !isSuccess) {
      dispatch(getCustomFieldData());
    }
    dispatch(fetchFieldMapping());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newMapObj = {};
    mappings.forEach(eachMap => {
      newMapObj[eachMap.fieldId] = eachMap.mapping;
    });
    setMapObject(newMapObj);
  }, [mappings]);

  if (isLoading) {
    return (
      <Box sx={{width: '100%'}}>
        <TableLoaderSkeleton numberOfRows={4} columns={2} />
      </Box>
    );
  }

  const handleMapObject = (index, value) => {
    const newMapObject = {...mapObject, [fields[index].id]: value};
    setMapObject(newMapObject);

    const hasObject = {},
      errorObject = {};

    let hasError = false;

    //Validating duplicate
    for (let key in newMapObject) {
      if (newMapObject[key]) {
        if (hasObject[newMapObject[key]]) {
          errorObject[key] = errorObject[hasObject[newMapObject[key]]] = 'Duplicate selected';
          hasError = true;
        }
        hasObject[newMapObject[key]] = key;
      }
    }

    //Validating invalid fields
    fields.forEach(eachField => {
      if (
        newMapObject[eachField.id] &&
        ComponentConfig[eachField.component].inputType !==
          allContactFields[newMapObject[eachField.id]].type
      ) {
        hasError = true;
        errorObject[eachField.id] =
          'Type mismatch. Must be type of ' + ComponentConfig[eachField.component].inputType;
      }
    });

    if (!hasError) {
      let payloadArr = [];
      fields.forEach(eachField => {
        if (newMapObject[eachField.id]) {
          payloadArr.push({
            type: eachField.component,
            fieldId: eachField.id,
            mapping: newMapObject[eachField.id]
          });
        }
      });
      dispatch(updateFieldMapping(payloadArr));
    }

    setErrorMap(errorObject);
  };

  return (
    <Box sx={{p: 3, height: '100%'}}>
      <Paper sx={{width: '100%', mb: 2}} elevation={0}>
        <TableContainer>
          <StyledTable aria-labelledby="tableTitle" size={'medium'}>
            <MappingTableHead />
            <TableBody>
              {fields.map((eachData, index) => (
                <TableRow key={index}>
                  <TableCell align="left" sx={{textTransform: 'capitalize'}}>
                    {eachData.label || '---'}
                  </TableCell>
                  <TableCell align="left" sx={{textTransform: 'capitalize'}}>
                    {ComponentConfig[eachData.component].name}
                  </TableCell>
                  <TableCell align="left" sx={{textTransform: 'capitalize'}}>
                    {ComponentConfig[eachData.component].inputType}
                  </TableCell>
                  <TableCell align="right">
                    <ContactFieldDropDown
                      allFields={allContactFields}
                      onChange={e => handleMapObject(index, e.target.value)}
                      selected={mapObject[eachData.id] || ''}
                    />
                    <Typography variant="helperText" color="error.main">
                      {errorMap[eachData.id] || ''}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>

          {fields.length === 0 && (
            <div>
              <GlobalEmptyPage
                icon={<EmptySubmissionFormIcon />}
                title={'No input fields found'}
                description={'There were no input fields added that support mapping.'}
              />
            </div>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default MappingContent;
