import React from 'react';
import {Typography} from '@mui/material';


const RequireIcon = ({variant}) => {
  const starFontSize =
    variant === 'card'
      ? 'calc(var(--fbp-theming-card-view-label-font-size) - 6px)'
      : 'calc(var(--fbp-theming-label-font-size) - 3px)';

  return (
    <Typography component="span" color="error.main" sx={{fontSize: starFontSize, verticalAlign: 'top'}}>
      *
    </Typography>
  );
};

export default RequireIcon;
