import React, {useState} from 'react';
import {Typography, Box, MenuItem} from '@mui/material';
import {ArrowDropDown} from '@mui/icons-material';
import AddOrEditTemplateCategory from './AddOrEditTemplateCategory';
import AddOrEditTemplateSubCategory from './AddOrEditTemplateSubCategory';
import MenuWrapper from '../../../common/Menu/MenuWrapper';
import CoreButton from '../../../common/Button/CoreButton';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';

const AddNewTemplateCategory = () => {
  const [isOpenCategoryModal, setOpenCategoryModal] = useState(false);
  const [isOpenSubCategoryModal, setOpenSubCategoryModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleOpenCategoryModal = () => setOpenCategoryModal(prevState => !prevState);
  const toggleOpenSubCategoryModal = () => setOpenSubCategoryModal(prevState => !prevState);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  return (
    <Box sx={{pt: 1}}>
      <CoreButton
        id="demo-customized-button"
        aria-haspopup="true"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        fullWidth
        variant={'contained'}
        size="medium"
        disableElevation
        color="secondary"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        Add new
      </CoreButton>

      <MenuWrapper
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem sx={{justifyContent: 'center'}} onClick={toggleOpenCategoryModal}>
          <Typography variant={'body2'}>Category</Typography>
        </MenuItem>
        <MenuItem sx={{justifyContent: 'center'}} onClick={toggleOpenSubCategoryModal}>
          <Typography variant={'body2'}>Sub-category</Typography>
        </MenuItem>
      </MenuWrapper>

      <BasicModalWithHeader
        modalTitle={'Add New Category'}
        open={isOpenCategoryModal}
        onClose={toggleOpenCategoryModal}
      >
        <AddOrEditTemplateCategory onClose={toggleOpenCategoryModal} isEditMode={false} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        modalTitle={'Add New sub-category'}
        open={isOpenSubCategoryModal}
        onClose={toggleOpenSubCategoryModal}
      >
        <AddOrEditTemplateSubCategory onClose={toggleOpenSubCategoryModal} isEditMode={false} />
      </BasicModalWithHeader>
    </Box>
  );
};

export default AddNewTemplateCategory;
