import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import FormSEOService from '../../../services/FormSEO.service';
import {showErrorSnackbar} from '../snackbar/snackbarSlice';
import {
  getFormSEOFailed,
  getFormSEOSuccess,
  updateFormSEOFailed,
  updateFormSEOSuccess
} from './formSEOSlice';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';

function* formSEOWatcher() {
  yield takeEvery('formSEO/getFormSEO', getFormSEOSaga);
  yield takeEvery('formSEO/updateFormSEO', updateFormSEOSaga);
}

function* getFormSEOSaga(action) {
  try {
    const response = yield call(FormSEOService.getFormSEO, action.payload);

    if (response.success) {
      yield put(getFormSEOSuccess(response.data));
    } else {
      yield put(getFormSEOFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormSEOSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const response = yield call(FormSEOService.updateFormSEO, action.payload);

    if (response.success) {
      yield put(updateFormSEOSuccess(response.data));
      // yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(updateFormSEOFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formSEOSaga() {
  yield all([formSEOWatcher()]);
}
