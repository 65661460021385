import React, {useState} from 'react';
import {Add} from '@mui/icons-material';
import {Box, Stack, Typography} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import CoreButton from '../../../common/Button/CoreButton';
import CreateTemplateModal from './CreateTemplateModal';
import RouteLink from '../../../common/Link/RouteLink';

const TemplatePageHeader = () => {
  const [isOpenNewFormModal, setIsOpenNewFormModal] = useState(false);

  const handleOpenNewFormModal = () => setIsOpenNewFormModal(true);
  const handleCloseNewFormModal = () => setIsOpenNewFormModal(false);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} mb={2}>
      <Box>
        <Typography variant={'h5'}>My Templates</Typography>
        <Typography variant={'body2'}>Now you can see all of your templates here</Typography>
      </Box>
      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        <RouteLink to={`/fbuilder/report`}>
            <CoreButton
              size={'large'}
              color={'secondary'}
              variant="outlined"
              startIcon={<InsightsIcon />}
            >
              Report
            </CoreButton>
        </RouteLink>
        <CoreButton
          size={'large'}
          color={'secondary'}
          variant={'contained'}
          startIcon={<Add />}
          onClick={handleOpenNewFormModal}
          disableElevation
          mr="3"
        >
          New Template
        </CoreButton>
      </Stack>
      <CreateTemplateModal open={isOpenNewFormModal} onClose={handleCloseNewFormModal} />
    </Stack>
  );
};

export default TemplatePageHeader;
