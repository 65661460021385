import {styled} from '@mui/material';

export const CustomList = styled('li')(({theme}) => ({
  padding: '14px 15px',
  cursor: 'pointer',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '30px',
  '&.EmptyList': {
    padding: '12px 15px',
    height: '80px',
    '& .daysRemaining': {
      zIndex: 11,
      height: 'auto',
      position: 'absolute',
      width: 'max-content',
      top: 0,
      right: 0,
      backgroundColor: theme.palette.warning.light,
      padding: '5px 8px',
      borderRadius: '4px'
    }
  },
  '&:hover': {
    backgroundColor: theme.palette.action.actionHover,
    '& .iconLists': {
      '& li': {
        visibility: 'visible',
        pointerEvents: 'all'
      }
    },
    '& .trashPageDeleteList': {
      '& li': {
        visibility: 'visible ',
        pointerEvents: 'all'
      }
    },
    '& .daysRemaining': {
      visibility: 'hidden !important',
      pointerEvents: 'none'
    }
  },
  '&.active': {
    backgroundColor: theme.palette.action.actionHover,
    '& .trashPageDeleteList': {
      '& li': {
        visibility: 'visible ',
        pointerEvents: 'all'
      }
    }
  },
  '& .favritStar': {
    '& svg': {
      '& path': {
        fill: theme.palette.action.active
      }
    },
    '&.active': {
      '& svg': {
        '& path': {
          fill: theme.palette.warning.main
        }
      }
    }
  },
  '@media(min-width:1201px) and (max-width:1295px)': {
    padding: '14px 9px'
  }
}));

export const CustomIconList = styled('ul')(({theme}) => ({
  width: 'max-content',
  display: 'flex',
  gap: '16px',
  '& li': {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    borderRadius: '10px',
    width: '20px',
    height: '20px',
    fontSize: '10px',
    transition: '0.3s all',
    pointerEvents: 'none',
    visibility: 'hidden',
    '& span': {
      display: 'inline-flex'
    },
    '& svg path': {
      fill: '#949db2'
    },
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main
      }
    }
  },
  '&.trashPageDeleteList': {
    visibility: 'hidden',
    pointerEvents: 'none',
    marginRight: '20px',
    '& li': {
      pointerEvents: 'all',
      visibility: 'hidden'
    }
  },
  '&.active': {
    '& li': {
      visibility: 'visible',
      pointerEvents: 'all'
    }
  },
  '&.hover': {
    '& li': {
      visibility: 'visible',
      pointerEvents: 'all'
    }
  },
  '@media(max-width:1200px)': {
    '&.formsList': {
      '& li:not(:last-child)': {
        display: 'none'
      }
    }
  }
}));
