import React from 'react';
import {Box} from '@mui/material';
import withThemeChange from '../../../hoc/withThemeChange';
import ButtonStyles from '../../Builder/RightComponents/ButtonStyles';
import FormLabelAlignment from '../../Builder/RightComponents/FormLabelAlignment';
import RightSideFontSelect from '../../Builder/RightComponents/RightSideFontSelect';
import RightSideNumberInput from '../../Builder/RightComponents/RightSideNumberInput';

const ThemeStyles = ({theme, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'classic' && (
        <>
          <RightSideNumberInput
            text="Form Container Width"
            title="Change the width of form container"
            unit="PX"
            value={theme.containerWidth}
            onChange={e => onChangeValue({containerWidth: e.target.value})}
          />

          <FormLabelAlignment
            onChange={value => onChangeValue({labelAlignment: value})}
            labelAlignment={theme.labelAlignment}
          />

          <RightSideNumberInput
            text="Field Spacing"
            title="Change the gap in every fields"
            unit="PX"
            value={theme.fieldSpacing}
            onChange={e => onChangeValue({fieldSpacing: e.target.value})}
          />

          <RightSideNumberInput
            text="Inner Gap"
            title="Inner gap masures the horizontal gap"
            unit="PX"
            value={theme.innerGap}
            onChange={e => onChangeValue({innerGap: e.target.value})}
          />
        </>
      )}

      <RightSideFontSelect
        text="Font"
        title="Change the font of form"
        value={theme.font}
        onChange={e => onChangeValue({font: e.target.value})}
      />

      {theme.layout === 'classic' ? (
        <RightSideNumberInput
          text="Label Font Size"
          title="Change the font size of label"
          unit="PX"
          value={theme.labelFontSize}
          onChange={e => onChangeValue({labelFontSize: e.target.value})}
        />
      ) : (
        <>
          <RightSideNumberInput
            text="Label Font Size"
            title="Change the font size of label"
            unit="PX"
            value={theme.cardViewLabelFontSize}
            onChange={e => onChangeValue({cardViewLabelFontSize: e.target.value})}
          />

          <RightSideNumberInput
            text="Description Font Size"
            title="Change the font size of description"
            unit="PX"
            value={theme.descriptionFontSize}
            onChange={e => onChangeValue({descriptionFontSize: e.target.value})}
          />
        </>
      )}

      <RightSideNumberInput
        text="Helper Text Font Size"
        title="Change the font size of helper text under input"
        unit="PX"
        value={theme.subTextFontSize}
        onChange={e => onChangeValue({subTextFontSize: e.target.value})}
      />

      <ButtonStyles
        text="Button Style"
        title="Change style of next and back button"
        theme={theme}
        onChange={obj => onChangeValue(obj)}
      />
    </Box>
  );
};

export default withThemeChange(ThemeStyles);
