import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Stack, Typography} from '@mui/material';
import ViewResponsesTabs from './ViewResponsesTabs';
import CustomTabs, {CustomTab} from '../../common/Tab/CustomTabs';
import {useEffect} from 'react';
import {getDatesByDuration} from '../../helpers/utils/getDatesByDuration';
import {format} from 'date-fns';
import {getFormStatistics} from '../../state/features/formStatistics/formStatisticSlice';
import DateRangeController from '../../common/DateRangePicker/DateRangePicker';

const TAB_VALUES = {
  0: 'thisWeek',
  1: 'thisMonth',
  2: 'allTime',
  3: 'custom'
};

const TimeTabs = () => {
  const {formId} = useParams();
  const dispatch = useDispatch();
  const [dates, setDates] = useState([new Date(), new Date()]);
  const [selectedDuration, setSelectedDuration] = useState('thisWeek');

  useEffect(() => {
    if (selectedDuration !== 'custom') {
      const newDates = getDatesByDuration(selectedDuration);
      setDates(newDates);
      dispatch(
        getFormStatistics({
          formId,
          startDate: format(newDates[0], 'yyyy-MM-dd'),
          endDate: format(newDates[1], 'yyyy-MM-dd')
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuration]);

  const handleTabChange = tabIndex => {
    setSelectedDuration(TAB_VALUES[tabIndex]);
  };

  const handleChangeDatePicker = ({_fromDate, _toDate}) => {
    dispatch(
      getFormStatistics({
        formId,
        startDate: _fromDate,
        endDate: _toDate
      })
    );
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent="space-between"
        sx={{width: '100%', position: 'relative'}}
      >
        <CustomTabs className="AnalyticsTabs" onChange={handleTabChange}>
          <CustomTab title="This Week">
            <ViewResponsesTabs />
          </CustomTab>
          <CustomTab title="This Month">
            <ViewResponsesTabs />
          </CustomTab>
          <CustomTab title="All-Time">
            <ViewResponsesTabs />
          </CustomTab>
          <CustomTab title="Custom">
            <ViewResponsesTabs />
          </CustomTab>
        </CustomTabs>

        <Stack
          direction={'row'}
          alignItems="center"
          sx={{position: 'absolute', right: 0, height: '40px', top: '10px'}}
        >
          <Typography
            variant={'body2'}
            sx={{
              display: 'flex',
              flexDirection: {xs: 'column', lg: 'row'},
              alignItems: {xs: 'end', lg: 'center'},
              gap: {xs: '0', lg: '10px'}
            }}
          >
            Showing analytics for
            {selectedDuration === 'custom' ? (
              <DateRangeController
                isNeedInstantCallback
                datePickerCallback={handleChangeDatePicker}
              />
            ) : (
              <span style={{fontWeight: '700'}}>
                {selectedDuration === 'allTime'
                  ? 'All Time'
                  : `${format(dates[0], 'MMMM dd, yyyy')} - ${format(dates[1], 'MMMM dd, yyyy')}`}
              </span>
            )}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default TimeTabs;
