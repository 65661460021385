import React, {useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewNumberComponent = ({field, onChange, editMode}) => {
  const rule = useMemo(() => {
    let rule = {};
    if (field.required) {
      rule['required'] = 'This value is required';
    }
    if (field.limitEntry) {
      if (field.minimumLimit !== '') {
        rule['min'] = {
          value: field.minimumLimit,
          message: 'This value must be more than or equal to ' + field.minimumLimit
        };
      }
      if (field.maximumLimit !== '') {
        rule['max'] = {
          value: field.maximumLimit,
          message: 'This value must be less than or equal to ' + field.maximumLimit
        };
      }
    }
    return rule;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>
        <Box sx={{width: '100%'}}>
          <FormInput
            type="number"
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            onChange={e => onChange({value: e.target.value})}
            readOnly={editMode || field.readOnly}
            helperTextProps={{
              text: field.helperText
            }}
            rules={rule}
            min={field.limitEntry && field.minimumLimit}
            max={field.limitEntry && field.maximumLimit}
            step={field.step}
          />
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewNumberComponent);
