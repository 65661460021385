import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Grid} from '@mui/material';
import TrafficTable from './AnalysisTables/TrafficTable';
import DeviceDistributionTable from './AnalysisTables/DeviceDistributionTable';
import ScreenSizeTable from './AnalysisTables/ScreenSizeTable';
import BrowsersTable from './AnalysisTables/BrowsersTable';
import PlatformTable from './AnalysisTables/PlatformTable';
import CustomTabs, {CustomTab} from '../../common/Tab/CustomTabs';
import {getFormStatisticsList} from '../../state/features/formStatistics/formStatisticSlice';

const LIST_FILTER = {
  0: 'traffic',
  1: 'device',
  2: 'platform',
  3: 'location'
};

const TrafficTabs = () => {
  const {formId} = useParams();
  const dispatch = useDispatch();
  const {selectedListFilter} = useSelector(state => state.formStatistics);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    dispatch(
      getFormStatisticsList({
        formId,
        page: 1,
        limit: 10,
        tab: LIST_FILTER[selectedTab]
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListFilter, selectedTab]);

  return (
    <CustomTabs className="AnalyticsTabs" onChange={setSelectedTab}>
      <CustomTab title="TRAFFIC">
        <TrafficTable />
      </CustomTab>
      <CustomTab title="DEVICE">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <DeviceDistributionTable />
          </Grid>
          <Grid item xs={12} md={4}>
            <ScreenSizeTable />
          </Grid>
        </Grid>
      </CustomTab>
      <CustomTab title="PLATFORM">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <BrowsersTable />
          </Grid>
          <Grid item xs={12} md={4}>
            <PlatformTable />
          </Grid>
        </Grid>
      </CustomTab>
      {/*<CustomTab title="LOCATION">*/}
      {/*  <LocationTable />*/}
      {/*</CustomTab>*/}
    </CustomTabs>
  );
};

export default TrafficTabs;
