import {Backdrop, CircularProgress} from '@mui/material';
import CoreButton from './CoreButton';

const LoadingButton = ({
  variant = 'contained',
  size = 'large',
  isLoading = false,
  loadingText = '',
  disabled = false,
  children = '',
  startIcon,
  ...rest
}) => {
  const handleOutSiteClick = () => {
    //  do nothing
  };

  return (
    <>
      <CoreButton
        variant={variant}
        size={size}
        disabled={isLoading || disabled}
        startIcon={
          isLoading && startIcon ? (
            <CircularProgress size={16} color={'inherit'} />
          ) : isLoading ? (
            <CircularProgress size={16} color={'inherit'} />
          ) : (
            startIcon
          )
        }
        {...rest}
      >
        {isLoading && loadingText ? loadingText : children}
      </CoreButton>

      <Backdrop
        sx={{zIndex: theme => theme.zIndex.drawer + 1}}
        open={isLoading}
        onClick={handleOutSiteClick}
        invisible={true}
      >
        {/*<CircularProgress color="inherit" />*/}
      </Backdrop>
    </>
  );
};

export default LoadingButton;
