import React from 'react';
import {Box, Stack} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from '../../../common/Icons/RequireIcon';

const CompanyComponent = ({field, editMode, onChange}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <>
      <Box className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

        <Box sx={{width: '100%'}}>
          <FormInput
            inputIcon={field.inputIcon && fbpIcons.companyIcon}
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={field.required ? {required: 'Company name is required'} : {}}
          />
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CompanyComponent);
