import React from 'react';

const RoundedCrossIcon = ({fill, ...props}) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99992 0.333313C3.31325 0.333313 0.333252 3.31331 0.333252 6.99998C0.333252 10.6866 3.31325 13.6666 6.99992 13.6666C10.6866 13.6666 13.6666 10.6866 13.6666 6.99998C13.6666 3.31331 10.6866 0.333313 6.99992 0.333313ZM10.3333 9.39331L9.39325 10.3333L6.99992 7.93998L4.60659 10.3333L3.66659 9.39331L6.05992 6.99998L3.66659 4.60665L4.60659 3.66665L6.99992 6.05998L9.39325 3.66665L10.3333 4.60665L7.93992 6.99998L10.3333 9.39331Z"
        fill="#949DB2"
      />
    </svg>
  );
};

export default RoundedCrossIcon;
