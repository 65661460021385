import React from 'react';
import {Box} from '@mui/material';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import Styles from './Audio.module.scss';

const AudioComponent = ({field}) => {
  const alignemntClass =
    field.positioning === 'default' ? Styles[field.alignment] : Styles.fullWidth;

  return (
    <Box>
      {!field.audio && (
        <Box className={`${Styles.fbpAudio} ${alignemntClass}`}>
          <Box sx={{lineHeight: 0}}>
            <svg
              width="31"
              height="42"
              viewBox="0 0 31 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.002 0V21.6533C12.9053 21.2567 11.7386 21 10.502 21C4.69195 21 0.00195312 25.69 0.00195312 31.5C0.00195312 37.31 4.69195 42 10.502 42C15.892 42 20.302 37.9167 20.8853 32.6667H21.002V7H30.3353V0H14.002Z"
                fill="#949DB2"
              />
            </svg>
          </Box>
        </Box>
      )}

      {field.audio && (
        <Box
          className={`${Styles.fbpAudio} ${alignemntClass}`}
          sx={{bgcolor: 'transparent', border: 'none', p: '0'}}
        >
          <Box sx={{width: '100%'}}>
            <audio controls>
              <source src={field.audio} type="audio/ogg" />
              <source src={field.audio} type="audio/mpeg" />
              <source src={field.audio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </Box>
        </Box>
      )}

      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default AudioComponent;
