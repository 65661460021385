import React from 'react';
import {Skeleton, Typography} from '@mui/material';
import {StyledBox} from './AnalysisStyles';
import {useSelector} from 'react-redux';

const TotalViewsBox = ({count, text, className, onClick = null}) => {
  const {isLoading} = useSelector(state => state.formStatistics);

  return (
    <StyledBox p={2} className={className} onClick={onClick}>
      <Typography variant="h1">{isLoading ? <Skeleton width={40} /> : count}</Typography>
      <Typography variant="body2">{text}</Typography>
    </StyledBox>
  );
};
export default TotalViewsBox;
