import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Stack, Box, Typography} from '@mui/material';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import {CustomFileUploader} from '../../../common/FileUpload/CustomFileUploader';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import TwoTabsWithTitle from '../../Builder/RightComponents/TwoTabsWithTitle';
import CustomTextField from '../../../common/TextField/CustomTextField';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideVideoPreviewComponent from '../../Builder/RightComponents/RightSideVideoPreviewComponent';

const VideoGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  const [selectedTab, setSelectedTab] = useState('upload');
  const [url, setUrl] = useState(field.video);

  useEffect(() => {
    if (field.video) {
      setUrl(field.video);
    }
  }, [field.video]);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'card' && (
        <RightSideInput
          text="Description"
          title="Change the description of the field"
          placeholder="Description"
          value={field.description}
          onChange={e => onChangeValue({description: e.target.value})}
        />
      )}

      <TwoTabsWithTitle
        text="Add Video"
        title="Add video to the form"
        child1="Upload"
        child2="Video URL"
        value1="upload"
        value2="url"
        selectedValue={selectedTab}
        onChange={setSelectedTab}
      >
        {selectedTab === 'upload' && (
          <CustomFileUploader
            className="fbp_custom_image_upload_option"
            preType="video"
            onFileUpload={videoFile => onChangeValue({video: videoFile.file})}
          />
        )}

        {selectedTab === 'url' && (
          <Stack
            direction="column"
            alignItems="flex-end"
            sx={{textAlign: 'right', width: '100%'}}
            spacing={1}
          >
            <Typography variant="tooltip" color="text.secondary" textAlign="justify">
              Video URL refers direct video file link. Use the Embed element to display a video from
              YouTube or any video platform.
            </Typography>
            <CustomTextField
              size="medium"
              labelPosition="top"
              placeholder="Enter URL"
              defaultValue={field.video}
              onChange={e => setUrl(e.target.value)}
            />

            <LightCoreButton onClick={e => onChangeValue({video: url})} size="small">
              Save
            </LightCoreButton>
          </Stack>
        )}
      </TwoTabsWithTitle>

      {field.video && (
        <RightSideVideoPreviewComponent
          text="Video"
          title="The added video"
          video={field.video}
          onRemove={() => onChangeValue({video: null})}
        />
      )}

      {theme.layout === 'classic' && (
        <>
          <TwoTabsWithTitle
            text="Video positioning"
            title="Video position on the form"
            child1="Default"
            child2="Full Width"
            value1="default"
            value2="fullWidth"
            selectedValue={field.positioning}
            onChange={value => onChangeValue({positioning: value})}
          />

          {field.positioning === 'default' && (
            <ThreeTabsWithTitle
              text="Video Alignment"
              title="Video alignment on the form"
              child1={fbpIcons.fbpLeftIcon}
              child2={fbpIcons.fbpCenterIcon}
              child3={fbpIcons.fbpRightIcon}
              value1="left"
              value2="center"
              value3="right"
              selectedValue={field.alignment}
              onChange={value => onChangeValue({alignment: value})}
            />
          )}
        </>
      )}

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(VideoGeneral);
