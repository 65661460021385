import React from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideInlineCheckbox from '../../Builder/RightComponents/RightSideInlineCheckbox';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';

const NameOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        sx={{gap: '20px'}}
        className="builderElementBasicStyle"
      >
        <RightComponentTitle
          text="Visible Options"
          title="Visible options on public page"
          className="mb-3"
        />

        <RightSideInlineCheckbox
          label="Middle Name"
          checked={field.middleNameEnabled}
          onChange={value => onChangeValue({middleNameEnabled: value})}
        />

        <RightSideInlineCheckbox
          label="Prefix"
          checked={field.prefixEnabled}
          onChange={value => onChangeValue({prefixEnabled: value})}
        />
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </>
  );
};

export default withFieldPropertyChange(NameOptions);
