import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import ConditionsBody from './ConditionsBody';
import AddNewCondition from './AddNewCondition/AddNewCondition';
import AddNewConditionItemDetails from './AddNewCondition/AddNewConditionItemDetails';
import {CONDITION_PAGE_VIEW} from '../../../helpers/constant/formConditionConstant';
import {resetConditionSetting} from '../../../state/features/conditionSetting/conditionSettingSlice';
import {selectConditionCurrentPage} from '../../../state/features/conditionSetting/conditionSettingSelector';

export default function Conditions() {
  const dispatch = useDispatch();
  const currentPage = useSelector(selectConditionCurrentPage);

  useEffect(() => {
    return dispatch(resetConditionSetting());
  }, [dispatch]);

  return (
    <Box sx={{position: 'relative'}}>
      {/* ## Condition first page ## */}
      {currentPage === CONDITION_PAGE_VIEW.HOME && <ConditionsBody />}

      {/* ## After Click Add New Condition - Select New Condition View ## */}
      {currentPage === CONDITION_PAGE_VIEW.TYPE_SELECTION && <AddNewCondition />}

      {/* ## Selected New Condition - Details View ## */}
      {currentPage === CONDITION_PAGE_VIEW.BUILDER && <AddNewConditionItemDetails />}
    </Box>
  );
}
