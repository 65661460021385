import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Box, Divider, Paper, Stack, Typography} from '@mui/material';
import HeaderSettingsBody from './HeaderSettingsBody';
import CircleLoader from "../../LoadingView/CircleLoader";
import CustomSwitch from '../../../common/Switch/CustomSwitch';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import {enableFormHeader} from "../../../state/features/formHeader/formHeaderSlice";
import {selectFormFooterState} from "../../../state/features/formHeader/formHeaderSelector";

const HeaderSettings = () => {
    const dispatch = useDispatch();
    const {formId} = useParams();
    const { data, isLoading, isUpdating } = useSelector(selectFormFooterState);
    const { enable = false } = data || {};

    const onEnabledHeader = () => {
        dispatch(enableFormHeader({formId, enable: !enable}))
    }

    if(isLoading) return <CircleLoader />;

  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'Header Settings'} content={'Show Basic information from the Header'}  info={"Header will only work in classic view."}/>
      <Divider />
      <Box p={0.5}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          px={3}
          py={2}
          // sx={{bgcolor: 'other.outlinedBorder'}}
        >
          <Typography variant={'body1medium'} color={'text.primary'}>
            Enable Header
          </Typography>
          <CustomSwitch
            variant="outlined"
            labelChecked={'ON'}
            labelUnchecked={'OFF'}
            status={enable}
            onChange={onEnabledHeader}
            width={62}
            disabled={isUpdating}
          />
        </Stack>
      </Box>
        <Box
            sx={{
                visibility: enable ? "visible" : "hidden",
                height: enable ? "100%" : "0px",
                transition: "all 0.3s ease-in-out",
                transitionOrigin: 'top'
            }}
        >
        <Divider light />
        <HeaderSettingsBody />
      </Box>
    </Paper>
  );
};

export default HeaderSettings;
