import React, {useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {format as dateFormat} from 'date-fns';
import {FormDatePicker} from '../../Builder/FormComponents/FormDatePicker';
import withFieldChange from '../../../hoc/withFieldChange';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewDateComponent = ({field, onChange, editMode}) => {
  const getDateFormat = useMemo(() => {
    let separtedFormat = field.format.split('/');
    return (
      separtedFormat[0] + field.separator + separtedFormat[1] + field.separator + separtedFormat[2]
    );
  }, [field]);

  const getDefaultDate = useMemo(() => {
    if (field.defaultDate === 'none') {
      return null;
    } else if (field.defaultDate === 'current') {
      return dateFormat(new Date(), 'yyyy-MM-dd');
    } else {
      return field.value === null ? null : field.value;
    }
  }, [field]);

  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>

        <Stack direction="column" spacing={1}>
          <FormDatePicker
            className="BuilderDatePicker"
            name={field.id}
            value={getDefaultDate}
            rules={field.required ? {required: 'This value is required'} : {}}
            format={getDateFormat}
            readOnly={editMode || field.readOnly}
            helperTextProps={{
              text: field.helperText
            }}
            onChange={value => onChange({value: value, defaultDate: 'custom'})}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewDateComponent);
