import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import PipelineService from '../../../services/Pipeline.service';
import {getPipelineDataFailed, getPipelineDataSuccess} from './pipelineSlice';

function* pipelineWatcher() {
  yield takeLatest('pipeline/getPipelineData', getPipelineDataSaga);
}

function* getPipelineDataSaga(action) {
  try {
    const response = yield call(PipelineService.getPipelineData, action.payload);

    if (response.success) {
      yield put(getPipelineDataSuccess(response.data));
    } else {
      yield put(getPipelineDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* pipelineSaga() {
  yield all([pipelineWatcher()]);
}
