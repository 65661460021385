import {createSlice} from '@reduxjs/toolkit';
import generateRandomString from '../../../helpers/utils/generateRandomString';
import {FORM_STATUS} from '../../../helpers/constant/formConstant';

const initialState = {
  forms: [],
  agencyId: '',
  page: 1,
  limit: 10,
  count: 10,
  isLoading: false,
  isError: false,
  error: '',
  renderId: '',

  isNewFormInsideFolder: false,
  newFormFolderId: '',
  isFormAdding: false,
  isFormAddSuccess: false,
  newFormId: null,

  isFormUpdating: false,
  isFormUpdateSuccess: false,

  isFormExporting: false,
  isFormExportSuccess: false,

  isFormDeleting: false,
  isFormDeleteSuccess: false,

  selectedFormIds: [],
  filters: {
    folderId: '',
    favourite: false,
    archived: false,
    trash: false,
    searchText: '',
    startDate: null,
    endDate: null,
    orderBy: '',
    status: []
  }
};

const folderFormsSlice = createSlice({
  name: 'folderForms',
  initialState: initialState,
  reducers: {
    getFolderForms: (state, action) => {
      state.isLoading = true;
      if (action.payload) {
        state.page = action.payload.page || 1;
        state.limit = action.payload.limit || 10;
      }
    },
    getFolderFormsSilent: (state, action) => {
      if (action.payload) {
        state.page = action.payload.page || 1;
        state.limit = action.payload.limit || 10;
      }
    },
    getFolderFormsSuccess: (state, action) => {
      state.forms = action.payload.content;
      state.count = action.payload.count;
      state.limit = action.payload.limit;
      state.page = action.payload.currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFolderFormsFailed: (state, action) => {
      state.forms = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addNewFormInsideFolder: (state, action) => {
      state.isNewFormInsideFolder = action.payload.status;
      state.newFormFolderId = action.payload.folderId;
    },
    addForm: (state, action) => {
      state.isFormAdding = true;
    },
    addFormSuccess: (state, action) => {
      if (action.payload?.id) {
        state.forms.push(action.payload);
      }
      state.isFormAdding = false;
      state.isFormAddSuccess = true;
      state.newFormId = action.payload.id;
    },
    addFormFailed: (state, action) => {
      state.isFormAdding = false;
    },
    resetAddFormSuccess: (state, action) => {
      state.isFormAddSuccess = false;
    },

    reFetchFolderForms: (state, action) => {
      state.renderId = generateRandomString(5);
    },

    formClone: (state, action) => {},
    formCloneSuccess: (state, action) => {
      state.renderId = generateRandomString(5);
    },

    formMoveToArchive: (state, action) => {},
    formMoveToArchiveSuccess: (state, action) => {
      if (action.payload?.ids) {
        // re fetch data by render id when data count more than 10
        if (state.count > 10) {
          state.renderId = generateRandomString(5);
        } else {
          state.forms = state.forms.filter(formData => !action.payload.ids.includes(formData.id));
          state.count -= 1;
        }
        state.selectedFormIds = [];
      }
    },

    formPauseOrPublish: state => {},
    formPauseOrPublishSuccess: (state, action) => {
      if (action.payload?.ids) {
        // re fetch data by render id when data count more than 10
        if (state.count > 10) {
          state.renderId = generateRandomString(5);
        } else {
          state.forms = state.forms.map(formData => {
            if (action.payload.ids.includes(formData.id)) {
              formData.status = action.payload.status;
            }
            return formData;
          });
        }
        state.selectedFormIds = [];
      }
    },

    updateFolderForm: (state, action) => {
      state.isFormUpdating = true;
    },
    updateFolderFormSuccess: (state, action) => {
      if (action.payload?.formId) {
        state.forms = state.forms.map(formData => {
          if (formData.id === action.payload.formId) {
            formData.formTitle = action.payload.formTitle;
          }
          return formData;
        });
      }
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
    },
    updateFolderFormFailed: (state, action) => {
      state.isFormUpdating = false;
    },
    resetUpdateFormSuccess: (state, action) => {
      state.isFormUpdateSuccess = false;
    },

    exportForm: (state, action) => {
      state.isFormExporting = true;
    },
    exportFormSuccess: (state, action) => {
      state.isFormExporting = false;
      state.isFormExportSuccess = true;
    },
    exportFormFailed: (state, action) => {
      state.isFormExporting = false;
    },
    resetExportFormSuccess: (state, action) => {
      state.isFormExportSuccess = false;
    },

    formMoveToTrash: (state, action) => {
      state.isFormDeleting = true;
    },
    formMoveToTrashSuccess: (state, action) => {
      if (action.payload?.ids) {
        if (state.count > 10) {
          state.renderId = generateRandomString(5);
        } else {
          state.forms = state.forms.filter(formData => !action.payload.ids.includes(formData.id));
          state.count -= 1;
        }
      }
      state.isFormDeleting = false;
      state.isFormDeleteSuccess = true;
      state.selectedFormIds = [];
    },
    formMoveToTrashFailed: (state, action) => {
      state.isFormDeleting = false;
    },

    restoreForm: (state, action) => {},
    restoreFormSuccess: (state, action) => {
      if (action.payload?.ids) {
        if (state.count > 10) {
          state.renderId = generateRandomString(5);
        } else {
          state.forms = state.forms.filter(formData => !action.payload.ids.includes(formData.id));
          state.count -= 1;
        }
        state.selectedFormIds = [];
      }
    },

    formRemoveForever: state => {},
    formRemoveForeverSuccess: (state, action) => {
      state.renderId = generateRandomString(5);
      state.selectedFormIds = [];
    },

    formRemoveFromFolder: state => {},
    formRemoveFromFolderSuccess: (state, action) => {
      state.selectedFormIds = [];
      state.renderId = generateRandomString(5);
    },

    formAddToFolder: (state, action) => {
      state.isFormUpdating = true;
    },
    formAddToFolderSuccess: (state, action) => {
      state.renderId = generateRandomString(5);
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
      state.selectedFormIds = [];
    },
    formAddToFolderFailed: (state, action) => {
      state.isFormUpdating = false;
    },

    toggleFavouriteForm: (state, action) => {
      state.isFormUpdating = true;
    },
    toggleFavouriteFormSuccess: (state, action) => {
      const {ids, favourite} = action.payload;
      if (ids?.length) {
        state.forms = state.forms.map(formData => {
          if (ids.indexOf(formData.id) !== -1) {
            formData.favourite = favourite;
          }
          return formData;
        });
        if (favourite === false && state.filters.favourite === true) {
          state.forms = state.forms.filter(formData => !ids.includes(formData.id));
          state.count -= 1;
        }
      }
      state.isFormUpdating = false;
    },
    toggleFavouriteFormFailed: (state, action) => {
      state.isFormUpdating = false;
    },

    // form selection actions
    selectedAllForm: state => {
      const ids = [];
      state.forms.map(formData => {
        ids.push(formData.id);
        return formData;
      });
      state.selectedFormIds = ids;
    },
    deselectAllForm: state => {
      state.selectedFormIds = [];
    },
    toggleSelectedSingleForm: (state, action) => {
      const currentFormIndex = state.selectedFormIds.findIndex(formId => formId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedFormIds.splice(currentFormIndex, 1);
      } else {
        state.selectedFormIds.push(action.payload);
      }
    },

    // filters
    selectFormFolder: (state, action) => {
      state.filters.folderId = action.payload;
      state.filters.favourite = false;
      state.filters.archived = false;
      state.filters.trash = false;
      state.filters.status = [];
      state.selectedFormIds = [];
      state.renderId = '';
    },
    selectFavoriteFolder: (state, action) => {
      state.filters.folderId = '';
      state.filters.favourite = true;
      state.filters.archived = false;
      state.filters.trash = false;
      state.filters.status = [];
      state.selectedFormIds = [];
      state.renderId = '';
    },
    selectArchivedFolder: (state, action) => {
      state.filters.folderId = '';
      state.filters.favourite = false;
      state.filters.archived = true;
      state.filters.trash = false;
      state.filters.status = [];
      state.selectedFormIds = [];
      state.renderId = '';
    },
    selectTrashFolder: (state, action) => {
      state.filters.folderId = '';
      state.filters.favourite = false;
      state.filters.archived = false;
      state.filters.trash = true;
      state.selectedFormIds = [];
      state.filters.status = [FORM_STATUS.TRASH];
      state.renderId = '';
    },
    addSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    addDateRange: (state, action) => {
      state.filters.startDate = action.payload.startTime;
      state.filters.endDate = action.payload.endTime;
    },
    addOrderByFilter: (state, action) => {
      state.filters.orderBy = action.payload;
    },
    resetFormFilter: state => {
      state.filters.folderId = '';
      state.filters.favourite = false;
      state.filters.archived = false;
      state.filters.trash = false;
      state.filters.searchText = '';
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.orderBy = '';
      state.filters.status = [];
    },
    resetOnlyFormFilter: state => {
      state.filters.searchText = '';
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.orderBy = '';
    }
  }
});

export const {
  getFolderForms,
  getFolderFormsSilent,
  getFolderFormsSuccess,
  getFolderFormsFailed,

  addNewFormInsideFolder,
  addForm,
  addFormSuccess,
  addFormFailed,
  resetAddFormSuccess,

  reFetchFolderForms,

  formClone,
  formCloneSuccess,

  formMoveToArchive,
  formMoveToArchiveSuccess,

  formPauseOrPublish,
  formPauseOrPublishSuccess,

  updateFolderForm,
  updateFolderFormSuccess,
  updateFolderFormFailed,
  resetUpdateFormSuccess,

  exportForm,
  exportFormSuccess,
  exportFormFailed,
  resetExportFormSuccess,

  formMoveToTrash,
  formMoveToTrashSuccess,
  formMoveToTrashFailed,

  restoreForm,
  restoreFormSuccess,

  formRemoveForever,
  formRemoveForeverSuccess,

  formRemoveFromFolder,
  formRemoveFromFolderSuccess,

  formAddToFolder,
  formAddToFolderSuccess,
  formAddToFolderFailed,

  toggleFavouriteForm,
  toggleFavouriteFormSuccess,
  toggleFavouriteFormFailed,

  selectedAllForm,
  deselectAllForm,
  toggleSelectedSingleForm,

  selectFormFolder,
  selectFavoriteFolder,
  selectArchivedFolder,
  selectTrashFolder,

  addSearchText,
  addDateRange,
  addOrderByFilter,
  resetFormFilter,
  resetOnlyFormFilter
} = folderFormsSlice.actions;

export default folderFormsSlice.reducer;
