import React from 'react';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';

const CardViewPublicPageViewManager = props => {
  const Component = ComponentConfig[props.field.component]?.cardComponent;

  if (Component) {
    return <Component key={props.pageNo} {...props} />;
  }

  return '';
};

export default CardViewPublicPageViewManager;
