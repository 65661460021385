import {nanoid} from 'nanoid';
import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import Prototype from '../../../helpers/builderConstant/FieldPrototypes';
import BuilderService from '../../../services/Builder.service';
import {selectAddFieldSaga, selectBuilderSetting} from './builderSelector';
import {builderQueueTypes, makeQueueSequence} from '../builderChannelQueue/builderQueueTypes';
import {
  addNewPageSuccess,
  cloneFieldSuccess,
  deleteFieldSuccess,
  reorderBuilderFieldsSuccess,
  reorderCardFieldsSuccess,
  storeAddBuilderField,
  storeUpdateAllFields,
  storeUpdatedBuilderField
} from './builderFieldsSlice';

function* builderFieldsWatcher() {
  yield takeEvery('builderFields/addBuilderField', addBuilderField);
  yield takeEvery('builderFields/deleteFieldFromSelectedIndex', deleteBuilderField);
  yield takeLatest('builderFields/updateBuilderField', updateBuilderField);
  yield takeLatest('builderFields/cloneFieldFormSelectedIndex', cloneBuilderField);
  yield takeLatest('builderFields/updateAllFields', updateAllBuilderField);
  yield takeEvery('builderFields/reorderBuilderFields', reorderBuilderFields);
  yield takeEvery('builderFields/reorderCardFields', reorderCardFields);
  yield takeEvery('builderFields/addNewPage', addNewPage);
  yield takeEvery('builderFields/deletePage', deletePage);
  yield takeEvery('builderFields/reorderPage', reorderPage);
}

function* addBuilderField(action) {
  try {
    let destinationIndex,
      destinationPageIndex,
      newField = action.payload.component;

    const {id, fields, selectedIndex, selectedPageIndex} = yield select(selectAddFieldSaga);

    if (action.payload.destinationIndex !== undefined) {
      //If the index is specified. That means dragged from the left panel
      if (action.payload.destinationPageIndex !== undefined) {
        //If classic view
        destinationIndex = action.payload.destinationIndex;
        destinationPageIndex = action.payload.destinationPageIndex;
      } else {
        //If card view
        let index = 0;
        fields.forEach((eachPage, eachPageIndex) => {
          eachPage.pageItems.forEach((eachField, fieldIndex) => {
            if (index === action.payload.destinationIndex) {
              destinationIndex = fieldIndex;
              destinationPageIndex = eachPageIndex;
            }
            index++;
          });
        });

        //Means we could'nt find the index
        //Now selecting last index
        if (destinationIndex === undefined && destinationPageIndex === undefined) {
          destinationPageIndex = fields.length - 1;
          destinationIndex = fields[destinationPageIndex].pageItems.length - 1;
        }
      }
    } else {
      //On click element from left panel. We always add new element above button if click from left side.
      destinationPageIndex = selectedPageIndex === null ? fields.length - 1 : selectedPageIndex;

      let buttonIndex = fields[destinationPageIndex].pageItems.findIndex(
        field => field.component === COMPONENT_TYPE.SUBMIT_BUTTON
      );

      if (selectedIndex !== null && selectedIndex < buttonIndex) {
        destinationIndex = selectedIndex + 1;
      } else {
        destinationIndex = buttonIndex;
      }
    }

    //Adding default options to radio and checkbox
    if (
      action.payload.component.component === COMPONENT_TYPE.RADIO ||
      action.payload.component.component === COMPONENT_TYPE.CHECKBOX
    ) {
      newField = {
        ...newField,
        options: [
          {title: 'Type option 1', value: nanoid()},
          {title: 'Type option 2', value: nanoid()}
        ]
      };
    }

    let params = {
      formId: id,
      elementType: action.payload.component.component,
      elementBuilderJson: JSON.stringify(newField),
      elementOrder: destinationIndex,
      formPage: destinationPageIndex + 1
    };

    //Adding new field to the store instantly.
    yield put(
      storeAddBuilderField({
        component: newField,
        index: destinationIndex,
        pageIndex: destinationPageIndex
      })
    );

    yield put(
      makeQueueSequence({
        api: BuilderService.addBuilderElement,
        params: params,
        id: newField.id,
        type: builderQueueTypes.ADD_FIELD
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteBuilderField(action) {
  try {
    const {id, fields, selectedIndex, selectedPageIndex} = yield select(selectAddFieldSaga);
    if (selectedIndex !== null) {
      yield put(
        makeQueueSequence({
          api: BuilderService.deleteBuilderElement,
          params: {
            formId: id,
            elementId: fields[selectedPageIndex].pageItems[selectedIndex].id
          },
          type: builderQueueTypes.DELETE_FIELD
        })
      );
      yield put(deleteFieldSuccess());
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* cloneBuilderField(action) {
  try {
    const {id, fields, selectedIndex, selectedPageIndex} = yield select(selectAddFieldSaga);
    if (selectedIndex !== null) {
      yield put(cloneFieldSuccess(action.payload));
      yield put(
        makeQueueSequence({
          api: BuilderService.cloneBuilderElement,
          params: {
            formId: id,
            id: action.payload.id,
            elementId: fields[selectedPageIndex].pageItems[selectedIndex].id
          },
          id: action.payload.id,
          type: builderQueueTypes.CLONE_FIELD
        })
      );
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateBuilderField(action) {
  try {
    yield delay(500);
    const {id} = yield select(selectBuilderSetting);
    yield put(storeUpdatedBuilderField(action.payload));
    yield put(
      makeQueueSequence({
        api: BuilderService.updateBuilderElement,
        params: {
          formId: id,
          elementId: action.payload.id,
          elementBuilderJson: JSON.stringify(action.payload)
        },
        type: builderQueueTypes.UPDATE_FIELD
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateAllBuilderField(action) {
  try {
    yield delay(500);
    const {id} = yield select(selectBuilderSetting);
    yield put(storeUpdateAllFields(action.payload));
    yield call(BuilderService.updateAllBuilderElement, {
      formId: id,
      propertyName: action.payload.type,
      propertyValue: action.payload.value
    });
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* reorderBuilderFields(action) {
  try {
    const {id, fields} = yield select(selectAddFieldSaga);
    yield put(reorderBuilderFieldsSuccess(action.payload));
    yield put(
      makeQueueSequence({
        api: BuilderService.reorderElement,
        params: {
          formId: id,
          elementId:
            fields[action.payload.sourcePageIndex].pageItems[action.payload.sourceIndex].id,
          elementMovedFrom: action.payload.sourceIndex,
          elementSourcePage: action.payload.sourcePageIndex + 1,
          elementMovedAt: action.payload.destinationIndex,
          elementTargetPage: action.payload.destinationPageIndex + 1
        },
        type: builderQueueTypes.REORDER_FIELDS
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* reorderCardFields(action) {
  try {
    const {id, fields} = yield select(selectAddFieldSaga);

    let index = 0,
      destinationIndex = 0,
      destinationPageIndex = 0,
      sourceIndex = 0,
      sourcePageIndex = 0,
      elementId = '';

    //Finding the proper field
    fields.forEach((eachPage, pageIndex) => {
      eachPage.pageItems.forEach((field, fieldIndex) => {
        if (action.payload.sourceId === field.id) {
          sourcePageIndex = pageIndex;
          sourceIndex = fieldIndex;
          elementId = field.id;
        }
        if (index === action.payload.destinationIndex) {
          destinationPageIndex = pageIndex;
          destinationIndex = fieldIndex;
        }
        index++;
      });
    });

    yield put(
      reorderCardFieldsSuccess({
        sourceIndex,
        sourcePageIndex,
        destinationIndex,
        destinationPageIndex
      })
    );

    yield put(
      makeQueueSequence({
        api: BuilderService.reorderElement,
        params: {
          formId: id,
          elementId: elementId,
          elementMovedFrom: sourceIndex,
          elementSourcePage: sourcePageIndex + 1,
          elementMovedAt: destinationIndex,
          elementTargetPage: destinationPageIndex + 1
        },
        type: builderQueueTypes.REORDER_FIELDS
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addNewPage(action) {
  try {
    const {id, fields} = yield select(selectAddFieldSaga);
    let newItem = {...Prototype[COMPONENT_TYPE.SUBMIT_BUTTON], id: nanoid()};

    yield put(addNewPageSuccess({...action.payload, newItem}));
    yield put(
      makeQueueSequence({
        api: BuilderService.addNewPage,
        params: {
          formId: id,
          pageNumber: fields.length + 1,
          elements: [JSON.stringify(newItem)]
        },
        type: builderQueueTypes.PAGE_UPDATE
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deletePage(action) {
  try {
    const {id} = yield select(selectBuilderSetting);
    yield put(
      makeQueueSequence({
        api: BuilderService.deletePage,
        params: {
          formId: id,
          pageNumber: action.payload + 1
        },
        type: builderQueueTypes.PAGE_UPDATE
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* reorderPage(action) {
  try {
    const {id} = yield select(selectBuilderSetting);
    yield put(
      makeQueueSequence({
        api: BuilderService.reorderPage,
        params: {
          formId: id,
          pageMovedFrom: action.payload.sourceIndex + 1,
          pageMovedAt: action.payload.destinationIndex + 1
        },
        type: builderQueueTypes.PAGE_UPDATE
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* builderFieldsSaga() {
  yield all([builderFieldsWatcher()]);
}
