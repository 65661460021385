import React from 'react';
import {Box, Typography} from '@mui/material';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {addBuilderField} from '../../../../state/features/builderFields/builderFieldsSlice';
import {nanoid} from 'nanoid';
import {COMPONENT_TYPE} from '../../../../helpers/builderConstant/ComponentType';
import fbpIcons from '../../../../helpers/fbpIcons/fbpIcons';
import Prototype from '../../../../helpers/builderConstant/FieldPrototypes';
import Styles from './Elements.module.scss';

function getStyle(style, snapshot) {
  if (!snapshot.isDragging) {
    return {transform: 'none'};
  }
  if (!snapshot.isDropAnimating) {
    return {
      ...style,
      border: '1px dashed #4099ff',
      top: style.top - 50
    };
  }

  return {
    ...style,
    transform: 'none',
    transitionDuration: `0.001s`
  };
}

const Widgets = () => {
  const dispatch = useDispatch();
  const {theme} = useSelector(state => state.builderSetting);
  const {fieldsCount} = useSelector(state => state.builderFields);

  const handleInsertField = prototype => {
    dispatch(
      addBuilderField({
        component: {
          ...prototype,
          id: nanoid(),
          ...(prototype.labelAlignment && {labelAlignment: theme.labelAlignment})
        }
      })
    );
  };
  return (
    <Droppable droppableId={'widgetElements'} isDropDisabled={true}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}
          className={Styles.fbpBuildersElementsWrapper}
        >
          <Draggable draggableId={'widgetElements' + COMPONENT_TYPE.PROGRESSBAR} index={0}>
            {(provided, snapshot) => (
              <Box
                className={`${Styles.fbpLeftEachElement} ${
                  fieldsCount[COMPONENT_TYPE.PROGRESSBAR] > 0 ? Styles.disabled : ''
                }`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
                style={getStyle(provided.draggableProps.style, snapshot)}
                onClick={() => handleInsertField(Prototype[COMPONENT_TYPE.PROGRESSBAR])}
              >
                {fbpIcons.progressbarIcon}
                <Typography variant={'helperText2'} color="text.secondary">
                  {'Progress'}
                </Typography>
              </Box>
            )}
          </Draggable>
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default Widgets;
