import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConditionAndActionBody from './ConditionAndActionBody';
import CoreButton from '../../../../common/Button/CoreButton';
import CustomTitleBar from '../../../../common/TitleBar/CustomTitleBar';
import {CONDITION_PAGE_VIEW} from '../../../../helpers/constant/formConditionConstant';
import {setConditionCurrentPage} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {selectConditionTypeHeader} from '../../../../state/features/conditionSetting/conditionSettingSelector';

const AddNewConditionItemDetails = () => {
  const dispatch = useDispatch();
  const typeHeader = useSelector(selectConditionTypeHeader);
  const {heading, subHeading, icon, color} = typeHeader || {};

  const handleClickBack = () => {
    dispatch(setConditionCurrentPage(CONDITION_PAGE_VIEW.HOME));
  };

  return (
    <CustomTitleBar title={heading} content={subHeading} icon={icon} iconBg={color}>
      <Box p={3}>
        <CoreButton
          variant={'outlined'}
          color={'secondary'}
          startIcon={<ArrowBackIcon fontSize="small" />}
          onClick={handleClickBack}
        >
          Back to list
        </CoreButton>

        {/* ## Condition And Action View ## */}
        <ConditionAndActionBody color={color} />
      </Box>
    </CustomTitleBar>
  );
};

export default AddNewConditionItemDetails;
