import React from 'react';
import Box from '@mui/material/Box';
import {Stack} from '@mui/material';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightsideInlineInput from '../../Builder/RightComponents/RightsideInlineInput';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';

const YesNoOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Stack direction="column" spacing={1} className="builderElementBasicStyle">
        <RightComponentTitle text="Values" title="Change yes no button text" />
        <RightsideInlineInput
          label="Positive"
          value={field.positiveText}
          onChange={e => onChangeValue({positiveText: e.target.value})}
        />
        <RightsideInlineInput
          label="Negative"
          value={field.negativeText}
          onChange={e => onChangeValue({negativeText: e.target.value})}
        />
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(YesNoOptions);
