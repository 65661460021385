import {Controller, useForm, useFormContext} from 'react-hook-form';
import {Box, Stack} from '@mui/material';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import CustomDatePicker from '../../../common/PrevDatePicker/CustomDatePicker';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';

export const FormDatePicker = ({
  inputIcon,
  name,
  value,
  rules,
  className = '',
  wrapperClass,
  format,
  type,
  orientation,
  helperTextProps = {},
  readOnly = false,
  onChange = () => {}
}) => {
  const {control} = useForm();
  const methods = useFormContext();

  return (
    <Controller
      control={methods ? methods.control : control}
      name={name}
      defaultValue={value}
      rules={rules}
      render={formOption => (
        <Stack direction="column" spacing={1}>
          <Box sx={{background: 'var(--fbp-theming-input-background)', borderRadius: '4px'}}>
            <CustomDatePicker
              inputIcon={inputIcon}
              className={`${className} ${wrapperClass}`}
              format={format}
              value={value}
              type={type}
              orientation={orientation}
              readOnly={readOnly}
              onChange={value => {
                methods?.setValue(name, value);
                onChange(value);
                if (formOption.formState.isSubmitted) {
                  methods?.trigger();
                }
              }}
            />
          </Box>
          {formOption.fieldState.error?.message ? (
            <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
          ) : (
            <FormSubtext>{helperTextProps.text}</FormSubtext>
          )}
        </Stack>
      )}
    />
  );
};
