import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: '',

  isAdding: false,
};

const saveIconSlice = createSlice({
  name: 'saveIcon',
  initialState: initialState,
  reducers: {
    getSaveIcons: state => {
      state.isLoading = true;
    },
    getSaveIconsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    getSaveIconsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    },

    saveMyIcon: state => {
      state.isSuccess = false
    }
  }
});

export const {getSaveIcons, getSaveIconsSuccess, getSaveIconsFailed, saveMyIcon} =
  saveIconSlice.actions;

export default saveIconSlice.reducer;
