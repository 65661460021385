import {all, put} from 'redux-saga/effects';
import {delay, takeEvery} from '@redux-saga/core/effects';
import {sendEmailFailed, sendEmailSuccess} from './emailShareSlice';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';

function* emailShareWatcher() {
  yield takeEvery('emailShare/sendEmail', sendEmailSaga);
}

function* sendEmailSaga(action) {
  try {
    // async api service call will be here
    // const response = yield call(NotificationService.getNotificationList, action.payload);
    const response = {success: true, data: action.payload, message: 'Successfully Send Email'};
    yield delay(1000);

    if (response.success) {
      yield put(sendEmailSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(sendEmailFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* emailShareSaga() {
  yield all([emailShareWatcher()]);
}
