import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {LocalOffer} from '@mui/icons-material';
import {IconButton, Popover, Stack} from '@mui/material';
import AddTag from '../AddTag/AddTag';
import AddToCampaign from '../AddToCampaign/AddToCampaign';
import InboxReplyForm from './InboxReplyForm';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import ExportFormContent from '../../FormList/ExportFormContent';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import CoreButton from '../../../common/Button/CoreButton';
import BasicDrawer from '../../../common/Drawer/BasicDrawer';
import DownloadIcon from '../../../common/Icons/DownloadIcon';
import TrashIcon from '../../../common/Icons/TrashIcon';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import useBoolean from '../../../hooks/useBoolean';
import {castArray} from '../../../helpers/utils/castArray';
import {FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION} from '../../../helpers/constant/formSubmissionConstant';
import {inboxFormDelete} from '../../../state/features/formSubmission/formSubmissionSlice';
import {
  selectSelectedContactId,
  selectSelectedSubmissionId
} from '../../../state/features/formSubmission/formSubmissionSelector';

const InboxHeaderActions = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const formSubmissionId = useSelector(selectSelectedSubmissionId);
  const contactId = useSelector(selectSelectedContactId);
  const [isOpenInboxReply, setOpenInboxReply] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    value: OpenDeleteModal,
    setTrue: onOpenDeleteModal,
    setFalse: onCloseDeleteModal
  } = useBoolean(false);
  const {value: openDrawer, toggle: toggleOpenDrawer} = useBoolean(false);
  const {value: openExportModal, toggle: toggleExportModal} = useBoolean(false);

  // const onOpenInboxReply = () => setOpenInboxReply(true);
  const onCloseInboxReply = () => setOpenInboxReply(false);

  // const handleReply = () => {
  //   onOpenInboxReply();
  // };

  const handleOpen = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleDownload = () => {
    toggleExportModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(inboxFormDelete({ids: castArray(formSubmissionId)}));
    onCloseDeleteModal();
  };
  const open = Boolean(anchorEl);
  const id = open ? 'tags-popover' : undefined;
  return (
    <>
      <Stack direction="row" alignItems={'center'} justifyContent={'end'} spacing={1.5}>
        {/*<CustomTooltip onClick={handleReply} title="Reply" arrow placement="bottom">*/}
        {/*  <span style={{display: 'inline-flex'}}>{fbpIcons.fbpReplayIcon}</span>*/}
        {/*</CustomTooltip>*/}

        <CustomTooltip
          aria-describedby={id}
          onClick={handleOpen}
          title="Add Tag"
          arrow
          placement="bottom"
        >
          <LocalOffer color={'action'} />
        </CustomTooltip>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <AddTag />
        </Popover>

        <CustomTooltip title="Export" arrow placement="bottom">
          <IconButton onClick={handleDownload} sx={{p: 0}}>
            <DownloadIcon color={'action'} />
          </IconButton>
        </CustomTooltip>

        <CustomTooltip title="Delete" arrow placement="bottom">
          <IconButton onClick={onOpenDeleteModal} sx={{p: 0}}>
            <TrashIcon color={'action'} />
          </IconButton>
        </CustomTooltip>

        <CoreButton
          size="small"
          variant="contained"
          color="secondary"
          onClick={toggleOpenDrawer}
          sx={{minWidth: '160px'}}
          disabled={!contactId}
        >
          Add to Campaign
        </CoreButton>
      </Stack>

      <BasicDrawer open={isOpenInboxReply} title={'Reply Inbox'} toggleDrawer={onCloseInboxReply}>
        <InboxReplyForm open={isOpenInboxReply} onClose={onCloseInboxReply} />
      </BasicDrawer>

      <BasicDrawer open={openDrawer} title={'Add to Campaign'} toggleDrawer={toggleOpenDrawer}>
        <AddToCampaign onClose={toggleOpenDrawer} isBulkAction={false} />
      </BasicDrawer>

      <DeleteWarningModal
        title={'Delete Submission'}
        warningSubtitle={'Are you sure you want to delete selected submission(s)?'}
        warningContent={
          'Selected 1 submissions will be deleted permanently.This action cannot be undone.'
        }
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete Forever'}
        open={OpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />

      <BasicModalWithHeader
        modalTitle={'Export Submission'}
        open={openExportModal}
        onClose={toggleExportModal}
      >
        <ExportFormContent
          onClose={toggleExportModal}
          formId={formId}
          module={FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION}
          submissionIds={castArray(formSubmissionId)}
        />
      </BasicModalWithHeader>
    </>
  );
};

export default InboxHeaderActions;
