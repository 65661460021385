import React, {useState} from 'react';
import {CustomUploadImage} from '../../../common/FileUpload/CustomUploadImage';
import HeadingImageModal from '../../EachComponent/heading/HeadingImageModal';
import RightComponentTitle from './RightComponentTitle';
import Stack from '@mui/material/Box';

const RightSideImageUploader = ({text = '', title = '', recommendedSize, onImageUpload}) => {
  const [showHeadingImageModal, setShowHeadingImageModal] = useState(false);

  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <CustomUploadImage
        recommendedSize={recommendedSize}
        className="rightSideFilUpload"
        onImageUpload={imageFile => onImageUpload(imageFile.image)}
      />
      <HeadingImageModal
        open={showHeadingImageModal}
        onClose={() => setShowHeadingImageModal(false)}
      />
    </Stack>
  );
};

export default RightSideImageUploader;
