import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {ListItem, Box, Stack, styled, Typography} from '@mui/material';
import {FOLDER_COLORS} from '../../helpers/constant/formFolderContant';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem
} from '../../common/PopoverMenu/CustomPopoverMenu';
import AddOrEditFolderName from './AddOrEditFolderName';
import CreateFormModal from '../CreateForm/CreateFormModal';
import DeleteWarningModal from '../Builder/Modals/DeleteWarningModal';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import MoreIcon from '../../common/Icons/MoreIcon';
import FolderIcon from '../../common/Icons/FolderIcon';
import {
  deleteFormFolder,
  updateFormFolderColor
} from '../../state/features/formFolder/formFolderSlice';
import {
  addNewFormInsideFolder,
  selectFormFolder
} from '../../state/features/folderForms/folderFormsSlice';
import Styles from './FormList.module.scss';

const ColorItemBox = styled(Box)(({theme}) => ({
  gap: '10px',
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  padding: '8px 16px',
  cursor: 'pointer',
  '& .fbpColorItem': {
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    display: 'inline-flex',
    background: theme.palette.other.outlinedBorder
  }
}));

const FolderListItem = ({folderData, selected}) => {
  const dispatch = useDispatch();
  const {id, name, color} = folderData || {};

  const [selectedFolderInfo, setSelectedFolderInfo] = useState(null);
  const [isShowFolderEditForm, setShowFolderEditForm] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOpenNewFormModal, setOpenNewFormModal] = useState(false);
  const [showSelectedFolder, setShowSelectedFolder] = useState(false);

  const handleShowFolderEditForm = () => setShowFolderEditForm(true);
  const handleCloseFolderEditForm = () => setShowFolderEditForm(false);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const onShowSelectedFolder = () => setShowSelectedFolder(true);
  const onHideSelectedFolder = () => setShowSelectedFolder(false);

  const handleSelectFormFolder = fId => {
    dispatch(selectFormFolder(fId));
  };

  // handle open and new form
  const toggleOpenNewFormModal = () => setOpenNewFormModal(prevState => !prevState);

  const handleAddNewFormInsideFolder = () => {
    dispatch(addNewFormInsideFolder({status: true, folderId: id}));
    toggleOpenNewFormModal();
  };

  // handle Change Folder Name
  const handleFolderNameChange = data => {
    setSelectedFolderInfo(data);
    handleShowFolderEditForm();
  };

  // handle Delete With confirmation
  const handleDeleteFolder = data => {
    setSelectedFolderInfo(data);
    handleOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    dispatch(
      deleteFormFolder({
        folderId: folderData.id
      })
    );
    handleCloseDeleteModal();
  };

  // handle Change Folder Color
  const handleChangeFolderColor = (id, color) => {
    dispatch(updateFormFolderColor({id: id, color}));
  };

  return (
    <>
      <ListItem
        sx={{display: 'flex', alignItems: 'center', mb: '5px', padding: "0px 10px 0px 0px"}}
        className={`${(selected || showSelectedFolder) && 'active'} ${Styles.formSidebarList}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={() => handleSelectFormFolder(id)}
          sx={{width: '89%', flex: 1, height: "40px", padding: "0px 10px"}}
        >
          <FolderIcon fontSize={'small'} sx={{color: color}} />
          <Typography variant="buttonMedium" className="text-truncate" title={name}>
            {name}
          </Typography>
        </Stack>
        <Box sx={{ml: 'auto', mr: '-10px'}}>
          <CustomPopoverMenu wrapperClass="fbpMoreOptions" callbackOnClose={onHideSelectedFolder}>
            <CustomPopoverMenuButton>
              <Box className={Styles.moreIcon} onClick={onShowSelectedFolder}>
                <MoreIcon />
              </Box>
            </CustomPopoverMenuButton>

            <CustomPopoverMenuItem
              title="Add New Form"
              icon={fbpIcons.fbpGrayPlusIcon}
              onClick={handleAddNewFormInsideFolder}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title="Change Name"
              icon={fbpIcons.fbpGrayEditIcon}
              onClick={() => handleFolderNameChange(folderData)}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title="Delete Folder"
              icon={fbpIcons.fbpGrayTrashIcon}
              onClick={() => handleDeleteFolder(folderData)}
              onClickHide={true}
            />
            <Box>
              <Stack
                direction="row"
                spacing={2}
                alignItems={'center'}
                px={2}
                py={1}
                sx={{cursor:'pointer'}}
              >
                {fbpIcons.fbpGrayColorIcon}
                <Typography variant="body1" color="text.primary">
                  Change Color
                </Typography>
              </Stack>

              <ColorItemBox>
                {FOLDER_COLORS.map((color, i) => (
                  <Box
                    key={i}
                    className="fbpColorItem"
                    style={{backgroundColor: color}}
                    onClick={() => handleChangeFolderColor(folderData?.id, color, name)}
                  />
                ))}
              </ColorItemBox>
            </Box>
          </CustomPopoverMenu>
        </Box>
      </ListItem>

      {isOpenNewFormModal && (
        <CreateFormModal open={isOpenNewFormModal} onClose={toggleOpenNewFormModal} />
      )}

      <BasicModalWithHeader
        titleIcon={fbpIcons.fbpGrayEditIcon}
        modalTitle={'Change Folder Name'}
        open={isShowFolderEditForm}
        onClose={handleCloseFolderEditForm}
      >
        <AddOrEditFolderName
          onClose={handleCloseFolderEditForm}
          folderInfo={selectedFolderInfo}
          isEditMode={true}
        />
      </BasicModalWithHeader>

      <DeleteWarningModal
        warningSubtitle={'Are you sure you want to delete this folder?'}
        warningContent={'You can not undo this action.'}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default React.memo(FolderListItem);
