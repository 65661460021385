export class CountManager {
  constructor() {
    this.count = 0;
    this.map = {};
  }

  //Next function return count value by key.
  next(key) {
    if (this.map[key] === undefined) {
      this.map[key] = ++this.count;
    }
    return this.map[key];
  }
}
