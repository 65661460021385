import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  error: '',

  isFormUpdating: false,
  isFormUpdateSuccess: false
};

const formSettingSlice = createSlice({
  name: 'formSetting',
  initialState: initialState,
  reducers: {
    getFormSetting: (state, action) => {
      state.isLoading = true;
    },
    getFormSettingSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormSettingFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFormSetting: state => {
      state.isFormUpdating = true;
    },
    updateFormSettingSuccess: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
    },
    updateFormSettingFailed: (state, action) => {
      state.isFormUpdating = false;
      state.error = action.payload;
    }
  }
});

export const {
  getFormSetting,
  getFormSettingSuccess,
  getFormSettingFailed,

  updateFormSetting,
  updateFormSettingSuccess,
  updateFormSettingFailed
} = formSettingSlice.actions;

export default formSettingSlice.reducer;
