import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Box, Collapse, Divider, Paper, Stack, Typography, useTheme, styled} from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MailIcon from '@mui/icons-material/Mail';
import LinkIcon from '@mui/icons-material/Link';
import ThankYouRadioButton from './ThankYouRadioButton';
import ThankYouDefault from './ThankYouDeafult';
import ThankYouCustom from './ThankYouCustom';
import ThankYouRedirect from './ThankYouRedirect';
import CircleLoader from '../../LoadingView/CircleLoader';
import {
  THANK_YOU_PAGE_LAYOUT_CUSTOM,
  THANK_YOU_PAGE_LAYOUT_DEFAULT,
  THANK_YOU_PAGE_LAYOUT_REDIRECT
} from '../../../helpers/constant/formSettingsConstant';
import {
  selectFormThankYouState,
  selectFormThankYouType
} from '../../../state/features/FormThankYou/formThankYouSelector';
import {updateFormThankYouSetting} from '../../../state/features/FormThankYou/formThankYouSlice';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';

const StyledCollapse = styled(Collapse)(() => ({
  transition: 'all 300ms ease 0s',
  '&:not(.MuiCollapse-entered)': {marginTop: 0}
}));

const ThankYou = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {formId} = useParams();
  const type = useSelector(selectFormThankYouType);
  const {data: thankYouFormData, isLoading} = useSelector(selectFormThankYouState);
  const [thankYouPageType, setThankYouPageType] = useState(0);

  useEffect(() => {
    setThankYouPageType(type);
  }, [type]);

  // handle thank you page type
  const onClickThankYouPageType = value => {
    setThankYouPageType(Number(value));
    dispatch(
      updateFormThankYouSetting({
        formId: formId,
        thankyouPageSetting: JSON.stringify({...thankYouFormData, type: +value})
      })
    );
  };

  // ## decide what to render
  let content = null;
  if (isLoading) {
    content = <CircleLoader />;
  } else {
    content = (
      <Box
        sx={{
          backgroundColor: theme.palette.other.pageColor,
          border: `1px solid ${theme.palette.other.outlinedBorder}`
        }}
        p={2}
      >
        <Stack spacing={2}>
          <Box>
            <Typography variant={'subtitle1'}>Submission Message</Typography>
            <Typography variant={'body2medium'} color={'text.secondary'}>
              After your form is submitted, a default message will be displayed to your end-users.
              You can customize that message here.
            </Typography>
          </Box>
          {/* Default Thank You Submission */}
          <ThankYouRadioButton
            icon={
              <MarkEmailReadIcon
                size={'large'}
                color={thankYouPageType === THANK_YOU_PAGE_LAYOUT_DEFAULT ? 'secondary' : 'action'}
              />
            }
            title={'Show default thank you message'}
            subTitle={'We’ll display the default thank you message shown below.'}
            name={'embedMode'}
            value={THANK_YOU_PAGE_LAYOUT_DEFAULT}
            checked={thankYouPageType === THANK_YOU_PAGE_LAYOUT_DEFAULT}
            onClick={onClickThankYouPageType}
          />
          <StyledCollapse in={thankYouPageType === THANK_YOU_PAGE_LAYOUT_DEFAULT}>
            <ThankYouDefault />
          </StyledCollapse>

          {/* Custom Thank You Submission */}
          <ThankYouRadioButton
            icon={
              <MailIcon
                size={'large'}
                color={thankYouPageType === THANK_YOU_PAGE_LAYOUT_CUSTOM ? 'secondary' : 'action'}
              />
            }
            title={'Add a custom message'}
            subTitle={'Add your own custom, personalized thank you message.'}
            name={'embedMode'}
            value={THANK_YOU_PAGE_LAYOUT_CUSTOM}
            checked={thankYouPageType === THANK_YOU_PAGE_LAYOUT_CUSTOM}
            onClick={onClickThankYouPageType}
          />
          <StyledCollapse in={thankYouPageType === THANK_YOU_PAGE_LAYOUT_CUSTOM}>
            <ThankYouCustom />
          </StyledCollapse>
          <Divider light />
        </Stack>

        {/* Redirect Submission Page */}
        <Stack spacing={2} pt={2}>
          <Box>
            <Typography variant={'subtitle1'}>Redirect to an external link</Typography>
            <Typography variant={'body2medium'} color={'text.secondary'}>
              Choose where you want to redirect respondents on form submission.
            </Typography>
          </Box>
          <ThankYouRadioButton
            icon={
              <LinkIcon
                size={'large'}
                color={thankYouPageType === THANK_YOU_PAGE_LAYOUT_REDIRECT ? 'secondary' : 'action'}
              />
            }
            title={'Redirect to an external link'}
            subTitle={'Choose where you want to redirect'}
            name={'embedMode'}
            value={THANK_YOU_PAGE_LAYOUT_REDIRECT}
            checked={thankYouPageType === THANK_YOU_PAGE_LAYOUT_REDIRECT}
            onClick={onClickThankYouPageType}
          />
          <StyledCollapse in={thankYouPageType === THANK_YOU_PAGE_LAYOUT_REDIRECT}>
            <ThankYouRedirect />
          </StyledCollapse>
        </Stack>
      </Box>
    );
  }

  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'Thank You Page'} content={'Shown after form submission.'} />
      <Divider />
      <Box sx={{p: 3, height: '100%'}}>{content}</Box>
    </Paper>
  );
};

export default ThankYou;
