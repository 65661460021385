import React from 'react';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import ThreeTabs from './ThreeTabs';
import RightComponentTitle from './RightComponentTitle';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';

const FormLabelAlignment = ({labelAlignment = 'top', onChange, shrinkMode = false}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '10px'
      }}
      className="builderElementBasicStyle"
    >
      <RightComponentTitle text="Label Alignment" title="Label Alignment" />
      <ThreeTabs
        child1={fbpIcons.fbpLeftIcon}
        child2={fbpIcons.fbpBaselineIcon}
        child3={fbpIcons.fbpRightIcon}
        value1="left"
        value2="top"
        value3="right"
        selectedValue={labelAlignment}
        onChange={value => onChange(value)}
      />

      {shrinkMode && (
        <Typography variant="helperText" color="error.main">
          Shrink mode will disable label alignment
        </Typography>
      )}
    </Box>
  );
};

export default FormLabelAlignment;
