import React from 'react';
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {Box, FormControl, FormLabel, Stack} from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import {registerPatternMediaImageIcon} from "../../../helpers/constant/registerPattern";
import {saveMyIcon} from "../../../state/features/saveIcon/saveIconSlice";

const AddMediaUrlTab = ({onAddImageIcon}) => {
    const dispatch = useDispatch();
    const {formId} = useParams()
    const {handleSubmit, reset, control} = useForm();

    // handle submit add folder
    const onSubmit = ({imageUrl}) => {
        if (imageUrl) {
            onAddImageIcon(imageUrl);
            dispatch(saveMyIcon({formId: formId, imageUrl: imageUrl}));
        }
    };

  return (
      <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{bgcolor: 'background.paper', mt: 2}}
      >
          <Controller
              control={control}
              name="imageUrl"
              defaultValue=""
              render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
                  <FormControl fullWidth error={invalid}>
                      <FormLabel>
                          Image Url
                      </FormLabel>
                      <CoreTextField
                          type={'text'}
                          size={'small'}
                          color={"secondary"}
                          placeholder={'https://domain.com/t/image.png'}
                          value={value}
                          onChange={onChange}
                          error={invalid}
                          helperText={invalid && error?.message}
                          sx={{mt: "4px !important"}}
                      />
                  </FormControl>
              )}
              rules={registerPatternMediaImageIcon.imageUrl}
          />

          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
              <CoreButton color={'error'} type={'button'} onClick={reset}>
                  reset
              </CoreButton>
              <CoreButton
                  variant={"contained"}
                  type={'submit'}
                  color={'success'}
              >
                  Save
              </CoreButton>
          </Stack>
      </Box>
  );
};

export default AddMediaUrlTab;
