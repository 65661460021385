import React from 'react';
import {Stack, styled, Typography, useTheme} from '@mui/material';

const StackStyled = styled(Stack)(({theme}) => ({
  width: '100%',
  height: '44px',
  border: `1px solid ${theme.palette?.other.outlinedBorder}`,
  borderRadius: '4px',
  padding: '8px 12px',
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const SidebarStatusButton = ({startIcon, title = '', onClick, isActive = false}) => {
  const theme = useTheme();
  return (
    <StackStyled
      direction="row"
      spacing={1}
      elavation={1}
      onClick={onClick}
      sx={isActive ? {backgroundColor: theme.palette.action.hover} : {}}
    >
      {startIcon}
      <Typography variant={'body1'}>{title}</Typography>
    </StackStyled>
  );
};

export default SidebarStatusButton;
