import React from 'react';
import {styled, Typography} from '@mui/material';

const StyledSection = styled('section')(({theme}) => ({
  background: 'var(--fbp-primary-states-outlined-hover-background)',
  border: '1px dashed var(--fbp-secondary-main)',
  borderRadius: 8,
  display:'grid',
  placeItems:'center',
  margin: '18px 0px',
  width: '100%',
  height: 70,
  backgroundColor: 'rgb(236 235 255)',
  '& p': {
    color: 'var(--fbp-text-secondary)',
    userSelect: 'none',
  }
}));

const DropElementHere = () => {
  return (
    <StyledSection>
      <Typography variant="body2">Drop here to add your field</Typography>
    </StyledSection>
  );
};

export default DropElementHere;
