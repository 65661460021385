import React from 'react';
import {Box} from '@mui/material';
import FormTemplateSidebar from './FormTemplateSidebar';
import FormTemplateBody from './FormTemplateBody';

const FormTemplate = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 165px)',
        padding: {lg: '15px 100px', md: '15px 30px', xs: '5px 20px'},
        overflowY: 'hidden',
        columnGap: '30px',
        display: 'flex',
      }}
    >
      <FormTemplateSidebar />
      <FormTemplateBody />
    </Box>
  );
};

export default FormTemplate;
