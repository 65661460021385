import {Box, styled} from '@mui/material';

export const StylesDropDownBox = styled(Box)(({theme}) => ({
  width: '100%',
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'borderBox',
  padding: '6px',
  background: 'var(--fbp-theming-input-background)',
  '& .MuiInputBase-root': {
    fontFamily: 'var(--fbp-theming-font-family) !important',
    color: 'var(--fbp-theming-text-color) !important',
    '& .MuiSelect-select': {
      '&.Mui-disabled': {
        opacity: '0.42',
        textFillColor: 'var(--fbp-theming-placeholder-color) '
      }
    }
  },
  '&.nameDropdown': {padding: '6px 12px'},
  '&.formListFilter': {
    background: 'transparent',
    '&.fbpCustomDropDownInnerFlex': {width: '223px', borderRadius: '4px', padding: '4px'},
    '& .MuiSelect-select': {
      fontFamily: 'var(--poppins) ',
      fontSize: '14px',
      color: `${theme.palette.text.secondary} !important`
    }
  },

  '&:focus': {
    border: `1px solid ${theme.palette.secondary.main}`
  },
  '&.fbpCustomDropDownInnerFlex': {
    position: 'relative',
    borderRadius: '8px',
    '&.fbpCustomDropDownWrSmall': {
      padding: '6px 37px'
    }
  },
  '& fbpCustomDropDownWrMedium': {
    '.MuiSelect-select': {
      padding: '10px 12px',
      fontFamily: 'var(--fbp-theming-font-family) !important'
    }
  },
  '& .fbpCustomDropDownWrSmall': {
    '& MuiSelect-select': {
      padding: '6px 12px',
      fontsize: '14px',
      fontFamily: 'var(--fbp-theming-font-family) !important'
    }
  },
  '& .fbpCustomDropDownWrLarge': {
    '& MuiSelect-select': {
      padding: '14px 12px',
      fontsize: '14px',
      fontFamily: 'var(--fbp-theming-font-family) !important'
    }
  },
  '&.fbpCustomDropDownTopWrLeft': {
    display: 'flex',
    alignItems: 'center',
    gridGap: '12px',
    '& .fbpCustomDropDownTopTitle': {
      padding: '0'
    }
  },
  '&.embedShare': {
    padding: '1px 6px',
    '& .MuiSelect-select': {
      fontFamily: 'var(--poppins) ',
      color: `${theme.palette.text.secondary} !important`
    }
  }
}));
