import {all} from 'redux-saga/effects';
import builderFieldsSaga from './features/builderFields/builderFieldsSaga';
import formFolderSaga from './features/formFolder/formFolderSaga';
import folderFormsSaga from './features/folderForms/folderFormsSaga';
import formSettingSaga from './features/formSetting/formSettingSaga';
import builderSettingSaga from './features/builderSetting/builderSettingSaga';
import emailShareSaga from './features/emailShare/emailShareSaga';
import formSubmissionSaga from './features/formSubmission/formSubmissionSaga';
import templateCategoriesSaga from './features/templateCategories/templateCategoriesSaga';
import templatesSaga from './features/templates/templatesSaga';
import tagsSaga from './features/tags/tagsSaga';
import campaignSaga from './features/campaign/campaignSaga';
import adminTemplateCategoriesSaga from './features/adminTemplateCategories/adminTemplateCategoriesSaga';
import adminTemplatesSaga from './features/adminTemplates/adminTemplatesSaga';
import pipelineSaga from './features/pipeline/pipelineSaga';
import pipelineStageSaga from './features/pipelineStage/pipelineStageSaga';
import exportedFileSaga from './features/exportedFiles/exportedFileSaga';
import publicPageSaga from './features/publicPage/publicPageSaga';
import formSEOSaga from './features/formSEO/formSEOSaga';
import formThankYouSaga from './features/FormThankYou/formThankYouSaga';
import adminAccessSaga from './features/adminAccess/adminAccessSaga';
import templatePublishSaga from './features/templatePublish/templatePublishSaga';
import builderChannelQueue from './features/builderChannelQueue/builderChannelQueue';
import formStatisticsSaga from './features/formStatistics/formStatisticsSaga';
import formReportSaga from "./features/formsReport/formReportSaga";
import formHeaderSaga from "./features/formHeader/formHeaderSaga";
import mediaIconSaga from "./features/mediaIcon/mediaIconSaga";
import fieldMappingSaga from './features/fieldMapping/fieldMappingSaga';
import customFieldSaga from './features/customField/customFieldSaga';
import saveIconSaga from "./features/saveIcon/saveIconSaga";
import formFooterSaga from "./features/formFooter/formFooterSaga";
import mediaImageSaga from './features/mediaImage/mediaImageSaga';
import conditionSettingSaga from "./features/conditionSetting/conditionSettingSaga";

export default function* rootSaga() {
  yield all([
    formFolderSaga(),
    folderFormsSaga(),
    templateCategoriesSaga(),
    templatesSaga(),
    formSettingSaga(),
    formThankYouSaga(),
    formSEOSaga(),
    builderFieldsSaga(),
    builderSettingSaga(),
    builderChannelQueue(),
    formSubmissionSaga(),
    tagsSaga(),
    campaignSaga(),
    pipelineSaga(),
    pipelineStageSaga(),
    emailShareSaga(),
    adminTemplateCategoriesSaga(),
    adminTemplatesSaga(),
    templatePublishSaga(),
    adminAccessSaga(),
    exportedFileSaga(),
    publicPageSaga(),
    formStatisticsSaga(),
    formReportSaga(),
    formHeaderSaga(),
    mediaIconSaga(),
    saveIconSaga(),
    fieldMappingSaga(),
    customFieldSaga(),
    formFooterSaga(),
    conditionSettingSaga(),
    mediaImageSaga()
  ]);
}
