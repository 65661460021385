import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Divider, styled} from '@mui/material';
import InboxBodyHeader from './InboxBodyHeader';
import InboxBodyContent from './InboxBodyContent';
import FormSubmissionConversation from './FormSubmissionConversation';
import CircleLoader from '../../LoadingView/CircleLoader';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
import {selectFormSubmissionData} from '../../../state/features/formSubmission/formSubmissionSelector';

const InboxBodyBox = styled(Box)(({theme}) => ({
  marginTop: '10px',
  padding: '0px 32px',
  overflowX: 'hidden',
  height: 'calc(100% - 107px)'
}));

const InboxBody = () => {
  const {selectedSubmission: submissionData, isLoading} = useSelector(selectFormSubmissionData);

  let content = null;
  if (!submissionData && isLoading) {
    content = <CircleLoader />;
  } else if (!submissionData) {
    content = (
      <GlobalEmptyPage
        icon={<EmptySubmissionFormIcon />}
        title={'Inbox not submission'}
        description={
          'All your submissions & reports data to this form will be gone forever. This operation cannot be undone.'
        }
      />
    );
  } else if (submissionData?.id) {
    content = (
      <Box sx={{height: '100%'}}>
        <InboxBodyHeader />
        <Divider />
        <InboxBodyBox className="fbp_has_scroll">
          <InboxBodyContent submission={submissionData} />
          <FormSubmissionConversation />
        </InboxBodyBox>
      </Box>
    );
  }

  return <Box sx={{flex: 1, bgcolor: 'background.paper', height: '100%'}}>{content}</Box>;
};

export default InboxBody;
