import {COMPONENT_TYPE} from '../builderConstant/ComponentType';

const getSubmissionFormNameAndEmail = (submissions = []) => {
  let name = '';
  let email = '';

  for (let submission of submissions) {
    if (name === '' && submission.type === COMPONENT_TYPE.FULL_NAME) {
      const {prefix, firstName, middleName, lastName} = submission || {};
      let values = [prefix, firstName, middleName, lastName];
      values = values.filter(value => !!value);
      name = values?.length > 0 && values.join(' ');
    }
    if (email === '' && submission.type === COMPONENT_TYPE.EMAIL) {
      email = submission.value;
    }
  }

  return {name, email};
};

export {getSubmissionFormNameAndEmail};
