import React from 'react';
import {Box, Divider, Paper, Stack, Typography} from '@mui/material';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import ShareOnSocialMedia from '../../../helpers/utils/ShareOnSocialMedia';
import {useSelector} from 'react-redux';
import {selectBuilderFormApiKey} from '../../../state/features/builderSetting/builderSettingSelector';
import generatePublicPageUrl from '../../../helpers/utils/generatePublicPageUrl';
import HrefLink from '../../../common/Link/HrefLink';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import Styles from '../styles/setting-page.module.scss';

const QuickShareSocialMedia = () => {
  const formApiKey = useSelector(selectBuilderFormApiKey);

  const shareOnFacebook = () => {
    return ShareOnSocialMedia.facebook(generatePublicPageUrl(formApiKey));
  };
  const shareOnLinkedIn = () => {
    return ShareOnSocialMedia.linkedIn(generatePublicPageUrl(formApiKey));
  };
  const shareOnTwitter = () => {
    return ShareOnSocialMedia.twitter(generatePublicPageUrl(formApiKey));
  };

  return (
    <Paper elevation={0} mt={3}>
      <BasicTitleBar
        title={'Share on social Media'}
        content={'Share your form as a public link, Social Share or Share with QR code'}
      />
      <Divider />
      <Stack direction="row" alignItems="center" spacing={2} px={4} py={3}>
        <HrefLink href={shareOnFacebook()} target={'_blank'}>
          <Box className={Styles.fbpPublicShareSocialSingle}>
            {fbpIcons.fbpFacebookIcon}
            <Typography variant="body1medium">Share on Facebook</Typography>
          </Box>
        </HrefLink>

          <HrefLink href={shareOnLinkedIn()} target={'_blank'}>
            <Box className={Styles.fbpPublicShareSocialSingle}>
              {fbpIcons.fbpLinkedinIcon}
              <Typography variant="body1medium">Share on LinkedIn</Typography>
            </Box>
          </HrefLink>

        <HrefLink href={shareOnTwitter()} target={'_blank'}>
          <Box className={Styles.fbpPublicShareSocialSingle}>
            {fbpIcons.fbpTwitterIcon}
            <Typography variant="body1medium">Share on Twitter</Typography>
          </Box>
        </HrefLink>
      </Stack>
    </Paper>
  );
};

export default QuickShareSocialMedia;
