import React from 'react';
import {Box, styled, Stack, Typography} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';

const StyledBoxTwoTabWithTitle = styled(Box)(({theme}) => ({
  width: ' 50%',
  height: '36px',
  background: theme.palette.other.gray,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    path: {
      fill: theme.palette.text.secondary
    }
  },
  '&.selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.secondary.main
      }
    }
  },
  '&.hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.secondary.main
      }
    }
  }
}));

const TwoTabsWithTitle = ({
  child1,
  child2,
  value1,
  value2,
  selectedValue,
  onChange,
  text,
  title,
  children
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '10px',
        position: 'relative'
      }}
      className="builderElementBasicStyle"
    >
      <RightComponentTitle text={text} title={title} />
      <Stack
        direction="row"
        sx={{
          width: '100%',
          borderRadius: '4px',
          overflow: 'hidden'
        }}
      >
        <StyledBoxTwoTabWithTitle
          onClick={() => onChange(value1)}
          className={value1 === selectedValue ? 'selected' : ''}
        >
          <Typography variant="body2medium">{child1}</Typography>
        </StyledBoxTwoTabWithTitle>
        <StyledBoxTwoTabWithTitle
          onClick={() => onChange(value2)}
          className={value2 === selectedValue ? 'selected' : ''}
        >
          <Typography variant="body2medium">{child2}</Typography>
        </StyledBoxTwoTabWithTitle>
      </Stack>
      {children}
    </Box>
  );
};

export default TwoTabsWithTitle;
