import {all, delay, put, select, call, takeLatest} from 'redux-saga/effects';
import FormStatisticsService from '../../../services/FormStatistics.service';
import {
  getFormStatisticsListSuccess,
  getFormStatisticsFailed,
  getFormStatisticsSuccess,
  getFormStatisticsListFailed
} from './formStatisticSlice';

function* formStatisticsWatcher() {
  yield takeLatest('formStatistics/getFormStatistics', getFormStatisticsSaga);
  yield takeLatest('formStatistics/getFormStatisticsList', getFormStatisticsListSaga);
}

function* getFormStatisticsSaga(action) {
  try {
    yield delay(500);
    const response = yield call(FormStatisticsService.getFormStatistics, action.payload);

    if (response.success) {
      yield put(getFormStatisticsSuccess(response));
    } else {
      yield put(getFormStatisticsFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* getFormStatisticsListSaga(action) {
  try {
    yield delay(500);
    const {selectedListFilter, formId} = yield select(state => state.formStatistics);
    let callFunction = null;

    if (action.payload.tab === 'traffic') {
      callFunction = FormStatisticsService.getTrafficData;
    } else if (action.payload.tab === 'device') {
      callFunction = FormStatisticsService.getDeviceData;
    } else if (action.payload.tab === 'platform') {
      callFunction = FormStatisticsService.getPlatformData;
    } else if (action.payload.tab === 'location') {
      // callFunction = FormStatisticsService.getLocationData;
    }

    //Calling the perspective function
    const response = yield call(callFunction, {
      ...action.payload,
      filterType: selectedListFilter,
      formId: action.payload.formId ?? formId
    });

    if (response.success) {
      yield put(getFormStatisticsListSuccess(response.data));
    } else {
      yield put(getFormStatisticsListFailed(response));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formStatisticsSaga() {
  yield all([formStatisticsWatcher()]);
}
