import React from 'react';
import {Stack} from '@mui/material';
import FormLayoutCard from '../../common/Cards/FormLayoutCard';
import FormOptionHeading from './FormOptionHeading';

const FormCreateOption = ({onSelectScratchLayout, onSelectTemplateLayout}) => {
  return (
    <Stack direction={'column'} alignItems={'center'} spacing={2}>
      <FormOptionHeading
        title="Create a New Form"
        subtitle="Create an amazing form to collect data easily."
      />

      <Stack direction={'row'} justifyContent={'center'} spacing={3} p={2} mt={3}>
        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'scratch.png'}
          title={'Start Form Scratch'}
          description={'Jump right in and build Whatever you want'}
          onClick={onSelectScratchLayout}
        />

        <FormLayoutCard
          image={process.env.REACT_APP_CDN_LINK + 'template.png'}
          title={'Use a Template'}
          description={'Pick a pre made form that’s fully customizable'}
          onClick={onSelectTemplateLayout}
        />
      </Stack>
    </Stack>
  );
};

export default FormCreateOption;
