import React from 'react';
import {Box, Stack} from '@mui/material';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormTextArea} from '../../Builder/FormComponents/FormTextArea';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewNoteComponent = ({field, editMode, onChange}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>

        <Stack direction="column" spacing={1}>
          <FormTextArea
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={field.required ? {required: 'Note is required'} : {}}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewNoteComponent);
