export const selectFormSubmissionPageView = state => state.formSubmission.pageView;
export const selectFormSubmissionData = state => state.formSubmission;
export const selectSelectedSubmissionId = state => state.formSubmission.selectedSubmission?.id;
export const selectSelectedSubmissionData = state => state.formSubmission.selectedSubmission;
export const selectSelectedSubmissionInboxTags = state =>
  state.formSubmission.selectedSubmission?.tagIds || [];
export const selectSelectedInboxFormIds = state => state.formSubmission.selectedInboxFormIds;

export const selectNextSubmissionData = state => {
  const currentSelectedSubmissionId = state.formSubmission.selectedSubmission?.id;

  let nextSubmissionData = null;

  if (currentSelectedSubmissionId) {
    const submissionList = state.formSubmission.data;

    for (let i = 0; i < submissionList.length; i++) {
      const element = submissionList[i];
      if (element.id === currentSelectedSubmissionId && submissionList[i + 1]) {
        nextSubmissionData = submissionList[i + 1];
        break;
      }
    }
  }

  return nextSubmissionData;
};

export const selectBackSubmissionData = state => {
  const currentSelectedSubmissionId = state.formSubmission.selectedSubmission?.id;

  let nextSubmissionData = null;

  if (currentSelectedSubmissionId) {
    const submissionList = state.formSubmission.data;

    for (let i = 1; i < submissionList.length; i++) {
      const element = submissionList[i];
      if (element.id === currentSelectedSubmissionId && submissionList[i - 1]) {
        nextSubmissionData = submissionList[i - 1];
        break;
      }
    }
  }

  return nextSubmissionData;
};

// for campaign
export const selectSelectedContactId = state => state.formSubmission.selectedSubmission?.contactId;
export const selectSelectedContactIds = state => {
  const selectedInboxFormIds = state.formSubmission.selectedInboxFormIds;

  return state.formSubmission.data
    .filter(submissionData => {
      return !!(selectedInboxFormIds.includes(submissionData?.id) && submissionData.contactId);
    })
    .map(submissionData => submissionData.contactId);
};

export const selectSubmissionFormTitle = state => state.formSubmission.formTitle;
export const selectSubmissionApiKey = state => state.formSubmission.formApiKey;

export const selectInboxFilters = state => state.formSubmission.filters;
// ## for table
export const selectFormSubmissionTableHeaders = state => state.formSubmission.tableHeader;
