import React from 'react';
import {SvgIcon} from '@mui/material';

const RenameIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M22 17.529V6.941H16.117V5.176H17.882V4H13.176V5.176H14.941V6.941H2V17.529H14.941V19.294H13.176V20.471H17.882V19.294H16.117V17.529H22ZM20.823 8.118V16.353H16.117V8.118H20.823ZM3.176 16.353V8.118H14.941V16.353H3.176Z"
        fill="#949DB2"
      />
      <path
        d="M4.353 9.294V11.647H5.529V10.471H6.706V14H5.529V15.177H9.059V14H7.882V10.471H9.059V11.647H10.235V9.294H4.353Z"
        fill="#949DB2"
      />
    </SvgIcon>
  );
};

export default RenameIcon;
