import {format} from 'date-fns';

const getFormatDataPickerDate = (startDate, endDate) => {
  const fromDate = format(startDate, 'MMM dd, yyyy');
  const toDate = format(endDate, 'MMM dd, yyyy');

  return [fromDate, toDate];
};

export default getFormatDataPickerDate;
