import React from 'react';
import {Box, Link, Stack, Typography, useTheme} from '@mui/material';
import {EmbedCard, EmbedDescription, EmbedTitle} from './FormEmbed';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import LinkIcon from '../../../common/Icons/LinkIcon';
import EmbedImgIcon from '../../../common/Icons/EmbedImgIcon';
import Styles from './EmbedAnything.module.scss';

const EmbededAnythingComponent = ({field}) => {
  const theme = useTheme();

  return (
    <>
      <Box sx={{width: '100%'}}>
        <Box
          className={`${Styles.EmbedCardWrp} ${Styles[field.alignment]}`}
          sx={{width: `${field.embedWidth}px`}}
        >
          <EmbedCard>
            {!field.link && (
              <Box sx={{borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`}}>
                <EmbedImgIcon width={field.embedWidth} fill="var(--fbp-theming-text-color)" />
              </Box>
            )}
            <Box>
              {field.link && (
                <iframe
                  width="100%"
                  height={field.height}
                  src={field.link}
                  title={field.link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
              {(field.title || field.description || field.showLink) && (
                <Box p={2}>
                  <Box>
                    {field.title && <EmbedTitle>{field.title}</EmbedTitle>}
                    {field.description && <EmbedDescription>{field.description}</EmbedDescription>}
                  </Box>

                  {field.showLink && (
                    <Stack direction="row" alignItems="center" spacing={1} pt={1}>
                      <span sx={{display: 'inline-flex'}}>
                        <LinkIcon fill="var(--fbp-theming-text-color)" />
                      </span>
                      <Link
                        sx={{textDecoration: 'none'}}
                        href={field.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Typography
                          variant="inputLevel"
                          title={field.link}
                          className="text-truncate"
                          py={1}
                          sx={{color: 'var(--fbp-theming-text-color)', opacity: '0.6'}}
                        >
                          {field.link}
                        </Typography>
                      </Link>
                    </Stack>
                  )}
                </Box>
              )}
            </Box>
          </EmbedCard>
        </Box>
        {field.hidden && <HiddenFieldAlert />}
      </Box>
    </>
  );
};

export default EmbededAnythingComponent;
