import React from 'react';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import ColorCustomize from '../../helpers/utils/ColorCustomize';
import {Typography} from '@mui/material';
import Styles from './CustomChip.module.scss';

const CustomChip = ({icon, text, color = '#333', onClose = null}) => {
  return (
    <div
      className={`${Styles.fbpFoldernameChip} ${Styles.colorScheme} ${Styles.checked}`}
      style={{borderColor: ColorCustomize.changeOpacity(color, 0.1)}}
    >
      {icon ? <span>{fbpIcons.fbpDynamicFolderIcon(color)}</span> : ''}

      <Typography variant="inputLevel" sx={{color: color}} px={1}>
        {text}
      </Typography>
      <span className={Styles.chipTrash} onClick={onClose}>
        {fbpIcons.fbpCrossIcon}
      </span>
    </div>
  );
};

export default CustomChip;
