import React from 'react';
import {Stack} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import BasicModalWithHeader from '../../../common/Modal/BasicModalWithHeader';
import AddImageIconContent from '../../EachComponent/multipleChoice/AddImageIconContent';
import useBoolean from '../../../hooks/useBoolean';

const RightSideUploadButtonComponent = ({text, title, buttonText, onUpload}) => {
  const {value: openModal, setTrue: onOpenModal, setFalse: onCloseModal} = useBoolean(false);

  const handleUploadImage = image => {
    onUpload(image);
    onCloseModal();
  };

  return (
    <Stack alignItems="start" direction="column" className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />

      <LightCoreButton onClick={onOpenModal} size="small">
        {buttonText}
      </LightCoreButton>

      <BasicModalWithHeader modalTitle={'Add Media'} open={openModal} onClose={onCloseModal}>
        <AddImageIconContent pexelsView onClose={onCloseModal} onAddImageIcon={handleUploadImage} />
      </BasicModalWithHeader>
    </Stack>
  );
};

export default RightSideUploadButtonComponent;
