import {styled} from '@mui/material';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';

const CustomTooltip = styled(({className, ...props}) => (
  <Tooltip {...props} arrow classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#15008A'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#15008A',
    fontSize: 11
  }
}));

export default CustomTooltip;
