import httpRequest from '../api/httpRequest';
import { isAdminRoute } from '../helpers/utils/isAdminRoute';

class FormService {
  getForms(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'form/lists',
      params
    );
  }

  createForm(body) {
    if(isAdminRoute()) {
      return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/create', body);
    }
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/create', body);
  }

  updateForm(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/update', body);
  }

  exportForm(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'export/create', body);
  }

  formMarkAsFavourite(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/favourite', body);
  }

  formAddToFolder(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'folderform/add', params);
  }

  formClone(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/clone', body);
  }

  formMoveToArchive(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/archive', body);
  }

  formPauseOrPublish(body) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/pauseorpublish',
      body
    );
  }

  restoreForm(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/restore', body);
  }

  formRemoveFromFolder(params) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'folderform/remove',
      params
    );
  }

  formMoveToTrash(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/remove',
      null,
      body
    );
  }
  formRemoveForever(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'trash/remove',
      null,
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormService();
