import React, {useState} from 'react';
import {ArrowBack} from '@mui/icons-material';
import GlobalFullScreenModal from '../../common/Modal/GlobalFullScreenModal';
import CoreButton from '../../common/Button/CoreButton';
import FormTemplate from './FormTemplate/FormTemplate';
import FormCreateOption from './FormCreateOption';
import FormLayoutOption from './FormLayoutOption';
import {CREATE_FORM_LAYOUT} from '../../helpers/constant/formConstant';

const CREATE_OPTION = 0;
const CreateFormModal = ({onClose, open}) => {
  const [selectedForm, setSelectedForm] = useState(CREATE_OPTION);

  const handleOnClose = () => {
    setSelectedForm(CREATE_OPTION);
    onClose();
  };

  const onSelectScratch = () => setSelectedForm(CREATE_FORM_LAYOUT.SCRATCH_LAYOUT);
  const onSelectTemplate = () => setSelectedForm(CREATE_FORM_LAYOUT.TEMPLATE_LAYOUT);
  const onClickBack = () => setSelectedForm(CREATE_OPTION);

  // decide what to render on view
  let content = null;
  if (selectedForm === CREATE_FORM_LAYOUT.SCRATCH_LAYOUT) {
    content = <FormLayoutOption />;
  } else if (selectedForm === CREATE_FORM_LAYOUT.TEMPLATE_LAYOUT) {
    content = <FormTemplate />;
  } else {
    content = (
      <FormCreateOption
        onSelectScratchLayout={onSelectScratch}
        onSelectTemplateLayout={onSelectTemplate}
      />
    );
  }

  let headerChildrenView = null;
  if (selectedForm !== CREATE_OPTION) {
    headerChildrenView = (
      <CoreButton
        size="small"
        color={'secondary'}
        variant={'outlined'}
        startIcon={<ArrowBack />}
        onClick={onClickBack}
      >
        Back
      </CoreButton>
    );
  }

  return (
    <>
      <GlobalFullScreenModal
        open={open}
        onClose={handleOnClose}
        stopBackDrop={true}
        headerChildren={headerChildrenView}
      >
        {content}
      </GlobalFullScreenModal>
    </>
  );
};

export default CreateFormModal;
