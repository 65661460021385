import React from 'react';
import {styled} from '@mui/material';

const UlCss = styled('ul')(({theme}) => ({
  minWidth: '120px',
  '& li': {
    listStyleType: 'square !important',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(2.5),
    '& span': {
      marginLeft: '-5px'
    }
  }
}));

const StatusCell = ({comments}) => {
  const {campaignComment, contactComment, stageComment} = comments || {};

  return (
    <UlCss>
      {contactComment && (
        <li>
          <span>{contactComment}</span>
        </li>
      )}
      {campaignComment && (
        <li>
          <span>{campaignComment}</span>
        </li>
      )}
      {stageComment && (
        <li>
          <span>{stageComment}</span>
        </li>
      )}
    </UlCss>
  );
};

export default StatusCell;
