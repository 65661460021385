import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const NoteAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Title"
        title="Change the title inside the box"
        placeholder="Placeholder"
        value={field.uploaderTitle}
        onChange={e => onChangeValue({uploaderTitle: e.target.value})}
      />

      <RightSideInput
        text="Sub Text"
        title="Change the sub text inside the box"
        placeholder="Placeholder"
        value={field.uploaderSubText}
        onChange={e => onChangeValue({uploaderSubText: e.target.value})}
      />

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}

      <RightSideSwitch
        text="Read Only"
        title="Set the input as read only"
        checked={field.readOnly}
        onChange={value => onChangeValue({readOnly: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(NoteAdvanced);
