import {TextField, styled} from '@mui/material';

const CustomBuilderInput = styled(TextField)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  background: 'var(--fbp-theming-input-background)',
  borderRadius: '4px',
  '& svg path': {
    fill: 'var(--fbp-theming-text-color) '
  },
  '& input': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.15px',
    height: '45px !important',
    padding: '0',
    border: 'none !important',
    borderRadius: '4px',
    transition: 'all 0.3s cubic-bezier(0.47, 0.01, 0.4, 0.99)',
    fontFamily: `var(--fbp-theming-font-family) !important`,
    color: 'var(--fbp-theming-text-color)',
    '&::placeholder': {
      fontFamily: `var(--fbp-theming-font-family)`,
      color: 'var(--fbp-theming-placeholder-color)'
    }
  },
  '&.MuiInputBase-inputSizeSmall': {
    padding: '6px 12px !important'
  },
  '& .MuiInputBase-root': {
    padding: '0 12px !important',
    flex: '1',
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.other.outlinedBorder}`
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.other.outlinedBorder}`
      }
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px !important',
        borderColor: theme.palette.secondary.main
      }
    }
  },
  '&.rightSideInput': {
    background: 'transparent !important',
    '&.input': {
      fontFamily: `var(--poppins) !important`,
      color: theme.palette.text.secondary,
      border: 'none !important',
      '&::placeholder': {
        fontFamily: `var(--poppins) !important`,
        color: `${theme.palette.text.secondary} !important`,
        opacity: '0.5'
      }
    },
    '& .MuiInputBase-root': {
      padding: '0 !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important'
      }
    }
  }
}));

export default CustomBuilderInput;
