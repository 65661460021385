import {styled} from '@mui/material';
import CoreButton from './CoreButton';

const ExtraSmallButton = styled(CoreButton)(({theme}) => ({
  padding: '0 6px !important',
  fontSize: '10px !important',
  minWidth: 'max-content'
}));

export default ExtraSmallButton;
