import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Divider, MenuItem, Select, Stack, styled, Typography, useTheme} from '@mui/material';
import {Restore} from '@mui/icons-material';
import CustomTooltip from '../../../../common/Tooltip/CustomTooltip';
import CoreTextField from '../../../../common/TextField/CoreTextField';
import {CustomColorPicker} from '../../../../common/CustomColorPicker';
import {LightCoreButton} from '../../../../common/Button/LightCoreButton';
import CustomImageUploadWrapper from '../../../../common/CustomIconPicker/CustomImageUploadWrapper';
import {
  EMBED_BUTTON_SIZES,
  EMBED_BUTTON_STYLES,
  EMBED_HEIGHT_SUFFIX,
  EMBED_MODES,
  EMBED_POPUP_SIZES,
  EMBED_WIDTH_SUFFIX
} from '../../../../helpers/constant/embedConstant';
import {ColorDropIcon} from '../../../../helpers/fbpIcons/IconComponents';
import {selectEmbedSettings} from '../../../../state/features/embedSettings/embedSettingsSelector';
import {setEmbedSettings} from '../../../../state/features/embedSettings/embedSettingsSlice';

const SmallMenuItem = styled(MenuItem)(() => ({fontSize: '14px'}));
const SmallSelect = styled(Select)(() => ({
  fontSize: '14px',
  '& .MuiOutlinedInput-input': {padding: '5px 12px'}
}));
const SmallCoreTextField = styled(CoreTextField)(() => ({
  '& .MuiInputBase-inputSizeSmall': {
    fontSize: '14px !important'
  }
}));
const IconBox = styled(Box)(({theme}) => ({
  height: '30px',
  width: '30px',
  display: 'grid',
  placeItems: 'center',
  backgroundColor: theme.palette.other.outlinedBorder,
  borderRadius: '4px'
}));

const EmbedSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {prototype = {}, currentEmbedMode} = useSelector(selectEmbedSettings);

  const {
    width,
    widthSuffix,
    height,
    heightSuffix,
    popUpSize = 'large',
    buttonText = 'Get Started',
    buttonStyle = 'contained',
    buttonSize = 'large',
    buttonColor = '#6258FF',
    icon = ''
  } = prototype[currentEmbedMode] || {};

  if (currentEmbedMode === EMBED_MODES.FULL_PAGE) return null;

  return (
    <>
      <Box sx={{bgcolor: 'background.paper', borderRadius: '4px'}} my={2} p={1.5}>
        <Typography variant="body1medium">Settings</Typography>
        <Stack direction="column" spacing={2} mb={3} mt={2}>
          {currentEmbedMode === EMBED_MODES.STANDARD && (
            <>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={1}
              >
                <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                  Width
                </Typography>
                <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{width: '150px'}}>
                  <SmallCoreTextField
                    fullWidth
                    type={'number'}
                    size="small"
                    value={width}
                    height={'30px'}
                    onChange={e => dispatch(setEmbedSettings({width: e.target.value}))}
                  />
                  <SmallSelect
                    size={'small'}
                    value={widthSuffix}
                    onChange={e => dispatch(setEmbedSettings({widthSuffix: e.target.value}))}
                  >
                    {EMBED_WIDTH_SUFFIX.map(width => (
                      <SmallMenuItem key={width.value} value={width.value}>
                        {width.title}
                      </SmallMenuItem>
                    ))}
                  </SmallSelect>
                </Stack>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={1}
              >
                <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                  Height
                </Typography>
                <Stack direction={'row'} spacing={1} sx={{width: '150px'}}>
                  <SmallCoreTextField
                    fullWidth
                    type={'number'}
                    size="small"
                    value={height}
                    height={'30px'}
                    onChange={e => dispatch(setEmbedSettings({height: e.target.value}))}
                  />
                  <SmallSelect
                    size={'small'}
                    value={heightSuffix}
                    onChange={e => dispatch(setEmbedSettings({heightSuffix: e.target.value}))}
                    sx={{
                      fontSize: '14px',
                      '& .MuiOutlinedInput-input': {padding: '3.5px 12px'}
                    }}
                  >
                    {EMBED_HEIGHT_SUFFIX.map(height => (
                      <SmallMenuItem key={height.value} value={height.value}>
                        {height.title}
                      </SmallMenuItem>
                    ))}
                  </SmallSelect>
                </Stack>
              </Stack>
            </>
          )}

          {currentEmbedMode === EMBED_MODES.POPUP && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={1.7}
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Pop-up size
              </Typography>

              <Stack direction={'row'} spacing={1} sx={{width: '150px'}}>
                <SmallSelect
                  fullWidth
                  size={'small'}
                  value={popUpSize}
                  onChange={e => dispatch(setEmbedSettings({popUpSize: e.target.value}))}
                >
                  {EMBED_POPUP_SIZES.map(height => (
                    <SmallMenuItem key={height.value} value={height.value}>
                      {height.title}
                    </SmallMenuItem>
                  ))}
                </SmallSelect>
              </Stack>
            </Stack>
          )}

          {(currentEmbedMode === EMBED_MODES.POPUP ||
            currentEmbedMode === EMBED_MODES.SLIDER ||
            currentEmbedMode === EMBED_MODES.SIDE_TAB) && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={1.7}
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Button Text
              </Typography>
              <Stack direction={'row'} spacing={1} sx={{width: '150px'}}>
                <SmallCoreTextField
                  fullWidth
                  type={'text'}
                  size="small"
                  height={'30px'}
                  value={buttonText}
                  onChange={e => dispatch(setEmbedSettings({buttonText: e.target.value}))}
                />
              </Stack>
            </Stack>
          )}

          {(currentEmbedMode === EMBED_MODES.POPUP || currentEmbedMode === EMBED_MODES.SLIDER) && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={1.7}
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Button Style
              </Typography>

              <Stack direction={'row'} spacing={1} sx={{width: '150px'}}>
                <SmallSelect
                  fullWidth
                  size={'small'}
                  value={buttonStyle}
                  onChange={e => dispatch(setEmbedSettings({buttonStyle: e.target.value}))}
                >
                  {EMBED_BUTTON_STYLES.map(height => (
                    <SmallMenuItem key={height.value} value={height.value}>
                      {height.title}
                    </SmallMenuItem>
                  ))}
                </SmallSelect>
              </Stack>
            </Stack>
          )}

          {(currentEmbedMode === EMBED_MODES.POPUP || currentEmbedMode === EMBED_MODES.SLIDER) && (
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={1.7}
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Button Size
              </Typography>

              <Stack direction={'row'} spacing={1} sx={{width: '150px'}}>
                <SmallSelect
                  fullWidth
                  size={'small'}
                  value={buttonSize}
                  onChange={e => dispatch(setEmbedSettings({buttonSize: e.target.value}))}
                >
                  {EMBED_BUTTON_SIZES.map(height => (
                    <SmallMenuItem key={height.value} value={height.value}>
                      {height.title}
                    </SmallMenuItem>
                  ))}
                </SmallSelect>
              </Stack>
            </Stack>
          )}

          {(currentEmbedMode === EMBED_MODES.POPUP ||
            currentEmbedMode === EMBED_MODES.SLIDER ||
            currentEmbedMode === EMBED_MODES.SIDE_TAB ||
            currentEmbedMode === EMBED_MODES.POPOVER) && (
            <Stack
              direction={'row'}
              justifyContent="space-between"
              spacing={1.7}
              alignItems="center"
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Button Color
              </Typography>
              <Box sx={{width: '100%'}}>
                <CustomColorPicker
                  color={buttonColor}
                  onChange={color =>
                    dispatch(
                      setEmbedSettings({
                        buttonColor:
                          color.rgb.a === 1
                            ? color.hex
                            : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                      })
                    )
                  }
                >
                  <LightCoreButton
                    size="small"
                    variant="outlined"
                    sx={{
                      border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
                      width: '100%',
                      color: theme.palette.text.primary,
                      '&.MuiButton-sizeSmall':{padding: '3px 10px',}
                    }}
                    startIcon={<ColorDropIcon fill={buttonColor} />}
                  >
                    {buttonColor?.toUpperCase().replace('RGBA(', '').replace(')', '')}
                  </LightCoreButton>
                </CustomColorPicker>
              </Box>
            </Stack>
          )}

          {(currentEmbedMode === EMBED_MODES.SIDE_TAB ||
            currentEmbedMode === EMBED_MODES.POPOVER) && (
            <Stack
              direction={'row'}
              justifyContent="space-between"
              alignItems="center"
              spacing={1.7}
            >
              <Typography variant={'body2'} sx={{minWidth: 'max-content'}}>
                Custom Icon
              </Typography>

              <Stack direction="row" alignItems={'center'} spacing={1}>
                <CustomImageUploadWrapper
                  onImageUpload={({image}) => dispatch(setEmbedSettings({icon: image}))}
                  maxFileSizeMB={2}
                >
                  <LightCoreButton size={'small'}>{icon ? 'Change' : 'Upload'}</LightCoreButton>
                </CustomImageUploadWrapper>
                {icon && (
                  <IconBox>
                    <CustomTooltip title="Reset Icon" placement="top">
                      <Restore
                        onClick={() => dispatch(setEmbedSettings({icon: ''}))}
                        sx={{cursor: 'pointer'}}
                      />
                    </CustomTooltip>
                  </IconBox>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Box>
      <Divider light />
    </>
  );
};
export default EmbedSettings;
