import React, {useEffect, useState} from 'react';
import {styled, Tabs, Tab, Box} from '@mui/material';
import PropTypes from 'prop-types';

const StyledBox = styled(Box)(({theme}) => ({
  '&.AnalyticsTabs': {
    width: '100%',
    '& .MuiTabs-flexContainer': {
      display: 'block'
    }
  },
  '& .MuiButtonBase-root:focus': {
    backgroundColor: 'inherit !important'
  },
  '&.fbpFormBuilderRight': {
    height: 'calc(100% - 42px)',
    backgroundColor: theme.palette.common.white,
    overflow: 'hidden',
    '& .builderSideTabPanel': {
      height: 'calc(100% - 48px)',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
}));
const StyledTabs = styled(Tabs)(({theme}) => ({
  '& .MuiTab-root': {
    fontSize: '14px'
  },
  '& .Mui-selected': {
    backgroundColor: 'inherit !important'
  },
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

export default function CustomTabs({children, className, selectedTab = 0, onChange = null, variant="fullWidth"}) {
  const [value, setValue] = useState(selectedTab);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (Array.isArray(children)) {
      let propTitles = [];
      let elementIndex = 0;
      children.forEach(elem => {
        if (elem.type?.name === CustomTab.name) {
          propTitles.push(
            <Tab
              className={className}
              key={elementIndex}
              label={elem.props.title}
              {...a11yProps(elementIndex)}
            />
          );
          elementIndex++;
        }

        setTitles(propTitles);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const getTabPanels = () => {
    let elementIndex = 0,
      elements = [];
    children.forEach(elem => {
      if (elem.type?.name === CustomTab.name) {
        elements.push(
          <TabPanel
            key={elementIndex}
            value={value}
            index={elementIndex}
            className={`${elem.props.className ?? ''} builderSideTabPanel`}
          >
            {elem.props.children}
          </TabPanel>
        );
        elementIndex++;
      }
    });

    return elements;
  };

  return (
    <StyledBox className={`${className ?? ''} fbpTabs`}>
      <StyledTabs
        className={className}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant={variant}
        aria-label="full width tabs example"
      >
        {titles}
      </StyledTabs>
      {getTabPanels()}
    </StyledBox>
  );
}

export const CustomTab = ({children}) => {
  return {children};
};

CustomTab.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};
