import React from 'react';
import {SvgIcon} from '@mui/material';

const TickIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z" fill="#546376" />
    </SvgIcon>
  );
};

export default TickIcon;
