import React, {cloneElement, useState} from 'react';
import {Popover} from '@mui/material';
import {SketchPicker} from 'react-color';
import Box from '@mui/material/Box';

export const CustomColorPicker = ({color, onChange, children}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <Box>
        {cloneElement(children, {
          onClick: e => setAnchorEl(e.currentTarget)
        })}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <SketchPicker color={color} onChange={onChange} />
      </Popover>
    </>
  );
};
