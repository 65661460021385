import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Refresh} from '@mui/icons-material';
import ButtonForIcon from '../../common/Button/ButtonForIcon';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {resetOnlyFormFilter} from '../../state/features/folderForms/folderFormsSlice';
import {selectFormFilters} from '../../state/features/folderForms/folderFormSelector';

const FormResetFilter = () => {
  const dispatch = useDispatch();
  const {searchText, startDate, endDate, orderBy} = useSelector(selectFormFilters);

  const handleReset = () => {
    dispatch(resetOnlyFormFilter());
  };

  const isShowResetButton = searchText || startDate || endDate || orderBy !== '';

  if (!isShowResetButton) {
    return null;
  }

  return (
    <CustomTooltip arrow placement="bottom" title="Reset Filter">
      <ButtonForIcon
        variant="outlined"
        size={'large'}
        color={'secondary'}
        startIcon={<Refresh />}
        onClick={handleReset}
        className="reset"
      />
    </CustomTooltip>
  );
};

export default FormResetFilter;
