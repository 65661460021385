import React from 'react';
import {useSelector} from 'react-redux';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideColorOption from '../../Builder/RightComponents/RightSideColorOption';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const colors = [
  '#9487ff',
  '#4941c7',
  '#0071ff',
  '#d60033',
  '#e16a01',
  '#8d00c3',
  '#0097c1',
  '#635d5d',
  '#ff3987',
  '#7bcc00'
];

const backgroundColors = [
  '#D2CCFF',
  '#6258FF',
  '#006DF5',
  '#ED0039',
  '#F2994A',
  '#A352C2',
  '#73C6DD',
  '#757575',
  '#F780AF',
  '#A5CD68'
];

const ProgressbarRightPanel = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <>
      {theme.layout === 'classic' && (
        <RightSideDropdown
          text="View format"
          title="Change the style of the progress bar"
          data={[
            {title: 'Style 1', value: '1'},
            {title: 'Style 2', value: '2'},
            {title: 'Style 3', value: '3'},
            {title: 'Style 4', value: '4'}
          ]}
          value={field.view}
          onChange={e => onChangeValue({view: e.target.value})}
        />
      )}

      <RightSideColorOption
        colors={colors}
        text="Progress bar color"
        title="Change the progress bar color"
        color={field.color}
        onChange={value => onChangeValue({color: value})}
        onClear={() => onChangeValue({color: colors[1]})}
      />

      {field.view !== '2' && (
        <RightSideColorOption
          colors={backgroundColors}
          text="Background Color"
          title="Change the background color of the progress bar"
          color={field.backgroundColor}
          onChange={value => onChangeValue({backgroundColor: value})}
          onClear={() => onChangeValue({backgroundColor: backgroundColors[0]})}
        />
      )}

      {theme.layout === 'classic' && (
        <>
          <RightSideSwitch
            text="Sticky on top"
            title="Sticky or nonsticky on the public or preview pages"
            checked={field.sticky}
            onChange={value => onChangeValue({sticky: value})}
          />

          <RightSideSwitch
            text="Calculation by fields"
            title="The percentage is calculated by fields if the option is turned on, and by pages if it is off."
            checked={field.calculationByFields}
            onChange={value => onChangeValue({calculationByFields: value})}
          />
        </>
      )}
    </>
  );
};

export default withFieldPropertyChange(ProgressbarRightPanel);
