import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import Box from '@mui/material/Box';
import {Send} from '@mui/icons-material';
import {Grid, Stack, Typography} from '@mui/material';
import CustomEditor from '../../common/Editor/CustomEditor';
import LoadingButton from '../../common/Button/LoadingButton';
import CoreTextField from '../../common/TextField/CoreTextField';
import {registerPatternEmailShare} from '../../helpers/constant/registerPattern';
import {resetSendEmailSuccess, sendEmail} from '../../state/features/emailShare/emailShareSlice';

const EmailShareForm = () => {
  const dispatch = useDispatch();
  const {isLoading, isSuccess} = useSelector(state => state.emailShare);
  const [content, setContent] = useState('');

  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      dispatch(resetSendEmailSuccess());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onChangeMailContent = value => {
    setContent(value);
  };

  const resetForm = () => {
    setValue('senderName', '');
    setValue('from', '');
    setValue('to', '');
    setValue('subject', '');
    setContent('');
  };

  // ## Form Submit Action
  const onSubmit = data => {
    data.content = content;
    dispatch(sendEmail(data));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="senderName"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Name
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  color="secondary"
                  placeholder={'Write here '}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="from"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  From *
                </Typography>
                <CoreTextField
                  fullWidth
                  type={'email'}
                  size={'small'}
                  color="secondary"
                  placeholder={'Email address'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternEmailShare.fromEmail}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="to"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  To *
                </Typography>
                <CoreTextField
                  fullWidth
                  type={'email'}
                  size={'small'}
                  color="secondary"
                  placeholder={'Email address'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternEmailShare.toEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Subject *
                </Typography>
                <CoreTextField
                  fullWidth
                  type={'text'}
                  size={'small'}
                  color="secondary"
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternEmailShare.subject}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
            Email Content
          </Typography>
          <CustomEditor body={content} onChangeBody={onChangeMailContent} />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" mt={2}>
        <LoadingButton
          isLoading={isLoading}
          loadingText={'Sending...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
          endIcon={<Send />}
        >
          Send Email
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default EmailShareForm;
