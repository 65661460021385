import httpRequest from '../api/httpRequest';

class ExportedFileService {
  getExportedFiles(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'export/list', params);
  }

  deletedExportedFile(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'export/remove',
      {},
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ExportedFileService();
