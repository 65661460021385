import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {format} from 'date-fns';
import {
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme
} from '@mui/material';
import {
  Delete,
  DeleteForever,
  DriveFileMove,
  Edit,
  MoreVert,
  PublishedWithChanges,
  RestoreFromTrash,
  Settings,
  Unpublished,
  Visibility
} from '@mui/icons-material';
import CloneIcon from '../../common/Icons/CloneIcon';
import TableLoaderSkeleton from '../LoadingView/TableLoaderSkeleton';
import AddOrMoveTemplateToSubCategory from './AddOrMoveTemplateToSubCategory';
import DeleteWarningModal from '../Builder/Modals/DeleteWarningModal';
import RenameTemplateContent from './RenameTemplateContent';
import GlobalTablePagination from '../../common/Pagination/GlobalTablePagination';
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem
} from '../../common/PopoverMenu/CustomPopoverMenu';
import {EmptySubmissionFormIcon} from '../../common/Icons/EmptyDataIcons';
import GlobalEmptyPage from '../../common/GlobalEmptyPage/GlobalEmptyPage';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import RouteLink from '../../common/Link/RouteLink';
import RenameIcon from '../../common/Icons/RenameIcon';
import useBoolean from '../../hooks/useBoolean';
import removeFalsyObjectProperty from '../../helpers/utils/removeFalsyObjectProperty';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import {BUILDER_OPTIONS, MODULE_NAMES, TRASH_TYPES} from '../../helpers/constant/CoreConstants';
import {castArray} from '../../helpers/utils/castArray';
import {
  selectSelectedTemplateIds,
  selectTemplateFilters,
  selectTemplates
} from '../../state/features/adminTemplates/adminTemplatesSelector';
import {
  cloneTemplate,
  deselectAllTemplate,
  getAdminTemplates,
  getAdminTemplatesSilently,
  moveToTrashTemplate,
  removeForeverAdminTemplate,
  resetTemplateFilters,
  restoreAdminTemplate,
  selectedAllTemplate,
  togglePublishUnPublishTemplate,
  toggleSelectedSingleTemplate
} from '../../state/features/adminTemplates/adminTemplatesSlice';

const TemplatesWrapper = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('tab')]: {
    height: 'calc(100vh - 410px)'
  }
}));

const getHeaderCells = isTrash => {
  return [
    {
      label: 'Templates',
      disablePadding: true,
      size: 'medium'
    },
    {
      label: 'Category / Sub-category',
      disablePadding: false,
      size: 'medium'
    },
    {
      label: isTrash ? 'Updated Date' : 'Created Date',
      disablePadding: false,
      size: 'medium'
    },
    {
      label: 'Status',
      disablePadding: false,
      size: 'medium'
    },
    {
      align: 'center',
      label: 'Action',
      disablePadding: false,
      size: 'small'
    }
  ];
};

// Table Head
function EnhancedTableHead({
  onSelectAllClick,
  onDeselectAllClick,
  checkedAll,
  numSelected,
  isTrash
}) {
  const theme = useTheme();

  const handleClick = () => {
    if (checkedAll) {
      if (onDeselectAllClick) onDeselectAllClick();
    } else {
      if (onSelectAllClick) onSelectAllClick();
    }
  };

  const headerCells = getHeaderCells(isTrash);

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: theme.palette?.other.outlinedBorder}}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={!checkedAll && numSelected > 0}
            checked={numSelected > 0 && checkedAll}
            onClick={handleClick}
          />
        </TableCell>

        {headerCells.map(headCell => (
          <TableCell
            align={headCell.align}
            key={headCell.label}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            size={headCell.size}
          >
            <TableSortLabel hideSortIcon={true}>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function AdminTemplateTable() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {data: templates, isLoading, page, limit, count, renderId} = useSelector(selectTemplates);
  const selectedTemplateIds = useSelector(selectSelectedTemplateIds);
  const {subcategoryId, searchText, status} = useSelector(selectTemplateFilters);
  const [selectedTemplateInfo, setSelectedTemplateInfo] = useState(null);
  const {
    value: isOpenMoveTemplate,
    setTrue: handleOpenMoveTemplate,
    setFalse: handleCloseMoveTemplate
  } = useBoolean();
  const {
    value: isOpenAddTemplate,
    // setTrue: handleOpenAddTemplate,
    setFalse: handleCloseAddTemplate
  } = useBoolean();
  const {
    value: isOpenDeleteModal,
    setTrue: onOpenDeleteModal,
    setFalse: onCloseDeleteModal
  } = useBoolean();
  const {
    value: isOpenRenameModal,
    setTrue: onOpenRenameModal,
    setFalse: onCloseRenameModal
  } = useBoolean();

  useEffect(() => {
    const reqObj = removeFalsyObjectProperty({
      subcategoryId,
      searchText,
      status: status !== null ? [status] : status,
      page: renderId ? page : 1,
      limit
    });

    if (renderId) {
      dispatch(getAdminTemplatesSilently(reqObj));
    } else dispatch(getAdminTemplates(reqObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subcategoryId, searchText, status, renderId]);

  useEffect(() => {
    return () => dispatch(resetTemplateFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePerPage = (perPage, newPage) => {
    const reqObj = removeFalsyObjectProperty({
      subcategoryId,
      searchText,
      status: status !== null ? castArray(status) : status,
      page: newPage,
      limit: perPage
    });
    dispatch(getAdminTemplates(reqObj));
  };

  const onChangePage = newPage => {
    const reqObj = removeFalsyObjectProperty({
      subcategoryId,
      searchText,
      status: status !== null ? castArray(status) : status,
      page: newPage,
      limit
    });
    dispatch(getAdminTemplates(reqObj));
  };

  const handleSelectAll = () => {
    dispatch(selectedAllTemplate());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllTemplate());
  };

  const handleTemplateSelect = (event, id) => {
    dispatch(toggleSelectedSingleTemplate(id));
  };

  const handleTemplateMenu = templateInfo => {
    setSelectedTemplateInfo(templateInfo);
  };

  const handleGoTemplateBuilder = templateId => {
    history.push(`/fbuilder/admin/template/${templateId}`);
  };

  const handleGoPreviewPage = templateId => {
    history.push('/fbuilder/admin/template/' + templateId, {
      tab: BUILDER_OPTIONS.PREVIEW
    });
  };

  const handleGoPublishSettings = templateId => {
    history.push('/fbuilder/admin/template/' + templateId, {
      tab: BUILDER_OPTIONS.FORM_SETTING
    });
  };

  const handleRenameForm = templateInfo => {
    setSelectedTemplateInfo(templateInfo);
    onOpenRenameModal();
  };

  const handlePublishAndUnPublishForm = row => {
    const hasPublish = row.status === FORM_STATUS.PUBLISHED;

    dispatch(
      togglePublishUnPublishTemplate({
        templateId: row.id,
        willPublish: !hasPublish
      })
    );
  };
  const handleTemplateClone = row => {
    dispatch(cloneTemplate({formId: row.id}));
  };
  const handleTemplateMoveToTrash = row => {
    dispatch(moveToTrashTemplate({ids: castArray(row.id)}));
  };
  const handleMoveTemplateToSubCategory = row => {
    handleOpenMoveTemplate();
    setSelectedTemplateInfo(row);
  };
  // const handleAddTemplateToSubCategory = row => {
  //   handleOpenAddTemplate();
  //   setSelectedTemplateInfo(row);
  // };

  const handleRestoreTemplate = row => {
    dispatch(restoreAdminTemplate({ids: castArray(row.id)}));
  };

  // ## handle Delete forever
  const handleDeleteForever = row => {
    onOpenDeleteModal();
    setSelectedTemplateInfo(row);
  };

  const onConfirmDeleteForever = () => {
    dispatch(
      removeForeverAdminTemplate({
        ids: castArray(selectedTemplateInfo.id),
        module: MODULE_NAMES.ADMIN_FORM_TEMPLATE,
        trashType: TRASH_TYPES.HARD
      })
    );
    onCloseDeleteModal();
  };

  if (isLoading) {
    return <TableLoaderSkeleton numberOfRows={10} columns={5} />;
  }
  const checkedAll = templates.every(tData => selectedTemplateIds.includes(tData.id));
  const isTrash = status === FORM_STATUS.TRASH;
  return (
    <Box sx={{width: '100%', position: 'relative', height: 'calc(100% - 60px)'}}>
      <Box sx={{width: '100%', height: 'calc(100% - 30px)'}}>
        <TemplatesWrapper className="fbp_has_scroll">
          <TableContainer sx={{width: '100%'}}>
            <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size={'medium'}>
              <EnhancedTableHead
                onSelectAllClick={handleSelectAll}
                onDeselectAllClick={handleDeselectAll}
                checkedAll={checkedAll}
                numSelected={selectedTemplateIds?.length}
                sortable={false}
                isTrash={isTrash}
              />
              <TableBody>
                {templates.map((row, index) => {
                  const isItemSelected = selectedTemplateIds.indexOf(row.id) !== -1;
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const formatCreatedAt = row.createdAt
                    ? format(new Date(row.createdAt), 'dd MMM yyyy')
                    : 'N/A';
                  const formatUpdatedAt = row.updatedAt
                    ? format(new Date(row.updatedAt), 'dd MMM yyyy')
                    : 'N/A';

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      role="checkbox"
                      tabIndex={-1}
                      selected={isItemSelected || selectedTemplateInfo?.id === row.id}
                      aria-checked={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={event => handleTemplateSelect(event, row.id)}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <RouteLink to={`/fbuilder/admin/template/${row.id}`}>
                          {row.formTitle || 'N/A'}
                        </RouteLink>
                      </TableCell>
                      <TableCell align="left">
                        {row.categoryName ? row.categoryName + ' / ' : ''}
                        {row.subCategoryName ? row.subCategoryName : '-'}
                      </TableCell>
                      <TableCell align="left">
                        {isTrash ? formatUpdatedAt : formatCreatedAt}
                      </TableCell>
                      <TableCell align="left">
                        {FORM_STATUS.PUBLISHED === row.status ? (
                          <Typography color="text.primary">Published</Typography>
                        ) : (
                          <Typography color="text.secondary">Unpublished</Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {status !== FORM_STATUS.TRASH && (
                          <CustomPopoverMenu
                            wrapperClass="formListMoreOptions"
                            menuClass="fbpTwoColumns"
                            sx={{display: 'grid', gridTemplateColumns: '1fr 48%'}}
                            callbackOnClose={() => handleTemplateMenu(null)}
                          >
                            <CustomPopoverMenuButton>
                              <MoreVert
                                color={'action'}
                                sx={{cursor: 'pointer'}}
                                onClick={() => handleTemplateMenu(row)}
                              />
                            </CustomPopoverMenuButton>
                            <CustomPopoverMenuItem
                              title="Preview"
                              icon={<Visibility color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleGoPreviewPage(row.id)}
                            />
                            <CustomPopoverMenuItem
                              title={row.status === FORM_STATUS.PAUSED ? 'Publish' : 'Unpublish'}
                              icon={
                                row.status === FORM_STATUS.PAUSED ? (
                                  <PublishedWithChanges color={'action'} />
                                ) : (
                                  <Unpublished color={'action'} />
                                )
                              }
                              onClickHide={true}
                              onClick={() => handlePublishAndUnPublishForm(row)}
                            />
                            <CustomPopoverMenuItem
                              title="Edit"
                              icon={<Edit color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleGoTemplateBuilder(row.id)}
                            />
                            <CustomPopoverMenuItem
                              title="Publish Settings"
                              icon={<Settings color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleGoPublishSettings(row.id)}
                            />
                            <CustomPopoverMenuItem
                              title="Rename"
                              icon={<RenameIcon color={'action'} />}
                              onClick={() => handleRenameForm(row)}
                              onClickHide
                            />
                            <CustomPopoverMenuItem
                              title="Duplicate"
                              icon={<CloneIcon color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleTemplateClone(row)}
                            />
                            <CustomPopoverMenuItem
                              title="Delete"
                              icon={<Delete color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleTemplateMoveToTrash(row)}
                            />
                            <CustomPopoverMenuItem
                              title="Move"
                              icon={<DriveFileMove color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleMoveTemplateToSubCategory(row)}
                            />

                            {/*<CustomPopoverMenuItem*/}
                            {/*  title="Copy to a sub-category"*/}
                            {/*  icon={<CreateNewFolder color={'action'} />}*/}
                            {/*  onClickHide={true}*/}
                            {/*  onClick={() => handleAddTemplateToSubCategory(row)}*/}
                            {/*/>*/}
                          </CustomPopoverMenu>
                        )}

                        {/*  ## Actions For Trash List ## */}
                        {status === FORM_STATUS.TRASH && (
                          <CustomPopoverMenu
                            wrapperClass="formListMoreOptions"
                            callbackOnClose={() => handleTemplateMenu(null)}
                          >
                            <CustomPopoverMenuButton>
                              <MoreVert
                                color={'action'}
                                sx={{cursor: 'pointer'}}
                                onClick={() => handleTemplateMenu(row)}
                              />
                            </CustomPopoverMenuButton>

                            <CustomPopoverMenuItem
                              title="Restore"
                              icon={<RestoreFromTrash color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleRestoreTemplate(row)}
                            />
                            <CustomPopoverMenuItem
                              title="Delete Forever"
                              icon={<DeleteForever color={'action'} />}
                              onClickHide={true}
                              onClick={() => handleDeleteForever(row)}
                            />
                          </CustomPopoverMenu>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {templates.length === 0 && (
              <Box sx={{minHeight: '55vh', display: 'grid', placeItems: 'center'}}>
                <GlobalEmptyPage
                  icon={<EmptySubmissionFormIcon />}
                  title={'No template form found'}
                  description={'No corresponding template form found'}
                />
              </Box>
            )}
          </TableContainer>
        </TemplatesWrapper>
      </Box>
      <GlobalTablePagination
        count={count}
        page={page}
        rowsPerPage={limit}
        onChangePage={onChangePage}
        onChangePerPage={onChangePerPage}
      />
      <BasicModalWithHeader
        modalTitle={'Move Template'}
        open={isOpenMoveTemplate}
        onClose={handleCloseMoveTemplate}
      >
        <AddOrMoveTemplateToSubCategory
          onClose={handleCloseMoveTemplate}
          templateInfo={selectedTemplateInfo}
        />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        modalTitle={'Copy Template'}
        open={isOpenAddTemplate}
        onClose={handleCloseAddTemplate}
      >
        <AddOrMoveTemplateToSubCategory
          onClose={handleCloseAddTemplate}
          templateInfo={selectedTemplateInfo}
          isAdd={true}
        />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        titleIcon={<RenameIcon color={'action'} />}
        modalTitle={'Rename Template'}
        open={isOpenRenameModal}
        onClose={onCloseRenameModal}
      >
        <RenameTemplateContent templateInfo={selectedTemplateInfo} onClose={onCloseRenameModal} />
      </BasicModalWithHeader>

      <DeleteWarningModal
        title={'Delete Template'}
        warningSubtitle={'Are you sure, want to delete this template?'}
        warningContent={`All your templates data to this form will be gone forever. This operation cannot be undone.`}
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete'}
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDeleteForever}
      />
    </Box>
  );
}
