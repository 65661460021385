import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack} from '@mui/material';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideInlineCheckbox from '../../Builder/RightComponents/RightSideInlineCheckbox';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import {getStructuredClone} from '../../../helpers/utils/getStructuredClone';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const CheckboxAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  const handleChangeCheckbox = (index, value) => {
    let options = getStructuredClone(field.options);
    options[index].checked = value;
    onChangeValue({options});
  };

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Stack direction="column" sx={{gap: '10px'}} className="builderElementBasicStyle">
        <RightComponentTitle
          text="Selected by default"
          title="Selected by default on form"
          className="mb-3"
        />
        {field.options.map((option, index) => (
          <RightSideInlineCheckbox
            key={option.value}
            label={option.title}
            checked={option.checked === true}
            onChange={value => handleChangeCheckbox(index, value)}
          />
        ))}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}

      <RightSideSwitch
        text="Read Only"
        title="Set the input as read only"
        checked={field.readOnly}
        onChange={value => onChangeValue({readOnly: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(CheckboxAdvanced);
