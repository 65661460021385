import httpRequest from '../api/httpRequest';

class PipelineService {
  getPipelineData(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'pipelines/list', params);
  }

  getPipelineStageData(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'pipelines/stages', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PipelineService();
