import React from 'react';
import {Box, Stack, styled, Typography} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';

const StyledBoxTabWithTitle = styled(Box)(({theme}) => ({
  width: ' 34%',
  height: '36px',
  background: theme.palette.other.gray,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& span': {
    display: 'inline-flex'
  },
  '& svg': {
    path: {
      fill: theme.palette.text.secondary
    }
  },
  '&.selected': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.common.white
      }
    }
  },
  '&.hover': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    svg: {
      path: {
        fill: theme.palette.common.white
      }
    }
  }
}));

const ThreeTabsWithTitle = ({
  child1,
  child2,
  child3,
  value1,
  value2,
  value3,
  selectedValue,
  onChange,
  text,
  title
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '10px',
        position: 'relative'
      }}
      className="builderElementBasicStyle"
    >
      <RightComponentTitle text={text} title={title} />
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          width: '100%',
          borderRadius: '4px',
          overflow: 'hidden'
        }}
      >
        <StyledBoxTabWithTitle
          onClick={() => onChange(value1)}
          className={value1 === selectedValue ? 'selected' : ''}
        >
          <Typography variant="body2medium">{child1}</Typography>
        </StyledBoxTabWithTitle>
        <StyledBoxTabWithTitle
          onClick={() => onChange(value2)}
          className={value2 === selectedValue ? 'selected' : ''}
        >
          <Typography variant="body2medium">{child2}</Typography>
        </StyledBoxTabWithTitle>
        <StyledBoxTabWithTitle
          onClick={() => onChange(value3)}
          className={value3 === selectedValue ? 'selected' : ''}
        >
          <Typography variant="body2medium">{child3}</Typography>
        </StyledBoxTabWithTitle>
      </Stack>
    </Box>
  );
};

export default ThreeTabsWithTitle;
