import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MoreVert} from '@mui/icons-material';
import {IconButton, Box, Typography} from '@mui/material';
import AddTagPopover from './AddTagPopover';
import AddTagColorBox from './AddTagColorBox';
import ColorDropIcon from '../../../common/Icons/ColorDropIcon';
import PencilEditIcon from '../../../common/Icons/PencilEditIcon';
import TickIcon from '../../../common/Icons/TickIcon';
import TrashIcon from '../../../common/Icons/TrashIcon';
import DeleteWarningModal from '../../Builder/Modals/DeleteWarningModal';
import ExtraSmallTextField from '../../../common/TextField/ExtraSmallTextField';
import compressString from '../../../helpers/utils/compressString';
import {castArray} from '../../../helpers/utils/castArray';
import {deleteTag, updateTag} from '../../../state/features/tags/tagsSlice';
import {
  selectSelectedSubmissionId,
  selectSelectedSubmissionInboxTags
} from '../../../state/features/formSubmission/formSubmissionSelector';
import {
  addTagOnSubmission,
  removeTagFromSubmission
} from '../../../state/features/formSubmission/formSubmissionSlice';
import Styles from './AddTag.module.scss';

const TagCard = ({tag}) => {
  const dispatch = useDispatch();
  const textInput = useRef(null);
  const tagIds = useSelector(selectSelectedSubmissionInboxTags);
  const submissionFormId = useSelector(selectSelectedSubmissionId);
  const {id, name, color} = tag || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenAddEditView, setOpenAddEditView] = useState(true);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEditableMode, setEditableMode] = useState(false);

  const handleOpenColorPallet = event => {
    setAnchorEl(event.currentTarget);
    setOpenAddEditView(true);
  };

  const handleOpenAddEditView = event => {
    setAnchorEl(event.currentTarget);
    setOpenAddEditView(false);
  };
  const handleCloseMenu = () => setAnchorEl(null);

  const onChangeColor = color => {
    dispatch(updateTag({tagId: id, color: color, name: name}));
  };

  // ## handle Delete
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDelete = () => {
    handleOpenDeleteModal();
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteTag({ids: castArray(id)}));
    handleCloseDeleteModal();
    handleCloseMenu();
  };

  // ## handle Edit
  const handleEditTag = () => {
    setEditableMode(true);
    handleCloseMenu();
  };

  useEffect(() => {
    if (isEditableMode) {
      textInput.current.focus();
      textInput.current.select();
    }
  }, [isEditableMode]);

  const handleEditTagSave = e => {
    e.preventDefault();
    setEditableMode(false);
    const newTagName = textInput.current.value?.trim();
    if (newTagName !== '' && newTagName !== name) {
      dispatch(updateTag({tagId: id, color: color, name: newTagName}));
    }
  };

  // handle tag add or remove on submission
  const handleToggleAddTagOnInbox = hasTagAssign => {
    if (hasTagAssign) {
      dispatch(
        removeTagFromSubmission({
          submissionIds: castArray(submissionFormId),
          tagIds: castArray(id)
        })
      );
    } else {
      dispatch(
        addTagOnSubmission({
          submissionIds: castArray(submissionFormId),
          tagIds: castArray(id)
        })
      );
    }
  };

  const hasTagAssign = tagIds?.includes(id);
  return (
    <li style={{display: 'flex', alignItems: 'center'}} className={Styles.singleTag}>
      {isEditableMode ? (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleEditTagSave}>
          <ExtraSmallTextField
            inputRef={textInput}
            type={'text'}
            size={'small'}
            defaultValue={name}
            className={'addTagInput'}
            onBlur={handleEditTag}
          />
        </Box>
      ) : (
        <Box
          className={Styles.tagName}
          sx={{background: color}}
          onClick={() => handleToggleAddTagOnInbox(hasTagAssign)}
        >
          <Typography variant={'body2'}>{compressString(name, 15)}</Typography>
          {hasTagAssign && <TickIcon size={'small'} color={'action'} />}
        </Box>
      )}

      <IconButton size={'small'} onClick={handleOpenAddEditView}>
        <ColorDropIcon sx={{color: color}} />
      </IconButton>
      <IconButton size={'small'} onClick={handleOpenColorPallet}>
        <MoreVert color={'action'} />
      </IconButton>

      <AddTagPopover id={'addTagListEditDelete'} anchorEl={anchorEl} handleClose={handleCloseMenu}>
        {isOpenAddEditView ? (
          <ul className={Styles.tagAddDeleteBox}>
            <li
              className={`${Styles.tagAddDeleteItem} fbp-typo-body2-regular`}
              onClick={handleEditTag}
            >
              <PencilEditIcon size={'small'} color={'action'} sx={{mr: 1, fontSize: '16px'}} /> Edit
            </li>
            <li
              className={`${Styles.tagAddDeleteItem} fbp-typo-body2-regular`}
              onClick={handleDelete}
            >
              <TrashIcon size={'small'} color={'action'} sx={{mr: 1, fontSize: '16px'}} /> Delete
            </li>
          </ul>
        ) : (
          <AddTagColorBox color={color} onChange={onChangeColor} />
        )}
      </AddTagPopover>

      <DeleteWarningModal
        title={'Delete Tag'}
        warningSubtitle={'Are you sure you want to permanently delete the selected tag?'}
        warningContent={
          'Current tag will be deleted from the tag list but not from the assigned submissions.This action cannot be undone!'
        }
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete Forever'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
    </li>
  );
};

export default TagCard;
