import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Divider, InputAdornment, Paper, Stack} from '@mui/material';
import {ContentCopy, LibraryAddCheck, OpenInNew} from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import CoreButton from '../../../common/Button/CoreButton';
import CoreTextField from '../../../common/TextField/CoreTextField';
import HrefLink from '../../../common/Link/HrefLink';
import generatePublicPageUrl from '../../../helpers/utils/generatePublicPageUrl';
import copyFromClipBoard from '../../../helpers/utils/copyFromClipBoard';
import {selectBuilderFormApiKey} from '../../../state/features/builderSetting/builderSettingSelector';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';

const QuickShareLink = () => {
  const formApiKey = useSelector(selectBuilderFormApiKey);
  const [copied, setCopied] = useState(false);

  const handleCopyLink = link => {
    copyFromClipBoard(link);
    setCopied(true);
  };

  const publicPageLink = generatePublicPageUrl(formApiKey);

  return (
    <Paper elevation={0}>
      <BasicTitleBar
        title={'Share Publicly'}
        content={'Share your form as a public link or privately within your target people.'}
      />
      <Divider />
      <Stack direction="column" spacing={2} px={4} py={3}>
        <CoreTextField
          fullWidth
          size={'small'}
          type={'text'}
          color={'secondary'}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{mr: 0}} position="start">
                <LinkIcon />
              </InputAdornment>
            )
          }}
          value={publicPageLink}
        />
        <Stack
          direction="row"
          alignItems="center"
          gap={'10px'}
          sx={{mb: '3px'}}
          justifyContent={'flex-end'}
        >
          <HrefLink href={publicPageLink} target={'_blank'}>
            <CoreButton size="small" variant="outlined" color="secondary" startIcon={<OpenInNew />}>
              Open new tab
            </CoreButton>
          </HrefLink>

          <CoreButton
            size="small"
            variant="contained"
            color="secondary"
            startIcon={copied ? <LibraryAddCheck /> : <ContentCopy />}
            onClick={() => handleCopyLink(publicPageLink)}
          >
            {copied ? 'Copied Link' : 'Copy Link'}
          </CoreButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default QuickShareLink;
