import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import Prototype from '../../../helpers/builderConstant/FieldPrototypes';

const initialState = {
  fields: [],
  selectedComponentType: null,
  selectedIndex: null,
  selectedPageIndex: null,
  fieldsIdMap: {},
  fieldsCount: {}
};

const builderFieldsSlice = createSlice({
  name: 'builderFields',
  initialState: initialState,
  reducers: {
    storeBuilderFields: (state, action) => {
      state.fieldsCount = {};
      const clonnedFields = [...action.payload];

      clonnedFields.forEach((eachPage, eachPageIndex) => {
        //Adding fields element id to id map
        eachPage.pageItems.forEach((eachField, eachFieldIndex) => {
          state.fieldsIdMap[eachField.id] = eachField.elementId;
          state.fieldsCount[eachField.component] = state.fieldsCount[eachField.component] + 1 || 1;

          //Clonning to concat new prototype data.
          clonnedFields[eachPageIndex].pageItems[eachFieldIndex] = {
            ...Prototype[eachField.component],
            ...eachField
          };
        });
      });

      state.fields = clonnedFields;
    },
    addBuilderField: (state, action) => {},
    storeAddBuilderField: (state, action) => {
      state.fields[action.payload.pageIndex].pageItems.splice(
        action.payload.index,
        0,
        action.payload.component
      );
      state.selectedIndex = action.payload.index;
      state.selectedPageIndex = action.payload.pageIndex;

      state.fieldsCount[action.payload.component.component] =
        state.fieldsCount[action.payload.component.component] + 1 || 1;
    },
    changeSelectedBuilderField: (state, action) => {
      if (action.payload === null) {
        state.selectedIndex = null;
        state.selectedPageIndex = null;
        state.selectedComponentType = null;
        return;
      }

      state.selectedIndex = action.payload.index;
      state.selectedPageIndex = action.payload.pageIndex;
      state.selectedComponentType = action.payload.componentType;
    },
    updateBuilderField: (state, action) => {},
    storeUpdatedBuilderField: (state, action) => {
      state.fields[state.selectedPageIndex].pageItems[state.selectedIndex] = {
        ...action.payload
      };
    },
    deleteFieldFromSelectedIndex: (state, action) => {},
    deleteFieldSuccess: (state, action) => {
      if (state.selectedIndex !== null) {
        let deletedComponent =
          state.fields[state.selectedPageIndex].pageItems[state.selectedIndex].component;
        state.fieldsCount[deletedComponent]--;
        state.fields[state.selectedPageIndex].pageItems.splice(state.selectedIndex, 1);
      }
      state.selectedIndex = null;
      state.selectedPageIndex = null;
    },
    cloneFieldFormSelectedIndex: (state, action) => {},
    cloneFieldSuccess: (state, action) => {
      if (state.selectedIndex !== null) {
        let clonnedField = {
          ...state.fields[state.selectedPageIndex].pageItems[state.selectedIndex]
        };
        clonnedField.id = action.payload.id;
        state.fields[state.selectedPageIndex].pageItems.splice(
          state.selectedIndex + 1,
          0,
          clonnedField
        );
        state.selectedIndex++;
      }
    },
    reorderBuilderFields: (state, action) => {},
    reorderBuilderFieldsSuccess: (state, action) => {
      //Copying the source field.
      let orderField = {
        ...state.fields[action.payload.sourcePageIndex].pageItems[action.payload.sourceIndex]
      };

      //Restricting if button moving to another page.
      if (
        orderField.component === COMPONENT_TYPE.SUBMIT_BUTTON &&
        action.payload.sourcePageIndex !== action.payload.destinationPageIndex
      ) {
        return;
      }

      //Picking the current selected id
      let selectedId = state.fields[state.selectedPageIndex]?.pageItems[state.selectedIndex]?.id;

      state.fields[action.payload.sourcePageIndex].pageItems.splice(action.payload.sourceIndex, 1);
      state.fields[action.payload.destinationPageIndex].pageItems.splice(
        action.payload.destinationIndex,
        0,
        orderField
      );
      if (selectedId) {
        state.selectedIndex = state.fields[action.payload.destinationPageIndex].pageItems.findIndex(
          field => field.id === selectedId
        );
        state.selectedPageIndex = action.payload.destinationPageIndex;
      }
    },
    reorderCardFields: (state, action) => {},
    reorderCardFieldsSuccess: (state, action) => {
      let {sourceIndex, sourcePageIndex, destinationIndex, destinationPageIndex} = action.payload;

      //Copying the source field.
      let orderField = {
        ...state.fields[sourcePageIndex].pageItems[sourceIndex]
      };

      let selectedId = state.fields[state.selectedPageIndex]?.pageItems[state.selectedIndex]?.id;
      state.fields[sourcePageIndex].pageItems.splice(sourceIndex, 1);

      state.fields[destinationPageIndex].pageItems.splice(destinationIndex, 0, orderField);

      if (selectedId) {
        state.selectedIndex = state.fields[destinationPageIndex].pageItems.findIndex(
          field => field.id === selectedId
        );
        state.selectedPageIndex = destinationPageIndex;
      }
    },
    updateAllFields: (state, action) => {},
    storeUpdateAllFields: (state, action) => {
      state.fields.forEach(eachFolder => {
        eachFolder.pageItems.forEach(field => {
          if (field[action.payload.type] !== undefined) {
            field[action.payload.type] = action.payload.value;
          }
        });
      });
    },
    addNewPage: (state, action) => {},
    addNewPageSuccess: (state, action) => {
      let pageId = nanoid();
      state.fields.push({
        pageId: pageId,
        pageItems: [action.payload.newItem]
      });
      state.selectedIndex = 0;
      state.selectedPageIndex = state.fields.length - 1;
      setTimeout(() => {
        document.getElementById('page-container-' + pageId)?.scrollIntoView({
          behavior: 'smooth'
        });
      }, 200);
    },
    deletePage: (state, action) => {
      state.fields.splice(action.payload, 1);
      state.selectedIndex = 0;
      state.selectedPageIndex = state.fields.length - 1;
    },
    reorderPage: (state, action) => {
      state.selectedIndex = null;
      state.selectedPageIndex = null;

      let sourcePage = {...state.fields[action.payload.sourceIndex]};
      if (sourcePage) {
        state.fields.splice(action.payload.sourceIndex, 1);
        state.fields.splice(action.payload.destinationIndex, 0, sourcePage);
      }
    },
    updateIdMap: (state, action) => {
      state.fieldsIdMap[action.payload.id] = action.payload.elementId;
    }
  }
});

export const {
  storeBuilderFields,
  addBuilderField,
  storeAddBuilderField,
  changeSelectedBuilderField,
  updateBuilderField,
  storeUpdatedBuilderField,
  deleteFieldFromSelectedIndex,
  deleteFieldSuccess,
  cloneFieldFormSelectedIndex,
  cloneFieldSuccess,
  reorderBuilderFields,
  reorderBuilderFieldsSuccess,
  reorderCardFields,
  reorderCardFieldsSuccess,
  updateAllFields,
  storeUpdateAllFields,
  addNewPage,
  addNewPageSuccess,
  deletePage,
  reorderPage,
  updateIdMap
} = builderFieldsSlice.actions;

export default builderFieldsSlice.reducer;
