import React, {useMemo} from 'react';
import {Box, Stack} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {parse as parseTime} from 'date-fns';
import CustomTimePicker from '../../../common/TimePicker/CustomTimePicker';
import withFieldChange from '../../../hoc/withFieldChange';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewTimeComponent = ({field, editMode, onChange}) => {
  const ampm = field.format === '12hr';
  const defaultTime = useMemo(() => {
    if (field.defaultTime === 'none') {
      return null;
    } else if (field.defaultTime === 'current') {
      return new Date();
    } else {
      return field.value === null ? null : parseTime(field.value, 'hh:mm aa', new Date());
    }
  }, [field]);

  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>
        <Stack direction="column" spacing={1}>
          <ConnectPublicForm
            name={field.id}
            value={defaultTime}
            rules={field.required ? {required: 'This value is required'} : {}}
            render={(formOption, formMethods) => (
              <>
                <CustomTimePicker
                  className="BuilderTimePicker"
                  ampm={ampm}
                  value={defaultTime}
                  readOnly={editMode || field.readOnly}
                  onChange={value => {
                    formMethods?.setValue(field.id, value);
                    onChange({value: value, defaultTime: 'custom'});
                    if (formOption.formState.isSubmitted) {
                      formMethods?.trigger();
                    }
                  }}
                />
                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewTimeComponent);
