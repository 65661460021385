import React from 'react';
import {Box, Stack, styled} from '@mui/material';
import {AddCircleTwoTone, AddPhotoAlternateTwoTone, HideImageTwoTone, RemoveCircleTwoTone} from "@mui/icons-material";
import CustomTextField from '../../../common/TextField/CustomTextField';
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";

const StyleImageBox = styled(Stack)(() => ({
  position: 'absolute',
  right: '8px',
  top: '10px',
  "& span": {
    cursor: "pointer",
    transition: 'all 0.2s ease-in-out',
    "&:hover": {
      transform: "scale(1.1)"
    }
  }
}))

const StyledStackForInDec = styled(Stack)(({theme}) => ({
  marginLeft: theme.spacing(0.75),
  '& span': {
    cursor: "pointer",
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: "scale(1.1)"
    }
  }
}));

const RightSideOptionsElements = ({
  title = '',
  value = '',
  icon="",
  showImageIcon=false,
  onClickAddImage = () => {},
  onChangeTitle = () => {},
  onResetImage = () => {},
  onAddOption = () => {},
  onRemoveOption = () => {},
  onEnterPress = () => {},
    ...rest
}) => {

  return (
    <Stack direction="row" alignItems="center">
      <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '44px',
          }}
      >
        <CustomTextField
            id={value}
            size="medium"
            padding={`5px ${icon ? "60px": "32px"} 5px 12px`}
            labelPosition="top"
            type="text"
            placeholder="Write here"
            value={title}
            onChange={e => onChangeTitle(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && onEnterPress(e)}
            {...rest}
        />
        {showImageIcon && (
            <StyleImageBox direction={"row"} spacing={0.25}>
              <CustomTooltip arrow placement="bottom" title={`${icon ? "Change" : "Add" } image`}>
                <Box component={"span"} onClick={onClickAddImage}>
                  <AddPhotoAlternateTwoTone color={"action"}/>
                </Box>
              </CustomTooltip>

              {icon && (
                  <CustomTooltip arrow placement="bottom" title="Reset image">
                    <Box component={"span"} onClick={() => onResetImage("")}>
                      <HideImageTwoTone color={"action"}/>
                    </Box>
                  </CustomTooltip>
              )}
            </StyleImageBox>
        )}
      </Box>
      <StyledStackForInDec direction="row" alignItem="center" justifyContent="center" spacing={0.5}>
        <Box component={"span"} onClick={onAddOption}>
          <AddCircleTwoTone color={"secondary"} />
        </Box>
        <Box component={"span"} onClick={onRemoveOption}>
          <RemoveCircleTwoTone color={"error"} />
        </Box>
      </StyledStackForInDec>
    </Stack>
  );
};

export default RightSideOptionsElements;
