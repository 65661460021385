import React from 'react';
import {Box, Grid, Skeleton, Stack} from '@mui/material';
import CommonInputLoader from './commonLoader/CommonInputLoader';
import CommonTextareaLoader from './commonLoader/CommonTextareaLoader';
import CommonCheckboxLoader from "./commonLoader/CommonCheckboxLoader";

const SeoSettingsLoader = () => {
  return (
    <Box sx={{p: 3, height: '100%'}}>
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12}>
          <CommonTextareaLoader />
        </Grid>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12} md={6}>
          <Skeleton variant="text" width={'50%'}  />
          <Stack direction="row" spacing={2}>
            <CommonCheckboxLoader />
            <CommonCheckboxLoader />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SeoSettingsLoader;
