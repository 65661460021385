import React from 'react';
import {Box} from '@mui/material';
import CustomBuilderInput from '../../../common/TextField/CustomBuilderInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {FormCardLabel} from "../../../common/Form-UI/FormCardLabel";

const CardViewHiddenFieldComponent = ({field}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box sx={{display: 'flex', flexDirection: 'column'}} className="fbpCardContent">
        <FormCardLabel mb={4}>{field.name}</FormCardLabel>
        <Box>
          <CustomBuilderInput type="text" placeholder={field.placeholder} value={field.value} />
        </Box>
        <HiddenFieldAlert />
      </Box>
    </>
  );
};

export default CardViewHiddenFieldComponent;
