import React from 'react';
import Styles from './Loader.module.scss';

const PublicPageLoader = () => {
  return (
    <div className={Styles.loadingContainer}>
      <div className={Styles.loader}>
        <div className={Styles.circle}>
          <div className={Styles.center}></div>
        </div>
        <p>Hold Tight! We’re getting the page ready...</p>
      </div>
    </div>
  );
};
export default PublicPageLoader;
