import React from 'react';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import {Stack, Box} from '@mui/material';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';

const ShortTextComponent = ({field, editMode, onChange}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <>
      <Box className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

        <Stack direction="column" sx={{flex: '1 1 100% !important', width: '100%'}} spacing={1}>
          <FormInput
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={field.required ? {required: 'This value is required'} : {}}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(ShortTextComponent);
