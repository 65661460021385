import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormProvider, useForm} from 'react-hook-form';
import {Box} from '@mui/material';
import Banner from '../Builder/Banner/Banner';
import FooterInFormsPage from '../Setting/footerSettings/FooterInFormsPage';
import HeaderInPreviewPage from '../Setting/HeaderSettings/HeaderInPreviewPage';
import PublicPageViewManager from './PublicPageViewManager';
import PublicPageSubmit from './PublicPageSubmit';
import ProgressBarHandler from '../EachComponent/progressbar/ProgressBarHandler';
import useRulesIndex from '../../hooks/useRulesIndex';
import {getStructuredClone} from '../../helpers/utils/getStructuredClone';
import {checkHasProgressBar} from '../../helpers/utils/checkHasProgressBar';
import {matchCondition} from '../../helpers/utils/conditionalUtils/matchCondition';
import {selectConditionSettingState} from '../../state/features/conditionSetting/conditionSettingSelector';
import initialMatchCondition from '../../helpers/utils/conditionalUtils/initialMatchCondition';
import matchPageChangeConditions from '../../helpers/utils/conditionalUtils/matchPageChangeConditions';
import Styles from './PublicPage.module.scss';

const PublicPageContent = ({theme, editMode, className = ''}) => {
  const formMethods = useForm();
  const [selectedPage, setSelectedPage] = useState(0);
  const [lastChangedValue, setLastChangedValue] = useState(null);
  const [progressBarComponent, setProgressBarComponent] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const backStack = useRef([]);
  const fields = useRef(
    useSelector(state => getStructuredClone(state.publicPage.fields ?? state.builderFields.fields))
  );
  const {rules} = useSelector(selectConditionSettingState);
  const {fieldsIdMap} = useRulesIndex(rules, fields.current);
  const totalPages = fields.current.length;

  useEffect(() => {
    if (rules.filter(rule => rule.disabled !== true).length === 0) {
      setProgressBarComponent(checkHasProgressBar(fields.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialMatchCondition(rules, fieldsIdMap, fields.current);
    setRefreshKey(refreshKey + 1);
    formMethods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = data => {
    backStack.current.push(selectedPage);

    const nextPage = matchPageChangeConditions(
      rules,
      fieldsIdMap,
      fields.current[selectedPage].pageItems
    );
    setSelectedPage(nextPage ?? selectedPage + 1);
  };

  const handleBack = () => {
    setSelectedPage(backStack.current.pop());
  };

  const handleChangeValue = (index, data) => {
    fields.current[selectedPage].pageItems[index] = {
      ...fields.current[selectedPage].pageItems[index],
      ...data
    };
    handleConditionalLogic(index);
    setLastChangedValue({index, data});
  };

  const handleConditionalLogic = index => {
    const fieldId = fields.current[selectedPage].pageItems[index].id;
    if (fieldsIdMap[fieldId] && fieldsIdMap[fieldId].ruleIndexes.length > 0) {
      matchCondition(rules, fieldsIdMap, fieldId, fields.current);
      setRefreshKey(refreshKey + 1); //Todo optimize refresh key
      formMethods.reset(); //Todo optimize form reset
    }
  };

  return (
    <Box className={`${Styles.PublicPageViewWrapper} ${className}`}>
      {fields.current.length > selectedPage && (
        <Box pb={theme.coverImage && theme.profileImage ? 4 : 0}>
          <Banner theme={theme} />
        </Box>
      )}

      {progressBarComponent && fields.current.length > selectedPage && (
        <Box
          className={`${Styles.ProgressBarContent} ${
            progressBarComponent.sticky === false ? Styles.nonSticky : ''
          }`}
        >
          <ProgressBarHandler
            fields={fields.current}
            lastField={lastChangedValue}
            currentPage={selectedPage}
            progressBar={progressBarComponent}
          />
        </Box>
      )}

      <Box
        className={`${Styles.fbpFormBuilderBodyContent}`}
        sx={{paddingTop: progressBarComponent ? '0' : '30px'}}
      >
        <Box className={Styles.fbpPageContainer}>
          {/* ## Header Preview Show ## */}
          {fields.current.length > selectedPage && <HeaderInPreviewPage />}

          <FormProvider {...formMethods}>
            {fields.current.length > selectedPage && (
              <Box
                noValidate
                component="form"
                onSubmit={formMethods.handleSubmit(onSubmit)}
                sx={{width: '100%', display: 'flex', flexWrap: 'wrap'}}
              >
                <PublicPageViewManager
                  refreshKey={refreshKey}
                  fields={fields.current[selectedPage].pageItems}
                  onBack={handleBack}
                  onChange={handleChangeValue}
                  currentPage={selectedPage + 1}
                  totalPages={totalPages}
                />
              </Box>
            )}
          </FormProvider>

          {fields.current.length <= selectedPage && (
            <Box sx={{width: '100%'}}>
              <PublicPageSubmit fields={fields.current} editMode={editMode} />
            </Box>
          )}

          {/* ## Footer Preview Show ## */}
          {fields.current.length > selectedPage && <FooterInFormsPage />}
        </Box>
      </Box>
    </Box>
  );
};

export default PublicPageContent;
