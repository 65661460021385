import {Controller, useForm, useFormContext} from 'react-hook-form';
import {Stack, styled} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';

const StyledTextArea = styled('textarea')(({theme}) => ({
  minHeight: '120px',
  width: '100% ',
  maxWidth: '100%',
  fontSize: '16px',
  padding: '8px 12px',
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  background: 'var(--fbp-theming-input-background)',
  borderRadius: '4px',
  fontFamily: `var(--fbp-theming-font-family) !important`,
  color: 'var(--fbp-theming-text-color) ',
  '&::placeholder': {
    fontFamily: `var(--fbp-theming-font-family) `,
    color: 'var(--fbp-theming-placeholder-color) ',
    opacity: '0.42'
  },
  '&:focus': {
    outline: '0',
    border: `1px solid ${theme.palette.secondary.main}`
  }
}));

export const FormTextArea = ({
  name,
  value,
  rules,
  className = '',
  placeholder,
  readOnly,
  helperTextProps = {},
  onChange = () => {}
}) => {
  const {control} = useForm();
  const methods = useFormContext();

  return (
    <Controller
      control={methods ? methods.control : control}
      name={name}
      defaultValue={value}
      rules={rules}
      render={formOption => (
        <>
          <Stack direction="column" spacing={1}>
            <StyledTextArea
              rows={3}
              className={className}
              readOnly={readOnly}
              placeholder={placeholder}
              value={value}
              onChange={e => {
                formOption.field.onChange(e);
                onChange(e);
              }}
            ></StyledTextArea>
            {formOption.fieldState.error?.message ? (
              <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
            ) : (
              <FormSubtext>{helperTextProps.text}</FormSubtext>
            )}
          </Stack>
        </>
      )}
    />
  );
};
