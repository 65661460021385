import React from 'react';
import {Divider, Paper} from '@mui/material';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import FormSEOBody from './FormSEOBody';

const FormSEO = () => {
  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'SEO Settings'} content={'Fill your brand info for better SEO'} />
      <Divider />
      <FormSEOBody />
    </Paper>
  );
};

export default FormSEO;
