import React from 'react';
import {Box, Stack, styled} from '@mui/material';
import EmbeddedForm from './EmbeddedForm';

const GrayBox = styled(Box)(({theme}) => ({
  height: '40px',
  width: '100%',
  backgroundColor: theme.palette.other.dividerFillColor,
  borderRadius: '4px'
}));


const SideTabViewEmbed = ({embedCode}) => {
  return (
    <>
      <Stack sx={{height: '100%', width: '100%', overflow: 'hidden'}} spacing={1}>
        <Box sx={{width: '100%'}}>
          <GrayBox />
        </Box>
        <EmbeddedForm embedCode={embedCode} />
        <Box sx={{width: '100%'}}>
          <GrayBox />
        </Box>
      </Stack>
    </>
  );
};

export default SideTabViewEmbed;
