import {createSlice} from '@reduxjs/toolkit';
import {EMBED_MODES} from "../../../helpers/constant/embedConstant";
import {nanoid} from "nanoid";

const initialPrototype = {
  [EMBED_MODES.STANDARD]: {
    mode: EMBED_MODES.STANDARD,
    width: '100',
    widthSuffix: "%",
    height: '500',
    heightSuffix: 'px',
  },
  [EMBED_MODES.FULL_PAGE]: {
    mode: EMBED_MODES.FULL_PAGE,
    withHtml: false,
  },
  [EMBED_MODES.POPUP]: {
    mode: EMBED_MODES.POPUP,
    popUpSize: 'large',
    buttonText: 'Get Started',
    buttonStyle: 'contained',
    buttonSize: "large",
    buttonColor: '#6258FF',
  },
  [EMBED_MODES.SLIDER]: {
    mode: EMBED_MODES.SLIDER,
    buttonText: 'Get Started',
    buttonStyle: 'contained',
    buttonSize: "large",
    buttonColor: '#6258FF',
  },
  [EMBED_MODES.POPOVER]: {
    mode: EMBED_MODES.POPOVER,
    buttonColor: '#6258FF',
    icon:  ""
  },
  [EMBED_MODES.SIDE_TAB]: {
    mode: EMBED_MODES.SIDE_TAB,
    buttonText: 'Get Started',
    buttonColor: '#6258FF',
    icon: ""
  },
}
const initialState = {
  prototype: initialPrototype,

  currentEmbedMode: EMBED_MODES.STANDARD,
  embedCode: "",
  renderId: "",
};

const embedSettingsSlice = createSlice({
  name: 'embedSettings',
  initialState: initialState,
  reducers: {
    setEmbedSettings: (state, action) => {
      state.prototype[state.currentEmbedMode] = {
        ...state.prototype[state.currentEmbedMode],
        ...action.payload
      };
      state.renderId = nanoid(5)
    },
    resetEmbedSettings: (state) => {
      state.prototype = initialPrototype;
      state.currentEmbedMode = EMBED_MODES.STANDARD;
      state.embedCode = "";
      state.renderId = "";
    },
    setCurrentEmbedMode: (state, action) => {
      state.currentEmbedMode = action.payload || EMBED_MODES.STANDARD;
    },
    setEmbedCode: (state, action) => {
      state.embedCode = action.payload;
    },
    resetEmbedSettingsUpdate: (state) => {
      state.renderId = "";
    }
  }
});

export const {
  setEmbedSettings,
  resetEmbedSettings,
  setCurrentEmbedMode,
  setEmbedCode,
} = embedSettingsSlice.actions;

export default embedSettingsSlice.reducer;
