import React from 'react';
import {List, ListItem, ListItemIcon, Typography, styled} from '@mui/material';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons.js';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectArchivedFolder,
  selectFavoriteFolder,
  selectTrashFolder
} from '../../state/features/folderForms/folderFormsSlice';
import {selectFormFilters} from '../../state/features/folderForms/folderFormSelector';
import Styles from './FormList.module.scss';

const StyledListItem = styled(ListItem)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '8px',
  borderRadius: '4px',
  marginBottom: '5px',
  '&:hover': {
    backgroundColor: theme.palette.action.actionHover
  },
  '&.active': {
    backgroundColor: theme.palette.action.actionHover
  }
}));

const SpecialFolderList = () => {
  const dispatch = useDispatch();
  const {favourite, archived, trash} = useSelector(selectFormFilters);

  const handleSelectFavoriteFolder = () => {
    dispatch(selectFavoriteFolder());
  };

  const handleSelectArchivedFolder = () => {
    dispatch(selectArchivedFolder());
  };

  const handleSelectTrashFolder = () => {
    dispatch(selectTrashFolder());
  };

  return (
    <List className={Styles.folderListsBottom}>
      <StyledListItem onClick={handleSelectFavoriteFolder} className={` ${favourite && 'active'}`}>
        <ListItemIcon sx={{minWidth: '26px'}}>{fbpIcons.fbpFavIcon}</ListItemIcon>
        <Typography variant="buttonMedium" color={'text.secondary'}>
          Favorites
        </Typography>
      </StyledListItem>
      <StyledListItem onClick={handleSelectArchivedFolder} className={`${archived && 'active'}`}>
        <ListItemIcon sx={{minWidth: '26px'}}>{fbpIcons.fbpArchiveIcon}</ListItemIcon>
        <Typography variant="buttonMedium" color={'text.secondary'}>
          Archive
        </Typography>
      </StyledListItem>
      <StyledListItem onClick={handleSelectTrashFolder} className={`${trash && 'active'}`}>
        <ListItemIcon sx={{minWidth: '26px'}}>{fbpIcons.fbpTrashIcon}</ListItemIcon>
        <Typography variant="buttonMedium" color={'text.secondary'}>
          Trash
        </Typography>
      </StyledListItem>
    </List>
  );
};

export default SpecialFolderList;
