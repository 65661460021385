import React, {useState} from 'react';
import {useTheme} from '@mui/styles';
import {styled} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FormTemplateCategoryList from './FormTemplateCategoryList.jsx';
import {
  TEMPLATE__CATEGORY_TYPE_BUSINESS,
  TEMPLATE__CATEGORY_TYPE_INDUSTRY
} from '../../../helpers/constant/templateConstant';

const TabStyled = styled(Tab)(({theme}) => ({
  background: theme.palette.background.paper,
  '&:focus': {
    backgroundColor: theme.palette.background.paper
  }
}));

const TabsStyled = styled(Tabs)(({theme}) => ({
  background: theme.palette.background.paper,
  boxShadow: 0
}));

function TabPanel({children, value, index, ...other}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `fullWidthTab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const FormTemplateTab = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <TabsStyled
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{padding: 0}}
        value={value}
        onChange={handleChange}
      >
        <TabStyled label="Types" {...a11yProps(0)} />
        <TabStyled label="Industries" {...a11yProps(1)} />
      </TabsStyled>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <FormTemplateCategoryList categoryType={TEMPLATE__CATEGORY_TYPE_BUSINESS} />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <FormTemplateCategoryList categoryType={TEMPLATE__CATEGORY_TYPE_INDUSTRY} />
      </TabPanel>
    </div>
  );
};

export default FormTemplateTab;
