import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  IconButton,
  ImageList,
  ImageListItemBar,
  InputAdornment,
  Skeleton,
  Stack
} from '@mui/material';
import {Search} from '@mui/icons-material';
import CoreTextField from '../../../common/TextField/CoreTextField';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {debounceHandler} from '../../../helpers/utils/debounceHandler';
import {getMediaImages} from '../../../state/features/mediaImage/mediaImageSlice';
import {selectMediaImageState} from '../../../state/features/mediaImage/mediaImageSelector';
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import {ImageListItemWrapper} from './AddMediaStyles';

const AddMediaImageTab = ({onAddImageIcon}) => {
  const dispatch = useDispatch();
  const {data: mediaImages = [], isLoading, error} = useSelector(selectMediaImageState);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getMediaImages());
  }, [dispatch]);

  const doSearch = value => {
    dispatch(getMediaImages({query: value}));
    setSearch(value);
  };

  // debounce implement for search input change
  const handleSearch = debounceHandler(doSearch, 500);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = Array.from({length: 20}).map((rowNum, index) => (
      <Skeleton key={index} variant="rounded" height={151} width={205} />
    ));
  } else if (error) {
    content = <GlobalEmptyPage description={error} />;
  } else if (!isLoading && mediaImages?.length === 0) {
    content = <GlobalEmptyPage title={'No Images Found'} />;
  } else if (!isLoading && mediaImages?.length > 0) {
    content = (
      <ImageList cols={3}>
        {mediaImages.map(item => (
          <ImageListItemWrapper key={item.id} onClick={() => onAddImageIcon(item.original)}>
            <img src={`${item.tiny}`} srcSet={`${item.tiny}`} alt={item.alt} loading="lazy" />
            <ImageListItemBar
              title={item.alt}
              actionIcon={
                <CustomTooltip title={item.alt} arrow>
                  <IconButton
                    sx={{color: 'rgba(255, 255, 255, 0.54)'}}
                    aria-label={`info about ${item.title}`}
                  >
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
              }
            />
          </ImageListItemWrapper>
        ))}
      </ImageList>
    );
  }

  return (
    <Stack spacing={2} sx={{mt: 2}}>
      <CoreTextField
        fullWidth
        size={'small'}
        type={'search'}
        color={'secondary'}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{mr: 0}} position="start">
              <Search />
            </InputAdornment>
          )
        }}
        placeholder={'Search images'}
        defaultValue={search}
        onChange={e => handleSearch(e.target.value)}
      />

      <Box sx={{height: '240px'}} className={'fbp_has_scroll'}>
        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '8px'}}>{content}</Box>
      </Box>
    </Stack>
  );
};

export default AddMediaImageTab;
