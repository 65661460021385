import React from 'react';
import {Stack, Typography, Box} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {Alert} from '@mui/material';
import GlobalCentralModal from '../../../common/Modal/GlobalCentralModal';

const DeleteWarningModal = ({
  title = 'Delete',
  warningSubtitle = 'Are you sure, want to delete?',
  warningContent = 'This operation cannot be undone.',
  buttonText = 'Yes, Delete',
  cancelButtonText = 'Cancel',
  open = false,
  onClose,
  onConfirm
}) => {
  return (
    <GlobalCentralModal
      className="deleteWarningModal"
      actionColor={'error'}
      size={'small'}
      title=""
      open={open}
      buttonText={buttonText}
      cancelButtonText={cancelButtonText}
      onSubmit={onConfirm}
      onClose={onClose}
    >
      <Stack alignItems="center">
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            padding: '10px',
            bgcolor: 'error.light',
            borderRadius: '10px'
          }}
        >
          <WarningRoundedIcon sx={{fontSize: '70px'}} color="error" />
        </Box>
        <Typography variant="h6" mt={1}>
          {title}
        </Typography>
        <Typography variant="body2" mb={4}>
          {warningSubtitle}
        </Typography>

        <div style={{width: '100%'}}>
          <Alert severity="error">{warningContent}</Alert>
        </div>
      </Stack>
    </GlobalCentralModal>
  );
};

export default DeleteWarningModal;
