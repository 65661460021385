import React from 'react';
import {useDispatch} from 'react-redux';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';
import {updateBuilderField} from '../../state/features/builderFields/builderFieldsSlice';

const EachEditContent = ({field, currentPage, totalPages}) => {
  const dispatch = useDispatch();

  const handleChangeValue = value => {
    dispatch(updateBuilderField({...field, ...value}));
  };

  const Component = ComponentConfig[field.component]?.component;

  if (Component) {
    return (
      <Component
        editMode
        field={field}
        onChange={handleChangeValue}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    );
  }

  return '';
};

export default EachEditContent;
