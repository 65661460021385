import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import {Box, Grid, Typography} from '@mui/material';
import {Send} from '@mui/icons-material';
import CustomEditor from '../../../common/Editor/CustomEditor';
import {registerPatternInboxReply} from '../../../helpers/constant/registerPattern';
import InputValidation from '../../../helpers/utils/InputValidation';
import CoreTextField from '../../../common/TextField/CoreTextField';
import LoadingButton from '../../../common/Button/LoadingButton';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbarSlice';
import {
  selectFormSubmissionData,
  selectSelectedSubmissionId
} from '../../../state/features/formSubmission/formSubmissionSelector';
import {
  inboxFormReply,
  resetInboxFormReplySuccess
} from '../../../state/features/formSubmission/formSubmissionSlice';

const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

const InboxReplyForm = ({onClose}) => {
  const dispatch = useDispatch();
  const inboxId = useSelector(selectSelectedSubmissionId);
  const {inboxFormReplyLoading, inboxFormReplySuccess} = useSelector(selectFormSubmissionData);
  const [contentBody, setContentBody] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [toEmails, setToEmails] = useState([]);
  const {handleSubmit, setValue, control} = useForm({
    mode: 'onSubmit'
  });

  useEffect(() => {
    if (inboxFormReplySuccess) {
      dispatch(resetInboxFormReplySuccess());
      resetForm();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxFormReplySuccess]);

  const resetForm = () => {
    setToEmails([]);
    setContentBody('');
    setValue('from', '');
    setValue('subject', '');
  };

  const handleKeyDown = event => {
    if (!inputValue) return;

    const {invalid} = InputValidation.email(inputValue);
    if (invalid) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setToEmails(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        return null;
    }
  };

  const onChangeMailContent = value => {
    setContentBody(value);
  };

  // ## form submit
  const onSubmit = data => {
    if (!toEmails && toEmails?.length === 0) return false;
    if (contentBody.trim() === '') {
      dispatch(showErrorSnackbar('Email content is required!'));
      return false;
    }

    const reqObj = {
      id: inboxId,
      to: [...toEmails],
      from: data.from,
      subject: data.subject,
      content: contentBody
    };

    dispatch(inboxFormReply(reqObj));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, bgcolor: 'background.paper'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="from"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  From
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'from.mail@gmail.com'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternInboxReply.fromEmail || null}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            '& #react-select-2-input': {
              height: '1.8rem !important',
              '&:focus': {
                boxShadow: 'none !important'
              }
            }
          }}
        >
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
            To
          </Typography>
          <CreatableSelect
            components={components}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: 'none'
              }),
              placeholder: styles => ({
                ...styles,
                color: '#546376',
                opacity: '0.7'
              }),
              input: styles => ({
                ...styles,
                color: '#546376'
              }),
              multiValue: styles => ({
                ...styles,
                backgroundColor: '#d7d7d7'
              })
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={newValue => setToEmails(newValue)}
            onInputChange={newValue => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder="Type something and press enter..."
            value={toEmails}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="subject"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Subject
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternInboxReply.subject || null}
          />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
              Email Content
            </Typography>
            <CustomEditor body={contentBody} onChangeBody={onChangeMailContent} />
          </Box>
        </Grid>

        <Grid item xs={12} sx={{textAlign: 'right', mt: 1}}>
          <LoadingButton
            type={'submit'}
            variant="contained"
            color={'secondary'}
            loadingText={'Sending...'}
            isLoading={inboxFormReplyLoading}
            endIcon={<Send />}
          >
            Send Email
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InboxReplyForm;
