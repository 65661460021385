import {CONDITION_LIST} from '../../../helpers/constant/formConditionConstant';

export const selectConditionSettingState = state => state.conditionSetting;
export const selectConditionCurrentPage = state => state.conditionSetting.currentPage;
export const selectConditionTypeHeader = state => {
  return CONDITION_LIST.find(condition => {
    return condition.type === state.conditionSetting.singleRule?.type;
  });
};
export const selectConditionSettingSingleRule = state => state.conditionSetting.singleRule || {};
export const selectSelectedConditionId = state => state.conditionSetting.selectedConditionId;

export const selectHasDisabledEnableInsideSelectedCondition = state => {
  const hasDisabled = state.conditionSetting.rules.some(
    rule => rule.disabled && state.conditionSetting.selectedConditionIds.includes(rule.id)
  );

  const hasEnabled = state.conditionSetting.rules.some(
    rule => !rule.disabled && state.conditionSetting.selectedConditionIds.includes(rule.id)
  );

  return {hasDisabled, hasEnabled};
};
