import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import CoreButton from '../../../common/Button/CoreButton';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {setTemplateSearchText} from '../../../state/features/templates/templatesSlice';

const SearchFormTemplate = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const handleOnBlur = () => {
    dispatch(setTemplateSearchText(search));
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(setTemplateSearchText(search));
  };

  return (
    <form
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
      onSubmit={onSubmit}
    >
      <CoreTextField
        className="chooseTemplateSearchbar"
        sx={{height: '40px', bgcolor: 'other.outlinedBorder'}}
        type="search"
        placeholder="Search Form Templates"
        value={search}
        onChange={handleSearch}
        onBlur={handleOnBlur}
      />
      <CoreButton
        variant={'contained'}
        size="medium"
        sx={{height: '40px', borderRadius: ' 0 4px 4px 0'}}
        color="secondary"
        type={'submit'}
      >
        Search
      </CoreButton>
    </form>
  );
};

export default SearchFormTemplate;
