import React from 'react';
import {useSelector} from 'react-redux';
import {alpha, IconButton, Stack, styled, Typography, useTheme} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import ConstructionIcon from '@mui/icons-material/Construction';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RocketIcon from '@mui/icons-material/Rocket';
import {ArrowBack, OpenInNew} from '@mui/icons-material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {BUILDER_OPTIONS, SCREEN_OPTIONS} from '../../helpers/constant/CoreConstants';
import CoreButton from '../../common/Button/CoreButton';
import CustomBreadcrum from '../../common/Breadcrumb/CustomBreadcrumb';
import {isAdminRoute} from '../../helpers/utils/isAdminRoute';
import generatePublicPageUrl from '../../helpers/utils/generatePublicPageUrl';

const BuilderHeaderWrapper = styled(Stack)(({theme, previewposition = 'absolute'}) => ({
  height: '52px',
  padding: '10px 16px',
  background: theme.palette.other.darkNavy,
  position: previewposition,
  top: 0,
  width: '100%',
  zIndex: 99
}));

const BuilderHeaderAction = styled(CoreButton, {
  shouldForwardProp: props => props !== 'selectedOption' && props !== 'backgroundColor'
})(({theme, selectedOption}) => ({
  backgroundColor: selectedOption
    ? theme.palette.common.white
    : alpha(theme.palette.common.white, 0.1),
  color: selectedOption ? theme.palette.other.darkNavy : theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.other.darkNavy
  }
}));

const DevicePreviewIcon = styled(IconButton, {
  shouldForwardProp: propName => propName !== 'selectedScreen'
})(({theme, selectedScreen}) => ({
  '&.MuiIconButton-root': {
    borderRadius: '4px',
    color: selectedScreen ? theme.palette.other.darkNavy : theme.palette.common.white,
    backgroundColor: selectedScreen ? theme.palette.common.white : theme.palette.other.darkNavy,
    '&:hover': {
      color: theme.palette.other.darkNavy,
      backgroundColor: theme.palette.common.white
    }
  }
}));

const BuilderHeader = ({
  selectedOption,
  setSelectedOption,
  selectedScreen,
  onChangeScreen,
  formTitle
}) => {
  const {formApiKey} = useSelector(state => state.builderSetting);
  const isAdmin = isAdminRoute();
  const updateTime = useSelector(state => state.builderSetting.updateTime);
  const theme = useTheme();

  // Preview view does have different header, so we do the condition if selected option preview or not
  return selectedOption === BUILDER_OPTIONS.PREVIEW ? (
    <BuilderHeaderWrapper
      direction={'row'}
      justifyContent="space-between"
      alignItems="center"
      previewposition={'fixed'}
    >
      <CoreButton
        variant={'text'}
        size={'small'}
        startIcon={<ArrowBack />}
        onClick={() => setSelectedOption(BUILDER_OPTIONS.BUILDER)}
        sx={{color: theme.palette.common.white}}
      >
        Back to editor
      </CoreButton>

      <Stack direction={'row'} alignItems="center" spacing={3.5}>
        <Typography
          variant={'body1medium'}
          color={'common.white'}
          sx={{display: {xs: 'none', sm: 'block'}}}
        >
          You're now in preview mode
        </Typography>
        <Stack direction={'row'} alignItems="center" spacing={0.5}>
          <CustomTooltip arrow placement="bottom" title="Mobile">
            <DevicePreviewIcon
              size={'small'}
              onClick={() => onChangeScreen(SCREEN_OPTIONS.MOBILE)}
              selectedScreen={selectedScreen === SCREEN_OPTIONS.MOBILE}
            >
              <SmartphoneIcon color={'inherit'} sx={{fontSize: '20px'}} />
            </DevicePreviewIcon>
          </CustomTooltip>
          <CustomTooltip arrow placement="bottom" title="Tab">
            <DevicePreviewIcon
              size={'small'}
              onClick={() => onChangeScreen(SCREEN_OPTIONS.TAB)}
              selectedScreen={selectedScreen === SCREEN_OPTIONS.TAB}
            >
              <TabletMacIcon color={'inherit'} sx={{fontSize: '20px'}} />
            </DevicePreviewIcon>
          </CustomTooltip>
          <CustomTooltip arrow placement="bottom" title="Desktop">
            <DevicePreviewIcon
              size={'small'}
              onClick={() => onChangeScreen(SCREEN_OPTIONS.DESKTOP)}
              selectedScreen={selectedScreen === SCREEN_OPTIONS.DESKTOP}
            >
              <DesktopWindowsOutlinedIcon color={'inherit'} sx={{fontSize: '20px'}} />
            </DevicePreviewIcon>
          </CustomTooltip>
        </Stack>
      </Stack>

      {!isAdmin && (
        <a
          href={generatePublicPageUrl(formApiKey)}
          target="_blank"
          rel="noreferrer"
          style={{textDecoration: 'none'}}
        >
          <CoreButton
            variant={'text'}
            size={'small'}
            endIcon={<OpenInNew />}
            sx={{color: theme.palette.common.white}}
          >
            Open new tab
          </CoreButton>
        </a>
      )}
    </BuilderHeaderWrapper>
  ) : (
    <BuilderHeaderWrapper direction={'row'} justifyContent="space-between" alignItems="center">
      <CustomBreadcrum
        parentLocation={'All Folder'}
        icon={<FolderIcon fontSize={'small'} sx={{color: theme.palette.common.white}} />}
        currentLocation={formTitle}
        parentUri={isAdmin ? '/fbuilder/admin' : '/fbuilder/forms'}
      />

      <Typography
        variant={'chipLight'}
        color={'common.white'}
        sx={{display: 'flex', alignItems: 'flex-end', gap: '4px'}}
      >
        {updateTime && (
          <>
            {updateTime === 'Saving...' ? (
              <SyncIcon fontSize={'small'} />
            ) : (
              <CloudDoneIcon fontSize={'small'} />
            )}
            {updateTime}
          </>
        )}
      </Typography>

      <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
        <BuilderHeaderAction
          variant={'contained'}
          size={'small'}
          backgroundColor={theme.palette.common.white}
          startIcon={<ConstructionIcon color={'inherit'} />}
          selectedOption={selectedOption === BUILDER_OPTIONS.BUILDER}
          onClick={() => setSelectedOption(BUILDER_OPTIONS.BUILDER)}
        >
          Builder
        </BuilderHeaderAction>
        <BuilderHeaderAction
          variant={'contained'}
          size={'small'}
          backgroundColor={theme.palette.common.white}
          startIcon={<VisibilityIcon color={'inherit'} />}
          selectedOption={selectedOption === BUILDER_OPTIONS.PREVIEW}
          onClick={() => setSelectedOption(BUILDER_OPTIONS.PREVIEW)}
        >
          Preview
        </BuilderHeaderAction>
        <BuilderHeaderAction
          variant={'contained'}
          size={'small'}
          backgroundColor={theme.palette.common.white}
          startIcon={<SettingsIcon color={'inherit'} />}
          selectedOption={selectedOption === BUILDER_OPTIONS.FORM_SETTING}
          onClick={() => setSelectedOption(BUILDER_OPTIONS.FORM_SETTING)}
        >
          Settings
        </BuilderHeaderAction>
        {!isAdmin && (
          <BuilderHeaderAction
            variant={'contained'}
            size={'small'}
            backgroundColor={theme.palette.common.white}
            startIcon={<RocketIcon color={'inherit'} />}
            selectedOption={selectedOption === BUILDER_OPTIONS.QUICK_SHARE}
            onClick={() => setSelectedOption(BUILDER_OPTIONS.QUICK_SHARE)}
          >
            Share
          </BuilderHeaderAction>
        )}
      </Stack>
    </BuilderHeaderWrapper>
  );
};

export default BuilderHeader;
