import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {Box, styled} from '@mui/material';

export const StyledTimePicker = styled(StaticTimePicker)(({theme}) => ({
  '& button': {'&:focus': {backgroundColor: 'unset'}},
  '& .MuiTimePickerToolbar-ampmSelection': {
    gap: '25px',
    margin: 0,
    justifyContent: 'space-around',
    marginRight: '30px',
    '& button': {'&:last-child': {marginRight: '20px'}}
  },
  '& .MuiClockNumber-root': {fontSize: '16px'}
}));
export const StyledDefaultTimePicker = styled(Box)(({theme}) => ({
  '& button': {'&:focus': {backgroundColor: 'unset'}},
  '& .MuiFormControl-root': {
    width: '100%',
    borderRadius: '4px',
    '& input': {
      height: '48px',
      padding: '0 12px !important',
      border: 'none !important'
    },

    '& .MuiOutlinedInput-input': {
      padding: '11.5px 14px'
    },
    '& .MuiOutlinedInput-notchedOutline ': {
      border: `1px solid ${theme.palette.other.outlinedBorder} !important`,
      '&.Mui-disabled': {
        borderColor: theme.palette.other.outlinedBorder
      }
    }
  },
  '&.BuilderTimePicker': {
    '& .MuiFormControl-root': {
      width: '100%',
      borderRadius: '4px',
      background: 'var(--fbp-theming-input-background)',
      '& svg path': {fill: 'var(--fbp-theming-text-color)'},
      '& input': {
        color: 'var(--fbp-theming-text-color) !important',
        fontFamily: 'var(--fbp-theming-font-family) !important',
        '&::placeholder': {
          color: 'var(--fbp-theming-placeholder-color)'
        }
      }
    }
  }
}));
