import React from 'react';
import Styles from './CustomTextAreaField.module.scss';
import {Typography} from '@mui/material';

const CustomTextAreaField = ({
  flex = '',
  labelPosition = 'left',
  labelIcon = '',
  label = '',
  placeholder = '',
  value = '',
  onChange,
  height = '62px'
}) => {
  return (
    <div
      className={`${Styles.fbpTextAreaGroup} ${
        labelPosition === 'left' ? Styles.fbpTextAreaGroupLeft : ''
      }`}
      style={{flex: `${flex}`}}
    >
      {label && (
        <Typography variant={'body2'} className={Styles.fbpTextAreaLabel}>
          {label}
          <span className={Styles.fbpTextAreaLabelStar}>{labelIcon}a</span>
        </Typography>
      )}
      <textarea
        className={Styles.fbpTextAreaField}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        style={{height: `${height}`}}
      ></textarea>
    </div>
  );
};

export default CustomTextAreaField;
