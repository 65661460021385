import httpRequest from '../api/httpRequest';

class CampaignService {
  getCampaignData(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'campaign/list', params);
  }

  inboxSubmissionAddToCampaign(params) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'campaign/contact/add',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CampaignService();
