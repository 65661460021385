import {styled} from "@mui/styles";
import {Box, ImageListItem} from "@mui/material";

export const AddMediaWrapper = styled(Box)(() => ({
    height: "320px",
}))

export const MediaImageWrapper = styled(Box)(({theme}) => ({
    height: 48,
    width: 48,
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    padding: theme.spacing(0.5),
    borderRadius: "4px",
    "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain"
    }
}))

export const ImageListItemWrapper = styled(ImageListItem)(() => ({
    cursor: 'pointer',
    "& :hover": {
        opacity: 0.8
    }
}))

export const ImageWrapperBox = styled(Box)(() => ({
    "& img": {
        height: "100%",
        width: "100%",
        objectFit: "contain"
    }
}))

