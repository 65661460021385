import React from 'react';

const StarIcon = ({fill}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99984 14.3917L15.1498 17.5L13.7832 11.6417L18.3332 7.70002L12.3415 7.19169L9.99984 1.66669L7.65817 7.19169L1.6665 7.70002L6.2165 11.6417L4.84984 17.5L9.99984 14.3917Z"
        fill="#949DB2"
      />
    </svg>
  );
};

export default StarIcon;
