import React from 'react';
import {useSelector} from 'react-redux';
import {Icon, Stack, styled} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';

const NextButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: `${buttontheme.nextButton.width}px`,
  height: `${buttontheme.nextButton.height}px`,
  background: buttontheme.nextButton.background,
  color: buttontheme.nextButton.color,
  borderRadius: buttontheme.nextButton.borderRadius,
  border: `1px solid ${buttontheme.nextButton.border}`,
  '&:hover': {
    background: buttontheme.nextButton.background
  }
}));
const BackButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: `${buttontheme.backButton.width}px`,
  height: `${buttontheme.backButton.height}px`,
  background: buttontheme.backButton.background,
  color: buttontheme.backButton.color,
  borderRadius: buttontheme.backButton.borderRadius,
  border: `1px solid ${buttontheme.backButton.border}`,
  '&:hover': {
    border: `1px solid ${buttontheme.backButton.border}`,
    background: buttontheme.backButton.background
  }
}));
const SubmitButton = styled(CoreButton, {
  shouldForwardProp: propName => propName !== 'buttontheme'
})(({theme, buttontheme = {}}) => ({
  width: `${buttontheme.submitButton.width}px`,
  height: `${buttontheme.submitButton.height}px`,
  background: buttontheme.submitButton.background,
  color: buttontheme.submitButton.color,
  borderRadius: buttontheme.submitButton.borderRadius,
  border: `1px solid ${buttontheme.submitButton.border}`,
  '&:hover': {
    background: buttontheme.submitButton.background
  }
}));

const SubmitButtonComponent = ({field, editMode, onBack, currentPage, totalPages}) => {
  const {theme} = useSelector(state =>
    state.publicPage.fields ? state.publicPage : state.builderSetting
  );

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      sx={{justifyContent: field.alignment}}
      spacing={1.5}
      p={2.5}
    >
      {currentPage !== 1 && (
        <BackButton
          buttontheme={theme}
          size={'small'}
          variant={'outlined'}
          startIcon={theme.backButton.iconVisible && <Icon>{theme.backButton.icon}</Icon>}
          onClick={() => !editMode && onBack()}
        >
          {field.backText}
        </BackButton>
      )}

      {currentPage === totalPages && (
        <SubmitButton
          type={editMode ? 'button' : 'submit'}
          buttontheme={theme}
          size={'small'}
          variant={'contained'}
          endIcon={theme.submitButton.iconVisible && <Icon>{theme.submitButton.icon}</Icon>}
        >
          {field.submitText}
        </SubmitButton>
      )}

      {currentPage < totalPages && (
        <NextButton
          type={editMode ? 'button' : 'submit'}
          buttontheme={theme}
          size={'small'}
          variant={'contained'}
          backgroundColor={theme.nextButton.background}
          endIcon={theme.nextButton.iconVisible && <Icon>{theme.nextButton.icon}</Icon>}
        >
          {field.nextText}
        </NextButton>
      )}
    </Stack>
  );
};

export default SubmitButtonComponent;
