import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DateRangeController from '../../common/DateRangePicker/DateRangePicker';
import {addDateRange} from '../../state/features/folderForms/folderFormsSlice';
import {debounceHandler} from '../../helpers/utils/debounceHandler';
import {selectFormFilters} from '../../state/features/folderForms/folderFormSelector';

const FormListFilterDateRange = () => {
  const dispatch = useDispatch();

  const {startDate, endDate} = useSelector(selectFormFilters);
  const [title, setTitle] = useState('All Time');

  // after reset
  useEffect(() => {
    if (!startDate || !endDate) {
      setTitle('All Time');
    }
  }, [startDate, endDate]);

  const doFilter = ({_fromDate, _toDate, difference, durationType}) => {
    dispatch(addDateRange({startTime: _fromDate, endTime: _toDate}));
    if (title) setTitle('');
  };
  const handleDatePicker = debounceHandler(doFilter, 1000);

  return (
    <DateRangeController
      datePickerCallback={handleDatePicker}
      title={title}
      isNeedInstantCallback={false}
    />
  );
};

export default FormListFilterDateRange;
