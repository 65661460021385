//Only applicable in card view
const isCurrentPageHasSubmitButton = (fields, selectedPage) => {
  for (let i = selectedPage + 1; i < fields.length; i++) {
    if (fields[i].hidden !== true) {
      return false;
    }
  }

  return true;
};

export default isCurrentPageHasSubmitButton;
