import React, {useEffect, useState} from 'react';
import {Box, styled, Tab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';

const StyledTabs = styled(Tabs, {
  shouldForwardProp: props => props !== 'orientationPosition'
})(({theme, orientationPosition}) => ({
  position: orientationPosition === 'bottom' ? 'absolute' : 'sticky',
  bottom: orientationPosition === 'bottom' ? 0 : 'auto',
  top: orientationPosition === 'bottom' ? 'auto' : 0,
  width: '100%',
  zIndex: 9,
  borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
  '& button.Mui-selected': {
    backgroundColor:
      orientationPosition === 'bottom'
        ? `${theme.palette.secondary.main} !important`
        : 'transparent',
    color: orientationPosition === 'bottom' ? `${theme.palette.common.white} !important` : 'inherit'
  }
}));

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

export default function BuilderLeftTabs({
  children,
  className,
  orientationPosition,
  selectedTab = 0,
  onChange = null,
  indicatorColor = 'primary'
}) {
  const [value, setValue] = useState(selectedTab);
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (Array.isArray(children)) {
      let propTitles = [];
      let elementIndex = 0;
      children.forEach(elem => {
        if (elem.type?.name === BuilderLeftTab.name) {
          propTitles.push(
            <Tab
              className={className}
              key={elementIndex}
              label={elem.props.title}
              {...a11yProps(elementIndex)}
            />
          );
          elementIndex++;
        }

        setTitles(propTitles);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const getTabPanels = () => {
    let elementIndex = 0,
      elements = [];
    children.forEach(elem => {
      if (elem.type?.name === BuilderLeftTab.name) {
        elements.push(
          <TabPanel
            key={elementIndex}
            value={value}
            index={elementIndex}
            className={`${elem.props.className ?? ''}`}
          >
            {elem.props.children}
          </TabPanel>
        );
        elementIndex++;
      }
    });

    return elements;
  };

  return (
    <>
      <StyledTabs
        className={className}
        value={value}
        onChange={handleChange}
        indicatorColor={indicatorColor}
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
        orientationPosition={orientationPosition}
      >
        {titles}
      </StyledTabs>
      {getTabPanels()}
    </>
  );
}

export const BuilderLeftTab = ({children}) => {
  return {children};
};

BuilderLeftTab.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};
