export const builderQueueTypes = {
  ADD_FIELD: 1,
  DELETE_FIELD: 2,
  UPDATE_FIELD: 3,
  CLONE_FIELD: 4,
  REORDER_FIELDS: 5,
  PAGE_UPDATE : 6
};

//You need to update the queue manager after adding things.
//Queue manager in /features/builderChannelQueue/builderChannelQueue.js
export const makeQueueSequence = payload => {
  return {
    type: 'MAKE_QUEUE',
    payload: payload
  };
};
