import {nanoid} from 'nanoid';
import {all, call, put} from 'redux-saga/effects';
import {select, takeLatest} from '@redux-saga/core/effects';
import {
  cloneConditionSuccess,
  conditionUpdateFailed,
  deleteConditionSuccess,
  disableConditionSuccess,
  editConditionSuccess,
  getConditionSettingFailed,
  getConditionSettingSuccess,
  reorderConditionSuccess,
  saveConditionFailed,
  saveConditionSuccess
} from './conditionSettingSlice';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import ConditionSettingService from '../../../services/ConditionSetting.service';
import {selectConditionSettingState} from './conditionSettingSelector';
import {selectBuilderSetting} from '../builderSetting/builderSettingSelector';
import {CONDITION_TYPE} from '../../../helpers/constant/formConditionConstant';
import {FORM_SETTINGS_TYPE_CONDITION} from '../../../helpers/constant/formSettingsConstant';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {selectFieldLabelsAndOptionsById} from '../builderFields/builderSelector';

// helper functions
const getErrorFormat = (isError = false, message = '') => ({isError, message});

const conditionValidate = (condition = {}, type) => {
  if (!condition.fieldId) {
    return getErrorFormat(true, 'Please select field(s)');
  } else if (!condition.operator) {
    return getErrorFormat(true, 'Please select a field state');
  } else if (
    condition.operator &&
    condition.target &&
    !condition.value &&
    condition.value !== false
  ) {
    return getErrorFormat(true, 'Please fill up the value or select a field');
  } else {
    return getErrorFormat(false, '');
  }
};

const actionValidate = (action = {}, type) => {
  if (!action.visibility) {
    return getErrorFormat(true, 'Please select condition action');
  } else if (type === CONDITION_TYPE.SKIP && typeof action.page !== 'number') {
    return getErrorFormat(true, 'Please select a page');
  } else if (type !== CONDITION_TYPE.SKIP && !action.fieldId) {
    return getErrorFormat(true, 'Please select field(s) to effect');
  } else {
    return getErrorFormat(false, '');
  }
};

function* conditionSettingWatcher() {
  yield takeLatest('conditionSetting/getConditionSetting', getConditionSettingSaga);
  yield takeLatest('conditionSetting/saveCondition', saveConditionSaga);
  yield takeLatest('conditionSetting/editCondition', editConditionSaga);
  yield takeLatest('conditionSetting/deleteCondition', deleteConditionSaga);
  yield takeLatest('conditionSetting/disableCondition', disableConditionSaga);
  yield takeLatest('conditionSetting/cloneCondition', cloneConditionSaga);
  yield takeLatest('conditionSetting/reorderCondition', reorderCondition);
}

function* getConditionSettingSaga(action) {
  try {
    const response = yield call(ConditionSettingService.getConditionSetting, action.payload);

    if (response.success) {
      yield put(getConditionSettingSuccess(response.data));
    } else {
      yield put(getConditionSettingFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* saveConditionSaga(action) {
  try {
    const {id: formId = ''} = yield select(selectBuilderSetting);
    const {singleRule, rules} = yield select(selectConditionSettingState);
    let {condition: conditions = [], action: actions = [], type} = singleRule || {};

    // ## validation start
    let hasError = false;
    let errMessage = '';

    const newConditions = conditions.slice().map(conditionItem => {
      const {isError, message} = conditionValidate(conditionItem, type);
      if (isError && !hasError) hasError = true;
      if (isError) {
        errMessage = !errMessage ? message : 'Please make the necessary corrections before saving';
      }
      return {...conditionItem, isError};
    });

    const newActions = actions.slice().map(actionItem => {
      const {isError, message} = actionValidate(actionItem, type);

      if (isError && !hasError) hasError = true;
      if (isError) {
        errMessage = !errMessage ? message : 'Please make the necessary corrections before saving';
      }
      return {...actionItem, isError};
    });
    // ## validation end

    // ## handle State
    const newSingleRule = {
      ...singleRule,
      condition: newConditions,
      action: newActions
    };

    if (!hasError) {
      // if there is no error, update the newRules list with updated new single rule
      const newRules = [...rules];
      const currentPosition = newRules.findIndex(singleRule => singleRule.id === newSingleRule.id);
      if (currentPosition !== -1) {
        newRules.splice(currentPosition, 1, newSingleRule);
      } else {
        newRules.push(newSingleRule);
      }

      const settings = JSON.stringify({rules: newRules, formId: formId});
      const payload = {
        formId: formId,
        type: FORM_SETTINGS_TYPE_CONDITION,
        settings: settings
      };

      yield put(updateBuilderTime('Saving...'));
      const response = yield call(ConditionSettingService.updateConditionSetting, payload);

      if (response.success) {
        yield put(saveConditionSuccess({rules: newRules}));
        yield put(showSuccessSnackbar('Successfully saved condition'));
      } else {
        yield put(showErrorSnackbar('Failed to save condition'));
      }

      yield put(
        updateBuilderTime(
          'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
        )
      );
    } else {
      yield put(
        saveConditionFailed({
          singleRule: newSingleRule,
          errMessage: errMessage
        })
      );
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* editConditionSaga(action) {
  try {
    const {rules} = yield select(selectConditionSettingState);
    const fieldLabelsById = yield select(selectFieldLabelsAndOptionsById);

    const singleRule = rules.find(singleRule => singleRule.id === action.payload.ruleId);

    let {condition: conditions = [], action: actions = [], type} = singleRule || {};

    // ## validation start
    let hasError = false;
    let errMessage = '';

    const newConditions = conditions.slice().map(conditionItem => {
      const newConditionItem = {...conditionItem};

      const isFieldDeleted = !fieldLabelsById[conditionItem.fieldId];

      // if condition field delete then reset("") others property
      if (isFieldDeleted) {
        newConditionItem.fieldId = '';
        newConditionItem.operator = '';
        if (newConditionItem.hasOwnProperty('target')) {
          newConditionItem.target = '';
          newConditionItem.value = '';
        }
      } else {
        // if condition field has but field has options and missing options
        const isFieldTypeThatHasOption =
          conditionItem.component === COMPONENT_TYPE.YES_NO ||
          conditionItem.component === COMPONENT_TYPE.RADIO ||
          conditionItem.component === COMPONENT_TYPE.CHECKBOX ||
          conditionItem.component === COMPONENT_TYPE.DROPDOWN;
        if (isFieldTypeThatHasOption) {
          const isOptionDeleted = !(
            fieldLabelsById[conditionItem.fieldId].options &&
            fieldLabelsById[conditionItem.fieldId].options[conditionItem.value]
          );
          if (isOptionDeleted) newConditionItem.value = '';
        }
      }

      const {isError, message} = conditionValidate(newConditionItem, type);

      if (isError && !hasError) hasError = true;
      if (isError) {
        errMessage = !errMessage ? message : 'Please make the necessary corrections before saving';
      }
      return {...newConditionItem, isError};
    });

    const newActions = actions.slice().map(actionItem => {
      const newActionItem = {...actionItem};

      const isFieldDeleted = !fieldLabelsById[newActionItem.fieldId];
      if (isFieldDeleted) {
        newActionItem.fieldId = '';
      }

      const {isError, message} = actionValidate(newActionItem, type);

      if (isError && !hasError) hasError = true;
      if (isError) {
        errMessage = !errMessage ? message : 'Please make the necessary corrections before saving';
      }
      return {...newActionItem, isError};
    });
    // ## validation end

    // ## handle State
    const newSingleRule = {
      ...singleRule,
      condition: newConditions,
      action: newActions
    };

    yield put(editConditionSuccess({singleRule: newSingleRule, errMessage: errMessage}));
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteConditionSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const {id: formId = ''} = yield select(selectBuilderSetting);
    const {rules = []} = yield select(selectConditionSettingState);

    const newRules = rules.filter(singleRule => action.payload.indexOf(singleRule.id) === -1);

    const settings = JSON.stringify({rules: newRules, formId: formId});
    const payload = {
      formId: formId,
      type: FORM_SETTINGS_TYPE_CONDITION,
      settings: settings
    };

    const response = yield call(ConditionSettingService.updateConditionSetting, payload);

    if (response.success) {
      const totalCondition = action.payload.length;
      yield put(deleteConditionSuccess({rules: newRules, ids: action.payload}));
      yield put(
        showSuccessSnackbar(
          `${totalCondition} ${
            totalCondition > 1 ? ' Conditions' : ' Condition'
          } deleted successfully`
        )
      );
    } else {
      yield put(conditionUpdateFailed());
      yield put(showErrorSnackbar('Condition delete failed!'));
    }

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* disableConditionSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const {id: formId = ''} = yield select(selectBuilderSetting);
    const {rules = []} = yield select(selectConditionSettingState);

    const newRules = rules.map(singleRule => {
      if (action.payload.ids.indexOf(singleRule.id) !== -1) {
        return {...singleRule, disabled: action.payload.disabled};
      }
      return singleRule;
    });

    const settings = JSON.stringify({rules: newRules, formId: formId});
    const payload = {
      formId: formId,
      type: FORM_SETTINGS_TYPE_CONDITION,
      settings: settings
    };

    const response = yield call(ConditionSettingService.updateConditionSetting, payload);

    if (response.success) {
      const totalCondition = action.payload.ids.length;
      yield put(disableConditionSuccess({rules: newRules, ids: action.payload.ids}));
      yield put(
        showSuccessSnackbar(
          `${action.payload.disabled ? 'Disable' : 'Enable'} ${totalCondition} ${
            totalCondition > 1 ? ' Conditions' : ' Condition'
          } successfully`
        )
      );
    } else {
      yield put(conditionUpdateFailed());
      yield put(
        showErrorSnackbar(`${action.payload.disabled ? 'Disable' : 'Enable'} condition failed!`)
      );
    }

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* cloneConditionSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const {id: formId = ''} = yield select(selectBuilderSetting);
    const {rules = []} = yield select(selectConditionSettingState);

    let newRules = rules.slice();
    const cloneRule = rules.find(singleRule => singleRule.id === action.payload.ruleId);
    newRules.push({...cloneRule, id: nanoid(10)});

    const settings = JSON.stringify({rules: newRules, formId: formId});
    const payload = {
      formId: formId,
      type: FORM_SETTINGS_TYPE_CONDITION,
      settings: settings
    };

    const response = yield call(ConditionSettingService.updateConditionSetting, payload);

    if (response.success) {
      yield put(cloneConditionSuccess({rules: newRules, id: action.payload.ids}));
      yield put(showSuccessSnackbar('Successfully cloned condition'));
    } else {
      yield put(conditionUpdateFailed());
      yield put(showErrorSnackbar('Condition clone failed!'));
    }

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* reorderCondition(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const {id: formId = ''} = yield select(selectBuilderSetting);
    const {rules = []} = yield select(selectConditionSettingState);
    const {sourceIndex, destinationIndex} = action.payload;

    let newRules = rules.slice();
    const orderRule = rules[sourceIndex];

    newRules.splice(sourceIndex, 1);
    newRules.splice(destinationIndex, 0, orderRule);

    yield put(reorderConditionSuccess({rules: newRules}));

    const settings = JSON.stringify({rules: newRules, formId: formId});
    const payload = {
      formId: formId,
      type: FORM_SETTINGS_TYPE_CONDITION,
      settings: settings
    };

    const response = yield call(ConditionSettingService.updateConditionSetting, payload);

    if (response.success) {
      yield put(showSuccessSnackbar('Successfully reordered conditions'));
    } else {
      yield put(conditionUpdateFailed({rules: rules}));
      yield put(showErrorSnackbar('Condition reordering failed!'));
    }

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* conditionSettingSaga() {
  yield all([conditionSettingWatcher()]);
}
