import React from 'react';
import {alpha, Box, Step, StepLabel, Stepper, styled} from '@mui/material';

export const NumberStepperStyle = styled(Stepper)(({theme, progressBarColor, backgroundColor}) => ({
  '& .MuiStepLabel-labelContainer': {
    display: 'none'
  },
  '& .MuiStepIcon-text': {
    fill: progressBarColor
  },
  '& .MuiStepLabel-iconContainer': {
    '&.Mui-disabled ': {
      '& .MuiStepIcon-text': {
        fill: alpha(progressBarColor, 0.3)
      },
      '& .MuiStepIcon-root': {
        color: alpha(backgroundColor, 0.3),
        border: `1px solid ${alpha(progressBarColor, 0.3)}`,
        borderRadius: '50%'
      }
    },
    '&.Mui-completed ': {
      '& .MuiStepIcon-text': {
        fill: progressBarColor
      },
      '& .MuiStepIcon-root': {
        fill: progressBarColor,
        backgroundColor: backgroundColor,
        borderRadius: '50%'
      }
    }
  },
  '& .MuiStepIcon-root': {
    '&.Mui-active ': {
      color: backgroundColor,
      border: `1px solid ${progressBarColor}`,
      borderRadius: '50%'
    }
  },
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 11px)',
    right: 'calc(50% + 12px)',
    '& .MuiStepConnector-line': {
      borderColor: progressBarColor
    },
    '&.Mui-active': {
      '& .MuiStepConnector-line': {
        borderColor: progressBarColor
      }
    },
    '&.Mui-disabled ': {
      '& .MuiStepConnector-line': {
        borderColor: alpha(progressBarColor, 0.3)
      }
    }
  }
}));

const getAllSteps = (totalSteps, color) => {
  let arr = [];
  for (let i = 1; i <= totalSteps; i++) {
    arr.push(
      <Step key={i}>
        <StepLabel>{i}</StepLabel>
      </Step>
    );
  }

  return arr;
};

const NumberStepper = ({totalSteps, color, backgroundColor, totalDone}) => {
  return (
    <Box sx={{width: '100%'}} py={2} mt={-4}>
      <NumberStepperStyle
        progressBarColor={color}
        activeStep={totalDone}
        backgroundColor={backgroundColor}
        alternativeLabel
      >
        {getAllSteps(totalSteps, color)}
      </NumberStepperStyle>
    </Box>
  );
};

export default NumberStepper;
