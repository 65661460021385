import {Box, styled} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';

export const InboxViewBox = styled(Box)(({theme}) => ({
  gap: '16px',
  display: 'flex',
  height: 'calc(100% - 50px)',
  '& .inboxCloseButton': {
    display: 'none'
  },
  '& .inboxBodyWrapper': {
    width: '100%',
    height: '100%',
    '& .inboxBodyContent': {
      boxShadow: 'none',
      height: '100%',
      transition: 'all 0.3s ease'
    }
  },

  [theme.breakpoints.down('lg')]: {
    '& .inboxBodyWrapper': {
      position: 'fixed',
      right: '0',
      width: '55%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      height: 'calc(100% - 160px)',
      '& .inboxCloseButton': {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& .MuiButton-contained': {
          '&:focus': {
            backgroundColor: `${theme.palette.primary.main} !important`
          }
        }
      },
      '& .inboxBodyContent': {
        width: 'calc(100% - 40px)',
        boxShadow: 'var(--fbp-elevation-6)'
      },
      '&.inboxViewTab': {
        '& .inboxBodyContent': {
          width: '15px',
          visibility: 'hidden'
        }
      }
    }
  },
  '@media screen and (max-width: 992px)': {
    '& .inboxBodyWrapper': {
      width: '80%'
    }
  }
}));

export const CloseButton = styled(CoreButton)(({theme}) => ({
  height: 'max-content',
  transform: 'rotate(-90deg)'
}));
