import React from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import withThemeChange from '../../../hoc/withThemeChange';
import RightSideUploadButtonComponent from '../../Builder/RightComponents/RightSideUploadButtonComponent';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';
import RightSideSlider from '../../Builder/RightComponents/RightSideSlider';
import CoreButton from '../../../common/Button/CoreButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {DEFAULT_IMAGE_SETTINGS} from '../../../helpers/builderConstant/DefaultTheme';

const CoverImageRightPanel = ({theme, onChangeValue}) => {
  const dispatch = useDispatch();

  const handleEditOptionOpen = componentType => {
    dispatch(changeSelectedBuilderField({index: null, pageIndex: null, componentType}));
  };

  return (
    <Box sx={{mt: 2}}>
      <Box px={2} pb={2}>
        <CoreButton
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleEditOptionOpen(COMPONENT_TYPE.THEME)}
        >
          Back
        </CoreButton>
      </Box>

      <RightSideUploadButtonComponent
        text="Select Cover Image"
        title="The cover image will be visible on top of the form"
        buttonText="Choose Image"
        recommendedSize={'1600 x 900'}
        onUpload={image => onChangeValue({coverImage: image})}
      />

      {theme.coverImage && (
        <>
          <RightSideImagePreviewComponent
            text="Cover Image"
            title="Cover Image Preview"
            image={theme.coverImage}
            onRemove={() => onChangeValue({coverImage: null, ...DEFAULT_IMAGE_SETTINGS.coverImage})}
          />

          <RightSideSlider
            min={100}
            max={400}
            alert="Only applicable to desktop devices."
            text="Cover height"
            title="Change the cover image height"
            value={theme.coverHeight ?? 312}
            onChangeValue={value => onChangeValue({coverHeight: value})}
          />

          <RightSideSlider
            step={1}
            min={1}
            max={200}
            text="Zoom In/Out"
            title="Zoom in or zoom out the image"
            value={theme.scaleCover ?? 100}
            onChangeValue={value => onChangeValue({scaleCover: value})}
          />

          <RightSideSlider
            min={0}
            max={100}
            text="Horizontal adjustment"
            title="Change the image adjustment left to right. (If cropping from the left or right)"
            value={theme.coverPositionX ?? 50}
            onChangeValue={value => onChangeValue({coverPositionX: value})}
          />

          <RightSideSlider
            min={0}
            max={100}
            text="Vertical adjustment"
            title="Change the image adjustment top to bottom. (If cropping from the top or bottom)"
            value={theme.coverPositionY ?? 50}
            onChangeValue={value => onChangeValue({coverPositionY: value})}
          />

          <Box p={2} sx={{textAlign: 'end'}}>
            <CoreButton
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              startIcon={<RestartAltIcon />}
              onClick={() => onChangeValue({...DEFAULT_IMAGE_SETTINGS.coverImage})}
            >
              Reset Settings
            </CoreButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withThemeChange(CoverImageRightPanel);
