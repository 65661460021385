import React from 'react';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import Box from '@mui/material/Box';
import FormLabelAlignment from '../../Builder/RightComponents/FormLabelAlignment';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const PhoneInputGeneral = ({field, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideInput
        text="Field Label"
        title="Change the label of the input"
        value={field.label}
        onChange={e => onChangeValue({label: e.target.value})}
      />

      <FormLabelAlignment
        hasSetAsDefault
        labelAlignment={field.labelAlignment}
        onChange={value => onChangeValue({labelAlignment: value})}
        shrinkMode={field.shrink}
      />

      <RightSideSwitch
        text="Required Field"
        title="Set the field required on submission"
        checked={field.required}
        onChange={value => onChangeValue({required: value})}
      />

      <RightSideInput
        text="Helper Text"
        title="Change the text below input"
        value={field.helperText}
        placeholder="Helper Text"
        onChange={e => onChangeValue({helperText: e.target.value})}
      />

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(PhoneInputGeneral);
