import React from 'react';
import {useSelector} from "react-redux";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {selectFormReportState} from "../../state/features/formsReport/formReportSelector";


export default function ReportChart() {
  const {data: chartList} = useSelector(selectFormReportState);

  const hasCartData = chartList?.length > 0;
  return (
     <ResponsiveContainer width="100%" height={500}>
          <LineChart
              width={500}
              height={400}
              data={chartList}
              margin={{
                top: 10,
                right: 40,
                left: 5,
                bottom: 5
              }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis  type="number" hide={false} />
            <XAxis dataKey="name" />
            <Tooltip />
            <Legend />
              {hasCartData && (
                  <>
                      <Line dataKey="Total Agency" stroke="#b574ce" fill="#b574ce" strokeWidth={3} />
                      <Line dataKey="Total Form" stroke="#338af7" fill="#338af7" strokeWidth={3} />
                      <Line dataKey="Total Views" stroke="#8179ff" fill="#8179ff" strokeWidth={3} />
                      <Line dataKey="Total Responses" stroke="#81c784" fill="#81c784" strokeWidth={3} />
                  </>
              )}
          </LineChart>
     </ResponsiveContainer>
  );
}
