import React from 'react';
import {CustomDropdown} from '../../../common/CustomDropdown';
import RightComponentTitle from './RightComponentTitle';
import Stack from '@mui/material/Box';
import {Box} from '@mui/material';

const dummyData = [{title: 'Large', value: 'large'}];

const RightSideDropdown = ({text, title, data = dummyData, value, onChange = null}) => {
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <Box sx={{width: '100%'}}>
        <CustomDropdown
          data={data}
          size="medium"
          value={value}
          onChange={onChange}
          wrapperClassName="formListFilter"
        />
      </Box>
    </Stack>
  );
};

export default RightSideDropdown;
