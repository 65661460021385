import React from 'react';
import {format} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Checkbox, FormControlLabel, styled, Typography, Stack} from '@mui/material';
import InboxTagColorBoxes from './InboxTagColorBoxes';
import {getSubmissionFormNameAndEmail} from '../../../helpers/utils/submissionFormUtils';
import {
  selectFormSubmission,
  toggleSelectedSingleInboxForm
} from '../../../state/features/formSubmission/formSubmissionSlice';
import {
  selectSelectedInboxFormIds,
  selectSelectedSubmissionId
} from '../../../state/features/formSubmission/formSubmissionSelector';

const StackStyled = styled(Stack)(({theme}) => ({
  padding: '12px 16px',
  marginRight: '6px',
  marginTop: '4px',
  transition: 'all linear 0.3s',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.action.hover
  },
  '&.inboxSubLeftSingleActive': {
    background: theme.palette.action.hover
  }
}));

const InboxLeftSideItem = ({submissionData}) => {
  const dispatch = useDispatch();
  const {
    id,
    submission: {submission: submissionDataList = []},
    createdAt
  } = submissionData;
  const submissionFormId = useSelector(selectSelectedSubmissionId);
  const selectedInboxFormIds = useSelector(selectSelectedInboxFormIds);

  const handleChangeChecked = (event, submissionData) => {
    event.stopPropagation();
    dispatch(toggleSelectedSingleInboxForm(submissionData?.id));
  };

  const onSelectFormSubmission = (event, data) => {
    event.stopPropagation();
    dispatch(selectFormSubmission(data));

    const pageWidth = window.innerWidth;
    if (pageWidth && pageWidth < 1024) {
      // console.log('hit page, open the modal', pageWidth);
    }
  };

  const {name, email} = getSubmissionFormNameAndEmail(submissionDataList);
  const formatCreatedAt = createdAt ? format(new Date(createdAt), 'MMM dd') : 'N/A';

  const isSelected = selectedInboxFormIds.includes(id);
  const isActive = submissionFormId === id || isSelected;

  return (
    <StackStyled
      justifyContent="space-between"
      className={isActive ? 'inboxSubLeftSingleActive' : undefined}
      onClick={e => onSelectFormSubmission(e, submissionData)}
    >
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected}
              onChange={e => handleChangeChecked(e, submissionData)}
              onClick={e => e.stopPropagation()}
              name="checkedB"
              color="primary"
            />
          }
          label=""
        />
        <Box onClick={e => onSelectFormSubmission(e, submissionData)}>
          {name && (
            <Typography variant="body2" color="text.primary" className="truncate">
              {name}
            </Typography>
          )}

          <Typography variant="chipMedium" color="text.secondary" className="truncate">
            {email}
          </Typography>

          {!name && !email && (
            <Typography variant="chipMedium" color="text.secondary" className="truncate">
              {`Submission #${submissionData?.id}`}
            </Typography>
          )}
          <InboxTagColorBoxes tagIds={submissionData?.tagIds || []} />
        </Box>
        <Typography variant="badge" color="text.primary" sx={{marginLeft: 'auto'}}>
          {formatCreatedAt}
        </Typography>
      </Stack>
    </StackStyled>
  );
};

export default InboxLeftSideItem;
