import httpRequest from '../api/httpRequest';

class MediaIconService {
  getMediaIcons(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'pipelines/list', params);
  }

  getSaveIcons(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API_17 + 'pexel-images/list', params);
  }

  saveMyIcon(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API_17 + 'pexel-images/save', body);
  }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MediaIconService();
