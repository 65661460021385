import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Typography
} from '@mui/material';
import {Star, UnarchiveOutlined} from '@mui/icons-material';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableChartIcon from '@mui/icons-material/TableChart';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {CustomIconList, CustomList} from './CustomListStyles';
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem
} from '../../common/PopoverMenu/CustomPopoverMenu';
import FormAddToFolder from './FormAddToFolder/FormAddToFolder';
import RenameFormContent from './RenameFormContent';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import RouteLink from '../../common/Link/RouteLink';
import {
  formClone,
  formMoveToArchive,
  formMoveToTrash,
  formPauseOrPublish,
  toggleFavouriteForm,
  toggleSelectedSingleForm
} from '../../state/features/folderForms/folderFormsSlice';
import {castArray} from '../../helpers/utils/castArray';
import {BUILDER_OPTIONS} from '../../helpers/constant/CoreConstants';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import StarIcon from '../../common/Icons/StarIcon';
import ExportFormContent from './ExportFormContent';
import FolderNameChipList from './FolderNameChipList';
import HrefLink from '../../common/Link/HrefLink';
import generatePublicPageUrl from '../../helpers/utils/generatePublicPageUrl';
import {FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION} from '../../helpers/constant/formSubmissionConstant';
import {selectIsArchivedSelect} from '../../state/features/folderForms/folderFormSelector';
import { formatFormListDate } from '../../helpers/utils/formatFormListDate';


const FormListEachForm = ({formData, checked}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isArchived = useSelector(selectIsArchivedSelect);
  const {
    id,
    formTitle,
    totalResponse,
    new: newResponse,
    favourite,
    archived,
    createdAt,
    updatedAt,
    folder,
    status,
    formApiKey
  } = formData || {};
  const [anchorElAddToFolder, setAnchorElAddToFolder] = useState(null);
  const [isOpenRenameModal, setOpenRenameModal] = useState(false);
  const [isOpenExportModal, setOpenExportModal] = useState(false);
  const [showSelectedForm, setShowSelectedForm] = useState(false);

  const handleToggleRenameModal = () => setOpenRenameModal(prevState => !prevState);
  const handleToggleExportModal = () => setOpenExportModal(prevState => !prevState);

  const onShowSelectedForm = () => setShowSelectedForm(true);
  const onHideSelectedForm = () => setShowSelectedForm(false);

  const handleSelectForm = () => {
    dispatch(toggleSelectedSingleForm(id));
  };

  const handleToggleFavourite = () => {
    dispatch(toggleFavouriteForm({ids: castArray(id), favourite: !favourite}));
  };

  const handleShowResponses = () => {
    history.push('/fbuilder/submission/' + id);
  };

  const handleEditForm = () => {
    history.push('/fbuilder/form/' + id);
  };
  const handleExportForm = () => {
    handleToggleExportModal();
  };
  const handleGoPublicPage = () => {
    window.open(generatePublicPageUrl(formApiKey), '_blank');
  };

  // handle delete form
  const handleDeleteForm = () => {
    dispatch(formMoveToTrash({ids: castArray(id)}));
  };

  const handleFormSettings = () => {
    history.push('/fbuilder/form/' + id, {
      tab: BUILDER_OPTIONS.FORM_SETTING
    });
  };
  const handleGoPublishForm = () => {
    history.push('/fbuilder/form/' + id, {
      tab: BUILDER_OPTIONS.QUICK_SHARE
    });
  };
  const handleRenameForm = () => {
    handleToggleRenameModal();
  };

  // handle form pause or publish
  const handlePauseOrPublishForm = status => {
    const {PUBLISHED, PAUSED} = FORM_STATUS;
    const reqObj = {
      ids: castArray(id),
      status: status === PUBLISHED ? PAUSED : PUBLISHED
    };

    dispatch(formPauseOrPublish(reqObj));
  };

  const handleCloneForm = () => {
    dispatch(formClone({formId: id}));
  };

  const handleFormMoveToArchive = () => {
    dispatch(formMoveToArchive({ids: castArray(id), archive: !archived}));
  };

  // ## handle add to folder
  const handleOpenAddToFolder = event => setAnchorElAddToFolder(event.currentTarget);
  const handleCloseAddToFolder = () => setAnchorElAddToFolder(null);

  const handleAddToFolderForm = event => {
    handleOpenAddToFolder(event);
  };

  const formatCreatedAt = createdAt ? formatFormListDate(createdAt) : 'N/A';
  const formatUpdatedAt = createdAt ? formatFormListDate(updatedAt) : 'N/A';
  const openAddToFolder = Boolean(anchorElAddToFolder);
  const folderIds = folder?.map(singleFolder => singleFolder.id);
  const isPublishedForm = status === FORM_STATUS.PUBLISHED;

  // const folderLength = folder.length;

  return (
    <CustomList className={checked || showSelectedForm ? 'active' : ''}>
      <Stack direction="row" alignItems="center" sx={{width: '100%'}}>
        <FormControlLabel
          sx={{marginRight: 0, opacity: isPublishedForm ? 1 : 0.6}}
          control={<Checkbox checked={checked} onChange={handleSelectForm} color="primary" />}
        />
        <Checkbox
          sx={{opacity: isPublishedForm ? 1 : 0.6}}
          inputProps={{'aria-label': 'Checkbox demo'}}
          icon={<StarIcon />}
          checkedIcon={<Star sx={{color: 'rgb(255, 180, 0)'}} />}
          checked={favourite}
          onClick={handleToggleFavourite}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{flex: 1, flexWrap: 'wrap', width: 'calc(100% - 80px)'}}
        >
          <Box
            ml={2}
            sx={{
              width: {xs: 'calc(100% - 90px)', lg: 'calc(100% - 270px)'},
              transition: 'all 0.3s ease-in-out'
            }}
          >
            <Stack direction={'row'} spacing={2} alignItems="center" sx={{width: `100%`}}>
              <RouteLink to={`/fbuilder/form/${id}`}>
                <Typography
                  variant={'body1medium'}
                  sx={{opacity: isPublishedForm ? 1 : 0.6, width: 'max-content'}}
                >
                  {formTitle}
                </Typography>
              </RouteLink>
              <FolderNameChipList folder={folder} id={id} />
            </Stack>

            <Stack direction={'row'} spacing={1} sx={{opacity: isPublishedForm ? 1 : 0.6}}>
              <Typography variant={'body2'} color="text.secondary">
                {totalResponse ? (
                  <RouteLink to={'/fbuilder/submission/' + id}>{totalResponse} Responses</RouteLink>
                ) : (
                  'No responses received'
                )}

                {newResponse && <Chip size={'small'} label={`${newResponse} New`} color="error" />}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant={'body2'} color="text.secondary">
                {isArchived ? `Updated on ${formatUpdatedAt}` : `Created on: ${formatCreatedAt}`}
              </Typography>
            </Stack>
          </Box>

          <CustomIconList
            className={`iconLists ${checked || showSelectedForm ? 'active' : ''} formsList`}
          >
            <li>
              <RouteLink to={'/fbuilder/statistics/' + id}>
                <CustomTooltip title="Statistics" arrow placement="top">
                  <IconButton size={'small'}>
                    <SignalCellularAltIcon />
                  </IconButton>
                </CustomTooltip>
              </RouteLink>
            </li>
            <li>
              <RouteLink to={'/fbuilder/submission/' + id}>
                <CustomTooltip title="All Response" arrow placement="top">
                  <IconButton size={'small'}>
                    <TableChartIcon />
                  </IconButton>
                </CustomTooltip>
              </RouteLink>
            </li>
            <li onClick={handleEditForm}>
              <CustomTooltip title="Edit" arrow placement="top">
                <IconButton size={'small'}>
                  <EditIcon />
                </IconButton>
              </CustomTooltip>
            </li>
            {!archived && (
              <li>
                <HrefLink
                  href={generatePublicPageUrl(formApiKey)}
                  target={"_blank"}
                  disabled={!!(archived || !isPublishedForm)}
                >
                  <CustomTooltip title="Public View" arrow placement="top">
                    <IconButton size={'small'}>
                      <VisibilityIcon />
                    </IconButton>
                  </CustomTooltip>
                </HrefLink>
              </li>
            )}

            <li onClick={handleDeleteForm}>
              <CustomTooltip title="Delete" arrow placement="top">
                <IconButton size={'small'}>
                  <DeleteIcon />
                </IconButton>
              </CustomTooltip>
            </li>
            <li>
              <CustomPopoverMenu
                wrapperClass="formListMoreOptions"
                menuClass="fbpTwoColumns"
                sx={{display: 'grid', gridTemplateColumns: '1fr 48%'}}
                callbackOnClose={onHideSelectedForm}
              >
                <CustomPopoverMenuButton>
                  <CustomTooltip
                    title="More Options"
                    arrow
                    placement="top"
                    onClick={onShowSelectedForm}
                  >
                    <IconButton size={'small'}>
                      <MoreVertIcon />
                    </IconButton>
                  </CustomTooltip>
                </CustomPopoverMenuButton>
                <CustomPopoverMenuItem
                  title="Public View"
                  icon={fbpIcons.fbpEyeIcon}
                  disabled={!!(archived || !isPublishedForm)}
                  onClick={handleGoPublicPage}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="All Response"
                  icon={fbpIcons.fbpGridIcon}
                  onClick={handleShowResponses}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Edit Form"
                  icon={fbpIcons.fbpPencilIcon}
                  onClick={handleEditForm}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Export Data"
                  icon={fbpIcons.fbpAddDataIcon}
                  onClick={() => handleExportForm(id)}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Form Setting"
                  icon={fbpIcons.fbpSettingIcon}
                  onClick={handleFormSettings}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Publish Form"
                  icon={fbpIcons.fbpShareIcon}
                  onClick={handleGoPublishForm}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Rename"
                  icon={fbpIcons.fbpRenameIcon}
                  onClick={handleRenameForm}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title={isPublishedForm ? 'Disable' : 'Enable'}
                  icon={isPublishedForm ? fbpIcons.fbpPauseIcon : fbpIcons.fbpPlayIcon}
                  disabled={!!archived}
                  onClick={() => handlePauseOrPublishForm(status)}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Clone"
                  icon={fbpIcons.fbpCloneIcon}
                  onClick={handleCloneForm}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title={!archived ? 'Archive' : 'Unarchive'}
                  icon={
                    !archived ? fbpIcons.fbpSuitcaseIcon : <UnarchiveOutlined color={'action'} />
                  }
                  onClick={handleFormMoveToArchive}
                  onClickHide
                />
                <CustomPopoverMenuItem
                  title="Add to folder"
                  icon={fbpIcons.fbpGrayFolderPluseIcon}
                  onClick={handleAddToFolderForm}
                  onClickHide={false}
                />
                <CustomPopoverMenuItem
                  title="Delete"
                  icon={fbpIcons.fbpGrayTrashIcon}
                  onClick={handleDeleteForm}
                  onClickHide
                />
              </CustomPopoverMenu>
            </li>
          </CustomIconList>
        </Stack>
      </Stack>

      {/* ## tag Popover list ## */}
      <Popover
        width="100%"
        open={openAddToFolder}
        anchorEl={anchorElAddToFolder}
        onClose={handleCloseAddToFolder}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <FormAddToFolder
          formIds={castArray(id)}
          folderIds={castArray(folderIds)}
          onClose={handleCloseAddToFolder}
        />
      </Popover>

      <BasicModalWithHeader
        titleIcon={fbpIcons.fbpAddDataIcon}
        modalTitle={'Export form data'}
        open={isOpenExportModal}
        onClose={handleToggleExportModal}
      >
        <ExportFormContent
          formId={id}
          onClose={handleToggleExportModal}
          module={FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION}
        />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        titleIcon={fbpIcons.fbpRenameIcon}
        modalTitle={'Rename form'}
        open={isOpenRenameModal}
        onClose={handleToggleRenameModal}
      >
        <RenameFormContent formInfo={formData} onClose={handleToggleRenameModal} />
      </BasicModalWithHeader>
    </CustomList>
  );
};

export default React.memo(FormListEachForm);
