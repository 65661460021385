import {all, call, put, takeLatest} from 'redux-saga/effects';
import {getFormReportDataFailed, getFormReportDataSuccess} from "./formsReportSlice";
import FormReportService from "../../../services/FormReport.service";

function* formsReportWatcher() {
  yield takeLatest('formReport/getFormReportData', getFormReportDataSaga);
}

function* getFormReportDataSaga(action) {
  try {
    const response = yield call(FormReportService.getFormReportData, action.payload);

    if (response.success) {
      const {totalForms = 0, totalViews = 0, totalAgency = 0 , totalResponse= 0 } = response.data?.stats || {}
      const { content = [] } = response.data || {}

      const chartList = content.map((chartData) => {
        const {name="", totalResponses=0, totalForms=0, totalViews=0, totalAgency=0} = chartData || {};
        return {
          name: name,
          "Total Form": totalForms || 0,
          "Total Views": totalViews || 0,
          "Total Agency": totalAgency || 0,
          "Total Responses": totalResponses || 0,
        }
      })

      yield put(getFormReportDataSuccess({
        totalForms, totalViews, totalAgencies: totalAgency, totalResponses: totalResponse, chartList: chartList
      }));
    } else {
      yield put(getFormReportDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put(getFormReportDataFailed("Something went wrong"));
  }
}


export default function* formReportSaga() {
  yield all([formsReportWatcher()]);
}
