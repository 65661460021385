import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';
import {
  CONDITION_PAGE_VIEW,
  CONDITION_PROTOTYPES
} from '../../../helpers/constant/formConditionConstant';

const initialState = {
  rules: [],
  isLoading: false,
  isError: false,
  error: '',

  isUpdating: false,
  isUpdateSuccess: false,

  currentPage: CONDITION_PAGE_VIEW.HOME,
  singleRule: null,
  selectedConditionId: '',
  selectedConditionIds: []
};

const conditionSettingSlice = createSlice({
  name: 'conditionSetting',
  initialState: initialState,
  reducers: {
    getConditionSetting: (state, action) => {
      state.isLoading = true;
    },
    getConditionSettingSuccess: (state, action) => {
      state.rules = Array.isArray(action.payload.rules) ? action.payload.rules : [];
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getConditionSettingFailed: (state, action) => {
      state.rules = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetConditionSetting: state => {
      state.currentPage = CONDITION_PAGE_VIEW.HOME;
      state.singleRule = null;
      state.selectedConditionIds = [];
      state.isError = false;
      state.error = '';
    },

    setConditionCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setNewConditionSingleRule: (state, action) => {
      if (action.payload) {
        let singleRule = CONDITION_PROTOTYPES[action.payload] || null;

        if (singleRule) {
          const uniqueId = nanoid(10);
          singleRule.id = uniqueId;
          state.singleRule = {...singleRule};
        }
        state.currentPage = CONDITION_PAGE_VIEW.BUILDER;
      }
    },
    addCondition: (state, action) => {
      if (state.singleRule.type) {
        let singleRule = CONDITION_PROTOTYPES[state.singleRule.type] || null;
        state.singleRule.condition.unshift(singleRule.condition[0]);
      }
    },
    removeCondition: (state, action) => {
      if (action.payload.hasOwnProperty('index')) {
        state.singleRule.condition.splice(action.payload.index, 1);
      }
    },

    addAction: (state, action) => {
      if (state.singleRule.type) {
        let singleRule = CONDITION_PROTOTYPES[state.singleRule.type] || null;
        state.singleRule.action.unshift(singleRule.action[0]);
      }
    },
    removeAction: (state, action) => {
      if (action.payload.hasOwnProperty('index')) {
        state.singleRule.action.splice(action.payload.index, 1);
      }
    },

    modifyConditionSingleRuleAction: (state, action) => {
      if (action.payload.actionItem) {
        state.singleRule.action.splice(action.payload.index, 1, action.payload.actionItem);
        state.isError = false;
        state.error = '';
      }
    },
    modifyConditionSingleRuleCondition: (state, action) => {
      if (action.payload.conditionItem) {
        state.singleRule.condition.splice(action.payload.index, 1, action.payload.conditionItem);
        state.isError = false;
        state.error = '';
      }
    },
    modifyConditionSingleRuleActionLink: (state, action) => {
      state.singleRule.link = action.payload;
    },

    editCondition: (state, action) => {
      state.currentPage = CONDITION_PAGE_VIEW.BUILDER;
    },
    editConditionSuccess: (state, action) => {
      state.singleRule = action.payload.singleRule;
      if (action.payload.errMessage) {
        state.error = action.payload.errMessage;
        state.isError = true;
      } else {
        state.error = '';
        state.isError = false;
      }
    },
    deleteCondition: (state, action) => {
      state.isUpdating = true;
    },
    deleteConditionSuccess: (state, action) => {
      state.isUpdating = false;
      state.rules = action.payload.rules;
      state.selectedConditionIds = state.selectedConditionIds.filter(
        singleId => action.payload.ids.indexOf(singleId) === -1
      );
    },
    disableCondition: (state, action) => {
      state.isUpdating = true;
    },
    disableConditionSuccess: (state, action) => {
      state.isUpdating = false;
      state.rules = action.payload.rules;
      state.selectedConditionIds = state.selectedConditionIds.filter(
        singleId => action.payload.ids.indexOf(singleId) === -1
      );
    },
    cloneCondition: (state, action) => {
      state.isUpdating = true;
    },
    cloneConditionSuccess: (state, action) => {
      state.isUpdating = false;
      state.rules = action.payload.rules;
      state.selectedConditionIds = state.selectedConditionIds.filter(
        singleId => singleId !== action.payload.id
      );
    },
    reorderCondition: (state, action) => {
      state.isUpdating = true;
    },
    reorderConditionSuccess: (state, action) => {
      state.isUpdating = false;
      state.rules = action.payload.rules;
    },

    conditionUpdateFailed: (state, action) => {
      state.isUpdating = false;
      state.rules = action.payload.rules;
    },
    saveCondition: (state, action) => {
      state.isUpdating = true;
    },
    saveConditionSuccess: (state, action) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.isError = false;
      state.rules = action.payload.rules;
      state.singleRule = {};
      state.currentPage = CONDITION_PAGE_VIEW.HOME;
    },
    saveConditionFailed: (state, action) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
      state.isError = true;
      state.error = action.payload.errMessage;
      state.singleRule = action.payload.singleRule;
    },

    // condition selection actions
    selectSingleConditionId: (state, action) => {
      state.selectedConditionId = action.payload;
    },
    selectedAllCondition: state => {
      const ids = [];
      state.rules.map(singleRule => {
        ids.push(singleRule.id);
        return singleRule;
      });
      state.selectedConditionIds = ids;
    },
    deselectAllCondition: state => {
      state.selectedConditionIds = [];
    },
    toggleSelectedCondition: (state, action) => {
      const currentConditionIndex = state.selectedConditionIds.findIndex(
        ruleId => ruleId === action.payload
      );
      if (currentConditionIndex !== -1) {
        state.selectedConditionIds.splice(currentConditionIndex, 1);
      } else {
        state.selectedConditionIds.push(action.payload);
      }
    }
  }
});

export const {
  getConditionSetting,
  getConditionSettingSuccess,
  getConditionSettingFailed,
  resetConditionSetting,

  setConditionCurrentPage,
  setNewConditionSingleRule,
  addCondition,
  removeCondition,
  addAction,
  removeAction,
  modifyConditionSingleRuleAction,
  modifyConditionSingleRuleCondition,
  modifyConditionSingleRuleActionLink,

  editCondition,
  editConditionSuccess,

  deleteCondition,
  deleteConditionSuccess,
  disableCondition,
  disableConditionSuccess,
  cloneCondition,
  cloneConditionSuccess,
  conditionUpdateFailed,
  reorderCondition,
  reorderConditionSuccess,
  saveCondition,
  saveConditionSuccess,
  saveConditionFailed,

  selectSingleConditionId,
  selectedAllCondition,
  deselectAllCondition,
  toggleSelectedCondition
} = conditionSettingSlice.actions;

export default conditionSettingSlice.reducer;
