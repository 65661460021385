import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import AdminTemplateService from '../../../services/AdminTemplate.service';
import {
  addTemplateToSubCategoryFailed,
  addTemplateToSubCategorySuccess,
  cloneTemplateSuccess,
  getAdminTemplatesFailed,
  getAdminTemplatesSuccess,
  moveTemplateToSubCategoryFailed,
  moveTemplateToSubCategorySuccess,
  moveToTrashTemplateSuccess,
  removeForeverAdminTemplateSuccess,
  renameTemplateFailed,
  renameTemplateSuccess,
  restoreAdminTemplateSuccess,
  togglePublishUnPublishTemplateSuccess,
  updateTemplateFailed,
  updateTemplateSuccess
} from './adminTemplatesSlice';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';

function* adminTemplatesSagaWatcher() {
  yield takeEvery('adminTemplates/getAdminTemplates', getAdminTemplatesSaga);
  yield takeEvery('adminTemplates/getAdminTemplatesSilently', getAdminTemplatesSaga);
  yield takeEvery('adminTemplates/updateTemplate', updateTemplateSaga);
  yield takeEvery('adminTemplates/renameTemplate', renameTemplateSaga);
  yield takeEvery(
    'adminTemplates/togglePublishUnPublishTemplate',
    togglePublishUnPublishTemplateSaga
  );
  yield takeEvery('adminTemplates/cloneTemplate', cloneTemplateSaga);
  yield takeEvery('adminTemplates/moveToTrashTemplate', moveToTrashTemplateSaga);
  yield takeEvery('adminTemplates/moveTemplateToSubCategory', moveTemplateToSubCategorySaga);
  yield takeEvery('adminTemplates/addTemplateToSubCategory', addTemplateToSubCategorySaga);
  yield takeEvery('adminTemplates/restoreAdminTemplate', restoreAdminTemplateSaga);
  yield takeEvery('adminTemplates/removeForeverAdminTemplate', removeForeverAdminTemplateSaga);
}

function* getAdminTemplatesSaga(action) {
  try {
    const response = yield call(AdminTemplateService.getAdminTemplates, action.payload);

    if (response.success) {
      yield put(getAdminTemplatesSuccess(response.data));
    } else {
      yield put(getAdminTemplatesFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
    yield put(getAdminTemplatesFailed('Internal server error'));
  }
}

function* updateTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.updateAdminTemplate, action.payload);

    if (response.success) {
      yield put(updateTemplateSuccess(action.payload));
    } else {
      yield put(updateTemplateFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* renameTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.updateAdminTemplate, action.payload);

    if (response.success) {
      yield put(renameTemplateSuccess(action.payload));
      yield put(showSuccessSnackbar('Successfully rename template'));
    } else {
      yield put(renameTemplateFailed(response.message));
      yield put(showErrorSnackbar('Failed to rename template'));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* togglePublishUnPublishTemplateSaga(action) {
  try {
    const response = yield call(
      AdminTemplateService.togglePublishUnPublishTemplate,
      action.payload
    );

    if (response.success) {
      yield put(togglePublishUnPublishTemplateSuccess());
      yield put(showSuccessSnackbar('Template status update successfully.'));
    } else {
      yield put(showErrorSnackbar('Template status update failed!'));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}
function* cloneTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.cloneTemplate, action.payload);

    if (response.success) {
      yield put(cloneTemplateSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}
function* moveToTrashTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.moveToTrashTemplate, action.payload);

    if (response.success) {
      yield put(moveToTrashTemplateSuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}
function* moveTemplateToSubCategorySaga(action) {
  try {
    const response = yield call(AdminTemplateService.moveTemplateToSubCategory, action.payload);

    if (response.success) {
      yield put(moveTemplateToSubCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(moveTemplateToSubCategoryFailed());
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addTemplateToSubCategorySaga(action) {
  try {
    const response = yield call(AdminTemplateService.addTemplateToSubCategory, action.payload);

    if (response.success) {
      yield put(addTemplateToSubCategorySuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(addTemplateToSubCategoryFailed());
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* restoreAdminTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.restoreAdminTemplate, action.payload);

    if (response.success) {
      yield put(restoreAdminTemplateSuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* removeForeverAdminTemplateSaga(action) {
  try {
    const response = yield call(AdminTemplateService.removeForeverAdminTemplate, action.payload);

    if (response.success) {
      yield put(removeForeverAdminTemplateSuccess());
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* adminTemplatesSaga() {
  yield all([adminTemplatesSagaWatcher()]);
}
