import React from 'react';
import {Divider, Paper} from '@mui/material';
import FormsSettingsForm from './FormSettingsForm';
import BasicTitleBar from '../../common/TitleBar/BasicTitleBar';

const FormsSettings = () => {
  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'Form Settings'} content={'Change form status and properties.'} />
      <Divider />
      <FormsSettingsForm />
    </Paper>
  );
};

export default FormsSettings;
