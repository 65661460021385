import React from 'react';
import {useDispatch} from 'react-redux';
import {Stack, Box, Typography} from '@mui/material';
import withThemeChange from '../../../hoc/withThemeChange';
import ColorScheme from '../../Builder/RightComponents/ColorScheme';
import RightSideColorPicker from '../../Builder/RightComponents/RightSideColorPicker';
import RightSideUploadButtonComponent from '../../Builder/RightComponents/RightSideUploadButtonComponent';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {DEFAULT_IMAGE_SETTINGS} from '../../../helpers/builderConstant/DefaultTheme';

const ThemeColors = ({theme, onChangeValue}) => {
  const dispatch = useDispatch();

  const handleEditOptionOpen = componentType => {
    dispatch(changeSelectedBuilderField({index: null, pageIndex: null, componentType}));
  };

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <ColorScheme
        text="Color Scheme"
        title="Change the color scheme of the form"
        layout={theme.layout}
        onChange={setting => onChangeValue(setting)}
      />
      {theme.layout === 'classic' && (
        <>
          {!theme.profileImage && (
            <RightSideUploadButtonComponent
              text="Select Profile Image"
              title="The profile image will be visible on top of the form."
              buttonText="Choose Image"
              recommendedSize={'720 x 720'}
              onUpload={image => onChangeValue({profileImage: image})}
            />
          )}

          {theme.profileImage && (
            <RightSideImagePreviewComponent
              text="Profile Image"
              title="Profile Image Preview"
              image={theme.profileImage}
              onRemove={() =>
                onChangeValue({profileImage: null, ...DEFAULT_IMAGE_SETTINGS.profileImage})
              }
              onEdit={() => handleEditOptionOpen(COMPONENT_TYPE.PROFILE_IMAGE)}
            />
          )}

          {!theme.coverImage && (
            <RightSideUploadButtonComponent
              text="Select Cover Image"
              title="The cover image will be visible on top of the form"
              buttonText="Choose Image"
              recommendedSize={'1600 x 900'}
              onUpload={image => onChangeValue({coverImage: image})}
            />
          )}

          {theme.coverImage && (
            <RightSideImagePreviewComponent
              text="Cover Image"
              title="Cover Image Preview"
              image={theme.coverImage}
              onRemove={() => onChangeValue({coverImage: null,...DEFAULT_IMAGE_SETTINGS.coverImage})}
              onEdit={() => handleEditOptionOpen(COMPONENT_TYPE.COVER_IMAGE)}
            />
          )}
        </>
      )}

      {!theme.formImage && (
        <>
          <RightSideColorPicker
            text="Form Color"
            title="Change the color of the form body"
            color={theme.formColor}
            onChange={color => onChangeValue({formColor: color})}
            className={'builderElementBasicStyle'}
          />

          <RightSideUploadButtonComponent
            text="Form Image"
            title="The image will visible as background of the form"
            buttonText="Choose Image"
            recommendedSize={'4160 x 6240'}
            onUpload={image => onChangeValue({formImage: image})}
          />
        </>
      )}

      {theme.formImage && (
        <RightSideImagePreviewComponent
          text="Form Image"
          title="Form Image Preview"
          image={theme.formImage}
          onRemove={() => onChangeValue({formImage: null,...DEFAULT_IMAGE_SETTINGS.formImage})}
          onEdit={() => handleEditOptionOpen(COMPONENT_TYPE.FORM_IMAGE)}
        />
      )}

      {theme.layout === 'classic' && (
        <>
          {!theme.pageImage && (
            <>
              <RightSideColorPicker
                text="Page Color"
                title="Change the color of the page background"
                color={theme.pageColor}
                onChange={color => onChangeValue({pageColor: color})}
                className={'builderElementBasicStyle'}
              />

              <RightSideUploadButtonComponent
                text="Page Image"
                title="Change the image of the page background"
                buttonText="Choose Image"
                recommendedSize={'1920 x 1080'}
                onUpload={image => onChangeValue({pageImage: image})}
              />
            </>
          )}

          {theme.pageImage && (
            <RightSideImagePreviewComponent
              text="Page Image"
              title="Page Image Preview"
              image={theme.pageImage}
              onRemove={() => onChangeValue({pageImage: null,...DEFAULT_IMAGE_SETTINGS.pageImage})}
              onEdit={() => handleEditOptionOpen(COMPONENT_TYPE.PAGE_IMAGE)}
            />
          )}
        </>
      )}

      <Stack direction="column" sx={{gap: '15px'}} className="builderElementBasicStyle">
        <Typography variant="body2medium" mb={2}>
          Font
        </Typography>
        <RightSideColorPicker
          text="Font Color"
          title="Change the font color of form label colors"
          color={theme.fontColor}
          onChange={color => onChangeValue({fontColor: color})}
        />

        <RightSideColorPicker
          text="Helper Text Color"
          title="Change the font color of helper texts under inputs"
          color={theme.subTextColor}
          onChange={color => onChangeValue({subTextColor: color})}
        />
      </Stack>

      <Stack direction="column" sx={{gap: '15px'}} className="builderElementBasicStyle">
        <Typography variant="body2medium" mb={2}>
          Input
        </Typography>
        <RightSideColorPicker
          text="Input Text Color"
          title="Change the text color of input value"
          color={theme.textColor}
          onChange={color => onChangeValue({textColor: color})}
        />

        <RightSideColorPicker
          text="Placeholder Color"
          title="Change the color color of input placeholder"
          color={theme.placeholderColor}
          onChange={color => onChangeValue({placeholderColor: color})}
        />

        <RightSideColorPicker
          text="Input Background"
          title="Change the color of input background"
          color={theme.inputBackground}
          onChange={color => onChangeValue({inputBackground: color})}
        />
      </Stack>
    </Box>
  );
};

export default withThemeChange(ThemeColors);
