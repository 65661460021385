import React from 'react';
import {nanoid} from 'nanoid';
import Box from '@mui/material/Box';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {useDispatch} from 'react-redux';
import {
  cloneFieldFormSelectedIndex,
  deleteFieldFromSelectedIndex
} from '../../../state/features/builderFields/builderFieldsSlice';
import {toggleBuilderSettingRightPanel} from '../../../state/features/builderSetting/builderSettingSlice';
import CardViewPublic from '../CardView/CardViewPublic';
import Styles from './SingleSectionElementsWrp.module.scss';

const CardComponentWrapper = ({field, children, selected, onClick, preview, className = ''}) => {
  const dispatch = useDispatch();

  const handleDelete = e => {
    e.stopPropagation();
    dispatch(deleteFieldFromSelectedIndex());
  };

  const handleTogglePropertyPanel = (e, value) => {
    e.stopPropagation();
    dispatch(toggleBuilderSettingRightPanel(value));
  };

  const handleCloneField = e => {
    e.stopPropagation();
    dispatch(cloneFieldFormSelectedIndex({id: nanoid()}));
  };

  if (preview) {
    return <CardViewPublic child={children} className={className} />;
  }

  return (
    <section
      className={`${Styles.fbpSingleSectionElements} ${Styles.fbpCardComponentWrapper} 
      ${className}
      ${selected ? Styles.fbpEditAreaActive : ''}`}
      onClick={onClick}
    >
      {selected && (
        <Box className={Styles.fbpElementsSettingsWrp}>
          <Box className={Styles.fbpElementOverlay}>
            <ul className={`${Styles.fbpEditorElementSettings} ${Styles.fbpEditorSectionSettings}`}>
              {field?.cloneableField !== false && (
                <CustomTooltip title="Clone" arrow>
                  <li className={Styles.fbpEditorElementSetting} onClick={handleCloneField}>
                    {fbpIcons.fbpBuilderCopyIcon}
                  </li>
                </CustomTooltip>
              )}

              <CustomTooltip title="Delete" arrow>
                <li className={Styles.fbpEditorElementSetting} onClick={handleDelete}>
                  {fbpIcons.fbpBuilderTrashIcon}
                </li>
              </CustomTooltip>
              <li className={`${Styles.fbpEditorElementSetting} ${Styles.fbpDragMenu}`}>
                {fbpIcons.fbpBuilderDragIndicatorIcon}
              </li>
              <CustomTooltip title="Properties" arrow>
                <li className={Styles.fbpEditorElementSetting} onClick={handleTogglePropertyPanel}>
                  {fbpIcons.fblBuilderSettingIcon}
                </li>
              </CustomTooltip>
            </ul>
          </Box>
        </Box>
      )}

      {children}
    </section>
  );
};

export default CardComponentWrapper;
