import React from 'react';
import {styled} from '@mui/styles';

const CountChipStyle = styled('span')(({theme}) => ({
  marginLeft: 'auto',
  color: theme.palette.text.secondary,
  background: theme.palette.other?.dividerFillColor,
  padding: '0px 8px',
  borderRadius: '14px',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '12px',
  height: '21px'
}));

const CountChip = ({label = '', ...rest}) => {
  return (
    <CountChipStyle className="fbp-form-quantity" {...rest}>
      {label}
    </CountChipStyle>
  );
};

export default CountChip;
