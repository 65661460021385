import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import DropdownAdvanced from './DropdownAdvanced';
import DropdownGeneral from './DropdownGeneral';
import DropdownOptions from './DropdownOptions';

const DropdownRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <DropdownGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <DropdownOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <DropdownAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default DropdownRightPanel;
