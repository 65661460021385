import React, {useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, CircularProgress, Stack, Typography, styled, useTheme} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import BuilderService from '../../services/Builder.service';
import {showErrorSnackbar} from '../../state/features/snackbar/snackbarSlice';
import Styles from './CustomFileUpload.module.scss';

const StyledBox = styled(Box)(({theme}) => ({
  '&.rightSideFilUpload': {
    height: '120px'
  },
  '&.builderFileUpload': {
    height: '244px',
    backdropFilter: 'blur(25px)',
    background: 'transparent',
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    '& svg path': {
      fill: `${theme.palette.other.subheading} `
    }
  }
}));

const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB

export const CustomUploadImage = ({
  className,
  recommendedSize = '1992 x 1494',
  onImageUpload = () => {}
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const inputFile = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [hasDrag, setHasDrag] = useState(false);

  const handleFileUpload = file => {
    try {
      if (!file.type.match(/image/g)) {
        dispatch(showErrorSnackbar('Please select a valid image.'));
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        dispatch(showErrorSnackbar('Maximum upload size : 10MB'));
        return;
      }

      setUploading(true);

      const dt = new FormData();
      dt.append('files', file, file.name);
      BuilderService.uploadFile(dt)
        .then(res => {
          if (res.success) {
            onImageUpload({
              image: res.urls[0]
            });
          } else {
            dispatch(showErrorSnackbar('Image upload failed, please try again later'));
          }
        })
        .finally(() => setUploading(false));
    } catch (e) {
      dispatch(showErrorSnackbar('Something went wrong, try upload file from local storage'));
    }
  };

  const renderUploadOption = () => {
    return (
      <Stack direction="column" alignItems="center">
        <UploadIcon sx={{color: theme.palette.secondary.main}} />
        <Typography sx={{margin: 0, padding: 0}} variant={'body2'} color={'secondary.main'}>
          Click here to upload
        </Typography>
        <Typography variant={'inputLevel'} color={'common.black'}>
          or drag & drop file here
        </Typography>
        <Typography variant={'helperText'} color={'error.main'} sx={{fontSize: '9px', pt: '3px'}}>
          Max file size : 10MB
        </Typography>
        <Typography variant={'helperText'} color={'warning.main'} sx={{fontSize: '9px', pt: '3px'}}>
          Recommended size : {recommendedSize}px
        </Typography>
        <input
          type="file"
          accept="image/*"
          ref={inputFile}
          style={{display: 'none'}}
          onChange={e => handleFileUpload(e.target.files[0])}
        />
      </Stack>
    );
  };

  const renderUploading = () => {
    return (
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <CircularProgress sx={{position: 'static', marginRight: '10px'}} size={23} />{' '}
        <Typography variant={'inputLevel'} color={'common.black'} ml={4}>
          Uploading...
        </Typography>
      </Box>
    );
  };

  const handleDragover = (e, drag) => {
    e.stopPropagation();
    e.preventDefault();
    if (!uploading) {
      setHasDrag(drag);
    }
  };

  const handleDragDrop = e => {
    e.stopPropagation();
    e.preventDefault();

    if (hasDrag) {
      setHasDrag(false);
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const onClickUploader = () => {
    if (!uploading && !hasDrag) {
      inputFile.current.click();
    }
  };

  return (
    <StyledBox
      className={`${Styles.fbpFileUploadWrapper} ${
        hasDrag ? Styles.fileDragZone : Styles.FileUpload
      } ${className}`}
      onDragEnter={e => handleDragover(e, true)}
      onDragOver={e => handleDragover(e, true)}
      onDragLeave={e => handleDragover(e, false)}
      onDrop={handleDragDrop}
      onClick={onClickUploader}
    >
      {uploading ? renderUploading() : hasDrag ? 'Drop file Here' : renderUploadOption()}
    </StyledBox>
  );
};
