import {styled} from '@mui/material';
import CoreButton from './CoreButton';

export const LightCoreButton = styled(CoreButton)(({theme}) => ({
  background: theme.palette.other.outlinedBorder,
  color: theme.palette.text.secondary,
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.other.secondaryHover,
    color: theme.palette.secondary.main,
    '& svg path': {
      fill: theme.palette.secondary.main
    }
  },
  '&.MuiButtonBase-root:focus': {
    backgroundColor: `${theme.palette.other.outlinedBorder} !important`
  }
}));
