import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import {Search} from '@mui/icons-material';
import CampaignSkeleton from '../../LoadingView/CampaignSkeleton';
import LoadingButton from '../../../common/Button/LoadingButton';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {debounceHandler} from '../../../helpers/utils/debounceHandler';
import {castArray} from '../../../helpers/utils/castArray';
import {
  selectCampaignData,
  selectCampaignSearchText,
  selectSelectedCampaignIds
} from '../../../state/features/campaign/campaignSelector';
import {
  addSearchTextForCampaign,
  deselectAllCampaign,
  getCampaignData,
  getMoreCampaignData,
  handleResetCampaign,
  inboxSubmissionAddToCampaign,
  resetSubmissionAddToCampaignSuccess,
  selectAllCampaign,
  toggleSelectSingleCampaign
} from '../../../state/features/campaign/campaignSlice';
import {
  selectSelectedContactId,
  selectSelectedContactIds
} from '../../../state/features/formSubmission/formSubmissionSelector';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbarSlice';

const AddToCampaign = ({onClose, isBulkAction = false}) => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectCampaignSearchText);
  const campaignIds = useSelector(selectSelectedCampaignIds);
  const contactId = useSelector(selectSelectedContactId);
  const selectedContactIds = useSelector(selectSelectedContactIds);

  const {
    data: campaignList,
    isLoading,
    page,
    perPage,
    total,
    addToCampaignLoading,
    addToCampaignSuccess
  } = useSelector(selectCampaignData);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    dispatch(
      getCampaignData({
        page: 1,
        perPage: perPage,
        searchText: searchText
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // when mount the page
  useEffect(() => {
    return () => dispatch(handleResetCampaign());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle close drawer after successfully add the campaign
  useEffect(() => {
    if (addToCampaignSuccess) {
      if (onClose) onClose();
      dispatch(resetSubmissionAddToCampaignSuccess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCampaignSuccess]);

  const fetchMore = () => {
    dispatch(getMoreCampaignData({page: page + 1, perPage: perPage}));
  };

  useEffect(() => {
    if (total > perPage) {
      const has = Math.ceil(total / perPage) > page;
      setHasMore(has);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, page]);

  const handleSelectAll = () => {
    dispatch(selectAllCampaign());
  };

  const handleDeselectAll = () => {
    dispatch(deselectAllCampaign());
  };

  const handleToggleSingleCampaign = id => {
    dispatch(toggleSelectSingleCampaign(id));
  };

  const doSearch = value => {
    dispatch(addSearchTextForCampaign(value));
  };

  const handelSearch = debounceHandler(doSearch, 500);

  const handleCancel = () => {
    if (onClose) onClose();
  };

  // handle Submit add campaign
  const handleSubmit = () => {
    if (campaignIds?.length === 0) {
      dispatch(showErrorSnackbar('You are not selected any campaign'));
      return false;
    }

    const contactIds = isBulkAction ? selectedContactIds : castArray(contactId);
    if (contactIds?.length === 0) {
      dispatch(showErrorSnackbar('No user contact found'));
    }

    dispatch(
      inboxSubmissionAddToCampaign({
        campaignIds: campaignIds,
        contactIds
      })
    );
  };

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <CampaignSkeleton count={10} />;
  } else if (!isLoading && campaignList?.length === 0) {
    content = <GlobalEmptyPage title={'No Campaign found!'} />;
  } else if (!isLoading && campaignList?.length > 0) {
    const isAllSelected = campaignList.every(campaign => campaignIds.indexOf(campaign.id) !== -1);

    const totalSelectedCampaign = campaignIds.length;

    content = (
      <>
        <ListItemButton
          onClick={isAllSelected ? handleDeselectAll : handleSelectAll}
          sx={{bgcolor: 'other.primaryLight', '&:hover': {bgcolor: 'other.primaryLight'}}}
        >
          <ListItemIcon>
            <Checkbox checked={isAllSelected} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText primary={`Choose All`} />
          {totalSelectedCampaign > 0 && <Typography>{totalSelectedCampaign} Selected</Typography>}
        </ListItemButton>
        <Divider />

        <Box
          className="fbp_has_scroll"
          id={'campaign_list_scroll_bar'}
          sx={{height: 'calc(100% - 40px)'}}
        >
          <InfiniteScroll
            dataLength={campaignList.length}
            next={fetchMore}
            hasMore={hasMore}
            loader={<CampaignSkeleton count={3} />}
            scrollableTarget="campaign_list_scroll_bar"
          >
            {campaignList.map((campaign, i) => (
              <ListItemButton
                key={campaign.id}
                selected={campaignIds.indexOf(campaign.id) !== -1}
                onClick={() => handleToggleSingleCampaign(campaign.id)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={campaignIds.indexOf(campaign.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': `transfer-list-all-item-${campaign.id}-label`
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={`transfer-list-all-item-${campaign.id}-label`}
                  primary={campaign.title}
                />
              </ListItemButton>
            ))}
          </InfiniteScroll>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{bgcolor: 'background.paper'}}>
      <Box sx={{px: 3, pt: 2, pb: 1}}>
        <CoreTextField
          fullWidth
          type={'search'}
          size={'small'}
          color={'secondary'}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{mr: 0}} position="start">
                <Search />
              </InputAdornment>
            )
          }}
          placeholder={'Search '}
          defaultValue={''}
          onChange={e => handelSearch(e.target.value)}
        />
      </Box>

      <List dense component="div" role="list" sx={{px: 3, height: 'calc(100vh - 226px)'}}>
        {content}
      </List>

      <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} p={3}>
        <Button variant={'outlined'} color={'error'} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={addToCampaignLoading}
          variant={'contained'}
          color={'secondary'}
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddToCampaign;
