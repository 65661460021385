import React, {useEffect} from 'react';
import {Grid, Stack,Box, Typography} from '@mui/material';
import CoreTextField from '../../common/TextField/CoreTextField';
import CoreButton from '../../common/Button/CoreButton';
import {registerPatternForm} from '../../helpers/constant/registerPattern';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import LoadingButton from '../../common/Button/LoadingButton';
import {selectFolderForm} from '../../state/features/folderForms/folderFormSelector';
import {
  updateFolderForm,
  resetUpdateFormSuccess
} from '../../state/features/folderForms/folderFormsSlice';

const RenameFormContent = ({onClose, formInfo = {}}) => {
  const dispatch = useDispatch();
  const {isFormUpdating, isFormUpdateSuccess} = useSelector(selectFolderForm);
  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (formInfo?.formTitle) {
      setValue('formTitle', formInfo.formTitle);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo]);

  useEffect(() => {
    if (isFormUpdateSuccess) {
      dispatch(resetUpdateFormSuccess());
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormUpdateSuccess]);

  // handle submit add folder
  const onSubmit = data => {
    if (formInfo?.formTitle === data?.formTitle) {
      onClose();
      return;
    }
    dispatch(updateFolderForm({formId: formInfo?.id, formTitle: data.formTitle}));
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="formTitle"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Form Name
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternForm.formTitle}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isFormUpdating}
          loadingText={'Renaming...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          Rename
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default RenameFormContent;
