import matchSingleField from './matchSingleField';
import {
  CONDITION_ACTION_SETTING,
  CONDITION_LINK,
  CONDITION_TYPE
} from '../../constant/formConditionConstant';
import {FORM_TYPE} from '../../constant/CoreConstants';

export const matchCondition = (
  rules,
  fieldIdMap,
  fieldId,
  fields,
  formType = FORM_TYPE.CLASSIC
) => {
  const trueRules = [],
    falseRules = [];

  fieldIdMap[fieldId].ruleIndexes.forEach(ruleIndex => {
    const rule = rules[ruleIndex];
    let conditionSuccess = rule.link === CONDITION_LINK.ALL;

    if (rule.type === CONDITION_TYPE.SKIP) {
      return;
    }
    for (let conditionIndex = 0; conditionIndex < rule.condition.length; conditionIndex++) {
      const ruleCondition = rule.condition[conditionIndex];
      const ruleFieldIndex = fieldIdMap[ruleCondition.fieldId];
      if (!ruleFieldIndex) {
        continue;
      }
      const field =
        formType === FORM_TYPE.CLASSIC
          ? fields[ruleFieldIndex.pageIndex].pageItems[ruleFieldIndex.fieldIndex]
          : fields[ruleFieldIndex.fieldIndex];

      const isMatched = matchSingleField(ruleCondition, field);
      if (rule.link === CONDITION_LINK.ANY) {
        conditionSuccess = conditionSuccess || isMatched;
        if (isMatched) {
          break;
        }
      } else {
        conditionSuccess = conditionSuccess && isMatched;
        if (!isMatched) {
          break;
        }
      }
    }

    if (conditionSuccess) {
      trueRules.push({
        rule,
        success: true,
        ruleIndex
      });
    } else {
      falseRules.push({
        rule,
        success: false,
        ruleIndex
      });
    }
  });

  //Running false rules first because
  //if same action applied on multiple rules, true conditions will get more priority
  [...falseRules, ...trueRules].forEach(eachRule => {
    eachRule.rule.action.forEach((eachAction, eachActionIndex) => {
      const ruleFieldIndex = fieldIdMap[eachAction.fieldId];
      //If the field is deleted
      if (ruleFieldIndex === undefined) {
        return;
      }

      let updateSetting = {...CONDITION_ACTION_SETTING[eachAction.visibility]};
      if (!eachRule.success) {
        for (let key in updateSetting) {
          updateSetting[key] = !updateSetting[key];
        }
      }

      if (formType === FORM_TYPE.CLASSIC) {
        fields[ruleFieldIndex.pageIndex].pageItems[ruleFieldIndex.fieldIndex] = {
          ...fields[ruleFieldIndex.pageIndex].pageItems[ruleFieldIndex.fieldIndex],
          ...updateSetting
        };
      } else {
        fields[ruleFieldIndex.fieldIndex] = {
          ...fields[ruleFieldIndex.fieldIndex],
          ...updateSetting
        };
      }
    });
  });
};
