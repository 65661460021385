import React from 'react';
import {Box, Stack} from '@mui/material';
import TotalViewsBox from './TotalViewsBox';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {updateListFilter} from '../../state/features/formStatistics/formStatisticSlice';

const ViewResponsesTabs = () => {
  const dispatch = useDispatch('');
  const {totalViews, totalResponses, conversionRate, selectedListFilter} = useSelector(
    state => state.formStatistics
  );

  const handleChangeFilter = filter => {
    if (selectedListFilter !== filter) {
      dispatch(updateListFilter(filter));
    }
  };

  return (
    <>
      <Box className={'view-responses'}>
        <Stack direction="row" alignItems="center" sx={{width: '100%'}} py={2}>
          <TotalViewsBox
            className={selectedListFilter === 'VIEWS' ? 'active' : ''}
            count={totalViews}
            text={'Views'}
            onClick={() => handleChangeFilter('VIEWS')}
          />
          <TotalViewsBox
            className={selectedListFilter === 'RESPONSES' ? 'active' : ''}
            count={totalResponses}
            onClick={() => handleChangeFilter('RESPONSES')}
            text={'Responses'}
          />
          <TotalViewsBox
            className={selectedListFilter === 'CONVERSIONRATE' ? 'active' : ''}
            count={`${conversionRate}%`}
            onClick={() => handleChangeFilter('CONVERSIONRATE')}
            text={'Conversion Rate'}
          />
        </Stack>
      </Box>
    </>
  );
};

export default ViewResponsesTabs;
