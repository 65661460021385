import React from 'react';
import {SvgIcon} from '@mui/material';

const TrashIcon = ({color="#546376", ...rest}) => {
  return (
    <SvgIcon {...rest}>
      <path
        d="M6 19.001C6 20.101 6.9 21.001 8 21.001H16C17.1 21.001 18 20.101 18 19.001V7.001H6V19.001ZM19 4.001H15.5L14.5 3.001H9.5L8.5 4.001H5V6.001H19V4.001Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default TrashIcon;
