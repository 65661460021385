import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {alpha, Box, Grid, Stack, styled} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ConditionDropDownField from './ConditionDropDownField';
import {
  selectAllBuilderFields,
  selectAllInputBuilderFields,
  selectBuilderFormattedPageList
} from '../../../../state/features/builderFields/builderSelector';
import {selectConditionSettingSingleRule} from '../../../../state/features/conditionSetting/conditionSettingSelector';
import {
  addAction,
  modifyConditionSingleRuleAction,
  removeAction
} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {
  CONDITION_ACTION_DO_LIST,
  CONDITION_TYPE
} from '../../../../helpers/constant/formConditionConstant';
import ExtraSmallButton from '../../../../common/Button/ExtraSmallButton';

const StyledBox = styled(Box, {shouldForwardProp: props => props !== 'error'})(
  ({theme, error}) => ({
    width: '100%',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    backgroundColor: error
      ? alpha(theme.palette.error.main, theme.palette.action.activatedOpacity)
      : theme.palette.background.paper
  })
);

const ActionController = () => {
  const dispatch = useDispatch();
  const fieldList = useSelector(selectAllInputBuilderFields);
  const allFieldList = useSelector(selectAllBuilderFields);
  const pageList = useSelector(selectBuilderFormattedPageList);
  const singleRule = useSelector(selectConditionSettingSingleRule);

  const {action = [], type = false} = singleRule || {};

  return (
    <Box>
      {action.map((actionItem, index) => {
        const {fieldId, visibility = '', page, isError} = actionItem || {};

        const handleChangeAction = (payload = {}) => {
          const newActionItem = {...actionItem, ...payload, isError: false};
          dispatch(modifyConditionSingleRuleAction({actionItem: newActionItem, index: index}));
        };

        const handleAddAction = () => {
          dispatch(addAction());
        };

        const handleRemoveAction = index => {
          dispatch(removeAction({index}));
        };

        // add or remove icon showing logic
        const isAddIconShow = index === 0 && type !== CONDITION_TYPE.SKIP;
        const isRemoveIconShow = index > 0 || action?.length > 1;

        return (
          <Grid key={index} container spacing={1}>
            <Grid item xs={10} sx={{mb: 1}}>
              <StyledBox p={2} error={isError}>
                <Stack spacing={2}>
                  <ConditionDropDownField
                    name={'THEN'}
                    value={visibility}
                    options={CONDITION_ACTION_DO_LIST[type]}
                    placeholder={'Please select a condition action'}
                    onChange={event => handleChangeAction({visibility: event.target.value})}
                  />
                  {type === CONDITION_TYPE.SKIP ? (
                    <ConditionDropDownField
                      name={'PAGE'}
                      disabled={!visibility}
                      value={page}
                      options={pageList}
                      placeholder={'Please select a page'}
                      onChange={event => handleChangeAction({page: event.target.value})}
                    />
                  ) : (
                    <ConditionDropDownField
                      name={'FIELD'}
                      disabled={!visibility}
                      value={fieldId}
                      options={type === CONDITION_TYPE.SHOW_HIDE ? allFieldList : fieldList}
                      placeholder={'Please select a field'}
                      onChange={event => handleChangeAction({fieldId: event.target.value})}
                    />
                  )}
                </Stack>
              </StyledBox>
            </Grid>

            <Grid item xs={2}>
              <Stack
                spacing={1}
                sx={{height: '100%', justifyContent: 'center', alignItems: 'center'}}
              >
                {isAddIconShow && (
                  <ExtraSmallButton
                    variant={'outlined'}
                    color={'secondary'}
                    size={'small'}
                    startIcon={<AddCircleIcon />}
                    onClick={handleAddAction}
                    sx={{width: '120px'}}
                  >
                    Add action
                  </ExtraSmallButton>
                )}
                {isRemoveIconShow && (
                  <ExtraSmallButton
                    variant={'outlined'}
                    color={'error'}
                    size={'small'}
                    startIcon={<RemoveCircleIcon />}
                    onClick={() => handleRemoveAction(index)}
                    sx={{width: '120px'}}
                  >
                    Remove action
                  </ExtraSmallButton>
                )}
              </Stack>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default ActionController;
