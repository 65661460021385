import {Box, styled, Typography} from '@mui/material';

export const EmbedCard = styled(Box)(({theme}) => ({
  backgroundColor: 'var( --fbp-theming-input-background)',
  border: `1px solid ${theme.palette.other.outlinedBorder}`,
  borderRadius: '8px',
  overflow: 'hidden'
}));
export const EmbedTitle = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family)',
  fontSize: 'var(--fbp-theming-label-font-size)',
  color: 'var(--fbp-theming-text-color)',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.15px',
  paddingBottom:'5px'
}));

export const EmbedDescription = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family)',
  color: 'var(--fbp-theming-text-color)',
  fontSize: 'var(--fbp-theming-label-sub-text-font-size)',
  fontWeight: 400,
  lineHeight: '15px',
  letterSpacing: '0.15px'
}));
