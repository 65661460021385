import React, {useRef, useState} from 'react';
import {Slider, Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import GlobalCentralModal from '../../../common/Modal/GlobalCentralModal';
import Styles from './HeadingImageModal.module.scss';

const HeadingImageModal = ({open, onClose}) => {
  const [isRepositionEnabled, setIsRepositionEnabled] = useState(false);
  const [positionPercent, setPositionPercent] = useState(50);
  const [value, setValue] = useState(1);
  const yPos = useRef(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrag = e => {
    if (!isRepositionEnabled) {
      return '';
    }

    if (yPos.current !== 0 && e.screenY !== 0) {
      if (yPos.current > e.screenY && positionPercent < 100) {
        setPositionPercent(currentPositionPercent => currentPositionPercent + 1);
      } else if (yPos.current < e.screenY && positionPercent > 0) {
        setPositionPercent(currentPositionPercent => currentPositionPercent - 1);
      }
    }
    yPos.current = e.screenY;
  };

  const handleDragStart = e => {
    setIsRepositionEnabled(true);
    return false;
  };

  const handleDragEnd = e => {
    setIsRepositionEnabled(false);
  };

  return (
    <GlobalCentralModal
      open={open}
      size="medium"
      title="Adjust Image"
      buttonText="Save Image"
      onClose={onClose}
    >
      <Box
        className={Styles.fbpImg}
        onDrag={handleDrag}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        style={{objectPosition: `0 ${positionPercent}%`}}
      >
        <img
          src={process.env.REACT_APP_CDN_LINK + 'drag-img.jpg'}
          alt=""
          style={{transform: `scale(${1 + value / 100})`}}
        />
        {!isRepositionEnabled && <span className={Styles.fbpDragInfo}>Drag to adjust </span>}
      </Box>
      <Box className={Styles.fbpHeadingImageModalWrapper}>
        <Box direction={'column'} alignItems="center" sx={{gap: '10px'}}>
          <Typography variant="body1">Zoom:</Typography>
          <Slider
            value={value}
            min={0}
            step={1}
            max={100}
            valueLabelFormat={v => v + '%'}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Box>
        <Stack direction={'row'} justifyContent="space-between">
          <Stack direction={'column'} alignItems="center" sx={{gap: '10px'}}>
            <Typography variant="body1">Flip:</Typography>
            <span style={{cursor: 'pointer', display: 'inline-flex'}}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.000976562" width="28" height="28" rx="4" fill="#D9E2FF" />
                <path
                  d="M17.5 24.5011H19.8333V22.1677H17.5V24.5011ZM22.1667 10.5011H24.5V8.16773H22.1667V10.5011ZM3.5 5.83439V22.1677C3.5 23.4511 4.55 24.5011 5.83333 24.5011H10.5V22.1677H5.83333V5.83439H10.5V3.50106H5.83333C4.55 3.50106 3.5 4.55106 3.5 5.83439ZM22.1667 3.50106V5.83439H24.5C24.5 4.55106 23.45 3.50106 22.1667 3.50106ZM12.8333 26.8344H15.1667V1.16772H12.8333V26.8344ZM22.1667 19.8344H24.5V17.5011H22.1667V19.8344ZM17.5 5.83439H19.8333V3.50106H17.5V5.83439ZM22.1667 15.1677H24.5V12.8344H22.1667V15.1677ZM22.1667 24.5011C23.45 24.5011 24.5 23.4511 24.5 22.1677H22.1667V24.5011Z"
                  fill="#006DF5"
                />
              </svg>
            </span>
            <span style={{cursor: 'pointer', display: 'inline-flex'}}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="28"
                  y="0.000976562"
                  width="28"
                  height="28"
                  rx="4"
                  transform="rotate(90 28 0.000976562)"
                  fill="#D9E2FF"
                />
                <path
                  d="M3.50016 17.501L3.50016 19.8343L5.83349 19.8343L5.83349 17.501L3.50016 17.501ZM17.5002 22.1676L17.5002 24.501L19.8335 24.501L19.8335 22.1676L17.5002 22.1676ZM22.1668 3.50098L5.83349 3.50098C4.55016 3.50098 3.50016 4.55098 3.50016 5.83431L3.50016 10.501L5.83349 10.501L5.83349 5.83431L22.1668 5.83431L22.1668 10.501L24.5002 10.501L24.5002 5.83431C24.5002 4.55098 23.4502 3.50098 22.1668 3.50098ZM24.5002 22.1676L22.1668 22.1676L22.1668 24.501C23.4502 24.501 24.5002 23.451 24.5002 22.1676ZM1.16683 12.8343L1.16683 15.1676L26.8335 15.1676L26.8335 12.8343L1.16683 12.8343ZM8.16683 22.1676L8.16683 24.501L10.5002 24.501L10.5002 22.1676L8.16683 22.1676ZM22.1668 17.501L22.1668 19.8343L24.5002 19.8343L24.5002 17.501L22.1668 17.501ZM12.8335 22.1676L12.8335 24.501L15.1668 24.501L15.1668 22.1676L12.8335 22.1676ZM3.50016 22.1676C3.50016 23.451 4.55016 24.501 5.83349 24.501L5.83349 22.1676L3.50016 22.1676Z"
                  fill="#006DF5"
                />
              </svg>
            </span>
          </Stack>
          <Stack direction={'column'} alignItems="center" sx={{gap: '10px'}}>
            <Typography variant="body1">Rotate:</Typography>
            <span style={{cursor: 'pointer', display: 'inline-flex'}}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.000976562" width="28" height="28" rx="4" fill="#D9E2FF" />
                <path
                  d="M18.1415 6.47606L12.8332 1.16772V4.74939C8.2365 5.32106 4.6665 9.24106 4.6665 14.0011C4.6665 18.7611 8.22484 22.6811 12.8332 23.2527V20.8961C9.51984 20.3361 6.99984 17.4661 6.99984 14.0011C6.99984 10.5361 9.51984 7.66606 12.8332 7.10606V11.6677L18.1415 6.47606ZM23.2515 12.8344C23.0532 11.2127 22.4115 9.64939 21.3615 8.29606L19.7048 9.95272C20.3348 10.8277 20.7315 11.8194 20.8948 12.8344H23.2515ZM15.1665 20.8844V23.2411C16.7882 23.0427 18.3632 22.4127 19.7165 21.3627L18.0365 19.6827C17.1615 20.3127 16.1815 20.7211 15.1665 20.8844ZM19.7048 18.0611L21.3615 19.7061C22.4115 18.3527 23.0532 16.7894 23.2515 15.1677H20.8948C20.7315 16.1827 20.3348 17.1744 19.7048 18.0611Z"
                  fill="#006DF5"
                />
              </svg>
            </span>
            <span style={{cursor: 'pointer', display: 'inline-flex'}}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.000976562" width="28" height="28" rx="4" fill="#D9E2FF" />
                <path
                  d="M8.2952 9.95272L6.6502 8.29606C5.6002 9.64939 4.94687 11.2127 4.74854 12.8344H7.1052C7.26854 11.8194 7.67687 10.8277 8.2952 9.95272ZM7.1052 15.1677H4.74854C4.94687 16.7894 5.58854 18.3527 6.63853 19.7061L8.28353 18.0494C7.67687 17.1744 7.26854 16.1944 7.1052 15.1677ZM8.28353 21.3744C9.63687 22.4244 11.2119 23.0544 12.8335 23.2527V20.8844C11.8185 20.7094 10.8385 20.3127 9.96353 19.6827L8.28353 21.3744ZM15.1669 4.74939V1.16772L9.85853 6.47606L15.1669 11.6677V7.10606C18.4802 7.66606 21.0002 10.5361 21.0002 14.0011C21.0002 17.4661 18.4802 20.3361 15.1669 20.8961V23.2527C19.7752 22.6811 23.3335 18.7611 23.3335 14.0011C23.3335 9.24106 19.7752 5.32106 15.1669 4.74939Z"
                  fill="#006DF5"
                />
              </svg>
            </span>
          </Stack>
        </Stack>
      </Box>
    </GlobalCentralModal>
  );
};

export default HeadingImageModal;
