import React from 'react';
import Box from '@mui/material/Box';
import CoreButton from '../../../common/Button/CoreButton';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {ImageComponentIcon} from "../../../helpers/fbpIcons/IconComponents";
import Styles from './Image.module.scss';

const ImageComponent = ({field, onChange, editMode}) => {
  const alignemntClass =
    field.positioning === 'default' ? Styles[field.alignment] : Styles.fullWidth;

  return (
    <Box sx={{position: 'relative'}}>
      {!field.image && (
        <Box className={`${Styles.ImageWrapper} ${alignemntClass}`}>
          <Box className={Styles.imgUpload}>
            <ImageComponentIcon/>
          </Box>
        </Box>
      )}

      {field.image && (
        <Box className={`${Styles.ImageWrapper} ${Styles.ImageFilledWrapper} ${alignemntClass}`}>
          <Box className={Styles.imgUpload}>
            <img src={field.image} alt={field.alt} style={{objectFit: field.objectFit}}/>
            {editMode && (
              <CoreButton
                variant="contained"
                size={'small'}
                sx={{position: 'absolute', right: '10px', top: '10px'}}
                onClick={() => onChange({image: null})}
              >
                remove
              </CoreButton>
            )}
          </Box>
        </Box>
      )}
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default ImageComponent;
