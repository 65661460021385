import React from 'react';
import {alpha, Box, Icon, Stack, styled} from '@mui/material';
import CardViewProgressBarComponent from '../../EachComponent/progressbar/CardViewProgressBarComponent';
import CoreButton from '../../../common/Button/CoreButton';
import FooterInFormsPage from "../../Setting/footerSettings/FooterInFormsPage";

const CardViewFooterWrapper = styled(Box)(({theme}) => ({
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  background: alpha(theme.palette.common.black, 0.2)
}));
const ButtonWrapper = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative'
}));

const NextButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: `${buttontheme.nextButton.width}px`,
  height: `${buttontheme.nextButton.height}px`,
  background: buttontheme.nextButton.background,
  color: buttontheme.nextButton.color,
  borderRadius: buttontheme.nextButton.borderRadius,
  border: `1px solid ${buttontheme.nextButton.border}`,
  '&:hover': {
    background: buttontheme.nextButton.background,
  }
}));
const BackButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: `${buttontheme.backButton.width}px`,
  height: `${buttontheme.backButton.height}px`,
  background: buttontheme.backButton.background,
  color: buttontheme.backButton.color,
  borderRadius: buttontheme.backButton.borderRadius,
  border: `1px solid ${buttontheme.backButton.border}`,
  '&:hover': {
    border: `1px solid ${buttontheme.backButton.border}`,
    background: buttontheme.backButton.background,
  }
}));
const SubmitButton = styled(CoreButton)(({theme, buttontheme}) => ({
  width: `${buttontheme.submitButton.width}px`,
  height: `${buttontheme.submitButton.height}px`,
  background: buttontheme.submitButton.background,
  color: buttontheme.submitButton.color,
  borderRadius: buttontheme.submitButton.borderRadius,
  border: `1px solid ${buttontheme.submitButton.border}`,
  '&:hover': {
    background: buttontheme.submitButton.background,
  }
}));

export default function CardViewPublicFooter({
  theme,
  onBack,
  progressBar,
  totalPages,
  currentPage,
  forceSubmit = false
}) {
  return (
    <CardViewFooterWrapper>
      <CardViewProgressBarComponent
        field={progressBar}
        totalFields={totalPages}
        totalDone={currentPage}
      />
      <ButtonWrapper
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        p={3}
      >
        {/* has back */}
        {currentPage !== 0 && (
          <BackButton
            buttontheme={theme}
            size={'small'}
            variant={'outlined'}
            backgroundColor={theme.backButton.background}
            startIcon={theme.backButton.iconVisible && <Icon>{theme.backButton.icon}</Icon>}
            onClick={e => {
              if (e.clientX !== 0 && e.clientY !== 0) {
                e.preventDefault();
                onBack();
              }
            }}
          >
            Back
          </BackButton>
        )}

        {/* has submit */}
        {totalPages - 1 === currentPage || forceSubmit ? (
          <SubmitButton
            type={'submit'}
            buttontheme={theme}
            size={'small'}
            variant={'contained'}
            backgroundColor={theme.submitButton.background}
            endIcon={theme.submitButton.iconVisible && <Icon>{theme.submitButton.icon}</Icon>}
          >
            Submit
          </SubmitButton>
        ) : (
          <NextButton
            type={'submit'}
            buttontheme={theme}
            size={'small'}
            variant={'contained'}
            backgroundColor={theme.nextButton.background}
            endIcon={theme.nextButton.iconVisible && <Icon>{theme.nextButton.icon}</Icon>}
          >
            Next
          </NextButton>
        )}
      </ButtonWrapper>

      {/* ## Footer Preview Show ## */}
      <FooterInFormsPage/>
    </CardViewFooterWrapper>
  );
}
