import React from 'react';
import {Typography, styled, Select, MenuItem} from '@mui/material';
import {StylesDropDownBox} from './CustomDropDownStyle';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    border: 'none',
    width: 'calc(100% - 30px)',
    display: 'block',
    padding: '4px 0 !important',
    alignItems: 'center',
    background: 'transparent',
    '& .MuiSelect-icon': {
      right: 0,
      top: 'auto'
    }
  }
}));
const DROPDOWN_BOTTOM_SETUP = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  // getContentAnchorEl: null
};

export const CustomDropdown = ({
  dropDownLeftIcon,
  label = '',
  labelPosition = 'top',
  size = 'medium',
  data = {},
  value = '',
  onChange,
  className = '',
  wrapperClassName = '',
  menuClassName = '',
  placeholder = '',
  displayEmpty = true,
  ...rest
}) => {
  const viewData = () => {
    return data.map((data, index) => {
      return (
        <MenuItem key={index} value={data.value} title={data.title} className="dropdownMenu">
          {data.title}
        </MenuItem>
      );
    });
  };

  let renderLoginClasses = null;
  renderLoginClasses =
    size === 'small'
      ? 'fbpCustomDropDownWrSmall'
      : size === 'medium'
      ? 'fbpCustomDropDownWrMedium'
      : size === 'large'
      ? ' fbpCustomDropDownWrLarge'
      : '';

  return (
    <StylesDropDownBox
      className={`${dropDownLeftIcon ? 'fbpCustomDropDownInnerFlex' : ''} ${
        labelPosition === 'left' ? 'fbpCustomDropDownTopWrLeft' : ''
      } ${wrapperClassName}`}
    >
      {label && (
        <Typography
          variant="body2"
          sx={{padding: '0 0 12px 0'}}
          className="fbpCustomDropDownTopTitle"
        >
          {label}
        </Typography>
      )}
      {typeof dropDownLeftIcon != 'undefined' && (
        <span
          style={{
            display: 'inline-flex',
            marginRight: '5px'
          }}
        >
          {dropDownLeftIcon}
        </span>
      )}
      <Select
        sx={{width: '100%'}}
        displayEmpty={displayEmpty}
        value={value}
        input={<BootstrapInput />}
        onChange={onChange}
        className={`${className} ${renderLoginClasses}`}
        MenuProps={{
          ...DROPDOWN_BOTTOM_SETUP,
          className: menuClassName,
          PaperProps: {
            sx: {
              width: 200,
              '& .MuiButtonBase-root': {
                display: 'block',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }
            }
          }
        }}
        {...rest}
      >
        {viewData()}
      </Select>
    </StylesDropDownBox>
  );
};
