import httpRequest from '../api/httpRequest';
import {isAdminRoute} from '../helpers/utils/isAdminRoute';

class BuilderService {
  uploadFile(params) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'files/upload/', params);
  }

  updateTheme(params) {
    if (isAdminRoute()) {
      return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/theme', params);
    }
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/theme', params);
  }

  getFormDetails(params) {
    if (isAdminRoute()) {
      return httpRequest.get(
        process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'templateelement',
        params
      );
    }
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'elements', params);
  }

  addBuilderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.post(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/create',
        params
      );
    }
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/create', params);
  }

  updateBuilderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.put(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/update',
        params
      );
    }
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/update', params);
  }

  updateAllBuilderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.put(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/property/change',
        params
      );
    }
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/property/change',
      params
    );
  }

  reorderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.put(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/reorder',
        params
      );
    }
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/reorder', params);
  }

  deleteBuilderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.delete(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/remove',
        params
      );
    }
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/remove',
      params
    );
  }

  cloneBuilderElement(params) {
    if (isAdminRoute()) {
      return httpRequest.post(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'templateelement/clone',
        params
      );
    }
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'elements/clone', params);
  }

  addNewPage(params) {
    if (isAdminRoute()) {
      return httpRequest.post(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/page/add',
        params
      );
    }
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/page/add', params);
  }

  deletePage(params) {
    if (isAdminRoute()) {
      return httpRequest.delete(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/page/remove',
        {},
        params
      );
    }
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/page/remove',
      {},
      params
    );
  }

  reorderPage(params) {
    if (isAdminRoute()) {
      return httpRequest.post(
        process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/page/reorder',
        params
      );
    }
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/page/reorder',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new BuilderService();
