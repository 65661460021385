import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  hasAccess: false,
  isLoading: false,
  isError: false,
  error: ''
};

const adminAccessSlice = createSlice({
  name: 'adminAccess',
  initialState: initialState,
  reducers: {
    getAdminAccessData: state => {
      state.isLoading = true;
    },
    getAdminAccessDataSuccess: (state, action) => {
      state.hasAccess = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getAdminAccessDataFailed: (state, action) => {
      state.hasAccess = false;
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    }
  }
});

export const {getAdminAccessData, getAdminAccessDataSuccess, getAdminAccessDataFailed} =
  adminAccessSlice.actions;

export default adminAccessSlice.reducer;
