import httpRequest from '../api/httpRequest';

class TagService {
  getTags(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/list', params);
  }
  addTag(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/create', body);
  }
  updateTag(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/update', body);
  }
  deleteTag(body) {
    return httpRequest.delete(process.env.REACT_APP_BACKEND_URL_FORM_API + 'tag/remove', {}, body);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TagService();
