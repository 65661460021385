import React, {useState} from 'react';
import {Icon, Box, Stack, Typography} from '@mui/material';
import fbpIcons from '../../../../helpers/fbpIcons/fbpIcons';
import GlobalCentralModal from '../../../../common/Modal/GlobalCentralModal';
import CustomSwitch from '../../../../common/Switch/CustomSwitch';
import {CustomColorPicker} from '../../../../common/CustomColorPicker';
import CustomIconPicker from '../../../../common/CustomIconPicker/CustomIconPicker';
import CustomTextField from '../../../../common/TextField/CustomTextField';
import {
  ButtonStylePillIcon,
  ButtonStyleRoundIcon,
  ButtonStyleSharpIcon,
  DownArrowIcon
} from '../../../../helpers/fbpIcons/IconComponents';
import Styles from './ButtonDesignModal.module.scss';

const BUTTON_BORDER_RADIUS = {
  SHARP: 0,
  ROUND: 6,
  PILL: 100
};

const ButtonDesignModal = ({
  title,
  open,
  onClose,
  onSubmit,
  isBackButton = false,
  button,
  buttonText = ''
}) => {
  const [selectedBackground, setSelectedBackground] = useState(button.background);
  const [selectedColor, setSelectedColor] = useState(button.color);
  const [selectedBorderColor, setSelectedBorderColor] = useState(button.border);
  const [selectedIcon, setSelectedIcon] = useState(button.icon);
  const [isIconVisible, setIsIconVisible] = useState(button.iconVisible);
  const [selectedBorderRadius, setSelectedBorderRadius] = useState(button.borderRadius);
  const [selectedWidth, setSelectedWidth] = useState(button.width);
  const [selectedHeight, setSelectedHeight] = useState(button.height);

  const handleSave = () => {
    onSubmit({
      background: selectedBackground,
      color: selectedColor,
      icon: selectedIcon,
      iconVisible: isIconVisible,
      borderRadius: selectedBorderRadius,
      width: selectedWidth,
      height: selectedHeight,
      border: selectedBorderColor
    });
  };

  return (
    <GlobalCentralModal
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      size="medium"
      title={title}
      buttonText="Save"
      className={`${Styles.main}`}
    >
      <Stack direction="row" sx={{height: '100%'}}>
        <Box className={`${Styles.editPart} fbp_has_scroll`}>
          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Button Color</Typography>
            <CustomColorPicker
              color={selectedBackground}
              onChange={color =>
                setSelectedBackground(
                  color.rgb.a === 1
                    ? color.hex
                    : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                )
              }
            >
              <Box className={`${Styles.chooseColor}`}>
                <Box
                  className={`${Styles.selectedColor} ${Styles.hasShadow}`}
                  sx={{
                    background: selectedBackground
                  }}
                ></Box>
                <DownArrowIcon />
              </Box>
            </CustomColorPicker>
          </Box>

          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Text Color</Typography>
            <CustomColorPicker
              color={selectedColor}
              onChange={color =>
                setSelectedColor(
                  color.rgb.a === 1
                    ? color.hex
                    : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                )
              }
            >
              <Box className={`${Styles.chooseColor}`}>
                <Box
                  className={`${Styles.selectedColor} ${Styles.hasShadow}`}
                  style={{
                    background: selectedColor
                  }}
                ></Box>
                <DownArrowIcon />
              </Box>
            </CustomColorPicker>
          </Box>

          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Border Color</Typography>
            <CustomColorPicker
              color={selectedBorderColor}
              onChange={color =>
                setSelectedBorderColor(
                  color.rgb.a === 1
                    ? color.hex
                    : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
                )
              }
            >
              <Box className={`${Styles.chooseColor}`}>
                <Box
                  className={`${Styles.selectedColor} ${Styles.hasShadow}`}
                  style={{
                    background: selectedBorderColor
                  }}
                ></Box>
                <DownArrowIcon />
              </Box>
            </CustomColorPicker>
          </Box>

          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Icon Visibility</Typography>
            <CustomSwitch
              status={isIconVisible}
              onChange={status => setIsIconVisible(status)}
              labelChecked="Yes"
              labelUnchecked="No"
            />
          </Box>

          {isIconVisible && (
            <Box className={Styles.buttonStyleOption}>
              <Typography variant="body2">Select Icon</Typography>
              <CustomIconPicker onChange={value => setSelectedIcon(value)}>
                <Box className={Styles.chooseColor}>
                  <Box className={Styles.selectedColor}>
                    <Icon>{selectedIcon}</Icon>
                  </Box>
                  <DownArrowIcon />
                </Box>
              </CustomIconPicker>
            </Box>
          )}

          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Width</Typography>
            <CustomTextField
              type="number"
              size="small"
              width="100px"
              placeholder="Select width"
              value={selectedWidth}
              onChange={e => setSelectedWidth(e.target.value)}
            />
          </Box>

          <Box className={Styles.buttonStyleOption}>
            <Typography variant="body2">Height</Typography>
            <CustomTextField
              type="number"
              size="small"
              width="100px"
              placeholder="Select height"
              value={selectedHeight}
              onChange={e => setSelectedHeight(e.target.value)}
            />
          </Box>

          <Box className={`${Styles.buttonStyleOption} ${Styles.buttonStyle}`}>
            <Typography variant="body2" mb={2}>
              Button Style
            </Typography>
            <Stack direction="row" justifyContent={'space-between'} className={Styles.buttonStyles}>
              <Box
                className={`${Styles.buttonStyleItem} ${
                  selectedBorderRadius === BUTTON_BORDER_RADIUS.SHARP ? Styles.selected : ''
                }`}
                onClick={() => setSelectedBorderRadius(BUTTON_BORDER_RADIUS.SHARP)}
              >
                <ButtonStyleSharpIcon />
                <Typography variant="body2">Sharp</Typography>
              </Box>
              <Box
                className={`${Styles.buttonStyleItem} ${
                  selectedBorderRadius === BUTTON_BORDER_RADIUS.ROUND ? Styles.selected : ''
                }`}
                onClick={() => setSelectedBorderRadius(BUTTON_BORDER_RADIUS.ROUND)}
              >
                <Box sx={{display: 'inline-flex'}}>
                  <ButtonStyleRoundIcon />
                </Box>
                <Typography variant="body2">Rounded</Typography>
              </Box>
              <Box
                className={`${Styles.buttonStyleItem} ${
                  selectedBorderRadius === BUTTON_BORDER_RADIUS.PILL ? Styles.selected : ''
                }`}
                onClick={() => setSelectedBorderRadius(BUTTON_BORDER_RADIUS.PILL)}
              >
                <ButtonStylePillIcon />
                <Typography variant="body2">Pill-shaped</Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Box className={Styles.previewPart} sx={{textAlign: 'center'}}>
          <Stack direction="row" alignItems="center" justifyContent="center" sx={{gap: '4px'}}>
            <Typography variant={'body1medium'}>Button Preview</Typography>
            <Box component={'span'} sx={{cursor: 'pointer', display: 'inline-flex'}}>
              {fbpIcons.fbpInfo}
            </Box>
          </Stack>
          <Box className={Styles.previewButton}>
            {!isBackButton && (
              <button
                className={Styles.outputButton}
                style={{
                  background: selectedBackground,
                  borderRadius: selectedBorderRadius,
                  color: selectedColor,
                  width: `${selectedWidth}px`,
                  height: `${selectedHeight}px`,
                  border: `1px solid ${selectedBorderColor}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px'
                }}
              >
                {buttonText}
                {isIconVisible && <Icon>{selectedIcon}</Icon>}
              </button>
            )}

            {isBackButton && (
              <button
                className={Styles.outputButton}
                style={{
                  background: selectedBackground,
                  borderRadius: selectedBorderRadius,
                  color: selectedColor,
                  width: `${selectedWidth}px`,
                  height: `${selectedHeight}px`,
                  border: `1px solid ${selectedBorderColor}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px'
                }}
              >
                {isIconVisible && <Icon>{selectedIcon}</Icon>}
                {buttonText}
              </button>
            )}
          </Box>
        </Box>
      </Stack>
    </GlobalCentralModal>
  );
};

export default ButtonDesignModal;
