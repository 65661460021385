import {getAccountData} from './getAccountData';

const generatePublicPageUrl = (apiKey = '') => {
  let agencyId = getAccountData('agencyId');
  let customDomain = getAccountData('formCustomDomain');

  if (!customDomain) {
    customDomain = 'https://crmforms.io';
  }

  return `${customDomain || ''}/?key=${window.btoa(apiKey + '/' + (agencyId || '1'))}`;
};

export default generatePublicPageUrl;
