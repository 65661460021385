import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import BuilderService from '../../../services/Builder.service';
import {storeBuilderFields} from '../builderFields/builderFieldsSlice';
import {selectBuilderSetting} from '../builderFields/builderSelector';
import {
  fetchFormDetailsSuccess,
  renameBuilderFormTitleSuccess,
  storeUpdatedBuilderTheme
} from './builderSettingSlice';
import FormService from '../../../services/Form.service';
import {getFormHeaderData} from '../formHeader/formHeaderSlice';
import {getFormFooterData} from '../formFooter/formFooterSlice';
import {
  FORM_SETTINGS_TYPE_CONDITION,
  FORM_SETTINGS_TYPE_HEADER
} from '../../../helpers/constant/formSettingsConstant';
import {FORM_SETTINGS_TYPE_FOOTER} from '../../../helpers/constant/formSettingsConstant';
import {getConditionSetting} from '../conditionSetting/conditionSettingSlice';
import { getFormThankYouSetting } from '../FormThankYou/formThankYouSlice';

function* builderSettingWatcher() {
  yield takeLatest('builderSetting/updateBuilderTheme', updateBuilderTheme);
  yield takeLatest('builderSetting/fetchFormDetails', fetchFormDetails);
  yield takeLatest('builderSetting/renameBuilderFormTitle', renameBuilderFormTitleSaga);
}

function* updateBuilderTheme(action) {
  try {
    yield delay(500);
    const {id} = yield select(selectBuilderSetting);
    yield put(storeUpdatedBuilderTheme(action.payload));
    yield call(BuilderService.updateTheme, {formId: id, theme: JSON.stringify(action.payload)});
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* fetchFormDetails(action) {
  try {
    const response = yield call(BuilderService.getFormDetails, {formId: action.payload});

    // get header and footer settings data
    yield put(getFormHeaderData({formId: action.payload, type: FORM_SETTINGS_TYPE_HEADER}));
    yield put(getFormFooterData({formId: action.payload, type: FORM_SETTINGS_TYPE_FOOTER}));
    yield put(getConditionSetting({formId: action.payload, type: FORM_SETTINGS_TYPE_CONDITION}));
    yield put(getFormThankYouSetting({formId: action.payload}));

    let theme = {};
    if (response.data.forms.theme) {
      theme = JSON.parse(response.data.forms.theme);
    }

    yield put(storeBuilderFields(response.data.formElements));

    yield put(
      fetchFormDetailsSuccess({
        id: response.data.forms.id,
        title: response.data.forms.formTitle,
        formApiKey: response.data.forms.formApiKey,
        theme
      })
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* renameBuilderFormTitleSaga(action) {
  try {
    const response = yield call(FormService.updateForm, action.payload);
    if (response.success) {
      yield put(renameBuilderFormTitleSuccess(action.payload.formTitle));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* builderSettingSaga() {
  yield all([builderSettingWatcher()]);
}
