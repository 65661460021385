import {ComponentConfig} from '../builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../builderConstant/ComponentType';

const getObj = (id, label, value, type, order) => ({id, label, ...value, type, order});

const getPublicPageSubmitContent = (fields, fileIds) => {
  let returnArr = [],
    order = 1,
    fileIndex = 0;

  let allFields = fields;

  if (fields[0]?.pageItems) {
    //Re arranging the array if classic view
    allFields = [];
    fields.forEach(eachPage => {
      allFields = [...allFields, ...eachPage.pageItems];
    });
  }

  allFields.forEach(field => {
    if (field.component === COMPONENT_TYPE.HIDDEN_FIELD) {
      returnArr.push(getObj(field.id, field.name, field.value, field.component, order++));
    } else if (field.hidden !== true) {
      if (field.component === COMPONENT_TYPE.FILE_UPLOAD) {
        let files = field.files.map(file => ({
          name: file.name,
          type: file.type,
          id: fileIds[fileIndex++]
        }));

        returnArr.push(getObj(field.id, field.label, {files}, field.component, order++));
      } else if (ComponentConfig[field.component].isInput) {
        let value = ComponentConfig[field.component].getValue(field, true);
        if (typeof value !== 'object') {
          value = {value};
        }
        returnArr.push(getObj(field.id, field.label, value, field.component, order++));
      }
    }
  });

  return returnArr;
};

export default getPublicPageSubmitContent;
