import React from 'react';
import {Box, Stack} from '@mui/material';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewLinkComponent = ({field, onChange, editMode}) => {
  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>
        <Box sx={{width: '100%'}}>
          <FormInput
            type="text"
            name={field.id}
            value={field.value}
            placeholder={field.placeholder}
            onChange={e => onChange({value: e.target.value})}
            readOnly={editMode || field.readOnly}
            helperTextProps={{
              text: field.helperText
            }}
            rules={{
              ...(field.required ? {required: 'This value is required'} : {}),
              ...registerPatternBuilderFields.link
            }}
          />
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewLinkComponent);
