import React from 'react';
import Box from '@mui/material/Box';
import {Skeleton} from '@mui/material';

const CampaignSkeleton = ({count = 10}) => {
  return Array.from({length: count}).map((rowNum, index) => (
    <Box key={index} sx={{display: 'flex', alignItems: 'center', margin: '24px 28px'}}>
      <Skeleton variant="rect" width={20} height={20} sx={{mr: 3}} />
      <Skeleton variant="rect" width={'100%'} height={20} />
    </Box>
  ));
};

export default CampaignSkeleton;
