const emailPattern = /^\w+([\\.-\\+]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export const registerPattern = {
  email: {
    required: 'Email is required!',
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    },
    maxLength: {
      value: 50,
      message: 'Email must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  emailNotRequired: {
    required: false,
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    },
    maxLength: {
      value: 50,
      message: 'Email must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 6,
      message: 'Password must have at least 6 characters'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  termsAndCondition: {
    required: 'Need to accept our terms and condition'
  },
  first_name: {
    required: 'Firstname is required',
    maxLength: {
      value: 50,
      message: 'Firstname must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  last_name: {
    required: 'Lastname is required',
    maxLength: {
      value: 50,
      message: 'Lastname must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  phone: {
    required: 'Phone number is required',
    pattern: {
      value: /^[0-9]+$/,
      message: 'Invalid phone number!'
    },
    minLength: {
      value: 11,
      message: 'Phone number must have minimum 11 digits!'
    },
    maxLength: {
      value: 16,
      message: 'Phone number must have less than 16 digits!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  phoneNotRequired: {
    required: false,
    pattern: {
      value: /^[0-9]+$/,
      message: 'Invalid phone number !'
    },
    minLength: {
      value: 11,
      message: 'Phone number must have minimum 11 digit!'
    },
    maxLength: {
      value: 16,
      message: 'Phone number must have less than 16 digit!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },

  company_name: {
    required: 'Agency name is required',
    maxLength: {
      value: 100,
      message: ' Agency name max length 100!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  description: {
    required: 'Agency description is required',
    setValueAs: v => (v ? v.trim() : ''),
    maxLength: {
      value: 252,
      message: 'Description must have less than 250 characters!'
    }
  },
  web_site_url: {
    required: false,
    pattern: {
      value:
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/,
      message: 'Invalid website url!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  address: {
    required: 'Address is required',
    setValueAs: v => (v ? v.trim() : '')
  },
  city: {
    required: 'City is required',
    maxLength: {
      value: 20,
      message: 'City must have less than 20 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  cityNotRequired: {
    required: false,
    maxLength: {
      value: 20,
      message: 'City must have less than 20 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  stateNotRequired: {
    required: false,
    maxLength: {
      value: 20,
      message: 'State must have less than 20 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },

  client_secret: {
    required: 'Client Secret key is required.',
    pattern: {
      value: /^\S*$/,
      message: 'White space is not allow'
    }
  },
  charge_amount: {
    required: 'Stripe Payment Charge is required.',
    pattern: {
      value: /^\d*[.]?\d*$/,
      message: 'Invalid charge amount value !'
    },
    max: {
      value: 50,
      message: 'Payment Charge must be less than 50'
    }
  },

  topUp_amount: {
    required: 'Amount is required.',
    pattern: {
      value: /^\d*[.]?\d*$/,
      message: 'Invalid amount value !'
    },
    min: {
      value: 10,
      message: 'Minimum amount value is 10'
    }
  },

  subject: {
    required: 'Subject is required',
    maxLength: {
      value: 100,
      message: 'Subject must have less than 100 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },

  sender_address: {
    required: 'Sender address is required',
    pattern: {
      value: /^[0-9]+$/,
      message: 'Invalid sender address!'
    },
    minLength: {
      value: 10,
      message: 'Sender address must have minimum 10 digits!'
    },
    maxLength: {
      value: 16,
      message: 'Sender address must have less than 16 digits!'
    },
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternInboxReply = {
  fromEmail: {
    required: 'Email is required!',
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    },
    maxLength: {
      value: 50,
      message: 'Email must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  subject: {
    required: false,
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternTemplateCategory = {
  categoryName: {
    required: 'name is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'name is empty'
    },
    maxLength: {
      value: 30,
      message: 'name must be less than 30 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  description: {
    required: 'Description is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'Description is empty'
    },
    minLength: {
      value: 20,
      message: 'Description must be greater than 20 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  categoryId: {
    required: 'Select category is required!'
  },
  subCategory: {
    required: 'Select sub-category is required!'
  }
};

export const registerPatternPublishSettings = {
  name: {
    required: 'name is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'name is empty'
    },
    maxLength: {
      value: 30,
      message: 'name should be less than 30 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  description: {
    required: 'Description is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'Description is empty'
    },
    minLength: {
      value: 20,
      message: 'Description must be greater than 20 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  category: {
    required: 'Select category is required!'
  },
  subCategory: {
    required: 'Select sub-category is required!'
  }
};

export const registerPatternEmailShare = {
  fromEmail: {
    required: 'From email is required!',
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    },
    maxLength: {
      value: 50,
      message: 'Email must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  toEmail: {
    required: 'To email is required!',
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    },
    maxLength: {
      value: 50,
      message: 'Email must have less than 50 characters!'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  subject: {
    required: 'Subject is required!',
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternFolder = {
  name: {
    required: 'Folder name is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'Folder name is empty'
    },
    maxLength: {
      value: 30,
      message: 'Folder name cannot be longer than 30 characters'
    },
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternForm = {
  formTitle: {
    required: 'Form title is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'Form title is empty'
    },
    maxLength: {
      value: 30,
      message: 'Form title cannot be longer than 30 characters'
    },
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternTemplate = {
  formTitle: {
    required: 'Template name is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'Template name is empty'
    },
    maxLength: {
      value: 30,
      message: 'Template name cannot be longer than 30 characters'
    },
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternFormExport = {
  fileName: {
    required: 'File name is required!',
    pattern: {
      value: /^(?!\s*$).+/,
      message: 'File name is empty'
    },
    setValueAs: v => (v ? v.trim() : '')
  },
  fileType: {
    required: 'File type is required!',
    setValueAs: v => (v ? v.trim() : '')
  }
};

export const registerPatternBuilderFields = {
  email: {
    pattern: {
      value: emailPattern,
      message: 'Email is invalid!'
    }
  },
  phone: {
    minLength: {
      value: 11,
      message: 'Phone number must be 11 characters long!'
    }
  },
  link: {
    pattern: {
      value:
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
      message: 'This URL is invalid!'
    }
  },
  checkbox: {
    min: {
      value: 1,
      message: 'Minimum 1 option is required'
    }
  },
  file: {
    min: {
      value: 1,
      message: 'Minimum 1 file is required'
    }
  }
};

export const registerPatternMediaImageIcon = {
  imageUrl: {
    required: "image url is required!",
    pattern: {
      // eslint-disable-next-line
      value: /^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi,
      message: 'This image URL is invalid!'
    }
  }
}

export const registerPatterFormHeaderSettings = {
  email: {
    required: false,
    pattern: {
      value: emailPattern,
      message: 'Invalid email address!'
    }
  },
  phone: {
    required: false,
    minLength: {
      value: 11,
      message: 'Phone number must be 11 characters long!'
    }
  },
}


