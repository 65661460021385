import React from 'react';
import {Stack, styled} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';
import ImageBox from '../../../common/ImageBox';
import ExtraSmallButton from '../../../common/Button/ExtraSmallButton';

const IconStack = styled(Stack)(() => ({
  position: 'absolute',
  right: '10px',
  top: '10px'
}));

const RightSideImagePreviewComponent = ({text, title, image, onRemove = () => {}, onEdit}) => {
  return (
    <Stack alignItems="start" direction="column" className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <ImageBox sx={{height: '120px'}}>
        <img src={image} alt={text} />
        <IconStack direction={'row'} spacing={1}>
          {typeof onEdit === 'function' && (
            <ExtraSmallButton
              size={'small'}
              variant={'contained'}
              color={'secondary'}
              onClick={onEdit}
            >
              Edit
            </ExtraSmallButton>
          )}

          <ExtraSmallButton size={'small'} variant={'contained'} color={'error'} onClick={onRemove}>
            Remove
          </ExtraSmallButton>
        </IconStack>
      </ImageBox>
    </Stack>
  );
};

export default RightSideImagePreviewComponent;
