import AddressComponent from '../../components/EachComponent/address/AddressComponent';
import AddressRightPanel from '../../components/EachComponent/address/AddressRightPanel';
import AudioComponent from '../../components/EachComponent/audio/AudioComponent';
import AudioRightPanel from '../../components/EachComponent/audio/AudioRightPanel';
import BirthdateComponent from '../../components/EachComponent/birthday/BirthdateComponent';
import BirthdateRightPanel from '../../components/EachComponent/birthday/BirthdateRightPanel';
import CardViewAddressComponent from '../../components/EachComponent/address/CardViewAddressComponent';
import CardViewAudioComponent from '../../components/EachComponent/audio/CardViewAudioComponent';
import CardViewBirthdateComponent from '../../components/EachComponent/birthday/CardViewBirthdateComponent';
import CardViewCompanyComponent from '../../components/EachComponent/company/CardViewCompanyComponent';
import CardViewCheckboxComponent from '../../components/EachComponent/checkbox/CardViewCheckboxComponent';
import CardViewDateComponent from '../../components/EachComponent/date/CardViewDateComponent';
import CardViewDropDownComponent from '../../components/EachComponent/dropdown/CardViewDropDownComponent';
import CardViewEmailComponent from '../../components/EachComponent/email/CardViewEmailComponent';
import CardViewEmbedAnythingComponent from '../../components/EachComponent/embedAnything/CardViewEmbedAnythingComponent';
import CardViewFileUploadComponent from '../../components/EachComponent/fileUpload/CardViewFileUploadComponent';
import CardViewFullNameComponent from '../../components/EachComponent/fullName/CardViewFullNameComponent';
import CardViewHeadingComponent from '../../components/EachComponent/heading/CardViewHeadingComponent';
import CardViewHiddenFieldComponent from '../../components/EachComponent/hiddenField/CardViewHiddenFieldComponent';
import CardViewImageComponent from '../../components/EachComponent/image/CardViewImageComponent';
import CardViewLinkComponent from '../../components/EachComponent/link/CardViewLinkComponent';
import CardViewLongTextComponent from '../../components/EachComponent/longText/CardViewLongTextComponent';
import CardViewNoteComponent from '../../components/EachComponent/note/CardViewNoteComponent';
import CardViewNumberComponent from '../../components/EachComponent/number/CardViewNumberComponent';
import CardViewParagraphComponent from '../../components/EachComponent/paragraph/CardViewParagraphComponent';
import CardViewPhoneComponent from '../../components/EachComponent/phone/CardViewPhoneComponent';
import CardViewPhoneInputComponent from '../../components/EachComponent/phoneInput/CardViewPhoneInputComponent';
import CardViewProgressBarComponent from '../../components/EachComponent/progressbar/CardViewProgressBarComponent';
import CardViewRadioComponent from '../../components/EachComponent/radio/CardViewRadioComponent';
import CardViewShortTextComponent from '../../components/EachComponent/shortText/CardViewShortTextComponent';
import CardViewTimeComponent from '../../components/EachComponent/time/CardViewTimeComponent';
import CardViewVideoComponent from '../../components/EachComponent/video/CardViewVideoComponent';
import CardViewYesNoComponent from '../../components/EachComponent/yesNo/CardViewYesNoComponent';
import CompanyComponent from '../../components/EachComponent/company/CompanyComponent';
import CompanyRightPanel from '../../components/EachComponent/company/CompanyRightPanel';
import CheckboxComponent from '../../components/EachComponent/checkbox/CheckboxComponent';
import CheckboxRightPanel from '../../components/EachComponent/checkbox/CheckboxRightPanel';
import DateComponent from '../../components/EachComponent/date/DateComponent';
import DateRightPanel from '../../components/EachComponent/date/DateRightPanel';
import DropdownComponent from '../../components/EachComponent/dropdown/DropdownComponent';
import DropdownRightPanel from '../../components/EachComponent/dropdown/DropdownRightPanel';
import EmailComponent from '../../components/EachComponent/email/EmailComponent';
import EmailRightPanel from '../../components/EachComponent/email/EmailRightPanel';
import EmbededAnythingComponent from '../../components/EachComponent/embedAnything/EmbedAnythingComponent';
import EmbedAnythingRightPanel from '../../components/EachComponent/embedAnything/EmbedAnythingRightPanel';
import FileUploadComponent from '../../components/EachComponent/fileUpload/FileUploadComponent';
import FileUploadRightPanel from '../../components/EachComponent/fileUpload/FileUploadRightPanel';
import HeadingComponent from '../../components/EachComponent/heading/HeadingComponent';
import HeadingRightPanel from '../../components/EachComponent/heading/HeadingRightPanel';
import HiddenFieldComponent from '../../components/EachComponent/hiddenField/HiddenFieldComponent';
import HiddenFieldRightPanel from '../../components/EachComponent/hiddenField/HiddenFieldRightPanel';
import ImageComponent from '../../components/EachComponent/image/ImageComponent';
import ImageRightPanel from '../../components/EachComponent/image/ImageRightPanel';
import LinkComponent from '../../components/EachComponent/link/LinkComponent';
import LinkRightPanel from '../../components/EachComponent/link/LinkRightPanel';
import LongTextComponent from '../../components/EachComponent/longText/LongTextComponent';
import LongTextRightPanel from '../../components/EachComponent/longText/LongTextRightPanel';
import NameComponent from '../../components/EachComponent/fullName/NameComponent';
import NameRightPanel from '../../components/EachComponent/fullName/NameRightPanel';
import NoteComponent from '../../components/EachComponent/note/NoteComponent';
import NoteRightPanel from '../../components/EachComponent/note/NoteRightPanel';
import NumberComponent from '../../components/EachComponent/number/NumberComponent';
import NumberRightPanel from '../../components/EachComponent/number/NumberRightPanel';
import ParagraphComponent from '../../components/EachComponent/paragraph/ParagraphComponent';
import ParagraphRightPanel from '../../components/EachComponent/paragraph/ParagraphRightPanel';
import PhoneComponent from '../../components/EachComponent/phone/PhoneComponent';
import PhoneRightPanel from '../../components/EachComponent/phone/PhoneRightPanel';
import PhoneInputComponent from '../../components/EachComponent/phoneInput/PhoneInputComponent';
import PhoneInputRightPanel from '../../components/EachComponent/phoneInput/PhoneInputRightPanel';
import ProgressBarComponent from '../../components/EachComponent/progressbar/ProgressBarComponent';
import ProgressbarRightPanel from '../../components/EachComponent/progressbar/ProgressbarRightPanel';
import RadioComponent from '../../components/EachComponent/radio/RadioComponent';
import RadioRightPanel from '../../components/EachComponent/radio/RadioRightPanel';
import ShortTextComponent from '../../components/EachComponent/shortText/ShortTextComponent';
import ShortTextRightPanel from '../../components/EachComponent/shortText/ShortTextRightPanel';
import SubmitButtonComponent from '../../components/EachComponent/submitButton/SubmitButtonComponent';
import SubmitButtonRightPanel from '../../components/EachComponent/submitButton/SubmitButtonRightPanel';
import TimeComponent from '../../components/EachComponent/time/TimeComponent';
import TimeRightPanel from '../../components/EachComponent/time/TimeRightPanel';
import VideoComponent from '../../components/EachComponent/video/VideoComponent';
import VideoRightPanel from '../../components/EachComponent/video/VideoRightPanel';
import YesNoComponent from '../../components/EachComponent/yesNo/YesNoComponent';
import YesNoRightPanel from '../../components/EachComponent/yesNo/YesNoRightPanel';
import {
  getAddressValue,
  getCheckboxValue,
  getConsentValue,
  getFullNameValue,
  getOptionValue,
  getYesNoValue
} from '../utils/getFieldValue';
import {COMPONENT_TYPE} from './ComponentType';
import { FORM_INPUT_TYPES } from '../constant/CoreConstants';
import DisclaimerRightPanel from "../../components/EachComponent/disclaimer/DisclaimerRightPanel";
import DisclaimerComponent from "../../components/EachComponent/disclaimer/DisclaimerComponent";
import CardViewDisclaimerComponent from "../../components/EachComponent/disclaimer/CardViewDisclaimerComponent";

export const ComponentConfig = {
  [COMPONENT_TYPE.THEME]: {
    name: 'Theme'
  },
  [COMPONENT_TYPE.COVER_IMAGE]: {
    name: 'Cover Image'
  },
  [COMPONENT_TYPE.PROFILE_IMAGE]: {
    name: 'Profile Image'
  },
  [COMPONENT_TYPE.FORM_IMAGE]: {
    name: 'Form Image'
  },
  [COMPONENT_TYPE.PAGE_IMAGE]: {
    name: 'Page Image'
  },
  [COMPONENT_TYPE.SUBMIT_BUTTON]: {
    name: 'Button',
    isInput: false,
    component: SubmitButtonComponent,
    rightComponent: SubmitButtonRightPanel
  },
  [COMPONENT_TYPE.HEADING]: {
    name: 'Heading',
    isInput: false,
    component: HeadingComponent,
    cardComponent: CardViewHeadingComponent,
    rightComponent: HeadingRightPanel
  },
  [COMPONENT_TYPE.FULL_NAME]: {
    name: 'Full Name',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: NameComponent,
    cardComponent: CardViewFullNameComponent,
    rightComponent: NameRightPanel,
    getValue: (field, isPublicPage) => getFullNameValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.EMAIL]: {
    name: 'Email',
    isInput: true,
    inputType: FORM_INPUT_TYPES.EMAIL,
    component: EmailComponent,
    cardComponent: CardViewEmailComponent,
    rightComponent: EmailRightPanel,
    getValue: field => (typeof field.value === 'string' ? field.value.toLowerCase() : field.value)
  },
  [COMPONENT_TYPE.PHONE]: {
    name: 'Phone',
    isInput: true,
    inputType: FORM_INPUT_TYPES.PHONE,
    component: PhoneComponent,
    cardComponent: CardViewPhoneComponent,
    rightComponent: PhoneRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.ADDRESS]: {
    name: 'Address',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: AddressComponent,
    cardComponent: CardViewAddressComponent,
    rightComponent: AddressRightPanel,
    getValue: (field, isPublicPage) => getAddressValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.COMPANY]: {
    name: 'Company',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: CompanyComponent,
    cardComponent: CardViewCompanyComponent,
    rightComponent: CompanyRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.BIRTHDAY]: {
    name: 'Birthday',
    isInput: true,
    inputType: FORM_INPUT_TYPES.DATE,
    component: BirthdateComponent,
    cardComponent: CardViewBirthdateComponent,
    rightComponent: BirthdateRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.NOTE]: {
    name: 'Note',
    isInput: true,
    inputType: FORM_INPUT_TYPES.MULTILINE,
    component: NoteComponent,
    cardComponent: CardViewNoteComponent,
    rightComponent: NoteRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.SHORT_ANSWER]: {
    name: 'Short Answer',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: ShortTextComponent,
    cardComponent: CardViewShortTextComponent,
    rightComponent: ShortTextRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.LONG_ANSWER]: {
    name: 'Long Answer',
    isInput: true,
    inputType: FORM_INPUT_TYPES.MULTILINE,
    component: LongTextComponent,
    cardComponent: CardViewLongTextComponent,
    rightComponent: LongTextRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.PHONE_INPUT]: {
    name: 'Phone Input',
    isInput: true,
    inputType: FORM_INPUT_TYPES.PHONE,
    component: PhoneInputComponent,
    cardComponent: CardViewPhoneInputComponent,
    rightComponent: PhoneInputRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.NUMBER]: {
    name: 'Number',
    isInput: true,
    inputType: FORM_INPUT_TYPES.NUMBER,
    component: NumberComponent,
    cardComponent: CardViewNumberComponent,
    rightComponent: NumberRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.LINK]: {
    name: 'Link',
    isInput: true,
    inputType: FORM_INPUT_TYPES.URL,
    component: LinkComponent,
    cardComponent: CardViewLinkComponent,
    rightComponent: LinkRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.DATE]: {
    name: 'Date',
    isInput: true,
    inputType: FORM_INPUT_TYPES.DATE,
    component: DateComponent,
    cardComponent: CardViewDateComponent,
    rightComponent: DateRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.TIME]: {
    name: 'Time',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: TimeComponent,
    cardComponent: CardViewTimeComponent,
    rightComponent: TimeRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.YES_NO]: {
    name: 'Yes/No',
    isInput: true,
    inputType: FORM_INPUT_TYPES.TEXT,
    component: YesNoComponent,
    cardComponent: CardViewYesNoComponent,
    rightComponent: YesNoRightPanel,
    getValue: (field, isPublicPage) => getYesNoValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.RADIO]: {
    name: 'Radio',
    isInput: true,
    inputType: FORM_INPUT_TYPES.RADIO,
    component: RadioComponent,
    cardComponent: CardViewRadioComponent,
    rightComponent: RadioRightPanel,
    getValue: (field, isPublicPage) => getOptionValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.CHECKBOX]: {
    name: 'Checkbox',
    isInput: true,
    inputType: FORM_INPUT_TYPES.CHECKBOX,
    component: CheckboxComponent,
    cardComponent: CardViewCheckboxComponent,
    rightComponent: CheckboxRightPanel,
    getValue: field => getCheckboxValue(field)
  },
  [COMPONENT_TYPE.DROPDOWN]: {
    name: 'Dropdown',
    isInput: true,
    inputType: FORM_INPUT_TYPES.DROPDOWN,
    component: DropdownComponent,
    cardComponent: CardViewDropDownComponent,
    rightComponent: DropdownRightPanel,
    getValue: (field, isPublicPage) => getOptionValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.FILE_UPLOAD]: {
    name: 'File Upload',
    isInput: true,
    component: FileUploadComponent,
    cardComponent: CardViewFileUploadComponent,
    rightComponent: FileUploadRightPanel,
    getValue: field => field.files
  },
  [COMPONENT_TYPE.HIDDEN_FIELD]: {
    name: 'Hidden Field',
    isInput: true,
    component: HiddenFieldComponent,
    cardComponent: CardViewHiddenFieldComponent,
    rightComponent: HiddenFieldRightPanel,
    getValue: field => field.value
  },
  [COMPONENT_TYPE.DISCLAIMER]: {
    name: 'Disclaimer',
    isInput: true,
    component: DisclaimerComponent,
    cardComponent: CardViewDisclaimerComponent,
    rightComponent: DisclaimerRightPanel,
    getValue: (field, isPublicPage) => getConsentValue(field, isPublicPage)
  },
  [COMPONENT_TYPE.PARAGRAPH]: {
    name: 'Paragraph',
    isInput: false,
    component: ParagraphComponent,
    cardComponent: CardViewParagraphComponent,
    rightComponent: ParagraphRightPanel
  },
  [COMPONENT_TYPE.IMAGE]: {
    name: 'Image',
    isInput: false,
    component: ImageComponent,
    cardComponent: CardViewImageComponent,
    rightComponent: ImageRightPanel
  },
  [COMPONENT_TYPE.VIDEO]: {
    name: 'Video',
    isInput: false,
    component: VideoComponent,
    cardComponent: CardViewVideoComponent,
    rightComponent: VideoRightPanel
  },
  [COMPONENT_TYPE.AUDIO]: {
    name: 'Audio',
    isInput: false,
    component: AudioComponent,
    cardComponent: CardViewAudioComponent,
    rightComponent: AudioRightPanel
  },
  [COMPONENT_TYPE.EMBED]: {
    name: 'Embed',
    isInput: false,
    component: EmbededAnythingComponent,
    cardComponent: CardViewEmbedAnythingComponent,
    rightComponent: EmbedAnythingRightPanel
  },
  [COMPONENT_TYPE.PROGRESSBAR]: {
    name: 'Progress Bar',
    isInput: false,
    component: ProgressBarComponent,
    cardComponent: CardViewProgressBarComponent,
    rightComponent: ProgressbarRightPanel
  }
};
