import {styled, TextField} from '@mui/material';

const CoreTextField = styled(TextField)(({theme, height = '40px'}) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& input': {
    border: 'none !important',
    boxShadow: 'none !important',
    letterSpacing: 'inherit',
    boxSizing: 'content-box !important',
    height: `${height} !important`,
    margin: '0 !important',
    background: 'none',
    display: 'block',
    minWidth: 0,
    width: '100%',
    padding: '0 14px !important',
    '&.MuiInputBase-inputSizeSmall': {
      padding: '0 12px !important'
    },
    '&:focus': {
      border: 'none !important',
      boxShadow: 'none !important'
    },
    '& .MuiInputBase-root': {
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px'
        }
      }
    }
  },
  '&.chooseTemplateSearchbar': {
    '& input': {padding: 0},
    '& .MuiInputBase-root': {
      borderRadius: ' 4px 0 0 4px',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.other.outlinedBorder}`
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.other.outlinedBorder}`
        }
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: theme.palette.secondary.main
        }
      }
    }
  },
  '&.formListSearchFilter': {
    background: 'transparent',
    minWidth: '230px',
    '& .MuiInputBase-root': {
      padding: '0 8px!important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.other.outlinedBorder}`
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${theme.palette.other.outlinedBorder}`
        }
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
          borderColor: theme.palette.secondary.main
        }
      }
    }
  },
  '&.fbp-addCategory': {
    '& .MuiFormHelperText-root': {
      margin: '2px 0 0'
    }
  }
}));

export default CoreTextField;
