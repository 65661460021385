import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, styled} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {addNewPage} from '../../../state/features/builderFields/builderFieldsSlice';
import Elements from './Elements/Elements';
import Widgets from './Elements/Widgets';
import SearchedElements from './Elements/SearchedElements';
import CustomBuilderSearch from '../../../common/BuilderSearch/CustomBuilderSearch';
import CoreButton from '../../../common/Button/CoreButton';
import BuilderLeftTabs, {BuilderLeftTab} from '../../../common/Tab/BuilderLeftTabs';

const StyledBox = styled(Box)(({theme, layout}) => ({
  paddingBottom: theme.spacing(2),
  height: layout === 'card' ? 'calc(100vh - 250px)' : 'calc(100vh - 300px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {display: 'none'}
}));

const BuilderElements = ({layout}) => {
  const dispatch = useDispatch();
  const [elementSearchQuery, setElementSearchQuery] = useState('');

  const handleAddNewPage = () => {
    dispatch(addNewPage());
  };

  return (
    <>
      <BuilderLeftTabs>
        <BuilderLeftTab title="Basic">
          <Box sx={{height: '100%'}}>
            <Box p={1} className="builderElementBasicStyle">
              <CustomBuilderSearch
                onChange={e => setElementSearchQuery(e.target.value)}
                value={elementSearchQuery}
              />
              {layout !== 'card' && (
                <Box pt={1} sx={{textAlign: 'center'}}>
                  <div>
                    <CoreButton
                      variant={'contained'}
                      color={'secondary'}
                      size={'small'}
                      fullWidth
                      startIcon={<AddIcon />}
                      onClick={() => handleAddNewPage()}
                    >
                      New Page
                    </CoreButton>
                  </div>
                </Box>
              )}
            </Box>
            <StyledBox layout={layout}>
              {elementSearchQuery === '' && <Elements />}
              {elementSearchQuery !== '' && <SearchedElements query={elementSearchQuery} />}
            </StyledBox>
          </Box>
        </BuilderLeftTab>

        <BuilderLeftTab title="Widgets">
          {/* <CustomBuilderSearch /> */}
          <StyledBox>
            <Widgets />
          </StyledBox>
        </BuilderLeftTab>
      </BuilderLeftTabs>
    </>
  );
};

export default BuilderElements;
