import React from 'react';
import {useSelector} from 'react-redux';
import {Stack, Box} from '@mui/material';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightsideInlineInput from '../../Builder/RightComponents/RightsideInlineInput';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const AddressAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Stack direction="column" sx={{gap: '10px'}} className="builderElementBasicStyle">
        <RightComponentTitle text="Placeholder" title="Change input placeholder" />
        <RightsideInlineInput
          label="Street Address 1"
          value={field.streetAddressPlaceholder}
          onChange={e => onChangeValue({streetAddressPlaceholder: e.target.value})}
        />
        <RightsideInlineInput
          label="Street Address 2"
          value={field.streetAddress2Placeholder}
          onChange={e => onChangeValue({streetAddress2Placeholder: e.target.value})}
        />
        <RightsideInlineInput
          label="City"
          value={field.cityPlaceholder}
          onChange={e => onChangeValue({cityPlaceholder: e.target.value})}
        />
        <RightsideInlineInput
          label="State / Province"
          value={field.statePlaceholder}
          onChange={e => onChangeValue({statePlaceholder: e.target.value})}
        />
        <RightsideInlineInput
          label="Postal / Zip Code"
          value={field.zipPlaceholder}
          onChange={e => onChangeValue({zipPlaceholder: e.target.value})}
        />
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}

      <RightSideSwitch
        text="Read Only"
        title="Set the input as read only"
        checked={field.readOnly}
        onChange={value => onChangeValue({readOnly: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(AddressAdvanced);
