import React from 'react';
import {Box, styled} from '@mui/material';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import CoreButton from '../../../common/Button/CoreButton';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {ImageComponentIcon} from '../../../helpers/fbpIcons/IconComponents';
import Styles from './Image.module.scss';

const BoxStyled = styled(Box)(({theme}) => ({
  background: 'var(--fbp-theming-input-background)',
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: '8px',
  width: '100%',
  height: '260px',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '& svg path': {
    fill: 'var(--fbp-theming-text-color)'
  }
}));

const CardViewImageComponent = ({field, onChange, editMode}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
        <Box mb={4}>
          <FormDescription>{field.description}</FormDescription>
        </Box>

        {field.image && (
          <BoxStyled>
            <Box className={Styles.imgUpload}>
              <img src={field.image} alt={field.alt} style={{objectFit: field.objectFit}}/>
              {editMode && (
                <CoreButton
                  variant="contained"
                  size={'small'}
                  sx={{position: 'absolute', right: '10px', top: '10px'}}
                  onClick={() => onChange({image: null})}
                >
                  remove
                </CoreButton>
              )}
            </Box>
          </BoxStyled>
        )}

        {!field.image && (
          <BoxStyled>
            <ImageComponentIcon />
          </BoxStyled>
        )}
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default CardViewImageComponent;
