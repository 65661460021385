import React, {useEffect, useState} from 'react';
import {ComponentConfig} from '../../../helpers/builderConstant/ComponentConfig';
import useDelayCallback from '../../../hooks/useDelayCallback';
import ProgressBarComponent from './ProgressBarComponent';

const getTotalFields = fields => {
  let totalFields = 0;
  fields.forEach(eachPage => {
    eachPage.pageItems.forEach(field => {
      if (field.hidden !== true && ComponentConfig[field.component].isInput) {
        totalFields++;
      }
    });
  });

  return totalFields;
};

const ProgressBarHandler = ({fields, lastField, currentPage, progressBar}) => {
  const [totalDone, setTotalDone] = useState(0);
  const [mappingValues, setMappingValues] = useState({});
  const [totalFields, setTotalFields] = useState(0);

  useEffect(() => {
    setTotalFields(
      progressBar.calculationByFields === true ? getTotalFields(fields) : fields.length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Setting mapping obj
  useDelayCallback(
    () => {
      if (lastField) {
        let field = fields[currentPage].pageItems[lastField.index];
        setMappingValues({
          ...mappingValues,
          [field.id]: ComponentConfig[field.component].getValue(field)
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [lastField],
    200
  );

  //Counting total done
  useEffect(() => {
    let count = 0;
    for (const key in mappingValues) {
      if (Array.isArray(mappingValues[key])) {
        if (mappingValues[key].length > 0) {
          count++;
        }
      } else if (mappingValues[key]) {
        count++;
      }
    }
    setTotalDone(count);
  }, [mappingValues]);

  return (
    <ProgressBarComponent
      field={progressBar}
      totalFields={totalFields}
      totalDone={progressBar.calculationByFields === true ? totalDone : currentPage}
    />
  );
};

export default ProgressBarHandler;
