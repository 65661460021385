import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Box, styled, Tab, Tabs, useTheme} from '@mui/material';
import AdminTemplateCategories from './AdminTemplateCategories';
import AdminCategoryListWrapper from './AdminCategoryListWrapper';
import {setAdminTemplateCategoryType} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {
  TEMPLATE__CATEGORY_TYPE_BUSINESS,
  TEMPLATE__CATEGORY_TYPE_INDUSTRY
} from '../../../helpers/constant/templateConstant';
import '../AdminTemplate.module.scss';

const StyledTab = styled(Tab)(({theme}) => ({
  background: theme.palette.background.paper,
  '&:focus': {
    backgroundColor: theme.palette.background.paper
  }
}));

const TabsStyled = styled(Tabs)(({theme}) => ({
  background: theme.palette.background.paper,
  boxShadow: 0
}));

function TabPanel({children, value, index, ...other}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

const AdminTemplateCategoryTab = ({isHideLeftSide, isShowLeftSideTab}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(
      setAdminTemplateCategoryType(
        newValue === 1 ? TEMPLATE__CATEGORY_TYPE_INDUSTRY : TEMPLATE__CATEGORY_TYPE_BUSINESS
      )
    );
  };

  return (
    <Box
      px={2}
      sx={{
        height: `${isShowLeftSideTab ? 'calc(100% - 60px) ' : 'calc(100vh - 236px)'}`,
        opacity: `${isHideLeftSide ? 0 : 1}`,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {display: 'none'}
      }}
    >
      <TabsStyled
        variant="fullWidth"
        aria-label="full width tabs example"
        sx={{padding: 0}}
        value={value}
        onChange={handleChange}
      >
        <StyledTab label="Categories" {...a11yProps(0)} />
        <StyledTab label="Industries" {...a11yProps(1)} />
      </TabsStyled>

      <AdminCategoryListWrapper>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <AdminTemplateCategories />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <AdminTemplateCategories />
        </TabPanel>
      </AdminCategoryListWrapper>
    </Box>
  );
};

export default AdminTemplateCategoryTab;
