import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import PhoneAdvanced from './PhoneAdvanced';
import PhoneGeneral from './PhoneGeneral';
import PhoneOptions from './PhoneOptions';

const PhoneRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <PhoneGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <PhoneOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <PhoneAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default PhoneRightPanel;
