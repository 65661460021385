import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: ''
};

const emailShareSlice = createSlice({
  name: 'emailShare',
  initialState: initialState,
  reducers: {
    sendEmail: (state, action) => {
      state.isLoading = true;
    },
    sendEmailSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    sendEmailFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetSendEmailSuccess: (state, action) => {
      state.isSuccess = false;
      state.error = '';
    }
  }
});

export const {sendEmail, sendEmailSuccess, sendEmailFailed, resetSendEmailSuccess} =
  emailShareSlice.actions;

export default emailShareSlice.reducer;
