import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
  getFormThankYouSettingFailed,
  getFormThankYouSettingSuccess,
  updateFormThankYouSettingFailed,
  updateFormThankYouSettingSuccess
} from './formThankYouSlice';
import {showErrorSnackbar} from '../snackbar/snackbarSlice';
import FormThankYouSettingService from '../../../services/FormThankYouSetting.service';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';

function* formSettingWatcher() {
  yield takeEvery('formThankYou/getFormThankYouSetting', getFormThankYouSettingSaga);
  yield takeEvery('formThankYou/updateFormThankYouSetting', updateFormThankYouSettingSaga);
}

function* getFormThankYouSettingSaga(action) {
  try {
    const response = yield call(FormThankYouSettingService.getFormThankYouSetting, action.payload);

    if (response.success) {
      yield put(getFormThankYouSettingSuccess(response.data));
    } else {
      yield put(getFormThankYouSettingFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormThankYouSettingSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const response = yield call(
      FormThankYouSettingService.updateFormThankYouSetting,
      action.payload
    );

    if (response.success) {
      const thankyouSettingData = JSON.parse(response.data.thankyouPageSetting);
      yield put(updateFormThankYouSettingSuccess(thankyouSettingData));
    } else {
      yield put(updateFormThankYouSettingFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formThankYouSaga() {
  yield all([formSettingWatcher()]);
}
