import * as React from 'react';
import {useSelector} from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme
} from '@mui/material';
import TableLoaderSkeleton from '../../LoadingView/TableLoaderSkeleton';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {EmptySubmissionFormIcon} from '../../../common/Icons/EmptyDataIcons';
// import {StyledSpan} from '../AnalysisStyles';

const headCells = [{label: 'Platform'}, {label: 'Views'}];

// Table Head
function FilesTableHead() {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: theme.palette?.other.outlinedBorder}}>
        {headCells.map(headCell => (
          <TableCell key={headCell.label} padding={'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PlatformTable() {
  const {isLoadingList, listData, selectedListFilter} = useSelector(state => state.formStatistics);

  if (isLoadingList || !listData.platformReport) {
    return (
      <Box sx={{width: '100%'}}>
        <TableLoaderSkeleton numberOfRows={2} columns={2} />
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%'}} pt={2}>
      <Paper sx={{width: '100%', mb: 2}} elevation={0}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'medium'}>
            <FilesTableHead />
            <TableBody>
              {listData.platformReport.map(eachData => (
                <TableRow>
                  <TableCell align="left">{eachData.platform}</TableCell>
                  <TableCell align="left">
                    {eachData.totalValue}
                    {selectedListFilter === 'CONVERSIONRATE' && '%'}
                    {/* <StyledSpan>100%</StyledSpan> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {listData.platformReport.length === 0 && (
            <div>
              <GlobalEmptyPage
                icon={<EmptySubmissionFormIcon />}
                title={'No data found'}
                description={'No corresponding platform data found'}
              />
            </div>
          )}
        </TableContainer>
      </Paper>
    </Box>
  );
}
