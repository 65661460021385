import React from 'react';
import {Divider, Paper} from "@mui/material";
import BasicTitleBar from "../../../../common/TitleBar/BasicTitleBar";
import EmbedShareIframeBody from "./EmbedShareIframeBody";

const EmbedShareIframe = () => {
    return (
        <Paper elevation={0}>
            <BasicTitleBar
                title={'Iframe'}
                content={'Copy and paste this snippet to your code and put your form on your website.'}
            />
            <Divider light />

            <EmbedShareIframeBody />
        </Paper>
    );
};

export default EmbedShareIframe;