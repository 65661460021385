import React from 'react';
import CustomTextAreaField from '../../../common/TextAreaField/CustomTextAreaField';
import RightComponentTitle from './RightComponentTitle';
import {Stack} from '@mui/material';

const RightSideTextArea = ({text, title, value = '', onChange, placeholder = 'Label'}) => {
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <Stack direction="row">
        <CustomTextAreaField
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          height="100px"
        />
      </Stack>
    </Stack>
  );
};

export default RightSideTextArea;
