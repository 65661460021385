import React from 'react';
import {Divider, Paper} from '@mui/material';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import MappingContent from './MappingContent';

const Mapping = () => {
  return (
    <Paper elevation={0}>
      <BasicTitleBar
        contentVariant="body2Semibold"
        fontWeight={400}
        title={'Field Mapping'}
        content={
          'In this step you have the ability to map the fields from your form so they appear in the correct spot on a contact record.'
        }
        info={"If you don't want to map a specific field just leave it unselected."}
      />
      <Divider />

      <MappingContent />
    </Paper>
  );
};

export default Mapping;
