import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';
import {TEMPLATE__CATEGORY_TYPE_BUSINESS} from '../../../helpers/constant/templateConstant';

const initialState = {
  data: [],
  categoryTypes: [],
  categoryType: TEMPLATE__CATEGORY_TYPE_BUSINESS,
  isLoading: false,
  isError: false,
  error: '',
  renderId: '',

  isAdding: false,
  isAddSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  isMoving: false,
  isMoveSuccess: false,

  selectedCategoryId: '',

  selectedCategory: {
    id: '',
    categoryName: ''
  },
  selectedSubCategory: {
    id: '',
    name: '',
    count: 0
  }
};

const adminTemplateCategoriesSlice = createSlice({
  name: 'adminTemplateCategories',
  initialState: initialState,
  reducers: {
    getAdminTemplateCategories: (state, action) => {
      state.isLoading = true;
    },
    getAdminTemplateCategoriesSilently: (state, action) => {},
    getAdminTemplateCategoriesSuccess: (state, action) => {
      state.data = action.payload.content;
      state.categoryTypes = action.payload.categoryTypes;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getAdminTemplateCategoriesFailed: (state, action) => {
      state.data = [];
      state.categoryTypes = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetAdminTemplateCategoriesSuccess: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = false;
      state.error = '';
      state.renderId = '';
    },

    addAdminTemplateCategory: (state, action) => {
      state.isAdding = true;
    },
    addAdminTemplateCategorySuccess: (state, action) => {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.renderId = nanoid(5);
    },
    addAdminTemplateCategoryFailed: (state, action) => {
      state.isAdding = false;
    },
    resetAddAdminTemplateCategorySuccess: (state, action) => {
      state.isAddSuccess = false;
    },

    updateAdminTemplateCategory: (state, action) => {
      state.isUpdating = true;
    },
    updateAdminTemplateCategorySuccess: (state, action) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.renderId = nanoid(5);
    },
    updateAdminTemplateCategoryFailed: (state, action) => {
      state.isUpdating = false;
    },
    resetUpdateAdminTemplateCategorySuccess: state => {
      state.isUpdateSuccess = false;
    },

    deleteAdminTemplateCategory: (state, action) => {},
    deleteAdminTemplateCategorySuccess: (state, action) => {
      state.renderId = nanoid(5);
    },

    addAdminTemplateSubCategory: (state, action) => {
      state.isAdding = true;
    },
    addAdminTemplateSubCategorySuccess: (state, action) => {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.renderId = nanoid(5);
    },
    addAdminTemplateSubCategoryFailed: (state, action) => {
      state.isAdding = false;
    },
    resetAddAdminTemplateSubCategorySuccess: state => {
      state.isAddSuccess = false;
    },

    updateAdminTemplateSubCategory: (state, action) => {
      state.isUpdating = true;
    },
    updateAdminTemplateSubCategorySuccess: (state, action) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.renderId = nanoid(5);
    },
    updateAdminTemplateSubCategoryFailed: (state, action) => {
      state.isUpdating = false;
    },
    resetUpdateAdminTemplateSubCategorySuccess: state => {
      state.isUpdateSuccess = false;
    },

    deleteAdminTemplateSubCategory: state => {},
    deleteAdminTemplateSubCategorySuccess: state => {
      state.renderId = nanoid(5);
    },

    moveAdminSubCategoryToCategory: state => {
      state.isMoving = true;
    },
    moveAdminSubCategoryToCategorySuccess: state => {
      state.isMoving = false;
      state.isMoveSuccess = true;
      state.renderId = nanoid(5);
    },
    moveAdminSubCategoryToCategoryFailed: state => {
      state.isMoving = false;
    },
    resetMoveAdminSubCategoryToCategorySuccess: state => {
      state.isMoveSuccess = false;
    },

    setAdminTemplateCategoryType: (state, action) => {
      state.categoryType = action.payload;
    },
    setSelectedAdminCategory: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    setSelectedAdminSubCategory: (state, action) => {
      state.selectedSubCategory.id = action.payload.id;
      state.selectedSubCategory.name = action.payload.name;
      state.selectedSubCategory.count = action.payload.count;
    },
    resetSelectedAdminCategory: state => {
      state.selectedCategoryId = '';
    },
    resetSelectedAdminSubCategory: state => {
      state.selectedSubCategory.id = '';
      state.selectedSubCategory.name = '';
      state.selectedSubCategory.count = '';
    }
  }
});

export const {
  getAdminTemplateCategories,
  getAdminTemplateCategoriesSilently,
  getAdminTemplateCategoriesSuccess,
  getAdminTemplateCategoriesFailed,

  addAdminTemplateCategory,
  addAdminTemplateCategorySuccess,
  addAdminTemplateCategoryFailed,
  resetAddAdminTemplateCategorySuccess,

  updateAdminTemplateCategory,
  updateAdminTemplateCategorySuccess,
  updateAdminTemplateCategoryFailed,
  resetUpdateAdminTemplateCategorySuccess,

  deleteAdminTemplateCategory,
  deleteAdminTemplateCategorySuccess,

  addAdminTemplateSubCategory,
  addAdminTemplateSubCategorySuccess,
  addAdminTemplateSubCategoryFailed,
  resetAddAdminTemplateSubCategorySuccess,

  deleteAdminTemplateSubCategory,
  deleteAdminTemplateSubCategorySuccess,

  updateAdminTemplateSubCategory,
  updateAdminTemplateSubCategorySuccess,
  updateAdminTemplateSubCategoryFailed,
  resetUpdateAdminTemplateSubCategorySuccess,

  moveAdminSubCategoryToCategory,
  moveAdminSubCategoryToCategorySuccess,
  moveAdminSubCategoryToCategoryFailed,
  resetMoveAdminSubCategoryToCategorySuccess,

  setAdminTemplateCategoryType,
  setSelectedAdminCategory,
  setSelectedAdminSubCategory,

  resetSelectedAdminCategory,
  resetSelectedAdminSubCategory
} = adminTemplateCategoriesSlice.actions;

export default adminTemplateCategoriesSlice.reducer;
