import React from 'react';
import {SvgIcon} from '@mui/material';

export default function MobileIcon({fill = 'ffffff', ...rest}) {
  return (
    <SvgIcon {...rest} viewBox="0 0 20 20">
      <path
        d="M10.45 16.4017C10.6748 16.4017 10.8904 16.3124 11.0493 16.1534C11.2083 15.9945 11.2976 15.7789 11.2976 15.5541C11.2976 15.3293 11.2083 15.1137 11.0493 14.9548C10.8904 14.7958 10.6748 14.7065 10.45 14.7065C10.2252 14.7065 10.0096 14.7958 9.85065 14.9548C9.69169 15.1137 9.6024 15.3293 9.6024 15.5541C9.6024 15.7789 9.69169 15.9945 9.85065 16.1534C10.0096 16.3124 10.2252 16.4017 10.45 16.4017ZM6.53421 2.81732C6.4325 2.81732 6.33495 2.85772 6.26302 2.92965C6.1911 3.00157 6.15069 3.09912 6.15069 3.20084V17.0077C6.15069 17.1094 6.1911 17.2069 6.26302 17.2788C6.33495 17.3508 6.4325 17.3912 6.53421 17.3912H14.1241C14.2258 17.3912 14.3234 17.3508 14.3953 17.2788C14.4672 17.2069 14.5077 17.1094 14.5077 17.0077V3.20084C14.5077 3.09912 14.4672 3.00157 14.3953 2.92965C14.3234 2.85772 14.2258 2.81732 14.1241 2.81732H6.53421ZM6.53421 1.66675H14.1241C14.531 1.66675 14.9212 1.82837 15.2089 2.11607C15.4966 2.40377 15.6582 2.79397 15.6582 3.20084V17.0077C15.6582 17.4145 15.4966 17.8047 15.2089 18.0924C14.9212 18.3801 14.531 18.5417 14.1241 18.5417H6.53421C6.12735 18.5417 5.73715 18.3801 5.44945 18.0924C5.16175 17.8047 5.00012 17.4145 5.00012 17.0077V3.20084C5.00012 2.79397 5.16175 2.40377 5.44945 2.11607C5.73715 1.82837 6.12735 1.66675 6.53421 1.66675ZM9.25953 3.96788H11.8023C11.8595 3.96545 11.9165 3.97461 11.9701 3.99481C12.0236 4.01501 12.0725 4.04584 12.1138 4.08543C12.1551 4.12502 12.188 4.17256 12.2105 4.22519C12.2329 4.27782 12.2445 4.33446 12.2445 4.39168C12.2445 4.4489 12.2329 4.50553 12.2105 4.55816C12.188 4.61079 12.1551 4.65833 12.1138 4.69792C12.0725 4.73752 12.0236 4.76834 11.9701 4.78854C11.9165 4.80874 11.8595 4.8179 11.8023 4.81547H9.25953C9.15025 4.81082 9.04699 4.76414 8.97131 4.68517C8.89563 4.6062 8.85338 4.50105 8.85338 4.39168C8.85338 4.2823 8.89563 4.17715 8.97131 4.09818C9.04699 4.01922 9.15025 3.97253 9.25953 3.96788Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
