const copyFromClipBoard = htmlCode => {
  navigator.clipboard
    .writeText(htmlCode)
    .then(() => {
      return true;
    })
    .catch(err => {
      return false;
    });
};

export default copyFromClipBoard;
