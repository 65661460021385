import React from 'react';
import {Box, MenuItem, Select} from '@mui/material';
import {DROPDOWN_BOTTOM_SETUP} from '../../../helpers/constant/CoreConstants';
import RightComponentTitle from './RightComponentTitle';
import {Stack, styled} from '@mui/material';

const StyledSelect = styled(Select)(({theme}) => ({
  width: '100%',
  '& .MuiSelect-select': {padding: '11px 8px'},
  '&.Mui-focused ': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'none',
      borderWidth: '1px'
    }
  }
}));

const RightSideFontSelect = ({text, title, value, onChange = null}) => {
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <Box sx={{width: '100%'}}>
        <Box>
          <StyledSelect
            color={'secondary'}
            value={value}
            onChange={onChange}
            MenuProps={{
              ...DROPDOWN_BOTTOM_SETUP,
            }}
          >
            <MenuItem
              value={'Arial, sans-serif'}
              ref={node => node && node.style.setProperty('font-family', 'Arial', 'important')}
            >
              Arial
            </MenuItem>
            <MenuItem
              value={"'Poppins', sans-serif"}
              ref={node => node && node.style.setProperty('font-family', 'Poppins', 'important')}
            >
              Poppins
            </MenuItem>
            <MenuItem
              value={'Verdana, sans-serif'}
              ref={node => node && node.style.setProperty('font-family', 'Verdana', 'important')}
            >
              Verdana
            </MenuItem>
            <MenuItem
              value={'Helvetica, sans-serif'}
              ref={node => node && node.style.setProperty('font-family', 'Helvetica', 'important')}
            >
              Helvetica
            </MenuItem>
            <MenuItem
              value={'Tahoma, sans-serif'}
              ref={node => node && node.style.setProperty('font-family', 'Tahoma', 'important')}
            >
              Tahoma
            </MenuItem>
            <MenuItem
              value={"'Trebuchet MS', sans-serif"}
              ref={node =>
                node && node.style.setProperty('font-family', 'Trebuchet MS', 'important')
              }
            >
              Trebuchet MS
            </MenuItem>
            <MenuItem
              value={"'Times New Roman', serif"}
              ref={node =>
                node && node.style.setProperty('font-family', 'Times New Roman', 'important')
              }
            >
              Times New Roman
            </MenuItem>
            <MenuItem
              value={'Georgia, serif'}
              ref={node => node && node.style.setProperty('font-family', 'Georgia', 'important')}
              style={{fontFamily: 'Georgia, serif'}}
            >
              Georgia
            </MenuItem>
            <MenuItem
              value={'Garamond, serif'}
              ref={node => node && node.style.setProperty('font-family', 'Garamond', 'important')}
            >
              Garamond
            </MenuItem>
            <MenuItem
              value={"'Courier New', monospace"}
              ref={node =>
                node && node.style.setProperty('font-family', 'Courier New', 'important')
              }
            >
              Courier New
            </MenuItem>
            <MenuItem
              value={"'Brush Script MT', cursive"}
              ref={node =>
                node && node.style.setProperty('font-family', 'Brush Script MT', 'important')
              }
            >
              Brush Script MT
            </MenuItem>
            <MenuItem
              value={"'Roboto', sans-serif"}
              ref={node => node && node.style.setProperty('font-family', 'Roboto', 'important')}
            >
              Roboto
            </MenuItem>
          </StyledSelect>
        </Box>
      </Box>
    </Stack>
  );
};

export default RightSideFontSelect;
