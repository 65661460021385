import React from 'react';
import {Skeleton, Stack} from '@mui/material';

const CommonTextareaLoader = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={'30%'}  />
      <Skeleton variant="rect" width={'100%'} height={100} />
    </Stack>
  );
};

export default CommonTextareaLoader;
