import React from 'react';
import {Box} from '@mui/material';
import TemplatePublishPage from './TemplatePublishPage';
import Styles from './styles/setting-page.module.scss';

const TemplatePublish = () => {
  return (
    <Box className={Styles.fbpPublicShareWr}>
      <Box className={`fbp_has_scroll ${Styles.fbpPublicShareMiddle}`}>
        <TemplatePublishPage />
      </Box>
    </Box>
  );
};

export default TemplatePublish;
