import React from 'react';
import {Controller, useForm, useFormContext} from 'react-hook-form';
import {InputAdornment, Stack} from '@mui/material';
import CustomBuilderInput from '../../../common/TextField/CustomBuilderInput';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';

export const FormInput = ({
  name,
  value,
  rules,
  inputIcon,
  className = '',
  placeholder,
  readOnly,
  type,
  helperTextProps = {},
  onChange = () => {},
  ...rest
}) => {
  const {control} = useForm();
  const methods = useFormContext();

  return (
    <Controller
      control={methods ? methods.control : control}
      name={name}
      defaultValue={value ?? ''}
      rules={rules}
      render={formOption => (
        <Stack direction="column" spacing={1}>
          <CustomBuilderInput
            className={className}
            type={type}
            value={value ?? ''}
            placeholder={placeholder}
            onChange={e => {
              if (!readOnly) {
                formOption.field.onChange(e);
                onChange(e);
              }
            }}
            inputProps={{
              readOnly: readOnly,
              ...rest
            }}
            InputProps={{
              readOnly: readOnly,
              ...(inputIcon
                ? {startAdornment: <InputAdornment position="start">{inputIcon}</InputAdornment>}
                : {})
            }}
            {...rest}
          />
          {formOption.fieldState.error?.message ? (
            <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
          ) : (
            <FormSubtext>{helperTextProps.text}</FormSubtext>
          )}
        </Stack>
      )}
    />
  );
};
