import React, {useEffect} from 'react';
import {useTheme, Stack, Box} from '@mui/material';
import CustomTextField from '../../../common/TextField/CustomTextField';
import RightSideSwitch from './RightSideSwitch';

const RightSideShrinkOption = ({shrink = false, onChangeShrink, width, onChangeWidth}) => {
  const theme = useTheme();

  useEffect(() => {
    if (typeof width === 'undefined') {
      onChangeWidth('auto');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <RightSideSwitch
        text="Shrink"
        title="Shrink the input so that we can handle the width"
        checked={shrink}
        onChange={value => onChangeShrink(value)}
      />

      {shrink && (
        <>
          <RightSideSwitch
            text="Width"
            title="Set the input width"
            checked={width !== 'auto'}
            onChange={value => onChangeWidth(value ? 30 : 'auto')}
            OffLabel="Auto"
            onLabel="Fixed"
            width={75}
            className="customWidth"
          >
            {width !== 'auto' && (
              <Stack direction="row" alignItems="start" sx={{margin: '10px 8px 0 0'}}>
                <CustomTextField
                  className="styleOptionInput"
                  size="medium"
                  labelPosition="top"
                  placeholder="Label"
                  value={width}
                  type="number"
                  onChange={e => onChangeWidth(e.target.value)}
                />
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '43px',
                    height: '44px',
                    background: theme.palette.action.selected,
                    borderRadius: '0px 4px 4px 0px'
                  }}
                >
                  %
                </span>
              </Stack>
            )}
          </RightSideSwitch>
        </>
      )}
    </Box>
  );
};

export default RightSideShrinkOption;
