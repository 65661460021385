import React, {useEffect, useState} from 'react';
import {useIsMounted} from '../hooks/IsMounted';
import useDelayCallback from '../hooks/useDelayCallback';

const withFieldChange =
  Component =>
  ({field, onChange, ...extraProps}) => {
    const isMounted = useIsMounted();
    const [localField, setLocalField] = useState({...field});
    const [lastValue, setLastValue] = useState({});

    useEffect(() => {
      setLocalField({...field});
    }, [field]);

    useDelayCallback(
      () => {
        if (onChange && isMounted) {
          onChange(lastValue);
        }
      },
      [lastValue],
      200
    );

    const handleChangeValue = value => {
      let fieldValue = {...localField, ...value};
      setLocalField(fieldValue);
      setLastValue(value);
    };

    return <Component field={localField} onChange={handleChangeValue} {...extraProps} />;
  };

export default withFieldChange;
