import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import YesNoAdvanced from './YesNoAdvanced';
import YesNoGeneral from './YesNoGeneral';
import YesNoOptions from './YesNoOptions';

const YesNoRightPanel = ({field}) => {
  return (
    <CustomTabs style={{width: '100vw', height: '100%', overflow: 'auto'}}>
      <CustomTab title="General">
        <YesNoGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <YesNoOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <YesNoAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default YesNoRightPanel;
