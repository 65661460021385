import React from 'react';
import {Stack} from '@mui/material';
import CustomSwitch from '../../../common/Switch/CustomSwitch';
import RightComponentTitle from './RightComponentTitle';

const RightSideSwitch = ({
  text,
  title,
  checked,
  onChange,
  onLabel = 'ON',
  OffLabel = 'OFF',
  width = 62,
  className = '',
  children
}) => {
  return (
    <Stack
      direction="column"
      alignItems="start"
      justifyContent="space-between"
      spacing={1}
      className={`${className} builderElementBasicStyle`}
    >
      <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
        <RightComponentTitle text={text} title={title} />
        <CustomSwitch
          variant="outlined"
          status={checked}
          labelChecked={onLabel}
          labelUnchecked={OffLabel}
          onChange={() => onChange(!checked)}
          width={width}
        />
      </Stack>

      {children}
    </Stack>
  );
};

export default RightSideSwitch;
