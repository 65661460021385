import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {useParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {selectFormSEO} from '../../../state/features/formSEO/formSEOSelector';
import {getFormSEO, updateFormSEO} from '../../../state/features/formSEO/formSEOSlice';
import convertNumber from '../../../helpers/utils/convertNumber';
import arrayToCommaString from '../../../helpers/utils/arrayToCommaString';
import generateRandomString from '../../../helpers/utils/generateRandomString';
import SeoSettingsLoader from '../../LoadingView/SeoSettingsLoader';

const components = {
  DropdownIndicator: null
};

const createOption = label => ({
  label,
  value: label
});

const robotOrIndexing = [
  {
    label: 'noindex',
    value: 'noindex'
  },
  {
    label: 'nofollow',
    value: 'nofollow'
  }
];

const FormSEOBody = () => {
  const {formId} = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();

  const {data: formSEOData, isLoading, error} = useSelector(selectFormSEO);

  const [formSEOInfo, setFormSEOInfo] = useState({
    author: '',
    description: ''
  });

  const [inputValue, setInputValue] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [robots, setRobots] = useState([]);
  const [updateRenderId, setUpdateRenderId] = useState('');

  useEffect(() => {
    if (formSEOData?.id) {
      setFormSEOInfo(prevState => ({
        ...prevState,
        author: formSEOData.author || '',
        description: formSEOData.description || ''
      }));
      setKeywords(
        formSEOData.keywords && typeof formSEOData.keywords === 'string'
          ? formSEOData.keywords.split(',').map(createOption)
          : []
      );
      setRobots(
        formSEOData.robots && typeof formSEOData.robots === 'string'
          ? formSEOData.robots.split(',')
          : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSEOData, error]);

  useEffect(() => {
    dispatch(getFormSEO({formId: formId}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateRenderId) {
      const reqObj = {
        formId: convertNumber(formId),
        author: formSEOInfo.author,
        description: formSEOInfo.description,
        robots: arrayToCommaString(robots),
        keywords: keywords.map(keyword => keyword.value).join(',')
      };
      dispatch(updateFormSEO(reqObj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRenderId]);

  const onChangeInput = event => {
    const {name, value} = event.target;
    setFormSEOInfo(prevState => ({...prevState, [name]: value}));
  };

  const onBlurAction = event => {
    const {name, value} = event.target;
    // when value actually change call the action
    if (formSEOData[name] !== value) {
      setUpdateRenderId(generateRandomString(5));
    }
  };

  const handleKeyDown = event => {
    if (!inputValue) return;

    // duplicate keyword not allow
    const hasDuplicate = keywords.some(keyword => keyword.value === inputValue);
    if (hasDuplicate) return false;

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setKeywords(prev => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        return null;
    }
  };

  const onBlurKeywords = () => {
    setUpdateRenderId(generateRandomString(5));
  };

  const handleChangeRobots = value => {
    const currentIndex = robots.indexOf(value);
    const copyRobots = [...robots];

    if (currentIndex !== -1) copyRobots.splice(currentIndex, 1);
    else copyRobots.push(value);

    setRobots(copyRobots);
    setUpdateRenderId(generateRandomString(5));
  };

  if (isLoading) return <SeoSettingsLoader />;

  return (
    <Box sx={{p: 3, height: '100%'}}>
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
            Author
          </Typography>
          <CoreTextField
            fullWidth
            type={'text'}
            size={'small'}
            color={'secondary'}
            placeholder="Author name"
            name={'author'}
            value={formSEOInfo.author}
            onChange={onChangeInput}
            onBlur={onBlurAction}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
            Description
          </Typography>
          <CoreTextField
            fullWidth
            type={'text'}
            size={'small'}
            color={'secondary'}
            placeholder="Write here"
            name={'description'}
            multiline
            rows={3}
            value={formSEOInfo.description}
            onChange={onChangeInput}
            onBlur={onBlurAction}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            '& input[type=text]:not(.browser-default)': {
              height: '1.8rem !important',
              '&:focus': {
                boxShadow: 'none !important'
              }
            }
          }}
        >
          <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 0.5}}>
            Keywords
          </Typography>
          <CreatableSelect
            components={components}
            styles={{
              option: (provided, state) => ({
                ...provided
                // backgroundColor: 'none'
              }),
              placeholder: styles => ({
                ...styles,
                color: '#546376',
                opacity: '0.7'
              }),
              input: styles => ({
                ...styles,
                color: '#546376'
              }),
              multiValue: styles => ({
                ...styles,
                backgroundColor: theme.palette.action.selected
              })
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={newValue => setKeywords(newValue)}
            onInputChange={newValue => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            onBlur={onBlurKeywords}
            placeholder="Type something and press enter..."
            value={keywords}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl component="fieldset" variant="standard">
            <Typography variant={'body2'} color={'text.secondary'}>
              Robots / indexing
            </Typography>
            <FormGroup row>
              {robotOrIndexing.map(robotData => (
                <FormControlLabel
                  key={robotData.value}
                  control={
                    <Checkbox
                      checked={robots.indexOf(robotData.value) !== -1}
                      onChange={() => handleChangeRobots(robotData.value)}
                      name="robots"
                    />
                  }
                  label={robotData.label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormSEOBody;
