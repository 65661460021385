import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Stack} from '@mui/material';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideNumberInput from '../../Builder/RightComponents/RightSideNumberInput';
import CustomTextField from '../../../common/TextField/CustomTextField';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';

const EmbedAnythingGeneral = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  const [link, setLink] = useState(field.link);

  useEffect(() => {
    setLink(field.link);
  }, [field]);

  const replaceURL = url => {
    if (url === '') {
      return url;
    }

    if (!url.includes('http://') && !url.includes('https://')) {
      url = 'https://' + url;
    }

    //If youtube
    let match = url.match(/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }

    //If iframe
    match = url.match(/(?:src=").*?(?=[\\?"])/);
    if (match) {
      return match[0].replace('src="', '');
    }

    return url
      .replace('https://www.loom.com/share/', 'https://www.loom.com/embed/')
      .replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
  };

  const handleChangeLink = () => {
    onChangeValue({link: replaceURL(link)});
  };

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Box className="builderElementBasicStyle">
        <RightComponentTitle text="Link" title="Set the embed url" />
        <Stack
          direction="column"
          alignItems="flex-end"
          sx={{textAlign: 'left', width: '100%'}}
          spacing={1}
          mt={1}
        >
          <CustomTextField
            max={null}
            size="medium"
            labelPosition="top"
            placeholder="Link"
            value={link}
            onChange={e => setLink(e.target.value)}
          />

          <LightCoreButton onClick={e => handleChangeLink()} size="small">
            Save
          </LightCoreButton>
        </Stack>
      </Box>

      {theme.layout === 'card' && (
        <RightSideInput
          text="Top Description"
          title="Change the description of the field"
          placeholder="Description"
          value={field.topDescription}
          onChange={e => onChangeValue({topDescription: e.target.value})}
        />
      )}

      <RightSideNumberInput
        text="Width"
        title="Width of the embed"
        unit="PX"
        placeholder="Enter width"
        value={field.embedWidth}
        onChange={e => onChangeValue({embedWidth: e.target.value})}
      />

      <RightSideNumberInput
        text="Height"
        title="Height of the embed"
        unit="PX"
        placeholder="Enter Height"
        value={field.height}
        onChange={e => onChangeValue({height: e.target.value})}
      />

      <ThreeTabsWithTitle
        text="Alignment"
        title="Alignment on the embed"
        child1={fbpIcons.fbpLeftIcon}
        child2={fbpIcons.fbpCenterIcon}
        child3={fbpIcons.fbpRightIcon}
        value1="left"
        value2="center"
        value3="right"
        selectedValue={field.alignment}
        onChange={value => onChangeValue({alignment: value})}
      />

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(EmbedAnythingGeneral);
