import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';

const RadioAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideDropdown
        text="Selected by Default"
        title="Change the selected option"
        data={[{title: 'None Selected', value: ''}, ...field.options]}
        value={field.value}
        onChange={e => onChangeValue({value: e.target.value})}
      />

      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}

      <RightSideSwitch
        text="Read Only"
        title="Set the input as read only"
        checked={field.readOnly}
        onChange={value => onChangeValue({readOnly: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(RadioAdvanced);
