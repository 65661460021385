import {COMPONENT_TYPE} from '../builderConstant/ComponentType';

export const getPublicPageFiles = fields => {
  let filesArr = [];

  if (!fields[0]?.pageItems) {
    //If card view
    fields.forEach(field => {
      if (field.component === COMPONENT_TYPE.FILE_UPLOAD) {
        filesArr = [...filesArr, ...field.files];
      }
    });
  } else {
    //If classic view
    fields.forEach(eachPage => {
      eachPage.pageItems.forEach(field => {
        if (field.component === COMPONENT_TYPE.FILE_UPLOAD) {
          filesArr = [...filesArr, ...field.files];
        }
      });
    });
  }

  return filesArr;
};
