import {styled, Typography} from '@mui/material';

export const FormCardLabel = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  fontSize: 'var(--fbp-theming-card-view-label-font-size) ',
  color: 'var(--fbp-theming-font-color) ',
  fontWeight: 600,
  lineHeight: '48px',
  letterSpacing: '0'
}));
