import React from 'react';
import Box from '@mui/material/Box';
import {Stack} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {CustomDropdown} from '../../../common/CustomDropdown';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import withFieldChange from '../../../hoc/withFieldChange';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const nameTitle = [
  {title: 'Mr', value: 'Mr'},
  {title: 'Mrs', value: 'Mrs'}
];

const CardViewFullNameComponent = ({field, editMode, onChange}) => {
  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}
      <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>

        <Box>
          <Stack direction="column" sx={{position: 'relative', gap: '20px', sm: {gap: '4px'}}}>
            {field.prefixEnabled && (
              <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
                <ConnectPublicForm
                  name={field.id + 1}
                  value={field.prefixValue}
                  rules={field.required ? {required: 'Prefix is required'} : {}}
                  render={formOption => (
                    <>
                      <CustomDropdown
                        data={nameTitle}
                        size="medium"
                        value={field.prefixValue}
                        displayEmpty={field.displayEmpty}
                        onChange={e => {
                          formOption.field.onChange(e);
                          onChange({prefixValue: e.target.value});
                        }}
                        disabled={editMode || field.readOnly}
                      />
                      {formOption.fieldState.error?.message ? (
                        <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                      ) : (
                        <FormSubtext>{field.prefixHelperText}</FormSubtext>
                      )}
                    </>
                  )}
                />
              </Stack>
            )}

            <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
              <FormInput
                name={field.id + 2}
                value={field.firstNameValue}
                placeholder={field.firstNamePlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => onChange({firstNameValue: e.target.value})}
                helperTextProps={{
                  text: field.firstNameHelperText
                }}
                rules={field.required ? {required: 'First name is required'} : {}}
              />
            </Stack>

            {field.middleNameEnabled && (
              <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
                <FormInput
                  name={field.id + 3}
                  value={field.middleNameValue}
                  placeholder={field.middleNamePlaceholder}
                  readOnly={editMode || field.readOnly}
                  onChange={e => onChange({middleNameValue: e.target.value})}
                  helperTextProps={{
                    text: field.middleNameHelperText
                  }}
                  rules={field.required ? {required: 'Middle name is required'} : {}}
                />
              </Stack>
            )}

            <Stack direction="column" spacing={1} sx={{flex: '1 1 !important', width: '100%'}}>
              <FormInput
                name={field.id + 4}
                value={field.lastNameValue}
                placeholder={field.lastNamePlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => onChange({lastNameValue: e.target.value})}
                helperTextProps={{
                  text: field.lastNameHelperText
                }}
                rules={field.required ? {required: 'Last name is required'} : {}}
              />
            </Stack>
          </Stack>
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewFullNameComponent);
