import React from 'react';
import {Stack, Typography} from '@mui/material';

const FormOptionHeading = ({title, subtitle, children}) => {
  return (
    <Stack direction={'column'} alignItems={'center'}>
      <Typography variant={'h5'}>{title}</Typography>
      <Typography variant={'body2'}>{subtitle}</Typography>
      {children}
    </Stack>
  );
};

export default FormOptionHeading;
