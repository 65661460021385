import httpRequest from '../api/httpRequest';

class FieldMappingService {
  getFieldMapping(params) {
    return httpRequest.get(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/mappings/fetch', params);
  }

  updateFieldMapping(params) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/mappings', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FieldMappingService();
