import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, styled, Tab, Tabs} from '@mui/material';
import CustomChipFrom from '../../common/Chips/CustomChipForm';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import {formRemoveFromFolder} from '../../state/features/folderForms/folderFormsSlice';

const FolderChipListTabs = styled(Tabs)(({theme}) => ({
  minHeight: 'max-content',
  alignItems: 'center',
  '& .MuiTab-root': {
    minHeight: 'max-content'
  },
  '& .MuiTabs-flexContainer': {gap: '8px'},
  '&:hover': {
    '& .MuiTabScrollButton-root': {
      display: 'inline-flex',
      zIndex: 99
    }
  },
  '& .MuiTabScrollButton-root': {
    display: 'none',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    background: theme.palette.text.secondary,
    color: theme.palette.common.white,
    position: 'absolute',
    left: '-10px',
    '&:last-child': {
      right: '-10px',
      left: 'auto'
    }
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  }
}));

const FolderNameChipList = ({id, folder = []}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFormMoveFromFolder = folderId => {
    dispatch(formRemoveFromFolder({formId: id, folderId}));
  };

  return (
    <Box
      sx={{
        flex: 1,
        width: 0,
        minWidth: 'calc(100% - 270px)',
        position: 'relative'
      }}
    >
      <FolderChipListTabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        value={value}
        onChange={handleChange}
      >
        {folder.map(singleFolder => (
          <Tab
            key={singleFolder.id}
            component={'div'}
            sx={{padding: 0}}
            label={
              <CustomChipFrom
                icon={fbpIcons.fbpFolderIcon}
                text={singleFolder.name}
                color={singleFolder.color}
                onClickRemove={() => handleFormMoveFromFolder(singleFolder.id)}
              />
            }
          />
        ))}
      </FolderChipListTabs>
    </Box>
  );
};

export default FolderNameChipList;
