import React from 'react';
import {Stack} from '@mui/material';
import InboxBodyHeaderLeft from './InboxBodyHeaderLeft';
import InboxBodyHeaderRight from './InboxBodyHeaderRight';

const InboxBodyHeader = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={'center'}
      spacing={2}
      sx={{padding: '0 17px', height: '72px'}}
    >
      <InboxBodyHeaderLeft />
      <InboxBodyHeaderRight />
    </Stack>
  );
};

export default InboxBodyHeader;
