import React from 'react';
import {useDispatch} from 'react-redux';
import {Box, Stack} from '@mui/material';
import {Shuffle} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddNewConditionItem from './AddNewConditionItem';
import CoreButton from '../../../../common/Button/CoreButton';
import CustomTitleBar from '../../../../common/TitleBar/CustomTitleBar';
import {
  CONDITION_LIST,
  CONDITION_PAGE_VIEW
} from '../../../../helpers/constant/formConditionConstant';
import {
  setConditionCurrentPage,
  setNewConditionSingleRule
} from '../../../../state/features/conditionSetting/conditionSettingSlice';

const AddNewCondition = () => {
  const dispatch = useDispatch();

  const handleClickBack = () => {
    dispatch(setConditionCurrentPage(CONDITION_PAGE_VIEW.HOME));
  };

  const handleAddNewCondition = (type = '') => {
    dispatch(setNewConditionSingleRule(type));
  };

  return (
    <CustomTitleBar
      title="Add Conditional Logic"
      content="Select an option below to build your logic flow."
      icon={<Shuffle />}
      iconBg="#006df5"
    >
      <Box p={3}>
        <CoreButton
          variant={'outlined'}
          color={'secondary'}
          startIcon={<ArrowBackIcon fontSize="small" />}
          onClick={handleClickBack}
        >
          Back to list
        </CoreButton>

        {/* ## Add New Condition List ## */}
        <Stack spacing={2} mt={3}>
          {CONDITION_LIST.map(condition => (
            <AddNewConditionItem
              key={condition.type}
              condition={condition}
              onClick={() => handleAddNewCondition(condition.type)}
            />
          ))}
        </Stack>
      </Box>
    </CustomTitleBar>
  );
};

export default AddNewCondition;
