import React from 'react';
import {MenuItem, Select, Typography} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 350
    }
  }
};

const ContactFieldDropDown = ({selected, onChange, allFields = {}}) => {
  const renderSelectItems = () => {
    let items = [];

    for (let key in allFields) {
      items.push(
        <MenuItem
          key={key}
          value={key}
          className={'text-truncate'}
          title={allFields[key].label}
          sx={{fontSize: '12px', display: 'block'}}
        >
          <Typography variant="caption" sx={{display: 'block'}}>
            {allFields[key].label}
          </Typography>
          <Typography variant="tooltip">Type : {allFields[key].type}</Typography>
        </MenuItem>
      );
    }

    return items;
  };

  return (
    <div>
      <Select
        displayEmpty
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={selected}
        onChange={onChange}
        size={'small'}
        MenuProps={MenuProps}
        sx={{
          '& .MuiInputBase-inputSizeSmall': {padding: '4px 14px', fontSize: '12px'}
        }}
        renderValue={value => allFields[value]?.label || 'Select Field'}
      >
        <MenuItem
          value={''}
          className={'text-truncate'}
          title={'Select Field'}
          sx={{fontSize: '12px'}}
        >
          Select Field
        </MenuItem>

        {renderSelectItems()}
      </Select>
    </div>
  );
};

export default ContactFieldDropDown;
