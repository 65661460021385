import {all, put} from 'redux-saga/effects';
import {delay, takeLatest} from '@redux-saga/core/effects';
import {getMediaIconsFailed, getMediaIconsSuccess} from './mediaIconSlice';

function* mediaIconWatcher() {
  yield takeLatest('mediaIcon/getMediaIcons', getMediaIconsSaga);
}

const responseData = {
  "message": "data fetched success",
  "data": {
    "total_count": 7273892,
    "icons": [
        {
            "icon_id": 317717,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/windows-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317717/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 5060515,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-flat-7/64/Social-media_Zhihu-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5060515/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 172543,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/sale-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/172543/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 2993747,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-2169/24/social_media_social_media_logo_imo-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/2993747/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 7123025,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/7123025/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 3057673,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-media-and-payment/64/-40-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3057673/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 1161953,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/picons-social/57/38-instagram-2-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1161953/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 4552222,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/seo-and-web-development-5/32/development_search_analysis_seo-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4552222/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 4373227,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/166_Houzz_logo_logos-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/4373227/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 104498,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104498/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 1217171,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/social-icons-grey/512/PINTEREST-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1217171/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 3668833,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn0.iconfinder.com/data/icons/expenses-vs-income/30/__utilities_service_repair_maintenance-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3668833/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 1530369,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn2.iconfinder.com/data/icons/weather-color-2/500/weather-22-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/1530369/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 317726,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/logotypes/32/square-google-plus-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/317726/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 3225191,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/2018-social-media-logotypes/1000/2018_social_media_popular_app_logo_instagram-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/3225191/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 5279112,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Instagram_svg-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279112/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 5305154,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/social-media-black-white-2/512/BW_Facebook_glyph_svg-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5305154/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        },
        {
            "icon_id": 104501,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn3.iconfinder.com/data/icons/picons-social/57/03-twitter-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/104501/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 211607,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-arrow-right-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/211607/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                }
            ]
        },
        {
            "icon_id": 5279111,
            "raster_sizes": [
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-16.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/16/download"
                        }
                    ],
                    "size": 16,
                    "size_width": 16,
                    "size_height": 16
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-20.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/20/download"
                        }
                    ],
                    "size": 20,
                    "size_width": 20,
                    "size_height": 20
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-24.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/24/download"
                        }
                    ],
                    "size": 24,
                    "size_width": 24,
                    "size_height": 24
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-32.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/32/download"
                        }
                    ],
                    "size": 32,
                    "size_width": 32,
                    "size_height": 32
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-48.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/48/download"
                        }
                    ],
                    "size": 48,
                    "size_width": 48,
                    "size_height": 48
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-64.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/64/download"
                        }
                    ],
                    "size": 64,
                    "size_width": 64,
                    "size_height": 64
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-128.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/128/download"
                        }
                    ],
                    "size": 128,
                    "size_width": 128,
                    "size_height": 128
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-256.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/256/download"
                        }
                    ],
                    "size": 256,
                    "size_width": 256,
                    "size_height": 256
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-512.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/512/download"
                        }
                    ],
                    "size": 512,
                    "size_width": 512,
                    "size_height": 512
                },
                {
                    "formats": [
                        {
                            "format": "png",
                            "preview_url": "https://cdn1.iconfinder.com/data/icons/social-media-circle-7/512/Circled_Facebook_svg-1024.png",
                            "download_url": "https://api.iconfinder.com/v4/icons/5279111/formats/png/1024/download"
                        }
                    ],
                    "size": 1024,
                    "size_width": 1024,
                    "size_height": 1024
                }
            ]
        }
    ]
  }
}

function* getMediaIconsSaga(action) {
  try {
    yield delay(2000);
    // const response = yield call(MediaIconService.getMediaIcons, action.payload);
      const response = {
          success: true,
          data: responseData.data,
          message: responseData.message
      }

    if (response.success) {
      yield put(getMediaIconsSuccess(response.data));
    } else {
      yield put(getMediaIconsFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* mediaIconSaga() {
  yield all([mediaIconWatcher()]);
}
