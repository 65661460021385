import React, {useEffect, useState} from 'react';
import {Stack, Typography} from '@mui/material';
import {
  ErrorOutline,
  PendingActions,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  Sync
} from '@mui/icons-material';
import CustomTooltip from '../Tooltip/CustomTooltip';
import {STATUS} from '../../helpers/constant/CoreConstants';

const getStatusObjectInfo = (status = 1) => {
  if (status === STATUS.PENDING) {
    return {
      label: 'Pending',
      icon: <PendingActions color={'action'} />
    };
  } else if (status === STATUS.PROCESSING) {
    return {
      label: 'Processing',
      icon: <Sync color={'warning'} />
    };
  } else if (status === STATUS.COMPLETE) {
    return {
      label: 'Processed',
      icon: <SentimentSatisfiedAlt color={'success'} />
    };
  } else if (status === STATUS.FAILED) {
    return {
      label: 'Failed',
      icon: <SentimentVeryDissatisfied color={'error'} />
    };
  } else {
    return {
      label: 'Failed',
      icon: <SentimentVeryDissatisfied color={'error'} />
    };
  }
};

const StatusChip = ({status = 0, error = ''}) => {
  const [chipInfo, setChipInfo] = useState({
    label: 'Pending',
    icon: ''
  });

  useEffect(() => {
    setChipInfo(getStatusObjectInfo(status));
  }, [status]);

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      {chipInfo.icon}
      <Typography variant={'body2'}>{chipInfo.label}</Typography>
      {status === STATUS.FAILED && (
        <CustomTooltip title={error || 'No failed reason found'} placement="top">
          <ErrorOutline color={'info'} fontSize={'small'} />
        </CustomTooltip>
      )}
    </Stack>
  );
};

export default StatusChip;
