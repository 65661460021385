import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
  addTagFailed,
  addTagSuccess,
  deleteTagFailed,
  deleteTagSuccess,
  getTagsFailed,
  getTagsSuccess,
  updateTagFailed,
  updateTagSuccess
} from './tagsSlice';
import TagService from '../../../services/Tag.service';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';

function* tagsWatcher() {
  yield takeEvery('tags/getTags', getTagsSaga);
  yield takeEvery('tags/addTag', addTagSaga);
  yield takeEvery('tags/updateTag', updateTagSaga);
  yield takeEvery('tags/deleteTag', deleteTagSaga);
}

function* getTagsSaga(action) {
  try {
    const response = yield call(TagService.getTags, action.payload);

    if (response.success) {
      yield put(getTagsSuccess(response.data));
    } else {
      yield put(getTagsFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addTagSaga(action) {
  try {
    const response = yield call(TagService.addTag, action.payload);

    if (response.success) {
      yield put(addTagSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(addTagFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateTagSaga(action) {
  try {
    const response = yield call(TagService.updateTag, action.payload);

    if (response.success) {
      yield put(updateTagSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(updateTagFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* deleteTagSaga(action) {
  try {
    const response = yield call(TagService.deleteTag, action.payload);

    if (response.success) {
      yield put(deleteTagSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(deleteTagFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* tagsSaga() {
  yield all([tagsWatcher()]);
}
