import React from 'react';
import {Divider, Paper} from '@mui/material';
import FormFooterBody from "./FormFooterBody";
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';

const FormFooter = () => {

  return (
    <Paper elevation={0}>
      <BasicTitleBar
        title={'Footer Settings'}
        content={'Display essential information in the footer.'}
      />
      <Divider />
      <FormFooterBody />
    </Paper>
  );
};

export default FormFooter;
