import React, {useState} from 'react';
import {ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled, Box} from '@mui/material';
import Styles from './CustomPopoverMenu.module.scss';

const CustomListItemText = styled(ListItemText)(({theme}) => ({
  paddingLeft: '0 !important'
}));
const StyledBox = styled(Box)(({theme}) => ({
  '&.folderNameChip': {
    width: '24px',
    height: '24px'
  }
}));

const positionLeft = {
  anchor: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transform: {
    vertical: 'top',
    horizontal: 'right'
  }
};

const positionRight = {
  anchor: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: {
    vertical: 'top',
    horizontal: 'left'
  }
};

export default function CustomPopoverMenu({
  wrapperClass,
  menuClass = '',
  position,
  onClickHide,
  callbackOnClose,
  children
}) {
  const [anchorActionMenu, setAnchorActionMenu] = useState(null);

  const handleOpenMenu = e => setAnchorActionMenu(e.currentTarget);
  const handleCloseMenu = e => {
    setAnchorActionMenu(null);
    if (callbackOnClose) callbackOnClose();
  };
  let buttonChildren = [];

  const renderActionMenu = () => {
    let items = [];

    if (Array.isArray(children)) {
      children.forEach((elem, index) => {
        const clickHandler = (e, element) => {
          const {onClickHide: onClickHideFromMenuItem, onClick} = element.props;
          if (onClickHideFromMenuItem || onClickHide) {
            handleCloseMenu();
          }
          onClick(e);
        };

        if (elem.type !== undefined && elem.type.name === CustomPopoverMenuButton.name) {
          buttonChildren.push(elem);
        } else if (elem.type !== undefined && elem.type.name === CustomPopoverMenuItem.name) {
          items.push(
            <MenuItem
              sx={{minWidth: '232px'}}
              disabled={elem.props.disabled}
              onClick={e => clickHandler(e, elem)}
              key={'action-menu-' + index}
              className={elem.props.className}
            >
              <ListItemIcon sx={{minWidth: '36px'}}>{elem.props.icon}</ListItemIcon>
              <CustomListItemText inset>{elem.props.title}</CustomListItemText>
            </MenuItem>
          );
        } else if (Array.isArray(elem)) {
          elem.forEach((item, index) => {
            items.push(
              <MenuItem
                onClick={e => clickHandler(e, item)}
                key={'action-menu-' + index}
                className={item.props.className}
              >
                <ListItemIcon>{item.props.icon}</ListItemIcon>
                <ListItemText inset>
                  <Typography variant="body2" color="text.primary">
                    {item.props.title}
                  </Typography>
                </ListItemText>
              </MenuItem>
            );
          });
        } else {
          items.push(elem);
        }
      });

      return items;
    } else {
      buttonChildren = children;
    }
    return null;
  };

  return (
    <Box sx={{cursor: 'pointer'}}>
      <StyledBox
        sx={{cursor: 'pointer'}}
        className={wrapperClass ? wrapperClass : ''}
        onClick={handleOpenMenu}
      >
        {buttonChildren}
      </StyledBox>

      <Menu
        // sx={{minWidth: '462px', maxWidth: '462px'}}
        className={`${menuClass || ''} ${Styles.fbpMoreDropdownWrapper}`}
        id="action-menu"
        anchorEl={anchorActionMenu}
        keepMounted
        open={Boolean(anchorActionMenu)}
        onClose={handleCloseMenu}
        anchorOrigin={position === 'left' ? positionLeft.anchor : positionRight.anchor}
        transformOrigin={position === 'left' ? positionLeft.transform : positionRight.transform}
        MenuListProps={{className: `${Styles.fbpMoreDropdown} ${menuClass}`}}
      >
        {renderActionMenu()}
      </Menu>
    </Box>
  );
}

export const CustomPopoverMenuButton = ({children}) => {
  return children;
};

export const CustomPopoverMenuItem = ({children}) => {
  return children;
};
