import httpRequest from '../api/httpRequest';

class FormSettingService {
  getFormSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/settings/fetch',
      params
    );
  }

  updateFormSetting(params) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/settings', params);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormSettingService();
