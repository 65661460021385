import React, {useEffect, useState} from 'react';
import {Box, FormControl, FormControlLabel, Grid, Stack, styled} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import CustomRadio from '../../../common/Radio/CustomRadio';
import CustomRadioGroup from '../../../common/Radio/CustomRadioGroup';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {FormText} from '../../../common/Form-UI/FormText';
import RequireIcon from '../../../common/Icons/RequireIcon';
import withFieldChange from '../../../hoc/withFieldChange';
import {ImageWrapperBox} from '../multipleChoice/AddMediaStyles';
import {GridViewItemBox, ListViewItemBox, SelectedIcon} from './RadioBoxStyle';

const FormControlLabelStyled = styled(FormControlLabel)(({theme}) => ({
  '&.MuiFormControlLabel-root': {
    margin: '0',
    '& .MuiRadio-root': {
      color: 'var(--fbp-theming-font-color)'
    }
  }
}));

const CardViewRadioComponent = ({field, onChange, editMode}) => {
  const [value, setValue] = useState(field.value);

  useEffect(() => {
    setValue(field.value);
  }, [field]);

  const handleChangeValue = (value, method, formOption) => {
    if (!field.readOnly && !editMode) {
      method?.setValue(field.id, value);
      onChange({value: value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <Box>
      <Stack direction="column" mb={4}>
        <Stack direction="row" alignItems="center">
          <FormCardLabel>
            {field.label}
            {field.required && <RequireIcon variant="card" />}
          </FormCardLabel>
        </Stack>
        <FormDescription>{field.description}</FormDescription>
      </Stack>
      <Stack direction="column" spacing={1}>
        <ConnectPublicForm
          name={field.id}
          value={field.value}
          rules={field.required ? {required: 'Please select an option'} : {}}
          render={(formOption, formMethods) => (
            <>
              {field.fieldStyle === 'list' && (
                <Box sx={{width: '100%'}}>
                  <FormControl component="fieldset" sx={{width: '100%'}}>
                    <CustomRadioGroup
                      style={{rowGap: '10px'}}
                      aria-label="radio"
                      name="radioComponent"
                      value={value}
                    >
                      {field.options.map((option, index) => {
                        const selected = field.value === option.value;
                        return (
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            key={index}
                            sx={{width: '100%', gap: '8px'}}
                            onClick={() => {
                              handleChangeValue(option.value, formMethods, formOption);
                            }}
                          >
                            <FormControlLabelStyled
                              value={option.value}
                              control={<CustomRadio className="builderRadio" />}
                            />
                            {option.icon && (
                              <ImageWrapperBox sx={{height: 48, width: 48}}>
                                <img
                                  src={
                                    option.icon ||
                                    process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                  }
                                  alt="Placeholder"
                                />
                              </ImageWrapperBox>
                            )}
                            <ListViewItemBox
                              alignItems={'center'}
                              selecteditem={selected.toString()}
                              ml={1.5}
                            >
                              <FormText>{option.title}</FormText>
                            </ListViewItemBox>
                          </Stack>
                        );
                      })}
                    </CustomRadioGroup>
                  </FormControl>
                </Box>
              )}

              {field.fieldStyle === 'grid' && (
                <Grid container spacing={1}>
                  {field.options.map((option, index) => {
                    const selected = field.value === option.value;
                    return (
                      <Grid
                        item
                        xs={6}
                        md={4}
                        lg={3}
                        className={'fbp_single_select_radio_box'}
                        sx={{
                          minWidth: {
                            xs: '50%',
                            md: field.shrink ? '50%' : '33.333333%',
                            lg: field.shrink ? '50%' : '25%'
                          }
                        }}
                      >
                        <GridViewItemBox
                          direction={'column'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          key={index}
                          selecteditem={selected.toString()}
                          onClick={() => {
                            handleChangeValue(option.value, formMethods, formOption);
                          }}
                        >
                          {option.icon && (
                            <ImageWrapperBox sx={{height: 48, width: 48}}>
                              <img
                                src={
                                  option.icon ||
                                  process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                }
                                alt="Placeholder"
                              />
                            </ImageWrapperBox>
                          )}
                          <FormText sx={{textAlign: 'center'}} mt={1}>
                            {option.title}
                          </FormText>
                          <SelectedIcon selected={selected.toString()}>
                            <CheckCircleOutlineIcon />
                          </SelectedIcon>
                        </GridViewItemBox>
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              {formOption.fieldState.error?.message ? (
                <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
              ) : (
                <FormSubtext>{field.helperText}</FormSubtext>
              )}
            </>
          )}
        />
      </Stack>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(CardViewRadioComponent);
