import React from 'react';

const LinkIcon = ({fill="#949DB2", opacity="0.6"}) => {
  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.10065 4.00008C2.10065 2.86008 3.02732 1.93341 4.16732 1.93341H6.83398V0.666748H4.16732C2.32732 0.666748 0.833984 2.16008 0.833984 4.00008C0.833984 5.84008 2.32732 7.33341 4.16732 7.33341H6.83398V6.06675H4.16732C3.02732 6.06675 2.10065 5.14008 2.10065 4.00008ZM4.83398 4.66675H10.1673V3.33341H4.83398V4.66675ZM10.834 0.666748H8.16732V1.93341H10.834C11.974 1.93341 12.9007 2.86008 12.9007 4.00008C12.9007 5.14008 11.974 6.06675 10.834 6.06675H8.16732V7.33341H10.834C12.674 7.33341 14.1673 5.84008 14.1673 4.00008C14.1673 2.16008 12.674 0.666748 10.834 0.666748Z"
        fill={fill}
        opacity={opacity}
      />
    </svg>
  );
};

export default LinkIcon;
