import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Stack, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {DeleteOutline, FileDownloadOutlined} from '@mui/icons-material';
import TableLoaderSkeleton from '../LoadingView/TableLoaderSkeleton';
import DeleteWarningModal from '../Builder/Modals/DeleteWarningModal';
import GlobalTablePagination from '../../common/Pagination/GlobalTablePagination';
import {EmptySubmissionFormIcon} from '../../common/Icons/EmptyDataIcons';
import GlobalEmptyPage from '../../common/GlobalEmptyPage/GlobalEmptyPage';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import StatusChip from '../../common/StatusChip/StatusChip';
import useBoolean from '../../hooks/useBoolean';
import {castArray} from '../../helpers/utils/castArray';
import {STATUS} from '../../helpers/constant/CoreConstants';
import downloadFileFromFileUrl from '../../helpers/utils/downloadFileFromFileUrl';
import {
  selectExportedFile,
  selectExportedFileFilters
} from '../../state/features/exportedFiles/exportedFileSelector';
import {
  deletedExportedFile,
  getExportedFiles,
  resetExportedFileFilters
} from '../../state/features/exportedFiles/exportedFileSlice';

const headCells = [
  {
    label: 'File Name'
  },
  {
    label: 'Requested time'
  },
  {
    label: 'Status'
  },
  {
    label: ''
  }
];

// Table Head
function FilesTableHead() {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: theme.palette?.other.outlinedBorder}}>
        {headCells.map(headCell => (
          <TableCell key={headCell.label} padding={'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ExportedFilesTable() {
  const dispatch = useDispatch();
  const {data: exportedFileList, isLoading, page, limit, count} = useSelector(selectExportedFile);
  const {searchText} = useSelector(selectExportedFileFilters);
  const [selectedFile, setSelectedFile] = useState({});
  const {
    value: isOpenDeleteModal,
    setTrue: onOpenDeleteModal,
    setFalse: onCloseDeleteModal
  } = useBoolean(false);

  useEffect(() => {
    dispatch(getExportedFiles({searchText, limit}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    return () => dispatch(resetExportedFileFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ## Pagination
  const onChangePerPage = (perPage, newPage) => {
    dispatch(
      getExportedFiles({
        searchText,
        page: newPage,
        limit: perPage
      })
    );
  };

  const onChangePage = newPage => {
    dispatch(
      getExportedFiles({
        searchText,
        page: newPage,
        limit
      })
    );
  };

  const handleDownload = (url = '') => {
    if (url) {
      downloadFileFromFileUrl(url);
    }
  };

  const handleDelete = fileInfo => {
    setSelectedFile(fileInfo);
    onOpenDeleteModal();
  };

  const onConfirmDelete = () => {
    dispatch(deletedExportedFile({ids: castArray(selectedFile.id)}));
    setSelectedFile({});
    onCloseDeleteModal();
  };

  if (isLoading) {
    return (
      <Box sx={{width: '100%'}} p={3}>
        <TableLoaderSkeleton numberOfRows={10} columns={5} />
      </Box>
    );
  }

  return (
    <Box sx={{width: '100%', height: 'calc(100% - 62px)'}} p={3}>
      <Paper sx={{width: '100%', mb: 2, height: 'calc(100% - 50px)'}} elevation={0}>
        <TableContainer sx={{height: '100%'}} className="fbp_has_scroll">
          <Table sx={{minWidth: 750}} aria-labelledby="tableTitle" size={'medium'} stickyHeader>
            <FilesTableHead />
            <TableBody>
              {exportedFileList.map((row, index) => {
                const formatCreatedAt = row.createdAt
                  ? format(new Date(row.createdAt), 'dd MMM yyyy')
                  : 'N/A';

                return (
                  <TableRow key={row.id} hover={true}>
                    <TableCell align="left">{row.fileName || 'N/A'}</TableCell>
                    <TableCell align="left">{formatCreatedAt}</TableCell>
                    <TableCell align="left">
                      <StatusChip status={row.status} error={row.failedReason} />
                    </TableCell>
                    <TableCell align="right">
                      <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                        {row.status === STATUS.COMPLETE && (
                          <CustomTooltip
                            onClick={() => handleDownload(row.fileUrl)}
                            title="Download"
                            placement="top"
                          >
                            <FileDownloadOutlined color={'action'} sx={{cursor: 'pointer'}} />
                          </CustomTooltip>
                        )}

                        {row.status !== STATUS.PROCESSING && (
                          <CustomTooltip
                            onClick={() => handleDelete(row)}
                            title="Delete"
                            placement="top"
                          >
                            <DeleteOutline color={'action'} sx={{cursor: 'pointer'}} />
                          </CustomTooltip>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {exportedFileList.length === 0 && (
            <Box sx={{height: '80%'}}>
              <GlobalEmptyPage
                icon={<EmptySubmissionFormIcon />}
                title={'No files exported'}
                description={'No corresponding exported files found'}
              />
            </Box>
          )}
        </TableContainer>
        <Box sx={{width: 'max-content', margin: '0 auto'}}>
          <GlobalTablePagination
            count={count}
            page={page}
            rowsPerPage={limit}
            onChangePage={onChangePage}
            onChangePerPage={onChangePerPage}
          />
        </Box>
      </Paper>

      <DeleteWarningModal
        warningSubtitle={'Are you sure!, want to delete this file?'}
        warningContent={'After delete, this operation cannot be undone.'}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </Box>
  );
}
