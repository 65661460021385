import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import NumberAdvanced from './NumberAdvanced';
import NumberGeneral from './NumberGeneral';
import NumberOptions from './NumberOptions';

const NumberRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <NumberGeneral field={field} />
      </CustomTab>

      <CustomTab title="Options">
        <NumberOptions field={field} />
      </CustomTab>

      <CustomTab title="Advanced">
        <NumberAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default NumberRightPanel;
