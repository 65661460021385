import React from 'react';

const GridViewIcon = ({fill = '#006DF5'}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.7825 1.5V5.7825H1.5V2.5725C1.5 2.2875 1.6125 2.0175 1.815 1.815C2.0175 1.6125 2.2875 1.5 2.5725 1.5H5.7825ZM5.7825 6.855V11.1375H1.5V6.855H5.7825ZM5.7825 12.2175V16.5H2.5725C2.2875 16.5 2.0175 16.3875 1.815 16.185C1.6125 15.9825 1.5 15.7125 1.5 15.4275V12.21H5.7825V12.2175ZM16.5 9V2.5725C16.5 2.2875 16.3875 2.0175 16.185 1.815C15.9825 1.6125 15.7125 1.5 15.4275 1.5H6.855V9H16.5ZM16.5 10.0725V15.4275C16.5 15.7125 16.3875 15.9825 16.185 16.185C15.9825 16.3875 15.7125 16.5 15.4275 16.5H6.855V10.0725H16.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default GridViewIcon;
