import React from 'react';
import {Box, Slider, Stack, styled, Typography} from '@mui/material';

const PercentageBox = styled(Box)(({theme}) => ({
  width: '100%',
  boxShadow:
    '0px 3px 5px -1px rgb(148 157 178 / 20%), 0px 5px 8px rgb(148 157 178 / 14%), 0px 1px 14px rgb(148 157 178 / 12%)',
  borderRadius: '8px 8px 0px 0px',
  padding: '6px 16px',
  display: 'flex',
  alignItems: 'center'
}));

const DetailsSlider = styled(Slider)(({theme, progressBarColor}) => ({
  '&.MuiSlider-root': {
    padding: '0'
  },
  '& .MuiSlider-rail': {
    display: 'none'
  },
  '& .MuiSlider-track': {
    border: 'none',
    color: progressBarColor
  },
  '& .MuiSlider-thumb': {
    display: 'none'
  },
  '& .MuiSlider-valueLabel': {
    display: 'none'
  }
}));

const valueLabelFormat = value => {
  return `${Math.round(value)}%`;
};

const AdvancedSliderStepper = ({percentage, color, backgroundColor, totalSteps, totalDone}) => {
  return (
    <Stack direction="column" sx={{width: '100%'}}>
      <PercentageBox sx={{backgroundColor: backgroundColor}}>
        <Typography id="non-linear-slider" gutterBottom m={0} sx={{color: color}}>
          {valueLabelFormat(percentage)}
        </Typography>
      </PercentageBox>
      <DetailsSlider
        progressBarColor={color}
        value={percentage}
        min={0}
        step={1}
        max={100}
        color={'primary'}
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="on"
        aria-labelledby="non-linear-slider"
        disableSwap
      />
    </Stack>
  );
};

export default AdvancedSliderStepper;
