import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import RightsideInlineInput from '../../Builder/RightComponents/RightsideInlineInput';
import RightSideDropdown from '../../Builder/RightComponents/RightSideDropdown';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';

const NumberOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideSwitch
        text="Limit Entry"
        title="Limit the input number"
        checked={field.limitEntry}
        onChange={value => onChangeValue({limitEntry: value})}
      >
        {field.limitEntry && (
          <>
            <RightsideInlineInput
              label="Minimum"
              inputType="number"
              value={field.minimumLimit}
              onChange={e => onChangeValue({minimumLimit: e.target.value})}
            />

            <RightsideInlineInput
              label="Maximum"
              inputType="number"
              value={field.maximumLimit}
              onChange={e => onChangeValue({maximumLimit: e.target.value})}
            />
          </>
        )}
      </RightSideSwitch>

      <RightSideDropdown
        text="Step"
        title="Each mouse scroll step or upper/lower arrow push on input"
        data={[
          {title: '0.001', value: '0.001'},
          {title: '0.01', value: '0.01'},
          {title: '0.1', value: '0.1'},
          {title: '0.5', value: '0.5'},
          {title: '1', value: '1'},
          {title: '2', value: '2'},
          {title: '5', value: '5'},
          {title: '10', value: '10'},
          {title: '50', value: '50'},
          {title: '100', value: '100'},
          {title: '1000', value: '1000'}
        ]}
        value={field.step}
        onChange={e => onChangeValue({step: e.target.value})}
      />
      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(NumberOptions);
