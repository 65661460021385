import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from '@mui/material/Select';
import {MenuItem, Stack, Typography} from '@mui/material';
import {CONDITION_LINK_LIST} from '../../../../helpers/constant/formConditionConstant';
import {selectConditionSettingSingleRule} from '../../../../state/features/conditionSetting/conditionSettingSelector';
import {modifyConditionSingleRuleActionLink} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {styled} from '@mui/material';

const ContainerStack = styled(Stack)(({theme}) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: '4px 4px 50% 50%',
  color: theme.palette.secondary.contrastText
}));

const ConditionTypeSelection = () => {
  const dispatch = useDispatch();
  const singleRule = useSelector(selectConditionSettingSingleRule);

  const {condition = [], link} = singleRule || {};

  const handleChangeConditionLink = event => {
    dispatch(modifyConditionSingleRuleActionLink(event.target.value));
  };

  // if total condition is one then hide this view
  if (condition?.length <= 1) return null;

  return (
    <ContainerStack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1}>
      <Typography variant="subtitle1">If </Typography>

      <Select
        size={'small'}
        color={'secondary'}
        inputProps={{'aria-label': 'Without label'}}
        name={'status'}
        value={link}
        onChange={handleChangeConditionLink}
        sx={{
          backgroundColor: '#fff',
          '& .MuiSelect-select': {
            padding: '3px 12px'
          }
        }}
      >
        {CONDITION_LINK_LIST.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Typography variant="subtitle1"> OF THE “IF” RULES ARE MATCHED</Typography>
    </ContainerStack>
  );
};

export default ConditionTypeSelection;
