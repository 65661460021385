import {Box, styled} from "@mui/material";

export const StyledSpan = styled('span')(({theme}) => ({
    borderLeft: `1px solid ${theme.palette.other.outlinedBorder}`,
    paddingLeft: '10px',
    marginLeft: '10px;'
}));

export const StyledBox = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    flex: '1 1 25%',
    border: `1px solid ${theme.palette.other.outlinedBorder}`,
    transition: 'all 0.3s ease-in-out',
    backgroundColor: theme.palette.other.secondaryHover,
    cursor: 'pointer',
    '&:not(:last-child)': {
        borderRight: 'none'
    },
    '&:hover': {
        transition: 'all 0.3s ease-in-out',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        transitionOrigin: 'center'
    },
    '&.active': {
        transition: 'all 0.3s ease-in-out',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        transitionOrigin: 'center',
        '&::before': {
            content: '" "',
            position: 'absolute',
            bottom: '-10px',
            width: '20px',
            height: '20px',
            transform: 'rotate(136deg)',
            background: `linear-gradient(45deg,transparent 50%, ${theme.palette.primary.main} 50%)`
        }
    }
}));