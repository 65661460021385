import React, {useMemo} from 'react';
import {Stack, Box} from '@mui/material';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import RequireIcon from '../../../common/Icons/RequireIcon';

const NumberComponent = ({field, editMode, onChange}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  const rule = useMemo(() => {
    let rule = {};
    if (field.required) {
      rule['required'] = 'This value is required';
    }
    if (field.limitEntry) {
      if (field.minimumLimit !== '') {
        rule['min'] = {
          value: field.minimumLimit,
          message: 'This value must be more than or equal to ' + field.minimumLimit
        };
      }
      if (field.maximumLimit !== '') {
        rule['max'] = {
          value: field.maximumLimit,
          message: 'This value must be less than or equal to ' + field.maximumLimit
        };
      }
    }
    return rule;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{position: 'relative'}}>
      <Box className={` ${labelAlignmentClass}`}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>
        <Stack direction="column" sx={{flex: '1 1 !important', width: '100%'}}>
          <FormInput
            name={field.id}
            value={field.value}
            type="number"
            placeholder={field.placeholder}
            readOnly={editMode || field.readOnly}
            onChange={e => onChange({value: e.target.value})}
            helperTextProps={{
              text: field.helperText
            }}
            rules={rule}
            min={field.limitEntry && field.minimumLimit}
            max={field.limitEntry && field.maximumLimit}
            step={field.step}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert className="mt-3" />}
    </Box>
  );
};

export default withFieldChange(NumberComponent);
