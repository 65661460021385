import React, {useState} from 'react';
import {Typography, Box, styled} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

const StyledAccordionSummery = styled(AccordionSummary, {
  shouldForwardProp: props => props !== 'summeryClass'
})(({summeryClass}) => ({
  '&.MuiAccordionSummary-root': {
    minHeight: '36px',
    padding: summeryClass === 'noSpacing' ? 0 : '0 16px',
    '& .MuiAccordionSummary-content': {
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    },
    '&.Mui-expanded': {
      minHeight: '36px'
    }
  }
}));

export default function CustomAccordion({
  children,
  expanded,
  className = '',
  summeryClass = '',
  ...props
}) {
  const [isExpanded, setIsExpanded] = useState(Boolean(expanded));

  return (
    <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)} elevation={0}>
      <StyledAccordionSummery expandIcon={<ExpandMoreIcon />} summeryClass={summeryClass}>
        <Typography variant="body2" color={'text.primary'} component="div">
          {props.heading}
        </Typography>
        <Box ml={0.5} sx={{display: 'inline-flex'}}>
          {props.sideicon}
        </Box>
      </StyledAccordionSummery>
      <AccordionDetails sx={{padding: 0}}>
        <Typography variant="body2" color={'text.secondary'} component="div">
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
