import React from 'react';
import HeadingGeneral from './HeadingGeneral';
import HeadingImage from './HeadingImage';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const HeadingRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <HeadingGeneral field={field} />
      </CustomTab>
      <CustomTab title="Heading Image">
        <HeadingImage field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default HeadingRightPanel;
