import React from 'react';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideSwitch from '../../Builder/RightComponents/RightSideSwitch';
import RightSideTextArea from '../../Builder/RightComponents/RightSideTextArea';

const ParagraphGeneral = ({field, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideTextArea
        text="Field Text"
        title="Change the field text"
        value={field.text}
        onChange={e => onChangeValue({text: e.target.value})}
      />

      <RightSideSwitch
        text="Hide Field"
        title="Hide the field on public submit"
        checked={field.hidden}
        onChange={value => onChangeValue({hidden: value})}
      />
    </Box>
  );
};
export default withFieldPropertyChange(ParagraphGeneral);
