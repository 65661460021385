import React from 'react';
import {useSelector} from 'react-redux';
import {Box, Divider, Typography} from '@mui/material';
import {Folder} from '@mui/icons-material';
import TimeTabs from './TimeTabs';
import TrafficTabs from './TrafficTabs';
import PresentationBreadcrumb from '../../common/Breadcrumb/PresentationBreadcrumb';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';

const FormStatistics = () => {
  const {formTitle} = useSelector(state => state.formStatistics);

  const breadCrumbArray = [
    {
      name: 'My Forms',
      icon: <Folder color={'action'} sx={{mr: 0.5}} fontSize="small" />,
      path: '/fbuilder/forms'
    },
    {
      name: formTitle
    }
  ];

  return (
    <Box
      className="fbp_has_scroll"
      bgcolor={'common.white'}
      sx={{
        borderRadius: '4px',
        width: '100%',
        height: 'calc(100vh - 115px)',
        '@media (max-width:992px)': {width: 'calc(100vw - 40px)'}
      }}
    >
      <Box p={2}>
        <CustomTooltip
          title={' All of your forms response Analytics will be Shown here.'}
          placement="top"
        >
          <Typography variant={'h5'} sx={{width: 'max-content'}}>
            Form Analytics
          </Typography>
        </CustomTooltip>
        <PresentationBreadcrumb breadCrumbArray={breadCrumbArray} />
      </Box>
      <Divider />
      <Box px={5} py={2}>
        <TimeTabs />
        <TrafficTabs />
      </Box>
    </Box>
  );
};

export default FormStatistics;
