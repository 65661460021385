export const getFullNameValue = (field, isPublic) => {
  let name = '';
  if (field.prefixEnabled) {
    name = field.prefixValue;
  }

  if (field.firstNameValue) {
    name = name.trim() + ' ' + field.firstNameValue;
  }

  if (field.middleNameValue) {
    name = name.trim() + ' ' + field.middleNameValue;
  }

  if (field.lastNameValue) {
    name = name.trim() + ' ' + field.lastNameValue;
  }

  if (isPublic) {
    return {
      prefix: field.prefixEnabled ? field.prefixValue : '',
      firstName: field.firstNameValue ? field.firstNameValue : '',
      middleName: field.middleNameEnabled ? field.middleNameValue?.trim() ?? '' : '',
      lastName: field.lastNameValue ? field.lastNameValue.trim() : '',
      value: name.trim().replace(/\b\w/g, l => l.toUpperCase())
    };
  }

  if (field.prefixEnabled && (name === 'Mr' || name === 'Mrs')) {
    return '';
  }

  return name.trim().replace(/\b\w/g, l => l.toUpperCase());
};

export const getAddressValue = (field, isPublic) => {
  let fullAddress = '';
  if (field.streetAddressEnabled && field.streetAddressValue) {
    fullAddress = field.streetAddressValue;
  }

  if (field.streetAddress2Enabled && field.streetAddress2Value) {
    fullAddress = fullAddress.trim() + ' ' + field.streetAddress2Value;
  }
  if (field.cityEnabled && field.cityValue) {
    fullAddress = fullAddress.trim() + ' ' + field.cityValue;
  }

  if (field.stateEnabled && field.stateValue) {
    fullAddress = fullAddress.trim() + ' ' + field.stateValue;
  }

  if (field.zipEnabled && field.zipValue) {
    fullAddress = fullAddress.trim() + ' ' + field.zipValue;
  }

  if (isPublic) {
    return {
      streetAddress1: field.streetAddressEnabled ? field.streetAddressValue?.trim() ?? '' : '',
      streetAddress2: field.streetAddress2Enabled ? field.streetAddress2Value?.trim() ?? '' : '',
      city: field.cityEnabled ? field.cityValue?.trim() ?? '' : '',
      state: field.stateEnabled ? field.stateValue?.trim() ?? '' : '',
      zip: field.zipEnabled ? field.zipValue?.trim() ?? '' : '',
      value: fullAddress.trim()
    };
  }

  return fullAddress.trim();
};

export const getOptionValue = (field, isPublic) => {
  if (!isPublic) {
    return field.value;
  }

  for (let i = 0; i < field.options.length; i++) {
    if (field.options[i].value === field.value) {
      return field.options[i].title;
    }
  }

  return field.value;
};

export const getCheckboxValue = field => {
  let values = [];

  for (let i = 0; i < field.options.length; i++) {
    if (field.options[i].checked) {
      values.push(field.options[i].title);
    }
  }

  return values.join(',');
};

export const getYesNoValue = (field, isPublic) => {
  if (!isPublic) {
    return field.value;
  }

  if (field.value === 'yes') {
    return field.positiveText;
  }

  return field.negativeText;
};

export const getConsentValue = (field, isPublic) => {
  if (!isPublic) {
    return field.value;
  }

  return field.value === true ? 'Yes' : 'No';
};
