import React from 'react';
import {Box, styled} from '@mui/material';
import {ColoredSpanStyle, MissingField, TypographyTruncate} from './ConditionStyles';
import {
  ALL_CONDITION_ACTION_ITEM_TEXT,
  CONDITION_ACTION_STATE,
  CONDITION_COLORS
} from '../../../../helpers/constant/formConditionConstant';
import {COMPONENT_TYPE} from '../../../../helpers/builderConstant/ComponentType';

const Container = styled(Box)(() => ({
  maxWidth: '70%',
  display: 'flex'
}));

const EachCondition = ({ruleType, condition, fieldLabelsById}) => {
  const operatorText = ALL_CONDITION_ACTION_ITEM_TEXT[condition.operator];
  const hasValue =
    condition.operator !== CONDITION_ACTION_STATE.IS_EMPTY &&
    condition.operator !== CONDITION_ACTION_STATE.IS_FILLED;

  const isFieldDeleted = !fieldLabelsById[condition.fieldId];

  const isFieldTypeThatHasOption =
    condition.component === COMPONENT_TYPE.YES_NO ||
    condition.component === COMPONENT_TYPE.RADIO ||
    condition.component === COMPONENT_TYPE.CHECKBOX ||
    condition.component === COMPONENT_TYPE.DROPDOWN ||
    condition.component === COMPONENT_TYPE.DISCLAIMER;

  const isOptionDeleted =
    (isFieldDeleted && isFieldTypeThatHasOption) ||
    (fieldLabelsById[condition.fieldId] &&
      fieldLabelsById[condition.fieldId].options &&
      !fieldLabelsById[condition.fieldId].options[condition.value]);

  let value;
  if (condition.component === COMPONENT_TYPE.DISCLAIMER) {
    value = condition.value ? 'CHECKED' : 'UNCHECKED';
  } else {
    value =
      !fieldLabelsById[condition.fieldId]?.options || isOptionDeleted
        ? condition.value
        : fieldLabelsById[condition.fieldId].options[condition.value];
  }

  const label = isFieldDeleted ? <MissingField /> : fieldLabelsById[condition.fieldId].label;

  return (
    <Container>
      <TypographyTruncate
        variant={'body1medium'}
        color={'text.primary'}
        textLength={typeof label === 'string' ? label.length : 0}
        title={typeof label === 'string' && label.length > 20 ? label : ''}
      >
        {label}
      </TypographyTruncate>
      <ColoredSpanStyle variant={'body1medium'} color={CONDITION_COLORS[ruleType]}>
        {operatorText.toUpperCase()}
      </ColoredSpanStyle>

      {hasValue && (
        <TypographyTruncate
          variant={'body1medium'}
          color={'text.primary'}
          textLength={typeof value === 'string' ? value.length : 0}
          title={typeof value === 'string' && value.length > 20 ? value : ''}
        >
          {isOptionDeleted ? <MissingField /> : <>"{value}"</>}
        </TypographyTruncate>
      )}
    </Container>
  );
};

export default EachCondition;
