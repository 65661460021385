import {styled, Typography} from '@mui/material';

export const FormDescription = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  fontSize: 'var(--fbp-theming-label-description-font-size)',
  color: 'var(--fbp-theming-sub-text-color)',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0.15px'
}));
