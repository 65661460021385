import React from 'react';
import {useSelector} from 'react-redux';
import ConditionTextField from './ConditionTextField';
import ConditionDropDownField from './ConditionDropDownField';
import {selectBuilderFieldValue} from '../../../../state/features/builderFields/builderSelector';

const ConditionTargetValueField = ({fieldId, name, value, onChange}) => {
  const dataOptions = useSelector(state => selectBuilderFieldValue(state, fieldId));

  // decide what to render
  let content = null;
  if (typeof dataOptions === 'string') {
    content = (
      <ConditionTextField
        name={name}
        placeholder={'Type here the value'}
        value={value}
        onChange={onChange}
      />
    );
  } else if (Array.isArray(dataOptions)) {
    content = (
      <ConditionDropDownField
        name={name}
        placeholder={'Please select'}
        options={dataOptions}
        value={value}
        onChange={onChange}
      />
    );
  }

  return content;
};

export default ConditionTargetValueField;
