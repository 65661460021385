import React from 'react';
import {Box, Grid} from '@mui/material';
import CommonInputLoader from './commonLoader/CommonInputLoader';

const FormSettingsLoader = () => {
  return (
    <Box sx={{p: 3, height: '100%'}}>
      <Grid container spacing={2} pb={1}>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12} md={6}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12} md={6}>
          <CommonInputLoader />
        </Grid>
        <Grid item xs={12}>
          <CommonInputLoader />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormSettingsLoader;
