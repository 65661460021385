import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {
  addTagOnSubmissionSuccess,
  getFormSubmissionDataFailed,
  getFormSubmissionDataSuccess,
  getMoreFormSubmissionDataFailed,
  getMoreFormSubmissionDataSuccess,
  inboxFormDeleteSuccess,
  inboxFormReplyFailed,
  inboxFormReplySuccess,
  removeTagFromSubmissionSuccess,
  updateFormSubmissionDataFailed,
  updateFormSubmissionDataSuccess
} from './formSubmissionSlice';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import SubmissionService from '../../../services/Submission.service';

function* formSubmissionWatcher() {
  yield takeEvery('formSubmission/getFormSubmissionData', getFormSubmissionDataSaga);
  yield takeEvery('formSubmission/getMoreFormSubmissionData', getMoreFormSubmissionDataSaga);
  yield takeEvery('formSubmission/updateFormSubmissionData', updateFormSubmissionDataSaga);
  yield takeEvery('formSubmission/inboxFormReply', inboxFormReplySaga);
  yield takeEvery('formSubmission/inboxFormDelete', inboxFormDeleteSaga);
  yield takeEvery('formSubmission/addTagOnSubmission', addTagOnSubmissionSaga);
  yield takeEvery('formSubmission/removeTagFromSubmission', removeTagFromSubmissionSaga);
}

const formatSubmissionData = (content = []) => {
  return content.map(singleContent => {
    if (typeof singleContent.submission === 'string' && singleContent.submission) {
      singleContent.submission = JSON.parse(singleContent.submission);
    } else {
      singleContent.submission = {};
    }
    return singleContent;
  });
};

function* getFormSubmissionDataSaga(action) {
  try {
    const response = yield call(SubmissionService.getFormSubmissionData, action.payload);

    if (response.success) {
      yield put(
        getFormSubmissionDataSuccess({
          data: formatSubmissionData(response.data.content || []),
          tableHeader: response.data.headers,
          formTitle: response.data.formTitle,
          formApiKey: response.data.formApiKey,
          page: response.data.currentPage,
          limit: response.data.limit,
          count: response.data.count
        })
      );
    } else {
      yield put(getFormSubmissionDataFailed(response.message));
    }
  } catch (err) {
    yield put(getFormSubmissionDataFailed('Network error'));
  }
}

function* getMoreFormSubmissionDataSaga(action) {
  try {
    const response = yield call(SubmissionService.getFormSubmissionData, action.payload);

    if (response.success) {
      yield put(
        getMoreFormSubmissionDataSuccess({
          data: formatSubmissionData(response.data.content || []),
          page: response.data.currentPage,
          limit: response.data.limit,
          count: response.data.count
        })
      );
    } else {
      yield put(getMoreFormSubmissionDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormSubmissionDataSaga(action) {
  try {
    // async api service call will be here
    // const response = yield call(NotificationService.getNotificationList, action.payload);
    const response = {success: true, data: action.payload};

    if (response.success) {
      yield put(updateFormSubmissionDataSuccess(response.data));
    } else {
      yield put(updateFormSubmissionDataFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* inboxFormReplySaga(action) {
  try {
    // async api service call will be here
    // const response = yield call(NotificationService.getNotificationList, action.payload);
    const response = {success: true, data: action.payload, message: 'inbox reply successfully'};

    if (response.success) {
      yield put(inboxFormReplySuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(inboxFormReplyFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* inboxFormDeleteSaga(action) {
  try {
    const response = yield call(SubmissionService.deleteSubmission, action.payload);

    if (response.success) {
      yield put(inboxFormDeleteSuccess(action.payload));
      yield put(showSuccessSnackbar(response.message));
    } else {
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* addTagOnSubmissionSaga(action) {
  try {
    yield put(addTagOnSubmissionSuccess(action.payload));
    const response = yield call(SubmissionService.addTagOnSubmission, action.payload);

    if (!response.success) {
      yield put(removeTagFromSubmissionSuccess(action.payload));
    }
  } catch (err) {
    yield put(removeTagFromSubmissionSuccess(action.payload));
  }
}

function* removeTagFromSubmissionSaga(action) {
  try {
    yield put(removeTagFromSubmissionSuccess(action.payload));
    const response = yield call(SubmissionService.removeTagFromSubmission, action.payload);

    if (!response.success) {
      yield put(addTagOnSubmissionSuccess(action.payload));
    }
  } catch (err) {
    yield put(addTagOnSubmissionSuccess(action.payload));
  }
}

export default function* formSubmissionSaga() {
  yield all([formSubmissionWatcher()]);
}
