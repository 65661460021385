import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import EmailShare from './EmailShare';
import EmbedShare from './EmbedShare/EmbedShare';
import QuickShare from './QuickShare/QuickShare';
import SettingPageWrapper from './SettingPageWrapper';
import {FORM_SETTING_ID} from '../../helpers/constant/CoreConstants';
import {resetEmbedSettings} from "../../state/features/embedSettings/embedSettingsSlice";

const options = [
  {
    id: 1,
    title: 'Quick Share',
    content: 'Share your form as a public link, Social Share or Share with QR code'
  },
  {
    id: 2,
    title: 'Embed',
    content: 'Embed your form on a website using the embed codes that suits your need.'
  },
  // {
  //   id: 3,
  //   title: 'Email',
  //   content: 'Share your form with a list of contacts using email.'
  // }
];

const PublishPage = () => {
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState(options[0].id);

  useEffect(() => {
    return () => dispatch(resetEmbedSettings())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SettingPageWrapper
      options={options}
      title="Publish Page"
      selectedPage={selectedPage}
      onChangePage={page => setSelectedPage(page)}
    >
      {selectedPage === FORM_SETTING_ID.QUICK_SHARE && <QuickShare />}

      {selectedPage === FORM_SETTING_ID.EMBED && <EmbedShare />}

      {selectedPage === FORM_SETTING_ID.EMAIL && <EmailShare />}
    </SettingPageWrapper>
  );
};

export default PublishPage;
