import React from 'react';
import {useSelector} from 'react-redux';
import {List, Box} from '@mui/material';
import CategoryListSkeleton from '../../LoadingView/CategoryListSkeleton';
import NoDataFoundPage from '../../../common/GlobalEmptyPage/NoDataFoundPage';
import AdminTemplateCategoryItem from './AdminTemplateCategoryItem';
import {TEMPLATE__CATEGORY_TYPE_INDUSTRY} from '../../../helpers/constant/templateConstant';
import {
  selectAdminTemplateCategories,
  selectAdminTemplateCategoryType
} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import Styles from '../AdminTemplate.module.scss';

const AdminTemplateCategories = () => {
  const {data: categoryList, isLoading} = useSelector(selectAdminTemplateCategories);
  const categoryType = useSelector(selectAdminTemplateCategoryType);

  let content = '';
  if (isLoading) {
    content = <CategoryListSkeleton count={10} />;
  } else if (!isLoading && categoryList.length === 0) {
    content = <NoDataFoundPage title={'No category found!'} />;
  } else if (!isLoading && categoryList.length > 0) {
    content = categoryList
      .filter(categoryItem => {
        if (TEMPLATE__CATEGORY_TYPE_INDUSTRY === categoryType) {
          return categoryItem.categoryType === categoryType;
        }
        return true;
      })
      .map(categoryItem => (
        <AdminTemplateCategoryItem key={categoryItem.id} categoryItem={categoryItem} />
      ));
  }

  return (
    <Box className={`${Styles.templateItem} fbp_has_scroll`} sx={{}}>
      <List sx={{width: '100%', maxWidth: 360, p: 1}}>{content}</List>
    </Box>
  );
};

export default AdminTemplateCategories;
