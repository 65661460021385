import React from 'react';
import Box from '@mui/material/Box';
import TickIcon from '../../../common/Icons/TickIcon';
import {styled} from '@mui/styles';
import {Typography} from '@mui/material';
import {DEFAULT_TAG_COLOR, TAG_COLORS} from '../../../helpers/constant/formSubmissionConstant';
import Styles from './AddTag.module.scss';
import Stack from '@mui/material/Box';

const ColorBox = styled(Box)(({theme}) => ({
  width: '30px',
  height: '20px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));

const AddTagColorBox = ({color, onChange}) => {
  const handleChange = selectedColor => {
    if (onChange) onChange(selectedColor);
  };

  const selectedColor = color || DEFAULT_TAG_COLOR;

  return (
    <Box className={Styles.tagListColorPicker}>
      <Typography variant={'subtitle1'} color={'text.secondary'} sx={{mb: 0.5}}>
        Choose colors
      </Typography>
      <Box className={`${Styles.tagListColorsContainer} fbp_has_scroll`}>
        <Stack direction="row" className={Styles.tagListColorsWrp}>
          {TAG_COLORS.map((tagColor, index) => {
            return (
              <ColorBox
                key={index}
                sx={{backgroundColor: tagColor}}
                onClick={() => handleChange(tagColor)}
              >
                {selectedColor === tagColor && <TickIcon size={'small'} color={'action'} />}
              </ColorBox>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};

export default AddTagColorBox;
