import React from 'react';
import {Skeleton} from '@mui/material';

const FormFolderSkeleton = ({count = 1}) => {
  return Array.from({length: count}).map((rowNum, index) => (
    <Skeleton key={index} variant="rect" sx={{mb: 0.7, borderRadius: 1}} height={38} />
  ));
};

export default FormFolderSkeleton;
