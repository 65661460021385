import React from 'react';
import {Stack, Typography} from '@mui/material';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';

const RightComponentTitle = ({text, title, className = ''}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      mb={1}
      className={className}
      sx={{'&.colorSchemeTitle': {padding: 0, margin: 0}}}
    >
      <Typography variant="body2medium">{text}</Typography>
      <CustomTooltip arrow placement="top" title={title}>
        <span style={{display: 'inline-flex', cursor: 'pointer', marginLeft: '5px'}}>
          {fbpIcons.fbpInfoRoundIcon}
        </span>
      </CustomTooltip>
    </Stack>
  );
};

export default RightComponentTitle;
