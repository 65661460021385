import React, {useState} from 'react';
import {Stack, Typography} from '@mui/material';
import CoreButton from '../../common/Button/CoreButton';
import {Add} from '@mui/icons-material';
import {useDispatch} from 'react-redux';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import AddOrEditFolderName from './AddOrEditFolderName';
import CreateFormModal from '../CreateForm/CreateFormModal';
import {addNewFormInsideFolder} from '../../state/features/folderForms/folderFormsSlice';
import CreateFolderIcon from '../../common/Icons/CreateFolderIcon';
import DownloadBlueIcon from '../../common/Icons/DownloadBlueIcon';
import RouteLink from '../../common/Link/RouteLink';

const FormListHeader = () => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenNewFormModal, setIsOpenNewFormModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  const handleOpenNewFormModal = () => setIsOpenNewFormModal(true);
  const handleCloseNewFormModal = () => setIsOpenNewFormModal(false);

  const handleAddNewForm = () => {
    dispatch(addNewFormInsideFolder({status: false, folderId: ''}));
    handleOpenNewFormModal();
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{mb: '13px'}}
      >
        <Stack direction="column" sx={{flex: 1}}>
          <Typography variant={'h5'} color={'text.primary'}>
            My Forms
          </Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            Now you can see your all forms & forms submission data here.
          </Typography>
        </Stack>

        <Stack direction={'row'} spacing={1}>
          <RouteLink to={`/fbuilder/files`}>
            <CoreButton
              color={'secondary'}
              variant={'outlined'}
              size={'large'}
              startIcon={<DownloadBlueIcon />}
            >
              Exported Files
            </CoreButton>
          </RouteLink>
          <CoreButton
            color={'secondary'}
            variant={'outlined'}
            size={'large'}
            startIcon={<Add />}
            onClick={handleOpenModal}
          >
            New Folder
          </CoreButton>

          <CoreButton
            color={'secondary'}
            size={'large'}
            variant="contained"
            onClick={handleAddNewForm}
          >
            New Form
          </CoreButton>
        </Stack>
      </Stack>

      <BasicModalWithHeader
        modalTitle={'Create New Folder'}
        titleIcon={<CreateFolderIcon />}
        open={isOpenModal}
        onClose={handleCloseModal}
      >
        <AddOrEditFolderName onClose={handleCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
      <CreateFormModal open={isOpenNewFormModal} onClose={handleCloseNewFormModal} />
    </>
  );
};

export default FormListHeader;
