import React, {useEffect, useMemo, useState} from 'react';
import {Box} from '@mui/material';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {isAdminRoute} from '../../helpers/utils/isAdminRoute';
import {BUILDER_OPTIONS, SCREEN_OPTIONS} from '../../helpers/constant/CoreConstants';
import Builder from './Builder';
import PublicPageContent from '../PublicView/PublicPageContent';
import BuilderHeader from './BuilderHeader';
import PublishPage from '../Setting/PublishPage';
import SeoSettings from '../../common/SeoSettings';
import SettingPage from '../Setting/SettingPage';
import CardViewPublicPageContent from '../PublicView/CardViewPublicPageContent';
import CustomLoader from '../../common/CustomLoader/CustomLoader';
import TemplatePublish from '../Setting/TemplatePublish';
import {fetchFormDetails} from '../../state/features/builderSetting/builderSettingSlice';
import {resetPublicPageDetails} from '../../state/features/publicPage/publicPageSlice';
import Theming from './Theming/Theming';
import Styles from './BuilderIndex.module.scss';

const BuilderIndex = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isAdmin = isAdminRoute();
  const {formId} = useParams();
  const {id, title, theme, isFormLoading} = useSelector(state => state.builderSetting);
  const [selectedOption, setSelectedOption] = useState(BUILDER_OPTIONS.BUILDER);
  const [selectedPreviewScreen, setSelectedPreviewScreen] = useState(SCREEN_OPTIONS.DESKTOP);

  useEffect(() => {
    if (
      (selectedOption === BUILDER_OPTIONS.BUILDER || selectedOption === BUILDER_OPTIONS.PREVIEW) &&
      id !== parseInt(formId)
    ) {
      dispatch(fetchFormDetails(formId));
    }

    //Reseting public page due to cache issue.
    dispatch(resetPublicPageDetails());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (location.state?.tab) {
      setSelectedOption(location.state.tab);
    }
  }, [location.state]);

  const selectedScreenClass = useMemo(() => {
    if (selectedOption !== BUILDER_OPTIONS.PREVIEW) {
      return '';
    }

    let previewClass = Styles.fbpFormBuilderPreviewWrapper + ' ';
    if (selectedPreviewScreen === SCREEN_OPTIONS.TAB) {
      previewClass += 'fbpTabView';
    } else if (selectedPreviewScreen === SCREEN_OPTIONS.MOBILE) {
      previewClass += 'fbpPhoneView';
    }

    return previewClass;
  }, [selectedOption, selectedPreviewScreen]);

  if (isFormLoading) {
    return <CustomLoader />;
  }

  return (
    <Box className={selectedScreenClass} sx={{position: 'relative'}}>
      <SeoSettings
        title="Form Builder Editor"
        description="Form builder Editor"
        keywords="test,test1"
      />
      <BuilderHeader
        formTitle={title}
        selectedOption={selectedOption}
        setSelectedOption={option => setSelectedOption(option)}
        selectedScreen={selectedPreviewScreen}
        onChangeScreen={screen => setSelectedPreviewScreen(screen)}
      />

      <Theming theme={theme} />

      {(selectedOption === BUILDER_OPTIONS.BUILDER ||
        selectedOption === BUILDER_OPTIONS.PREVIEW) && (
        <Box className={Styles.fbpFormBuilderBodyWrapper}>
          <Box className={Styles.fbpFormBuilderBodyInner}>
            <Box className={Styles.fbpFormBuilderContents}>
              <Box
                className={`${Styles.fbpFormBuilderScrollable} fbp_has_scroll ${
                  theme.layout === 'card' ? Styles.cardViewBodyWrapper : ''
                }`}
              >
                {selectedOption === BUILDER_OPTIONS.BUILDER ? (
                  <Builder />
                ) : theme.layout === 'classic' ? (
                  <Box className={Styles.publicPageContentWrp} sx={{paddingTop: '52px'}}>
                    <PublicPageContent theme={theme} editMode />
                  </Box>
                ) : (
                  <CardViewPublicPageContent theme={theme} editMode />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {selectedOption === BUILDER_OPTIONS.FORM_SETTING && !isAdmin && <SettingPage />}
      {selectedOption === BUILDER_OPTIONS.FORM_SETTING && isAdmin && <TemplatePublish />}

      {selectedOption === BUILDER_OPTIONS.QUICK_SHARE && <PublishPage />}
    </Box>
  );
};

export default BuilderIndex;
