import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  error: '',

  isFormUpdating: false,
  isFormUpdateSuccess: false
};

const formThankYouSlice = createSlice({
  name: 'formThankYou',
  initialState: initialState,
  reducers: {
    getFormThankYouSetting: (state, action) => {
      state.isLoading = true;
    },
    getFormThankYouSettingSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getFormThankYouSettingFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFormThankYouSetting: state => {
      state.isFormUpdating = true;
    },
    updateFormThankYouSettingSuccess: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
    },
    updateFormThankYouSettingFailed: (state, action) => {
      state.isFormUpdating = false;
      state.error = action.payload;
    }
  }
});

export const {
  getFormThankYouSetting,
  getFormThankYouSettingSuccess,
  getFormThankYouSettingFailed,

  updateFormThankYouSetting,
  updateFormThankYouSettingSuccess,
  updateFormThankYouSettingFailed
} = formThankYouSlice.actions;

export default formThankYouSlice.reducer;
