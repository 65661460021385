import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import GlobalEmptyPage from '../../common/GlobalEmptyPage/GlobalEmptyPage';
import {getAdminAccessData} from '../../state/features/adminAccess/adminAccessSlice';
import {selectAdminAccessData} from '../../state/features/adminAccess/adminAccessSelector';

const AdminPrivateLayout = ({children}) => {
  const dispatch = useDispatch();
  const {hasAccess, isLoading} = useSelector(selectAdminAccessData);

  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    setIsPageReady(true)
    dispatch(getAdminAccessData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // decide what to render
  let content = null;
  if (isLoading || !isPageReady) {
    content = (
      <Backdrop sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}} open={isLoading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  } else if (!isLoading && !hasAccess) {
    content = (
      <GlobalEmptyPage
        title={'No Page Found !!404'}
        description={'you have no access on this page'}
      />
    );
  } else if (!isLoading && hasAccess) {
    content = children;
  }

  return content;
};

export default AdminPrivateLayout;
