import React from 'react';
import {Stack, useTheme} from '@mui/material';
import {LightCoreButton} from "../../../common/Button/LightCoreButton";
import {CustomColorPicker} from '../../../common/CustomColorPicker';
import RightComponentTitle from './RightComponentTitle';
import {ColorDropIcon} from '../../../helpers/fbpIcons/IconComponents';

const RightSideColorPicker = ({text, title, color, onChange, className}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" className={className}>
      <RightComponentTitle text={text} title={title} />
      <CustomColorPicker
        color={color}
        onChange={color =>
          onChange(
            color.rgb.a === 1
              ? color.hex
              : `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`
          )
        }
      >
        <LightCoreButton
          size="small"
          variant="outlined"
          sx={{
            border: `1px solid ${theme.palette.text.secondary}`
          }}
          startIcon={<ColorDropIcon fill={color} />}
        >
          {color?.toUpperCase().replace('RGBA(', '').replace(')', '')}
        </LightCoreButton>
      </CustomColorPicker>
    </Stack>
  );
};

export default RightSideColorPicker;
