import React from 'react';
import {Typography, Box} from '@mui/material';
import {ALL_BLOCKS} from '../../../../helpers/builderConstant/LayoutBlocks';
import Prototype from '../../../../helpers/builderConstant/FieldPrototypes';
import {useDispatch, useSelector} from 'react-redux';
import {nanoid} from 'nanoid';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import CustomAccordion from '../../../../common/Accordion/CustomAccordion';
import {addBuilderField} from '../../../../state/features/builderFields/builderFieldsSlice';
import Styles from './Elements.module.scss';

function getStyle(style, snapshot) {
  if (!snapshot.isDragging) {
    return {transform: 'none'};
  }
  if (!snapshot.isDropAnimating) {
    return {
      ...style,
      border: '1px dashed #4099ff',
      top: style.top - 50
    };
  }

  return {
    ...style,
    transform: 'none',
    transitionDuration: `0.001s`
  };
}

export default function Elements() {
  const dispatch = useDispatch();
  const {theme} = useSelector(state => state.builderSetting);

  const handleInsertField = prototype => {
    dispatch(
      addBuilderField({
        component: {
          ...prototype,
          id: nanoid(),
          ...(prototype.labelAlignment && {labelAlignment: theme.labelAlignment})
        }
      })
    );
  };

  return ALL_BLOCKS.map((eachBlock, index) => (
    <Box className="builderElementBasicStyle" sx={{padding: '0'}} key={index}>
      <CustomAccordion key={index} heading={eachBlock.heading} expanded>
        <Droppable droppableId={eachBlock.heading} isDropDisabled={true}>
          {(provided, snapshot) => {
            return (
              <div ref={provided.innerRef} className={Styles.fbpBuildersElementsWrapper}>
                {eachBlock.blocks.map((element, index) => (
                  <Draggable
                    key={index}
                    draggableId={eachBlock.heading + element.type}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Box
                          className={Styles.fbpLeftEachElement}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getStyle(provided.draggableProps.style, snapshot)}
                          onClick={() => handleInsertField(Prototype[element.type])}
                        >
                          {element.icon}
                          <Typography variant={'helperText2'} color="text.secondary">
                            {element.title}
                          </Typography>
                        </Box>
                        {snapshot.isDragging && (
                          <Box className={Styles.fbpLeftEachElement}>
                            {element.icon}
                            <Typography variant={'helperText2'} color="text.secondary">
                              {element.title}
                            </Typography>
                          </Box>
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
                {/*{provided.placeholder}*/}
              </div>
            );
          }}
        </Droppable>
      </CustomAccordion>
    </Box>
  ));
}
