import React from 'react';
import {Box, styled} from '@mui/material';
import {
  BracesStyle,
  ColoredSpanStyle,
  MissingField,
  StatementLineStyle,
  TypographyTruncate
} from './ConditionStyles';
import {CONDITION_COLORS, CONDITION_TYPE} from '../../../../helpers/constant/formConditionConstant';

const Container = styled(Box)(() => ({
  maxWidth: '78%',
  display: 'flex'
}));

const EachAction = ({ruleType, action, fieldLabelsById}) => {
  const visibility =
    ruleType === CONDITION_TYPE.SKIP ? 'SKIP TO PAGE NO' : action.visibility.toUpperCase();

  const label =
    ruleType === CONDITION_TYPE.SKIP
      ? parseInt(action.page) + 1
      : fieldLabelsById[action.fieldId]?.label ?? <MissingField />;

  return (
    <StatementLineStyle direction={'row'} alignItems={'center'} spacing={1} pl={5}>
      <BracesStyle> &#123; </BracesStyle>

      <Container>
        <ColoredSpanStyle color={CONDITION_COLORS[ruleType]}>{visibility}</ColoredSpanStyle>
        <TypographyTruncate
          variant={'body1medium'}
          color={'text.primary'}
          textLength={typeof label === 'string' ? label.length : 0}
          title={typeof label === 'string' && label.length > 20 ? label : ''}
        >
          {label}
        </TypographyTruncate>
      </Container>

      <BracesStyle> &#125;</BracesStyle>
    </StatementLineStyle>
  );
};

export default EachAction;
