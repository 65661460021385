import React, {useState} from 'react';
import {List} from '@mui/material';
import {Add} from '@mui/icons-material';
import AddOrEditFolderName from './AddOrEditFolderName';
import CoreButton from '../../common/Button/CoreButton';
import CreateFolderIcon from '../../common/Icons/CreateFolderIcon';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import Styles from './FormList.module.scss';

const CreateFolder = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => setIsOpenModal(true);
  const handleCloseModal = () => setIsOpenModal(false);

  return (
    <>
      <List p={0} className={Styles.CreatNewFolder}>
        <CoreButton
          fullWidth
          variant={'contained'}
          color={'secondary'}
          startIcon={<Add />}
          onClick={handleOpenModal}
        >
          Create New Folder
        </CoreButton>
      </List>

      <BasicModalWithHeader
        titleIcon={<CreateFolderIcon />}
        modalTitle={'Create New Folder'}
        open={isOpenModal}
        onClose={handleCloseModal}
      >
        <AddOrEditFolderName onClose={handleCloseModal} isEditMode={false} />
      </BasicModalWithHeader>
    </>
  );
};

export default CreateFolder;
