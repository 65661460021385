import React from 'react';
import {Stack} from '@mui/material';
import {FormHeading, FormSubHeader} from '../../../common/Form-UI/FormHeading';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import ImageBox from "../../../common/ImageBox";

const HeadingComponent = ({field}) => {
  const headingSizeClass =
    field.size === 'medium' ? 'fbpHeadingMedium' : field.size === 'small' ? 'fbpHeadingSmall' : '';
  const headingAlignmentClass =
    field.alignment === 'center'
      ? 'fbpHeadingCenterAlign'
      : field.alignment === 'right'
      ? 'fbpHeadingRightAlign'
      : '';

  return (
    <>
      <Stack direction="column" spacing={2} className={headingAlignmentClass}>
        {field.image && (
          <ImageBox>
            <img src={field.image} alt="Heading" />
          </ImageBox>
        )}
        <FormHeading variant={'h3'} className={headingSizeClass}>
          {field.heading}
        </FormHeading>
        <FormSubHeader variant="body1medium">{field.subHeading}</FormSubHeader>
      </Stack>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default HeadingComponent;
