import React from 'react';
import Box from '@mui/material/Box';
import {Checkbox, FormControlLabel} from '@mui/material';

const RightSideInlineCheckbox = ({label = '', checked = true, onChange = () => {}}) => {

  return (
    <Box style={{padding: `0 10px`, height: 38}}>
      <FormControlLabel
        color="primary"
        className="text-truncate"
        title={label}
        sx={{width: '100%'}}
        control={
          <Checkbox
            checked={checked}
            onChange={() => onChange(!checked)}
            name="checkedB"
            color="primary"
          />
        }
        label={label}
      />
    </Box>
  );
};

export default RightSideInlineCheckbox;
