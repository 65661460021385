import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateBuilderField} from '../state/features/builderFields/builderFieldsSlice';

const withFieldPropertyChange =
  Component =>
  ({field}) => {
    const dispatch = useDispatch();
    const {selectedIndex} = useSelector(state => state.builderFields);
    const [localField, setLocalField] = useState({...field});

    useEffect(() => {
      setLocalField({...field});
    }, [selectedIndex, field]);

    const handleChangeValue = value => {
      let fieldValue = {...localField, ...value};
      setLocalField(fieldValue);
      dispatch(updateBuilderField(fieldValue));
    };

    return <Component field={localField} onChangeValue={handleChangeValue} />;
  };

export default withFieldPropertyChange;
