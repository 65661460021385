import React from 'react';
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import RateReviewIcon from "@mui/icons-material/RateReview";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import {green} from "@mui/material/colors";
import SingleReportCard from "./SingleReportCard";
import {selectFormReportState} from "../../state/features/formsReport/formReportSelector";

const formatNumber = (number=0) => {
    return number.toLocaleString('en-US');
}

const ReportFooter = () => {
    let {isLoading, totalAgencies, totalForms, totalViews, totalResponses} = useSelector(selectFormReportState);

    totalAgencies = formatNumber(totalAgencies)
    totalForms = formatNumber(totalForms)
    totalViews = formatNumber(totalViews)
    totalResponses = formatNumber(totalResponses)

    return (
        <Grid container spacing={2} sx={{mt:0}}>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Agency'}
                    subtitle={'Total Agency Used'}
                    amount={totalAgencies}
                    isLoading={isLoading}
                    icon={<BusinessCenterIcon />}
                    color={'info.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Forms'}
                    subtitle={'Total Forms'}
                    amount={totalForms}
                    isLoading={isLoading}
                    icon={<DynamicFormIcon />}
                    color={'secondary.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Views'}
                    subtitle={'Total Views'}
                    amount={totalViews}
                    isLoading={isLoading}
                    icon={<PreviewIcon />}
                    color={'primary.light'}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
                <SingleReportCard
                    title={'Responses'}
                    subtitle={'Total Responses'}
                    amount={totalResponses}
                    isLoading={isLoading}
                    icon={<RateReviewIcon />}
                    color={green[300]}
                />
            </Grid>
        </Grid>
    );
};

export default ReportFooter;