import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DateRangeController from '../../common/DateRangePicker/DateRangePicker';
import {debounceHandler} from '../../helpers/utils/debounceHandler';
import {addSubmissionDateRange} from '../../state/features/formSubmission/formSubmissionSlice';
import {selectInboxFilters} from '../../state/features/formSubmission/formSubmissionSelector';

const SubmissionDateRange = () => {
  const dispatch = useDispatch();
  const {startDate, endDate} = useSelector(selectInboxFilters);
  const [title, setTitle] = useState('All Time');

  // after reset
  useEffect(() => {
    if (!startDate || !endDate) {
      setTitle('All Time');
    }
  }, [startDate, endDate]);

  const doFilter = ({_fromDate, _toDate}) => {
    dispatch(addSubmissionDateRange({startDate: _fromDate, endDate: _toDate}));
    if (title) setTitle('');
  };
  const handleDatePicker = debounceHandler(doFilter, 1000);

  return (
    <DateRangeController
      datePickerCallback={handleDatePicker}
      title={title}
      isNeedInstantCallback={false}
    />
  );
};

export default SubmissionDateRange;
