import {styled} from '@mui/material';
import CoreButton from './CoreButton';

const ButtonForIcon = styled(CoreButton)(({theme}) => ({
  padding: '8px 8px !important',
  '& .MuiButton-startIcon': {
    marginRight: '0px',
    marginLeft: '0px'
  },
  '&.reset': {
    '&:hover': {
      '& .MuiButton-startIcon': {
        transform: 'rotate(360deg)',
        transition: '0.4s ease-in-out'
      }
    }
  }
}));

export default ButtonForIcon;
