import React from 'react';
import {Divider, IconButton, Modal, Paper, Stack, Box, styled, Typography} from '@mui/material';
import {Close} from '@mui/icons-material';

const PaperStyle = styled(Paper)(({theme}) => ({
  width: '98%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 0,
  borderRadius: '4px',
  paddingBottom: '4px',
  '& .modalTitle': {
    '& svg path': {
      fill: theme.palette.text.primary
    }
  },
  '&:focus-visible': {
    outline: 0
  }
}));

const BasicModalWithHeader = ({
  modalTitle = '',
  open,
  titleIcon,
  onClose,
  width = 700,
  disableOutSideClick = false,
  children,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...rest}
      >
        <PaperStyle sx={{maxWidth: width}}>
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignContent="center"
            sx={{padding: '24px 24px 16px 24px'}}
          >
            <Stack direction="row" alignItems="center" spacing={1} className={'modalTitle'}>
              {titleIcon}
              <Typography variant="body1medium" color="text.primary">
                {modalTitle}
              </Typography>
            </Stack>

            <IconButton color={'error'} onClick={onClose}>
              <Close fontSize={'medium'} />
            </IconButton>
          </Stack>
          <Divider />

          <Box>{children}</Box>
        </PaperStyle>
      </Modal>
    </React.Fragment>
  );
};

export default BasicModalWithHeader;
