import { FORM_INPUT_TYPES } from "../constant/CoreConstants";

const TEXT = 1;
const MULTILINE_TEXT = 2;
const NUMERIC = 3;
const DATE = 4;
const CHECKBOX = 5;
const SELECT = 6;
const RADIO = 7;
const PHONE = 8;
const ZIP_CODE = 9;
const WEBSITE = 10;
const DATETIME = 11;

export const CUSTOM_FIELD_MAPS = {
  [TEXT] : FORM_INPUT_TYPES.TEXT,
  [MULTILINE_TEXT]: FORM_INPUT_TYPES.MULTILINE,
  [NUMERIC]: FORM_INPUT_TYPES.NUMBER,
  [DATE] : FORM_INPUT_TYPES.DATE,
  [CHECKBOX]: FORM_INPUT_TYPES.CHECKBOX,
  [SELECT]: FORM_INPUT_TYPES.DROPDOWN,
  [RADIO]: FORM_INPUT_TYPES.RADIO,
  [PHONE]: FORM_INPUT_TYPES.PHONE,
  [ZIP_CODE]: FORM_INPUT_TYPES.TEXT,
  [WEBSITE]: FORM_INPUT_TYPES.URL,
  [DATETIME]: FORM_INPUT_TYPES.TEXT
}
