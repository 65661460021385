import React from 'react';
import {Stack, Box, styled} from '@mui/material';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import withFieldChange from '../../../hoc/withFieldChange';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import CrossIcon from '../../../common/Icons/CrossIcon';
import YesIcon from '../../../common/Icons/YesIcon';
import RequireIcon from '../../../common/Icons/RequireIcon';

const StylesYesNoBtn = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1',
  minHeight: '52px',
  border: '1.5px solid rgba(0, 109, 245, 0.5)',
  borderRadius: '4px',
  gap: '12px',
  transition: 'all 0.2s',
  color: 'var(--fbp-theming-text-color) ',
  fontFamily: 'var(--fbp-theming-font-family) !important',
  background: 'var(--fbp-theming-input-background)',
  fontSize: '18px',
  lineHeight: '28px',
  letterSpacing: '0.15px',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.other.skylight,
    color: theme.palette.common.white,
    '& svg path': {fill: theme.palette.common.white}
  },
  '&.active': {
    background: theme.palette.other.skylight,
    color: theme.palette.common.white,
    '& svg path': {fill: theme.palette.common.white}
  }
}));

const YesNoComponent = ({field, onChange, editMode}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  const handleChangeValue = (value, method, formOption) => {
    if (!editMode && !field.readOnly) {
      method?.setValue(field.id, value);
      onChange({value: value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <Box sx={{position: 'relative'}}>
      <Box mb={2} className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>

          <Stack direction="column" spacing={1} sx={{width: '100%'}}>
        <ConnectPublicForm
          name={field.id}
          value={field.value}
          rules={field.required ? {required: 'Please select an option'} : {}}
          render={(formOption, formMethods) => (
            <>
              <Stack direction="row" spacing={1} sx={{flex: '1 1', width: '100%'}}>
                <StylesYesNoBtn
                  className={field.value === 'yes' ? 'active' : ''}
                  onClick={() => {
                      handleChangeValue(
                          field.value === 'yes' ? null : 'yes',
                          formMethods,
                          formOption
                      );
                  }}
                >
                  <YesIcon />
                  {field.positiveText}
                </StylesYesNoBtn>
                <StylesYesNoBtn
                  className={field.value === 'no' ? 'active' : ''}
                  onClick={() => {
                      handleChangeValue(field.value === 'no' ? null : 'no', formMethods, formOption);
                  }}
                >
                  <CrossIcon />
                  {field.negativeText}
                </StylesYesNoBtn>
              </Stack>

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
      </Stack>
    </Box>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(YesNoComponent);
