import React from 'react';
import MaskedInput from 'react-text-mask';
import CoreTextField from "./TextField/CoreTextField";
import {checkLetterExistInText} from '../helpers/utils/checkLetterExistInText';

const mask = [
  '+',
  /\d/,
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const CustomMuiPhoneInput = ({
  placeholder,
  onChange,
  onBlur,
  value,
    ...rest
}) => {
  const handleChange = e => {
    let value = e.target.value;
    if (checkLetterExistInText(value) || value === '') {
      if (value.length > 0 && value[0] !== '1') {
        value = 1 + value;
      }
      onChange(value);
    } else {
      try {
        value = value.replace(/[^0-9]+/g, '');
        if (value.length > 0 && value[0] !== '1') {
          value = 1 + value;
        }
        onChange(value);
      } catch (e) {
        console.log('Error:: ', e);
      }
    }
  };

  const handleBlurChange = e => {
    let value = e.target.value;
    if (checkLetterExistInText(value) || value === '') {
      if (value.length > 0 && value[0] !== '1') {
        value = 1 + value;
      }
      if (onBlur) {
        onBlur(value);
      }

    } else {
      try {
        value = value.replace(/[^0-9]+/g, '');
        if (value.length > 0 && value[0] !== '1') {
          value = 1 + value;
        }
        if (onBlur) {
          onBlur(value);
        }
      } catch (e) {
        console.log('Error:: ', e);
      }
    }
  };


  return (
    <MaskedInput
      mask={mask}
      placeholder={placeholder}
      guide={true}
      keepCharPositions={true}
      value={value}
      onChange={handleChange}
      onBlur={handleBlurChange}
      render={(ref, props) => (
        <CoreTextField
          inputRef={ref}
          type="tel"
          {...props}
          {...rest}
        />
      )}
    />
  );
};

export default CustomMuiPhoneInput;
