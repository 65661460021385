import React from 'react';
import {Box, Typography} from '@mui/material';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import ColorCustomize from '../../helpers/utils/ColorCustomize';
import Styles from './CustomChip.module.scss';

const CustomChipFrom = ({icon, text, color = '#333', onClickRemove}) => {
  const handleClick = () => {
    if (onClickRemove) onClickRemove();
  };

  return (
    <Box
      className={`${Styles.fbpFoldernameChip} ${Styles.checked}`}
      style={{backgroundColor: ColorCustomize.changeOpacity(color, 0.1)}}
    >
      {icon ? (
        <Box component={'span'} sx={{display: 'inline-flex', mx: '3px'}}>
          {fbpIcons.fbpDynamicFolderIcon(color)}
        </Box>
      ) : (
        ''
      )}
      <Typography
        sx={{color: color}}
        pr={1}
      >
        {text}
      </Typography>
      <span className={Styles.chipTrash} onClick={handleClick}>
        {fbpIcons.fbpCrossIcon}
      </span>
    </Box>
  );
};

export default CustomChipFrom;
