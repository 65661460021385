import {styled, Typography} from '@mui/material';

export const FormSubtextError = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  fontSize: 'var(--fbp-theming-label-sub-text-font-size) ',
  color: theme.palette.error.main,
  fontWeight: 400,
  lineHeight: '14px',
  letterSpacing: '0.4px'
}));
