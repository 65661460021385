import React, {useEffect, useState} from 'react';
import {Slide} from '@mui/material';
import {useIsMounted} from '../hooks/IsMounted';

export const AnimationHandler = ({animation, children, onAnimationChangeCallback, page}) => {
  const isMounted = useIsMounted();
  const [animationIn, setAnimationIn] = useState(false);
  const [direction, setDirection] = useState();
  const [currentPage, setCurrentPage] = useState(page);
  const [currentChildren, setCurrentChildren] = useState(children);

  useEffect(() => {
    if (isMounted) {
      let isBack = page < currentPage;
      setCurrentPage(page);
      getAnimationDetails(false, isBack);
      setTimeout(() => {
        setCurrentChildren(children);
        getAnimationDetails(true, isBack);
        onAnimationChangeCallback && onAnimationChangeCallback();
      }, 300);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAnimationDetails = (anim, isBack) => {
    let animTodo = isBack ? !anim : anim;
    if (animation === 'slideLeft') {
      setDirection(animTodo ? 'left' : 'right');
    } else if (animation === 'slideRight') {
      setDirection(animTodo ? 'right' : 'left');
    } else if (animation === 'slideUp') {
      setDirection(animTodo ? 'up' : 'down');
    } else if (animation === 'slideDown') {
      setDirection(animTodo ? 'down' : 'up');
    }
    setAnimationIn(anim);
  };

  //No animation neccessary
  if (!animation || animation === 'default' || direction === undefined) {
    return currentChildren;
  }

  if (animation === 'slideUp' || animation === 'slideDown') {
    return (
      <Slide direction={direction} in={animationIn} style={{height: '100%'}}>
        <div>{currentChildren}</div>
      </Slide>
    );
  }

  return (
    <Slide direction={direction} in={animationIn} timeout={200} style={{height: '100%'}}>
      <div style={{height: '100%'}}>{currentChildren}</div>
    </Slide>
  );
};
