import React from 'react';
import {useSelector} from 'react-redux';
import {styled, Box} from '@mui/material';
import FormListFilterSearch from './FormListFilterSearch';
import FormListBulkActions from './FormListBulkActions';
import FormListFilterStatus from './FormListFilterStatus';
import FormListFilterDateRange from './FormListFilterDateRange';
import FormResetFilter from './FormResetFilter';
import {selectSelectedFormIds} from '../../state/features/folderForms/folderFormSelector';

const HeaderBoxWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gridGap: '8px',
  marginBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.action.hover}`,
  paddingBottom: theme.spacing(1)
}));

const FormListFilterHeader = () => {
  const selectedFormIds = useSelector(selectSelectedFormIds);

  return (
    <HeaderBoxWrapper>
      <Box sx={{flex: 1}}>
        {selectedFormIds?.length === 0 ? <FormListFilterSearch /> : <FormListBulkActions />}
      </Box>
      <FormListFilterDateRange />
      <FormListFilterStatus />
      <FormResetFilter />
    </HeaderBoxWrapper>
  );
};

export default FormListFilterHeader;
