import React from 'react';
import {Box, styled} from '@mui/material';
import Styles from './banner.module.scss';

const ProfileImageWrapper = styled(Box)(
  ({theme, clickable, bordercolor, imagebg, imageradius}) => ({
    cursor: clickable ? 'pointer' : 'default',
    borderRadius: `${imageradius}%`,
    backgroundColor: imagebg,
    border: `3px solid ${bordercolor}`,
    '& img': {
      objectFit: 'contain'
    }
  })
);
const CoverImageWrapper = styled(Box)(
  ({theme, clickable, coverImage, coverPosition, scaleCover}) => ({
    width: '100%',
    backgroundColor: theme.palette.common.white,
    cursor: clickable ? 'pointer' : 'default',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    backgroundImage: `url(${coverImage})`,
    backgroundAttachment: 'scroll',
    backgroundPosition: coverPosition,
    backgroundSize: scaleCover + '%',
    backgroundRepeat: 'no-repeat'
  })
);

export default function Banner({
  theme: {
    coverImage,
    profileImage,
    coverHeight = 312,
    coverPositionX = 50,
    coverPositionY = 50,
    scaleCover = 100,
    scaleProfileImage = 1,
    radiusProfileImage = 50,
    profileImageBackground = '#fff',
    profileImageTranslateX = 0,
    profileImageTranslateY = 0
  },
  onClickCover = null,
  onClickProfile = null
}) {
  return (
    <Box sx={{position: 'relative'}} className={`${Styles.fbpBannerWrapper}`}>
      {coverImage && (
        <CoverImageWrapper
          clickable={onClickCover}
          sx={{height: {xs: '100px', md: '160px', lg: `${coverHeight}px`}}}
          className={Styles.bannerImage}
          onClick={() => {
            onClickCover && onClickCover();
          }}
          coverImage={coverImage}
          coverPosition={`${coverPositionX}% ${coverPositionY}%`}
          scaleCover={scaleCover}
        ></CoverImageWrapper>
      )}
      {profileImage && (
        <ProfileImageWrapper
          clickable={onClickCover}
          imageradius={radiusProfileImage}
          imagebg={profileImageBackground}
          bordercolor={profileImageBackground}
          className={`${Styles.profileImageContainer} ${
            !coverImage ? Styles.profileImageWithoutBanner : ''
          }`}
          onClick={() => {
            onClickProfile && onClickProfile();
          }}
        >
          <Box
            component="img"
            src={profileImage}
            alt=""
            sx={{
              transform: `translateX(${profileImageTranslateX}%) translateY(${profileImageTranslateY}%) scale(${scaleProfileImage})`
            }}
          />
        </ProfileImageWrapper>
      )}
    </Box>
  );
}
