import React from 'react';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';
import withThemeChange from '../../../hoc/withThemeChange';
import RightSideUploadButtonComponent from '../../Builder/RightComponents/RightSideUploadButtonComponent';
import RightSideImagePreviewComponent from '../../Builder/RightComponents/RightSideImagePreviewComponent';
import RightSideColorPicker from '../../Builder/RightComponents/RightSideColorPicker';
import RightSideSlider from '../../Builder/RightComponents/RightSideSlider';
import CoreButton from '../../../common/Button/CoreButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {DEFAULT_IMAGE_SETTINGS} from '../../../helpers/builderConstant/DefaultTheme';

const PageImageRightPanel = ({theme, onChangeValue}) => {
  const dispatch = useDispatch();

  const handleEditOptionOpen = componentType => {
    dispatch(changeSelectedBuilderField({index: null, pageIndex: null, componentType}));
  };

  return (
    <Box sx={{mt: 2}}>
      <Box px={2} pb={2}>
        <CoreButton
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<ArrowBackIcon />}
          onClick={() => handleEditOptionOpen(COMPONENT_TYPE.THEME)}
        >
          Back
        </CoreButton>
      </Box>

      {!theme.pageImage && (
        <>
          <RightSideColorPicker
            text="Page Color"
            title="Change the color of the page background"
            color={theme.pageColor}
            onChange={color => onChangeValue({pageColor: color})}
            className={'builderElementBasicStyle'}
          />
        </>
      )}

      <RightSideUploadButtonComponent
        text="Page Image"
        title="Change the image of the page background"
        buttonText="Choose Image"
        recommendedSize={'1920 x 1080'}
        onUpload={image => onChangeValue({pageImage: image})}
      />

      {theme.pageImage && (
        <>
          <RightSideImagePreviewComponent
            text="Page Image"
            title="Page Image Preview"
            image={theme.pageImage}
            onRemove={() => onChangeValue({pageImage: null, ...DEFAULT_IMAGE_SETTINGS.pageImage})}
          />

          <RightSideSlider
            step={0.05}
            min={0}
            max={1}
            text="Opacity"
            title="Change the image opacity"
            value={theme.pageImageOpacity}
            onChangeValue={value => onChangeValue({pageImageOpacity: value})}
          />

          <RightSideColorPicker
            text="Opacity Color"
            title="Change the opacity color to the page image"
            color={theme.pageImageColor}
            onChange={color => onChangeValue({pageImageColor: color})}
            className={'builderElementBasicStyle'}
          />

          <Box p={2} sx={{textAlign: 'end'}}>
            <CoreButton
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              startIcon={<RestartAltIcon />}
              onClick={() => onChangeValue({...DEFAULT_IMAGE_SETTINGS.pageImage})}
            >
              Reset Settings
            </CoreButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withThemeChange(PageImageRightPanel);
