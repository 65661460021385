import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addNewPage,
  changeSelectedBuilderField,
  deletePage
} from '../../state/features/builderFields/builderFieldsSlice';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import EachEditContent from './EachEditContent';
import ComponentWrapper from './FormComponents/ComponentWrapper';
import Box from '@mui/material/Box';
import AddNewPageIcon from '../../common/Icons/AddNewPageIcon';
import {RemovePageIcon} from '../../helpers/fbpIcons/IconComponents';
import DropElementHere from '../../common/Form-UI/DropElementHere';
import Styles from './BuilderContent.module.scss';
import Banner from './Banner/Banner';
import {COMPONENT_TYPE} from '../../helpers/builderConstant/ComponentType';

const getItemStyle = (isDragging, draggableStyle, containerWidth, width) => {
  if (!isDragging) {
    return {
      ...draggableStyle,
      width: (width / 100) * containerWidth - 6
    };
  }
  return {...draggableStyle};
};

const BuilderContent = ({selectedField}) => {
  const dispatch = useDispatch();
  const {fields, totalPages} = useSelector(state => ({
    fields: state.builderFields.fields,
    totalPages: state.builderFields.fields.length
  }));
  const {theme} = useSelector(state => state.builderSetting);

  const changeSelectedField = (index, pageIndex, componentType = null) => {
    dispatch(changeSelectedBuilderField({index, pageIndex, componentType}));
  };
  const handleAddNewPage = () => {
    dispatch(addNewPage());
  };
  const handleDeletePage = deletePageIndex => {
    dispatch(deletePage(deletePageIndex));
  };

  const handleOutsideClick = e => {
    if (e.target.getAttribute('class') === e.currentTarget.getAttribute('class')) {
      dispatch(changeSelectedBuilderField(null));
    }
  };

  return (
    <Box
      className={`${Styles.fbpFormBuilderBodyContent} fbp_has_scroll`}
      onClick={handleOutsideClick}
    >
      {(theme.coverImage || theme.profileImage) && (
        <Box mb={theme.coverImage && theme.profileImage ? 2 : -4}>
          <Banner
            theme={theme}
            onClickCover={() => changeSelectedField(null, null, COMPONENT_TYPE.COVER_IMAGE)}
            onClickProfile={() => changeSelectedField(null, null, COMPONENT_TYPE.PROFILE_IMAGE)}
          />
        </Box>
      )}

      {fields.map((eachPage, eachPageIndex) => (
        <Box
          key={eachPage.pageId}
          className={`${Styles.fbpClassicViewContent} ${eachPageIndex !== 0 ? 'mt-5' : ''}`}
          id={`page-container-${eachPage.pageId}`}
        >
          <Droppable droppableId={'droppable_' + eachPageIndex}>
            {(provided, snapshot) => {
              return (
                <Box
                  className={Styles.fbpPageContainer}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {eachPage.pageItems.length === 1 && (
                    <Box sx={{margin: '0 80px', width: '100%'}}>
                      <DropElementHere />
                    </Box>
                  )}

                  {eachPage.pageItems.map((field, index) => {
                    return (
                      <Draggable key={field.id} draggableId={field.id} index={index}>
                        {(provided, snapshot) => {
                          const width =
                            field.shrink === false || field.shrink === undefined
                              ? 100
                              : field.width === 'auto'
                              ? 50
                              : field.width;
                          return (
                            <Box
                              key={field.id}
                              snapshot={snapshot}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                theme.containerWidth,
                                width
                              )}
                            >
                              <ComponentWrapper
                                field={field}
                                onClick={() => changeSelectedField(index, eachPageIndex)}
                                selected={field.id === selectedField?.id}
                              >
                                <EachEditContent
                                  field={field}
                                  currentPage={eachPageIndex + 1}
                                  totalPages={totalPages}
                                />
                              </ComponentWrapper>
                            </Box>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              );
            }}
          </Droppable>

          {eachPageIndex !== 0 && (
            <Box
              className={Styles.fbpBuilderDeletePageButton}
              sx={{my: 2}}
              onClick={() => handleDeletePage(eachPageIndex)}
            >
              <RemovePageIcon />
            </Box>
          )}

          {eachPageIndex === fields.length - 1 && (
            <Box className={Styles.fbpBuilderAddNew} onClick={handleAddNewPage}>
              <span>
                <AddNewPageIcon fill={'#949DB2'} />
              </span>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default BuilderContent;
