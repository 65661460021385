import {Box, styled} from '@mui/material';

export const EmbedShareOption = styled(Box)(({theme}) => ({
  padding: '8px 12px',
  height:'100%',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '6px',
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  cursor: 'pointer',
  '& .radioButtonBox': {
    marginLeft: 'auto',
    display: 'inline-flex'
  },
  '&:hover': {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.other.secondaryHover
  },
  '& .fbpEmbedIcon': {
    padding: '2px',
    minWidth: '28px',
    width: '28px',
    height: '28px',
    border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
    borderRadius: '4px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary
  },
  '&.activeOption': {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.other.secondaryHover,

    '& .fbpEmbedIcon': {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`
    }
  }
}));
