import React, {cloneElement, useState} from 'react';
import {Icon, Popover} from '@mui/material';
import Styles from './CustomIconPicker.module.scss';

const icons = [
  'arrow_right_alt',
  'done_all',
  'check',
  'east',
  'double_arrow',
  'arrow_right',
  'arrow_forward_ios',
  'arrow_back_ios_new',
  'arrow_forward',
  'arrow_back',
  'label_important_outline',
  'done_outline',
  'arrow_upward',
  'arrow_downward',
  'first_page',
  'create_new_folder',
  'front_hand',
  'calendar_month',
  'schedule',
  'language',
  'task_alt',
  'lock',
  'bookmark',
  'push_pin',
  'how_to_reg',
  'bookmark_add',
  'mail',
  'call',
  'power_settings_new',
  'phone_enabled',
  'shopping_cart',
  'payments',
  'credit_card',
  'work',
  'redeem'
];

const CustomIconPicker = ({children, onChange = null}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <div>
        {cloneElement(children, {
          onClick: e => setAnchorEl(e.currentTarget)
        })}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={Styles.CustomIconPickerWrp}>
          {icons.map(value => (
            <div key={value} className={Styles.iconItem} onClick={() => onChange(value)}>
              <Icon>{value}</Icon>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default CustomIconPicker;
