import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import {showErrorSnackbar} from '../snackbar/snackbarSlice';
import FormFooterService from '../../../services/FormFooter.service';
import {
  getFormFooterDataFailed,
  getFormFooterDataSuccess,
  updateFormFooterDataFailed,
  updateFormFooterDataSuccess
} from './formFooterSlice';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';

function* formFooterWatcher() {
  yield takeLatest('formFooter/getFormFooterData', getFormFooterDataSaga);
  yield takeLatest('formFooter/enableFormFooter', updateFormFooterDataSaga);
  yield takeLatest('formFooter/updateFormFooterData', updateFormFooterDataSaga);
}

function* getFormFooterDataSaga(action) {
  try {
    const response = yield call(FormFooterService.getFormFooterData, action.payload);

    if (response.success) {
      yield put(getFormFooterDataSuccess(response.data));
    } else {
      yield put(getFormFooterDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormFooterDataSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const response = yield call(FormFooterService.updateFormFooterData, action.payload);

    if (response.success) {
      yield put(updateFormFooterDataSuccess(response.data));
    } else {
      yield put(updateFormFooterDataFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formFooterSaga() {
  yield all([formFooterWatcher()]);
}
