import httpRequest from '../api/httpRequest';

class AdminTemplateService {
  getAdminTemplates(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_REPORT_API + 'template/lists',
      body
    );
  }

  addAdminTemplate(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/create', body);
  }

  updateAdminTemplate(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/update', body);
  }

  togglePublishUnPublishTemplate(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/update', body);
  }

  moveToTrashTemplate(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/remove',
      null,
      body
    );
  }

  cloneTemplate(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/clone', body);
  }

  moveTemplateToSubCategory(body) {
    return httpRequest.post(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/move', body);
  }

  addTemplateToSubCategory(body) {
    return httpRequest.post(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/subcategory/add',
      body
    );
  }

  restoreAdminTemplate(body) {
    return httpRequest.put(process.env.REACT_APP_BACKEND_URL_FORM_API + 'template/restore', body);
  }

  removeForeverAdminTemplate(body) {
    return httpRequest.delete(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'trash/admin/remove',
      null,
      body
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AdminTemplateService();
