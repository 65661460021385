import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Stack, Typography} from '@mui/material';
import CustomTabs, {CustomTab} from '../../common/Tab/CustomTabs';
import DateRangeController from '../../common/DateRangePicker/DateRangePicker';
import {getDatesByDuration} from '../../helpers/utils/getDatesByDuration';
import {selectFormReportFilters} from '../../state/features/formsReport/formReportSelector';
import {
  getFormReportData,
  setReportDateRange
} from '../../state/features/formsReport/formsReportSlice';

const TAB_VALUES = {
  0: 'thisWeek',
  1: 'thisMonth',
  2: 'custom'
};

const ReportTimeTabs = () => {
  const dispatch = useDispatch();
  const {startDate, endDate} = useSelector(selectFormReportFilters);
  const [isSelectCustomDateRange, setSelectCustomDateRange] = useState(false);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        getFormReportData({
          startDate: startDate,
          endDate: endDate
        })
      );
    }
  }, [dispatch, startDate, endDate]);

  const handleTabChange = tabIndex => {
    const durationType = TAB_VALUES[tabIndex];

    setSelectCustomDateRange(durationType === 'custom');
    if (durationType === 'custom') return;

    let [startDate, endDate] = getDatesByDuration(durationType);
    startDate = format(startDate, 'yyyy-MM-dd');
    endDate = format(endDate, 'yyyy-MM-dd');
    dispatch(setReportDateRange({startDate: startDate, endDate: endDate}));
  };

  const handleChangeDatePicker = ({_fromDate, _toDate}) => {
    dispatch(
      setReportDateRange({
        startDate: _fromDate,
        endDate: _toDate
      })
    );
  };

  console.log({startDate, endDate});

  const formattedStartDate = startDate
    ? format(new Date(startDate), 'MMMM dd, yyyy')
    : format(new Date(), 'MMMM dd, yyyy');
  const formattedEndDate = endDate
    ? format(new Date(endDate), 'MMMM dd, yyyy')
    : format(new Date(), 'MMMM dd, yyyy');

  return (
    <>
      <Stack
        justifyContent="space-between"
        sx={{
          width: '100%',
          position: 'relative',
          flexDirection: {xs: 'column', md: 'row'},
          alignItems: {xs: 'flex-start', md: 'center'}
        }}
      >
        <CustomTabs className="AnalyticsTabs" onChange={handleTabChange}>
          <CustomTab title="This Week"></CustomTab>
          <CustomTab title="This Month"></CustomTab>
          <CustomTab title="Custom"></CustomTab>
        </CustomTabs>

        <Stack direction={'column'} justifyContent={'center'} sx={{height: 60}}>
          <Typography variant={'body2'}>Showing Reports for</Typography>

          <Typography variant={'body2'} sx={{minWidth: '300px'}}>
            {isSelectCustomDateRange ? (
              <Box pt={0.25}>
                <DateRangeController
                  isNeedInstantCallback
                  datePickerCallback={handleChangeDatePicker}
                />
              </Box>
            ) : (
              <span style={{fontWeight: '700'}}>
                {formattedStartDate + ' - ' + formattedEndDate}
              </span>
            )}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default ReportTimeTabs;
