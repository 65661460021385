import {format} from "date-fns";
import {createSlice} from '@reduxjs/toolkit';
import {getDatesByDuration} from "../../../helpers/utils/getDatesByDuration";

const [startDate, endDate] = getDatesByDuration('thisWeek');

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  error: "",

  isLoadingList: false,
  totalAgencies: 0,
  totalForms: 0,
  totalViews: 0,
  totalResponses: 0,

  filters: {
    startDate: format(startDate, 'yyyy-MM-dd'), // "yyyy-MM-dd" format,
    endDate : format(endDate, 'yyyy-MM-dd') //  "yyyy-MM-dd" format
  }
};

const formsReportSlice = createSlice({
  name: 'formReport',
  initialState: initialState,
  reducers: {
    getFormReportData: (state, action) => {
      state.isLoading = true;
    },
    getFormReportDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.chartList;
      state.totalAgencies = action.payload.totalAgencies;
      state.totalForms = action.payload.totalForms;
      state.totalViews = action.payload.totalViews;
      state.totalResponses = action.payload.totalResponses;
    },
    getFormReportDataFailed: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
      state.data = [];
      state.totalAgencies = 0;
      state.totalForms = 0;
      state.totalViews = 0;
      state.totalResponses = 0;
    },

    setReportDateRange: (state, action) => {
      state.filters.startDate = action.payload.startDate;
      state.filters.endDate = action.payload.endDate;
    },
    resetReportDateRange: (state, action) => {
      state.filters.startDate = format(startDate, 'yyyy-MM-dd');
      state.filters.endDate = format(endDate, 'yyyy-MM-dd');
    },
  }
});

export const {
  getFormReportData,
  getFormReportDataSuccess,
  getFormReportDataFailed,

  setReportDateRange,
  resetReportDateRange,
} = formsReportSlice.actions;

export default formsReportSlice.reducer;
