import {createSlice} from '@reduxjs/toolkit';
import {FORM_TEMPLATE_STATUS} from '../../../helpers/constant/formConstant';

const initialState = {
  data: [],
  page: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: '',

  isMoreLoading: false,
  isUpdating: false,

  filters: {
    searchText: '',
    status: FORM_TEMPLATE_STATUS.MOST_POPULAR,
    type: ''
  }
};

const templatesSlice = createSlice({
  name: 'templates',
  initialState: initialState,
  reducers: {
    getTemplates: (state, action) => {
      state.isLoading = true;
      if (action.payload.page) state.page = action.payload.page;
      if (action.payload.limit) state.limit = action.payload.limit;
    },
    getTemplatesSuccess: (state, action) => {
      state.data = action.payload.content;
      state.page = action.payload.currentPage;
      state.count = action.payload.count;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getTemplatesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getMoreTemplates: (state, action) => {
      state.isMoreLoading = true;
      if (action.payload.page) state.page = action.payload.page;
      if (action.payload.limit) state.limit = action.payload.limit;
    },
    getMoreTemplatesSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.content];
      state.page = action.payload.currentPage;
      state.count = action.payload.count;
      state.isMoreLoading = false;
      state.isError = false;
      state.error = '';
    },
    getMoreTemplatesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    toggleTemplateFavourite: (state, action) => {
      state.isUpdating = true;
    },
    toggleTemplateFavouriteSuccess: (state, action) => {
      state.isUpdating = false;

      if (state.filters.status === FORM_TEMPLATE_STATUS.FAVORITE) {
        state.data = state.data.filter(template => !action.payload.ids.includes(template.id));
        state.count -= 1;
      } else {
        state.data = state.data.map(template => {
          if (action.payload.ids.includes(template.id)) {
            template.isFavourite = action.payload.favourite;
          }
          return template;
        });
      }
    },
    toggleTemplateFavouriteFailed: (state, action) => {
      state.isUpdating = false;
    },

    // filters actions
    setTemplateSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    setTemplateType: (state, action) => {
      state.filters.type = action.payload;
    },
    setTemplateStatus: (state, action) => {
      state.filters.status = action.payload;
    },
    resetTemplateFilters: state => {
      state.filters.searchText = '';
      state.filters.type = '';
      state.filters.status = '';
    }
  }
});

export const {
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFailed,

  getMoreTemplates,
  getMoreTemplatesSuccess,
  getMoreTemplatesFailed,

  setTemplateSearchText,
  setTemplateType,
  toggleTemplateFavourite,
  toggleTemplateFavouriteSuccess,
  toggleTemplateFavouriteFailed,
  setTemplateStatus,
  resetTemplateFilters
} = templatesSlice.actions;

export default templatesSlice.reducer;
