import React from 'react';
import {Stack, Box, useTheme} from '@mui/material';
import CustomChip from '../../../common/Chips/CustomChip.jsx';
import RightComponentTitle from './RightComponentTitle.jsx';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons.js';
import Styles from './RightSideElements.module.scss';

function RightSideColorOption({text, title, color, onChange, colors = [], onClear = () => {}}) {
  const theme = useTheme();
  return (
    <Box className={`${Styles.ColorScheme} builderElementBasicStyle`}>
      <RightComponentTitle text={text} title={title} sideicon={fbpIcons.fbpInfoRoundIcon} />
      <Stack direction={'row'} sx={{flexWrap: 'wrap', gap: '13.3px'}}>
        {colors.map(eachColor => {
          return (
            <Box
              key={eachColor}
              className={`${Styles.fbpColor} ${color === eachColor ? Styles.active : ''}`}
              sx={{
                borderColor: `${eachColor} !important`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={() => onChange(eachColor)}
            >
              {fbpIcons.fbpTickIcon}
            </Box>
          );
        })}

        <CustomChip text="Clear" color={theme.palette.error.main} onClose={onClear} />
      </Stack>
    </Box>
  );
}

export default RightSideColorOption;
