import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton, Popover, Stack, styled} from '@mui/material';
import {
  CreateNewFolder,
  Delete,
  DeleteForever,
  Pause,
  PlayArrow,
  RestoreFromTrash,
  UnarchiveOutlined
} from '@mui/icons-material';
import FormAddToFolder from './FormAddToFolder/FormAddToFolder';
import DeleteWarningModal from '../Builder/Modals/DeleteWarningModal';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import ArchiveIcon from '../../common/Icons/ArchiveIcon';
import CoreButton from '../../common/Button/CoreButton';
import {castArray} from '../../helpers/utils/castArray';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import {MODULE_NAMES, TRASH_TYPES} from '../../helpers/constant/CoreConstants';
import {
  formMoveToArchive,
  formMoveToTrash,
  formPauseOrPublish,
  formRemoveForever,
  restoreForm
} from '../../state/features/folderForms/folderFormsSlice';
import {
  hasEnabledDisabledSelectedOnForms,
  selectedFormsFolderIds,
  selectIsArchivedSelect,
  selectIsTrashSelect,
  selectSelectedFormIds
} from '../../state/features/folderForms/folderFormSelector';

const AddToFolderBtn = styled(CoreButton)(({theme}) => ({
  minWidth: '120px',
  width: 'max-content',
  padding: '6px 10px',
  '& .MuiButton-startIcon': {marginRight: '4px'}
}));

const FormListBulkActions = () => {
  const dispatch = useDispatch();
  const selectedFormIds = useSelector(selectSelectedFormIds);
  const isTrashSelect = useSelector(selectIsTrashSelect);
  const isArchived = useSelector(selectIsArchivedSelect);
  const selectedFormFolderIds = useSelector(selectedFormsFolderIds);
  const {enabled, disabled} = useSelector(hasEnabledDisabledSelectedOnForms);
  const [anchorElAddToFolder, setAnchorElAddToFolder] = useState(null);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const handleBulkFormsMoveToArchive = () => {
    dispatch(formMoveToArchive({ids: selectedFormIds, archive: !isArchived}));
  };

  const handleFormsPauseOrPublish = status => {
    dispatch(formPauseOrPublish({ids: selectedFormIds, status: status}));
  };

  const handleFormsMovetoTrash = () => {
    dispatch(formMoveToTrash({ids: selectedFormIds}));
  };

  // ## handle add to folder
  const handleOpenAddToFolder = event => setAnchorElAddToFolder(event.currentTarget);
  const handleCloseAddToFolder = () => setAnchorElAddToFolder(null);

  const handleRestoreForm = () => {
    dispatch(restoreForm({ids: selectedFormIds}));
  };

  // handle delete forms
  const toggleDeleteModal = () => setOpenDeleteModal(preState => !preState);
  const onConfirmFormsDeleteForever = () => {
    dispatch(
      formRemoveForever({
        ids: selectedFormIds,
        module: MODULE_NAMES.FORM,
        trashType: TRASH_TYPES.HARD
      })
    );
    toggleDeleteModal();
  };

  if (isTrashSelect) {
    return (
      <>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <CoreButton
            variant={'contained'}
            color={'inherit'}
            startIcon={<RestoreFromTrash />}
            onClick={handleRestoreForm}
            sx={{maxWidth: '100%'}}
          >
            Restore
          </CoreButton>
          <CoreButton
            variant={'contained'}
            color={'error'}
            startIcon={<DeleteForever />}
            onClick={toggleDeleteModal}
            sx={{minWidth: '120px', maxWidth: '100%'}}
          >
            Delete Forever
          </CoreButton>
        </Stack>

        <DeleteWarningModal
          title={'Delete Form'}
          warningSubtitle={'Are you sure, want to delete this Form?'}
          warningContent={`All your submissions & reports data to this form will be gone forever. This operation cannot be undone.`}
          cancelButtonText={'No, Keep it'}
          buttonText={'Yes, Delete Forever'}
          open={isOpenDeleteModal}
          onClose={toggleDeleteModal}
          onConfirm={onConfirmFormsDeleteForever}
        />
      </>
    );
  } else {
    const openAddToFolder = Boolean(anchorElAddToFolder);
    const id = openAddToFolder ? 'add-to-folder-popover' : undefined;
    return (
      <>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <AddToFolderBtn
            aria-describedby={id}
            variant={'outlined'}
            color={'secondary'}
            startIcon={<CreateNewFolder />}
            onClick={handleOpenAddToFolder}
          >
            Add to folder
          </AddToFolderBtn>

          <Stack direction={'row'} spacing={0.25} alignItems={'center'}>
            <CustomTooltip title={isArchived ? 'Unarchive' : 'Archive'} arrow placement="top">
              <IconButton onClick={handleBulkFormsMoveToArchive}>
                {isArchived ? (
                  <UnarchiveOutlined color={'action'} />
                ) : (
                  <ArchiveIcon color={'action'} />
                )}
              </IconButton>
            </CustomTooltip>
            {!isArchived && (
              <>
                {enabled && (
                  <CustomTooltip title="Disable" arrow placement="top">
                    <IconButton onClick={() => handleFormsPauseOrPublish(FORM_STATUS.PAUSED)}>
                      <Pause color={'action'} />
                    </IconButton>
                  </CustomTooltip>
                )}

                {disabled && (
                  <CustomTooltip title="Enable" arrow placement="top">
                    <IconButton onClick={() => handleFormsPauseOrPublish(FORM_STATUS.PUBLISHED)}>
                      <PlayArrow color={'action'} />
                    </IconButton>
                  </CustomTooltip>
                )}
              </>
            )}

            <CustomTooltip title="Delete" arrow placement="top">
              <IconButton onClick={handleFormsMovetoTrash}>
                <Delete color={'action'} />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </Stack>

        {/* ## tag Popover list ## */}
        <Popover
          id={id}
          open={openAddToFolder}
          anchorEl={anchorElAddToFolder}
          onClose={handleCloseAddToFolder}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <FormAddToFolder
            formIds={castArray(selectedFormIds)}
            folderIds={selectedFormFolderIds}
            onClose={handleCloseAddToFolder}
          />
        </Popover>
      </>
    );
  }
};

export default FormListBulkActions;
