import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton, Stack, Typography} from '@mui/material';
import {Delete, DeleteForever, DriveFileMove, RestoreFromTrash} from '@mui/icons-material';
import AddOrMoveTemplateToSubCategory from './AddOrMoveTemplateToSubCategory';
import DeleteWarningModal from '../Builder/Modals/DeleteWarningModal';
import BasicModalWithHeader from '../../common/Modal/BasicModalWithHeader';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import CoreButton from '../../common/Button/CoreButton';
import {castArray} from '../../helpers/utils/castArray';
import {MODULE_NAMES, TRASH_TYPES} from '../../helpers/constant/CoreConstants';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import useBoolean from '../../hooks/useBoolean';
import {
  moveToTrashTemplate,
  removeForeverAdminTemplate,
  restoreAdminTemplate
} from '../../state/features/adminTemplates/adminTemplatesSlice';
import {
  selectSelectedTemplateIds,
  selectTemplateStatus
} from '../../state/features/adminTemplates/adminTemplatesSelector';

const TemplateBulkActions = () => {
  const dispatch = useDispatch();
  const selectedTemplateIds = useSelector(selectSelectedTemplateIds);
  const status = useSelector(selectTemplateStatus);
  const {
    value: isOpenMoveTemplate,
    setTrue: handleOpenMoveTemplate,
    setFalse: handleCloseMoveTemplate
  } = useBoolean();
  const {
    value: isOpenAddTemplate,
    // setTrue: handleOpenAddTemplate,
    setFalse: handleCloseAddTemplate
  } = useBoolean();
  const {
    value: isOpenDeleteModal,
    setTrue: onOpenDeleteModal,
    setFalse: onCloseDeleteModal
  } = useBoolean();

  const handleDelete = () => {
    dispatch(moveToTrashTemplate({ids: castArray(selectedTemplateIds)}));
  };

  const handleRestore = () => {
    dispatch(restoreAdminTemplate({ids: castArray(selectedTemplateIds)}));
  };

  // ## handle delete forever
  const onConfirmDeleteForever = () => {
    dispatch(
      removeForeverAdminTemplate({
        ids: castArray(selectedTemplateIds),
        module: MODULE_NAMES.ADMIN_FORM_TEMPLATE,
        trashType: TRASH_TYPES.HARD
      })
    );
  };

  const totalTemplate = selectedTemplateIds?.length;
  return (
    <>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
        <Typography variant={'subtitle1'} color={'text.secondary'} sx={{ml: 0.5}}>
          {`${totalTemplate} ${totalTemplate === 1 ? 'template' : 'templates'} selected`}
        </Typography>

        {status !== FORM_STATUS.TRASH ? (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <CustomTooltip title="Move" arrow placement="top">
              <IconButton onClick={handleOpenMoveTemplate}>
                <DriveFileMove color={'action'} />
              </IconButton>
            </CustomTooltip>

            {/*<CustomTooltip title="Copy to a sub-category" arrow placement="top">*/}
            {/*  <IconButton onClick={handleOpenAddTemplate}>*/}
            {/*    <CreateNewFolder color={'action'} />*/}
            {/*  </IconButton>*/}
            {/*</CustomTooltip>*/}

            <CoreButton
              variant={'outlined'}
              color={'error'}
              startIcon={<Delete />}
              onClick={handleDelete}
            >
              Delete
            </CoreButton>
          </Stack>
        ) : (
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <CoreButton
              variant={'contained'}
              color={'inherit'}
              startIcon={<RestoreFromTrash />}
              onClick={handleRestore}
            >
              Restore
            </CoreButton>
            <CoreButton
              variant={'outlined'}
              color={'error'}
              startIcon={<DeleteForever />}
              onClick={onOpenDeleteModal}
            >
              Delete Forever
            </CoreButton>
          </Stack>
        )}
      </Stack>

      <BasicModalWithHeader
        modalTitle={`Move ${totalTemplate > 1 ? 'Templates' : 'Template'} (${totalTemplate})`}
        open={isOpenMoveTemplate}
        onClose={handleCloseMoveTemplate}
      >
        <AddOrMoveTemplateToSubCategory
          onClose={handleCloseMoveTemplate}
          isAdd={false}
          isBulk={true}
        />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        modalTitle={`Add ${totalTemplate > 1 ? 'Templates' : 'Template'} (${totalTemplate})`}
        open={isOpenAddTemplate}
        onClose={handleCloseAddTemplate}
      >
        <AddOrMoveTemplateToSubCategory
          onClose={handleCloseAddTemplate}
          isAdd={true}
          isBulk={true}
        />
      </BasicModalWithHeader>

      <DeleteWarningModal
        title={'Delete Templates'}
        warningSubtitle={'Are you sure, want to delete this templates?'}
        warningContent={`All your templates data to this form will be gone forever. This operation cannot be undone.`}
        cancelButtonText={'No, Keep it'}
        buttonText={'Yes, Delete'}
        open={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDeleteForever}
      />
    </>
  );
};

export default TemplateBulkActions;
