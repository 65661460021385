import React from 'react';
import {styled} from '@mui/styles';
import Box from '@mui/material/Box';
import {alpha, Typography} from '@mui/material';

const BoxStyle = styled(Box)(({theme}) => ({
  width: 348,
  padding: '24px',
  cursor: 'pointer',
  borderRadius: 8,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.action.disabled,
  transition: 'all 0.4s',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
  },
  '& > img': {
    width: '100%'
  }
}));

const FormLayoutCard = ({image, title, description, onClick}) => {
  return (
    <BoxStyle onClick={onClick}>
      <Box sx={{display: 'grid', placeItems: 'center', height: '235px'}}>
        {image && <img src={image} alt="" />}
      </Box>
      <Box pt={2}>
        <Typography sx={{textAlign: 'center', p: 1}} variant={'subtitle1'} color={'primary'}>
          {title}
        </Typography>
        <Typography sx={{textAlign: 'center', p: 1}} variant={'body1'} color={'text.secondary'}>
          {description}
        </Typography>
      </Box>
    </BoxStyle>
  );
};

export default FormLayoutCard;
