import React from 'react';
import {Box, Typography} from '@mui/material';
import Styles from './CustomTitleBar.module.scss';

const CustomTitleBar = ({title = '', content = '',icon = '', iconBg='', children = {}}) => {
  return (
      <div className={Styles.fbpGlobalSectionTitleWr}>
          <div className={Styles.fbpGlobalSectionTitleRow}>
              {icon && <Box className={Styles.fbpGlobalSectionIcon} sx={{backgroundColor: iconBg}}>{icon}</Box>}
              <Box>
                  <Typography variant="body1SemiBold" className={Styles.fbpGlobalSectionTitleHeading}>
                      {title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                      {content}
                  </Typography>
              </Box>
          </div>
      {children}
      </div>
  );
};

export default CustomTitleBar;
