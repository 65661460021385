import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, List, Stack, styled, Typography} from '@mui/material';
import FolderListItem from './FolderListItem';
import CreateFolder from './CreateFolder';
import FolderItemAll from './FolderItemAll';
import SpecialFolderList from './SpecialFolderList';
import FormFolderSkeleton from '../LoadingView/FormFolderSkeleton';
import HideSideBarIcon from '../../common/Icons/HideSideBarIcon';
import ShowSideBarIcon from '../../common/Icons/ShowSideBarIcon';
import {selectFormFolderData} from '../../state/features/formFolder/formFolderSelector';
import {getFormFolders} from '../../state/features/formFolder/formFolderSlice';
import {selectSelectedFolderId} from '../../state/features/folderForms/folderFormSelector';
import Styles from './FormList.module.scss';

const CustomHr = styled('hr')(({theme}) => ({
  border: 'none',
  height: '1px',
  background: theme.palette.other.outlinedBorder
}));

const CustomList = styled(List)(({theme}) => ({
  maxHeight: 'calc(100% - 265px)',
  height: 'auto',
  overflowY: 'auto',
  padding: 0,
  paddingRight: '6px',
  '& li': {
    borderRadius: '8px',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.action.selected
    },
    '&.active': {
      background: theme.palette.action.selected
    }
  }
}));

const FormListLeftSide = ({toggleLeftSide, isHideLeftSide, isShowLeftSideTab}) => {
  const dispatch = useDispatch();
  const {folders, isLoading} = useSelector(selectFormFolderData);

  const folderId = useSelector(selectSelectedFolderId);

  useEffect(() => {
    dispatch(getFormFolders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // decide folderList view render
  let folderListView = null;
  if (isLoading) {
    folderListView = <FormFolderSkeleton count={6} />;
  } else if (!isLoading && folders?.length >= 0) {
    folderListView = folders.map(folderData => (
      <FolderListItem
        key={folderData.id}
        folderData={folderData}
        selected={folderData.id === folderId}
      />
    ));
  }

  return (
    <>
      <Box className={`${Styles.fbpLeftSidebar} ${isHideLeftSide ? Styles.hideSidebar : ''}`}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={Styles.fbpLeftSideTopbar}
        >
          <Typography
            variant="buttonMedium"
            color="text.secondary"
            className={Styles.fbpLeftSidebarTitle}
          >
            My Forms
          </Typography>

          <div className={Styles.fbpToggleSidebar} onClick={toggleLeftSide}>
            {isHideLeftSide ? <ShowSideBarIcon /> : <HideSideBarIcon />}
          </div>
        </Stack>
        <Box
          px={1}
          sx={{
            height: `${isShowLeftSideTab ? 'calc(100% - 55px) ' : 'calc(100vh - 236px)'}`,
            opacity: `${isHideLeftSide ? 0 : 1}`,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {display: 'none'}
          }}
        >
          <FolderItemAll />
          <CustomList
            className="fbp_has_scroll"
            sx={{opacity: `${isHideLeftSide ? 0 : 1}`, transitionDelay: '0.3s'}}
          >
            {folderListView}
          </CustomList>

          <CreateFolder />
          <CustomHr />
          <SpecialFolderList />
        </Box>
      </Box>
    </>
  );
};

export default FormListLeftSide;
