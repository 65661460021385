import axios from 'axios';
import {getCookie} from '../helpers/Cookie';

// ## handle http request response
const responseBody = response => {
  const responseData = response.data;
  if (Number(responseData.status) === 401 || responseData.message === 'Token Invalid') {
    // TODO logout function or invalidation action
  }
  return responseData;
};

// ## handle http request error
const errorResponseBody = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const backendError = error.response.data;
    if (Number(backendError.status) === 401 || backendError.message === 'Token Invalid') {
      // TODO logout function or invalidation action
    }
    return backendError;
  } else if (error.request) {
    console.log('Error: axios ', error.request);
  } else {
    console.log('Error: axios ', error.message);
  }
};

const httpRequest = {
  get: (url = '', params = {}) =>
    axios
      .get(url, {
        params: params,
        headers: {
          Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`
        }
      })
      .then(responseBody)
      .catch(errorResponseBody),

  post: (url = '', body = {}) =>
    axios
      .post(url, body, {
        headers: {
          Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`
        }
      })
      .then(responseBody)
      .catch(errorResponseBody),

  put: (url = '', body = {}) =>
    axios
      .put(url, body, {
        headers: {
          Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`
        }
      })
      .then(responseBody)
      .catch(errorResponseBody),

  delete: (url = '', params = {}, body = {}) =>
    axios
      .delete(url, {
        data: body,
        params: params,
        headers: {
          Authorization: `Bearer ${getCookie(process.env.REACT_APP_ACCESS_TOKEN)}`
        }
      })
      .then(responseBody)
      .catch(errorResponseBody)
};

export const customHttpRequest = {
  get: (url = '', params = {}, headers = {}) =>
    axios
      .get(url, {
        params: params,
        headers: headers
      })
      .then(responseBody)
      .catch(errorResponseBody),
  post: (url = '', body = {}, headers = {}) =>
    axios
      .post(url, body, {
        headers: headers
      })
      .then(responseBody)
      .catch(errorResponseBody),
  put: (url = '', body = {}, headers = {}) =>
    axios
      .put(url, body, {
        headers: headers
      })
      .then(responseBody)
      .catch(errorResponseBody)
};

export default httpRequest;
