import React from 'react';
import {Box, Stack} from '@mui/material';
import {parse as parseTime} from 'date-fns';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import CustomTimePicker from '../../../common/TimePicker/CustomTimePicker';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';
import ThreeTabs from '../../Builder/RightComponents/ThreeTabs';
import TwoTabsWithTitle from '../../Builder/RightComponents/TwoTabsWithTitle';
import {useSelector} from 'react-redux';

const TimeOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'classic' && (
        <TwoTabsWithTitle
          text="View Type"
          title="Change the style of the time picker"
          child1="Default"
          child2="Static"
          value1="default"
          value2="static"
          selectedValue={field.viewType}
          onChange={value => onChangeValue({viewType: value})}
        />
      )}

      <TwoTabsWithTitle
        text="Time Format"
        title="Change the time format to 12hr or 24hr"
        child1="AM/PM"
        child2="24Hr"
        value1="12hr"
        value2="24hr"
        selectedValue={field.format}
        onChange={value => onChangeValue({format: value})}
      />

      <Stack
        alignItems={'flex-start'}
        direction={'column'}
        sx={{gap: '10px'}}
        className="builderElementBasicStyle"
      >
        <RightComponentTitle text={'Default Time'} title={'Set the default time on form load'} />
        <ThreeTabs
          child1="None"
          child2="Current"
          child3="Custom"
          value1="none"
          value2="current"
          value3="custom"
          selectedValue={field.defaultTime}
          onChange={value => onChangeValue({defaultTime: value})}
        />

        {field.defaultTime === 'custom' && (
          <CustomTimePicker
            placeholder="Select Time"
            value={field.value === null ? null : parseTime(field.value, 'hh:mm aa', new Date())}
            onChange={value => onChangeValue({value: value})}
          />
        )}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(TimeOptions);
