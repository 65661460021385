import React from 'react';
import {useTheme, Stack} from '@mui/material';
import CustomTextField from '../../../common/TextField/CustomTextField';
import RightComponentTitle from './RightComponentTitle';

const RightSideInput = ({
  text,
  title,
  hasPadding = false,
  unit = '',
  placeholder = 'Label',
  ...other
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <RightComponentTitle text={text} title={title} />
      <Stack
        direction="row"
        alignItems="center"
        sx={{marginLeft: hasPadding ? `8px` : '', width: '100%'}}
      >
        <CustomTextField size="medium" labelPosition="top" placeholder={placeholder} {...other} />
        {unit !== '' && (
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '43px',
              height: '44px',
              background: theme.palette.action.selected,
              borderRadius: '0px 4px 4px 0px'
            }}
          >
            {unit}
          </span>
        )}
      </Stack>
    </Stack>
  );
};

export default RightSideInput;
