import React from 'react';
import {Helmet} from 'react-helmet';

const SeoSettings = ({
  title = '',
  description = '',
  keywords = '',
  author = '',
  robots = '',
  favIcon = ''
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="robots" content={robots} />
      {favIcon && <link rel="icon" type="image/x-icon" href={favIcon} />}
    </Helmet>
  );
};

export default SeoSettings;
