export const COMPONENT_TYPE = {
  THEME: 1,
  SUBMIT_BUTTON: 2,
  HEADING: 3,
  FULL_NAME: 4,
  EMAIL: 5,
  PHONE: 6,
  ADDRESS: 7,
  COMPANY: 8,
  BIRTHDAY: 9,
  NOTE: 10,
  SHORT_ANSWER: 11,
  LONG_ANSWER: 12,
  PHONE_INPUT: 13,
  NUMBER: 14,
  LINK: 15,
  DATE: 16,
  TIME: 17,
  YES_NO: 18,
  RADIO: 19,
  CHECKBOX: 20,
  DROPDOWN: 21,
  FILE_UPLOAD: 22,
  HIDDEN_FIELD: 23,
  PARAGRAPH: 24,
  IMAGE: 25,
  VIDEO: 26,
  AUDIO: 27,
  EMBED: 28,
  PROGRESSBAR: 29,
  CUSTOM_FIELD: 30,
  DISCLAIMER: 31,
  COVER_IMAGE: 32,
  PROFILE_IMAGE: 33,
  FORM_IMAGE: 34,
  PAGE_IMAGE: 35
};
