import React from 'react';
import {Stack, Typography} from '@mui/material';
import CoreTextField from '../../../../common/TextField/CoreTextField';

const ConditionTextField = ({name, width, placeholder = 'Type here', value, onChange, ...rest}) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{width}}>
      <Typography variant="body1SemiBold" style={{width: '150px'}}>
        {name}
      </Typography>
      <CoreTextField
        size={'small'}
        color={'secondary'}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        sx={{width: '70%', height: '36px !important'}}
        {...rest}
      />
    </Stack>
  );
};

export default ConditionTextField;
