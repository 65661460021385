import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  error: '',

  selectedCategory: {},
  selectedSubCategory: {}
};

const templateCategoriesSlice = createSlice({
  name: 'templateCategories',
  initialState: initialState,
  reducers: {
    getTemplateCategories: (state, action) => {
      state.isLoading = true;
    },
    getTemplateCategoriesSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getTemplateCategoriesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    setSelectedCategory: (state, action) => {
      state.selectedCategory.id = action.payload.id;
      state.selectedCategory.categoryName = action.payload.categoryName;
    },
    setSelectedSubCategory: (state, action) => {
      if (action.payload) {
        state.selectedSubCategory.id = action.payload.id;
        state.selectedSubCategory.name = action.payload.name;
        state.selectedSubCategory.description = action.payload.description;
        state.selectedSubCategory.count = action.payload.count;
      } else {
        state.selectedSubCategory = {};
      }
    },
    resetSelectedCategory: state => {
      state.selectedCategory = {};
    },
    resetSelectedSubCategory: state => {
      state.selectedSubCategory = {};
    }
  }
});

export const {
  getTemplateCategories,
  getTemplateCategoriesSuccess,
  getTemplateCategoriesFailed,

  setSelectedCategory,
  setSelectedSubCategory,
  resetSelectedCategory,
  resetSelectedSubCategory
} = templateCategoriesSlice.actions;

export default templateCategoriesSlice.reducer;
