import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: '',

  isDataFetching: false,

  addToCampaignLoading: false,
  addToCampaignSuccess: false,

  selectedCampaignIds: [],
  filters: {
    searchText: ''
  }
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
    getCampaignData: state => {
      state.isLoading = true;
    },
    getCampaignDataSuccess: (state, action) => {
      state.data = action.payload.data;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = '';
    },
    getCampaignDataFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = action.payload;
    },

    getMoreCampaignData: state => {
      state.isDataFetching = true;
    },
    getMoreCampaignDataSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.data];
      state.total = action.payload.total;
      state.page = action.payload.page || 1;
      state.perPage = action.payload.perPage || 10;
      state.isDataFetching = false;
    },
    getMoreCampaignDataFailed: (state, action) => {
      state.isError = true;
      state.error = action.payload;
      state.isDataFetching = false;
    },

    inboxSubmissionAddToCampaign: state => {
      state.addToCampaignLoading = true;
    },
    inboxSubmissionAddToCampaignSuccess: (state, action) => {
      state.addToCampaignLoading = false;
      state.addToCampaignSuccess = true;
    },
    inboxSubmissionAddToCampaignFailed: (state, action) => {
      state.addToCampaignLoading = false;
    },
    resetSubmissionAddToCampaignSuccess: (state, action) => {
      state.addToCampaignSuccess = false;
    },

    // inbox form selection actions
    selectAllCampaign: state => {
      state.selectedCampaignIds = state.data.map(inboxForm => inboxForm.id);
    },
    deselectAllCampaign: state => {
      state.selectedCampaignIds = [];
    },
    toggleSelectSingleCampaign: (state, action) => {
      const currentFormIndex = state.selectedCampaignIds.findIndex(
        formId => formId === action.payload
      );
      if (currentFormIndex !== -1) {
        state.selectedCampaignIds.splice(currentFormIndex, 1);
      } else {
        state.selectedCampaignIds.push(action.payload);
      }
    },

    handleResetCampaign: (state, action) => {
      state.data = [];
      state.page = 1;
      state.perPage = 10;
      state.total = 0;
      state.isError = false;
      state.error = '';
      state.selectedCampaignIds = [];
      state.filters.searchText = '';
    },

    //filters
    addSearchTextForCampaign: (state, action) => {
      state.filters.searchText = action.payload;
    }
  }
});

export const {
  getCampaignData,
  getCampaignDataSuccess,
  getCampaignDataFailed,

  getMoreCampaignData,
  getMoreCampaignDataSuccess,
  getMoreCampaignDataFailed,
  handleResetCampaign,

  inboxSubmissionAddToCampaign,
  inboxSubmissionAddToCampaignSuccess,
  inboxSubmissionAddToCampaignFailed,
  resetSubmissionAddToCampaignSuccess,

  selectAllCampaign,
  deselectAllCampaign,
  toggleSelectSingleCampaign,

  addSearchTextForCampaign
} = campaignSlice.actions;

export default campaignSlice.reducer;
