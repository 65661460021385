const generateRandomString = (length = 5) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charsLength = chars.length;

  return Array(length)
    .fill('')
    .map(v => chars[Math.floor(Math.random() * charsLength)])
    .join('');
};

export default generateRandomString;
