import React from 'react';
import {alpha, Box, Checkbox, FormControlLabel, Stack} from '@mui/material';
import ConditionItemActions from './ConditionItemActions';
import ConditionItemContent from './ConditionItemContent';
import {CONDITION_LIST} from '../../../../helpers/constant/formConditionConstant';
import Styles from '../ConditionsPage.module.scss';

const ConditionItem = ({
  index,
  singleRule = {},
  dragHandler,
  checked = false,
  handleSelectCondition,
  fieldLabelsById = {}
}) => {
  const conditionTypeProperties = CONDITION_LIST.find(
    typeProps => singleRule.type === typeProps.type
  );

  return (
    <Stack direction={'row'} alignItems="center" spacing={1}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleSelectCondition} />}
        label={''}
      />

      <Box
        className={Styles.fbpConditionItem}
        sx={{
          borderColor: checked && conditionTypeProperties.color,
          backgroundColor: checked && alpha(conditionTypeProperties.color, 0.1)
        }}
      >
        <ConditionItemContent
          index={index}
          singleRule={singleRule}
          onClick={handleSelectCondition}
          conditionTypeProperties={conditionTypeProperties}
          fieldLabelsById={fieldLabelsById}
          dragHandler={dragHandler}
        />
        <ConditionItemActions singleRule={singleRule} />
      </Box>
    </Stack>
  );
};

export default ConditionItem;
