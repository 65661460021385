import React from 'react';
import Box from '@mui/material/Box';
import Styles from './fbpCardViewPreview.module.scss';

const CardViewPublic = ({child, className}) => {
  return (
    <Box
      className={`fbp_has_scroll ${Styles.fbpCardViewPreviewWrapper} `}
      sx={
        className !== 'fbpCardThankYou'
          ? {height: {xs: 'calc(100% - 92px)', sm: 'calc(100% - 100px)'}}
          : {}
      }
    >
      <Box className={Styles.fbpCardViewWrap}>
        <Box className={Styles.fbpCardViewContent}>{child}</Box>
      </Box>
    </Box>
  );
};

export default CardViewPublic;
