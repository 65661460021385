import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  error: '',

  isTagAdding: false,
  isUpdating: false
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState: initialState,
  reducers: {
    getTags: state => {
      state.isLoading = true;
    },
    getTagsSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getTagsFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addTag: (state, action) => {
      state.isTagAdding = true;
    },
    addTagSuccess: (state, action) => {
      state.isTagAdding = false;
      state.data.push(action.payload);
    },
    addTagFailed: (state, action) => {
      state.isTagAdding = false;
    },

    updateTag: (state, action) => {
      state.isUpdating = true;
    },
    updateTagSuccess: (state, action) => {
      state.isUpdating = false;
      state.data = state.data.map(tag => {
        if (tag.id === action.payload.tagId) {
          tag.name = action.payload.name;
          tag.color = action.payload.color;
        }
        return tag;
      });
    },
    updateTagFailed: (state, action) => {
      state.isUpdating = false;
    },

    deleteTag: (state, action) => {
      state.isUpdating = true;
    },
    deleteTagSuccess: (state, action) => {
      state.isUpdating = false;
      state.data = state.data.filter(tag => !action.payload.ids.includes(tag.id));
    },
    deleteTagFailed: (state, action) => {
      state.isUpdating = false;
    }
  }
});

export const {
  getTags,
  getTagsSuccess,
  getTagsFailed,

  addTag,
  addTagSuccess,
  addTagFailed,

  updateTag,
  updateTagSuccess,
  updateTagFailed,

  deleteTag,
  deleteTagSuccess,
  deleteTagFailed
} = tagsSlice.actions;

export default tagsSlice.reducer;
