import React from 'react';
import {Stack} from '@mui/material';
import {Folder} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import SubmissionDateRange from './SubmissionDateRange';
import SubmissionViewSwitch from './SubmissionViewSwitch';
import SubmissionResetFilter from './SubmissionFilterReset';
import PresentationBreadcrumb from '../../common/Breadcrumb/PresentationBreadcrumb';
import {selectSubmissionFormTitle} from '../../state/features/formSubmission/formSubmissionSelector';
import CoreButton from '../../common/Button/CoreButton';
import DownloadBlueIcon from '../../common/Icons/DownloadBlueIcon';
import RouteLink from '../../common/Link/RouteLink';

const SubmissionHeader = () => {
  const formTitle = useSelector(selectSubmissionFormTitle);

  const breadCrumbArray = [
    {
      name: 'My Forms',
      icon: <Folder color={'action'} sx={{mr: 0.5}} fontSize="small" />,
      path: '/fbuilder/forms'
    },
    {
      name: formTitle
    }
  ];

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={1}
      mb={1}
    >
      <PresentationBreadcrumb breadCrumbArray={breadCrumbArray} />
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <RouteLink to={`/fbuilder/files`}>
          <CoreButton
            color={'secondary'}
            variant={'outlined'}
            startIcon={<DownloadBlueIcon />}
            sx={{width: 'max-content'}}
          >
            Exported Files
          </CoreButton>
        </RouteLink>
        <SubmissionDateRange />
        <SubmissionResetFilter />
        <SubmissionViewSwitch />
      </Stack>
    </Stack>
  );
};

export default SubmissionHeader;
