import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import Box from '@mui/material/Box';
import CoreTextField from '../../../common/TextField/CoreTextField';
import LoadingButton from '../../../common/Button/LoadingButton';
import CoreButton from '../../../common/Button/CoreButton';
import {registerPatternTemplateCategory} from '../../../helpers/constant/registerPattern';
import {
  addAdminTemplateSubCategory,
  resetAddAdminTemplateSubCategorySuccess,
  resetUpdateAdminTemplateSubCategorySuccess,
  updateAdminTemplateSubCategory
} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {selectAdminTemplateCategories} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import AsteriskIcon from "../../../common/Icons/AsterickIcon";

const AddOrEditTemplateSubCategory = ({
  onClose,
  isEditMode = false,
  subCategoryInfo = {},
  categoryId
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    data: categoryList,
    isAdding,
    isAddSuccess,
    isUpdating,
    isUpdateSuccess
  } = useSelector(selectAdminTemplateCategories);
  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (subCategoryInfo?.id) {
      setValue('name', subCategoryInfo.name);
      setValue('description', subCategoryInfo.description);
      setValue('categoryId', categoryId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetAddAdminTemplateSubCategorySuccess());
      dispatch(resetUpdateAdminTemplateSubCategorySuccess());
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess, isUpdateSuccess]);

  // ## Form Submit Action
  const onSubmit = data => {
    const reqObj = {
      name: data.name,
      description: data.description,
      categoryId: data.categoryId
    };
    if (isEditMode && subCategoryInfo?.id) {
      reqObj.id = subCategoryInfo.id;
      dispatch(updateAdminTemplateSubCategory(reqObj));
    } else {
      dispatch(addAdminTemplateSubCategory(reqObj));
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, backgroundColor: theme.palette.background.paper, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="categoryId"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl disabled={isEditMode} error={invalid} fullWidth>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Select Category <AsteriskIcon/>
                </Typography>
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  size={'small'}
                  inputProps={{'aria-label': 'Without label'}}
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {categoryList?.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.categoryName}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && <FormHelperText error={invalid}>{error.message}</FormHelperText>}
              </FormControl>
            )}
            rules={registerPatternTemplateCategory.categoryId}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Name <AsteriskIcon/>
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.categoryName || null}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Description <AsteriskIcon/>
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  multiline={true}
                  rows={3}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.description || null}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isAdding || isUpdating}
          loadingText={isEditMode ? 'Updating...' : 'Creating...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          {isEditMode ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditTemplateSubCategory;
