import React from 'react';
import {SvgIcon} from '@mui/material';

export default function TabIcon({fill = 'ffffff', ...rest}) {
  return (
    <SvgIcon {...rest} viewBox="0 0 20 20">
      <path
        d="M10.3126 16.6667C10.8314 16.6667 11.2501 16.248 11.2501 15.7292C11.2501 15.2105 10.8314 14.7917 10.3126 14.7917C9.79387 14.7917 9.37512 15.2105 9.37512 15.7292C9.37512 16.248 9.79387 16.6667 10.3126 16.6667ZM6.25012 2.60425C6.16887 2.60425 6.08762 2.6355 6.03137 2.698C5.96887 2.75425 5.93762 2.8355 5.93762 2.91675V17.2917C5.93762 17.373 5.96887 17.4542 6.03137 17.5105C6.08762 17.573 6.16887 17.6042 6.25012 17.6042H14.3751C14.4564 17.6042 14.5376 17.573 14.5939 17.5105C14.6501 17.4542 14.6876 17.373 14.6876 17.2917V2.91675C14.6876 2.8355 14.6564 2.75425 14.5939 2.698C14.5376 2.6355 14.4564 2.60425 14.3751 2.60425H6.25012ZM6.25012 1.66675H14.3751C14.7064 1.66675 15.0251 1.798 15.2564 2.0355C15.4939 2.26675 15.6251 2.5855 15.6251 2.91675V17.2917C15.6251 17.623 15.4939 17.9417 15.2564 18.173C15.0251 18.4105 14.7064 18.5417 14.3751 18.5417H6.25012C5.91887 18.5417 5.60012 18.4105 5.36887 18.173C5.13137 17.9417 5.00012 17.623 5.00012 17.2917V2.91675C5.00012 2.5855 5.13137 2.26675 5.36887 2.0355C5.60012 1.798 5.91887 1.66675 6.25012 1.66675ZM5.62512 2.29175H15.0001V3.54175H5.62512V2.29175Z"
        fill={fill}
      />
    </SvgIcon>
  );
}
