import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  formId: '',
  formTitle: '',
  isLoading: false,
  isLoadingList: false,
  totalViews: 0,
  totalResponses: 0,
  conversionRate: 0,
  selectedListFilter: 'VIEWS',
  listData: {}
};

const formStatisticsSlice = createSlice({
  name: 'formStatistics',
  initialState: initialState,
  reducers: {
    getFormStatistics: (state, action) => {
      state.isLoading = true;
    },
    getFormStatisticsSuccess: (state, action) => {
      state.isLoading = false;
      state.formTitle = action.payload.formTitle;
      state.totalViews = action.payload.totalViews;
      state.totalResponses = action.payload.totalResponses;
      state.conversionRate = action.payload.conversionRate;
    },
    getFormStatisticsFailed: (state, action) => {
      state.isLoading = false;
    },
    updateListFilter: (state, action) => {
      state.selectedListFilter = action.payload;
    },
    getFormStatisticsList: (state, action) => {
      if (action.payload.formId) {
        state.formId = action.payload.formId;
      }
      state.isLoadingList = true;
    },
    getFormStatisticsListSuccess: (state, action) => {
      state.listData = action.payload;
      state.isLoadingList = false;
    },
    getFormStatisticsListFailed: (state, action) => {
      state.isLoadingList = false;
    }
  }
});

export const {
  getFormStatistics,
  getFormStatisticsSuccess,
  getFormStatisticsFailed,
  updateListFilter,
  getFormStatisticsList,
  getFormStatisticsListSuccess,
  getFormStatisticsListFailed
} = formStatisticsSlice.actions;

export default formStatisticsSlice.reducer;
