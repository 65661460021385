import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import AdminAccessService from '../../../services/AdminAccess.service';
import {getAdminAccessDataFailed, getAdminAccessDataSuccess} from './adminAccessSlice';

function* adminAccessWatcher() {
  yield takeLatest('adminAccess/getAdminAccessData', getAdminAccessDataSaga);
}

function* getAdminAccessDataSaga(action) {
  try {
    const response = yield call(AdminAccessService.getAdminAccessData, action.payload);

    if (response.success) {
      yield put(getAdminAccessDataSuccess(response.data.hasAccess));
    } else {
      yield put(getAdminAccessDataFailed(response.message));
    }
  } catch (err) {
    yield put(getAdminAccessDataFailed('Network error'));
  }
}

export default function* adminAccessSaga() {
  yield all([adminAccessWatcher()]);
}
