import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Refresh} from '@mui/icons-material';
import ButtonForIcon from '../../common/Button/ButtonForIcon';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {selectInboxFilters} from '../../state/features/formSubmission/formSubmissionSelector';
import {resetSubmissionFilter} from '../../state/features/formSubmission/formSubmissionSlice';

const SubmissionResetFilter = () => {
  const dispatch = useDispatch();
  const {searchText, startDate, endDate} = useSelector(selectInboxFilters);

  const handleReset = () => {
    dispatch(resetSubmissionFilter());
  };

  const isShowResetButton = searchText || startDate || endDate;

  if (!isShowResetButton) {
    return null;
  }

  return (
    <CustomTooltip arrow placement="bottom" title="Reset Filter">
      <ButtonForIcon
        variant="outlined"
        size={'small'}
        color={'secondary'}
        startIcon={<Refresh />}
        onClick={handleReset}
        className="reset"
      />
    </CustomTooltip>
  );
};

export default SubmissionResetFilter;
