import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, styled} from '@mui/material';
import FormTemplateCard from './FormTemplateCard';
import TemplateCardSkeleton from '../../LoadingView/TemplateCardSkeleton';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import {FORM_TEMPLATE_STATUS} from '../../../helpers/constant/formConstant';
import {EmptyFormListIcon} from '../../../common/Icons/EmptyDataIcons';
import {
  getMoreTemplates,
  getTemplates,
  resetTemplateFilters
} from '../../../state/features/templates/templatesSlice';
import removeFalsyObjectProperty from '../../../helpers/utils/removeFalsyObjectProperty';
import {
  selectTemplates,
  selectTemplatesFilters
} from '../../../state/features/templates/templatesSelector';
import {selectSelectedSubCategory} from '../../../state/features/templateCategories/templateCategoriesSelector';
import CoreButton from '../../../common/Button/CoreButton';

export const StyledBox = styled(Box)(({theme}) => ({
  width: '100%',
  height: 'max-content',
  flexGrow: 1,
  display: 'flex',
  '@media(max-width:930px)': {paddingTop: '20px'},
  '& .templateCardContainer': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    width: '100%',
    '@media(min-width:800px) and (max-width:930px)': {
      margin: '0 50px'
    }
  }
}));

const FormTemplateBody = () => {
  const dispatch = useDispatch();
  const {
    data: templateList,
    isLoading,
    isMoreLoading,
    limit,
    page,
    count
  } = useSelector(selectTemplates);
  const subCategory = useSelector(selectSelectedSubCategory);
  const {searchText, type, status} = useSelector(selectTemplatesFilters);

  useEffect(() => {
    const reqObj = removeFalsyObjectProperty({
      subcategoryId: subCategory?.id,
      searchText: searchText,
      formType: type,
      popular: status === FORM_TEMPLATE_STATUS.MOST_POPULAR,
      isFavourite: status === FORM_TEMPLATE_STATUS.FAVORITE,
      limit: limit,
      page: 1
    });

    dispatch(getTemplates(reqObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory?.id, searchText, type, status]);

  useEffect(() => {
    dispatch(resetTemplateFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeeMore = () => {
    const reqObj = removeFalsyObjectProperty({
      subcategoryId: subCategory?.id,
      searchText: searchText,
      formType: type,
      popular: status === FORM_TEMPLATE_STATUS.MOST_POPULAR,
      isFavourite: status === FORM_TEMPLATE_STATUS.FAVORITE,
      limit: limit,
      page: page + 1
    });

    dispatch(getMoreTemplates(reqObj));
  };

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <TemplateCardSkeleton count={6} />;
  } else if (!isLoading && templateList?.length === 0) {
    content = (
      <Box sx={{minHeight: '55vh', width: '100%'}}>
        <GlobalEmptyPage
          icon={<EmptyFormListIcon />}
          title={'No Template Found'}
          description={''}
        />
      </Box>
    );
  } else if (!isLoading && templateList?.length > 0) {
    content = templateList.map(template => (
      <FormTemplateCard key={template.id} template={template} />
    ));
  }

  return (
    <>
      <StyledBox>
        <Box className="templateCardContainer">
          {content}
          {isMoreLoading && <TemplateCardSkeleton count={6} />}
          {templateList?.length < count && (
            <Box sx={{width: '100%', textAlign: 'center'}}>
              <CoreButton
                variant="contained"
                color="secondary"
                sx={{mt: 2}}
                onClick={handleSeeMore}
              >
                See More
              </CoreButton>
            </Box>
          )}
        </Box>
      </StyledBox>
    </>
  );
};

export default FormTemplateBody;
