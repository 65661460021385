import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {Box, Checkbox, FormControlLabel, Grid, Stack, Typography, useTheme} from '@mui/material';
import CoreButton from '../../../common/Button/CoreButton';
import LoadingButton from '../../../common/Button/LoadingButton';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {registerPatternTemplateCategory} from '../../../helpers/constant/registerPattern';
import {
  TEMPLATE__CATEGORY_TYPE_BUSINESS,
  TEMPLATE__CATEGORY_TYPE_INDUSTRY
} from '../../../helpers/constant/templateConstant';
import {
  addAdminTemplateCategory,
  resetAddAdminTemplateCategorySuccess,
  resetUpdateAdminTemplateCategorySuccess,
  updateAdminTemplateCategory
} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {selectAdminTemplateCategories} from '../../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';

const AddOrEditTemplateCategory = ({onClose, isEditMode = false, categoryInfo = {}}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {isAdding, isAddSuccess, isUpdating, isUpdateSuccess} = useSelector(
    selectAdminTemplateCategories
  );
  const {handleSubmit, setValue, control} = useForm();

  useEffect(() => {
    if (categoryInfo?.id) {
      setValue('name', categoryInfo.categoryName);
      setValue('description', categoryInfo.description);
      setValue('isIndustrious', categoryInfo.categoryType === TEMPLATE__CATEGORY_TYPE_INDUSTRY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetAddAdminTemplateCategorySuccess());
      dispatch(resetUpdateAdminTemplateCategorySuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess, isUpdateSuccess]);

  // ## Form Submit Action
  const onSubmit = data => {
    const reqObj = {
      name: data.name,
      description: data.description,
      type: data.isIndustrious ? TEMPLATE__CATEGORY_TYPE_INDUSTRY : TEMPLATE__CATEGORY_TYPE_BUSINESS
    };

    if (isEditMode && categoryInfo?.id) {
      reqObj.id = categoryInfo.id;
      dispatch(updateAdminTemplateCategory(reqObj));
    } else {
      dispatch(addAdminTemplateCategory(reqObj));
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, backgroundColor: theme.palette.background.paper, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Name *
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  className={'fbp-addCategory'}
                  autoFocus
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.categoryName || null}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  Description *
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  type={'text'}
                  size={'small'}
                  multiline={true}
                  rows={3}
                  className={'fbp-addCategory'}
                  placeholder={'Write here'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternTemplateCategory.description || null}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="isIndustrious"
            defaultValue={false}
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControlLabel
                control={<Checkbox size={'small'} checked={value} onChange={onChange} />}
                label="Add this category as an industry as well"
              />
            )}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isAdding || isUpdating}
          loadingText={isEditMode ? 'Updating...' : 'Creating...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          {isEditMode ? 'Update' : 'Create'}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditTemplateCategory;
