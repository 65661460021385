import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  id: null,
  title: '',
  formApiKey: '',
  agencyId: '',
  updateTime: '',
  isFormLoading: false,
  isShowBuilderRightPanel: true,
  isShowBuilderLeftPanel: true,
  builderLeftPanelTabIndex: 0,
  theme: {
    profileImage: null,
    coverImage: null,
    layout: 'classic',
    animation: 'slideLeft',
    formColor: '#ffffff',
    formImage: null,
    formImageOpacity: 0,
    formImageColor: '#000',
    formImageAttachment: 'scroll',
    pageColor: '#f4f5f7',
    pageImage: null,
    pageImageOpacity: 0,
    pageImageColor: '#000',
    fontColor: '#1c1b1f',
    inputBackground: '#ffffff',
    subTextColor: '#546376',
    placeholderColor: '#b4b8c6',
    textColor: '#546376',
    containerWidth: 1000,
    fieldSpacing: 20,
    innerGap: 80,
    labelAlignment: 'top',
    font: "'Poppins', sans-serif",
    labelFontSize: 16,
    cardViewLabelFontSize: 36,
    descriptionFontSize: 16,
    subTextFontSize: 12,
    nextButton: {
      color: '#ffffff',
      border: '#008945',
      background: '#008945',
      icon: 'arrow_forward',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    backButton: {
      color: '#008945',
      border: '#008945',
      background: '#ffffff',
      icon: 'arrow_back',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    },
    submitButton: {
      color: '#ffffff',
      border: '#006df5',
      background: '#006df5',
      icon: 'check',
      iconVisible: true,
      borderRadius: 6,
      width: 132,
      height: 44
    }
  }
};

const builderSettingSlice = createSlice({
  name: 'builderSetting',
  initialState: initialState,
  reducers: {
    fetchFormDetails: (state, action) => {
      state.isFormLoading = true;
    },
    fetchFormDetailsSuccess: (state, action) => {
      state.isFormLoading = false;
      state.id = action.payload.id;
      state.title = action.payload.title;
      state.updateTime = '';
      state.formApiKey = action.payload.formApiKey;
      state.theme = {...state.theme, ...action.payload.theme};
    },
    toggleBuilderSettingLeftPanelTab: (state, action) => {
      state.builderLeftPanelTabIndex =
        action.payload === undefined
          ? state.builderLeftPanelTabIndex === 0
            ? 1
            : 0
          : action.payload;
    },
    toggleBuilderSettingRightPanel: (state, action) => {
      let newValue = action.payload === undefined ? !state.isShowBuilderRightPanel : action.payload;
      if (window.innerWidth <= 1500 && state.isShowBuilderLeftPanel && newValue) {
        state.isShowBuilderLeftPanel = false;
      }
      state.isShowBuilderRightPanel = newValue;
    },
    toggleBuilderSettingLeftPanel: (state, action) => {
      let newValue = action.payload === undefined ? !state.isShowBuilderLeftPanel : action.payload;
      if (window.innerWidth <= 1500 && state.isShowBuilderRightPanel && newValue) {
        state.isShowBuilderRightPanel = false;
      }
      state.isShowBuilderLeftPanel = newValue;
    },
    updateBuilderTheme: (state, action) => {},
    storeUpdatedBuilderTheme: (state, action) => {
      state.theme = {...action.payload};
    },
    renameBuilderFormTitle: (state, action) => {
      state.updateTime = 'Saving...';
    },
    renameBuilderFormTitleSuccess: (state, action) => {
      state.title = action.payload;
      state.updateTime =
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'});
    },
    updateBuilderTime: (state, action) => {
      state.updateTime = action.payload;
    }
  }
});

export const {
  fetchFormDetails,
  fetchFormDetailsSuccess,
  toggleBuilderSettingLeftPanelTab,
  toggleBuilderSettingRightPanel,
  toggleBuilderSettingLeftPanel,
  updateBuilderTheme,
  storeUpdatedBuilderTheme,
  renameBuilderFormTitle,
  renameBuilderFormTitleSuccess,
  updateBuilderTime
} = builderSettingSlice.actions;

export default builderSettingSlice.reducer;
