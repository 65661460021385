import React, {useMemo} from 'react';
import Box from '@mui/material/Box';
import {useDispatch, useSelector} from 'react-redux';
import {changeSelectedBuilderField} from '../../../state/features/builderFields/builderFieldsSlice';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import CardComponentWrapper from '../FormComponents/CardComponentWrapper';
import EachEditContentCardView from '../EachEditContentCardView';
import DropElementHere from '../../../common/Form-UI/DropElementHere';
import {ComponentConfig} from '../../../helpers/builderConstant/ComponentConfig';
import {CountManager} from '../../../helpers/utils/CountManager';
import Styles from './card-view.module.scss';

const CardViewContent = ({selectedField}) => {
  const dispatch = useDispatch();
  const {fields} = useSelector(state => state.builderFields);

  const changeSelectedField = (index, pageIndex) => {
    dispatch(changeSelectedBuilderField({index, pageIndex}));
  };

  const totalFields = useMemo(() => {
    let totalFieldsCount = 0;
    fields.forEach(eachPage => {
      eachPage.pageItems.forEach(eachField => {
        if (ComponentConfig[eachField.component].cardComponent) {
          totalFieldsCount++;
        }
      });
    });

    return totalFieldsCount;
  }, [fields]);

  return (
    <Box className={`${Styles.fbpCardBodyContent}`}>
      <Box pt={4} className="fbp_has_scroll">
        <Droppable droppableId={'cardViewDroppable'}>
          {(provided, snapshot) => {
            let i = 0,
              counter = new CountManager();
            return (
              <Box ref={provided.innerRef} {...provided.droppableProps}>
                {!totalFields && <DropElementHere />}

                {fields.map((eachPage, eachPageIndex) =>
                  eachPage.pageItems.map((field, index) => (
                    <Draggable key={field.id} draggableId={field.id} index={i++}>
                      {(provided, snapshot) => (
                        <Box
                          key={field.id}
                          snapshot={snapshot}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {ComponentConfig[field.component].cardComponent && (
                            <CardComponentWrapper
                              field={field}
                              preview={false}
                              selected={field.id === selectedField?.id}
                              onClick={() => changeSelectedField(index, eachPageIndex)}
                            >
                              <EachEditContentCardView
                                field={field}
                                pageNo={counter.next(eachPageIndex + '_' + index)}
                                totalPage={totalFields}
                              />
                            </CardComponentWrapper>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>
      </Box>
    </Box>
  );
};

export default CardViewContent;
