import React, {useMemo} from 'react';
import CustomTooltip from '../../common/Tooltip/CustomTooltip';
import {ComponentConfig} from '../../helpers/builderConstant/ComponentConfig';
import ComponentWrapper from '../Builder/FormComponents/ComponentWrapper';

const PublicPageViewManager = ({fields, onChange, onBack, currentPage, totalPages, refreshKey}) =>
  useMemo(
    () =>
      fields.map((field, index) => {
        if (field.hidden === true) {
          return null;
        }

        const Component = ComponentConfig[field.component]?.component;

        if (!Component) {
          return null;
        }

        let width =
          field.shrink === false || field.shrink === undefined
            ? 100
            : field.width === 'auto'
            ? 50
            : field.width;

        return (
          <CustomTooltip title={field.hoverText ?? null} arrow placement="top" key={field.id}>
            <ComponentWrapper field={field} width={width}>
              <Component
                field={field}
                onBack={onBack}
                currentPage={currentPage}
                totalPages={totalPages}
                onChange={data => onChange(index, data)}
              />
            </ComponentWrapper>
          </CustomTooltip>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, refreshKey]
  );

export default React.memo(PublicPageViewManager);
