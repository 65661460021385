import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isError: false,
  error: '',

  isUpdating: false,
  isUpdateSuccess: false
};

const templatePublishSlice = createSlice({
  name: 'templatePublish',
  initialState: initialState,
  reducers: {
    getTemplatePublishData: (state, action) => {
      state.isLoading = true;
    },
    getTemplatePublishDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getTemplatePublishDataFailed: (state, action) => {
      state.data = {};
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    resetTemplatePublish: state => {
      state.data = {};
      state.isError = false;
      state.error = '';
    },

    updateTemplatePublishData: state => {
      state.isUpdating = true;
    },
    updateTemplatePublishDataSuccess: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      };
      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    updateTemplatePublishDataFailed: (state, action) => {
      state.isUpdating = false;
      state.error = action.payload;
    }
  }
});

export const {
  getTemplatePublishData,
  getTemplatePublishDataSuccess,
  getTemplatePublishDataFailed,

  updateTemplatePublishData,
  updateTemplatePublishDataSuccess,
  updateTemplatePublishDataFailed,

  resetTemplatePublish
} = templatePublishSlice.actions;

export default templatePublishSlice.reducer;
