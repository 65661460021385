import React from 'react';
import Paper from '@mui/material/Paper';
import {Divider} from '@mui/material';
import FilesHeader from './FilesHeader';
import ExportedFilesTable from './ExportedFilesTable';

const FilesPage = () => {
  return (
    <div>
      <Paper elevation={0} sx={{height: 'calc(100vh - 122px)'}}>
        <FilesHeader />
        <Divider />
        <ExportedFilesTable />
      </Paper>
    </div>
  );
};

export default FilesPage;
