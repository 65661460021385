import httpRequest from '../api/httpRequest';

class FormThankYouSettingService {
  getFormThankYouSetting(params) {
    return httpRequest.get(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/thankyoupage/setting/fetch',
      params
    );
  }

  updateFormThankYouSetting(params) {
    return httpRequest.put(
      process.env.REACT_APP_BACKEND_URL_FORM_API + 'form/thankyoupage/setting/edit',
      params
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FormThankYouSettingService();
