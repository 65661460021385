import {SvgIcon} from '@mui/material';
import React from 'react';

export default function MoreIcon({props, fill}) {
  return (
    <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
      <path
        d="M9 6C9.825 6 10.5 5.325 10.5 4.5C10.5 3.675 9.825 3 9 3C8.175 3 7.5 3.675 7.5 4.5C7.5 5.325 8.175 6 9 6ZM9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5ZM9 12C8.175 12 7.5 12.675 7.5 13.5C7.5 14.325 8.175 15 9 15C9.825 15 10.5 14.325 10.5 13.5C10.5 12.675 9.825 12 9 12Z"
        fill="#949DB2"
      />
    </SvgIcon>
  );
}
