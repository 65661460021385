import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, MenuItem, Select} from '@mui/material';
import {FORM_TEMPLATE_TYPE} from '../../../helpers/constant/formConstant';
import {setTemplateType} from '../../../state/features/templates/templatesSlice';
import {selectTemplatesFiltersType} from '../../../state/features/templates/templatesSelector';

const listData = [
  {title: 'All', value: ''},
  {title: 'Classic', value: FORM_TEMPLATE_TYPE.CLASSIC},
  {title: 'Card', value: FORM_TEMPLATE_TYPE.CARD}
];

const FormTemplateTypeSelect = () => {
  const dispatch = useDispatch();
  const type = useSelector(selectTemplatesFiltersType);
  const [value, setValue] = useState(type);

  const onSelectFilter = event => {
    setValue(event.target.value);
    dispatch(setTemplateType(event.target.value));
  };

  return (
    <>
      <FormControl sx={{m: 1, minWidth: 120}} size="small">
        <Select
          value={value}
          onChange={onSelectFilter}
          displayEmpty
          inputProps={{'aria-label': 'Without label'}}
        >
          {listData.map(data => (
            <MenuItem key={data.title} value={data.value}>
              {data.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default FormTemplateTypeSelect;
