import React from 'react';
import {Stack} from '@mui/material';
import {FormHeading, FormSubHeader} from '../../../common/Form-UI/FormHeading';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import ImageBox from '../../../common/ImageBox';

const CardViewHeadingComponent = ({field}) => {
  const headingAlignmentClass =
    field.alignment === 'center'
      ? 'fbpHeadingCenterAlign'
      : field.alignment === 'right'
      ? 'fbpHeadingRightAlign'
      : '';

  const headingSizeClass =
    field.size === 'medium' ? 'fbpHeadingMedium' : field.size === 'small' ? 'fbpHeadingSmall' : '';

  return (
    <>
      {/* <CardStep pageNo={pageNo} totalPage={totalPage} />  */}

      {field.image && (
        <ImageBox>
          <img src={field.image} alt="" />
        </ImageBox>
      )}

      <Stack direction={'column'} className={headingAlignmentClass}>
        <FormHeading mt={2} className={headingSizeClass}>
          {field.heading}
        </FormHeading>
        <FormSubHeader variant="body1medium">{field.subHeading}</FormSubHeader>
      </Stack>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default CardViewHeadingComponent;
