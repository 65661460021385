import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {nanoid} from 'nanoid';
import {
  Divider,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import {InsertLink} from '@mui/icons-material';
import {
  REDIRECT_URL_OPEN_WINDOW_ARRAY,
  REDIRECT_URL_SAME_WINDOW
} from '../../../helpers/constant/formSettingsConstant';
import InputValidation from '../../../helpers/utils/InputValidation';
import CustomRadio from '../../../common/Radio/CustomRadio';
import CustomRadioGroup from '../../../common/Radio/CustomRadioGroup';
import BasicTitleBar from '../../../common/TitleBar/BasicTitleBar';
import CoreTextField from '../../../common/TextField/CoreTextField';
import {selectFormThankYouData} from '../../../state/features/FormThankYou/formThankYouSelector';
import {updateFormThankYouSetting} from '../../../state/features/FormThankYou/formThankYouSlice';

const ThankYouRedirect = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const thankYouFormData = useSelector(selectFormThankYouData);

  const [renderId, setRenderId] = useState('');
  const [formState, setFormState] = useState({
    shortURL: '',
    appendDataToURL: false,
    urlWindow: REDIRECT_URL_SAME_WINDOW
  });
  const [errorInfo, setErrorInfo] = useState({});

  useEffect(() => {
    if (thankYouFormData) {
      setFormState({
        ...formState,
        shortURL: thankYouFormData.shortURL || '',
        appendDataToURL: thankYouFormData.appendDataToURL || false,
        urlWindow: thankYouFormData.urlWindow || REDIRECT_URL_SAME_WINDOW
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankYouFormData]);

  // when update any state data and set new render id and dispatch update action
  useEffect(() => {
    if (renderId) {
      dispatch(
        updateFormThankYouSetting({
          formId: formId,
          thankyouPageSetting: JSON.stringify({...thankYouFormData, ...formState})
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId]);

  const onChangeInput = event => {
    const {name, value} = event.target;
    if (name === 'shortURL' && value && value.trim()) {
      const {invalid, message} = InputValidation.linkUrl(value);

      setErrorInfo(prevState => ({
        ...prevState,
        [name]: {
          invalid,
          message
        }
      }));
    }
    setFormState(prevState => ({...prevState, [name]: value}));
  };

  const onBlurAction = event => {
    const {name, value} = event.target;

    // when value actually change call the action
    if (thankYouFormData[name] !== value) {
      setRenderId(nanoid(5));
    }
  };

  const onChangeWindowUrlType = event => {
    const {name, value} = event.target;
    setFormState(prevState => ({...prevState, [name]: value}));
    setRenderId(nanoid(5));
  };

  return (
    <Paper elevation={0}>
      <BasicTitleBar
        title={'Choose where you want to redirect respondents on form submission.'}
        titleVariant={'body2medium'}
        padding={1.5}
      />
      <Divider />
      <Stack spacing={2} p={2}>
        <CoreTextField
          fullWidth
          size={'small'}
          type={'search'}
          color={'secondary'}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{mr: 0}} position="start">
                <InsertLink />
              </InputAdornment>
            )
          }}
          name={'shortURL'}
          value={formState.shortURL}
          onChange={onChangeInput}
          onBlur={onBlurAction}
          error={errorInfo?.shortURL?.invalid}
          helperText={errorInfo?.shortURL?.message}
        />
        <Box pl={1}>
          <FormControl>
            <FormLabel
              component={'legend'}
              id="demo-row-radio-buttons-group-label"
              sx={{color: 'text.primary', '&.Mui-focused': {color: 'text.primary'}}}
            >
              Open URL in:
            </FormLabel>
            <CustomRadioGroup
              aria-label="urlWindow"
              name="urlWindow"
              value={formState.urlWindow}
              onChange={onChangeWindowUrlType}
              sx={{flexDirection: 'row', gap: '8px'}}
            >
              {REDIRECT_URL_OPEN_WINDOW_ARRAY.map(openWindow => (
                <FormControlLabel
                  key={openWindow.value}
                  value={openWindow.value}
                  control={<CustomRadio color="secondary" />}
                  label={
                    <Typography variant={'body2'} color={'text.primary'}>
                      {openWindow.label}
                    </Typography>
                  }
                />
              ))}
            </CustomRadioGroup>
          </FormControl>
        </Box>
      </Stack>
    </Paper>
  );
};

export default ThankYouRedirect;
