import React from 'react';
import {Box, Stack} from '@mui/material';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {CustomDropdown} from '../../../common/CustomDropdown';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import withFieldChange from '../../../hoc/withFieldChange';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const CardViewDropDownComponent = ({field, onChange, editMode}) => {
  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Stack direction="column">
        <Stack direction="row" alignItems="center">
          <FormCardLabel>{field.label}</FormCardLabel>
          {field.required && <RequireIcon variant="card" />}
        </Stack>
        <FormDescription>{field.description}</FormDescription>
      </Stack>
      <Box mt={2}>
        <ConnectPublicForm
          name={field.id}
          value={field.value}
          rules={field.required ? {required: 'This value is required'} : {}}
          render={formOption => (
            <>
              <Stack spacing={1} sx={{flex: '1', width: '100%'}}>
                <CustomDropdown
                  data={
                    field.displayEmpty
                      ? [{title: field.placeholder, value: ''}, ...field.options]
                      : field.options
                  }
                  size="medium"
                  value={field.value}
                  displayEmpty={field.displayEmpty}
                  disabled={editMode || field.readOnly}
                  onChange={e => {
                    formOption.field.onChange(e);
                    onChange({value: e.target.value});
                  }}
                />
                {formOption.fieldState.error?.message ? (
                  <FormSubtextError>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext>{field.helperText}</FormSubtext>
                )}
              </Stack>
            </>
          )}
        />
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewDropDownComponent);
