import {combineReducers} from '@reduxjs/toolkit';
import builderFieldsSlice from './features/builderFields/builderFieldsSlice';
import builderSettingSlice from './features/builderSetting/builderSettingSlice';
import formFolderSlice from './features/formFolder/formFolderSlice';
import folderFormsSlice from './features/folderForms/folderFormsSlice';
import formSettingSlice from './features/formSetting/formSettingSlice';
import emailShareSlice from './features/emailShare/emailShareSlice';
import formSubmissionSlice from './features/formSubmission/formSubmissionSlice';
import templateCategoriesSlice from './features/templateCategories/templateCategoriesSlice';
import templatesSlice from './features/templates/templatesSlice';
import tagsSlice from './features/tags/tagsSlice';
import campaignSlice from './features/campaign/campaignSlice';
import adminTemplateCategoriesSlice from './features/adminTemplateCategories/adminTemplateCategoriesSlice';
import snackbarSlice from './features/snackbar/snackbarSlice';
import adminTemplatesSlice from './features/adminTemplates/adminTemplatesSlice';
import pipelineSlice from './features/pipeline/pipelineSlice';
import pipelineStageSlice from './features/pipelineStage/pipelineStageSlice';
import exportedFileSlice from './features/exportedFiles/exportedFileSlice';
import publicPageSlice from './features/publicPage/publicPageSlice';
import formSEOSlice from './features/formSEO/formSEOSlice';
import formThankYouSlice from './features/FormThankYou/formThankYouSlice';
import adminAccessSlice from './features/adminAccess/adminAccessSlice';
import templatePublishSlice from './features/templatePublish/templatePublishSlice';
import formStatisticSlice from './features/formStatistics/formStatisticSlice';
import formsReportSlice from "./features/formsReport/formsReportSlice";
import embedSettingsSlice from "./features/embedSettings/embedSettingsSlice";
import formHeaderSlice from "./features/formHeader/formHeaderSlice";
import mediaIconSlice from "./features/mediaIcon/mediaIconSlice";
import fieldMappingSlice from './features/fieldMapping/fieldMappingSlice';
import customFieldSlice from './features/customField/customFieldSlice';
import saveIconSlice from "./features/saveIcon/saveIconSlice";
import formFooterSlice from "./features/formFooter/formFooterSlice";
import mediaImageSlice from './features/mediaImage/mediaImageSlice';
import conditionSettingSlice from "./features/conditionSetting/conditionSettingSlice";

const combinedReducer = combineReducers({
  snackbar: snackbarSlice,
  formFolder: formFolderSlice,
  folderForms: folderFormsSlice,
  templateCategories: templateCategoriesSlice,
  templates: templatesSlice,
  formSetting: formSettingSlice,
  formThankYou: formThankYouSlice,
  formSEO: formSEOSlice,
  formSubmission: formSubmissionSlice,
  tags: tagsSlice,
  campaign: campaignSlice,
  pipeline: pipelineSlice,
  pipelineStage: pipelineStageSlice,
  emailShare: emailShareSlice,
  builderSetting: builderSettingSlice,
  builderFields: builderFieldsSlice,
  adminTemplateCategories: adminTemplateCategoriesSlice,
  adminTemplates: adminTemplatesSlice,
  templatePublish: templatePublishSlice,
  adminAccess: adminAccessSlice,
  exportedFile: exportedFileSlice,
  publicPage: publicPageSlice,
  formStatistics : formStatisticSlice,
  formReport : formsReportSlice,
  embedSettings: embedSettingsSlice,
  mediaIcon: mediaIconSlice,
  saveIcon: saveIconSlice,
  fieldMapping: fieldMappingSlice,
  customFields: customFieldSlice,
  formHeader: formHeaderSlice,
  formFooter: formFooterSlice,
  conditionSetting: conditionSettingSlice,
  mediaImage: mediaImageSlice
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
