import React from 'react';
import {Popover} from '@mui/material';

const AddTagPopover = ({id, children, anchorEl, handleClose, handleOpen}) => {
  const open = Boolean(anchorEl);
  return (
    <>
      <Popover
        className="pfbAddTagListPopover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleOpen={handleOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default AddTagPopover;
