import React, {useRef, useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {CircularProgress, styled, Typography, Box, Stack, useTheme} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import {showErrorSnackbar} from '../../state/features/snackbar/snackbarSlice';
import BuilderService from '../../services/Builder.service';
import Styles from './CustomFileUpload.module.scss';

const BoxStyled = styled(Box)(({theme}) => ({
  '&.builderFileUpload': {
    height: '244px'
  }
}));
const StyledStack = styled(Stack)(({theme}) => ({
  '&.builderFileUpload': {
    '& .MuiTypography-root': {
      fontFamily: 'var(--fbp-theming-font-family) !important'
    }
  }
}));

const MAX_FILE_SIZE = 10485760; //1024*1024*10 --- 10MB

// preType = 'image' | 'video' | 'document'

export const CustomFileUploader = ({
  className,
  preType = 'all',
  type = '',
  title = 'Click here to upload',
  subText = 'or drag & drop file here',
  onFileUpload = () => {},
  disabled = false,
  recommendedSize = '',
  lazyUpload = false,
  fileLimit = 1
}) => {
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const theme = useTheme();
  const [uploading, setUploading] = useState(false);
  const [hasDrag, setHasDrag] = useState(false);

  const acceptType = useMemo(() => {
    switch (preType) {
      case 'image':
        return 'image/*';
      case 'video':
        return 'video/*';
      case 'audio':
        return 'audio/*';
      case 'document':
        return '.pdf';
      case 'custom':
        return type;
      default:
        return '';
    }
  }, [preType, type]);

  const customJoinedExtensions = useMemo(() => {
    if (preType !== 'custom') {
      return '';
    }

    return type
      .split(',')
      .map(item => item.trim())
      .join('|');
  }, [preType, type]);

  const validateFile = file => {
    switch (preType) {
      case 'image':
        return {
          success: file.type.match(/image/g),
          message: 'Invalid image type'
        };
      case 'video':
        return {
          success: file.type.match(/video/g),
          message: 'Invalid video type'
        };
      case 'audio':
        return {
          success: file.type.match(/audio/g),
          message: 'Invalid audio type'
        };
      case 'document':
        return {
          success: file.type.match(/pdf/g),
          message: 'Invalid document type'
        };
      case 'custom':
        return {
          success: new RegExp(customJoinedExtensions).test(file.name),
          message: 'Invalid file type'
        };
      default:
        return {success: true};
    }
  };

  const handleFileUpload = files => {
    try {
      //Checking maximum limit
      if (!isNaN(fileLimit) && files.length > fileLimit) {
        dispatch(showErrorSnackbar(`Only ${fileLimit} files uploads allowed.`));
        return;
      }

      // Validating files
      for (let i = 0; i < files.length; i++) {
        let validate = validateFile(files[i]);
        if (!validate.success) {
          dispatch(showErrorSnackbar(validate.message));
          return;
        }
        if (files[i].size > MAX_FILE_SIZE) {
          dispatch(showErrorSnackbar('Maximum upload size : 10MB'));
          return;
        }
      }

      if (lazyUpload) {
        onFileUpload({files: [...files]});
        if (inputFileRef.current?.value) {
          inputFileRef.current.value = null;
        }
        return;
      }

      //We only allow one upload in the absence of lazy upload.
      setUploading(true);

      const dt = new FormData();
      dt.append('files', files[0], files[0].name);
      BuilderService.uploadFile(dt)
          .then(res => {
            if (res.success) {
              onFileUpload({
                file: res.urls[0]
              });
            } else {
              dispatch(showErrorSnackbar(res.message));
            }

          }).finally(() => setUploading(false));
    } catch (e) {
      dispatch(showErrorSnackbar('Something went wrong, try upload file from local storage'));
      setUploading(false);
    }
  };

  const renderUploadOption = () => {
    return (
      <StyledStack direction="column" alignItems="center" className={className}>
        <UploadIcon sx={{color: theme.palette.secondary.main}} />
        <Typography sx={{margin: 0, padding: 0}} variant={'body2'} color={'secondary.main'}>
          {title}
        </Typography>
        <Typography variant={'inputLevel'} color={'common.black'}>
          {subText}
        </Typography>
        <Typography variant={'helperText'} color={'error.main'} sx={{fontSize: '9px', pt: '3px'}}>
          Max file size : 10MB
        </Typography>
        {recommendedSize && (
          <Typography
            variant={'helperText'}
            color={'warning.main'}
            sx={{fontSize: '9px', pt: '3px'}}
          >
            Recommended size : {recommendedSize}
          </Typography>
        )}

        <input
          type="file"
          accept={acceptType}
          ref={inputFileRef}
          style={{display: 'none'}}
          onChange={e => handleFileUpload(e.target.files)}
          multiple={fileLimit !== 1}
        />
      </StyledStack>
    );
  };

  const renderUploading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <CircularProgress sx={{position: 'static', marginRight: '10px'}} size={23} />{' '}
        <Typography
          variant={'inputLevel'}
          color={'common.black'}
          className={Styles.fbpLoadingContent}
        >
          Uploading...
        </Typography>
      </Box>
    );
  };

  const handleDragover = (e, drag) => {
    e.stopPropagation();
    e.preventDefault();
    if (!uploading && !disabled) {
      setHasDrag(drag);
    }
  };

  const handleDragDrop = e => {
    e.stopPropagation();
    e.preventDefault();

    if (hasDrag) {
      setHasDrag(false);
      handleFileUpload(e.dataTransfer.files);
    }
  };

  const onClickUploader = () => {
    if (!uploading && !hasDrag && !disabled) {
      inputFileRef.current.click();
    }
  };

  return (
    <BoxStyled
      className={`${Styles.fbpFileUploadWrapper} ${
        hasDrag ? Styles.fileDragZone : Styles.FileUpload
      } ${className}`}
      onDragEnter={e => handleDragover(e, true)}
      onDragOver={e => handleDragover(e, true)}
      onDragLeave={e => handleDragover(e, false)}
      onDrop={handleDragDrop}
      onClick={onClickUploader}
    >
      {uploading ? renderUploading() : hasDrag ? 'Drop file Here' : renderUploadOption()}
    </BoxStyled>
  );
};
