import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';
import {FORM_SUBMISSION_PAGE_VIEW_GRID} from '../../../helpers/constant/formSubmissionConstant';

const initialState = {
  data: [],
  tableHeader: [],
  formTitle: '',
  formApiKey: '',
  page: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: '',

  renderId: '',

  isDataFetching: false,
  isFormUpdating: false,
  isFormUpdateSuccess: false,

  inboxFormReplyLoading: false,
  inboxFormReplySuccess: false,

  //filter
  pageView: FORM_SUBMISSION_PAGE_VIEW_GRID,
  selectedSubmission: undefined,
  selectedInboxFormIds: [],
  filters: {
    searchText: '',
    startDate: null,
    endDate: null,
    resetId: ''
  }
};

const formSubmissionSlice = createSlice({
  name: 'formSubmission',
  initialState: initialState,
  reducers: {
    getFormSubmissionData: (state, action) => {
      if (state.renderId) {
        state.isLoading = false;
      } else {
        state.isLoading = true;
      }
    },
    getFormSubmissionDataSuccess: (state, action) => {
      state.data = action.payload.data;
      state.tableHeader = action.payload.tableHeader;
      state.formTitle = action.payload.formTitle;
      state.formApiKey = action.payload.formApiKey;
      state.page = action.payload.page || 1;
      state.limit = action.payload.limit || 10;
      state.count = action.payload.count;
      state.isLoading = false;
      state.isError = false;
      state.error = '';

      if (!state.selectedSubmission) {
        state.selectedSubmission =
          action.payload.data?.length > 0 ? action.payload.data[0] : undefined;
      }
    },
    getFormSubmissionDataFailed: (state, action) => {
      state.data = [];
      state.tableHeader = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    getMoreFormSubmissionData: state => {
      state.isDataFetching = true;
    },
    getMoreFormSubmissionDataSuccess: (state, action) => {
      state.data = [...state.data, ...action.payload.data];
      state.page = action.payload.page || 1;
      state.limit = action.payload.limit || 10;
      state.count = action.payload.count;
      state.isDataFetching = false;
    },
    getMoreFormSubmissionDataFailed: (state, action) => {
      state.isError = true;
      state.error = action.payload;
      state.isDataFetching = false;
    },

    resetFormSubmissionState: (state, action) => {
      state.data = [];
      state.tableHeader = [];
      state.formTitle = '';
      state.page = 1;
      state.limit = 10;
      state.count = 0;
      state.isError = false;
      state.error = '';
      state.renderId = '';
      state.selectedSubmission = undefined;
      state.selectedInboxFormIds = [];
    },

    updateFormSubmissionData: state => {
      state.isFormUpdating = true;
    },
    updateFormSubmissionDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isFormUpdating = false;
      state.isFormUpdateSuccess = true;
    },
    updateFormSubmissionDataFailed: (state, action) => {
      state.isFormUpdating = false;
      state.error = action.payload;
    },

    inboxFormReply: state => {
      state.inboxFormReplyLoading = true;
    },

    inboxFormReplySuccess: (state, action) => {
      state.inboxFormReplyLoading = false;
      state.inboxFormReplySuccess = true;
    },
    inboxFormReplyFailed: (state, action) => {
      state.inboxFormReplyLoading = false;
      state.error = action.payload;
    },
    resetInboxFormReplySuccess: (state, action) => {
      state.inboxFormReplySuccess = false;
    },
    inboxFormDelete: state => {},
    inboxFormDeleteSuccess: (state, action) => {
      if (state.pageView === FORM_SUBMISSION_PAGE_VIEW_GRID) {
        state.data = state.data.filter(
          submissionData => !action.payload.ids.includes(submissionData.id)
        );
        state.count -= action.payload.ids.length;
        state.selectedSubmission = state.data.length > 0 ? state.data[0] : undefined;
      } else {
        state.renderId = nanoid(5);
      }
      if (action.payload.ids.length > 0 && state.selectedInboxFormIds.length > 0) {
        state.selectedInboxFormIds = [];
      }
    },

    addTagOnSubmission: state => {},
    addTagOnSubmissionSuccess: (state, action) => {
      state.data = state.data.map(submissionData => {
        if (action.payload.submissionIds.includes(submissionData.id)) {
          submissionData.tagIds = [...submissionData.tagIds, ...action.payload.tagIds];
          state.selectedSubmission.tagIds = [...submissionData.tagIds, ...action.payload.tagIds];
        }
        return submissionData;
      });
    },

    removeTagFromSubmission: (state, action) => {},
    removeTagFromSubmissionSuccess: (state, action) => {
      state.data = state.data.map(submissionData => {
        if (action.payload.submissionIds.includes(submissionData.id)) {
          const filterTagIds = submissionData.tagIds.filter(
            tagId => !action.payload.tagIds.includes(tagId)
          );
          submissionData.tagIds = filterTagIds;
          state.selectedSubmission.tagIds = filterTagIds;
        }
        return submissionData;
      });
    },

    // inbox form selection actions
    selectedAllInboxForm: state => {
      state.selectedInboxFormIds = state.data.map(inboxForm => inboxForm.id);
    },
    deselectAllInboxForm: state => {
      state.selectedInboxFormIds = [];
    },
    toggleSelectedSingleInboxForm: (state, action) => {
      const currentFormIndex = state.selectedInboxFormIds.findIndex(
        formId => formId === action.payload
      );
      if (currentFormIndex !== -1) {
        state.selectedInboxFormIds.splice(currentFormIndex, 1);
      } else {
        state.selectedInboxFormIds.push(action.payload);
      }
    },

    //filters
    switchFormSubmissionPageView: (state, action) => {
      state.pageView = action.payload || FORM_SUBMISSION_PAGE_VIEW_GRID;
    },
    selectFormSubmission: (state, action) => {
      state.selectedSubmission = action.payload;
    },
    addSearchTextForInboxForm: (state, action) => {
      state.filters.searchText = action.payload;
    },
    addSubmissionDateRange: (state, action) => {
      state.filters.startDate = action.payload.startDate;
      state.filters.endDate = action.payload.endDate;
    },
    resetSubmissionFilter: (state, action) => {
      state.filters.searchText = '';
      state.filters.startDate = null;
      state.filters.endDate = null;
      state.filters.resetId = nanoid(5);
    }
  }
});

export const {
  getFormSubmissionData,
  getFormSubmissionDataSuccess,
  getFormSubmissionDataFailed,

  getMoreFormSubmissionData,
  getMoreFormSubmissionDataSuccess,
  getMoreFormSubmissionDataFailed,

  resetFormSubmissionState,

  updateFormSubmissionData,
  updateFormSubmissionDataSuccess,
  updateFormSubmissionDataFailed,

  inboxFormReply,
  inboxFormReplySuccess,
  inboxFormReplyFailed,
  resetInboxFormReplySuccess,

  inboxFormDelete,
  inboxFormDeleteSuccess,

  addTagOnSubmission,
  addTagOnSubmissionSuccess,

  removeTagFromSubmission,
  removeTagFromSubmissionSuccess,

  selectedAllInboxForm,
  deselectAllInboxForm,
  toggleSelectedSingleInboxForm,

  switchFormSubmissionPageView,
  selectFormSubmission,

  addSearchTextForInboxForm,
  addSubmissionDateRange,
  resetSubmissionFilter
} = formSubmissionSlice.actions;

export default formSubmissionSlice.reducer;
