import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  styled
} from '@mui/material';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormText} from '../../../common/Form-UI/FormText';
import RequireIcon from '../../../common/Icons/RequireIcon';
import withFieldChange from '../../../hoc/withFieldChange';

const FormControlLabelStyled = styled(FormControlLabel)(({theme}) => ({
  '&.MuiFormControlLabel-root': {
    margin: '0',
    '& .MuiCheckbox-root': {
      color: 'var(--fbp-theming-font-color)'
    }
  }
}));

const CardViewDisclaimerComponent = ({field, onChange, editMode}) => {
  const onChangeValue = (value, method, formOption) => {
    if (!field.readOnly && !editMode) {
      method?.setValue(field.id, value);
      onChange({value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
            <FormCardLabel>
              {field.label}
              {field.required && <RequireIcon variant="card" />}
            </FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>

        <Stack direction="column" spacing={1} sx={{flex: '1', width: '100%'}}>
          <ConnectPublicForm
            name={field.id}
            value={field.value}
            rules={field.required ? {required: 'This field is required'} : {}}
            render={(formOption, formMethods) => (
              <>
                <Box sx={{width: '100%'}}>
                  <FormControl component="fieldset" sx={{width: '100%'}}>
                    <RadioGroup
                      sx={{rowGap: '12px'}}
                      aria-label="checkbox"
                      name="checkboxComponent"
                    >
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        sx={{width: '100%', gap: '8px'}}
                        onClick={() => onChangeValue(!field.value, formMethods, formOption)}
                      >
                        <FormControlLabelStyled
                          control={
                            <Checkbox
                              checked={field.value}
                              name="checkedB"
                              onClick={e => e.preventDefault()}
                            />
                          }
                        />
                        <FormText>{field.checkboxLabel}</FormText>
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CardViewDisclaimerComponent);
