import React from 'react';
import GlobalFullScreenModal from '../../../common/Modal/GlobalFullScreenModal';
import TemplateLayoutOption from './TemplateLayoutOption';

const CreateTemplateModal = ({onClose, open}) => {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <>
      <GlobalFullScreenModal open={open} onClose={handleOnClose} stopBackDrop={true}>
        <TemplateLayoutOption />
      </GlobalFullScreenModal>
    </>
  );
};

export default CreateTemplateModal;
