import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';

const VideoAdvanced = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      {theme.layout === 'classic' && (
        <RightSideInput
          text="Hover Text"
          title="Set a tooltip on hover"
          placeholder="Hover Text"
          value={field.hoverText}
          onChange={e => onChangeValue({hoverText: e.target.value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(VideoAdvanced);
