import React from 'react';
import {useSelector} from 'react-redux';
import Typography from '@mui/material/Typography';
import {List} from '@mui/material';
import CategoryListSkeleton from '../../LoadingView/CategoryListSkeleton';
import FormTemplateCategoryListItem from './FormTemplateCategoryListItem';
import {TEMPLATE__CATEGORY_TYPE_INDUSTRY} from '../../../helpers/constant/templateConstant';
import {selectTemplateCategories} from '../../../state/features/templateCategories/templateCategoriesSelector';

const FormTemplateCategoryList = ({categoryType}) => {
  const {data: categoryList, isLoading} = useSelector(selectTemplateCategories);

  // render view
  let content = null;
  if (isLoading) {
    content = <CategoryListSkeleton count={10} />;
  } else if (!isLoading && categoryList?.length === 0) {
    content = (
      <Typography variant={'h5'} align={'center'} style={{margin: '36px 0'}}>
        No Category Found
      </Typography>
    );
  } else if (!isLoading && categoryList?.length > 0) {
    content = categoryList
      .filter(category => {
        if (categoryType === TEMPLATE__CATEGORY_TYPE_INDUSTRY) {
          return category.categoryType === categoryType;
        }

        return true;
      })
      .map(categoryItem => (
        <FormTemplateCategoryListItem key={categoryItem.id} categoryItem={categoryItem} />
      ));
  }

  return <List sx={{width: '100%', maxWidth: 360}}>{content}</List>;
};

export default FormTemplateCategoryList;
