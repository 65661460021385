import React from 'react';

const EmbedImgIcon = ({fill = '#1C1B1F', width = ''}) => {
  return (
    <svg
      width={width}
      height="192"
      viewBox="0 0 481 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{maxWidth: '100%'}}
    >
      <rect width="480" height="192" />
      <path
        d="M258.5 110V82C258.5 79.8 256.7 78 254.5 78H226.5C224.3 78 222.5 79.8 222.5 82V110C222.5 112.2 224.3 114 226.5 114H254.5C256.7 114 258.5 112.2 258.5 110ZM233.5 99L238.5 105.02L245.5 96L254.5 108H226.5L233.5 99Z"
        fill={fill}
      />
    </svg>
  );
};

export default EmbedImgIcon;
