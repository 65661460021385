import {createSlice} from '@reduxjs/toolkit';
import {nanoid} from 'nanoid';

const initialState = {
  data: [],
  page: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: '',

  renderId: '',

  isMoving: false,
  isMoveSuccess: false,

  isAdding: false,
  isAddSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,
  selectedTemplateIds: [],

  filters: {
    subcategoryId: '',
    searchText: '',
    startTime: null,
    endTime: null,
    orderBy: '',
    status: null
  }
};

const adminTemplatesSlice = createSlice({
  name: 'adminTemplates',
  initialState: initialState,
  reducers: {
    getAdminTemplates: (state, action) => {
      state.isLoading = true;
      if (action.payload) {
        state.page = action.payload.page || 1;
        state.limit = action.payload.limit || 10;
      }
    },
    getAdminTemplatesSilently: (state, action) => {
      if (action.payload?.page) {
        state.page = action.payload.page;
      }
    },
    getAdminTemplatesSuccess: (state, action) => {
      state.data = action.payload.content;
      state.page = action.payload.currentPage;
      state.count = action.payload.count;
      state.isLoading = false;
      state.isError = false;
      state.error = '';
    },
    getAdminTemplatesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateTemplate: (state, action) => {
      state.isUpdating = true;
    },
    updateTemplateSuccess: (state, action) => {
      state.isUpdating = false;
      state.renderId = nanoid(5);
    },
    updateTemplateFailed: (state, action) => {
      state.isUpdating = false;
    },
    resetTemplateUpdateSuccess: state => {
      state.isUpdateSuccess = false;
    },

    renameTemplate: (state, action) => {
      state.isUpdating = true;
    },
    renameTemplateSuccess: (state, action) => {
      if (action.payload?.templateId) {
        state.data = state.data.map(template => {
          if (template.id === action.payload.templateId) {
            template.formTitle = action.payload.name;
          }
          return template;
        });
      }
      state.isUpdating = false;
      state.isUpdateSuccess = true;
    },
    renameTemplateFailed: (state, action) => {
      state.isUpdating = false;
    },

    togglePublishUnPublishTemplate: (state, action) => {
      state.isUpdating = true;
    },
    togglePublishUnPublishTemplateSuccess: (state, action) => {
      state.isUpdating = false;
      state.renderId = nanoid(5);
    },

    cloneTemplate: state => {},
    cloneTemplateSuccess: (state, action) => {
      state.renderId = nanoid(5);
    },

    moveToTrashTemplate: state => {},
    moveToTrashTemplateSuccess: (state, action) => {
      state.renderId = nanoid(5);
      state.selectedTemplateIds = [];
    },

    moveTemplateToSubCategory: state => {
      state.isMoving = true;
    },
    moveTemplateToSubCategorySuccess: (state, action) => {
      state.renderId = nanoid(5);
      state.isMoving = false;
      state.isMoveSuccess = true;
      state.selectedTemplateIds = [];
    },
    moveTemplateToSubCategoryFailed: (state, action) => {
      state.isMoving = false;
    },
    resetMovingSuccess: (state, action) => {
      state.isMoveSuccess = false;
    },

    addTemplateToSubCategory: state => {
      state.isAdding = true;
    },
    addTemplateToSubCategorySuccess: (state, action) => {
      state.renderId = nanoid(5);
      state.isAdding = false;
      state.isAddSuccess = true;
      state.selectedTemplateIds = [];
    },
    addTemplateToSubCategoryFailed: (state, action) => {
      state.isAdding = false;
    },
    resetAddingSuccess: (state, action) => {
      state.isAddSuccess = false;
    },

    restoreAdminTemplate: state => {},
    restoreAdminTemplateSuccess: (state, action) => {
      state.renderId = nanoid(5);
      state.selectedTemplateIds = [];
    },

    removeForeverAdminTemplate: state => {},
    removeForeverAdminTemplateSuccess: (state, action) => {
      state.renderId = nanoid(5);
      state.selectedTemplateIds = [];
    },

    // template selection actions
    selectedAllTemplate: state => {
      state.selectedTemplateIds = state.data.map(formData => formData.id);
    },
    deselectAllTemplate: state => {
      state.selectedTemplateIds = [];
    },
    toggleSelectedSingleTemplate: (state, action) => {
      const currentTemplateIndex = state.selectedTemplateIds.findIndex(
        formId => formId === action.payload
      );
      if (currentTemplateIndex !== -1) {
        state.selectedTemplateIds.splice(currentTemplateIndex, 1);
      } else {
        state.selectedTemplateIds.push(action.payload);
      }
    },

    // filters actions
    setTemplateSubCategoryId: (state, action) => {
      state.filters.subcategoryId = action.payload;
      state.filters.status = null;
      state.renderId = '';
    },
    setTemplateSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    setTemplateDateRange: (state, action) => {
      state.filters.startTime = action.payload.startTime;
      state.filters.endTime = action.payload.endTime;
    },
    setTemplateOrderBy: (state, action) => {
      state.filters.orderBy = action.payload;
    },
    setAdminTemplateStatus: (state, action) => {
      state.filters.status = action.payload;
      state.filters.subcategoryId = '';
      state.renderId = '';
    },

    resetTemplateFilters: state => {
      state.filters.subcategoryId = '';
      state.filters.searchText = '';
      state.filters.startTime = null;
      state.filters.endTime = null;
      state.filters.orderBy = '';
      state.filters.status = null;
      state.renderId = '';
    }
  }
});

export const {
  getAdminTemplates,
  getAdminTemplatesSilently,
  getAdminTemplatesSuccess,
  getAdminTemplatesFailed,

  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFailed,
  resetTemplateUpdateSuccess,

  renameTemplate,
  renameTemplateSuccess,
  renameTemplateFailed,

  togglePublishUnPublishTemplate,
  togglePublishUnPublishTemplateSuccess,

  cloneTemplate,
  cloneTemplateSuccess,

  moveToTrashTemplate,
  moveToTrashTemplateSuccess,

  moveTemplateToSubCategory,
  moveTemplateToSubCategorySuccess,
  moveTemplateToSubCategoryFailed,
  resetMovingSuccess,

  addTemplateToSubCategory,
  addTemplateToSubCategorySuccess,
  addTemplateToSubCategoryFailed,
  resetAddingSuccess,

  restoreAdminTemplate,
  restoreAdminTemplateSuccess,

  removeForeverAdminTemplate,
  removeForeverAdminTemplateSuccess,

  selectedAllTemplate,
  deselectAllTemplate,
  toggleSelectedSingleTemplate,

  setTemplateSubCategoryId,
  setTemplateSearchText,
  setTemplateDateRange,
  setTemplateOrderBy,
  setAdminTemplateStatus,
  resetTemplateFilters
} = adminTemplatesSlice.actions;

export default adminTemplatesSlice.reducer;
