import {all, put} from 'redux-saga/effects';
import {call, select, takeEvery} from '@redux-saga/core/effects';
import {showErrorSnackbar} from '../snackbar/snackbarSlice';
import FormHeaderService from '../../../services/FromHeader.service';
import {
  getFormHeaderDataFailed,
  getFormHeaderDataSuccess,
  updateFormHeaderDataFailed,
  updateFormHeaderDataSuccess
} from './formHeaderSlice';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';
import {selectFormFooterData} from "./formHeaderSelector";
import {FORM_SETTINGS_TYPE_HEADER} from "../../../helpers/constant/formSettingsConstant";

function* formHeaderWatcher() {
  yield takeEvery('formHeader/getFormHeaderData', getFormHeaderDataSaga);
  yield takeEvery('formHeader/enableFormHeader', updateFormHeaderDataSaga);
  yield takeEvery('formHeader/updateFormHeaderData', updateFormHeaderDataSaga);
}

function* getFormHeaderDataSaga(action) {
  try {
    const response = yield call(FormHeaderService.getFormHeaderData, action.payload);

    if (response.success) {
      yield put(getFormHeaderDataSuccess(response.data));
    } else {
      yield put(getFormHeaderDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormHeaderDataSaga(action) {
  try {
    const headerSettings = yield select(selectFormFooterData);
    let settings = {...headerSettings, ...action.payload};
    settings = JSON.stringify(settings);

    const payload = {
      formId: action.payload.formId,
      type: FORM_SETTINGS_TYPE_HEADER,
      settings: settings
    }

    yield put(updateBuilderTime('Saving...'));
    const response = yield call(FormHeaderService.updateFormHeaderData, payload);

    if (response.success) {
      yield put(updateFormHeaderDataSuccess(response.data));
    } else {
      yield put(updateFormHeaderDataFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
    yield put(
        updateBuilderTime(
            'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
        )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formHeaderSaga() {
  yield all([formHeaderWatcher()]);
}