import {FORM_TYPE} from '../../constant/CoreConstants';
import {matchCondition} from './matchCondition';

const initialMatchCondition = (rules, fieldsIdMap, fields, formType = FORM_TYPE.CLASSIC) => {
  if (formType === FORM_TYPE.CLASSIC) {
    fields.forEach(eachPage => {
      eachPage.pageItems.forEach(eachField => {
        const fieldId = eachField.id;
        if (fieldsIdMap[fieldId] && fieldsIdMap[fieldId].ruleIndexes.length > 0) {
          matchCondition(rules, fieldsIdMap, fieldId, fields);
        }
      });
    });
  } else {
    fields.forEach(eachField => {
      const fieldId = eachField.id;
      if (fieldsIdMap[fieldId] && fieldsIdMap[fieldId].ruleIndexes.length > 0) {
        matchCondition(rules, fieldsIdMap, fieldId, fields, FORM_TYPE.CARD);
      }
    });
  }
};

export default initialMatchCondition;
