import {all, put} from 'redux-saga/effects';
import {call, takeEvery} from '@redux-saga/core/effects';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbarSlice';
import TemplatePublishService from '../../../services/TemplatePublish.service';
import {
  getTemplatePublishDataFailed,
  getTemplatePublishDataSuccess,
  updateTemplatePublishDataFailed,
  updateTemplatePublishDataSuccess
} from './templatePublishSlice';
import {renameBuilderFormTitleSuccess} from '../builderSetting/builderSettingSlice';

function* templatePublishWatcher() {
  yield takeEvery('templatePublish/getTemplatePublishData', getTemplatePublishDataSaga);
  yield takeEvery('templatePublish/updateTemplatePublishData', updateTemplatePublishDataSaga);
}

function* getTemplatePublishDataSaga(action) {
  try {
    const response = yield call(TemplatePublishService.getTemplatePublishData, action.payload);

    if (response.success) {
      yield put(getTemplatePublishDataSuccess(response.data));
    } else {
      yield put(getTemplatePublishDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateTemplatePublishDataSaga(action) {
  try {
    const response = yield call(TemplatePublishService.updateTemplatePublishData, action.payload);

    if (response.success) {
      yield put(updateTemplatePublishDataSuccess(response.data));
      yield put(showSuccessSnackbar(response.message));
      if (response.data.name) {
        yield put(renameBuilderFormTitleSuccess(response.data.name));
      }
    } else {
      yield put(updateTemplatePublishDataFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* templatePublishSaga() {
  yield all([templatePublishWatcher()]);
}
