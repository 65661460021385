import {ComponentConfig} from '../../../helpers/builderConstant/ComponentConfig';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import {FORM_TYPE} from '../../../helpers/constant/CoreConstants';

export const selectAddFieldSaga = state => {
  const {id} = state.builderSetting;
  const {fields, selectedIndex, selectedPageIndex, fieldsIdMap} = state.builderFields;
  return {id, fields, selectedIndex, selectedPageIndex, fieldsIdMap};
};

export const selectBuilderSetting = state => state.builderSetting;
export const selectBuilderFields = state => state.builderFields;

export const selectAllInputBuilderFields = state => {
  const allFields = [];

  state.builderFields.fields.forEach(eachPage => {
    eachPage.pageItems.forEach(eachField => {
      let clonedField = JSON.parse(JSON.stringify(eachField));
      if (
        ComponentConfig[clonedField.component].isInput !== false &&
        clonedField.component !== COMPONENT_TYPE.HIDDEN_FIELD
      ) {
        allFields.push(clonedField);
      }
    });
  });

  return allFields;
};

export const selectAllBuilderFields = state => {
  const allFields = [];

  state.builderFields.fields.forEach(eachPage => {
    eachPage.pageItems.forEach(eachField => {
      let clonedField = JSON.parse(JSON.stringify(eachField));
      if (
        clonedField.component !== COMPONENT_TYPE.HIDDEN_FIELD &&
        clonedField.component !== COMPONENT_TYPE.SUBMIT_BUTTON &&
        clonedField.component !== COMPONENT_TYPE.PROGRESSBAR
      ) {
        const key =
          eachField.component === COMPONENT_TYPE.PARAGRAPH
            ? 'text'
            : eachField.component === COMPONENT_TYPE.HEADING
            ? 'heading'
            : 'label';
        allFields.push({
          ...clonedField,
          label: clonedField[key] || ComponentConfig[eachField.component].name
        });
      }
    });
  });

  return allFields;
};

export const selectFieldLabelsAndOptionsById = state => {
  const fieldIdLabels = {};

  state.builderFields.fields.forEach(eachPage => {
    eachPage.pageItems.forEach(eachField => {
      let options;
      const key =
        eachField.component === COMPONENT_TYPE.PARAGRAPH
          ? 'text'
          : eachField.component === COMPONENT_TYPE.HEADING
          ? 'heading'
          : 'label';
      const label = eachField[key] ?? ComponentConfig[eachField.component].name;

      if (eachField.component === COMPONENT_TYPE.YES_NO) {
        options = {
          yes: eachField.positiveText,
          no: eachField.negativeText
        };
      } else if (
        eachField.component === COMPONENT_TYPE.RADIO ||
        eachField.component === COMPONENT_TYPE.CHECKBOX ||
        eachField.component === COMPONENT_TYPE.DROPDOWN
      ) {
        options = {};
        eachField.options.forEach(eachOption => {
          options[eachOption.value] = eachOption.title;
        });
      }

      fieldIdLabels[eachField.id] = {label, options};
    });
  });

  return fieldIdLabels;
};

export const selectBuilderFieldValue = (state, fieldId) => {
  if (!fieldId) return '';
  let searchField = null;

  state.builderFields.fields.forEach(eachPage => {
    eachPage.pageItems.forEach(eachField => {
      let clonedField = JSON.parse(JSON.stringify(eachField));
      if (clonedField.id === fieldId) {
        searchField = clonedField;
      }
    });
  });

  if (!searchField) return '';

  if (searchField.component === COMPONENT_TYPE.YES_NO) {
    return [
      {label: searchField.positiveText, id: 'yes'},
      {label: searchField.negativeText, id: 'no'}
    ];
  } else if (
    searchField.component === COMPONENT_TYPE.RADIO ||
    searchField.component === COMPONENT_TYPE.CHECKBOX ||
    searchField.component === COMPONENT_TYPE.DROPDOWN
  ) {
    return Array.isArray(searchField.options)
      ? searchField.options.map(option => ({label: option.title, id: option.value}))
      : [];
  } else if (searchField.component === COMPONENT_TYPE.DISCLAIMER) {
    return [
      {label: 'Checked', id: true},
      {label: 'Unchecked', id: false}
    ];
  } else {
    return '';
  }
};

export const selectBuilderFormattedPageList = state => {
  const pages = [];

  if (state.builderSetting.theme.layout === FORM_TYPE.CARD) {
    const fieldLabelsById = selectFieldLabelsAndOptionsById(state);
    let pageIndex = 0;
    state.builderFields.fields.forEach(eachPage => {
      eachPage.pageItems.forEach(eachField => {
        if (ComponentConfig[eachField.component].cardComponent && eachField.hidden !== true) {
          pages.push({
            label: `Page ${pageIndex + 1} (${fieldLabelsById[eachField.id]?.label})`,
            id: pageIndex
          });
          pageIndex++;
        }
      });
    });
    return pages;
  }

  const totalPage = state.builderFields.fields?.length || 0;
  Array.from({length: totalPage || 1}).forEach((_, i) => {
    const pageNo = i + 1;
    pages.push({
      label: `Page ${pageNo} ${totalPage > 1 && totalPage === pageNo ? ': Last Page' : ''}`,
      id: i
    });
  });

  return pages;
};

export const selectPublicPage = state => state.publicPage;
