import React from 'react';
import {Skeleton, Stack} from '@mui/material';

const CommonCheckboxLoader = ({count = 10}) => {
  return (
    <Stack direction={'row'} alignItems="center" spacing={1}>
        <Skeleton variant="rounded" width={15} height={15}  />
        <Skeleton variant="text" width={80} />
    </Stack>
  );
};

export default CommonCheckboxLoader;
