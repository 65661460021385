import React from 'react';
import {alpha, Box, Step, StepLabel, Stepper, styled} from '@mui/material';

export const CustomStepper = styled(Stepper)(({theme}) => ({
  '& .MuiStepLabel-iconContainer': {
    height: '6px',
    width: '100%'
  },
  '& .MuiStepConnector-line': {
    display: 'none'
  }
}));

const CustomStepIcon = (stepProps, color) => {
  return (
    <Box
      sx={{
        background: stepProps.completed
          ? color
          : stepProps.active
          ? alpha(color, 0.5)
          : alpha(color, 0.2),
        width: '100%',
        border: stepProps.active ? `1px solid ${color}` : '',
        borderRadius: 4
      }}
    />
  );
};

const getAllSteps = (totalSteps, color) => {
  let arr = [];
  for (let i = 1; i <= totalSteps; i++) {
    arr.push(
      <Step key={i}>
        <StepLabel StepIconComponent={stepProps => CustomStepIcon(stepProps, color)}></StepLabel>
      </Step>
    );
  }

  return arr;
};

const DashStepper = ({totalSteps, color, totalDone}) => {
  return (
    <Box sx={{width: '100%'}} py={2}>
      <CustomStepper activeStep={totalDone} alternativeLabel>
        {getAllSteps(totalSteps, color)}
      </CustomStepper>
    </Box>
  );
};

export default DashStepper;
