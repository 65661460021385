import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import RedoIcon from '@mui/icons-material/Redo';
import {COMPONENT_TYPE} from '../builderConstant/ComponentType';
import AsteriskIcon from '../../common/Icons/AsteriskIcon';

export const CONDITION_PAGE_VIEW = {
  HOME: 'home',
  TYPE_SELECTION: 'type',
  BUILDER: 'builder'
};

export const CONDITION_TYPE = {
  SHOW_HIDE: 'show',
  ENABLE_REQUIRED: 'required',
  SKIP: 'skip'
};

export const CONDITION_LINK = {
  ANY: 'any',
  ALL: 'all'
};

export const CONDITION_LINK_LIST = [
  {
    label: 'Any',
    id: CONDITION_LINK.ANY
  },
  {
    label: 'All',
    id: CONDITION_LINK.ALL
  }
];

export const CONDITION_COLORS = {
  [CONDITION_TYPE.SHOW_HIDE]: '#0984e3',
  [CONDITION_TYPE.ENABLE_REQUIRED]: '#ff7675',
  [CONDITION_TYPE.SKIP]: '#00b894'
};

export const CONDITION_ACTION_DO = {
  HIDE: 'hide',
  SHOW: 'show',
  REQUIRED: 'require',
  UN_REQUIRED: 'unRequire',
  DISABLED: 'disable',
  ENABLED: 'enable',
  SKIP_PAGE: 'skipPage'
};

export const CONDITION_ACTION_SETTING = {
  [CONDITION_ACTION_DO.HIDE]: {hidden: true},
  [CONDITION_ACTION_DO.SHOW]: {hidden: false},
  [CONDITION_ACTION_DO.REQUIRED]: {required: true},
  [CONDITION_ACTION_DO.UN_REQUIRED]: {required: false},
  [CONDITION_ACTION_DO.DISABLED]: {readOnly: true},
  [CONDITION_ACTION_DO.ENABLED]: {readOnly: false},
  [CONDITION_ACTION_DO.SKIP_PAGE]: {skipToPageCondition: true}
};

const DO_HIDE = {
  label: 'Hide',
  id: CONDITION_ACTION_DO.HIDE
};

const DO_SHOW = {
  label: 'Show',
  id: CONDITION_ACTION_DO.SHOW
};

const DO_REQUIRED = {
  label: 'Require',
  id: CONDITION_ACTION_DO.REQUIRED
};

const DO_UN_REQUIRED = {
  label: 'Unrequire',
  id: CONDITION_ACTION_DO.UN_REQUIRED
};

const DO_DISABLED = {
  label: 'Disable (read only)',
  id: CONDITION_ACTION_DO.DISABLED
};

const DO_ENABLED = {
  label: 'Enable',
  id: CONDITION_ACTION_DO.ENABLED
};

const DO_SKIP_PAGE = {
  label: 'Skip to Page on Next Button',
  id: CONDITION_ACTION_DO.SKIP_PAGE
};

export const CONDITION_ACTION_DO_LIST = {
  [CONDITION_TYPE.SHOW_HIDE]: [DO_HIDE, DO_SHOW],
  [CONDITION_TYPE.ENABLE_REQUIRED]: [DO_REQUIRED, DO_UN_REQUIRED, DO_DISABLED, DO_ENABLED],
  [CONDITION_TYPE.SKIP]: [DO_SKIP_PAGE]
};

export const CONDITION_LIST = [
  {
    type: CONDITION_TYPE.SHOW_HIDE,
    heading: 'SHOW / HIDE FIELD',
    subHeading: 'Change visibility of field(s) depending on `IF` State conditions.',
    icon: <RemoveRedEyeIcon />,
    color: CONDITION_COLORS[CONDITION_TYPE.SHOW_HIDE]
  },
  {
    type: CONDITION_TYPE.ENABLE_REQUIRED,
    heading: 'ENABLE / REQUIRE FIELD',
    subHeading: 'Set field requirements, disable fields, or mask fields',
    icon: <AsteriskIcon />,
    color: CONDITION_COLORS[CONDITION_TYPE.ENABLE_REQUIRED]
  },
  {
    type: CONDITION_TYPE.SKIP,
    heading: 'SKIP TO A NEW FIELD/PAGE',
    subHeading: 'Skip ahead to a specific field or page based on a contacts selection.',
    icon: <RedoIcon />,
    color: CONDITION_COLORS[CONDITION_TYPE.SKIP]
  }
];

export const CONDITION_ACTION_STATE = {
  IS_EMPTY: 'isEmpty',
  IS_FILLED: 'isFilled',
  IS_EQUAL_TO: 'isEqualTo',
  IS_NOT_EQUAL_TO: 'isNotEqualTo'
};

const STATE_IS_EMPTY = {
  label: 'Is Empty',
  id: CONDITION_ACTION_STATE.IS_EMPTY
};

const STATE_IS_FILLED = {
  label: 'Is Filled',
  id: CONDITION_ACTION_STATE.IS_FILLED
};

const STATE_IS_EQUAL_TO = {
  label: 'Is Equal To',
  id: CONDITION_ACTION_STATE.IS_EQUAL_TO
};

const STATE_IS_NOT_EQUAL_TO = {
  label: 'Is Not Equal To',
  id: CONDITION_ACTION_STATE.IS_NOT_EQUAL_TO
};

export const CONDITION_ACTION_STATE_LIST = {
  0: [STATE_IS_EQUAL_TO, STATE_IS_NOT_EQUAL_TO, STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.FULL_NAME]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.ADDRESS]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.BIRTHDAY]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.DATE]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.TIME]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.FILE_UPLOAD]: [STATE_IS_EMPTY, STATE_IS_FILLED],
  [COMPONENT_TYPE.EMAIL]: [
    STATE_IS_EQUAL_TO,
    STATE_IS_NOT_EQUAL_TO,
    STATE_IS_EMPTY,
    STATE_IS_FILLED
  ],
  [COMPONENT_TYPE.DISCLAIMER]: [STATE_IS_EQUAL_TO]
};

export const ALL_CONDITION_ACTION_ITEM_TEXT = {
  [CONDITION_ACTION_STATE.IS_EMPTY]: STATE_IS_EMPTY.label,
  [CONDITION_ACTION_STATE.IS_FILLED]: STATE_IS_FILLED.label,
  [CONDITION_ACTION_STATE.IS_EQUAL_TO]: STATE_IS_EQUAL_TO.label,
  [CONDITION_ACTION_STATE.IS_NOT_EQUAL_TO]: STATE_IS_NOT_EQUAL_TO.label
};

const CONDITION_ACTION_TARGET = {
  VALUE: 'value'
};

const TARGET_VALUE = {
  label: 'Value',
  id: CONDITION_ACTION_TARGET.VALUE
};

export const CONDITION_ACTION_TARGET_LIST = {
  [CONDITION_ACTION_STATE.IS_EQUAL_TO]: [TARGET_VALUE],
  [CONDITION_ACTION_STATE.IS_NOT_EQUAL_TO]: [TARGET_VALUE]
};

export const CONDITION_PROTOTYPES = {
  [CONDITION_TYPE.SHOW_HIDE]: {
    action: [
      {
        fieldId: '',
        visibility: ''
      }
    ],
    link: CONDITION_LINK.ANY,
    condition: [
      {
        fieldId: '',
        operator: '',
        value: ''
      }
    ],
    type: CONDITION_TYPE.SHOW_HIDE
  },
  [CONDITION_TYPE.ENABLE_REQUIRED]: {
    action: [
      {
        fieldId: '',
        visibility: ''
      }
    ],
    link: CONDITION_LINK.ANY,
    condition: [
      {
        fieldId: '',
        operator: '',
        value: ''
      }
    ],
    type: CONDITION_TYPE.ENABLE_REQUIRED
  },
  [CONDITION_TYPE.SKIP]: {
    action: [
      {
        fieldId: '',
        page: ''
      }
    ],
    link: CONDITION_LINK.ANY,
    condition: [
      {
        fieldId: '',
        operator: '',
        value: ''
      }
    ],
    type: CONDITION_TYPE.SKIP
  }
};
