import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Box, Stack} from "@mui/material";
import {ContentCopy, LibraryAddCheck} from "@mui/icons-material";
import CodeViewer from "../CodeViewer";
import CoreButton from "../../../../common/Button/CoreButton";
import copyFromClipBoard from "../../../../helpers/utils/copyFromClipBoard";
import generatePublicPageUrl from "../../../../helpers/utils/generatePublicPageUrl";
import {
    selectBuilderFormApiKey,
    selectBuilderFormTitle
} from "../../../../state/features/builderSetting/builderSettingSelector";

const EmbedShareIframeBody = () => {
    const formApiKey = useSelector(selectBuilderFormApiKey);
    const formTitle = useSelector(selectBuilderFormTitle);

    const [iframeValue, setIframeValue] = useState(
        '<iframe id="inlineFrameExample"\n    title="Inline Frame Example"\n    height="200"\n    style="border: 0px;width: 100%;"\n    src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik">\n</iframe>'
    );
    const [copied, setCopied] = useState(false);

    const onCopy = React.useCallback(value => {
        copyFromClipBoard(value);
        setCopied(true);
    }, []);

    useEffect(() => {
        setIframeValue(
            `<iframe id="${
                'inlineForm-' + formApiKey
            }" title="${formTitle}" height="600px" width="100%" frameborder="0" allowfullscreen src="${generatePublicPageUrl(
                formApiKey
            )}"/>`
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiKey]);

    return (
        <Stack direction="column" spacing={2} px={4} py={3}>
            <CodeViewer>
                {iframeValue}
            </CodeViewer>

            <Box sx={{textAlign: 'right'}}>
                <CoreButton
                    size="small"
                    variant="contained"
                    color="secondary"
                    startIcon={copied ? <LibraryAddCheck /> : <ContentCopy />}
                    onClick={() => onCopy(iframeValue)}
                >
                    {copied ? 'Copied Code' : 'Copy Code'}
                </CoreButton>
            </Box>
        </Stack>
    );
};

export default EmbedShareIframeBody;