import React from 'react';
import {Box, Container, Stack, styled, Typography, useTheme} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Clear} from '@mui/icons-material';
import CoreButton from '../../../common/Button/CoreButton';
import FormTemplatePreviewRender from './FormTemplatePreviewRender';

const PreviewWrapper = styled(Box)(({theme}) => ({
  height: '100%',
  width: '100%'
}));

const FormTemplatePreviewContent = ({templateInfo, onClose, handleUseTemplate}) => {
  const theme = useTheme();
  const {id, formTitle, description} = templateInfo || {};

  return (
    <div className="fbp-template-preview">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: 'common.white',
          zIndex: 9,
          borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`
        }}
      >
        <Typography variant={'h6'}>{formTitle}</Typography>

        <Stack direction={'row'} alignItems="center" spacing={1}>
          <CoreButton variant="contained" color="secondary" onClick={handleUseTemplate}>
            Use This Template
          </CoreButton>
          <IconButton onClick={onClose}>
            <Clear color={'action'} />
          </IconButton>
        </Stack>
      </Stack>

      <Container maxWidth="lg" sx={{P: 1}}>
        <Stack direction={'column'} spacing={1} my={2}>
          <Typography variant={'h6'}>{formTitle}</Typography>
          <Typography variant={'body2'}>{description}</Typography>
        </Stack>
        <PreviewWrapper>
          <FormTemplatePreviewRender templateId={id} />
        </PreviewWrapper>
      </Container>
    </div>
  );
};

export default FormTemplatePreviewContent;
