import React, {useState} from 'react';
import {StyledDefaultTimePicker, StyledTimePicker} from './TimePickerStyles';
import {Box, TextField} from '@mui/material';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {format as timeFormat} from 'date-fns';
import Styles from './custom-time-picker.module.scss';

const CustomTimePicker = ({
  className = '',
  size = 'medium',
  ampm = true,
  value = null,
  onChange = null,
  readOnly = false,
  viewType = 'default',
  orientation = 'landscape'
}) => {
  const [open, setOpen] = useState(false);

  const handleTimeChange = time => {
    if (!readOnly) {
      onChange(timeFormat(time.$d, 'hh:mm aa'));
    }
  };

  const handleOpenDialog = () => {
    if (!readOnly) {
      setOpen(true);
    }
  };

  const renderClasses =
    size === 'small'
      ? Styles.fbpGlobalTimePickerSmall
      : size === 'medium'
      ? Styles.fbpGlobalTimePickerMedium
      : Styles.fbpGlobalTimePickerLarge;

  if (viewType === 'default') {
    return (
      <StyledDefaultTimePicker
        className={`${Styles.fbpGlobalTimePicker} ${renderClasses} ${className}`}
        key={ampm}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            open={open}
            onOpen={() => handleOpenDialog()}
            onClose={() => setOpen(false)}
            sx={{width: '100%'}}
            ampm={ampm}
            value={value}
            onChange={handleTimeChange}
            renderInput={params => {
              params.inputProps.readOnly = true;
              params.placeholder = 'hh:mm'
              return <TextField onClick={() => handleOpenDialog()} {...params} />;
            }}
            PopperProps={{sx: {zIndex: 9999}}}
          />
        </LocalizationProvider>
      </StyledDefaultTimePicker>
    );
  }

  return (
    <Box sx={{width: '100%'}} className={`${Styles.fbpGlobalTimePicker} ${renderClasses}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledTimePicker
          ampm={ampm}
          orientation={orientation}
          openTo="hours"
          value={value}
          onChange={handleTimeChange}
          toolbarTitle=""
          renderInput={params => <TextField {...params} />}
          disabled={readOnly}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CustomTimePicker;
