export const EMBED_MODES = {
    STANDARD: "standard",
    FULL_PAGE: "fullPage",
    POPUP: "popup",
    SLIDER: "slider",
    POPOVER: "popover",
    SIDE_TAB: "sideTab"
};

export const EMBED_MODES_TEXTS = {
    [EMBED_MODES.STANDARD]: 'Standard',
    [EMBED_MODES.FULL_PAGE]: 'Full Page',
    [EMBED_MODES.POPUP]: 'Pop-up',
    [EMBED_MODES.SLIDER]: 'Slider',
    [EMBED_MODES.POPOVER]: 'Pop-over',
    [EMBED_MODES.SIDE_TAB]: 'Side Tab'
};

export const EMBED_POPUP_SIZES = [
    {title: 'Large', value: 'large'},
    {title: 'Medium', value: 'medium'},
    {title: 'Small', value: 'small'}
];

export const EMBED_BUTTON_STYLES = [
    {title: 'Contained', value: 'contained'},
    {title: 'Outlined', value: 'outlined'},
    {title: 'Text', value: 'text'}
];

export const EMBED_BUTTON_SIZES = [
    {title: 'Large', value: 'large'},
    {title: 'Medium', value: 'medium'},
    {title: 'Small', value: 'small'}
];

export const EMBED_WIDTH_SUFFIX = [
    {title: 'PX', value: 'px'},
    {title: '%', value: '%'},
];

export const EMBED_HEIGHT_SUFFIX = [
    {title: 'PX', value: 'px'},
    {title: '%', value: 'vh'},
];