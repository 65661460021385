import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {alpha, Box} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import CloseIcon from '@mui/icons-material/Close';
import {Pause, PlayArrow} from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteWarningModal from '../../../Builder/Modals/DeleteWarningModal';
import useBoolean from '../../../../hooks/useBoolean';
import {
  cloneCondition,
  deleteCondition,
  disableCondition,
  editCondition,
  selectSingleConditionId
} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {selectSelectedConditionId} from '../../../../state/features/conditionSetting/conditionSettingSelector';
import {CONDITION_COLORS} from '../../../../helpers/constant/formConditionConstant';
import {castArray} from '../../../../helpers/utils/castArray';
import Styles from '../ConditionsPage.module.scss';

const ConditionItemActions = ({singleRule = {}}) => {
  const dispatch = useDispatch();
  const selectedConditionId = useSelector(selectSelectedConditionId);

  const {
    value: isOpenDeleteModal,
    setTrue: handleOpenDeleteModal,
    setFalse: handleCloseDeleteModal
  } = useBoolean();

  const onClickEdit = () => {
    dispatch(editCondition({ruleId: singleRule.id}));
    dispatch(selectSingleConditionId(''));
  };

  const onClickSetting = isSettingOpen => {
    dispatch(selectSingleConditionId(isSettingOpen ? '' : singleRule.id));
  };

  const onConfirmDelete = () => {
    dispatch(deleteCondition(castArray(singleRule.id)));
    handleCloseDeleteModal();
  };

  const onClickDisabled = disabled => {
    dispatch(disableCondition({ids: castArray(singleRule.id), disabled: disabled}));
    dispatch(selectSingleConditionId(''));
  };

  const onClickClone = () => {
    dispatch(cloneCondition({ruleId: singleRule.id}));
    dispatch(selectSingleConditionId(''));
  };

  const settingOpen = selectedConditionId === singleRule.id;
  return (
    <>
      <Box className={Styles.fbpConditionAction}>
        <Box
          className={Styles.fbpConditionActionItem}
          sx={{backgroundColor: CONDITION_COLORS[singleRule.type]}}
          onClick={onClickEdit}
        >
          <CreateIcon fontSize="large" />
        </Box>

        <Box
          className={Styles.fbpConditionActionItem}
          sx={{backgroundColor: CONDITION_COLORS[singleRule.type]}}
          onClick={() => onClickSetting(settingOpen)}
        >
          {settingOpen ? <CloseIcon fontSize="large" /> : <SettingsIcon fontSize="large" />}
        </Box>
      </Box>

      {settingOpen && (
        <>
          {/* Condition Setting background */}
          <Box
            className={Styles.fbpConditionSettingBg}
            sx={{backgroundColor: alpha(CONDITION_COLORS[singleRule.type], 0.5)}}
          />
          {/* Condition Setting actions */}
          <Box className={Styles.fbpConditionSettingContent}>
            <Box className={Styles.fbpConditionSettingContentItem} onClick={handleOpenDeleteModal}>
              <DeleteForeverIcon fontSize="small" />
              <Box>DELETE</Box>
            </Box>

            <Box
              className={Styles.fbpConditionSettingContentItem}
              onClick={() => onClickDisabled(!singleRule.disabled)}
            >
              {singleRule.disabled ? <PlayArrow fontSize="small" /> : <Pause fontSize="small" />}
              <Box>{singleRule.disabled ? 'ENABLE' : 'DISABLE'}</Box>
            </Box>

            <Box className={Styles.fbpConditionSettingContentItem} onClick={onClickClone}>
              <ContentCopyIcon fontSize="small" />
              <Box>CLONE</Box>
            </Box>
          </Box>
        </>
      )}

      <DeleteWarningModal
        warningSubtitle={'Are you sure you want to delete this condition?'}
        warningContent={'This condition will be deleted permanently.This action cannot be undone.'}
        buttonText={'Yes, Delete'}
        cancelButtonText={'Cancel'}
        open={isOpenDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default ConditionItemActions;
