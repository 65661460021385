import React, {useMemo} from 'react';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {Stack, Box} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {format as dateFormat} from 'date-fns';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import {FormDatePicker} from '../../Builder/FormComponents/FormDatePicker';
import withFieldChange from '../../../hoc/withFieldChange';
import RequireIcon from '../../../common/Icons/RequireIcon';

const BirthdateComponent = ({field, onChange, editMode}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  const getDateFormat = useMemo(() => {
    let separtedFormat = field.format.split('/');
    return (
      separtedFormat[0] + field.separator + separtedFormat[1] + field.separator + separtedFormat[2]
    );
  }, [field]);

  const getDefaultDate = useMemo(() => {
    if (field.defaultDate === 'none') {
      return null;
    } else if (field.defaultDate === 'current') {
      return dateFormat(new Date(), 'yyyy-MM-dd');
    } else {
      return field.value === null ? null : field.value;
    }
  }, [field]);

  const orientation = useMemo(
    () => (field.shrink || field.labelAlignment !== 'top' ? 'portrait' : 'landscape'),
    [field]
  );

  return (
    <Box sx={{position: 'relative'}}>
      <Box className={`${field.inputIcon ? 'fbpEmailComponentIcon' : ''} ${labelAlignmentClass}`}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>
        <Box sx={{flex: 1, position: 'relative', width: '100%'}}>
          <FormDatePicker
            inputIcon={
              field.inputIcon &&
              field.viewType === 'default' && <span>{fbpIcons.birthdayIcon}</span>
            }
            className="BuilderDatePicker"
            readOnly={editMode || field.readOnly}
            name={field.id}
            value={getDefaultDate}
            rules={field.required ? {required: 'Birthdate is required'} : {}}
            format={getDateFormat}
            type={field.viewType}
            orientation={orientation}
            helperTextProps={{
              text: field.helperText
            }}
            onChange={value => onChange({value: value, defaultDate: 'custom'})}
          />
        </Box>
      </Box>

      {field.hidden && <HiddenFieldAlert className="mt-3" />}
    </Box>
  );
};

export default withFieldChange(BirthdateComponent);
