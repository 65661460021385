import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import {useWindowSize} from '../../hooks/useWindowSize';
import TemplatePageHeader from './AdminTemplateHeader/TemplatePageHeader';
import TemplateRightTabBar from './TemplateLeftTabBar';
import TemplateLeftSidebar from './TemplateLeftSidebar';
import TemplateBody from './TemplateBody';
import SeoSettings from '../../common/SeoSettings';
import {
  getAdminTemplateCategories,
  getAdminTemplateCategoriesSilently,
  resetSelectedAdminCategory,
  resetSelectedAdminSubCategory
} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSlice';
import {selectAdminTemplateCategoriesRenderId} from '../../state/features/adminTemplateCategories/adminTemplateCategoriesSelector';
import Styles from './AdminTemplate.module.scss';

const AdminTemplate = () => {
  const dispatch = useDispatch();
  const {width} = useWindowSize();
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);
  const renderId = useSelector(selectAdminTemplateCategoriesRenderId);

  const toggleLeftSidePanel = () => {
    if (width > 1024) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  };

  useEffect(() => {
    if (!renderId) dispatch(getAdminTemplateCategories());
    else dispatch(getAdminTemplateCategoriesSilently());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderId]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedAdminCategory());
      dispatch(resetSelectedAdminSubCategory());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{bgColor: 'var(--fbp-background-default)'}}>
      <SeoSettings
        title="Form Builder Admin Templates"
        description="Form Builder Admin Templates"
        keywords="test,test1"
      />
      <TemplatePageHeader />

      <Box
        className={`${Styles.fbpBody} ${isHideLeftSide ? Styles.hideSidebar : ''} 
        ${isShowLeftSideTab ? Styles.showTabSidebar : ''}`}
      >
        <Box className={`${Styles.fbpLeftSide} fbpLeftSide`}>
          <TemplateLeftSidebar
            isHideLeftSide={isHideLeftSide}
            toggleLeftSide={toggleLeftSidePanel}
          />
          {/* For Tab Responsive */}
          <TemplateRightTabBar onChangeLeftSideTab={() => toggleLeftSidePanel()} />
        </Box>

        <Box className={Styles.mainContent}>
          <TemplateBody />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminTemplate;
