import {useEffect} from 'react';

const Theming = ({theme}) => {
  useEffect(() => {
    //Variables are available in Theming.scss
    document.documentElement.style.setProperty('--fbp-theming-form-color', theme.formColor);
    document.documentElement.style.setProperty(
      '--fbp-theming-form-image',
      theme.formImage ? `url(${theme.formImage})` : 'none'
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-form-image-opacity',
      theme.formImageOpacity
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-form-image-color',
      theme.formImageColor
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-form-image-attachment',
      theme.formImageAttachment
    );
    document.documentElement.style.setProperty('--fbp-theming-page-color', theme.pageColor);
    document.documentElement.style.setProperty(
      '--fbp-theming-page-image',
      theme.pageImage ? `url(${theme.pageImage})` : 'none'
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-page-image-opacity',
      theme.pageImageOpacity
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-page-image-color',
      theme.pageImageColor
    );
    document.documentElement.style.setProperty('--fbp-theming-font-color', theme.fontColor);
    document.documentElement.style.setProperty(
      '--fbp-theming-input-background',
      theme.inputBackground
    );
    document.documentElement.style.setProperty('--fbp-theming-sub-text-color', theme.subTextColor);
    document.documentElement.style.setProperty(
      '--fbp-theming-placeholder-color',
      theme.placeholderColor
    );
    document.documentElement.style.setProperty('--fbp-theming-text-color', theme.textColor);
    document.documentElement.style.setProperty(
      '--fbp-theming-container-width',
      `${theme.containerWidth}px`
    );

    document.documentElement.style.setProperty(
      '--fbp-theming-field-spacing',
      `${theme.fieldSpacing}px`
    );
    document.documentElement.style.setProperty('--fbp-theming-inner-gap', `${theme.innerGap}px`);
    document.documentElement.style.setProperty('--fbp-theming-font-family', theme.font);
    document.documentElement.style.setProperty(
      '--fbp-theming-label-font-size',
      `${theme.labelFontSize}px`
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-card-view-label-font-size',
      `${theme.cardViewLabelFontSize}px`
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-label-sub-text-font-size',
      `${theme.subTextFontSize}px`
    );
    document.documentElement.style.setProperty(
      '--fbp-theming-label-description-font-size',
      `${theme.descriptionFontSize}px`
    );
  }, [theme]);

  return '';
};

export default Theming;
