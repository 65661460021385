export const getPlatformName = () => {
  if (window.navigator.userAgent.indexOf('Windows NT 10.0') !== -1) return 'Windows 10';
  if (window.navigator.userAgent.indexOf('Windows NT 6.3') !== -1) return 'Windows 8.1';
  if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) return 'Windows 8';
  if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) return 'Windows 7';
  if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) return 'Windows Vista';
  if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) return 'Windows XP';
  if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) return 'Windows 2000';
  if (
    window.navigator.userAgent.indexOf('iPhone') !== -1 ||
    window.navigator.userAgent.indexOf('iPod') !== -1
  ) {
    let match = window.navigator.userAgent.match(/OS ((\d+_?){2,3})\s/);
    let os = 'iOS ' + (match ? match[1] : '');
    //We cannot use replaceAll to old safari
    while (os.includes('_')) {
      os = os.replace('_', '.');
    }
    return os;
  }
  if (window.navigator.userAgent.indexOf('Mac') !== -1) return 'Mac';
  if (window.navigator.userAgent.indexOf('Android') !== -1) {
    // eslint-disable-next-line no-useless-escape
    let match = window.navigator.userAgent.toLowerCase().match(/android\s([0-9\.]*)/i);
    return 'Android ' + (match ? match[1] : '');
  }
  if (window.navigator.userAgent.indexOf('Linux') !== -1) return 'Linux';
  if (window.navigator.userAgent.indexOf('X11') !== -1) return 'UNIX';

  return 'Unknown';
};
