import React from 'react';
import {Box, Checkbox, FormControl, Grid, RadioGroup, Stack} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {registerPatternBuilderFields} from '../../../helpers/constant/registerPattern';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormText} from '../../../common/Form-UI/FormText';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import withFieldChange from '../../../hoc/withFieldChange';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from '../../../common/Icons/RequireIcon';
import {ImageWrapperBox} from '../multipleChoice/AddMediaStyles';
import {getStructuredClone} from '../../../helpers/utils/getStructuredClone';
import {
  FormControlLabelStyled,
  GridViewItemBox,
  ListViewItemBox,
  SelectedIcon
} from './CheckBoxStyle';

const CheckboxComponent = ({field, onChange, editMode}) => {
  const handleChangeCheckbox = (index, value, method, formOption) => {
    if (!field.readOnly && !editMode) {
      let options = getStructuredClone(field.options);
      options[index].checked = value;
      onChange({options});
      method?.setValue(field.id, options.filter(option => option.checked).length);
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  return (
    <>
      <Box mb={1} className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>
            {field.label}
            {field.required && <RequireIcon />}
          </FormLabel>
        </Stack>

        <Stack direction="column" spacing={1} sx={{width: '100%'}}>
          <ConnectPublicForm
            name={field.id}
            value={field.options.filter(option => option.checked).length}
            rules={field.required ? registerPatternBuilderFields.checkbox : {}}
            render={(formOption, formMethods) => (
              <>
                {field.fieldStyle === 'list' && (
                  <Box sx={{width: '100%'}}>
                    <FormControl component="fieldset" sx={{width: '100%'}}>
                      <RadioGroup
                        sx={{rowGap: '12px'}}
                        aria-label="checkbox"
                        name="checkboxComponent"
                      >
                        {field.options.map((option, index) => {
                          const selected = option.checked === true;
                          return (
                            <Stack
                              direction="row"
                              alignItems={'center'}
                              sx={{width: '100%', gap: '8px'}}
                              key={index}
                              onClick={() => {
                                handleChangeCheckbox(
                                  index,
                                  !option.checked,
                                  formMethods,
                                  formOption
                                );
                              }}
                            >
                              <FormControlLabelStyled
                                control={
                                  <Checkbox
                                    checked={selected}
                                    name="checkedB"
                                    onClick={e => e.preventDefault()}
                                  />
                                }
                              />
                              {option.icon && (
                                <ImageWrapperBox sx={{height: 48, width: 48}}>
                                  <img
                                    src={
                                      option.icon ||
                                      process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                    }
                                    alt="Placeholder"
                                  />
                                </ImageWrapperBox>
                              )}
                              <ListViewItemBox
                                alignItems={'center'}
                                selecteditem={selected.toString()}
                                ml={1.5}
                              >
                                <FormText>{option.title}</FormText>
                              </ListViewItemBox>
                            </Stack>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                )}

                {field.fieldStyle === 'grid' && (
                  <Grid container spacing={1}>
                    {field.options.map((option, index) => {
                      const selected = option.checked === true;
                      return (
                        <Grid
                          key={index}
                          item
                          xs={6}
                          md={4}
                          lg={3}
                          className={'fbp_single_select_radio_box'}
                          sx={{
                            minWidth: {
                              xs: '50%',
                              md: field.shrink ? '50%' : '33.333333%',
                              lg: field.shrink ? '50%' : '25%'
                            }
                          }}
                        >
                          <GridViewItemBox
                            direction={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            selecteditem={selected.toString()}
                            key={index}
                            onClick={() => {
                              handleChangeCheckbox(index, !option.checked, formMethods, formOption);
                            }}
                          >
                            {option.icon && (
                              <ImageWrapperBox sx={{height: 48, width: 48}}>
                                <img
                                  src={
                                    option.icon ||
                                    process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                  }
                                  alt="Placeholder"
                                />
                              </ImageWrapperBox>
                            )}
                            <FormText sx={{textAlign: 'center'}}>{option.title}</FormText>
                            <SelectedIcon selected={selected.toString()}>
                              <CheckCircleOutlineIcon />
                            </SelectedIcon>
                          </GridViewItemBox>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(CheckboxComponent);
