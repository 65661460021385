import React from 'react';
import {Breadcrumbs, styled, Typography} from '@mui/material';
import RouteLink from '../Link/RouteLink';

const BreadcrumbsStyled = styled(Breadcrumbs)(() => ({
  background: 'none',
  boxShadow: 'none',
  height: 40,
  display: 'flex',
  alignItems: 'center'
}));

const PresentationBreadcrumb = ({breadCrumbArray = [{name: '', icon: '', path: ''}], callback}) => {
  function handleClick(info) {
    if (callback) callback(info);
  }

  return (
    <BreadcrumbsStyled aria-label="breadcrumb">
      {breadCrumbArray.map((breadCrumbInfo, index) => {
        const {name, path, icon} = breadCrumbInfo || {};

        if (breadCrumbInfo.path) {
          return (
            <RouteLink key={index} to={path}>
              {icon}
              {name}
            </RouteLink>
          );
        } else {
          return (
            <Typography
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: callback && index !== breadCrumbArray.length - 1 ? 'pointer' : 'unset'
              }}
              color={index === breadCrumbArray.length - 1 ? 'text.primary' : 'text.secondary'}
              variant={index === breadCrumbArray.length - 1 ? 'body1' : 'body2'}
              onClick={() => handleClick(breadCrumbInfo)}
            >
              {icon || ''}
              {name}
            </Typography>
          );
        }
      })}
    </BreadcrumbsStyled>
  );
};

export default PresentationBreadcrumb;
