import React, {forwardRef} from 'react';
import Box from '@mui/material/Box';
import {useDispatch} from 'react-redux';
import {nanoid} from 'nanoid';
import {
  cloneFieldFormSelectedIndex,
  deleteFieldFromSelectedIndex
} from '../../../state/features/builderFields/builderFieldsSlice';
import {
  toggleBuilderSettingLeftPanelTab,
  toggleBuilderSettingRightPanel
} from '../../../state/features/builderSetting/builderSettingSlice';
import {COMPONENT_TYPE} from '../../../helpers/builderConstant/ComponentType';
import CustomTooltip from '../../../common/Tooltip/CustomTooltip';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import Styles from './SingleSectionElementsWrp.module.scss';

const ComponentWrapper = forwardRef(
  ({field, children, selected, onClick, width = 100, className, ...rest}, ref) => {
    const dispatch = useDispatch();

    const handleDelete = e => {
      e.stopPropagation();
      dispatch(deleteFieldFromSelectedIndex());
    };

    const handleTogglePropertyPanel = (e, value) => {
      e.stopPropagation();
      dispatch(toggleBuilderSettingRightPanel(value));
    };

    const handleCloneField = e => {
      e.stopPropagation();
      dispatch(cloneFieldFormSelectedIndex({id: nanoid()}));
    };

    const handlePageTabOpen = e => {
      e.stopPropagation();
      dispatch(toggleBuilderSettingLeftPanelTab());
    };

    return (
      <Box
        component="section"
        ref={ref}
        {...rest}
        className={`
                  ${Styles.fbpSingleSectionElements} 
                  ${selected ? Styles.fbpEditAreaActive : ''} 
                  ${field.shrink !== true ? 'fbpHasLabelAlignment' : ''}
                  ${className}
                `}
        onClick={onClick}
        onDoubleClick={e => handleTogglePropertyPanel(e, true)}
        sx={{width: `${width}%`}}
      >
        {selected && (
          <Box className={Styles.fbpElementsSettingsWrp}>
            <Box className={Styles.fbpElementOverlay}>
              <ul
                className={`${Styles.fbpEditorElementSettings} ${Styles.fbpEditorSectionSettings}`}
              >
                <CustomTooltip title="Pages" arrow>
                  <li className={Styles.fbpEditorElementSetting} onClick={handlePageTabOpen}>
                    {fbpIcons.fbpLayerIcon}
                  </li>
                </CustomTooltip>

                {field.cloneableField !== false && (
                  <CustomTooltip title="Clone" arrow>
                    <li className={Styles.fbpEditorElementSetting} onClick={handleCloneField}>
                      {fbpIcons.fbpBuilderCopyIcon}
                    </li>
                  </CustomTooltip>
                )}

                <li className={`${Styles.fbpEditorElementSetting} ${Styles.fbpDragMenu}`}>
                  {fbpIcons.fbpBuilderDragIndicatorIcon}
                </li>

                {field.component !== COMPONENT_TYPE.SUBMIT_BUTTON && (
                  <CustomTooltip title="Delete" arrow>
                    <li className={Styles.fbpEditorElementSetting} onClick={handleDelete}>
                      {fbpIcons.fbpBuilderTrashIcon}
                    </li>
                  </CustomTooltip>
                )}

                <CustomTooltip title="Properties" arrow>
                  <li
                    className={Styles.fbpEditorElementSetting}
                    onClick={handleTogglePropertyPanel}
                  >
                    {fbpIcons.fblBuilderSettingIcon}
                  </li>
                </CustomTooltip>
              </ul>
            </Box>
          </Box>
        )}
        {children}
      </Box>
    );
  }
);

export default ComponentWrapper;
