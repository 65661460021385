import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import EmailAdvanced from './EmailAdvanced';
import EmailGeneral from './EmailGeneral';
import EmailOptions from './EmailOptions';

const EmailRightPanel = ({field}) => {
  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <EmailGeneral field={field} />
      </CustomTab>
      <CustomTab title="Options">
        <EmailOptions field={field} />
      </CustomTab>
      <CustomTab title="Advanced">
        <EmailAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default EmailRightPanel;
