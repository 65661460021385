import {styled, Typography} from '@mui/material';

export const FormHeading = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  color: 'var(--fbp-theming-font-color) ',
  fontWeight: 600,
  fontSize: '36px',
  lineHeight: '48px',
  letterSpacing: '0'
}));

export const FormSubHeader = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  color: 'var(--fbp-theming-sub-text-color) ',
  fontSize: '16px ',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: ' 0.15px'
}));
