import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cogoToast from 'cogo-toast';
import {Box, Typography} from '@mui/material';
import {clearSnackbar} from '../../state/features/snackbar/snackbarSlice';

const SuccessSnackbar = () => {
  const dispatch = useDispatch();
  const {isSuccessSnackbarOpen, successMessage} = useSelector(state => state.snackbar);

  useEffect(() => {
    if (isSuccessSnackbarOpen) {
      cogoToast.success(
        <Box>
          <Typography variant={'body1'}>{successMessage}</Typography>
        </Box>,
        {position: 'bottom-right', heading: 'Success'}
      );
      dispatch(clearSnackbar());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSnackbarOpen]);

  return <div />;
};

export default SuccessSnackbar;
