import React from 'react';
import { useSelector } from 'react-redux';
import {nanoid} from 'nanoid';
import {Stack, Box} from '@mui/material';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightComponentTitle from '../../Builder/RightComponents/RightComponentTitle';
import RightSideOptionsElements from '../../Builder/RightComponents/RightSideOptionsElements';
import {LightCoreButton} from '../../../common/Button/LightCoreButton';
import {getStructuredClone} from '../../../helpers/utils/getStructuredClone';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';

const DropdownOptions = ({field, onChangeValue}) => {
  const {theme} = useSelector(state => state.builderSetting);
  const handleOptionTitleChange = (index, value) => {
    let options = getStructuredClone(field.options);
    options[index].title = value;
    onChangeValue({options});
  };

  const handleAddOption = index => {
    let options = getStructuredClone(field.options);
    let newValue = nanoid();
    options.splice(index, 0, {title: '', value: newValue});
    onChangeValue({options});
    setTimeout(() => {
      //Focusing the next element
      document.getElementById(newValue)?.focus();
    }, 50);
  };

  const handleRemoveOption = index => {
    let options = getStructuredClone(field.options);
    options.splice(index, 1);
    onChangeValue({options});
  };

  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <Stack direction="column" spacing={1} className="builderElementBasicStyle">
        <RightComponentTitle text="Options" title="Each options on this field" />
        {field.options.map((option, index) => (
          <RightSideOptionsElements
            key={option.value}
            value={option.value}
            title={option.title}
            onChangeTitle={value => handleOptionTitleChange(index, value)}
            onAddOption={() => handleAddOption(index + 1)}
            onRemoveOption={() => handleRemoveOption(index)}
            onEnterPress={() => handleAddOption(index + 1)}
          />
        ))}

        {field.options.length === 0 && (
          <LightCoreButton size="small" onClick={() => handleAddOption(0)}>
            Add Option
          </LightCoreButton>
        )}
      </Stack>

      {theme.layout === 'classic' && (
        <RightSideShrinkOption
          shrink={field.shrink}
          onChangeShrink={value => onChangeValue({shrink: value})}
          width={field.width}
          onChangeWidth={value => onChangeValue({width: value})}
        />
      )}
    </Box>
  );
};

export default withFieldPropertyChange(DropdownOptions);
