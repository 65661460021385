import React from 'react';
import {Skeleton} from '@mui/material';

const FormListSkeleton = ({count = 1}) => {
  return (
      <>
        <Skeleton variant="rect" height={40}/>
        {Array.from({length: count}).map((rowNum, index) => (
              <Skeleton key={index} variant="rect" style={{marginTop: '8px'}} height={68}/>
          ))}
      </>
  );
};

export default FormListSkeleton;
