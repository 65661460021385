import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material';
import CoreTextField from '../../common/TextField/CoreTextField';
import CoreButton from '../../common/Button/CoreButton';
import LoadingButton from '../../common/Button/LoadingButton';
import {registerPatternFormExport} from '../../helpers/constant/registerPattern';
import {
  DATA_EXPORT_FILE_TYPE_LIST,
  FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION
} from '../../helpers/constant/formSubmissionConstant';
import {selectFolderForm} from '../../state/features/folderForms/folderFormSelector';
import {
  exportForm,
  resetExportFormSuccess
} from '../../state/features/folderForms/folderFormsSlice';

const ExportFormContent = ({
  onClose,
  formId = '',
  submissionIds = [],
  module = FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION
}) => {
  const dispatch = useDispatch();
  const {isFormExporting, isFormExportSuccess} = useSelector(selectFolderForm);
  const {handleSubmit, control} = useForm();

  useEffect(() => {
    if (isFormExportSuccess) {
      dispatch(resetExportFormSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormExportSuccess]);

  // handle submit add folder
  const onSubmit = data => {
    const {fileName, fileType} = data;
    const filterData = {
      formId: formId
    };
    if (submissionIds?.length > 0) filterData.submissionIds = submissionIds;

    dispatch(
      exportForm({
        fileName,
        fileType,
        module,
        filterData: JSON.stringify(filterData)
      })
    );
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      sx={{p: 3, height: '100%'}}
    >
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Controller
            control={control}
            name="fileName"
            defaultValue=""
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  File name
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  type={'text'}
                  size={'small'}
                  placeholder={'Your file name'}
                  value={value}
                  onChange={onChange}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
            rules={registerPatternFormExport.fileName}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            control={control}
            name="fileType"
            defaultValue={DATA_EXPORT_FILE_TYPE_LIST[0].value}
            render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
              <FormControl error={invalid} fullWidth>
                <Typography variant={'body2'} color={'text.secondary'} sx={{mb: 1}}>
                  File type
                </Typography>
                <Select
                  value={value}
                  onChange={onChange}
                  displayEmpty
                  size={'small'}
                  inputProps={{'aria-label': 'Without label'}}
                >
                  {DATA_EXPORT_FILE_TYPE_LIST?.map(fileInfo => (
                    <MenuItem key={fileInfo.value} value={fileInfo.value}>
                      {fileInfo.label}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && <FormHelperText error={invalid}>{error.message}</FormHelperText>}
              </FormControl>
            )}
            rules={registerPatternFormExport.fileType}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} mt={2}>
        <CoreButton size={'large'} color={'error'} onClick={onClose} type={'button'}>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isFormExporting}
          loadingText={'Saving...'}
          type={'submit'}
          size={'large'}
          color={'secondary'}
        >
          Save
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default ExportFormContent;
