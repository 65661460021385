import {useState, useEffect} from 'react';
import {FORM_TYPE} from '../helpers/constant/CoreConstants';

function useRulesIndex(rules, fields, formType = FORM_TYPE.CLASSIC) {
  const [fieldsIdMap, setFieldsIdMap] = useState({});

  useEffect(() => {
    // Perform any side effects here

    //Mapping fields by id and putting indexes
    if (formType === FORM_TYPE.CLASSIC) {
      mapClassicFields(fields, fieldsIdMap);
    } else {
      mapCardFields(fields, fieldsIdMap);
    }

    //Adding indexes rules
    rules.forEach((eachRules, ruleIndex) => {
      //Skipping disabled rules
      if (eachRules.disabled) {
        return;
      }

      //storing rule index to get better performance
      //if not exists we add the rule index
      eachRules.condition.forEach(eachCondition => {
        if (typeof fieldsIdMap[eachCondition.fieldId] === 'object') {
          if (
            fieldsIdMap[eachCondition.fieldId].ruleIndexes.find(v => v === ruleIndex) === undefined
          ) {
            fieldsIdMap[eachCondition.fieldId].ruleIndexes.push(ruleIndex);
          }
        }
      });
    });

    setFieldsIdMap(fieldsIdMap);
  }, [rules, fields, fieldsIdMap, formType]);

  return {fieldsIdMap};
}

const mapClassicFields = (fields, fieldsIdMap) => {
  fields.forEach((eachPage, pageIndex) => {
    eachPage.pageItems.forEach((eachField, fieldIndex) => {
      fieldsIdMap[eachField.id] = {
        pageIndex,
        fieldIndex,
        ruleIndexes: []
      };
    });
  });
};

const mapCardFields = (fields, fieldsIdMap) => {
  fields.forEach((eachField, fieldIndex) => {
    fieldsIdMap[eachField.id] = {
      fieldIndex,
      ruleIndexes: []
    };
  });
};

export default useRulesIndex;
