import React from 'react';
import {Stack} from '@mui/material';
import EmbedModes from './EmbedModes';
import EmbedShareIframe from "./EmbedShareIframe/EmbedShareIframe";

const EmbedShare = () => {
  return (
      <Stack spacing={2}>
        <EmbedShareIframe />
        <EmbedModes />
      </Stack>

  );
};

export default EmbedShare;
