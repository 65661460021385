import React from 'react';
import Box from '@mui/material/Box';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideShrinkOption from '../../Builder/RightComponents/RightSideShrinkOption';

const VideoOptions = ({field, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <RightSideShrinkOption
        shrink={field.shrink}
        onChangeShrink={value => onChangeValue({shrink: value})}
        width={field.width}
        onChangeWidth={value => onChangeValue({width: value})}
      />
    </Box>
  );
};

export default withFieldPropertyChange(VideoOptions);
