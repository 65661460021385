import React from 'react';
import {Divider, Paper} from '@mui/material';
import EmailShareForm from './EmailShareForm';
import BasicTitleBar from '../../common/TitleBar/BasicTitleBar';

const EmailShare = () => {
  return (
    <Paper elevation={0}>
      <BasicTitleBar title={'Share on email'} />
      <Divider />

      <EmailShareForm />
    </Paper>
  );
};

export default EmailShare;
