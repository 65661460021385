import React from 'react';
import {Skeleton} from '@mui/material';
import {Stack, Box} from '@mui/material';
import {StyledTemplateCard} from '../CreateForm/FormTemplate/FormTemplateCardStyles';

const TemplateCardSkeleton = ({count = 6}) => {
  return Array.from({length: count}).map((rowNum, index) => (
    <StyledTemplateCard elevation={2} key={index}>
      <Skeleton variant="rect" width={280} height={200} />
      <Box p={1}>
        <Skeleton />
        <Skeleton width="60%" />
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={2}>
          <Skeleton variant="rounded" width={140} height={35} />
          <Skeleton variant="rounded" width={50} height={35} />
        </Stack>
      </Box>
    </StyledTemplateCard>
  ));
};

export default TemplateCardSkeleton;
