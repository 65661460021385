import React, {useEffect, useState} from 'react';
import {Box, FormControl, Grid, Stack} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CustomRadioGroup from '../../../common/Radio/CustomRadioGroup';
import CustomRadio from '../../../common/Radio/CustomRadio';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormText} from '../../../common/Form-UI/FormText';
import withFieldChange from '../../../hoc/withFieldChange';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from '../../../common/Icons/RequireIcon';
import {ImageWrapperBox} from '../multipleChoice/AddMediaStyles';
import {
  FormControlLabelStyled,
  GridViewItemBox,
  ListViewItemBox,
  SelectedIcon
} from './RadioBoxStyle';

const RadioComponent = ({field, onChange, editMode}) => {
  const [value, setValue] = useState(field.value);

  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  useEffect(() => {
    setValue(field.value);
  }, [field]);

  const handleChangeValue = (value, method, formOption) => {
    if (!field.readOnly && !editMode) {
      method?.setValue(field.id, value);
      onChange({value: value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <Box>
      <Box className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>
            {field.label}
            {field.required && <RequireIcon />}
          </FormLabel>
        </Stack>

        <Stack direction="column" spacing={1} sx={{width: '100%'}}>
          <ConnectPublicForm
            name={field.id}
            value={field.value}
            rules={field.required ? {required: 'Please select an option'} : {}}
            render={(formOption, formMethods) => (
              <>
                {field.fieldStyle === 'list' && (
                  <Box sx={{width: '100%'}}>
                    <FormControl component="fieldset" sx={{width: '100%'}}>
                      <CustomRadioGroup
                        style={{rowGap: '10px'}}
                        aria-label="radio"
                        name="radioComponent"
                        value={value}
                      >
                        {field.options.map((option, index) => {
                          const selected = field.value === option.value;
                          return (
                            <Stack
                              direction="row"
                              alignItems={'center'}
                              key={index}
                              sx={{width: '100%', gap: '8px'}}
                              onClick={() => {
                                handleChangeValue(option.value, formMethods, formOption);
                              }}
                            >
                              <FormControlLabelStyled
                                value={option.value}
                                control={<CustomRadio className="builderRadio" />}
                              />
                              {option.icon && (
                                <ImageWrapperBox sx={{height: 48, width: 48}}>
                                  <img
                                    src={
                                      option.icon ||
                                      process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                    }
                                    alt="Placeholder"
                                  />
                                </ImageWrapperBox>
                              )}

                              <ListViewItemBox
                                alignItems={'center'}
                                selecteditem={selected.toString()}
                                ml={1.5}
                              >
                                <FormText>{option.title}</FormText>
                              </ListViewItemBox>
                            </Stack>
                          );
                        })}
                      </CustomRadioGroup>
                    </FormControl>
                  </Box>
                )}

                {field.fieldStyle === 'grid' && (
                  <Grid container spacing={1}>
                    {field.options.map((option, index) => {
                      const selected = field.value === option.value;
                      return (
                        <Grid
                          item
                          xs={6}
                          md={4}
                          lg={3}
                          className={'fbp_single_select_radio_box'}
                          sx={{
                            minWidth: {
                              xs: '50%',
                              md: field.shrink ? '50%' : '33.333333%',
                              lg: field.shrink ? '50%' : '25%'
                            }
                          }}
                        >
                          <GridViewItemBox
                            direction={'column'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            key={index}
                            selecteditem={selected.toString()}
                            onClick={() => {
                              handleChangeValue(option.value, formMethods, formOption);
                            }}
                          >
                            {option.icon && (
                              <ImageWrapperBox sx={{height: 48, width: 48}}>
                                <img
                                  src={
                                    option.icon ||
                                    process.env.REACT_APP_CDN_LINK + 'image-placeholder.png'
                                  }
                                  alt="Placeholder"
                                />
                              </ImageWrapperBox>
                            )}
                            <FormText sx={{textAlign: 'center'}} mt={1}>
                              {option.title}
                            </FormText>
                            <SelectedIcon selected={selected.toString()}>
                              <CheckCircleOutlineIcon />
                            </SelectedIcon>
                          </GridViewItemBox>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(RadioComponent);
