import React from 'react';
import {Skeleton} from '@mui/material';

const CategoryListSkeleton = ({count = 6}) => {
  return (
    <div style={{padding: '12px'}}>
      {Array.from({length: count}).map((rowNum, i) => (
        <Skeleton key={i} variant="text" style={{marginBottom: '6px'}} />
      ))}
    </div>
  );
};

export default CategoryListSkeleton;
