import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: ''
};

const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState: initialState,
  reducers: {
    getPipelineData: state => {
      state.isLoading = true;
    },
    getPipelineDataSuccess: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.error = '';
    },
    getPipelineDataFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.error = action.payload;
    }
  }
});

export const {getPipelineData, getPipelineDataSuccess, getPipelineDataFailed} =
  pipelineSlice.actions;

export default pipelineSlice.reducer;
