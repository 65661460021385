import React from 'react';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import ThemeColors from './ThemeColors';
import ThemeStyles from './ThemeStyles';
import ThemeThemes from './ThemeThemes';

const ThemeRightPanel = ({theme}) => {
  return (
    <CustomTabs
      className=" fbpFormBuilderRight"
    >
      <CustomTab title="Colors">
        <ThemeColors theme={theme} />
      </CustomTab>
      <CustomTab title="Themes">
        <ThemeThemes theme={theme} />
      </CustomTab>
      <CustomTab title="Styles">
        <ThemeStyles theme={theme} />
      </CustomTab>
    </CustomTabs>
  );
};

export default ThemeRightPanel;
