import React from 'react';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import withFieldPropertyChange from '../../../hoc/withFieldPropertyChange';
import RightSideInput from '../../Builder/RightComponents/RightSideInput';
import ThreeTabsWithTitle from '../../Builder/RightComponents/ThreeTabsWithTitle';

const SubmitButtonRightPanel = ({field, onChangeValue}) => {
  return (
    <>
      <RightSideInput
        text="Submit Button Text"
        title="Change the button text"
        value={field.submitText}
        placeholder="Submit Button Text"
        onChange={e => onChangeValue({submitText: e.target.value})}
      />

      <RightSideInput
        text="Next Button Text"
        title="Change the next button text (If exists)"
        value={field.nextText}
        placeholder="Back Button Text"
        onChange={e => onChangeValue({nextText: e.target.value})}
      />

      <RightSideInput
        text="Back Button Text"
        title="Change the back button text (If exists)"
        value={field.backText}
        placeholder="Back Button Text"
        onChange={e => onChangeValue({backText: e.target.value})}
      />

      <ThreeTabsWithTitle
        text="Button Alignment"
        title="Button alignment on the form"
        child1={fbpIcons.fbpLeftIcon}
        child2={fbpIcons.fbpCenterIcon}
        child3={fbpIcons.fbpRightIcon}
        value1="left"
        value2="center"
        value3="right"
        selectedValue={field.alignment}
        onChange={value => onChangeValue({alignment: value})}
      />
    </>
  );
};

export default withFieldPropertyChange(SubmitButtonRightPanel);
