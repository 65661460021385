import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import PipelineService from '../../../services/Pipeline.service';
import {getPipelineStageDataFailed, getPipelineStageDataSuccess} from './pipelineStageSlice';

function* pipelineStageWatcher() {
  yield takeLatest('pipelineStage/getPipelineStageData', getPipelineStageDataSaga);
}

function* getPipelineStageDataSaga(action) {
  try {
    const response = yield call(PipelineService.getPipelineStageData, action.payload);

    if (response.success) {
      yield put(getPipelineStageDataSuccess(response.data));
    } else {
      yield put(getPipelineStageDataFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* pipelineStageSaga() {
  yield all([pipelineStageWatcher()]);
}
