import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import BuilderService from '../../services/Builder.service';

const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL_FORM_API}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

const CustomEditor = ({
  editorRef,
  body,
  onChangeBody,
  simpleVersion = false,
  onBlur = () => {},
  ...rest
}) => {
  const handleUploadFile = (blobInfo, success, failure, progress) => {
    const formData = new FormData();
    formData.append('files', blobInfo.blob(), blobInfo.filename());
    BuilderService.uploadFile(formData).then(res => {
      if (res.success === true) {
        success(res.urls[0]);
      } else {
        failure('Unable to upload the file. Please try again later');
      }
    });
  };

  return (
    <Editor
      apiKey="bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr"
      // onInit={(evt, editor) => editorRef.current = editor}
      value={body}
      cloudChannel="5-dev"
      textareaName="email_reply"
      init={{
        height: 300,
        auto_focus: true,
        menubar: !simpleVersion && 'favs file edit view insert format tools table help',
        menu: {
          favs: {title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons'}
        },
        plugins: [
          'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
          'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
          'insertdatetime media table contextmenu code help wordcount template'
        ],
        convert_urls: false,
        toolbar:
          'insertfile undo redo | styleselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | link image | bullist numlist outdent indent | removeformat | preview media fullpage',
        imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
        imagetools_proxy: emailUploadFileApi,
        images_upload_handler: handleUploadFile,
        automatic_uploads: true,
        file_picker_types: 'file image media',
        target_list: [
          {title: 'Same page', value: '_self'},
          {title: 'New page', value: '_blank'}
        ],
        content_style: 'p { margin: 0px; }'
      }}
      onEditorChange={onChangeBody}
      onBlur={(e) => onBlur()}
      // id="email-setting-body-input-field"
      {...rest}
    />
  );
};

export default CustomEditor;
