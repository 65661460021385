import React from 'react';
import {Box, styled} from '@mui/material';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {AudioComponentIcon} from '../../../helpers/fbpIcons/IconComponents';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';

const BoxStyled = styled(Box)(({theme}) => ({
  background: 'var(--fbp-theming-input-background)',
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: '8px',
  width: '100%',
  height: 'max-content',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '26px 32px',
  overflow: 'hidden',
  '& svg path':{
    fill: 'var(--fbp-theming-text-color)'
  }
}));

const CardViewAudioComponent = ({field}) => {
  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
        <Box mb={4}>
          <FormDescription>{field.description}</FormDescription>
        </Box>

        <Box>
          {!field.audio && (
            <BoxStyled>
              <AudioComponentIcon />
            </BoxStyled>
          )}

          {field.audio && (
            <BoxStyled>
              <div className="audio_play">
                <audio controls>
                  <source src={field.audio} type="audio/ogg" />
                  <source src={field.audio} type="audio/mpeg" />
                  <source src={field.audio} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>
              </div>
            </BoxStyled>
          )}
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default CardViewAudioComponent;
