import React, {useEffect, useState} from 'react';
import {DateRange} from 'react-date-range';
import {differenceInDays, format} from 'date-fns';
import {styled, Typography} from '@mui/material';
import getFormatDataPickerDate from '../../helpers/utils/getFormatDatePickerDate';
import getDurationType from '../../helpers/utils/getDurationType';
import getFormatFromAndToDate from '../../helpers/utils/getFormatFromAndToDate';
import fbpIcons from '../../helpers/fbpIcons/fbpIcons';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Styles from './DateRangePicker.module.scss';

export const StyledDateRangePicker = styled(DateRange)(({theme}) => ({
  '& .rdrMonthAndYearPickers': {
    '& span': {
      '& select': {
        display: 'flex !important',
        '&::-webkit-scrollbar': {
          width: '4px'
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#5A5BD7'
        },
        '& option': {
          backgroundColor: theme.palette.background.paper,
          padding: '3px !important'
        }
      }
    }
  }
}));

const startDate = new Date(Date.now() - 3600 * 1000 * 24 * 30);
const endDate = new Date();
const [datePickerStartDate, datePickerEndDate] = getFormatDataPickerDate(startDate, endDate);

const DateRangeController = ({datePickerCallback, title = '', isNeedInstantCallback = false}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }
  ]);

  const [datePickerFormatDate, setDatePickerFormatDate] = useState({
    startDate: datePickerStartDate,
    endDate: datePickerEndDate
  });

  const handleDateRangeToggle = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  const handleDateRangeChange = dateRange => {
    setState([dateRange.selection]);

    const [datePickerStartDate, datePickerEndDate] = getFormatDataPickerDate(
      dateRange.selection.startDate,
      dateRange.selection.endDate
    );

    const fromDate = format(dateRange.selection.startDate, 'yyyy-MM-dd');
    const toDate = format(dateRange.selection.endDate, 'yyyy-MM-dd');
    const difference = differenceInDays(dateRange.selection.endDate, dateRange.selection.startDate);
    const durationType = getDurationType(difference);

    setDatePickerFormatDate({startDate: datePickerStartDate, endDate: datePickerEndDate});
    if (datePickerCallback) {
      datePickerCallback({_fromDate: fromDate, _toDate: toDate, difference, durationType});
    }
  };

  useEffect(() => {
    if (isNeedInstantCallback) {
      const {fromDate, toDate, difference, durationType} = getFormatFromAndToDate(
        startDate,
        endDate
      );

      if (datePickerCallback) {
        datePickerCallback({_fromDate: fromDate, _toDate: toDate, difference, durationType});
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {datePickerOpen && (
        <div onClick={() => setDatePickerOpen(false)} className={Styles.drpOuterClick} />
      )}

      <div className={Styles.drpWrapper}>
        <div onClick={handleDateRangeToggle} className={Styles.drpContentWrapper}>
          <div className={Styles.drpContentArea}>
            <span style={{display: 'inline-flex'}}>{fbpIcons.fbpDateIcon}</span>
            <Typography
              variant="body2"
              color={'text.secondary'}
              sx={{fontSize: title ? '14px' : '13px'}}
            >
              {title
                ? title
                : datePickerFormatDate.startDate + ' - ' + datePickerFormatDate.endDate}
            </Typography>
          </div>
        </div>

        <div className={Styles.drpBody}>
          {datePickerOpen && (
            <StyledDateRangePicker
              editableDateInputs={true}
              onChange={item => handleDateRangeChange(item)}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangeController;
