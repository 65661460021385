import React from 'react';
import Box from '@mui/material/Box';
import withThemeChange from '../../../hoc/withThemeChange';
import BuilderLayoutOptions from '../../Builder/RightComponents/BuilderLayoutOptions';
import BuilderAnimation from '../../Builder/RightComponents/BuilderAnimation';
// import BuilderLayoutTheme from "../../Builder/RightComponents/BuilderLayoutTheme";

const ThemeThemes = ({theme, onChangeValue}) => {
  return (
    <Box sx={{overflowY: 'auto', paddingBottom: '20px'}}>
      <BuilderLayoutOptions
        selected={theme.layout}
        onChange={layout => onChangeValue({layout: layout})}
      />

      {theme.layout === 'card' && (
        <BuilderAnimation
          selected={theme.animation}
          onChange={animation => onChangeValue({animation: animation})}
        />
      )}

       {/*<BuilderLayoutTheme />*/}
    </Box>
  );
};

export default withThemeChange(ThemeThemes);
