import {all, put} from 'redux-saga/effects';
import {call, takeLatest} from '@redux-saga/core/effects';
import {getCustomFieldFailed, getCustomFieldSuccess} from './customFieldSlice';
import CustomFieldService from '../../../services/CustomField.service';

function* customFieldWatcher() {
  yield takeLatest('customField/getCustomFieldData', getCustomFieldSaga);
}

function* getCustomFieldSaga(action) {
  try {
    const response = yield call(CustomFieldService.getCustomFieldData, action.payload);

    if (response.success) {
      yield put(getCustomFieldSuccess(response.data));
    } else {
      yield put(getCustomFieldFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* customFieldSaga() {
  yield all([customFieldWatcher()]);
}
