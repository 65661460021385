import React from 'react';
import {Box, Paper, Typography} from '@mui/material';

const PageNotFound = ({icon, text}) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'grid',
        placeItems: 'center',
        background: '#f4f5f6',
        padding: '0 20px'
      }}
    >
      <Paper
        elevation={2}
        sx={{
          width: 'max-width',
          minWidth: {sm: '500px', xs: '100%'},
          margin: 'auto',
          padding: '100px 30px',
          textAlign: 'center'
        }}
      >
        {icon}
        <Typography variant={'h3'}>{text}</Typography>
      </Paper>
    </Box>
  );
};
export default PageNotFound;
