import React from 'react';
import InboxHeaderActions from './InboxHeaderActions';


const InboxBodyHeaderRight = () => {
  return (
      <InboxHeaderActions />
  );
};

export default InboxBodyHeaderRight;
