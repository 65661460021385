import React from 'react';
import {IconButton, Box, styled, Typography} from '@mui/material';
import {Close} from '@mui/icons-material';

const CustomChipStyle = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '4px 8px',
  borderRadius: '16px',
  overflow: 'hidden',
  width: 'max-content',
  transition: 'padding 0.3s ease-in-out',
  position: 'relative',
  '&:hover': {
    paddingRight: '24px'
  },
  '&:hover .MuiIconButton-root': {
    transform: 'translateX(0px)',
    right: 0,
    opacity: 2,
    visibility: 'visible'
  }
}));

const IconButtonStyle = styled(IconButton)(() => ({
  transition: 'all 0.3s',
  transform: 'translateX(20px)',
  position: 'absolute',
  right: '-20px'
}));

const CustomRemoveTagChip = ({text, color = '#aaa', onClose}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <CustomChipStyle sx={{backgroundColor: color}}>
      <Typography
        variant={'caption'}
        sx={{maxWidth: '150px', width: 'max-content'}}
        className={'text-truncate'}
        title={text}
      >
        {text}
      </Typography>
      <IconButtonStyle size={'small'} onClick={handleClose}>
        <Close color={'action'} sx={{fontSize: '14px'}} />
      </IconButtonStyle>
    </CustomChipStyle>
  );
};

export default CustomRemoveTagChip;
