import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Skeleton} from "@mui/material";
import {MediaImageWrapper} from "./AddMediaStyles";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import {getSaveIcons} from "../../../state/features/saveIcon/saveIconSlice";
import {selectSaveIconState} from "../../../state/features/saveIcon/saveIconSelector";

const AddMediaMyImagesTab = ({onAddImageIcon}) => {
    const dispatch = useDispatch();
    const {data: mediaIcons = [], isLoading, isSuccess} = useSelector(selectSaveIconState);

    useEffect(() => {
        if(!isSuccess) {
            dispatch(getSaveIcons())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // decide what to render
    let content = null;
    if (isLoading) {
        content = Array.from({length: 20}).map((rowNum, index) => (
            <Skeleton key={index} variant="rounded" height={48} width={48}/>
        ));
    } else if (!isLoading && mediaIcons?.length === 0) {
        content = <GlobalEmptyPage title={"No image found!!"}/>
    } else if (!isLoading && mediaIcons?.length > 0) {
        content = mediaIcons.map((icon, index) => (
            <MediaImageWrapper key={index} sx={{cursor: "pointer"}} onClick={() => onAddImageIcon(icon)}>
                <img src={icon} alt="icon"/>
            </MediaImageWrapper>
        ))
    }

  return (
      <Box sx={{height: "240px", mt: 2}} className={"fbp_has_scroll"}>
          <Box sx={{display: "flex", flexWrap: "wrap", gap: "8px"}}>
              {content}
          </Box>
      </Box>
  );
};

export default AddMediaMyImagesTab;
