import React from 'react';
import {useSelector} from 'react-redux';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';
import ShortTextAdvanced from './ShortTextAdvanced';
import ShortTextGeneral from './ShortTextGeneral';
import ShortTextOptions from './ShortTextOptions';

const ShortTextRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <ShortTextGeneral field={field} />
      </CustomTab>

      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <ShortTextOptions field={field} />
        </CustomTab>
      )}

      <CustomTab title="Advanced">
        <ShortTextAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default ShortTextRightPanel;
