import React from 'react';
import {Typography, useTheme} from '@mui/material';
import Stack from '@mui/material/Box';

const InboxCell = ({rowOne, rowTwo}) => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
        padding: '12px 0'
      }}
      direction="row"
    >
      <Typography sx={{flexBasis: '269px'}} color="text.secondary" variant="body2">
        {rowOne}
      </Typography>
      <Typography component={'div'} color="text.primary" variant="body2">
        {rowTwo}
      </Typography>
    </Stack>
  );
};

export default InboxCell;
