import React from 'react';

const EmbeddedForm = ({embedCode}) => {
  return (
    <div style={{height: '100%', width: '100%'}}>
      <iframe
        id="xxx"
        title="Embed Code View"
        width="100%"
        height="100%"
        frameBorder="value"
        allowTransparency
        srcDoc={`
          <!doctype html>
          <html>
              <head>
                  <title>Embed Code View</title>
                  <meta charset="utf-8">
                  <meta name="viewport" content="width=device-width">
                  
              </head>
              <body style="width:100%; overflow: hidden; margin: 0">
                  ${embedCode}
              </body>
          </html>
          `}
      />
    </div>
  );
};

export default EmbeddedForm;
