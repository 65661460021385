import {Stack, styled, Typography} from '@mui/material';

export const BracesStyle = styled('span')(({theme}) => ({
  color: theme.palette.other.outlinedBorderDarker,
  fontSize: '24px',
  fontWeight: '600'
}));

export const ConditionTypeStack = styled(Stack)(({theme}) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(0.25),
  padding: theme.spacing(0.25),
  color: theme.palette.common.white
}));

export const StatementLineStyle = styled(Stack)(({theme}) => ({
  position: 'relative',
  '&::before': {
    content: '"then"',
    position: 'absolute',
    top: '4px',
    left: '4px',
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'italic',
    color: theme.palette.text.secondary
  }
}));

export const ColoredSpanStyle = styled(Typography)(({theme, color}) => ({
  color: color,
  margin: '0 4px'
}));

export const FoldMoreLessIconWrapper = styled('div')(({theme}) => ({
  position: 'absolute',
  cursor: 'pointer',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  '&:hover': {
    opacity: 0.6
  }
}));

export const TypographyTruncate = styled(Typography)(({textLength = 0}) => ({
  flex: textLength > 20 ? 1 : 'none',
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
}));

export const MissingField = () => (
  <ColoredSpanStyle color={'#f00'}>"MISSING FIELD"</ColoredSpanStyle>
);
