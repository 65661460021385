export const FORM_STATUS = {
  PAUSED: 0,
  PUBLISHED: 1,
  TRASH: 2
};

export const CREATE_FORM_LAYOUT = {
  SCRATCH_LAYOUT: 1,
  TEMPLATE_LAYOUT: 2
};

export const FORM_TEMPLATE_CATEGORY_TYPE = {
  TYPES_ALL: 1,
  INDUSTRIES: 2
};

export const FORM_TEMPLATE_TYPE = {
  CLASSIC: 1,
  CARD: 2
};

export const FORM_TEMPLATE_STATUS = {
  ALL: '',
  MOST_POPULAR: 1,
  FAVORITE: 2
};
