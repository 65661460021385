import React from 'react';

const CreateFolderIcon = props => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6665 5.00016H9.99984L8.33317 3.3335H3.33317C2.40817 3.3335 1.67484 4.07516 1.67484 5.00016L1.6665 15.0002C1.6665 15.9252 2.40817 16.6668 3.33317 16.6668H16.6665C17.5915 16.6668 18.3332 15.9252 18.3332 15.0002V6.66683C18.3332 5.74183 17.5915 5.00016 16.6665 5.00016ZM15.8332 11.6668H13.3332V14.1668H11.6665V11.6668H9.1665V10.0002H11.6665V7.50016H13.3332V10.0002H15.8332V11.6668Z"
        fill="#546376"
      />
    </svg>
  );
};

export default CreateFolderIcon;
