import React from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Box, Stack, Typography} from '@mui/material';
import FormFooterOptions from "./FormFooterOptions";
import FooterSkeleton from "../../LoadingView/FooterSkeleton";
import CustomSwitch from '../../../common/Switch/CustomSwitch';
import {enableFormFooter} from "../../../state/features/formFooter/formFooterSlice";
import {selectFormFooterState} from "../../../state/features/formFooter/formFooterSelector";
import {FORM_SETTINGS_TYPE_FOOTER} from "../../../helpers/constant/formSettingsConstant";

const FormFooterBody = () => {
    const dispatch = useDispatch();
    const {formId} = useParams();
    const { data, isLoading } = useSelector(selectFormFooterState);
    const { enable = false } = data || {};

    const onEnabledFooter = () => {
        let settings = {...data, enable: !enable};
        settings = JSON.stringify(settings);
        dispatch(enableFormFooter({formId, settings: settings, type: FORM_SETTINGS_TYPE_FOOTER }))
    }

  if(isLoading) return <FooterSkeleton />;
  return (
      <>
          <Stack direction={'row'} alignItems={"center"} justifyContent={'space-between'} p={3}>
              <Typography variant={'body1medium'} color={'text.primary'}>
                  Enable Footer
              </Typography>
              <CustomSwitch
                  variant="outlined"
                  status={enable}
                  labelChecked={'ON'}
                  labelUnchecked={'OFF'}
                  onChange={onEnabledFooter}
                  width={62}
              />
          </Stack>

          <Box sx={{visibility: enable ? "visible" : "hidden", height: enable ? "100%": "0px", overflow: "hidden", transition: "0.5s"}}>
              <FormFooterOptions />
          </Box>
      </>
  );
};

export default FormFooterBody;
