import React from 'react';
import {Typography, Box, styled} from '@mui/material';
import CustomFormHelperText from '../FormHelperText/CustomFormHelperText';
import Styles from './customTextField.module.scss';

const StyledBox = styled('div')(({theme, padding}) => ({
  width: '100%',
  '& input': {
    padding: `${padding}!important`,
    borderRadius: '4px !important'
  },
  '&.styleOptionInput': {
    '& input': {
      borderRadius: '4px 0 0 4px !important',
      padding: '5px 12px'
    }
  },
  '&.embedShare': {
    '& input': {
      border: `1px solid ${theme.palette.other.outlinedBorder} !important`,
      borderRadius: '4px',
      padding: '6px 12px'
    }
  }
}));

const CustomTextField = ({
  max = 200,
  type = 'text',
  width = '',
  flex = '',
  labelPosition = 'left',
  labelIcon = '',
  label = '',
  placeholder = '',
  size = 'large',
  error = false,
  errorMessage = '',
  padding = '5px 12px',
  className,
  ...rest
}) => {
  return (
    <StyledBox
      padding={padding}
      className={`${Styles.fbpTextGroup} ${
        labelPosition === 'left' ? Styles.fbpTextGroupLeft : ''
      } ${className}`}
      style={{flex: `${flex}`, width: `${width}`}}
    >
      {label && (
        <Box className={`${Styles.fbpTextLabel}`}>
          <Typography variant="body2medium" color="text.secondary">
            {label}
          </Typography>
          <span className={Styles.fbpTextLabelStar}>{labelIcon}</span>
        </Box>
      )}
      <input
        className={` ${Styles.fbpTextField}  ${
          size === 'medium'
            ? Styles.fbpTextFieldMedium
            : size === 'small'
            ? Styles.fbpTextFieldSmall
            : Styles.fbpTextFieldLarge
        }`}
        type={type}
        placeholder={placeholder}
        maxLength={max}
        {...rest}
      />
      {error && <CustomFormHelperText message={errorMessage} />}
    </StyledBox>
  );
};

export default CustomTextField;
