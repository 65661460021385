import React from 'react';
import {Divider, Paper} from '@mui/material';
import PublishSettingsForm from './PublishSettingsForm';
import BasicTitleBar from '../../common/TitleBar/BasicTitleBar';

const TemplatePublishPage = () => {
  return (
    <Paper elevation={0} sx={{flex: '0 1 57%'}}>
      <BasicTitleBar
        title={'Template Publish Page'}
        content={'Share template form as a public all user.'}
      />
      <Divider />

      <PublishSettingsForm />
    </Paper>
  );
};

export default TemplatePublishPage;
