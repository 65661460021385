import React from 'react';
import {Alert, Box, Step, StepLabel} from '@mui/material';
import {StyledNumberStepper} from './CardProgressBarStyles';

const CardViewProgressBarComponent = ({editMode, totalFields = 6, totalDone = 3, field}) => {
  if (!field) {
    return '';
  }

  const getAllSteps = totalSteps => {
    let arr = [];
    for (let i = 1; i <= totalSteps; i++) {
      arr.push(
        <Step key={i}>
          <StepLabel>{i}</StepLabel>
        </Step>
      );
    }

    return arr;
  };

  return (
    <>
      <Box
        sx={{
          width: {sm: '720px', md: '800px', lg: '1024px'},
          maxWidth: '100%',
          transition: 'width 0.2s ease',
          marginInline: 'auto',
          display: {xs: 'none', sm: 'block'}
        }}
        mt={-1.4}
        className={"cardViewProgressBar"}
      >
        <StyledNumberStepper
          progressBarColor={field.color}
          activeStep={totalDone}
          backgroundColor={field.backgroundColor}
          alternativeLabel
        >
          {getAllSteps(totalFields, field.color)}
        </StyledNumberStepper>
      </Box>

      {editMode && (
        <Alert severity="warning" sx={{marginTop: '20px'}}>
          The progress bar will appear at the bottom of your form.
        </Alert>
      )}
    </>
  );
};

export default CardViewProgressBarComponent;
