import {styled, Typography} from '@mui/material';

export const FormSubtext = styled(Typography)(({theme}) => ({
  fontFamily: 'var(--fbp-theming-font-family) !important',
  fontSize: 'var(--fbp-theming-label-sub-text-font-size) ',
  color: 'var(--fbp-theming-sub-text-color) ',
  fontWeight: 400,
  lineHeight: 'var(--fbp-theming-label-sub-text-font-size) ',
  letterSpacing: '0.4px',
  wordBreak:'break-all'
}));


