import React from 'react';
import {Stack, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import CoreButton from '../../../../common/Button/CoreButton';
import {saveCondition} from '../../../../state/features/conditionSetting/conditionSettingSlice';
import {selectConditionSettingState} from '../../../../state/features/conditionSetting/conditionSettingSelector';

const ConditionSaveController = () => {
  const dispatch = useDispatch();
  const {isError, error, isUpdating} = useSelector(selectConditionSettingState);

  const handleSave = () => {
    dispatch(saveCondition());
  };

  const errorMessage = isError && typeof error === 'string' && error;

  return (
    <Stack direction={'row'} justifyContent="space-between" spacing={2}>
      <Typography variant={'body1'} color={'error'}>
        {errorMessage}
      </Typography>
      <CoreButton disabled={isUpdating} variant="contained" color={'success'} onClick={handleSave}>
        {isUpdating ? 'Saving...' : 'Save'}
      </CoreButton>
    </Stack>
  );
};

export default ConditionSaveController;
