import React from 'react';
import {Alert} from '@mui/material';

const HiddenFieldAlert = ({className = ''}) => {
  return (
    <Alert className={className} severity="warning" sx={{marginTop: '20px'}}>
      This field is hidden and will not be seen on the form.
    </Alert>
  );
};

export default HiddenFieldAlert;
