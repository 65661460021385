import React from 'react';
import {Stack} from '@mui/material';
import ActionController from './ActionController';
import ConditionController from './ConditionController';
import ConditionTypeSelection from './ConditionTypeSelection';
import ConditionSaveController from './ConditionSaveController';

const ConditionAndActionBody = () => {
  return (
    <Stack mt={3} spacing={2}>
      {/* ## Condition Section ## */}
      <ConditionController />

      {/* ## Condition Link Type Selection ## */}
      <ConditionTypeSelection />

      {/* ## Action Section ## */}
      <ActionController />

      {/* ## Condition Save Section ## */}
      <ConditionSaveController />
    </Stack>
  );
};

export default ConditionAndActionBody;
