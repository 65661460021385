import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Popover, Stack, styled} from '@mui/material';
import CustomRemoveTagChip from '../../../common/Chip/CustomRemoveTagChip';
import {castArray} from '../../../helpers/utils/castArray';
import compressString from '../../../helpers/utils/compressString';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {selectTags} from '../../../state/features/tags/tagsSelector';
import {removeTagFromSubmission} from '../../../state/features/formSubmission/formSubmissionSlice';

const MoreChipBox = styled(Box)(({theme}) => ({
  display: 'inline-flex',
  cursor:'pointer',
  '& svg': {transform: 'rotate(90deg)'}
}));
const FoldersPopover = styled(Stack)(({theme}) => ({
  width: '230px',
  maxHeight: '250px'
}));

const ChipWrapper = styled(Box)(({theme}) => ({
  maxWidth: {sm: '350px', md: '165px', xl: '400px'},
  display: 'flex',
  flex: '1',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {display: 'none'}
}));

const TagsList = ({tagIds = [], submissionId}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const tagList = useSelector(selectTags);
  const handleRemoveTag = tagId => {
    dispatch(
      removeTagFromSubmission({
        submissionIds: castArray(submissionId),
        tagIds: castArray(tagId),
        status: false
      })
    );
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popOverId = open ? 'simple-popover' : undefined;

  return (
    <ChipWrapper>
      <Stack direction="row" spacing={1} alignItems="center">
        {tagList
          .filter(tag => tagIds.includes(tag.id))
          .slice(0, 1)
          .map(tagItem => (
            <CustomRemoveTagChip
              key={tagItem.id}
              color={tagItem.color}
              text={compressString(tagItem.name, 15)}
              onClose={() => handleRemoveTag(tagItem.id)}
            />
          ))}

        {tagIds?.length > 1 ? (
          <>
            <MoreChipBox onClick={handleClick}>{fbpIcons.fbpThreeDotIcon}</MoreChipBox>
            <Popover
              id={popOverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <FoldersPopover direction={'column'} spacing={1} p={1} className={'fbp_has_scroll'}>
                {tagList
                  .filter(tag => tagIds.includes(tag.id))
                  .slice(1)
                  .map(tagItem => (
                    <CustomRemoveTagChip
                      key={tagItem.id}
                      color={tagItem.color}
                      text={compressString(tagItem.name, 15)}
                      onClose={() => handleRemoveTag(tagItem.id)}
                    />
                  ))}
              </FoldersPopover>
            </Popover>
          </>
        ) : (
          ''
        )}
        {tagIds?.length === 0 && '-'}
      </Stack>
    </ChipWrapper>
  );
};

export default TagsList;
