import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Stack, styled, Typography} from '@mui/material';
import CustomRemoveTagChip from '../../../common/Chip/CustomRemoveTagChip';
import {getSubmissionFormNameAndEmail} from '../../../helpers/utils/submissionFormUtils';
import compressString from '../../../helpers/utils/compressString';
import {castArray} from '../../../helpers/utils/castArray';
import fbpIcons from '../../../helpers/fbpIcons/fbpIcons';
import {
  selectSelectedSubmissionData,
  selectSelectedSubmissionId
} from '../../../state/features/formSubmission/formSubmissionSelector';
import {removeTagFromSubmission} from '../../../state/features/formSubmission/formSubmissionSlice';
import {selectTags} from '../../../state/features/tags/tagsSelector';

const ArrowBox = styled(Box)(({theme}) => ({
  display: 'none',
  cursor: 'pointer',
  height: '21px',
  width: '24px',
  position: 'absolute',
  top: '30px',
  '@media screen and (maxWidth: 1024px)': {
    display: 'block'
  }
}));

const InboxBodyHeaderRight = () => {
  const dispatch = useDispatch();
  const submissionFormData = useSelector(selectSelectedSubmissionData);
  const selectedSubmissionId = useSelector(selectSelectedSubmissionId);
  const tagList = useSelector(selectTags);
  const {
    submission: {submission: submissionList = []} = {},
    id,
    tagIds = []
  } = submissionFormData || {};
  const {name, email} = getSubmissionFormNameAndEmail(submissionList);

  const handleRemoveTag = tagId => {
    dispatch(
      removeTagFromSubmission({
        submissionIds: castArray(selectedSubmissionId),
        tagIds: castArray(tagId)
      })
    );
  };

  const submittedBy = name ? name : email ? email : `Submission #${id}`;

  return (
    <>
      <Stack direction="row" alignItems={'center'} sx={{width: 'calc(100% - 285px)'}}>
        <ArrowBox>{fbpIcons.fbpArrowBackIcon}</ArrowBox>
        <Box sx={{width: '100%'}}>
          <Typography variant="subtitle1" className="text-truncate" title={submittedBy}>
            {submittedBy}
          </Typography>
          <Box
            sx={{
              overflowX: 'scroll',
              '&::-webkit-scrollbar': {display: 'none'}
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1} sx={{width: 'max-content'}}>
              {tagList
                ?.slice()
                .filter(tag => tagIds.includes(tag.id))
                .map(tagItem => (
                  <CustomRemoveTagChip
                    key={tagItem.id}
                    color={tagItem.color}
                    text={compressString(tagItem.name, 15)}
                    onClose={() => handleRemoveTag(tagItem.id)}
                  />
                ))}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default InboxBodyHeaderRight;
